import _ from 'lodash';
import slugify from 'slugify';
import React from 'react';
import moment from 'moment';

import { defaultDateTimeFormat } from '../constants/config';

export function formatBinaryIntoBase64(binaryData) {
  return Buffer.from(binaryData, 'binary').toString('base64');
}

export function parseJSON(jsonString) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    _.noop();
  }

  return undefined;
}

export function isUndefinedNullNaNorEmptyString(value) {
  return (
    _.isUndefined(value) || _.isNull(value) || _.isNaN(value) || value === ''
  );
}

export function replaceSymbolsToLowerCase(input, replacement) {
  return slugify(input, { replacement, lower: true });
}

export function getAttachmentsId(attachments) {
  return _.map(attachments, (attachment) => attachment.id);
}

export function getTimeAgo(timeOfEvent, withoutPrefix = false) {
  const agoString = moment(timeOfEvent, moment.ISO_8601).fromNow(withoutPrefix);
  const fullDateString = moment(timeOfEvent).format(defaultDateTimeFormat);
  return <span title={fullDateString}>{agoString}</span>;
}
