import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import permissionApi from '../../api/permissionApi';
import toastr from 'toastr';
import PrincipalLabel from '../common/PrincipalLabel';
import PartyLabel from '../common/labels/PartyLabel';
import { Table } from 'semantic-ui-react';

const PrincipalListFromNode = (props) => {
  const { structuralNodeId } = props;

  const [principals, setPrincipals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await permissionApi.getPrincipalsListForNode(
          structuralNodeId
        );
        setPrincipals(result);
      } catch (error) {
        toastr.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Table celled collapsing>
        {principals.map((principal, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              <PrincipalLabel principal={principal} />
            </Table.Cell>
            <Table.Cell>
              <PartyLabel
                party={{ label: principal.partyLabel, id: principal.partyId }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </React.Fragment>
  );
};

PrincipalListFromNode.propTypes = {
  structuralNodeId: PropTypes.number,
};

export default PrincipalListFromNode;
