import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Accordion } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const FileAccordion = ({ description, originalFileName }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Accordion>
      <Accordion.Title active={isActive} onClick={handleClick}>
        <Icon name={`chevron ${isActive ? 'up' : 'right'}`} />
        <span className="table-cell-fix-wrap">{originalFileName}</span>
      </Accordion.Title>
      <Accordion.Content active={isActive}>
        <ReactMarkdown source={description} />
      </Accordion.Content>
    </Accordion>
  );
};

FileAccordion.propTypes = {
  description: PropTypes.string,
  originalFileName: PropTypes.string,
};

export default FileAccordion;
