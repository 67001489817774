import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Icon, Step } from 'semantic-ui-react';
import { WrapWizardContext } from '../../context/WizardContext';

const WizardSteps = (props) => {
  const { steps, stepClicked, currentStep } = props;

  if (!currentStep) return null;

  let stepNumber = 1;

  return (
    <React.Fragment>
      <Step.Group fluid size="small">
        {_.map(steps, (value, key) => (
          <Step
            key={key}
            active={value.current}
            completed={value.completed}
            link={false}
            onClick={value.completed ? () => stepClicked(key) : null}
          >
            <Icon name={value.icon} />
            <Step.Content>
              <Step.Title>
                {stepNumber++} - {value.title}
              </Step.Title>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
      <Header as="h2" size="large">
        {steps[currentStep].description}{' '}
      </Header>
    </React.Fragment>
  );
};

WizardSteps.propTypes = {
  steps: PropTypes.object,
  stepClicked: PropTypes.func,
  currentStep: PropTypes.string,
};

export default WrapWizardContext(WizardSteps);
