export function pluralize(text, value, numberBeforeText, onlyWord = false) {
  const pluralText = value > 1 ? `${text}s` : text;
  if (onlyWord) return pluralText;
  if (numberBeforeText) {
    return `${value} ${pluralText}`;
  }
  return `${pluralText} ${value}`;
}

export function getOrdinal(n) {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}
