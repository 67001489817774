import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import * as _ from 'lodash';

const TagsInput = ({
  tags,
  value,
  onChange,
  addNewTag,
  name = 'tags',
  inline = false,
  multiple = true,
}) => {
  const mapTags = (event, data) => {
    data.value = _.isArray(data.value)
      ? data.value.map((tagId) => {
          return _.find(tags, { id: tagId });
        })
      : _.find(tags, { id: data.value });
    onChange(event, data);
  };
  return (
    <Dropdown
      options={mapDropDownOptions(tags)}
      placeholder="Choose Tag"
      search
      fluid
      selection={!inline}
      multiple={multiple}
      allowAdditions
      value={
        _.isArray(value)
          ? value.filter((t) => !!t).map((t) => t.id)
          : value
          ? value.id
          : null
      }
      name={name}
      onAddItem={addNewTag}
      onChange={mapTags}
      inline={inline}
      floating={inline}
    />
  );
};

function mapDropDownOptions(tags) {
  return tags.map((tag) => {
    return {
      key: tag.id,
      text: tag.label.toUpperCase(),
      value: tag.id,
    };
  });
}

TagsInput.propTypes = {
  tags: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  addNewTag: PropTypes.func,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  inline: PropTypes.bool,
};

export default TagsInput;
