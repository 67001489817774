import React from 'react';
import * as PropTypes from 'prop-types';
import highcharts from 'highcharts';
import highchartsGantt from 'highcharts/modules/gantt';
import highchartsDrag from 'highcharts/modules/draggable-points';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';

import { gradientColors } from '../../constants/config';

HighchartsCustomEvents(highcharts);
highchartsGantt(highcharts);
highchartsDrag(highcharts);
exporting(highcharts);
offlineExporting(highcharts);

// highcharts.setOptions({
//   colors: [
//     gradientColors.orange,
//     gradientColors.yellow,
//     gradientColors.olive,
//     gradientColors.teal,
//     gradientColors.violet
//   ]
// });

class HighchartsReact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount() {
    const props = this.props;
    this.chart = highcharts[props.constructorType || 'chart'](
      this.container.current,
      props.options,
      props.callback ? props.callback : undefined
    );
  }

  componentDidUpdate() {
    if (this.props.allowChartUpdate !== false) {
      this.chart.update(
        this.props.options,
        ...(this.props.updateArgs || [true, true])
      );
    }
  }

  componentWillUnmount() {
    // Destroy chart
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  render() {
    // Create container for the chart
    return React.createElement('div', { ref: this.container });
  }
}

HighchartsReact.propTypes = {
  allowChartUpdate: PropTypes.bool,
  options: PropTypes.object,
  updateArgs: PropTypes.array,
};

export default HighchartsReact;
