import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import ThresholdListItem from './ThresholdListItem';
import { PartyWrapContext } from '../../context/PartyContext';
import SortableHeaderCell from '../common/SortableHeaderCell';
import ConfirmWrapper from './../common/ConfirmWrapper';

const ThresholdsList = (props) => {
  const { riskThresholds, handleSortChange, paginationOptions } = props;
  const { showConfirmDialog } = props.confirmPopupActions;
  const { removeThreshold } = props.partyStateActions;
  const { openCreateThreshold } = props.partyActions;
  const renderThresholdList = () => {
    return riskThresholds.map((riskThreshold) => {
      return (
        <ThresholdListItem
          key={riskThreshold.id}
          threshold={riskThreshold}
          removeThreshold={removeThreshold}
          editThreshold={openCreateThreshold}
          showConfirmDialog={showConfirmDialog}
        />
      );
    });
  };
  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="dataFiledReference"
            handleSortChange={handleSortChange}
          >
            Data field reference
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="comparison"
            handleSortChange={handleSortChange}
          >
            Comparison
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="valueType"
            handleSortChange={handleSortChange}
          >
            Value Type
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="lowValue"
            handleSortChange={handleSortChange}
          >
            Low Value
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="highValue"
            handleSortChange={handleSortChange}
          >
            High Value
          </SortableHeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderThresholdList()}</Table.Body>
    </Table>
  );
};

ThresholdsList.propTypes = {
  riskThresholds: PropTypes.array.isRequired,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  confirmPopupActions: PropTypes.object,
  partyStateActions: PropTypes.object,
  partyActions: PropTypes.object,
};

export default PartyWrapContext(ConfirmWrapper(ThresholdsList));
