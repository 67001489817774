import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { Segment } from 'semantic-ui-react';

type Props = {
  value: string;
  onChange: (code: string) => void;
  onBlur?: () => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
};
export const CodeEditor = ({
  onChange,
  value,
  onBlur,
  onKeyUp,
  onFocus,
  onClick,
}: Props) => {
  const [code, setCode] = React.useState(
    `function add(a, b) {\n  return a + b;\n}`
  );
  return (
    <Segment>
      <Editor
        value={value}
        onValueChange={(code) => onChange(code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={5}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onClick={onClick}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
        }}
      />
    </Segment>
  );
};
