import React, { useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Button, Header } from 'semantic-ui-react';

import ExecutionPlanContext from '../../context/ExecutionPlanContext';
import PermissionsBuilder from '../common/PermissionsBuilder';
import ExecutionPlanDetailsForm from './ExecutionPlanDetailsForm';
import { checkValidation as checkValidationCommon } from '../../utils/Validation';

const ExecutionPlanSettings = ({ workflows }) => {
  const executionPlanContext = useContext(ExecutionPlanContext);
  const { executionPlan, executionPlanStateActions } = executionPlanContext;
  const {
    assignedPartyId,
    ownerPartyId,
    reviewPartyId,
    label,
    workflowId,
    granularPermissionTypeId,
    assignedPartyUserId,
    ownerPartyUserId,
    reviewPartyUserId,
  } = executionPlan;
  const formDataInitial = {
    assignedPartyId,
    ownerPartyId,
    reviewPartyId,
    label,
    workflowId,
    granularPermissionTypeId,
    assignedPartyUserId,
    ownerPartyUserId,
    reviewPartyUserId,
    relationPermissions: _.get(
      executionPlan,
      'meta.options.relationPermissions'
    ),
  };

  const [permissions, setPermissions] = useState(executionPlan.permissions);
  const [isPermissionsEdited, setIsPermissionsEdited] = useState(false);
  const [isDetailsEdited, setIsDetailsEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState(formDataInitial);
  const [validations, setValidations] = useState({ label: { isValid: true } });
  const [isFormDataValid, setIsFormDataValid] = useState(true);

  useEffect(() => {
    setIsPermissionsEdited(!_.isEqual(executionPlan.permissions, permissions));
  }, [permissions]);

  useEffect(() => {
    setIsDetailsEdited(!_.isEqual(formDataInitial, formData));
  }, [formData]);

  useEffect(() => {
    setIsFormDataValid(_.every(validations, { isValid: true }));
  }, [validations]);

  const savePermissionSettings = () => {
    setSaving(true);
    const patchObject = {
      id: executionPlan.id,
      __type: executionPlan.__type,
    };
    if (isPermissionsEdited) {
      patchObject['permissions'] = permissions;
    }
    if (isDetailsEdited) {
      const {
        label,
        workflowId,
        granularPermissionTypeId,
        assignedPartyUserId,
        ownerPartyUserId,
        reviewPartyUserId,
        relationPermissions,
      } = formData;
      if (label !== formDataInitial.label) {
        patchObject['label'] = label;
      }
      if (workflowId !== formDataInitial.workflowId) {
        patchObject['workflowId'] = workflowId;
      }
      if (
        granularPermissionTypeId !== formDataInitial.granularPermissionTypeId
      ) {
        patchObject['granularPermissionTypeId'] = granularPermissionTypeId;
      }
      if (assignedPartyUserId !== formDataInitial.assignedPartyUserId) {
        patchObject['assignedPartyUserId'] = assignedPartyUserId;
      }
      if (ownerPartyUserId !== formDataInitial.ownerPartyUserId) {
        patchObject['ownerPartyUserId'] = ownerPartyUserId;
      }
      if (reviewPartyUserId !== formDataInitial.reviewPartyUserId) {
        patchObject['reviewPartyUserId'] = reviewPartyUserId;
      }
      if (
        !_.isEqual(
          relationPermissions,
          _.get(executionPlan, 'meta.options.relationPermissions')
        )
      ) {
        patchObject['meta'] = _.cloneDeep(executionPlan.meta);
        _.set(
          patchObject,
          'meta.options.relationPermissions',
          relationPermissions
        );
      }
    }
    executionPlanStateActions
      .patchExecutionPlan(patchObject)
      .then(({ executionPlan }) => {
        setSaving(false);
        setPermissions(executionPlan.permissions);
        setIsPermissionsEdited(false);
        setIsDetailsEdited(false);
      });
  };

  const formDataChanged = (e, { name, value, checked }) => {
    setFormData({
      ...formData,
      [name]: value || checked,
    });
  };

  const handleOnFocus = (name) => {
    validations[name].hasFocus = true;
    setValidations(validations);
  };

  const handleOnBlur = (name) => {
    validations[name].hasFocus = false;
    checkValidation(name, formData[name]);
  };

  const checkValidation = (name, value) => {
    const validation = checkValidationCommon(
      { [name]: value },
      {
        [name]: {
          required: {
            error: `Please supply the ${name}`,
          },
        },
      }
    );
    validations[name].errors = validation.errors;
    validations[name].isValid = validation.valid;
    setValidations(validations);
  };

  const labelErrors =
    !validations['label'].hasFocus && !_.isEmpty(validations['label'].errors)
      ? validations['label'].errors
      : {};

  const isSaveButtonVisible =
    isPermissionsEdited || (isFormDataValid && isDetailsEdited);

  return (
    <div>
      <ExecutionPlanDetailsForm
        formData={formData}
        workflows={workflows}
        handleFormDataChanged={formDataChanged}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        labelErrors={labelErrors}
      />
      <Header as="h3">Permissions</Header>
      <PermissionsBuilder permissions={permissions} onChange={setPermissions} />
      {isSaveButtonVisible && (
        <Button
          loading={saving}
          disabled={saving}
          onClick={savePermissionSettings}
        >
          Save settings
        </Button>
      )}
    </div>
  );
};

ExecutionPlanSettings.propTypes = {
  workflows: PropTypes.array,
};

export default ExecutionPlanSettings;
