import React from 'react';
import PropTypes from 'prop-types';

import ActivityMainDetails from './ActivityMainDetails';
import ActivityOtherDetails from './ActivityOtherDetails';
import FormBuilder from '../common/FormBuilder';
import FormThresholdBuilder from '../common/FormThresholdBuilder';
import ActivityWorkflowSettings from './ActivityWorkflowSettings';
import ActivityDashboardSettings from './ActivityDashboardSettings';

let Editor;
if (typeof window !== 'undefined') {
  Editor = require('jsoneditor-react').JsonEditor;
}

const ActivityFormBody = ({
  activeItem,
  activity,
  parties,
  activityStatuses,
  currentUser,
  tags,
  activityFieldTypes,
  activityResolutionTypes,
  updateActivityState,
  addField,
  onFieldChange,
  addNewTag,
  updateResolution,
  layout,
  workflowStatusInputTypes,
}) => {
  let Component;
  switch (activeItem) {
    case 'mainDetails': {
      Component = (
        <ActivityMainDetails
          activity={activity}
          parties={parties}
          activityStatuses={activityStatuses}
          currentUser={currentUser}
          updateActivityState={updateActivityState}
          layout={layout}
        />
      );
      break;
    }
    case 'formBuilder': {
      Component = (
        <FormBuilder
          activityFieldTypes={activityFieldTypes}
          addField={addField}
          fields={activity.fields}
          onFieldChange={onFieldChange}
          isTableView={false}
          layout={layout}
          workflowStatusInputTypes={workflowStatusInputTypes}
        />
      );
      break;
    }
    case 'thresholds': {
      Component = (
        <FormThresholdBuilder
          activityFieldTypes={activityFieldTypes}
          addField={addField}
          fields={activity.fields}
          onFieldChange={onFieldChange}
          isTableView={false}
          layout={layout}
          workflowStatusInputTypes={workflowStatusInputTypes}
        />
      );
      break;
    }
    case 'otherDetails': {
      Component = (
        <ActivityOtherDetails
          tags={tags}
          activity={activity}
          updateActivityState={updateActivityState}
          addNewTag={addNewTag}
        />
      );
      break;
    }
    case 'workflowSettings': {
      Component = (
        <React.Fragment>
          <ActivityWorkflowSettings
            tags={tags}
            activity={activity}
            activityResolutionTypes={activityResolutionTypes}
            updateActivityState={updateActivityState}
            addNewTag={addNewTag}
            updateResolution={updateResolution}
          />

          <ActivityDashboardSettings
            activity={activity}
            updateActivityState={updateActivityState}
          />
        </React.Fragment>
      );
      break;
    }
    case 'Meta': {
      Component = (
        <React.Fragment>
          <Editor
            value={activity.meta}
            onChange={(value) => {
              updateActivityState(null, { name: 'meta', value });
            }}
            search={false}
            mode="text"
          />
        </React.Fragment>
      );
      break;
    }
  }
  return Component;
};

ActivityFormBody.propTypes = {
  activeItem: PropTypes.string,
  activity: PropTypes.object,
  parties: PropTypes.array,
  activityStatuses: PropTypes.array,
  currentUser: PropTypes.object,
  tags: PropTypes.array,
  activityFieldTypes: PropTypes.array,
  activityResolutionTypes: PropTypes.array,
  updateActivityState: PropTypes.func,
  addField: PropTypes.func,
  onFieldChange: PropTypes.func,
  addNewTag: PropTypes.func,
  updateResolution: PropTypes.func,
  workflowStatusInputTypes: PropTypes.array,
};

export default ActivityFormBody;
