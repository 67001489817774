import React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import UserWrapper from '../../user/User';
import * as permissionTypes from '../../../constants/permissionTypes';
import * as nodeTypes from '../../../constants/nodeTypes';
import MainMenuItem from './MainMenuItem';

import './MainMenu.scss';

const SITE_THEME = window.JSW_CONFIG.siteTheme;

const mainMenuItems = (currentUser) => [
  // { label: 'Your Dashboard', path: '/', icon: 'line graph' },
  {
    label: 'Fair Value',
    path: '/fairvalueSummary',
    icon: 'boxes icon',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Product Status',
    path: '/fairvalueProductStatus',
    icon: 'boxes icon',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Products',
    path: '/products',
    icon: 'boxes icon',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Distributor Status',
    path: '/fairvalueDistributorStatus',
    icon: 'factory icon',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Distributors',
    path: `/party/${currentUser.details.party.id}/distributors`,
    icon: 'factory icon',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'POG Overview',
    path: '/fairvaluePOGOverview',
    icon: 'pie chart',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  { label: 'Programme Status', path: '/programmeStatus', icon: 'rocket' },
  { label: 'Programme Search', path: '/executions', icon: 'list' },
  { label: 'Ticket Search', path: '/ticketsearch', icon: 'briefcase' },
  {
    label: 'Customer Cost Analysis (Target Market)',
    path: '/dashboards/4AA1E670-C4D0-11EE-A480-9D5C16990076',
    icon: 'money',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Customer Cost Analysis (Distributor)',
    path: '/dashboards/A61E39A0-E0C2-11EE-8FD8-9FC1681E0B71',
    icon: 'money',
    hidden: SITE_THEME !== 'theme-visrisk',
  },
  {
    label: 'Review Management',
    path: '/reviewManagement',
    icon: 'clipboard check',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Risk Distribution',
    path: '/riskDistribution',
    icon: 'exclamation triangle',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  { 
    label: 'Party Management', 
    path: '/parties', 
    icon: 'building' 
  },
  {
    label: 'Users',
    path: '/users',
    icon: 'users',
  },
  {
    label: 'Events',
    path: '/events',
    icon: 'bell',
  },
  {
    label: 'Thresholds',
    path: `/thresholds`,
    icon: 'balance scale',
  },
  {
    label: 'Question Bank',
    path: '/questions',
    icon: 'question circle',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Workflow Management',
    path: '/workflows',
    icon: 'exchange',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Admin Panel',
    path: '/admin',
    icon: 'cogs',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Permissions',
    path: '/permission/1',
    icon: 'tree',
    structuralNodeId: 2,
    permissionType: permissionTypes.ADMIN,
  },
  {
    label: 'Charts',
    path: '/charts',
    icon: 'pie chart',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'API Calls',
    path: '/apicalls',
    icon: 'server',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Dashboards',
    path: '/dashboards',
    icon: 'dashboard',
    structuralNodeId: 2,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Programme Planning',
    path: '/executionPlans',
    icon: 'pencil',
    permission: nodeTypes.ExecutionPlanGroup,
    permissionType: permissionTypes.EDIT,
  },
  {
    label: 'Programme Library',
    path: '/programmes',
    icon: 'book',
    permission: nodeTypes.ProgrammeGroup,
    permissionType: permissionTypes.VIEW,
  },
  {
    label: 'Metrics',
    path: '/metrics',
    icon: 'line graph ',
    permission: nodeTypes.ProgrammeGroup,
    permissionType: permissionTypes.SUPER,
  },
  {
    label: 'Metric Reports',
    path: '/metricReports',
    icon: 'tasks  ',
    permission: nodeTypes.ProgrammeGroup,
    permissionType: permissionTypes.SUPER,
  },
];

const MainMenu = ({ uiActions, isMenuExpanded, currentUser }) => {
  return (
    <Menu
      vertical
      icon="labeled"
      borderless
      fluid
      width="thin"
      className="main-menu"
      size="tiny"
    >
      <Menu.Item className="main-menu__logo" as={NavLink} to="/">
        &nbsp;
      </Menu.Item>
      {mainMenuItems(currentUser).map((item, index) => {
        return (
          <MainMenuItem
            options={item}
            key={index}
            isMenuExpanded={isMenuExpanded}
          />
        );
      })}

      {currentUser.details.party.dashboards &&
        currentUser.details.party.dashboards.length > 0 && (
          <React.Fragment>
            {currentUser.details.party.dashboards.map((dashboard, index) =>
              isMenuExpanded ? (
                <Menu.Item
                  key={`dash-${index}`}
                  as={NavLink}
                  to={`/dashboards/${dashboard.id}`}
                >
                  <Icon name="dashboard" />
                  <span>{dashboard.label}</span>
                </Menu.Item>
              ) : (
                <Popup
                  key={`dash-${index}`}
                  trigger={
                    <Menu.Item as={NavLink} to={`/dashboards/${dashboard.id}`}>
                      <Icon name="dashboard" />
                    </Menu.Item>
                  }
                  disabled
                  position="right center"
                  content={dashboard.label}
                  basic
                />
              )
            )}
          </React.Fragment>
        )}
      <Menu.Item
        className="menu-arrow"
        onClick={() => uiActions.changeMenuState(!isMenuExpanded)}
      >
        <Icon name={`angle double ${isMenuExpanded ? 'left' : 'right'}`} />
      </Menu.Item>
    </Menu>
  );
};

MainMenu.propTypes = {
  uiActions: PropTypes.object,
  currentUser: PropTypes.object,
  isMenuExpanded: PropTypes.bool,
};

export default withRouter(UserWrapper(MainMenu));
