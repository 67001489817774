import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import * as _ from 'lodash';
import { Container, Header } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SearchSort from '../common/SearchSort';

import ExecutionList from './ExecutionList';
import ExecutionWrapper from './Execution';
import UserWrapper from '../user/User';
import DashboardContainer from '../dashboard/DashboardContainer';
import useQueryString from '../../utils/hooks/useQueryString';
import { getQueryStringValue } from '../../utils/QueryString';
import useDebounce from '../../utils/hooks/useDebounce';

import * as listFiltersActions from '../../actions/listFiltersActions';

const sortOptions = [
  { key: 'label', text: 'Label' },
  { key: 'programmeLabel', text: 'Programme Type' },
  { key: 'applicableDate', text: 'Applicable Date' },
  { key: 'dueDate', text: 'Due Date' },
  { key: 'partyLabel', text: 'Assigned Party' },
  { key: 'status', text: 'Status' },
  {
    key: 'completionPercentage',
    text: 'Progress',
  },
];

const ExecutionsSearchPage = (props) => {
  const { stateFilters, history } = props;
  const { pathname } = { ...history.location };
  const { filters: reduxFilters } = { ...stateFilters[pathname] };
  const { search: locationSearch } = window.location;

  const [executions, setExecutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useQueryString(reduxFilters || {});
  const [totalExecutions, setTotalExecutions] = useState(0);
  const [executionsPerPage] = useState(15);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [isInitial, setIsInitial] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({});
  const debouncedFilters = useDebounce(filters, 300);

  const totalPages = Math.ceil(totalExecutions / executionsPerPage);

  const {
    ProgramIds,
    PartyIds,
    OwnerPartyIds,
    execitionStatuses,
    DueDateFrom,
    DueDateTo,
    ApplicableDateFrom,
    ApplicableDateTo,
    search,
    sortOrder,
    sortItem,
    page = [1],
  } = debouncedFilters;
  const [activePageString] = page;
  const activePage = Number(activePageString);

  useEffect(() => {
    checkUrlParams();
  }, []);

  useEffect(() => {
    loadExecutions(true);
  }, [
    ProgramIds,
    PartyIds,
    OwnerPartyIds,
    execitionStatuses,
    DueDateFrom,
    DueDateTo,
    ApplicableDateFrom,
    ApplicableDateTo,
    search,
    activePage,
  ]);

  useEffect(() => {
    const { listFilters } = props;
    listFilters.setFilters(pathname, { ...filters, sortItem, sortOrder });
    if (sortItem && sortItem.length > 0) {
      const direction = _.isEqual(sortOrder, ['ASC'])
        ? 'ascending'
        : 'descending';
      const [sortedBy] = sortItem;
      setPaginationOptions({ direction, sortedBy });
      if (!isInitial) {
        loadExecutions();
      }
    }
  }, [sortItem, sortOrder]);

  useEffect(() => {
    const { listFilters } = props;
    listFilters.setFilters(pathname, { ...filters, page });
  }, [activePage]);

  useEffect(() => {
    checkUrlParams();
  }, [locationSearch]);

  const checkUrlParams = () => {
    const queryStringValue = getQueryStringValue();
    if (!_.isEqual(filters, queryStringValue)) {
      const { listFilters } = props;
      setFilters(queryStringValue);
      setIsInitial(true);
      if (!isInitial) listFilters.setFilters(pathname, queryStringValue);
    }
  };

  const loadExecutions = (updateTicketCount = false) => {
    let where = {};
    let order = '';

    const { executionStateActions } = props;
    let currentActivePage = activePage;
    setLoading(true);
    setSearchValue(search || '');
    setIsInitial(false);

    if (ProgramIds && ProgramIds.length > 0)
      _.set(where, 'programmeId.inq', ProgramIds);
    if (PartyIds && PartyIds.length > 0) _.set(where, 'partyId.inq', PartyIds);
    if (OwnerPartyIds && OwnerPartyIds.length > 0)
      _.set(where, 'ownerPartyId.inq', OwnerPartyIds);
    if (execitionStatuses && execitionStatuses.length > 0)
      _.set(where, 'status.inq', execitionStatuses);
    if (DueDateFrom && DueDateTo) {
      if (DueDateFrom.length > 0 && DueDateTo.length > 0)
        _.set(where, 'dueDate.between', [DueDateFrom, DueDateTo]);
    } else {
      if (DueDateFrom && DueDateFrom.length > 0)
        _.set(where, 'dueDate.gt', DueDateFrom);
      if (DueDateTo && DueDateTo.length > 0)
        _.set(where, 'dueDate.lt', DueDateTo);
    }
    if (ApplicableDateFrom && ApplicableDateTo) {
      if (ApplicableDateFrom.length > 0 && ApplicableDateTo.length > 0)
        _.set(where, 'applicableDate.between', [
          ApplicableDateFrom,
          ApplicableDateTo,
        ]);
    } else {
      if (ApplicableDateFrom && ApplicableDateFrom.length > 0)
        _.set(where, 'applicableDate.gt', ApplicableDateFrom);
      if (ApplicableDateTo && ApplicableDateTo.length > 0)
        _.set(where, 'applicableDate.lt', ApplicableDateTo);
    }

    if (sortItem && sortItem.length > 0) {
      order = `${sortItem} ${sortOrder}`;
    }

    if (search && search.length > 0) {
      _.set(where, 'label', {
        like: `%${search}%`,
      });
    }

    const filter = {
      skip: (currentActivePage - 1) * executionsPerPage,
      limit: executionsPerPage,
      order,
      where,
    };

    const request = executionStateActions
      .loadExecutions(filter)
      .then(({ executions }) => {
        setLoading(false);
        setExecutions(executions);
        setCurrentRequest(null);
        if (!updateTicketCount) return;
        executionStateActions.loadExecutionsCount(where).then((res) => {
          setTotalExecutions(res.count);
          setIsInitial(false);
        });
      })
      .catch((error) => {
        toastr.error(error);
        setLoading(false);
        setCurrentRequest(null);
      });

    if (currentRequest) {
      currentRequest.cancel();
    }

    setCurrentRequest(request);
  };

  const handleFilterChange = (data) => {
    const { listFilters } = props;
    const newFiltersValue = { ...data, page: [1] };
    setIsInitial(false);
    setFilters(newFiltersValue);
    listFilters.setFilters(pathname, newFiltersValue);
  };

  const handlePaginationChange = (e, { activePage }) => {
    setFilters({ ...filters, page: [activePage] });
    setIsInitial(true);
  };

  const handleOrderItemClick = (e, { value }) => {
    const [order = null] = sortOrder ? [...sortOrder] : [];
    const newFilters = {
      ...filters,
      sortItem: [value],
      sortOrder: [order ? order : 'ASC'],
    };
    setFilters(newFilters);
  };

  const handleOrderFilterChange = () => {
    const [order = null] = sortOrder ? [...sortOrder] : [];
    const newSortOrder = order === 'ASC' ? 'DESC' : 'ASC';
    const newFilters = {
      ...filters,
      sortOrder: [newSortOrder],
    };
    setFilters(newFilters);
  };

  const handleSearch = (overrideSearchValue) => {
    setFilters({
      ...filters,
      search: _.isString(overrideSearchValue)
        ? overrideSearchValue
        : searchValue,
    });
  };

  const handleSearchValueChange = (e, { value }) => {
    setSearchValue(value);
  };

  const handleSortChange = (clickedColumn) => {
    const [currentItem = null] = sortItem ? [...sortItem] : [];
    const [order = null] = sortOrder ? [...sortOrder] : [];
    const otherSortOrder = order === 'ASC' ? 'DESC' : 'ASC';
    const currentOrder = currentItem === clickedColumn ? otherSortOrder : order;
    const newFilters = {
      ...filters,
      sortItem: [clickedColumn],
      sortOrder: [order ? currentOrder : 'ASC'],
    };
    setFilters(newFilters);
  };

  return (
    <Container fluid className="executions-page">
      <Header size="medium" as="h2">
        Programme Search
      </Header>
      <DashboardContainer
        id="E488AF60-DE4A-11E9-B176-3F0BCDA04FFA"
        onPropertyChange={handleFilterChange}
        initialProperties={filters}
        forceChartRefresh={false}
        dashboardProperiesCanBeUpdated={true}
      />
      <SearchSort
        sort={sortItem ? { item: sortItem, order: sortOrder } : undefined}
        handleItemClick={handleOrderItemClick}
        handleOrderFilterChange={handleOrderFilterChange}
        options={sortOptions}
        searchValue={searchValue}
        handleSearchValueChange={handleSearchValueChange}
        loading={loading}
        handleSearchClick={handleSearch}
      />

      <ExecutionList
        executions={executions || []}
        activePage={activePage}
        loading={loading}
        totalPages={totalPages}
        handlePaginationChange={handlePaginationChange}
        handleSortChange={handleSortChange}
        executionsPerPage={executionsPerPage}
        totalExecutions={totalExecutions}
        paginationOptions={paginationOptions}
      />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    stateFilters: state.listFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listFilters: bindActionCreators(listFiltersActions, dispatch),
  };
}

ExecutionsSearchPage.propTypes = {
  executionStateActions: PropTypes.object,
  stateFilters: PropTypes.object,
  listFilters: PropTypes.object,
  history: PropTypes.object,
};

export default UserWrapper(
  ExecutionWrapper(
    connect(mapStateToProps, mapDispatchToProps)(ExecutionsSearchPage)
  )
);
