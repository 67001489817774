import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import WidgetComponents from '../DashboardWidgetComponents';

const GroupComponent = (props) => {
  const {
    dataSet,
    data,
    items,
    component,
    itemWrapperComponent,
    propsFilter,
    wrapperPropsFilter,
    number,
    numberOffset = 1,
  } = props;

  let itemsToRender = [];

  if (dataSet && data) {
    itemsToRender = data.data[dataSet];
  }

  if (items) itemsToRender = items;

  let Component;
  let ItemWrapperComponent = React.Fragment;
  let wrapperComponentProps = {};
  let componentProps = props;

  if (component) {
    Component = WidgetComponents[component];
    if (propsFilter)
      componentProps = _.pick(
        props,
        propsFilter.map((pf) => pf.split('~').join(''))
      );
  }
  if (itemWrapperComponent) {
    ItemWrapperComponent = WidgetComponents[itemWrapperComponent];
    if (wrapperPropsFilter)
      wrapperComponentProps = _.pick(props, wrapperPropsFilter);
  }

  return (
    <React.Fragment>
      {Component && (
        <Component {...componentProps} number={number + numberOffset} />
      )}
      <ItemWrapperComponent {...wrapperComponentProps}>
        {itemsToRender &&
          itemsToRender.map((item, index) => {
            const args = {
              ...item,
              number: index,
              groupNumber: number + numberOffset,
            };
            delete args.ref;
            return <GroupComponent {...args} key={index} />;
          })}
      </ItemWrapperComponent>

      {/*<ReactJson src={props} collapsed={true} />*/}
    </React.Fragment>
  );
};

GroupComponent.propTypes = {
  dataSet: PropTypes.string,
  data: PropTypes.object,
  items: PropTypes.array,
  component: PropTypes.string,
  itemWrapperComponent: PropTypes.string,
  propsFilter: PropTypes.array,
  wrapperPropsFilter: PropTypes.array,
  number: PropTypes.number,
  numberOffset: PropTypes.number,
};

export default GroupComponent;
