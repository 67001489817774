import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal, Form, Input } from 'semantic-ui-react';

import Country from '../common/fields/Country';
import * as uiActions from '../../actions/uiActions';
import SearchBox from './SearchBox';
import { handleResponse } from './../../utils/GoogleMapHelper';

class SavePartyAddressModal extends Component {
  state = {
    label: '',
    saving: false,
    city: '',
    zipOrPostcode: '',
    stateProvinceCounty: '',
    countryId: '',
    line1: '',
    line2: '',
    line3: '',
    latitude: '0',
    longitude: '0',
    meta: '{}',
  };

  componentWillMount() {
    const { address } = this.props;
    if (address) {
      const {
        line1,
        line2,
        line3,
        city,
        zipOrPostcode,
        stateProvinceCounty,
        countryId,
        latitude,
        longitude,
        meta,
      } = address;
      this.setState({
        city,
        zipOrPostcode,
        stateProvinceCounty,
        countryId,
        line1,
        line2,
        line3,
        latitude,
        longitude,
        meta,
        id,
      });
    }
    const { id } = this.props.party;
    this.setState({ partyId: id });
  }

  saveAddressToParty = () => {
    const {
      partyId,
      line1,
      line2,
      line3,
      city,
      zipOrPostcode,
      stateProvinceCounty,
      countryId,
      latitude,
      longitude,
      meta,
    } = this.state;
    const partyAddress = {
      line1,
      line2,
      line3,
      city,
      zipOrPostcode,
      stateProvinceCounty,
      countryId,
      latitude,
      longitude,
      meta,
    };
    const { partyStateActions, address } = this.props;
    this.setState({ saving: true });
    partyStateActions
      .saveAddressToParty(partyId, partyAddress, address ? address.id : null)
      .then(() => {
        this.saveAddressToPartyCallback(address);
      })
      .catch((error) => this.saveAddressToPartyError(error));
  };

  saveAddressToPartyCallback = (address) => {
    const { uiActions } = this.props;
    toastr.success(
      address ? `${address.line1} address is updated` : `New address is added`
    );
    uiActions.closeModal();
  };

  saveAddressToPartyError = (error) => {
    toastr.error(error);
    this.setState({ saving: false });
    throw error;
  };

  handleInputChanged = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  onPlacesChanged = (place) => {
    this.cleanAddressFields();
    this.setLocation(place.geometry.location);
    const resp = handleResponse(place);
    resp.forEach((item) => {
      this.setState({ [item.type]: item.value });
    });
  };

  setLocation = (location) => {
    this.setState({ latitude: location.lat(), longitude: location.lng() });
  };

  cleanAddressFields = () => {
    this.setState({
      city: '',
      zipOrPostcode: '',
      stateProvinceCounty: '',
      countryId: '',
      line1: '',
      line2: '',
      line3: '',
      latitude: '0',
      longitude: '0',
      meta: '{}',
    });
  };

  render() {
    const {
      saving,
      line1,
      line2,
      line3,
      city,
      zipOrPostcode,
      stateProvinceCounty,
      countryId,
    } = this.state;
    const { uiActions, address } = this.props;

    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>
          {address ? 'Update address' : 'Add new Address'}
        </Modal.Header>
        <Modal.Content>
          {window?.JSW_CONFIG?.googleMapsKey && (
            <SearchBox
              onPlacesChanged={this.onPlacesChanged}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${window.JSW_CONFIG.googleMapsKey}&v=3.exp&libraries=geometry,drawing,places&language=en`}
              loadingElement="<div style={{ height: `100%` }} />"
              containerElement="<div style={{ height: `400px` }} />"
            />
          )}
          <Form>
            <Form.Field>
              <Input
                fluid
                placeholder="Line 1"
                value={line1}
                name="line1"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                placeholder="Line 2"
                value={line2}
                name="line2"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                placeholder="Line 3"
                value={line3}
                name="line3"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>

            <Form.Field>
              <Input
                fluid
                placeholder="City"
                value={city}
                name="city"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                placeholder="Postcode"
                value={zipOrPostcode}
                name="zipOrPostcode"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <Input
                fluid
                placeholder="Region"
                value={stateProvinceCounty}
                name="stateProvinceCounty"
                onChange={this.handleInputChanged}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <Country
                value={countryId}
                name="countryId"
                onChange={this.handleInputChanged}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button positive onClick={this.saveAddressToParty}>
            {address ? 'Update' : 'Save'}
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SavePartyAddressModal.propTypes = {
  party: PropTypes.object,
  partyStateActions: PropTypes.object,
  uiActions: PropTypes.object,
  address: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SavePartyAddressModal);
