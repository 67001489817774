import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';

const CheckBoxBool = (props) => {
  const {
    label,
    value,
    onChange,
    disabled = false,
    required,
    editable = true,
    name,
  } = props;

  if (!editable) {
    return <React.Fragment>{value.toString()}</React.Fragment>;
  }

  return (
    <Form.Field inline required={required}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Form.Group inline>
        <Form.Checkbox
          checked={value}
          onChange={(e, { checked }) => {
            onChange(e, { name, value: checked });
          }}
          disabled={disabled}
        />
      </Form.Group>
    </Form.Field>
  );
};

CheckBoxBool.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

CheckBoxBool.defaultProps = {
  value: '',
};

export default CheckBoxBool;
