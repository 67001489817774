import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const camelKeys = function (args) {
  const { dataSet, data, outputKey } = args;
  const targetData = args.data[dataSet];

  // const newData = _.zipObject(
  //   inputData.map(d => d.key),
  //   inputData.map(d => d.data)
  // );
  const outputValues = _.chain(targetData)
    .map((value) => {
      const v = _.zipObject(
        _.map(value, (v, key) => _.camelCase(key)),
        _.map(value, (v) => v)
      );
      return v;
    })
    .value();

  return { ...args, data: { ...data, [outputKey || dataSet]: outputValues } };
};

camelKeys.args = {
  dataSet: ArgTypes.string,
  outputKey: ArgTypes.string,
};

export default camelKeys;
