import * as types from '../constants/actionTypes';
import partyApi from '../api/partyApi';
import { checkValidation, transformValidation } from '../utils/Validation';
import ValidationSchema from '../utils/ValidationSchema';

export function loadParties() {
  return function (dispatch) {
    return partyApi.getParties().then((parties) => {
      dispatch({
        type: types.LOAD_PARTIES_SUCCESS,
        parties,
      });
    });
  };
}

export function loadPartiesWithRelationships() {
  return function (dispatch) {
    return partyApi
      .getParties({
        include: [
          'creator',
          { permissionsNode: 'parents' },
          'partyRelationships',
          'partyRelationshipsTo',
          { ticketSets: 'tickets' },
        ],
      })
      .then((parties) => {
        dispatch({
          type: types.LOAD_PARTIES_SUCCESS,
          parties,
        });
      });
  };
}

export function loadParty(id, filter) {
  return function (dispatch) {
    return partyApi.getParty(id, filter).then((party) => {
      return dispatch({
        type: `${types.LOAD_PARTY_SUCCESS}`,
        party,
      });
    });
  };
}

export function loadDistributorInfos(filter) {
  return function (dispatch) {
    return partyApi.getDistributorInfos(filter).then((distributorinfos) => {
      return dispatch({
        type: `${types.LOAD_DISTRIBUTOR_INFOS_SUCCESS}`,
        distributorinfos,
      });
    });
  };
}

export function saveRelationship(
  newRelationship,
  toParty,
  relationshipId = null,
  fromPartyId,
  relationshipType
) {
  const { entries } = newRelationship;
  return function (dispatch) {
    return partyApi
      .saveRelationship(fromPartyId, newRelationship, relationshipId)
      .then((relationship) => {
        return dispatch({
          type: `${types.SAVE_RELATIONSHIP_SUCCESS}`,
          relationship,
          toParty,
          relationshipType,
          entries,
        });
      });
  };
}

export function saveUserGroup(partyId, label, userGroupId = null) {
  return function (dispatch) {
    const newUserGroup = {
      label,
    };
    const validation = checkValidation(
      newUserGroup,
      ValidationSchema.createUserGroup
    );

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    if (userGroupId) {
      return partyApi
        .updateUserGroup(partyId, newUserGroup, userGroupId)
        .then((userGroup) => {
          return dispatch({
            type: `${types.UPDATE_USERGROUP_SUCCESS}`,
            userGroup,
          });
        });
    }
    return partyApi.createUserGroup(partyId, newUserGroup).then((userGroup) => {
      return dispatch({
        type: `${types.CREATE_USERGROUP_SUCCESS}`,
        userGroup,
      });
    });
  };
}

export function createParty(label, ref, structuralNodeId, creator) {
  return function (dispatch) {
    const newParty = {
      label,
      structuralNodeId,
      ref,
    };
    const validation = checkValidation(newParty, ValidationSchema.createParty);

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    return partyApi.createParty(newParty).then((party) => {
      return dispatch({
        type: `${types.CREATE_PARTY_SUCCESS}`,
        party,
        creator,
      });
    });
  };
}

export function addUserToUserGroup(userGroupId, user) {
  const { id } = user;
  return function (dispatch) {
    const addUser = {
      userId: id,
    };
    const validation = checkValidation(
      addUser,
      ValidationSchema.addUserToUserGroup
    );

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    return partyApi.addUserToUserGroup(userGroupId, id).then((response) => {
      return dispatch({
        type: `${types.ADD_USER_TO_USERGROUP_SUCCESS}`,
        response,
        user,
      });
    });
  };
}

export function removeUserFromUserGroup(userGroupId, userId) {
  return function (dispatch) {
    return partyApi
      .removeUserFromUserGroup(userGroupId, userId)
      .then((response) => {
        return dispatch({
          type: `${types.REMOVE_USER_FROM_USERGROUP_SUCCESS}`,
          response,
          userGroupId,
          userId,
        });
      });
  };
}

export function getAddresses(partyId) {
  return function (dispatch) {
    return partyApi.getAddresses(partyId).then((response) => {
      return dispatch({
        type: `${types.GET_ADDRESSES_SUCCESS}`,
        response,
      });
    });
  };
}

export function saveAddressToParty(partyId, partyAddress, addressId = null) {
  return function (dispatch) {
    const validation = checkValidation(
      partyAddress,
      ValidationSchema.saveAddressToParty
    );

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    return partyApi
      .saveAddressToParty(partyId, partyAddress, addressId)
      .then((address) => {
        return dispatch({
          type: `${types.SAVE_ADDRESS_TO_PARTY_SUCCESS}`,
          address,
        });
      });
  };
}

export function deleteRelationshipFromParty(partyId, relationshipId) {
  return function (dispatch) {
    return partyApi
      .deleteRelationshipFromParty(partyId, relationshipId)
      .then(() => {
        return dispatch({
          type: `${types.DELETE_RELATIONSHIP_FROM_PARTY_SUCCESS}`,
          relationshipId,
        });
      });
  };
}

export function deleteUserGroup(partyId, userGroupId) {
  return function (dispatch) {
    return partyApi.deleteUserGroup(partyId, userGroupId).then((response) => {
      return dispatch({
        type: `${types.DELETE_USERGROUP_SUCCESS}`,
        response,
        userGroupId,
      });
    });
  };
}

export function deleteAddress(partyId, addressId) {
  return function (dispatch) {
    return partyApi.deleteAddress(partyId, addressId).then(() => {
      return dispatch({
        type: `${types.DELETE_ADDRESS_SUCCESS}`,
        addressId,
      });
    });
  };
}

export function deleteAllAddresses(partyId) {
  return function (dispatch) {
    return partyApi.deleteAllAddresses(partyId).then(() => {
      return dispatch({
        type: `${types.DELETE_ALL_ADDRESSES_SUCCESS}`,
      });
    });
  };
}

export function uploadImage(files, imageType) {
  return {
    type: `${types.UPLOAD_PARTY_IMAGE}`,
    files,
    imageType,
  };
}

export function saveThreshold(newThreshold, partyId, thresholdId) {
  return function (dispatch) {
    return partyApi
      .saveThreshold(partyId, newThreshold, thresholdId)
      .then((threshold) => {
        return dispatch({
          type: `${types.SAVE_THRESHOLD_SUCCESS}`,
          threshold,
        });
      });
  };
}

export function removeThreshold(thresholdId, partyId) {
  return function (dispatch) {
    return partyApi.removeThreshold(thresholdId, partyId).then(() => {
      return dispatch({
        type: `${types.REMOVE_THRESHOLD_SUCCESS}`,
        thresholdId,
      });
    });
  };
}

export function addDashboard(partyId, dashboardId) {
  return function (dispatch) {
    return partyApi.addDashboard(partyId, dashboardId).then(() => {
      dispatch(
        loadParty(partyId, {
          include: ['dashboards'],
        })
      );
    });
  };
}
export function removeDashboard(partyId, dashboardId) {
  return function (dispatch) {
    return partyApi.removeDashboard(partyId, dashboardId).then(() => {
      dispatch(
        loadParty(partyId, {
          include: ['dashboards'],
        })
      );
    });
  };
}

export function deactivate(partyId, additionalEvent) {
  return function (dispatch) {
    return partyApi.deactivate(partyId, additionalEvent).then((party) => {
      return dispatch({
        type: `${types.UPDATE_PARTY_SUCCESS}`,
        party,
      });
    });
  };
}

export function reactivate(partyId) {
  return function (dispatch) {
    return partyApi.reactivate(partyId).then((party) => {
      return dispatch({
        type: `${types.UPDATE_PARTY_SUCCESS}`,
        party,
      });
    });
  };
}
