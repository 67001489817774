import { useEffect, useReducer, useState } from 'react';
import ActivityAccordion from './ActivityList';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { activityReducer } from './activityReducer';
import { Activity, Programme, Field } from '../../../types';
import { Button, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import * as toastr from 'toastr';

import programmeApi from '../../../api/programmeApi';
import activityApi from '../../../api/activityApi';
import ActivityViewer from './Activity';

import './ProgrammeFieldManager.scss';

export interface ProgrammeField {
  id: string;
  active: boolean;
  fields: Field[];
}

interface ProgrammeFieldManagerProps {
  programme: Programme;
}

const sortProgrammeFields = (programme: Programme): Programme => {
  return {
    ...programme,
    activitySets: programme.activitySets?.map((activitySet) => ({
      ...activitySet,
      activities: activitySet.activities?.map((activity) => ({
        ...activity,
        fields: activity.fields?.sort((a, b) => a.sort - b.sort),
      })),
    })),
  };
};

export const ProgrammeFieldManager = ({
  programme,
}: ProgrammeFieldManagerProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useReducer(activityReducer, {
    programme: sortProgrammeFields(cloneDeep(programme)),
    activeAccordions: [],
    activitySaveQueue: [],
    selectedActivityIndex: 0,
  });

  useEffect(() => {
    console.log(state);
  }, [state.programme]);

  useEffect(() => {
    if (state.activitySaveQueue.length === 0) return;

    const saveActivity = async () => {
      const activityToSave = state.activitySaveQueue[0];
      setIsSaving(true);
      const result: Field[] = await activityApi.bulkUpdateFields(
        activityToSave.fields
      );
      setIsSaving(false);
      dispatch({
        type: 'ACTIVITY_SAVE_SUCCESS',
        payload: {
          activity: {
            ...activityToSave,
            fields: result.sort((a, b) => a.sort - b.sort),
          },
        },
      });
    };
    saveActivity();
  }, [state.activitySaveQueue.length]);

  const onDragEnd = (result: DropResult) => {
    if (state.activitySaveQueue.length > 0) return;
    const { source, destination, type } = result;

    // return;
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    )
      return;

    switch (type) {
      case 'field':
        dispatch({
          type: 'MOVE_FIELD',
          payload: {
            fromActivityId: source.droppableId,
            toActivityId: destination.droppableId.split('l-').join(''),
            fromIndex: source.index,
            toIndex: destination.index,
          },
        });
        break;
      case 'activity':
        dispatch({
          type: 'MOVE_ACTIVITY',
          payload: {
            fromIndex: source.index,
            toIndex: destination.index,
          },
        });
        break;

      default:
        break;
    }

    if (type === 'field') {
    } else if (type === 'activity') {
    }
  };

  const activities: Activity[] = state.programme.activitySets.reduce(
    (acc, activitySet) => [...acc, ...activitySet.activities],
    []
  );
  const uniqueActivities = activities.filter(
    (activity, index, self) =>
      index === self.findIndex((a) => a.id === activity.id)
  );

  const handleSelectActivity = (activityIndex: number) => {
    dispatch({
      type: 'SELECT_ACTIVITY',
      payload: {
        activityIndex,
      },
    });
  };

  const handleDeleteField = async (
    activity: Activity,
    field: Field,
    fieldIndex: number
  ) => {
    try {
      await activityApi.deleteField(field);
      dispatch({
        type: 'DELETE_FIELD',
        payload: {
          activityId: activity.id,
          fieldIndex,
        },
      });
    } catch (error) {
      toastr.error('An error occured while deleting the field');
      console.log(error);
    }
  };

  const selectedActivity = uniqueActivities[state.selectedActivityIndex];

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="programme-field-manager">
          <Dimmer active={isSaving} inverted>
            <Loader />
          </Dimmer>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <ActivityAccordion
                  activities={uniqueActivities}
                  onActivitySelect={handleSelectActivity}
                  selectedActivityIndex={state.selectedActivityIndex}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                {selectedActivity && (
                  <ActivityViewer
                    activity={selectedActivity}
                    onDeleted={handleDeleteField}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </DragDropContext>
    </>
  );
};
