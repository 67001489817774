import * as types from '../constants/actionTypes';

export function setCreateActivityAction(action) {
  return {
    type: types.SET_CREATE_ACTIVITY_ACTION,
    action,
  };
}

export function registerUIState(componentName, initialState) {
  return {
    type: types.REGISTER_UI_STATE,
    componentName,
    initialState,
  };
}

export function changeUIState(uiState, key, value) {
  return {
    type: types.CHANGE_UI_STATE,
    uiState,
    key,
    value,
  };
}

export function showModal(modalOptions) {
  return {
    type: types.SHOW_MODAL,
    modalOptions,
  };
}

export function closeModal() {
  return {
    type: types.CLOSE_MODAL,
  };
}

export function changeMenuState(isMenuExpanded) {
  return {
    type: types.EXPAND_MENU,
    isMenuExpanded,
  };
}

export function showSidebar(sidebarOptions) {
  return {
    type: types.SHOW_SIDEBAR,
    sidebarOptions,
  };
}

export function closeSidebar() {
  return {
    type: types.CLOSE_SIDEBAR,
  };
}

export function showContextMenu(contextMenuOptions) {
  return {
    type: types.SHOW_CONTEXT_MENU,
    contextMenuOptions,
  };
}

export function closeContextMenu() {
  return {
    type: types.CLOSE_CONTEXT_MENU,
  };
}
export function dispatchAction(action, args) {
  return {
    type: action,
    ...args,
  };
}
