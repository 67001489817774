import React from 'react';
import * as PropTypes from 'prop-types';

import FilterAndPaginate from '../common/FilterAndPaginate';
import Pagination from '../common/Pagination';
import TicketsList from './TicketsList';

const TicketsSidebarList = (props) => {
  const {
    paginatedItems,
    handleSortChange,
    handlePaginationChange,
    paginationOptions,
    executionLabel,
    showResolutions = true,
    callConfig,
  } = props;
  return (
    <React.Fragment>
      <TicketsList
        tickets={paginatedItems || []}
        paginationOptions={paginationOptions}
        handleSortChange={handleSortChange}
        executionLabel={executionLabel}
        showResolutions={showResolutions}
        relevantEntries={callConfig.relevantEntries}
        exceptionIds={callConfig.exceptionIds}
      />
      <Pagination
        activePage={paginationOptions.activePage}
        onPageChange={handlePaginationChange}
        totalPages={paginationOptions.totalPages}
        style={{ float: 'center' }}
        className="sidebar-pagination"
      />
    </React.Fragment>
  );
};

TicketsSidebarList.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.array.isRequired,
  paginationOptions: PropTypes.object,
  callConfig: PropTypes.object,
  paginatedItems: PropTypes.array,
  handleSortChange: PropTypes.func,
  handlePaginationChange: PropTypes.func,
  executionLabel: PropTypes.string,
  showResolutions: PropTypes.bool,
};

function mapOptions(props) {
  return {
    disableFilterPersist: true,
    items: props.items,
  };
}

export default FilterAndPaginate(mapOptions)(TicketsSidebarList);
