import { useEffect, useState } from 'react';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import toastr from 'toastr';
import { Entity } from '../../../types';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import SaveEntityModalForm, { EntityFormData } from './SaveEntityModalForm';
import { checkModalFormDataValid } from '../../../utils/Validation';
import entityApi from '../../../api/entityApi';
import { findIndex } from 'lodash';
import { useAppSelector } from '../../../actions/store';
import { loadParty } from '../../../actions/partyActions';
import { partyRelationshipsFilter } from './CreateEntityModal';

interface Props {
  entityId: string;
  type: string;
}

const SaveEntityModal = ({ entityId, type }: Props) => {
  const dispatch = useDispatch();
  const [entity, setEntity] = useState<Entity>();
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState<EntityFormData>({
    label: entity?.label,
    entries: entity?.entries,
    launchExecution: false,
    executionPlanId: null,
  });

  const party = useAppSelector((state) => state.currentUser.details.party);

  useEffect(() => {
    dispatch(loadParty(party.id, partyRelationshipsFilter));
  }, []);

  useEffect(() => {
    if (entity || !entityId) return;

    const fetchEntity = async () => {
      const result = await entityApi.getEntity(entityId);
      setEntity(result);
      console.log(result);
      setFormData({
        label: result.label,
        entries: result.entries,
        launchExecution: false,
        executionPlanId: null,
      });
    };

    fetchEntity();
  }, []);

  const [validations, setValidations] = useState({
    label: {},
  });

  const checkValidation = (name, value) => {
    const modalFormDataValid = checkModalFormDataValid(
      validations,
      name,
      value,
      {
        toPartyId: {},
      }
    );
    return modalFormDataValid;
  };

  const handleSaveEntity = async () => {
    try {
      setSaving(true);
      await entityApi.updateEntity(entity, formData);
      toastr.success('Entity Saved');
      setSaving(false);
    } catch (error) {
      toastr.error(error);
      setSaving(false);
    }
  };

  const handlefieldChange = ({ name, value, checked }) => {
    console.log({ name, value });
    const index = findIndex(formData.entries, {
      entityTypeFieldId: name,
    });
    if (index !== -1) {
      formData.entries[index].value = value;
      setFormData({ ...formData, entries: formData.entries });
    } else {
      const newValue = {
        entityTypeFieldId: name,
        value: value,
      };
      setFormData({ ...formData, entries: [...formData.entries, newValue] });
    }
  };
  const handleInputChanged = (event, { name, value, checked }) => {
    setFormData({
      ...formData,
      [name]: value || checked,
    });
  };

  const handleOnFocus = (name) => {
    // setState(prevState => ({
    //   validations: {
    //     ...prevState.validations,
    //     [name]: { ...prevState.validations[name], hasFocus: true }
    //   }
    // }));
  };

  const handleOnBlur = (name) => {
    // const { validations, isModalFormDataValid } = checkValidation(
    //   name,
    //   state.formData[name]
    // );
    // setState({
    //   validations: {
    //     ...validations,
    //     [name]: { ...validations[name], hasFocus: false }
    //   },
    //   isModalFormDataValid
    // });
  };

  const handleClose = () => {
    dispatch(uiActions.closeModal());
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      size="small"
      closeOnDimmerClick={false}
    >
      <Dimmer active={saving} inverted>
        <Loader disabled={!saving} />
      </Dimmer>
      <Modal.Header>
        {entity ? `Update ${entity.label}` : 'Create new entity'}
      </Modal.Header>
      <Modal.Content>
        {entity && (
          <SaveEntityModalForm
            // entityTypes={entityTypes}
            // partyStateActions={partyStateActions}
            formData={formData}
            handleInputChanged={handleInputChanged}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
            type={entity?.entityTypeId || type}
            // getCurrentEntityFields={getCurrentEntityFields}
            onFieldChange={handlefieldChange}
            // onEntityTypeChange={onEntityTypeChange}
            // validations={validations}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          positive
          onClick={handleSaveEntity}
          // disabled={
          //   !isEntityFieldsValid ||
          //   !isModalFormDataValid ||
          //   (isAllowBudget && !isBudgetFieldsValid)
          // }
        >
          {entity ? 'Update' : 'Save'}
        </Button>
        <Button secondary onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SaveEntityModal;
