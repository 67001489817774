import React from 'react';
import * as _ from 'lodash';
import { Table } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { complexityLevels, severityLevels } from '../../../../constants/config';

const AnalyseAndImplement = (props) => {
  const { value, showSeverityComplexity = true } = props;
  if (!value)
    return (
      <React.Fragment>
        {showSeverityComplexity && <Table.Cell />}
        {showSeverityComplexity && <Table.Cell />}
        <Table.Cell />
        <Table.Cell />
      </React.Fragment>
    );
  const valueParsed = JSON.parse(value);
  const { analysisCompletionDate, implementationDate } = valueParsed;
  const severity = _.chain(severityLevels)
    .find({ id: valueParsed.severity })
    .get('label')
    .value();
  const complexity = _.chain(complexityLevels)
    .find({ id: valueParsed.complexity })
    .get('label')
    .value();

  return (
    <React.Fragment>
      {showSeverityComplexity && (
        <Table.Cell valign="top">{severity}</Table.Cell>
      )}
      <Table.Cell valign="top">
        <Moment format="DD/MM/YYYY">{analysisCompletionDate}</Moment>
      </Table.Cell>
      {showSeverityComplexity && (
        <Table.Cell valign="top">{complexity}</Table.Cell>
      )}
      <Table.Cell valign="top">
        <Moment format="DD/MM/YYYY">{implementationDate}</Moment>
      </Table.Cell>
    </React.Fragment>
  );
};

AnalyseAndImplement.propTypes = {
  value: PropTypes.string,
  showSeverityComplexity: PropTypes.bool,
};

export default AnalyseAndImplement;
