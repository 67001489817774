import apiTools from './apiTools';
const isDev = process.env.NODE_ENV !== 'production';

class ErrorApi {
  static getErrors() {
    const filter = {
      order: 'label ASC',
    };
    return apiTools.get('Errors', { filter }).then((response) => {
      return response.data;
    });
  }

  static saveError(error) {
    if (!error || isDev) return;
    const data = {
      stack: error.stack,
      message: error.message,
      name: error.name,
      userAgent: window.navigator.userAgent,
      url: window.location.href,
    };
    return apiTools
      .post(`Errors/log`, {
        type: 'JS',
        data,
      })
      .catch(() => {});
  }
}

export default ErrorApi;
