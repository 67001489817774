import React from 'react';
import Moment from 'react-moment';
import * as _ from 'lodash';

const ProCover = (props) => {
  const executionInfo = _.get(props, 'data.data.executionInfo.0');
  const entityInfo = _.get(props, 'data.data.entityInfo');

  if (!executionInfo || !entityInfo) return null;

  const onBehalf = _.chain(executionInfo.entries)
    .find({ field: { fieldKey: 'OBO' } })
    .get('value')
    .value();

  const umr = _.chain(entityInfo)
    .find({ entriesFieldId: 'BND-UMR' })
    .get('entriesValue')
    .value();

  const today = new Date();

  return (
    <div className="pro-cover">
      <h3>
        Coverholder Review of {executionInfo.party.label} on Behalf of{' '}
        {onBehalf} - {umr}
      </h3>
      <h4>Click the button above to export the report</h4>
      {/*<h3><Moment format="MMM YYYY">{today}</Moment></h3>*/}
    </div>
  );
};
export default ProCover;
