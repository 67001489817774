/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */

import React, { Component, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Form, Header } from 'semantic-ui-react';
import jsonLogic from 'json-logic-js';
import Moment from 'react-moment';

import FormField from './FormField';
import { FieldAuthor } from './FormFieldAuthor';
import { checkValidation } from '../../utils/Validation';
import { defaultDateTimeFormat } from '../../constants/config';
Moment.globalFormat = defaultDateTimeFormat;

const FormFields = (props) => {
  const [stateFields, setStateFields] = useState([]);
  const { enableDisplayLogic = true, showFieldKeys } = props;

  // state = {
  //   fields: {},
  // };
  // componentDidMount() {
  //   const { entries, fields } = props;
  //   if (!_.isEmpty(entries) || fields.length > 0) setFields();
  // }
  // componentDidUpdate(prevProps) {
  //   const { fields } = props;
  //   if (prevProps.fields.length !== fields.length) {
  //     setFields();
  //   } else {
  //     const objectsIsEqual = getIsFieldsObjectsEqual(prevProps);
  //     !objectsIsEqual && setFields();
  //   }
  // }

  useEffect(() => {
    const { entries, fields } = props;
    if (!_.isEmpty(entries) || fields.length > 0)
      setStateFields(generateFields());
  }, []);

  useEffect(() => {
    const { entries, fields } = props;
    if (!_.isEmpty(entries) || fields.length > 0)
      setStateFields(generateFields());
  }, [props.entries, showFieldKeys]);

  // const setFields = () => {
  //   const fields = generateFields();
  //   setState({ fields });
  // };
  const handleFieldDataChange = (field, value) => {
    const updatedField = updateFieldValue(field, value);
    if (field.required) {
      validate(updatedField);
    } else {
      const { onChange } = props;
      onChange(updatedField, true);
    }
  };
  const updateFieldValue = (field, value) => {
    field.value = value !== undefined ? value : '';
    return field;
  };
  const handleFieldOnFocus = (index) => {
    // const { fields } = state;
    stateFields[index].hasFocus = true;
    setStateFields(stateFields);
  };
  const handleFieldOnBlur = (index) => {
    // const { fields } = state;
    stateFields[index].hasFocus = false;
    // setState({ fields }, () => {
    //   const field = fields[index];
    //   if (field.required) {
    //     validate(field);
    //   }
    // });
    setStateFields(stateFields);
    const field = stateFields[index];
    if (field.required) {
      validate(field);
    }
  };
  const generateFields = () => {
    const { fields, parentId, entries } = props;
    const values = _.keyBy(
      entries.filter((entry) => !!entry.field?.fieldKey),
      function (entry) {
        return entry.field?.fieldKey;
      }
    );

    const filteredFields = enableDisplayLogic
      ? fields.filter((field) => {
          if (field.displayLogic) {
            const logicValue = jsonLogic.apply(field.displayLogic, values);

            return logicValue;
          }
          return true;
        })
      : fields;

    const hydratedFields = _.map(filteredFields, (field, index) => {
      const { id, label, activityFieldType, options, fieldKey } = field;
      let optionsCurrent = options;
      if (options?.values) optionsCurrent = options.values;
      // if (field.activityFieldTypeId !== 'AnalyseAndImplement') {
      //   optionsCurrent = options ? options.values : null;
      // }

      return {
        index,
        name: id,
        hasFocus: false,
        errors: {},
        isValid: getIsValid(field),
        value: getEntryValue(id),
        type: activityFieldType,
        key: id,
        ...field,

        label: showFieldKeys ? `${label} (${fieldKey})` : label,
        options: optionsCurrent,
        parentId,
      };
    });

    if (props.onFormValidation) {
      const requiredFields = _.filter(hydratedFields, { required: true });
      props.onFormValidation(_.every(requiredFields, { isValid: true }));
    }
    return hydratedFields;
  };
  const getIsValid = (field) => {
    const { entries } = props;
    const { id, required } = field;
    if (!entries.length) return !required;
    return !!getEntryValue(id);
  };
  const getEntryValue = (id) => {
    const { entries, fieldIdKey, workflowFieldIdKey } = props;
    if (!entries.length) return '';
    const isSimpleField = _.keys(entries[0]).includes(fieldIdKey);
    const searchField = isSimpleField ? fieldIdKey : workflowFieldIdKey;
    const index = _.findIndex(entries, {
      [searchField]: id,
    });
    return index == -1 ? '' : entries[index].value;
  };
  const validate = (field) => {
    // let { fields } = state;
    const fieldName = field.name;
    const fieldIndex = field.index;
    const validation = checkValidation(
      { [fieldName]: stateFields[fieldIndex].value },
      {
        [fieldName]: {
          required: field.required && {
            error: `Please supply the ${field.label}`,
          },
        },
      }
    );
    stateFields[fieldIndex].errors = validation.errors;
    stateFields[fieldIndex].isValid = validation.valid;
    // setState({ ...fields }, () => {
    //   const { onChange } = props;
    //   const requiredFields = _.filter(fields, { required: true });
    //   onChange(field, _.every(requiredFields, { isValid: true }));
    // });
    setStateFields(stateFields);

    const { onChange } = props;
    const requiredFields = _.filter(stateFields, { required: true });
    onChange(field, _.every(requiredFields, { isValid: true }));
  };
  const getIsFieldsObjectsEqual = (prevProps) => {
    const isEqualFields = _.isEqual(prevProps.fields, props.fields);
    const isEqualEntries = _.isEqual(prevProps.entries, props.entries);
    return isEqualFields && isEqualEntries;
  };
  const renderColumnFields = (fieldsArray) => {
    const { fieldsPerRow = 1 } = props;
    let result = [];
    for (let i = 0; i < fieldsArray.length / fieldsPerRow; i++) {
      const renderFields = _.slice(
        fieldsArray,
        i * fieldsPerRow,
        i * fieldsPerRow + fieldsPerRow
      );
      result.push(renderRowFields(renderFields, i));
    }
    return _.forEach(result, (item) => item);
  };

  const renderRowFields = (fieldsArray, key) => {
    return (
      <Form.Group
        widths={`equal ${fieldsArray.length === 1 ? 'full-width' : ''}`}
        key={key}
      >
        {renderFields(fieldsArray)}
      </Form.Group>
    );
  };

  const renderDescription = (entry) => {
    if (entry && entry.modifiedBy) {
      return <FieldAuthor user={entry.modifiedBy} modifiedDate={entry.modifiedAt} />;
    }
  };

  const renderFields = (fieldsArray) => {
    return _.map(fieldsArray, (field) => {
      const {
        dataEntryAllowed = true,
        editable = true,
        exceptionFields,
        highlightRequired,
      } = props;
      const fieldObj = field.field;

      const { hasFocus, errors, index } = fieldObj;
      const { entries } = props;
      const { key } = field;
      const isField = entries ? _.get(_.first(entries), 'field') : null;
      let entry = null;
      if (entries) {
        entry = isField
          ? _.find(entries, (entry) => entry.field.id === key)
          : _.find(
              entries,
              (entry) => entry.partyRelationshipTypeFieldId === key
            );
      }
      const className =
        isFullWidthField(fieldObj.activityFieldTypeId) || props.allFullWidth
          ? 'full-width'
          : '';

      const hasException =
        (highlightRequired && fieldObj.required && !fieldObj.isValid) ||
        (exceptionFields && exceptionFields.indexOf(fieldObj.fieldKey) !== -1);

      return (
        fieldObj && (
          <div
            className={`form-field ${className} ${
              hasException ? '--has-exception' : ''
            }`}
            key={index}
          >
            {!editable &&
              fieldObj.activityFieldTypeId !== 'FormSectionHeader' && (
                <Header as="h4">{fieldObj.label}</Header>
              )}
            <FormField
              hasException={hasException}
              key={index}
              field={fieldObj}
              errors={hasFocus ? {} : errors}
              handleFieldOnFocus={() => handleFieldOnFocus(index)}
              handleFieldOnBlur={(event, value) =>
                handleFieldOnBlur(index, value)
              }
              handleFieldDataChange={handleFieldDataChange}
              editable={editable}
              disabled={!dataEntryAllowed}
              {...fieldObj}
            />
            {renderDescription(entry)}
          </div>
        )
      );
    });
  };

  const isFullWidthField = (activityFieldTypeId) => {
    const fullWidthFieldTypes = [
      'RichTextArea',
      'TextArea',
      'AnalyseAndImplement',
      'FormSectionHeader',
      'YesNo',
      'Threshold',
      ...(props.fullWidthFieldTypes || []),
    ];
    return _.includes(fullWidthFieldTypes, activityFieldTypeId);
  };

  const { fieldsPerRow } = props;
  // const { fields } = state;
  const fieldsArray = _.map(stateFields, (field) => {
    return { field, key: field.id };
  });
  return (
    <React.Fragment>
      {fieldsPerRow
        ? renderColumnFields(fieldsArray)
        : renderFields(fieldsArray)}
    </React.Fragment>
  );
};

FormFields.propTypes = {
  fields: PropTypes.array,
  handleFieldsDataChange: PropTypes.func,
  entries: PropTypes.array,
  partyRelationshipTypeId: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onFormValidation: PropTypes.func,
  fieldIdKey: PropTypes.string,
  workflowFieldIdKey: PropTypes.string,
  fieldsPerRow: PropTypes.number,
  highlightRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  enableDisplayLogic: PropTypes.bool,
  dataEntryAllowed: PropTypes.bool,
  editable: PropTypes.bool,
  showFieldKeys: PropTypes.bool,
  fullWidthFieldTypes: PropTypes.array,
  exceptionFields: PropTypes.array,
  parentId: PropTypes.number,
};

export default FormFields;
