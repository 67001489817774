import { orderBy, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  Breadcrumb,
  Button,
  Header,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';

import * as toastr from 'toastr';

import { loadProgrammes, loadProgramme } from '../../actions/programmeActions';
import programmeApi from '../../api/programmeApi';
import activityApi from '../../api/activityApi';
import { useAppSelector } from '../../actions/store';
import * as uiActions from '../../actions/uiActions';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import ActionButtons from '../common/ActionButtons';
import { Activity, ActivitySet, Category, Field, Programme } from '../../types';

import './FieldSelector.scss';
import { FieldForm } from './FieldForm';

type Props = {
  onSelect: (field: Field | Field[]) => void;
  addedFields?: string[];
};
export const FieldSelector = ({ onSelect, addedFields }: Props) => {
  const dispatch = useDispatch();
  const [selectedProgramme, setSelectedProgramme] = useState<Programme>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedActivitySet, setSelectedActivitySet] = useState<ActivitySet>();
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [loadedProgramme, setLoadedProgramme] = useState<Programme>();
  const [loadedActivity, setLoadedActivity] = useState<Activity>();
  const [isSaving, setIsSaving] = useState(false);
  const [showFieldForm, setShowFieldForm] = useState(false);

  const programmes = useAppSelector((state) =>
    sortBy(state.programmes, 'label')
  );

  const fetchProgrammes = async () => {
    dispatch(loadProgrammes());
  };

  useEffect(() => {
    fetchProgrammes();
  }, []);

  useEffect(() => {
    if (!selectedProgramme) return;

    const fetchProgramme = async () => {
      const programme = await programmeApi.getProgramme(selectedProgramme.id);
      setLoadedProgramme(programme);
    };
    fetchProgramme();
    // dispatch(loadProgramme(selectedProgramme.id, "FIELD_SELECTOR"));
  }, [selectedProgramme?.id]);

  const fetchActivity = async () => {
    const activity = await activityApi.getActivity(selectedActivity.id);
    setLoadedActivity(activity);
  };

  useEffect(() => {
    if (!selectedActivity) return;

    fetchActivity();
    // dispatch(loadProgramme(selectedProgramme.id, "FIELD_SELECTOR"));
  }, [selectedActivity?.id]);

  const handleEditActivity = (activityId: string) => {
    dispatch(
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
        modalProps: {
          activityId,
        },
      })
    );
  };

  const handleReset = () => {
    setSelectedProgramme(null);
  };

  const handleAddAllFields = async (fields: Field[]) => {
    console.log(fields);
  };

  const handleAddField = async (field: Field) => {
    console.log('handleAddField', field);

    setIsSaving(true);

    try {
      const sort = loadedActivity.fields.length + 1;
      const result = await activityApi.createField(loadedActivity.id, {
        ...field,
        sort,
      });
      console.log(result);
      onSelect(result);
      toastr.success('Field Added');
      fetchActivity();
      setIsSaving(false);
    } catch (error) {
      toastr.error(error.message);
      setIsSaving(false);
    }
  };

  return (
    <Segment className="field-selector">
      <Breadcrumb>
        <Breadcrumb.Section
          active={!!selectedProgramme}
          link={false}
          onClick={() => {
            setSelectedProgramme(null);
            setSelectedCategory(null);
            setSelectedActivitySet(null);
            setSelectedActivity(null);
          }}
        >
          Programmes
        </Breadcrumb.Section>

        {selectedProgramme && (
          <>
            <Breadcrumb.Divider />
            <Breadcrumb.Section
              link={!!selectedCategory}
              onClick={() => {
                setSelectedCategory(null);
                setSelectedActivitySet(null);
                setSelectedActivity(null);
              }}
            >
              {selectedProgramme?.ref || selectedProgramme?.label}
            </Breadcrumb.Section>
          </>
        )}
        {selectedCategory && (
          <>
            <Breadcrumb.Divider />
            <Breadcrumb.Section
              link={!!selectedActivitySet}
              onClick={() => {
                setSelectedActivitySet(null);
                setSelectedActivity(null);
              }}
            >
              {selectedCategory.label}
            </Breadcrumb.Section>
          </>
        )}
        {selectedActivitySet && (
          <>
            <Breadcrumb.Divider />
            <Breadcrumb.Section
              link={!!selectedActivity}
              onClick={() => {
                setSelectedActivity(null);
              }}
            >
              {selectedActivitySet.ref || selectedActivitySet.label}
            </Breadcrumb.Section>
          </>
        )}
        {selectedActivity && (
          <>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              {selectedActivity.ref || selectedActivity.label}
            </Breadcrumb.Section>
          </>
        )}
      </Breadcrumb>

      {!selectedProgramme && (
        <List>
          {programmes?.map((prog) => (
            <List.Item>
              <List.Header as="a" onClick={() => setSelectedProgramme(prog)}>
                {prog.label}
              </List.Header>
              <p>{prog.ref}</p>
            </List.Item>
          ))}
        </List>
      )}

      {selectedProgramme && loadedProgramme && !selectedCategory && (
        <>
          <Header>Categories</Header>
          <List>
            {loadedProgramme.categories?.map((cat) => (
              <List.Item as="a" onClick={() => setSelectedCategory(cat)}>
                {cat.label}
              </List.Item>
            ))}
          </List>
        </>
      )}

      {loadedProgramme && selectedCategory && !selectedActivitySet && (
        <>
          <Header>Question Sets</Header>
          <List>
            {loadedProgramme.activitySets
              ?.filter((as) => as.categoryId === selectedCategory.id)
              .map((as) => (
                <List.Item>
                  <List.Header
                    as="a"
                    onClick={() => setSelectedActivitySet(as)}
                  >
                    {as.label}
                  </List.Header>
                  <p>{as.ref}</p>
                </List.Item>
              ))}
          </List>
        </>
      )}
      {loadedProgramme &&
        selectedCategory &&
        selectedActivitySet &&
        !selectedActivity && (
          <>
            <Header>Questions</Header>
            <List>
              {selectedActivitySet.activities?.map((activity) => (
                <List.Item>
                  <List.Content className="field-selector-row">
                    <div>
                      <List.Header
                        as="a"
                        onClick={() => setSelectedActivity(activity)}
                      >
                        {activity.label}
                      </List.Header>
                      <p>{activity.ref}</p>
                    </div>
                    <ActionButtons
                      editClicked={() => handleEditActivity(activity.id)}
                    />
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </>
        )}
      {loadedProgramme &&
        selectedCategory &&
        selectedActivitySet &&
        selectedActivity &&
        loadedActivity && (
          <>
            <Header>Fields</Header>
            <Button.Group>
              <Button
                size="tiny"
                onClick={() => handleEditActivity(loadedActivity.id)}
              >
                Edit this question
              </Button>
            </Button.Group>
            <Button.Group>
              <Button
                size="tiny"
                onClick={() => onSelect(loadedActivity.fields)}
              >
                Add All Fields
              </Button>
            </Button.Group>

            <Accordion>
              <Accordion.Title
                active={showFieldForm}
                index={0}
                onClick={() => setShowFieldForm(!showFieldForm)}
              >
                <Icon name="dropdown" />
                Add a new field
              </Accordion.Title>
              <Accordion.Content active={showFieldForm}>
                <Segment basic>
                  <FieldForm onSubmit={handleAddField} isSaving={isSaving} />
                </Segment>
              </Accordion.Content>
            </Accordion>
            <List>
              {loadedActivity.fields &&
                orderBy(loadedActivity.fields, 'sort').map((field) =>
                  field.activityFieldTypeId === 'FormSectionHeader' ? null : (
                    <List.Item>
                      <List.Header>
                        {field.label} ( {field.activityFieldTypeId}){' '}
                        {addedFields?.includes(field.fieldKey) ? (
                          <Icon name="check" />
                        ) : (
                          <a>
                            <Icon name="plus" onClick={() => onSelect(field)} />{' '}
                          </a>
                        )}
                      </List.Header>
                      <p>{field.fieldKey}</p>
                    </List.Item>
                  )
                )}
            </List>
          </>
        )}
    </Segment>
  );
};
