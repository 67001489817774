import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Segment, Table } from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

import UploadProgressListRow from './UploadProgressListRow';
import './UploadProgressList.scss';

const UploadProgressList = (props) => {
  const {
    visible,
    files,
    className,
    Wrapper,
    attachmentsConstants,
    attachmentStateActions,
    savedFiles,
  } = props;
  const [editingAttachmentId, setEditingAttachmentId] = useState();
  const uploadAttachmentSuccess = () => {
    toastr.success('Attachment is updated');
  };
  return (
    <Wrapper
      as={Segment}
      animation="overlay"
      width="thin"
      direction="bottom"
      visible={visible}
      icon="labeled"
      className={`upload-progress-list ${className}`}
    >
      {editingAttachmentId}
      <Table celled compact>
        <Table.Body>
          {_.map(files, (file, index) => {
            const attachment = _.find(savedFiles, {
              originalFileName: file.name,
            });
            return (
              <UploadProgressListRow
                key={index}
                attachmentsConstants={attachmentsConstants}
                attachmentStateActions={attachmentStateActions}
                savedFiles={savedFiles}
                uploadAttachmentSuccess={uploadAttachmentSuccess}
                file={file}
                attachment={attachment}
                setEditingAttachmentId={setEditingAttachmentId}
                isEditOpen={attachment && attachment.id === editingAttachmentId}
              />
            );
          })}
        </Table.Body>
      </Table>
    </Wrapper>
  );
};

UploadProgressList.propTypes = {
  visible: PropTypes.bool,
  files: PropTypes.array,
  className: PropTypes.string,
  Wrapper: PropTypes.func,
  attachmentsConstants: PropTypes.object,
  attachmentStateActions: PropTypes.object,
  savedFiles: PropTypes.array,
};

export default UploadProgressList;
