import { map } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useDispatch } from 'react-redux';
import {
  Button,
  Header,
  List,
  Message,
  Progress,
  Segment,
} from 'semantic-ui-react';
import { loadParty } from '../../../actions/partyActions';
import { useAppSelector } from '../../../actions/store';
import partyApi, { ImportParty, ImportPartyData } from '../../../api/partyApi';
import { Party, PartyRelationshipType } from '../../../types';

import BulkImportPartyPresentation from '@vidende/components/BulkImportPartyPresentation'; // Assuming they are in the same directory
import queueApi, { QueueStatus } from '../../../api/queueApi';
import QueueDetails from '../../queue/QueueDetails';

interface Props {
  type: string;
  partyId: string;
}

const BulkImportPartyContainer = ({ type, partyId }: Props) => {
  const dispatch = useDispatch();

  const partyType = useAppSelector((state) =>
    state.constants.partyRelationshipTypes.find(
      (prt) => prt.id === type.toUpperCase()
    )
  );

  const [loadingState, setLoadingState] = useState<
    'IDLE' | 'PROCESSING' | 'COMPLETE'
  >('IDLE');

  const [queueId, setQueueId] = useState<string | null>(null);
  const queueIdRef = useRef<string | null>(null);

  const party = useAppSelector((state) => state.party);

  useEffect(() => {
    const fetchParty = async () => {
      const filter = {
        include: [
          {
            relation: 'permissionsNode',
            scope: {
              include: [
                {
                  relation: 'children',
                  scope: {
                    where: {
                      structuralNodeTypeId: 'PartyGroup',
                    },
                    include: [],
                  },
                },
              ],
            },
          },
        ],
      };
      await dispatch(loadParty(partyId, filter));
    };
    fetchParty();
  }, []);

  const bulkImportRelationships = async (
    partyId: string,
    data: {
      rows: ImportParty[];
      queueId: string | null;
    }
  ): Promise<any> => {
    const result = await partyApi.bulkImportRelationships(partyId, data);
    queueIdRef.current = result.queueId;
    setQueueId(result.queueId);
    return result;
  };

  return (
    <div>
      <BulkImportPartyPresentation
        partyType={partyType}
        party={party}
        loadingState={loadingState}
        setLoadingState={setLoadingState}
        bulkImportRelationships={bulkImportRelationships}
        queueId={queueId}
      />
      {queueId && <QueueDetails queueId={queueId} />}
    </div>
  );
};

export default BulkImportPartyContainer;
