import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Header, Icon } from 'semantic-ui-react';
import config from '../../../constants/fileExportConfig';

import './DashboardExportButtons.scss';

const DashboardExportButtons = (props) => {
  const { onClick, formats, loading } = props;

  return (
    <React.Fragment>
      <Header
        size="small"
        className="dashboard-export-buttons-header hide-for-print"
      >
        Export:{' '}
      </Header>
      <Button.Group className="dashboard-export-buttons hide-for-print">
        {formats.map((format) => (
          <Button
            icon
            name={format}
            key={format}
            onClick={onClick}
            size="large"
            loading={loading}
            disabled={loading}
          >
            <Icon name={config[format].icon} />
          </Button>
        ))}
      </Button.Group>
    </React.Fragment>
  );
};

DashboardExportButtons.propTypes = {
  onClick: PropTypes.func,
  formats: PropTypes.array,
  loadingExport: PropTypes.bool,
};

export default DashboardExportButtons;
