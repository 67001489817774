import apiTools from './apiTools';
import { generatePermissionsFilter } from '../utils/FilterUtils';

class PermissionApi {
  static getPermissionTypes() {
    return apiTools
      .get(
        'StructuralNodeTypes'
        //, { filter: { include: ['principal'] } }
      )
      .then((response) => response.data);
  }

  static getRoleDefinitions() {
    return apiTools
      .get('RoleDefinitions', {
        filter: {
          include: ['permissions'],
        },
      })
      .then((response) => response.data);
  }

  static getPermissions(filter) {
    return apiTools
      .get('StructuralNodes', {
        filter: Object.assign(
          {},
          {
            order: 'createdAt DESC',
          },
          filter
        ),
      })
      .then((response) => {
        return response.data;
      });
  }

  static createPermission(id, name, type) {
    return apiTools
      .post(`StructuralNodes/${id}/children`, {
        name,
        structuralNodeTypeId: type,
      })
      .then((response) => response);
  }

  static addPrincipalRole(structuralNodeId, data) {
    return apiTools
      .post(`StructuralNodes/${structuralNodeId}/principalRoles`, data)
      .then((response) => response.data);
  }

  static deleteRole(structuralNodeId, principalRoleId) {
    return apiTools
      .delete(
        `StructuralNodes/${structuralNodeId}/principalRoles/${principalRoleId}`
      )
      .then((response) => response);
  }

  static getPermission(id, filter = null) {
    return apiTools
      .get(`StructuralNodes/${id}`, {
        filter: filter ? filter : generatePermissionsFilter(5),
      })
      .then((response) => {
        return response.data;
      });
  }
  static getPrincipalsListForNode(id) {
    return apiTools
      .get(`StructuralNodes/${id}/viewPrincipals`)
      .then((response) => {
        return response.data;
      });
  }

  static updateRole(structuralNodeId, data) {
    return apiTools
      .put(`StructuralNodes/${structuralNodeId}/principalRoles/${data.id}`, data)
      .then((response) => {return response.data});
  }
}

export default PermissionApi;
