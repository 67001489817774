import React, { Component } from 'react';

const AttachmentsContext = React.createContext();

export const WrapAttachmentsContext = (ComponentToWrap) =>
  class WrapAttachmetnsContext extends Component {
    render() {
      return (
        <AttachmentsContext.Consumer>
          {(context) => <ComponentToWrap {...context} {...this.props} />}
        </AttachmentsContext.Consumer>
      );
    }
  };

export default AttachmentsContext;
