import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header, Item, Segment } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import * as _ from 'lodash';

class ReportComponent extends Component {
  render() {
    const sections = _.get(this, 'props.data.data.sections');
    let sectionNumber = 0;
    if (!sections) return <div>ReportComponent</div>;
    return (
      <div>
        {sections.map((section) => {
          sectionNumber++;
          let subsectionNumber = 0;

          return (
            <Segment key={sectionNumber} basic>
              <h2 className="page-break">
                {sectionNumber} {section.label}
              </h2>
              {section.items.map((item) => {
                subsectionNumber++;
                return (
                  <Item key={subsectionNumber}>
                    <Header
                      as="h3"
                      subheader={item.reference}
                      content={`${sectionNumber}.${subsectionNumber} ${item.heading}`}
                    />
                    <ReactMarkdown source={item.content} />
                  </Item>
                );
              })}
            </Segment>
          );
        })}
      </div>
    );
  }
}

ReportComponent.propTypes = {
  data: PropTypes.object,
};

export default ReportComponent;
