import { useEffect, useState, RefObject, useRef } from 'react';
import { debounce } from 'lodash';

const useColumnHeight = (
  initialHeight: number | string = 'auto',
  bottomPadding: number = 0
): [(node: HTMLElement | null) => void, number | string, () => void] => {
  const ref = useRef<HTMLElement | null>(null);
  const [columnHeight, setColumnHeight] = useState<number | string>(
    initialHeight
  );

  const resize = () => {
    if (!ref.current) return;
    const newHeight =
      window.innerHeight -
      ref.current.getBoundingClientRect().top -
      bottomPadding;

    setColumnHeight(newHeight);
  };

  const resizeDebounced = debounce(resize, 150);

  useEffect(() => {
    window.addEventListener('resize', resizeDebounced);
    return () => window.removeEventListener('resize', resizeDebounced);
  }, []);

  return [
    (node: HTMLElement | null) => {
      ref.current = node;
      resizeDebounced(); // resize when ref is updated
    },
    columnHeight,
    resizeDebounced,
  ];
};

export default useColumnHeight;
