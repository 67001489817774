// /* eslint-disable react/sort-comp */
// import React from 'react';
// let Viewer;

// if (typeof window === 'undefined') {

// } else {
//   Viewer = require('tui-editor/dist/tui-editor-Viewer');
// }

// import * as PropTypes from 'prop-types';

// class ViewerComponent extends React.Component {
//   rootEl = React.createRef();

//   viewerInst = null;

//   getRootElement() {
//     return this.rootEl.current;
//   }

//   getInstance() {
//     return this.viewerInst;
//   }

//   bindEventHandlers(props, prevProps) {
//     Object.keys(this.props)
//       .filter(key => /on[A-Z][a-zA-Z]+/.test(key))
//       .forEach(key => {
//         const eventName = key[2].toLowerCase() + key.slice(3);
//         // For <Viewer onFocus={condition ? onFocus1 : onFocus2} />
//         if (prevProps && prevProps[key] !== props[key]) {
//           this.viewerInst.off(eventName);
//         }
//         this.viewerInst.on(eventName, props[key]);
//       });
//   }

//   componentDidMount() {
//     if (!Viewer) {
//       consle.log("Viewer is not defined")
//     }
//     this.viewerInst = new Viewer({
//       el: this.rootEl.current,
//       ...this.props
//     });

//     this.bindEventHandlers(this.props);
//   }

//   shouldComponentUpdate(nextProps) {
//     const currentValue = this.props.initialValue;
//     const nextValue = nextProps.initialValue;

//     if (currentValue !== nextValue) {
//       this.getInstance().setValue(nextValue);
//     }

//     this.bindEventHandlers(nextProps, this.props);

//     return false;
//   }

//   render() {
//     return <div ref={this.rootEl} />;
//   }
// }

// ViewerComponent.propTypes = {
//   initialValue: PropTypes.string
// };

// export default ViewerComponent;

if (typeof window !== 'undefined') window.process = { cwd: () => '', env: {} };

import ReactMarkdown from 'react-markdown';

const Viewer = ({ initialValue }) => {
  return <ReactMarkdown source={initialValue} />;
};

export default Viewer;
