import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Table } from 'semantic-ui-react';

const serverSideRender =
  typeof process !== 'undefined' && !!process.env.SERVER_REND;

const DataTableWrapper = (props) => {
  const { items, showHeader = true, className } = props;
  const columnNames = _.get(items, '0.columnNames');
  return (
    <Table
      verticalAlign="top"
      clear="all"
      celled={serverSideRender}
      fixed={serverSideRender}
      border={serverSideRender ? 1 : null}
      cellSpacing={serverSideRender ? 0 : null}
      cellPadding={serverSideRender ? 5 : null}
      className={`${className} data-table-component`}
    >
      {columnNames && showHeader && (
        <Table.Header>
          <Table.Row>
            {columnNames.map((columnName, index) => {
              if (_.isArray(columnName)) {
                return columnName.map((subColumnName, subIndex) => (
                  <Table.HeaderCell key={`${index}_${subIndex}`} valign="top">
                    {subColumnName}
                  </Table.HeaderCell>
                ));
              }
              return (
                <Table.HeaderCell key={index} valign="top">
                  {columnName}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>{props.children}</Table.Body>
    </Table>
  );
};

DataTableWrapper.propTypes = {
  items: PropTypes.array,
  children: PropTypes.array,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
};

export default DataTableWrapper;
