import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import ProgrammeCategory from './ProgrammeCategory';
import ActionButtons from '../common/ActionButtons';

class ProgrammeCategoryBuilder extends Component {
  render() {
    const { programme, selectedCategoryId, addCategory } = this.props;
    return (
      <React.Fragment>
        <Header>Categories </Header>
        <ActionButtons addClicked={() => addCategory()} />
        <Grid columns={3} celled>
          {programme.categories.map((category, index) => (
            <ProgrammeCategory
              selected={selectedCategoryId === category.uid}
              key={index}
              index={index}
              category={category}
              {...this.props}
            />
          ))}
        </Grid>
      </React.Fragment>
    );
  }
}

ProgrammeCategoryBuilder.propTypes = {
  programme: PropTypes.object.isRequired,
  selectedCategoryId: PropTypes.number,
  addCategory: PropTypes.func,
};

export default ProgrammeCategoryBuilder;
