import React from 'react';

const PartyContext = React.createContext();

export const PartyWrapContext = (ComponentToWrap) =>
  class PartyWrapContext extends React.Component {
    render() {
      return (
        <PartyContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </PartyContext.Consumer>
      );
    }
  };

export default PartyContext;
