import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import * as uiActions from '../../actions/uiActions';
import ManagePermissions from './ManagePermissions';

class ManagePermissionsModal extends Component {
  render() {
    const { closeModal } = this.props.uiActions;
    const { name, permissionsNodeId, modelStateActions } = this.props;

    return (
      <Modal size="small" open={true} onClose={() => closeModal()}>
        <Modal.Header>Manage Permissions for {name}</Modal.Header>
        <Modal.Content>
          <ManagePermissions
            permissionsNodeId={permissionsNodeId}
            modelStateActions={modelStateActions}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => closeModal()}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ManagePermissionsModal.propTypes = {
  uiActions: PropTypes.object.isRequired,
  permissionsNodeId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  modelStateActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ManagePermissionsModal);
