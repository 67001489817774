import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Table } from 'semantic-ui-react';

import PrincipalRoleRow from './PrincipalRoleRow';
import { PermissionContextConsumer } from '../../context/PermissionContext';
import ConfirmWrapper from './../common/ConfirmWrapper';

const PrincipalRoleTable = (props) => {
  const {
    structuralNode,
    isAdminPermission,
    includeSuperUser,
    onDelete,
    onChange,
  } = props;
  let { principalRoles } = props;
  const { showConfirmDialog } = props.confirmPopupActions;
  if (!principalRoles)
    principalRoles = structuralNode ? structuralNode.principalRoles : null;

  if (principalRoles && principalRoles.length === 0) return null;
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Label</Table.HeaderCell>
          <Table.HeaderCell>Party</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          {isAdminPermission && <Table.HeaderCell>Actions</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {principalRoles &&
          _.map(principalRoles, (principalRole, index) => (
            <PrincipalRoleRow
              key={index}
              principalRole={principalRole}
              isAdminPermission={isAdminPermission}
              includeSuperUser={includeSuperUser}
              showConfirmDialog={showConfirmDialog}
              onDelete={onDelete}
              onChange={onChange}
            />
          ))}
      </Table.Body>
    </Table>
  );
};

PrincipalRoleTable.propTypes = {
  structuralNode: PropTypes.object,
  isAdminPermission: PropTypes.bool,
  includeSuperUser: PropTypes.bool,
  confirmPopupActions: PropTypes.object,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  principalRoles: PropTypes.array,
};

export default PermissionContextConsumer(ConfirmWrapper(PrincipalRoleTable));
