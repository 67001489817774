import _ from 'lodash';
import { nest } from 'd3-collection';

import { gradientColors } from '../../../constants/config';
import ArgTypes from '../ArgTypes';

function flattenNestedValues(obj) {
  if (obj.values) {
    return obj.values.map((v) => flattenNestedValues(v));
  } else if (obj.value) {
    return obj.value;
  }
}

const Pie = function (args) {
  const {
    series,
    yColumn,
    clickType,
    data,
    callback,
    selection = [],
    outputKey = 'pieData',
    groupThreshold,
  } = args;
  //Sort data in series order
  let sortedData = data[series[0].dataSet];
  const seriesTotal = series.length;

  if (groupThreshold) {
    //yColumn
    const total = _.sumBy(sortedData, yColumn.dataRow);

    const thresholdData = [];

    const other = {
      [series[0].dataRow]: 'Other',
      [yColumn.dataRow]: 0,
    };

    _.forEach(sortedData, (dataPoint) => {
      const count = dataPoint[yColumn.dataRow];
      const percent = (count / total) * 100;
      if (percent < groupThreshold) {
        other[yColumn.dataRow] = other[yColumn.dataRow] + count;
      } else {
        thresholdData.push(dataPoint);
      }
    });

    sortedData = [other, ...thresholdData];
  }

  const seriesData = [];

  _.reduce(
    series,
    (dataRows, seriesItem) => {
      dataRows.push(seriesItem.dataRow);

      const newNest = nest();

      _.forEach(dataRows, (dataRow) => {
        newNest.key(function (d) {
          return d[dataRow];
        });
      });

      newNest.rollup(function (v) {
        const label = v[0][seriesItem.dataRow];
        const dataValue = {
          [seriesItem.dataRow]: label,
        };
        if (seriesItem.dataId)
          dataValue[seriesItem.dataId] = v[0][seriesItem.dataId];
        const item = {
          name: label,
          y: _.sumBy(v, yColumn.dataRow),
          dataValue,
          events: {
            click: (event) => {
              if (callback) {
                _.debounce(() => {
                  callback({ ...event.point, clickType });
                })();
              }

              event.preventDefault();
              return false;
            },
          },
        };

        if (selection && selection.indexOf(label) !== -1) {
          item.className = 'selected';
        }

        if (seriesItem.dataColour) {
          item.color =
            gradientColors[v[0][seriesItem.dataColour]] ||
            v[0][seriesItem.dataColour];
        }
        item.showInLegend = seriesItem.showInLegend;

        return item;
      });

      const values = newNest.entries(sortedData);
      const flattenedValues = values.map((value) => flattenNestedValues(value));
      const mappedValues = _.flattenDeep(flattenedValues);
      seriesData.push(mappedValues);
      return dataRows;
    },
    []
  );

  const seriesSize = 40 / seriesTotal;
  const outputData = seriesData.map((data, index) => {
    const innerSize = index * seriesSize + 40;
    const size = innerSize + seriesSize;

    const showInLegend = data?.[0]?.showInLegend;
    return {
      innerSize: `${innerSize}%`,
      size: `${size}%`,
      showInLegend,
      dataLabels: {
        distance: -20,
        enabled: !showInLegend,
      },
      data,
    };
  });

  return {
    ...args,
    data: { ...data, [outputKey]: outputData },
    // categories: xCategories,
    // series
  };
};

Pie.args = {
  dataSet: ArgTypes.string,
  outputKey: ArgTypes.string,
  series: ArgTypes.json,
  yColumn: ArgTypes.json,
  groupThreshold: ArgTypes.number,
};

export default Pie;
