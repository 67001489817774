import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import RichTextArea from '../common/fields/RichTextArea';
import TagsInput from '../common/TagsInput';

const ActivityOtherDetails = ({
  activity,
  updateActivityState,
  addNewTag,
  tags,
}) => {
  const {
    moreInfo,
    primaryTag,
    tags: activityTags,
    riskGroup,
    riskCategory,
    riskSubCategory,
    weighting,
    statsReporting,
    versionNotes,
  } = activity;

  const updateAdditionalTags = (event, data) => {
    const { name, value } = data;
    const notEmptyValues = _.filter(value, Boolean);
    const newData = { name, value: notEmptyValues };

    updateActivityState(event, newData);
  };

  return (
    <Form fluid>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Primary Tag</label>
          <TagsInput
            tags={tags}
            value={primaryTag}
            onChange={(e, data) => {
              updateActivityState(e, data);
              updateActivityState(e, {
                name: 'primaryTagId',
                value: data.value ? data.value.id : null,
              });
            }}
            addNewTag={addNewTag}
            name="primaryTag"
            multiple={false}
          />
        </Form.Field>
        <Form.Field>
          <label>Additional Tags</label>
          <TagsInput
            tags={tags}
            value={activityTags}
            onChange={updateAdditionalTags}
            addNewTag={addNewTag}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>Risk Group</label>
          <Form.Input
            placeholder="Risk Group"
            value={riskGroup}
            onChange={updateActivityState}
            name="riskGroup"
          />
        </Form.Field>
        <Form.Field>
          <label>Risk Category</label>
          <Form.Input
            placeholder="Risk Category"
            value={riskCategory}
            onChange={updateActivityState}
            name="riskCategory"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Risk Sub Category</label>
          <Form.Input
            placeholder="Risk Sub Category"
            value={riskSubCategory}
            onChange={updateActivityState}
            name="riskSubCategory"
          />
        </Form.Field>
        <Form.Field>
          <label>Weighting</label>
          <Form.Input
            placeholder="Weighting"
            value={weighting}
            onChange={updateActivityState}
            name="weighting"
            type="number"
          />
        </Form.Field>
        <Form.Field>
          <label>Stats Reporting</label>
          <Form.Input
            control={Checkbox}
            placeholder="Stats Reporting"
            checked={statsReporting}
            onChange={(e, data) => {
              updateActivityState(e, {
                name: 'statsReporting',
                value: data.checked,
              });
            }}
            name="statsReporting"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <RichTextArea
          label="More Info"
          value={moreInfo}
          onChange={updateActivityState}
          name="moreInfo"
          fullWidth={true}
        />
      </Form.Group>
      <Form.Group>
        <RichTextArea
          label="Version Notes"
          value={versionNotes}
          onChange={updateActivityState}
          name="versionNotes"
          fullWidth={true}
        />
      </Form.Group>
    </Form>
  );
};

ActivityOtherDetails.propTypes = {
  activity: PropTypes.object,
  updateActivityState: PropTypes.object,
  addNewTag: PropTypes.func,
  tags: PropTypes.array,
};

export default ActivityOtherDetails;
