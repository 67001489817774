import * as types from '../constants/actionTypes';
import activityApi from '../api/activityApi';
import initialState from '../reducers/initialState';
import { checkValidation } from '../utils/Validation';
import ValidationSchema from '../utils/ValidationSchema';

export function loadActivity(id, name) {
  return function (dispatch) {
    return activityApi.getActivity(id).then((activity) => {
      dispatch({
        type: types.LOAD_ACTIVITY_SUCCESS,
        activity,
        name,
      });
    });
  };
}

export function loadActivities(filters = {}) {
  return function (dispatch) {
    return activityApi.getActivities(filters).then((activities) => {
      dispatch({
        type: types.LOAD_ACTIVITIES_SUCCESS,
        activities,
      });
    });
  };
}

export function removeActivityWorkflowFieldDefault(name, field, id) {
  return {
    type: types.REMOVE_ACTIVITY_WORKFLOW_FIELD_DEFAULT,
    field,
    id,
  };
}

export function updateActivityWorkflowFieldDefault(name, field, value, id) {
  return {
    type: types.UPDATE_ACTIVITY_WORKFLOW_FIELD_DEFAULT,
    field,
    value,
    id,
  };
}

export function updateActivityValue(name, keyName, value, id) {
  return { type: types.UPDATE_ACTIVITY, keyName, value, name, id };
}

export function addActivityField(
  name,
  fieldType,
  label = '',
  options = {},
  id,
  fieldKey
) {
  return {
    type: types.ADD_ACTIVITY_FIELD,
    fieldType,
    label,
    options,
    id,
    fieldKey,
  };
}

export function updateActivityField(name, fieldIndex, value, id) {
  return {
    type: types.UPDATE_ACTIVITY_FIELD,
    fieldIndex,
    value,
    id,
  };
}

export function updateResolutionLabel(
  name,
  statusKey,
  resolutionTypeId,
  value,
  id
) {
  return {
    type: types.UPDATE_RESOLUTION_LABEL,
    statusKey,
    resolutionTypeId,
    value,
    id,
  };
}

export function removeActivityField(name, fieldIndex, id) {
  return { type: types.REMOVE_ACTIVITY_FIELD, fieldIndex, id };
}

export function resetActivity(name, resetState = initialState.activity, id) {
  return function (dispatch, getState) {
    const currentUser = getState()['currentUser'];
    dispatch({
      type: types.RESET_ACTIVITY,
      id,
      resetState: Object.assign(
        {},
        initialState.activity,
        {
          ownerPartyId: currentUser.details
            ? currentUser.details.partyId
            : null,
        },
        resetState
      ),
    });
  };
}

export function saveActivity(activity) {
  return function (dispatch) {
    return activityApi.saveActivity(activity).then((activity) => {
      dispatch({
        type: `${types.SAVE_ACTIVITY_SUCCESS}`,
        activity,
      });
    });
  };
}

export function saveStateActivity(
  name,
  isNewVersion = false,
  overrideUpdateChecks = false,
  id
) {
  return function (dispatch, getState) {
    const activity = { ...getState().activity.byId[id] };
    if (activity.id === 'NEW') delete activity.id;

    const validation = checkValidation(activity, ValidationSchema.activity);
    if (!validation.valid) {
      return Promise.reject(validation);
    }

    return activityApi
      .saveActivity(activity, isNewVersion, overrideUpdateChecks)
      .then((activity) => {
        return dispatch({
          type: types.SAVE_ACTIVITY_SUCCESS,
          activity,
        });
      });
  };
}
