import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { TicketContextConsumer } from '../../context/TicketContext';
import toastr from 'toastr';
import PrincipalLabel from '../common/PrincipalLabel';
import { mapPrincipal } from '../../utils/TicketUtils';
import PrincipalSearch from '../common/PrincipalSearch';

class TicketAssignment extends Component {
  static propTypes = {
    ticket: PropTypes.object,
    currentTicket: PropTypes.object,
    ticketStateActions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const ticket = props.ticket || props.currentTicket;
    const {
      assignedPrincipalId,
      assignedPrincipalLabel,
      assignedPrincipalType,
    } = ticket;
    const assignedPrincipalObj = {
      id: assignedPrincipalId,
      label: assignedPrincipalLabel,
      type: assignedPrincipalType,
    };
    const assignedPrincipal = ticket ? assignedPrincipalObj : null;
    // If ticket has an assigned principal, translate this to search data format
    const currentPrincipal = assignedPrincipal
      ? mapPrincipal(assignedPrincipal)
      : null;

    this.state = {
      currentPrincipal,
      saving: false,
      showAssignment: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const ticket = nextProps.ticket || nextProps.currentTicket;
    const prevTicket = this.props.ticket || this.props.currentTicket;

    if (
      ticket &&
      prevTicket &&
      (ticket.id !== prevTicket.id ||
        ticket.assignedPrincipalId !== prevTicket.assignedPrincipalId)
    ) {
      const {
        assignedPrincipalId,
        assignedPrincipalLabel,
        assignedPrincipalType,
      } = ticket;
      const assignedPrincipalObj = {
        id: assignedPrincipalId,
        label: assignedPrincipalLabel,
        type: assignedPrincipalType,
      };
      this.setState({
        currentPrincipal: mapPrincipal(assignedPrincipalObj),
      });
      return;
    }

    if (!ticket || !ticket.assignedPrincipalId) return;

    if (
      !this.state.currentPrincipal ||
      ticket.assignedPrincipalId !== this.state.currentPrincipal.id
    ) {
      const { assignedPrincipal, assignedPrincipalType } = ticket;
      this.setState({
        currentPrincipal: assignedPrincipal[assignedPrincipalType],
      });
    }
  };

  handleChange = (event, data) => {
    const { value } = data;
    // Do not search if chosen principal hasn't changed
    if (
      this.state.currentPrincipal &&
      value &&
      this.state.currentPrincipal.id === value.id
    ) {
      this.setState({ showAssignment: false });
      return;
    }
    this.setState({ saving: true });
    const ticket = this.props.ticket || this.props.currentTicket;

    this.props.ticketStateActions
      .assign(ticket, value ? value.id : null)
      .then(() => {
        toastr.success('Ticket assignment successful');
        this.setState(
          {
            saving: false,
            showAssignment: false,
            currentPrincipal: value,
          },
          () => {
            this.handleClose();
          }
        );
      })
      .catch((error) => {
        toastr.error(error);
        this.setState(
          { saving: false, error: error, showAssignment: false },
          () => {
            this.handleClose();
          }
        );
      });
  };

  //Debouncing to avoid race condition with state change in handleChange
  handleClose = _.debounce(() => {
    if (this.state.saving) return;
    this.setState({ showAssignment: false });
  });

  labelClicked = () => {
    const { disabled, userHasAssignPermission } = this.props;
    if (!userHasAssignPermission)
      return toastr.error('You do not have permission to reassign this ticket');
    if (!disabled) {
      this.setState({ showAssignment: true });
    }
  };
  render() {
    const { currentPrincipal, saving, showAssignment } = this.state;
    return (
      <React.Fragment>
        {showAssignment ? (
          <PrincipalSearch
            onChange={this.handleChange}
            onClose={this.handleClose}
            saving={saving}
            addUnassignValue={true}
          />
        ) : (
          <PrincipalLabel
            principal={currentPrincipal}
            onClick={this.labelClicked}
          />
        )}
      </React.Fragment>
    );
  }
}

TicketAssignment.propTypes = {
  disabled: PropTypes.bool,
  userHasAssignPermission: PropTypes.bool,
};

export default TicketContextConsumer(TicketAssignment);
