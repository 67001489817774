import { type } from 'os';
import { Entity } from '../types';

type ReturnVal = Entity & { [key: string]: any };

export const addEntriesToEntityObject = (entity: Entity): ReturnVal => {
  const result = entity.entries?.reduce(
    (accum, current) => {
      return { ...accum, [current.entityTypeFieldId]: current.value };
    },
    { ...entity }
  );

  return result;
};

export const addEntriesToEntities = (entities: Entity[]) => {
  return entities.map((entity) => addEntriesToEntityObject(entity));
};
