import { ReactNode, useState } from 'react';
import { Accordion, Header, Icon, Label, List, Menu } from 'semantic-ui-react';
import { Droppable } from 'react-beautiful-dnd';
import { DraggableComponent } from '../../common/dragAndDrop/DraggableComponent';
import { Activity } from '../../../types';

interface DraggableActivityProps {
  id: string;
  activity: Activity;
  selected?: boolean;
  index: number;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>, data: any) => void;
}

export const ActivityListItem = ({
  id,
  index,
  activity,
  onClick,
  selected,
}: DraggableActivityProps) => {
  const [active, setActive] = useState(false);

  return (
    // <DraggableComponent draggableId={id} index={index}>
    <Droppable droppableId={`l-${activity.id}`} type="field">
      {(provided, snapshot) => (
        <div
          onClick={onClick}
          active={selected}
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`item  activity-list-item  ${
            selected || snapshot.isDraggingOver ? 'active' : ''
          }`}
        >
          <Label size="tiny">{activity.fields?.length}</Label>
          <Header as="h5">{activity.label}</Header>
          {activity.ref && <p>{activity.ref}</p>}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    // </DraggableComponent>
  );
};
