import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { formatValue } from '../../../../../utils/FormatUtils';
import { kpiValueFormats } from '../../../../../constants/config';

export const ThirdStyleKpiComponent = ({
  label,
  format = kpiValueFormats.Number,
  color,
  value,
  currencyType,
  onClick,
}) => {
  return (
    <div
      className="component-dashboard-widget component-kpi third"
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : '',
      }}
    >
      <div className="label-component-kpi">
        <Icon name="circle" className="icon-component-kpi" color={color} />
        {label}
      </div>
      <div className="value-component-kpi">
        {formatValue(value, format, currencyType)}
      </div>
    </div>
  );
};

ThirdStyleKpiComponent.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  currencyType: PropTypes.string,
  onClick: PropTypes.func,
};
