import React from 'react';
import * as PropTypes from 'prop-types';

const PaginationDetails = ({ totalItems, itemsPerPage, activePage }) => {
  const minValue = (activePage - 1) * itemsPerPage + 1;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const isLastPage = totalPages === activePage;
  const maxValue = isLastPage ? totalItems : activePage * itemsPerPage;

  return (
    <React.Fragment>
      {minValue}-{maxValue} of {totalItems}
    </React.Fragment>
  );
};

PaginationDetails.propTypes = {
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  activePage: PropTypes.number,
};

export default PaginationDetails;
