import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header } from 'semantic-ui-react';

const FieldErrors = ({ errors }) => {
  return (
    <React.Fragment>
      {_.map(errors, (error, index) => (
        <Header as="h6" color="red" key={index}>
          {error}
        </Header>
      ))}
    </React.Fragment>
  );
};

FieldErrors.propTypes = {
  errors: PropTypes.array,
};

export default FieldErrors;
