import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'semantic-ui-react';

import Text from '../common/fields/Text';
import Date from '../common/fields/Date';
import ApplicableDate from '../executionPlan/ApplicableDate';
import UserSearch from '../common/search/UserSearch';
import ExecutionContext from '../../context/ExecutionContext';
import { mapToOptions } from '../../utils/React';
import * as permissionTypes from '../../constants/permissionTypes';
import UserContext from '../../context/UserContext';

const ExecutionDetailsForm = ({
  execution,
  handleFormDataChange,
  getErrors,
  handleOnBlur,
  handleOnFocus,
  onSave,
  saving,
  isValid,
  isEdited,
}) => {
  const {
    label,
    dueDate,
    applicableDate,
    applicableDateTypeId,
    partyId,
    ownerPartyId,
    reviewPartyId,
    assignedPartyUserId,
    reviewPartyUserId,
    ownerPartyUserId,
    executionStateId,
  } = execution;

  const executionContext = useContext(ExecutionContext);
  const userContext = useContext(UserContext);
  const { executionStateTypes, currentExecution } = executionContext;
  const { currentUser } = userContext;

  const isAdmin = currentUser.checkPermissionForNode(
    currentExecution.structuralNodeId,
    permissionTypes.ADMIN
  );

  return (
    <Form>
      <Text
        value={label}
        fluid={true}
        label="Label"
        onChange={handleFormDataChange}
        name="label"
        errors={getErrors('label')}
        onBlur={() => handleOnBlur('label')}
        onFocus={() => handleOnFocus('label')}
      />
      {isAdmin && (
        <Form.Field>
          <label>State</label>
          <Dropdown
            label="State"
            fluid
            selection
            name="executionStateId"
            onChange={handleFormDataChange}
            options={mapToOptions(executionStateTypes)}
            value={executionStateId}
          />
        </Form.Field>
      )}

      {ownerPartyId && (
        <Form.Field>
          <label>Owner Party User</label>

          <UserSearch
            onChange={handleFormDataChange}
            value={ownerPartyUserId}
            name="ownerPartyUserId"
            returnUserObject={false}
            sortItemsAlphabetically={true}
            filterByParty={ownerPartyId}
          />
        </Form.Field>
      )}

      <Form.Field>
        <label>Assigned Party User</label>

        <UserSearch
          onChange={handleFormDataChange}
          value={assignedPartyUserId}
          name="assignedPartyUserId"
          returnUserObject={false}
          sortItemsAlphabetically={true}
          filterByParty={[partyId, ownerPartyId]}
        />
      </Form.Field>
      {reviewPartyId && (
        <Form.Field>
          <label>Review Party User</label>

          <UserSearch
            onChange={handleFormDataChange}
            value={reviewPartyUserId}
            name="reviewPartyUserId"
            returnUserObject={false}
            sortItemsAlphabetically={true}
            filterByParty={reviewPartyId}
          />
        </Form.Field>
      )}

      <Date
        label="Due Date"
        value={dueDate}
        onChange={handleFormDataChange}
        name="dueDate"
        isClearable={true}
      />
      <Form.Field>
        <ApplicableDate
          applicableDate={applicableDate}
          applicableDateTypeId={applicableDateTypeId}
          onChange={handleFormDataChange}
        />
      </Form.Field>

      <Form.Field>
        <Button
          secondary
          onClick={onSave}
          inverted
          loading={saving}
          disabled={!isValid || !isEdited}
        >
          Save
        </Button>
      </Form.Field>
    </Form>
  );
};

ExecutionDetailsForm.propTypes = {
  execution: PropTypes.object,
  handleFormDataChange: PropTypes.func,
  getErrors: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleOnFocus: PropTypes.func,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  isValid: PropTypes.bool,
  isEdited: PropTypes.bool,
};

export default ExecutionDetailsForm;
