/*global gantt*/

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
if (typeof process !== 'undefined' && !process.env.SERVER_REND) {
  require('dhtmlx-gantt');
  require('dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker');
  require('dhtmlx-gantt/codebase/dhtmlxgantt.css');
}

import './GanttComponent.scss';

class GanttComponent extends Component {
  constructor(props) {
    super(props);

    gantt.attachEvent('onTaskClick', this.handleTaskClick);

    gantt.config.min_column_width = 300;
    gantt.config.scale_unit = 'month';
    gantt.config.date_scale = '%F %Y';
    gantt.config.step = 1;
    gantt.config.scale_height = 60;
    gantt.config.readonly = true;
    gantt.config.open_tree_initially = true;
    gantt.config.autosize = 'y';

    gantt.config.columns = [
      {
        name: 'ref',
        label: 'Reference',
        tree: true,
        template: (ticket) => {
          return ticket.text;
        },
        width: 200,
      },
      {
        name: 'start_date',
        label: 'Analysis',
        align: 'center',
        width: '*',
        template: (row) => {
          return moment(row.start_date).format('DD/MM/YY');
        },
      },
      {
        name: 'end_date',
        label: 'Implementation',
        align: 'center',
        width: '*',
        template: (row) => {
          return moment(row.end_date).format('DD/MM/YY');
        },
      },
    ];

    gantt.templates.task_class = (start, end, task) => {
      return task.type;
    };
  }

  componentDidMount() {
    gantt.init(this.ganttContainer);
    gantt.parse(this.props.data.tasks);
    // gantt.parse(testtasks);
    window.gantt = gantt;
    gantt.render();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.tasks.data.length != this.props.data.tasks.data.length) {
      gantt.clearAll();
      gantt.parse(this.props.data.tasks);
      // gantt.parse(testtasks);
      gantt.refreshData();
    }
    gantt.render();
  }

  handleTaskClick = () => {
    return true;
  };

  render() {
    return (
      <div
        ref={(input) => {
          this.ganttContainer = input;
        }}
        style={{ width: '100%' }}
      />
    );
  }
}

GanttComponent.propTypes = {
  data: PropTypes.object,
};

export default GanttComponent;
