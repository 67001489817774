import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import * as _ from 'lodash';

import UIDGenerator from '../../../utils/UIDGenerator';
import FieldToolTip from './FieldToolTip';

const RadioGroup = (props) => {
  const {
    label,
    options,
    value,
    onChange,
    disabled = false,
    required,
    editable = true,
    error,
  } = props;
  const u = UIDGenerator.get();

  if (!editable) {
    return <React.Fragment>{value}</React.Fragment>;
  }

  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      {_.map(options, (option, index) => (
        <Form.Radio
          key={index}
          label={option}
          name={`radioGroup${u}`}
          value={option}
          checked={value === option}
          onChange={onChange}
          disabled={disabled}
        />
      ))}
    </Form.Field>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
};

RadioGroup.defaultProps = {
  value: '',
};

export default RadioGroup;
