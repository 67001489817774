import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Checkbox, Segment, Menu } from 'semantic-ui-react';
import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';
import ExecutionPlanActivitySet from '../executionPlan/ExecutionPlanActivitySet';
import { ProgrammeContextConsumer } from '../../context/ProgrammeContext';

@ProgrammeContextConsumer
@ExecutionPlanWrapContext
export default class ActivitySetScoping extends Component {
  static propTypes = {
    value: PropTypes.object,
    executionPlan: PropTypes.object,
    currentProgramme: PropTypes.object,
    executionPlanStateActions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  addClicked = () => {
    if (this.state.loading) return;
    this.setState({ loading: true });
    this.props.executionPlanStateActions
      .addActivitySet(this.props.executionPlan, this.props.value)
      .then(() => {
        this.setState({ loading: false });
      });
  };

  removeClicked = () => {
    if (this.state.loading) return;
    this.setState({ loading: true });

    const planActivitySet = this.props.executionPlan.activitySets.find(
      (as) => as.activitySetId === this.props.value.id
    );

    this.props.executionPlanStateActions
      .removeActivitySet(this.props.executionPlan, planActivitySet)
      .then(() => {
        this.setState({ loading: false });
      });
  };

  addRelationshipToActivitySet = (planActivitySet, relationship) => {
    return this.props.executionPlanStateActions.activitySetAddRelationship(
      this.props.executionPlan,
      planActivitySet,
      relationship
    );
  };

  removeRelationshipToActivitySet = (planActivitySet, relationship) => {
    return this.props.executionPlanStateActions.activitySetRemoveRelationship(
      this.props.executionPlan,
      planActivitySet,
      relationship
    );
  };

  addEntityToActivitySet = (planActivitySet, entity) => {
    return this.props.executionPlanStateActions.activitySetAddEntity(
      this.props.executionPlan,
      planActivitySet,
      entity
    );
  };

  removeEntityToActivitySet = (planActivitySet, entity) => {
    return this.props.executionPlanStateActions.activitySetRemoveEntity(
      this.props.executionPlan,
      planActivitySet,
      entity
    );
  };

  render() {
    const { value, executionPlan, currentProgramme, entities } = this.props;

    const { loading } = this.state;

    const planActivitySet = executionPlan.activitySets.find(
      (as) => as.activitySetId === value.id
    );
    const programmeActivitySets = currentProgramme
      ? _.keyBy(currentProgramme.activitySets, 'id')
      : null;
    const isAdded = !!planActivitySet;

    return (
      <React.Fragment>
        <Menu attached="top">
          <Menu.Item>
            <Header>{value.label}</Header>
          </Menu.Item>
          <Menu.Item>Questions in set: {value.activities.length}</Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Checkbox
                toggle
                onChange={isAdded ? this.removeClicked : this.addClicked}
                checked={isAdded}
                disabled={loading}
                label="In Scope"
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Segment attached="bottom">
          {isAdded && (
            <ExecutionPlanActivitySet
              onRemove={() => {}}
              onAddRelationship={this.addRelationshipToActivitySet}
              onRemoveRelationship={this.removeRelationshipToActivitySet}
              onAddEntity={this.addEntityToActivitySet}
              onRemoveEntity={this.removeEntityToActivitySet}
              relationships={executionPlan.ownerParty.partyRelationships}
              entities={entities}
              planActivitySet={Object.assign({}, planActivitySet, {
                activitySet:
                  programmeActivitySets[planActivitySet.activitySetId],
              })}
            />
          )}
        </Segment>
      </React.Fragment>
    );
  }
}
