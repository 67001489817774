import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import ticketApi from '../../api/ticketApi';
import { mapPrincipalsToOptions } from '../../utils/React';
import { getUnassignedUsers } from '../../utils//ExecutionUtils';
import { WrapUserContext } from '../../context/UserContext';

class PrincipalSearch extends Component {
  state = {
    options: this.props.currentPrincipal
      ? mapPrincipalsToOptions([this.props.currentPrincipal])
      : [],
    principals: this.props.currentPrincipal
      ? { [this.props.currentPrincipal.id]: this.props.currentPrincipal }
      : {},
  };

  componentWillMount() {
    this.handleSearchChange({}, { searchQuery: '' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exceptPrincipal) {
      if (!_.isEqual(prevProps.exceptPrincipal, this.props.exceptPrincipal)) {
        this.handleSearchChange({}, { searchQuery: '' });
      }
    }
  }

  handleSearchChange = (event, { searchQuery }) => {
    const {
      includeDefaultGroups = false,
      exceptPrincipal = null,
      restrictToAssigned = false,
      addUnassignValue = false,
      currentUser,
    } = this.props;
    ticketApi
      .searchTicketPrincipals(
        searchQuery,
        includeDefaultGroups,
        restrictToAssigned
      )
      .then((data) => {
        let options = mapPrincipalsToOptions(data);

        if (currentUser) {
          options = [
            {
              key: currentUser.details.principalId,
              text: `You (${currentUser.fullname})`,
              value: currentUser.details.principalId,
            },
            ...options.filter(
              (option) => option.key !== currentUser.details.principalId
            ),
          ];
        }

        if (exceptPrincipal) {
          options = getUnassignedUsers(options, exceptPrincipal);
        }
        if (addUnassignValue) {
          const unassignValue = {
            text: 'Unassigned',
          };
          options = [unassignValue, ...options];
        }

        //currentUser

        this.setState({ options, principals: _.keyBy(data, 'id') });
      });
  };

  handleValueChanged = (event, { value }) => {
    const { onChange } = this.props;
    const { principals } = this.state;
    const newValue = value ? principals[value] : null;
    onChange(event, { value: newValue });
  };

  render() {
    const {
      onClose,
      saving,
      currentPrincipal,
      isAutofocus = true,
      clearable = false,
      label,
      disabled,
    } = this.props;
    const { options } = this.state;

    return (
      <Dropdown
        fluid
        selection
        search={true}
        selectOnBlur={false}
        selectOnNavigation={false}
        options={options}
        value={currentPrincipal ? currentPrincipal.id : null}
        placeholder={label || 'Principal'}
        onChange={this.handleValueChanged}
        onClose={onClose}
        onSearchChange={this.handleSearchChange}
        disabled={saving || disabled}
        loading={saving}
        searchInput={{ autoFocus: isAutofocus }}
        clearable={clearable}
        className="principal-search"
      />
    );
  }
}

PrincipalSearch.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
  includeDefaultGroups: PropTypes.bool,
  currentPrincipal: PropTypes.object,
  showUnassigned: PropTypes.bool,
  isAutofocus: PropTypes.bool,
  clearable: PropTypes.bool,
  restrictToAssigned: PropTypes.bool,
  exceptPrincipal: PropTypes.array,
  addUnassignValue: PropTypes.bool,
  currentUser: PropTypes.object,
  label: PropTypes.string,
};

PrincipalSearch.defaultProps = {
  showUnassigned: true,
};

export default WrapUserContext(PrincipalSearch);
