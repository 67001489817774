import React, { Component } from 'react';

import * as chartConfigs from './chartConfigBase';
import * as ReactHighcharts from 'react-highcharts';
const ReactHighmaps = require('react-highcharts/ReactHighmaps').withHighcharts(
  ReactHighcharts.default
);

class MapComponent extends Component {
  createChartConfig = () => {
    return Object.assign({}, chartConfigs.europeMap, { ...this.props });
  };

  render() {
    return (
      <div>
        <ReactHighmaps config={this.createChartConfig()} />
      </div>
    );
  }
}

MapComponent.propTypes = {};

export default MapComponent;
