import React from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

const TicketMoreInfo = ({ ticket, showSidebar }) => {
  const showMoreDetailsSidebar = () => {
    showSidebar(ticket);
  };

  return (
    <Header onClick={() => showMoreDetailsSidebar()} as="h4">
      <p className="more-info-button">Show more info</p>
    </Header>
  );
};

TicketMoreInfo.propTypes = {
  ticket: PropTypes.object,
  showSidebar: PropTypes.func,
};

export default TicketMoreInfo;
