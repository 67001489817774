import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Menu, Dropdown, Button, Icon, Input, Form } from 'semantic-ui-react';

import './SearchSort.scss';

const SearchSort = ({
  options,
  sort = { item: [''], order: ['ASC'] },
  handleItemClick,
  handleOrderFilterChange,
  searchValue,
  handleSearchValueChange,
  loading,
  handleSearchClick,
}) => {
  const [order] = [...sort.order];
  const [item] = [...sort.item];
  const orderButtonIcon = order === 'ASC' ? 'arrow up' : 'arrow down';
  const currentSortItem =
    item.length > 0 ? _.find(options, ({ key }) => key === item) : null;
  const currentSortItemText = currentSortItem ? currentSortItem.text : '';
  const dropdownText = `Sort by ${currentSortItemText}`;

  const handleSearchValueClear = () => {
    handleSearchValueChange(null, { value: '' });
    handleSearchClick('');
  };

  return (
    <div className="search-sort">
      <Menu text>
        <Dropdown item text={dropdownText}>
          <Dropdown.Menu>
            {_.map(options, ({ key, text }) => (
              <Dropdown.Item
                key={key}
                value={key}
                active={item === key}
                onClick={handleItemClick}
              >
                {text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      <Button icon onClick={handleOrderFilterChange} size="mini">
        <Icon name={orderButtonIcon} />
      </Button>
      <Form className="search-sort-form">
        <Input
          icon={
            searchValue.length > 0 ? (
              <Icon
                onClick={handleSearchValueClear}
                name="remove circle"
                link
              />
            ) : null
          }
          value={searchValue}
          placeholder="Contains text"
          onChange={handleSearchValueChange}
          clearable={true}
          size="small"
        />
        <Button
          content="Search"
          primary
          disabled={loading}
          onClick={handleSearchClick}
          size="small"
        />
      </Form>
    </div>
  );
};

SearchSort.propTypes = {
  sort: PropTypes.object,
  handleItemClick: PropTypes.func,
  handleOrderFilterChange: PropTypes.func,
  options: PropTypes.array,
  searchValue: PropTypes.string,
  handleSearchValueChange: PropTypes.func,
  handleSearchClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default SearchSort;
