import _ from 'lodash';

export default function (args) {
  const { dataSet, flattenBy, values, outputKey, data } = args;
  const dataToFilter = data[dataSet];

  let filteredData = dataToFilter;
  if (flattenBy && dataToFilter) {
    filteredData = _.chain(dataToFilter)
      .map((v) => {
        return _.map(v[flattenBy], (item) => {
          const toInclude = {};
          _.forEach(values, (mapValue, mapKey) => {
            const val = _.get(v, mapValue);
            toInclude[mapKey] = _.isNil(val) ? mapValue : val;
          });

          return { ...item, ...toInclude };
        });
      })
      .flatten()
      .value();
  } else {
    filteredData = _.flatten(dataToFilter);
  }

  return { ...args, data: { ...data, [outputKey || dataSet]: filteredData } };
}
