import React from 'react';
import { Button, Dropdown, Table } from 'semantic-ui-react';
import { sortBy } from 'lodash';

import FormBuilderItem from './FormBuilderItem';
import FormBuilderField from './FormBuilderField';
import { ActivityFieldType, Field, FieldType } from '../../types';

interface Option {
  id: string;
  name: string;
}

interface FormThresholdBuilderProps {
  activityFieldTypes: ActivityFieldType[];
  fields: Field[];
  addField: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: any
  ) => void;
  onFieldChange: (index: number, value: Field) => void;
  isTableView?: boolean;
  layout?: string;
  workflowStatusInputTypes?: Option[];
}

const FormThresholdBuilder = ({
  activityFieldTypes,
  addField,
  fields,
  onFieldChange,
  isTableView = true,
  layout,
  workflowStatusInputTypes,
}: FormThresholdBuilderProps) => {
  if (!fields) return null;
  return (
    <div>
      {isTableView ? (
        <Table celled>
          {fields.length > 0 && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
                <Table.HeaderCell>Data Field Reference</Table.HeaderCell>
                <Table.HeaderCell>Display Short Label</Table.HeaderCell>
                <Table.HeaderCell collapsing>Data Field</Table.HeaderCell>
                <Table.HeaderCell collapsing>Required</Table.HeaderCell>
                <Table.HeaderCell collapsing />
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {sortBy(
              fields.map((field, index) =>
                Object.assign({}, field, { originalIndex: index })
              ),
              ['sort']
            ).map((field) => (
              <FormBuilderField
                key={field.originalIndex}
                value={field}
                activityFieldTypes={activityFieldTypes}
                fieldIndex={field.originalIndex}
                onChange={onFieldChange}
                editable={true}
              />
            ))}

            <Table.Row>
              <Table.Cell colSpan={8} textAlign="center">
                <Dropdown
                  text="Add field"
                  icon="add circle"
                  floating
                  labeled
                  button
                  scrolling
                  className="icon"
                  upward
                >
                  <Dropdown.Menu>
                    <Dropdown.Header content="Form Fields" />
                    {activityFieldTypes.map((option) => (
                      <Dropdown.Item
                        key={option.id}
                        text={option.name}
                        value={option.id}
                        onClick={addField}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <div>
          <div>
            {sortBy(
              fields.map((field, index) => ({
                ...field,
                originalIndex: index,
              })),
              ['sort']
            ).map((field) =>
              field.activityFieldTypeId === 'Threshold' ? (
                <FormBuilderItem
                  key={field.originalIndex}
                  field={field}
                  onFieldChange={onFieldChange}
                  activityFieldTypes={activityFieldTypes}
                  layout={layout}
                  workflowStatusInputTypes={workflowStatusInputTypes}
                />
              ) : null
            )}
          </div>
          <Button onClick={() => addField(null, { value: 'Threshold' })}>
            Add Threshold
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormThresholdBuilder;
