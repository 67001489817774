import React, { Component } from 'react';
import { Card, Grid, Header, Button, Icon, Label } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { pluralize } from './../../utils/Pluralize';

import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';
import ExecutionPlanActivitySet from '../executionPlan/ExecutionPlanActivitySet';
import { MODAL_COMPONENTS } from './../common/ModalRoot';
import AssignWorkflow from './AssignWorkflow';
import ExecutionPlanAssignQuestionSet from './ExecutionPlanAssignQuestionSet';

class ActivitySetCard extends Component {
  state = {
    loading: false,
  };

  addRelationshipToActivitySet = (planActivitySet, relationship) => {
    const { executionPlanStateActions, executionPlan } = this.props;
    return executionPlanStateActions.activitySetAddRelationship(
      executionPlan,
      planActivitySet,
      relationship
    );
  };

  removeRelationshipToActivitySet = (planActivitySet, relationship) => {
    const { executionPlanStateActions, executionPlan } = this.props;
    return executionPlanStateActions.activitySetRemoveRelationship(
      executionPlan,
      planActivitySet,
      relationship
    );
  };

  addEntityToActivitySet = (planActivitySet, entity) => {
    return this.props.executionPlanStateActions.activitySetAddEntity(
      this.props.executionPlan,
      planActivitySet,
      entity
    );
  };

  removeEntityToActivitySet = (planActivitySet, entity) => {
    return this.props.executionPlanStateActions.activitySetRemoveEntity(
      this.props.executionPlan,
      planActivitySet,
      entity
    );
  };

  addClicked = () => {
    const { executionPlanStateActions, executionPlan, item } = this.props;
    this.setState({ loading: true });
    executionPlanStateActions.addActivitySet(executionPlan, item).then(() => {
      this.setState({ loading: false });
    });
  };

  removeClicked = () => {
    const { executionPlanStateActions, executionPlan, item } = this.props;
    this.setState({ loading: true });
    const planActivitySet = executionPlan.activitySets.find(
      (as) => as.activitySetId === item.id
    );

    executionPlanStateActions
      .removeActivitySet(executionPlan, planActivitySet)
      .then(() => {
        this.setState({ loading: false });
      });
  };

  openQuestionSetModal = () => {
    const {
      uiActions,
      item: { activities, label, id },
    } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.QUESTION_SET_MODAL,
      modalProps: { activities, label, id },
    });
  };

  getNumberOfQuestions = (item) => {
    return item.activities
      ? pluralize('Question', item.activities.length, true)
      : '';
  };

  updateActivitySetWorkflow = (planActivitySetId, workflowid = null) => {
    const { updateActivitySetWorkflow } = this.props.executionPlanStateActions;
    return updateActivitySetWorkflow(planActivitySetId, workflowid);
  };

  assignToUser = (executionPlanId, activitySetId, principalId) => {
    const { executionPlanStateActions } = this.props;
    return executionPlanStateActions.activityAssignToUser(
      executionPlanId,
      activitySetId,
      principalId
    );
  };

  render() {
    const { item, executionPlan, workflows, entities } = this.props;
    const { partyRelationships } = executionPlan.ownerParty;
    const { loading } = this.state;

    const planActivitySet = _.find(
      executionPlan.activitySets,
      (as) => as.activitySetId === item.id
    );

    const programmeActivitySets = executionPlan.programme
      ? _.keyBy(executionPlan.programme.activitySets, 'id')
      : null;

    const isAdded = !!planActivitySet;
    return (
      <Card
        key={item.id}
        className={isAdded ? 'card-in-scope' : ''}
        color={isAdded ? 'blue' : null}
      >
        <Card.Content>
          {isAdded && (
            <Icon
              name="check circle"
              size="large"
              color="blue"
              floated="left"
              className="tick-icon-card"
            />
          )}

          <Header as="h4" className="card-header">
            {item.label}
          </Header>
          <Label
            attached="top right"
            as="a"
            onClick={() => this.openQuestionSetModal()}
          >
            {this.getNumberOfQuestions(item)}
          </Label>

          {isAdded && (
            <Card.Description>
              <Grid.Row>
                <ExecutionPlanActivitySet
                  onRemove={() => {}}
                  onAddRelationship={this.addRelationshipToActivitySet}
                  onRemoveRelationship={this.removeRelationshipToActivitySet}
                  onAddEntity={this.addEntityToActivitySet}
                  onRemoveEntity={this.removeEntityToActivitySet}
                  relationships={partyRelationships ? partyRelationships : []}
                  planActivitySet={
                    planActivitySet
                      ? Object.assign({}, planActivitySet, {
                          activitySet:
                            programmeActivitySets[
                              planActivitySet.activitySetId
                            ],
                        })
                      : {}
                  }
                  executionPlan={executionPlan}
                  entities={entities}
                />
              </Grid.Row>
              {item.allowWorkflowAssignment && (
                <Grid.Row>
                  <AssignWorkflow
                    workflows={workflows.list}
                    executionPlan={executionPlan}
                    planActivitySet={
                      planActivitySet
                        ? Object.assign({}, planActivitySet, {
                            activitySet:
                              programmeActivitySets[
                                planActivitySet.activitySetId
                              ],
                          })
                        : {}
                    }
                    updateWorkflow={this.updateActivitySetWorkflow}
                  />
                </Grid.Row>
              )}
              <ExecutionPlanAssignQuestionSet
                activitySet={planActivitySet}
                assignToUser={this.assignToUser}
              />
            </Card.Description>
          )}
        </Card.Content>
        <Card.Content extra>
          {!isAdded ? (
            <Button
              primary
              fluid
              onClick={() => this.addClicked()}
              disabled={loading}
            >
              <Icon name="plus" /> Add to scope
            </Button>
          ) : (
            <Button
              negative
              fluid
              onClick={() => this.removeClicked()}
              disabled={loading}
            >
              <Icon name="close" />
              Remove from scope
            </Button>
          )}
        </Card.Content>
      </Card>
    );
  }
}

ActivitySetCard.propTypes = {
  item: PropTypes.object,
  executionPlan: PropTypes.object,
  executionPlanStateActions: PropTypes.object,
  uiActions: PropTypes.object,
  workflows: PropTypes.array,
};

export default ExecutionPlanWrapContext(ActivitySetCard);
