import EventComponentComment from '../components/common/events/EventComponentComment';

export const EVENT_LIST_LIMIT = 10;

export const EVENT_TYPES = {
  WAS_LAUNCHED: 'WAS_LAUNCHED',
  CHANGED_STATE: 'CHANGED_STATE',
  DATA_ENTERED_BY: 'DATA_ENTERED_BY',
  ATTACHED: 'ATTACHED',
  TRANSITIONED_BY: 'TRANSITIONED_BY',
  ASSIGNED_TO: 'ASSIGNED_TO',
  RESOLVED_BY: 'RESOLVED_BY',
  COMMENT: 'COMMENT',
  USER_USERGROUP: 'USER_USERGROUP',
  NOTIFICATION_SENT: 'NOTIFICATION_SENT',
  ADDED_PERMISSION: 'ADDED_PERMISSION',
  CREATEDPartyRelationship: 'CREATEDPartyRelationship',
  CREATEDParty: 'CREATEDParty',
  CREATEDUser: 'CREATEDUser',
  CREATEDUserGroup: 'CREATEDUserGroup',
  DEACTIVATED_PARTY: 'DEACTIVATED_PARTY',
  DEACTIVATED_USER: 'DEACTIVATED_USER',
  EMAIL_BLOCK: 'EMAIL_BLOCK',
  EMAIL_BOUNCE: 'EMAIL_BOUNCE',
  EMAIL_INVALID: 'EMAIL_INVALID',
  USER_LOGIN: 'USER_LOGIN',
  PARTY_NOTDISTRIBUTE: 'PARTY_NOTDISTRIBUTE',
  REACTIVATED_PARTY:  'REACTIVATED_PARTY'
};

export const EVENT_ICONS = {
  [EVENT_TYPES.WAS_LAUNCHED]: 'rocket',
  [EVENT_TYPES.CHANGED_STATE]: 'rocket',
  [EVENT_TYPES.DATA_ENTERED_BY]: 'pencil',
  [EVENT_TYPES.ATTACHED]: 'attach',
  [EVENT_TYPES.TRANSITIONED_BY]: 'arrow alternate circle right',
  [EVENT_TYPES.ASSIGNED_TO]: 'user plus',
  [EVENT_TYPES.CREATEDUser]: 'user plus',
  [EVENT_TYPES.RESOLVED_BY]: 'check circle',
  [EVENT_TYPES.COMMENT]: 'comment',
  [EVENT_TYPES.NOTIFICATION_SENT]: 'envelope',
  [EVENT_TYPES.ADDED_PERMISSION]: 'user',
  [EVENT_TYPES.USER_LOGIN]: 'user',
  [EVENT_TYPES.USER_USERGROUP]: 'users',
  [EVENT_TYPES.CREATEDUserGroup]: 'users',
  [EVENT_TYPES.CREATEDPartyRelationship]: 'building',
  [EVENT_TYPES.CREATEDParty]: 'building',
  [EVENT_TYPES.EMAIL_BLOCK]: 'warning',
  [EVENT_TYPES.EMAIL_BOUNCE]: 'warning',
  [EVENT_TYPES.EMAIL_INVALID]: 'warning',
  [EVENT_TYPES.PARTY_NOTDISTRIBUTE]: 'dont',
  [EVENT_TYPES.DEACTIVATED_PARTY]: 'close',
  [EVENT_TYPES.DEACTIVATED_USER]: 'close',
  [EVENT_TYPES.REACTIVATED_PARTY]: 'check',
  default: 'bell',
};

export const EVENT_COMPONENTS = {
  [EVENT_TYPES.COMMENT]: EventComponentComment,
};

export const TARGET_MODELS = {
  EXECUTION_TICKET: 'ExecutionTicket',
  EXECUTION: 'Execution',
};
