import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const ProgrammeActionButtons = ({ handleMenuAction }) => {
  return (
    <Button.Group attached="top">
      <Button icon="edit" text="Edit" onClick={handleMenuAction} name="edit">
        {' '}
        Edit Programme
      </Button>
    </Button.Group>
  );
};

ProgrammeActionButtons.propTypes = {
  handleMenuAction: PropTypes.func.isRequired,
};

export default ProgrammeActionButtons;
