export const ticketFilter = {
  include: [
    {
      relation: 'activity',
      scope: {
        include: [
          {
            relation: 'fields',
          },
        ],
      },
    },
    {
      relation: 'workflowEntries',
      scope: {
        include: [
          {
            relation: 'field',
            scope: {
              include: ['fieldType', 'workflowStatus'],
            },
          },
          'modifiedBy',
        ],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'entries',
      scope: {
        include: [
          {
            relation: 'field',
            scope: {
              include: ['fieldType'],
            },
          },
          'modifiedBy',
        ],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'statusResolutions',
      scope: {
        include: ['resolution'],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'attachments',
      scope: {
        include: ['creator'],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'toTicketRelationships',
      scope: {
        include: ['fromTicket', 'ticketRelationshipType'],
      },
    },
    {
      relation: 'fromTicketRelationships',
      scope: {
        include: ['toTicket', 'ticketRelationshipType'],
      },
    },
    'deadlines',
  ],
};

export const ticketPreviewFilter = {
  include: [
    {
      relation: 'statusResolutions',
      scope: {
        include: ['resolution'],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'toTicketRelationships',
      scope: {
        include: ['fromTicket', 'ticketRelationshipType'],
      },
    },
    {
      relation: 'fromTicketRelationships',
      scope: {
        include: ['toTicket', 'ticketRelationshipType'],
      },
    },
    'deadlines',
  ],
};

export const executionFilter = {
  include: ['assignedParty'],
};

export const sidebarTicketListFilter = {
  include: [
    'entries',
    'activity',
    {
      relation: 'execution',
      scope: {
        include: [
          'party',
          {
            relation: 'programme',
            scope: {
              include: ['categories'],
            },
          },
        ],
      },
    },
    {
      relation: 'executionTicketSet',
      scope: {
        include: ['assignedPartyRelationship', 'activitySet'],
      },
    },
    {
      relation: 'currentStatusNode',
      scope: {
        include: [
          {
            relation: 'status',
            scope: {
              include: [
                {
                  relation: 'resolutions',
                  scope: {
                    include: ['resolutionType', 'status'],
                  },
                },
                'statusType',
              ],
            },
          },
        ],
      },
    },
    {
      relation: 'statusResolutions',
      scope: {
        include: [
          {
            relation: 'resolution',
            scope: {
              include: ['status', 'resolutionType'],
            },
          },
        ],
        where: {
          current: true,
        },
      },
    },
  ],
};

export const filtersByModel = {
  ExecutionTicket: sidebarTicketListFilter,
  Execution: executionFilter,
};

export const permissionFilter = {
  include: [
    {
      relation: 'principalRoles',
      scope: {
        include: [
          {
            relation: 'principal',
            scope: {
              include: [
                { relation: 'userGroup', scope: { include: ['party'] } },
                { relation: 'user', scope: { include: ['party'] } },
              ],
            },
          },
          'roleDefinition',
        ],
      },
    },
  ],
};

export const loadExecutionFilter = {
  include: [
    {
      relation: 'ticketSets',
      scope: {
        include: [
          {
            relation: 'assignedPartyRelationship',
            scope: {
              include: ['relationshipType', 'toParty', 'fromParty'],
            },
          },
          {
            relation: 'tickets',
          },
          'activitySet',
          {
            relation: 'entities',
            scope: {
              include: ['entityType'],
            },
          },
          'permissionsNode',
        ],
      },
    },
    {
      relation: 'attachments',
      scope: {
        include: ['creator'],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'programme',
      scope: {
        include: [
          {
            relation: 'fields',
            scope: {
              include: ['fieldType'],
            },
          },
        ],
      },
    },
    {
      relation: 'entries',
      scope: {
        include: ['modifiedBy', 'field'],
        where: {
          current: true,
        },
      },
    },
    {
      relation: 'entities',
    },
    'party',
    'reviewParty',
    'reviewPartyUser',
    'ownerParty',
    'ownerPartyUser',
    'assignedPartyUser',
    'assignedParty',
    'categories',
  ],
};

export const loadMatrixRoleFilter = {
  include: [
    {
      relation: 'ticketSets',
      scope: {
        include: [
          {relation: 'permissionsNode',
          scope: {
            include: [
              {
                relation: 'principalRoles',
              },
            ],
          },
        },
          {
            relation: 'activitySet',
            scope: {
            },
          },
        ],
      },
    },
  ],
};
