import { useState, useEffect, FocusEvent, ChangeEvent } from 'react';
import { Form } from 'semantic-ui-react';
import numbro from 'numbro';
import { ModelSearch } from '../search/ModelSearch';

interface ModelSearchFieldProps {
  name?: string;
  label?: string;
  value?: string | number;
  onChange?(...args: unknown[]): unknown;
  regex?: RegExp;
  disabled?: boolean;
  errors?: object;
  required?: boolean;
  editable?: boolean;
  onFocus?(name: string): string;
  onBlur?(name: string): string;
  alwaysReturnString?: boolean;
  options: {
    modelName: string;
    valueProperty?: string;
    refProperty?: string;
  };
}

const ModelSearchField = ({
  name,
  label,
  value: propValue,
  onChange,
  regex,
  disabled = false,
  errors,
  required,
  editable = true,
  onFocus,
  onBlur,
  alwaysReturnString,
  options,
}: ModelSearchFieldProps) => {
  if (!editable) {
    return <>{propValue}</>;
  }

  return (
    <ModelSearch
      modelName={options.modelName}
      label={label}
      value={propValue}
      valueProperty={options.valueProperty}
      refProperty={options.refProperty}
      onChange={onChange}
      name={name}
    />
  );
};

export default ModelSearchField;
