import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as storeTypes from '../constants/storeTypes';

import activities from './activitiesReducer';
import programmes from './programmesReducer';
import executions from './executionsReducer';
import workflows from './workflowsReducer';
import executionPlans from './executionPlansReducer';
import parties from './partiesReducer';
import tags from './tagReducer';
import createActivityReducer from './activityReducer';
import createProgrammeReducer from './programmeReducer';
import createExecutionReducer from './executionReducer';
import tickets from './ticketsReducer';
import createWorkflowReducer from './workflowReducer';
import createExecutionPlanReducer from './executionPlanReducer';
import constants from './constantsReducer';
import createPermissionReducer from './permissionReducer';
import createUserReducer from './userReducer';
import party from './partyReducer';
import chartData from './chartReducer';
import chartDataByHash from './chartDataByHashReducer';
import uiStatus from './uiReducer';
import dashboards from './dashboardsReducer';
import events from './eventsReducer';
import filterData from './filterReducer';
import appData from './appReducer';
import attachments from './attachmentReducer';
import listFilters from './listFiltersReducer';
import fairValue from './fairValueReducer';
import entity from './entityReducer';
import status from './statusReducer';
import distributorInfos from './distributorInfosReducer';
import storeProvider from '../store/storeProvider';

export const clearLocalStorage = () => {
  const { persistor } = storeProvider.getStore();
  const localStorageKeys = Object.keys(localStorage);
  persistor.flush()
  localStorageKeys.forEach((key) => {
    persistor.purge(key);
  });
};

const uiPersistConfig = {
  key: 'uiStatus',
  storage,
  whitelist: ['isMenuExpanded'],
};

const listFiltersPersistConfig = {
  key: 'listFilters',
  storage,
};

export const createRootReducer = (history) =>
  combineReducers({
    activities,
    programmes,
    executions,
    workflows,
    executionPlans,
    tickets,
    activity: createActivityReducer('CURRENT'),
    currentExecution: createExecutionReducer('CURRENT'),
    currentProgramme: createProgrammeReducer('CURRENT'),
    permissions: createPermissionReducer('CURRENT'),
    currentUser: createUserReducer('CURRENT'),
    newProgramme: createProgrammeReducer('NEW'),
    [`ExecutionPlan${storeTypes.CURRENT}`]: createExecutionPlanReducer(
      storeTypes.CURRENT
    ),
    [`Workflow${storeTypes.EDITING}`]: createWorkflowReducer(
      storeTypes.EDITING
    ),
    constants,
    tags,
    uiStatus: persistReducer(uiPersistConfig, uiStatus),
    router: history ? connectRouter(history) : null,
    parties,
    party,
    chartData,
    chartDataByHash,
    dashboards,
    events,
    filterData,
    appData,
    attachments,
    listFilters,
    fairValue,
    entity,
    status,
    distributorInfos
  });

// export default rootReducer;
