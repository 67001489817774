import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Dimmer, Loader, Grid } from 'semantic-ui-react';

import PartyWrapper from './Party';
import PartyDetails from './PartyDetails';

import ErrorBoundary from './../common/ErrorBoundary';
import ConfirmWrapper from './../common/ConfirmWrapper';

import PartySettings from './PartySettings';
import PartyUsers from './PartyUsers';
import PartyRelationships from './PartyRelationships';
import PartyEntities from './entity/PartyEntities';
import PartyUserGroups from './PartyUserGroups';
import PartyAddresses from './PartyAddresses';
import PartyMenu from './PartyMenu';
import PartyEvents from './PartyEvents';

const menuItems = {
  users: PartyUsers,
  relationships: PartyRelationships,
  entities: PartyEntities,
  usergroups: PartyUserGroups,
  addresses: PartyAddresses,
  settings: PartySettings,
  events: PartyEvents,
};

const PartyPage = ({ match, partyStateActions, history, party }) => {
  const [loading, setLoading] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [currentMenuItem, setCurrentMenuItem] = useState('users');
  const { url, params } = match;
  const { id, menuItem } = params;
  const MainCompnent = menuItems[currentMenuItem];

  useEffect(() => {
    getParty();
    setIsInitial(false);
  }, []);

  useEffect(() => {
    getParty();
  }, [url]);

  useEffect(() => {
    if (menuItem) {
      setCurrentMenuItem(menuItem);
    }
    if (!menuItem && !isInitial) {
      history.push('/parties');
    }
    // if (!menuItem && isInitial) {
    //   changeMenuItem('users');
    // }
  }, [menuItem]);

  const getParty = () => {
    if (id && isInitial) {
      loadParty(id);
    }
  };

  const loadParty = (id) => {
    setLoading(true);
    const filter = {
      include: [
        'dashboards',
        { users: 'loginDetails' },
        {
          relation: 'partyRelationships',
          scope: {
            include: [
              'relationshipType',

              {
                relation: 'toParty',
                scope: {
                  where: {
                    status: { inq: ['ACTIVE', 'INACTIVE'] },
                  },
                },
              },

              'entries',
              'budgets',
            ],
          },
        },
        { relation: 'userGroups', scope: { include: ['users'] } },
        {
          relation: 'events',
          scope: {
            // only show latest event
            order: 'createdAt DESC',
            limit: 50,
            include: ['eventType', 'targetPrincipal', 'modifiedBy'],
          },
        },
      ],
    };

    partyStateActions
      .loadParty(id, filter)
      .then(() => {
        partyStateActions.getAddresses(id).then(() => {
          setLoading(false);
        });
      })
      .catch(() => {
        history.replace('/notFoundPage');
      });
  };

  const handleMenuItemChange = (event, { name }) => {
    changeMenuItem(name);
  };

  const changeMenuItem = (name) => {
    history.push(`/party/${id}/${name}`);
    setCurrentMenuItem(name);
  };

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader disabled={!loading} />
      </Dimmer>
      {party && (
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <PartyDetails />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={2} floated="left" className="fixed">
              <PartyMenu
                handleItemClick={handleMenuItemChange}
                currentItem={currentMenuItem}
                structuralNodeId={party.structuralNodeId}
              />
            </Grid.Column>
            <Grid.Column width={14} floated="right">
              {currentMenuItem && <MainCompnent />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
};

PartyPage.propTypes = {
  party: PropTypes.object,
  partyStateActions: PropTypes.object,
  partyActions: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default ErrorBoundary(
  withRouter(ConfirmWrapper(PartyWrapper(PartyPage)))
);
