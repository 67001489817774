import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';

const FieldToolTip = (props) => {
  const { description } = props;
  if (!description) return null;
  return (
    <Popup
      wide
      trigger={<Icon name="question circle outline" />}
      content={description}
      position="bottom center"
    />
  );
};

FieldToolTip.propTypes = {
  description: PropTypes.string,
};

export default FieldToolTip;
