/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Input, Form, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as partyActions from '../../actions/partyActions';
import Select from '../common/fields/Select';

class SaveUserForm extends Component {
  state = {
    loading: false,
  };
  componentDidMount() {
    const { parties, partyActions } = this.props;
    if (!parties.length) {
      this.setState({ loading: true });
      partyActions.loadParties().then(() => {
        this.setState({ loading: false });
      });
    }
  }
  render() {
    const { user, parties, handleInputChanged, isNewUser } = this.props;
    const { firstName, lastName, email, partyId, isAdmin } = user;
    const { loading } = this.state;
    return (
      <Form>
        <Form.Field>
          <Input
            fluid
            placeholder="First Name"
            value={firstName}
            name="firstName"
            onChange={handleInputChanged}
          />
        </Form.Field>
        <Form.Field>
          <Input
            fluid
            placeholder="Last Name"
            value={lastName}
            name="lastName"
            onChange={handleInputChanged}
          />
        </Form.Field>
        <Form.Field>
          <Input
            fluid
            placeholder="Email"
            value={email}
            name="email"
            onChange={handleInputChanged}
          />
        </Form.Field>
        <Select
          placeholder="Select party"
          fluid
          selection
          onChange={handleInputChanged}
          name="partyId"
          options={parties ? parties : []}
          value={partyId}
          loading={loading}
          sortItemsAlphabetically={true}
        />
        <Form.Field>
          <Checkbox
            label="Is Admin"
            name="isAdmin"
            onChange={handleInputChanged}
            checked={isAdmin}
            readOnly={!isNewUser}
          />
        </Form.Field>
      </Form>
    );
  }
}

SaveUserForm.propTypes = {
  user: PropTypes.object,
  parties: PropTypes.array,
  handleInputChanged: PropTypes.func,
  partyActions: PropTypes.object,
  isNewUser: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    parties: state.parties.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    partyActions: bindActionCreators(partyActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveUserForm);
