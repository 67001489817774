import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as uiActions from '../../actions/uiActions';
import { bindActionCreators } from 'redux';
import * as ticketActions from '../../actions/ticketActions';
import ticketApi from '../../api/ticketApi';
import TicketContext from '../../context/TicketContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import { SIDEBAR_COMPONENTS } from '../common/SidebarRoot';
import * as MODELS from '../../constants/models';
import { getOrderedTicketsFromTicketSets } from '../../utils/TicketsUtils';
import WorkflowWrapper from '../workflow/Workflow';
import * as _ from 'lodash';
import moment from 'moment';
import toastr from 'toastr';
import {
  checkUserInputPermission,
  validateTransition,
} from '../../utils/TicketUtils';
import UserWrapper from '../user/User';

function TicketWrapper(ComponentToWrap) {
  class Ticket extends Component {
    state = {
      currentModal: '',
      modalConfig: {},
      isTicketLoading: false,
      transitioning: false,
      resolving: false,
      recommendationOverride: '',
      transitionErrors: [],
      saved: false,
    };

    gotoTicket = (ticket) => {
      this.props.history.push(`/ticket/${ticket.id}`);
    };

    loadTickets = (ticketSets) => {
      const { ticketStateActions, tickets } = this.props;
      const ticketsFromSets = getOrderedTicketsFromTicketSets(ticketSets);
      const ticketsToLoad = ticketsFromSets.filter(
        (ticket) => !tickets[ticket]
      );

      ticketStateActions.loadTickets({ tickets: ticketsToLoad });
    };

    setTicket = ({ id, isPreview = true, autoLoad = true }) => {
      if (id === this.state.id) return;
      const { tickets, ticketStateActions } = this.props;

      this.setState({ id });
      const currentTicket = tickets[id];
      if (
        (autoLoad && !currentTicket) ||
        (!isPreview && currentTicket.isPreview === true)
      ) {
        this.setState({ isTicketLoading: true });
        return ticketStateActions.loadTicket({ id, isPreview }).then(() => {
          this.setState({ isTicketLoading: false });
        });
      }
      if (currentTicket && autoLoad) {
        ticketApi.getSimpleTicket({ id }).then((ticket) => {
          const isUpdatedOnServer = moment(ticket.modifiedAt).isAfter(
            currentTicket.modifiedAt
          );
          if (isUpdatedOnServer) {
            this.setState({ isTicketLoading: true });
            return ticketStateActions.loadTicket({ id, isPreview }).then(() => {
              this.setState({ isTicketLoading: false });
            });
          }
        });
      }
    };

    get ticket() {
      const { tickets } = this.props;
      const { id } = this.state;

      if (id && tickets[id]) {
        return tickets[id];
      }
    }

    resolutionClicked = (event, resolution) => {
      const { ticketStateActions } = this.props;
      const ticket = this.ticket;
      this.setState({ resolving: true });
      const { statusId, id } = resolution;

      ticketStateActions
        .resolve(ticket, statusId, id, resolution)
        .then(() => {
          this.setState({ resolving: false });
        })
        .catch((error) => {
          toastr.error(error);
          this.setState({ resolving: false, error: error });
        });
    };

    transitionClicked = (event, transition) => {
      const ticket = this.ticket;
      const { workflowActions } = this.props;
      const currentStatusNode = this.ticket
        ? workflowActions.getStatusNode(this.ticket.statusNodeId)
        : null;
      const validate = validateTransition(
        ticket,
        transition,
        currentStatusNode
      );
      if (validate.errors) {
        toastr.error(validate.errors.map((error) => `${error.label}<br/>`));
        this.setState({
          transitionErrors: validate.errors.map((error) => error.label),
        });
        return;
      }

      this.setState({ transitioning: true, transitionErrors: [] });
      if (ticket.edited) {
        this.checkEditedFields(transition);
      } else {
        this.transitionTicket(transition);
      }
    };

    saveWorkflowFields = () => {
      const { ticketStateActions } = this.props;
      return ticketStateActions.saveWorkflowFields(this.ticket);
    };

    checkEditedFields = (transition) => {
      const { ticketStateActions } = this.props;
      const currentTicket = this.ticket;
      const currentUserDetails = this.props.currentUser.details;
      if (
        _.every(['entries', 'workflowEntries'], (el) =>
          _.includes(currentTicket.edited, el)
        )
      ) {
        ticketStateActions
          .saveActivityFields(currentTicket, currentUserDetails)
          .then(() => {
            ticketStateActions.saveWorkflowFields(currentTicket).then(() => {
              this.setState({ saved: true });
              this.transitionTicket(transition);
            });
          });
      } else {
        if (_.includes(currentTicket.edited, 'entries')) {
          ticketStateActions
            .saveActivityFields(currentTicket, currentUserDetails)
            .then(() => {
              this.setState({ saved: true });
              this.transitionTicket(transition);
            });
        } else {
          if (_.includes(currentTicket.edited, 'workflowEntries')) {
            ticketStateActions.saveWorkflowFields(currentTicket).then(() => {
              this.setState({ saved: true });
              this.transitionTicket(transition);
            });
          }
        }
      }
    };

    transitionTicket = (transition) => {
      const { ticketStateActions } = this.props;
      const ticket = this.ticket;
      const { id } = transition;
      ticketStateActions
        .transition(ticket, id)
        .then(() => {
          this.setState({ transitioning: false });
        })
        .catch((error) => {
          toastr.error(error);
          this.setState({ transitioning: false, error: error });
          console.error(error);
        });
    };

    openAssignTicketsModal = (assignObject, isTicketSet = true) => {
      this.props.uiActions.showModal({
        modalType: MODAL_COMPONENTS.ASSIGN_TICKET_MODAL,
        modalProps: {
          assignObject,
          isTicketSet,
        },
      });
    };

    openAttachment = (ticket, attachment) => {
      ticketApi.openAttachment(ticket, attachment.id);
    };

    openMoreInfoSidebar = (ticket, handleReadAll, isReadAll) => {
      const { uiActions } = this.props;
      uiActions.showSidebar({
        sidebarType: SIDEBAR_COMPONENTS.MORE_INFO_SIDEBAR,
        sidebarProps: { ticket, handleReadAll, isReadAll },
        sidebarConfig: { width: 'wide' },
      });
    };

    openAddAttachmentModal = (ticket) => {
      const {
        uiActions,
        ticketStateActions,
        maxFileSize,
        currentUser,
      } = this.props;
      const { details } = currentUser;
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.ADD_EXISTING_ATTACHMENT,
        modalProps: {
          currentModel: ticket,
          modelStateActions: ticketStateActions,
          modelType: MODELS.EXECUTION_TICKETS,
          maxFileSize,
          currentUser: details,
        },
      });
    };

    openEditAttachmentDetailsModal = (attachment) => {
      const { uiActions, ticketId, currentUser } = this.props;
      const userDetails = currentUser.details;
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.EDIT_ATTACHMENT_DETAILS,
        modalProps: {
          attachment,
          userDetails,
          ticketId,
        },
      });
    };

    render() {
      const {
        attachmentsConstants,
        ticketStateActions,
        fullPageView,
        workflowActions,
        currentUser,
      } = this.props;
      const {
        isTicketLoading,
        resolving,
        transitioning,
        transitionErrors,
      } = this.state;

      const currentStatusNode = this.ticket
        ? workflowActions.getStatusNode(this.ticket.statusNodeId)
        : null;

      let currentDeadlineDate, currentStatusOverdue;
      if (currentStatusNode) {
        const { workflows } = this.props;
        const { statusId } = currentStatusNode;
        const { workflowId, deadlines } = { ...this.ticket };
        const currentWorkflow = workflows.byId[workflowId];
        const { statuses } = { ...currentWorkflow };
        const orderedStatuses = _.orderBy(statuses, (status) => status.order);
        const currentStatusOrder =
          _.findIndex(orderedStatuses, (status) => status.id === statusId) + 1;

        const nextStatuses = _.filter(
          orderedStatuses,
          (status) => status.order > currentStatusOrder
        );
        if (nextStatuses.length) {
          const nextStatusesFromDeadline = _.filter(
            nextStatuses,
            (nextStatus) =>
              _.find(
                deadlines,
                (deadline) => nextStatus.id === deadline.workflowStatusId
              )
          );
          const nextDeadlineStatus = _.first(nextStatusesFromDeadline);
          if (nextDeadlineStatus) {
            const nextDeadline = _.find(
              deadlines,
              (deadline) => deadline.workflowStatusId === nextDeadlineStatus.id
            );

            currentDeadlineDate = nextDeadline
              ? nextDeadline.deadlineDate
              : null;
            currentStatusOverdue =
              moment().diff(moment(currentDeadlineDate)) > 0;
          }
        }
      }
      const dashboardId = _.get(this.ticket, 'activity.meta.dashboardId');
      const currentFields = _.has(this.ticket, 'activity')
        ? _.filter(
            this.ticket.activity.fields,
            ({ workflowStatusInputTypeId }) =>
              _.isNull(workflowStatusInputTypeId)
          )
        : [];
      const allowDataEntry = currentStatusNode
        ? currentStatusNode.status.allowDataEntry
        : false;
      const userHasInputPermission = checkUserInputPermission(
        currentUser,
        this.ticket,
        currentStatusNode
      );

      const userHasAssignPermission =
        userHasInputPermission ||
        (this.ticket &&
          this.ticket.assignedPrincipalId === currentUser.details.principalId);
      const values = {
        ticket: this.ticket,
        currentTicket: this.ticket,
        attachmentsConstants,
        ticketActions: extractFunctions(this),
        ticketStateActions,
        isTicketLoading,
        resolving,
        transitioning,
        fullPageView,
        currentStatusNode,
        currentDeadlineDate,
        currentStatusOverdue,
        dashboardId,
        currentFields,
        allowDataEntry,
        userHasInputPermission,
        userHasAssignPermission,
        transitionErrors,
      };

      return (
        <TicketContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </TicketContext.Provider>
      );
    }
  }

  Ticket.propTypes = {
    ticketStateActions: PropTypes.object,
    workflowStateActions: PropTypes.object,
    uiActions: PropTypes.object.isRequired,
    history: PropTypes.object,
    currentUser: PropTypes.object,
    maxFileSize: PropTypes.number,
    attachmentsConstants: PropTypes.object,
    tickets: PropTypes.object,
    ticketId: PropTypes.number,
    workflow: PropTypes.object,
    workflowActions: PropTypes.object,
    fullPageView: PropTypes.bool,
    workflows: PropTypes.object,
  };

  function mapStateToProps(state) {
    const { constants } = state;
    const attachmentsConstants = {
      attachmentClassification: constants.attachmentClassification,
      attachmentType: constants.attachmentType,
    };
    return {
      tickets: state.tickets.byId,
      maxFileSize: state.constants.options.maxFileSize,
      attachmentsConstants,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      uiActions: bindActionCreators(uiActions, dispatch),
      ticketStateActions: bindActionCreators(ticketActions, dispatch),
    };
  }
  return UserWrapper(
    WorkflowWrapper(connect(mapStateToProps, mapDispatchToProps)(Ticket))
  );
}

export default TicketWrapper;
