import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, Dropdown, Form } from 'semantic-ui-react';

import { loadPartiesWithRelationships } from '../../actions/partyActions';
import { loadExecutions } from '../../actions/executionActions';
import { useAppSelector } from '../../actions/store';
import { ExecutionExtended } from '../../types';
import { Link } from 'react-router-dom';

import './FairValueDistributorView.scss';
import { getTickets } from '../../actions/fairValueActions';

import ListParty from './FairValueListParty';

const FairValueDistributorView = () => {
  const dispatch = useDispatch();
  const parties = useAppSelector((state) => state.parties.list);
  const currentUser = useAppSelector((state) => state.currentUser);
  const executions = useAppSelector((state) => state.executions);
  const fairValueTickets = useAppSelector((state) => state.fairValue.list);

  const workflowStatusTypes = useAppSelector(
    (state) => state.constants.workflowStatusTypes
  );

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>([]);
  const [productClassFilter, setProductClassFilter] = useState<string[]>([]);

  const productOptions = useMemo(() => {
    if (!executions) return [];
    return (
      executions
        .filter((execution) => execution.programmeRef === 'CD-FV-M')
        // .filter(execution => !!execution.entries?.find(entry => entry.field.fieldKey === "CD-FV-PRODUCT")?.value )
        // .filter(execution => find(ticketsById, {executionId: execution.id}))
        .map((execution, index) => {
          const productEntity = execution.entities?.find(
            (entity) => entity.entityTypeId === 'PRODUCT'
          );
          const productName = productEntity?.label;
          const productClass =
            productEntity?.entries?.find(
              (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS'
            )?.value || '';
          return {
            text: `${productClass} - ${productName}`,
            value: productEntity?.id,
            key: index,
          };
        })
    );
  }, [executions]);

  const productClassOptions = useMemo(() => {
    if (!executions) return [];

    const productClassArray = executions
      .filter((execution) => execution.programmeRef === 'CD-FV-M')
      // .filter(execution => !!execution.entries?.find(entry => entry.field.fieldKey === "CD-FV-PRODUCT")?.value )
      // .filter(execution => find(ticketsById, {executionId: execution.id}))
      .map((execution) => {
        const productEntity = execution.entities?.find(
          (entity) => entity.entityTypeId === 'PRODUCT'
        );
        const productClass =
          productEntity?.entries?.find(
            (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS'
          )?.value || '';
        return productClass;
      });

    return Array.from(new Set<any>(productClassArray)).map((productClass) => ({
      text: productClass,
      value: productClass,
    }));
  }, [executions]);

  // const executionsById = useMemo(() => {
  //   return executions.reduce((previous, current) => {
  //     return {
  //       ...previous,
  //       [current.id]: current,
  //     };
  //   }, {} as { [key: number]: ExecutionExtended });
  // }, [executions]);

  useEffect(() => {
    dispatch(loadPartiesWithRelationships());
    dispatch(
      loadExecutions({
        include: [{ entries: 'field' }, { entities: 'entries' }],
      })
    );
    dispatch(getTickets());
  }, []);

  const rootParty = parties.find(
    (party) => party.id === currentUser.details.partyId
  );

  return (
    <>
      {/* <p>Root: {rootParty?.label}</p> */}

      {/* {rootParty && (<List>{renderParty(rootParty)}</List>)} */}
      {/* <pre>{JSON.stringify(fairValueTickets)}</pre> */}
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Status</label>
            <Dropdown
              placeholder="Status"
              fluid
              selection
              multiple
              options={[
                {
                  text: 'To Do',
                  value: 'TODO',
                  icon: { name: 'circle', color: 'grey' },
                },
                {
                  text: 'Awaiting Approval',
                  value: 'APPROVAL',
                  icon: { name: 'circle', color: 'blue' },
                },
                {
                  text: 'Exception',
                  value: 'REJECTED',
                  icon: { name: 'circle', color: 'red' },
                },
                {
                  text: 'Done',
                  value: 'DONE',
                  icon: { name: 'circle', color: 'green' },
                },
              ]}
              value={statusFilter}
              onChange={(_, { value }) => setStatusFilter(value as string[])}
            />
          </Form.Field>

          <Form.Field>
            <label>Product</label>
            <Dropdown
              placeholder="Product    "
              fluid
              selection
              multiple
              options={productOptions}
              value={productFilter}
              onChange={(_, { value }) => setProductFilter(value as string[])}
            />
          </Form.Field>

          <Form.Field>
            <label>Class</label>
            <Dropdown
              placeholder="Class    "
              fluid
              selection
              multiple
              options={productClassOptions}
              value={productClassFilter}
              onChange={(_, { value }) =>
                setProductClassFilter(value as string[])
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>

      {rootParty &&
        rootParty.partyRelationships
          ?.filter(
            (relationship) => relationship.partyRelationshipTypeId === 'DIST'
          )
          .map((distributorRelationship) => {
            const distributor = parties.find(
              (party) => party.id === distributorRelationship.toPartyId
            );
            if (!distributor || !parties || !workflowStatusTypes) return null;
            return (
              <Accordion styled fluid key={distributor.id}>
                <ListParty
                  fairValueTickets={fairValueTickets}
                  party={distributor}
                  statusFilter={statusFilter}
                  productFilter={productFilter}
                  productClassFilter={productClassFilter}
                  topLevel={true}
                  parties={parties}
                  workflowStatusTypes={workflowStatusTypes}
                  LinkRenderer={(ticket) => {
                    return (
                      <Link to={`/ticket/${ticket.id}`}>
                        {ticket.ticketSetLabel}
                      </Link>
                    );
                  }}
                />
              </Accordion>
            );
          })}
    </>
  );
};

export default FairValueDistributorView;
