import React from 'react';
import * as PropTypes from 'prop-types';
import { PartyWrapContext } from '../../context/PartyContext';
import { Table } from 'semantic-ui-react';
import ExecutionPlanListItem from './ExecutionPlanListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';

const PlanList = (props) => {
  const {
    executionPlans,
    handleSortChange,
    paginationOptions,
    handleAddFilter,
    currentFilters,
    removeFilterValues,
  } = props;
  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            removeFilterValues={removeFilterValues}
            isSearchable={true}
          >
            Programme Name
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="ownerParty"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            removeFilterValues={removeFilterValues}
          >
            Owner Party
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="assignedParty"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            removeFilterValues={removeFilterValues}
          >
            Assigned Party
          </SortableHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {executionPlans.map((executionPlan, index) => {
          return (
            <ExecutionPlanListItem key={index} executionPlan={executionPlan} />
          );
        })}
      </Table.Body>
    </Table>
  );
};

PlanList.propTypes = {
  executionPlans: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  handleAddFilter: PropTypes.func,
  currentFilters: PropTypes.array,
  removeFilterValues: PropTypes.func,
};

export default PartyWrapContext(PlanList);
