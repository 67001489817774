import {createContext, Component} from 'react';

const TicketContext = createContext();

export const TicketContextConsumer = (ComponentToWrap) =>
  class WrapContext extends Component {
    render() {
      return (
        <TicketContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </TicketContext.Consumer>
      );
    }
  };

export default TicketContext;
