import React, { ReactNode } from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import StatusTableRow from './StatusTableRow';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { getActiveItems } from '../../utils/PartyUtils';
import { useAppSelector } from '../../actions/store';
import { orderBy } from 'lodash';

interface StatusListProps {
  entities: any[];
  handleSortChange: (sortField: string) => void;
  paginationOptions: object;
  handleAddFilter: (filters: object) => void;
  removeFilterValues: (filters: string[]) => void;
  currentFilters: object;
  children: ReactNode;
}

const StatusList = (props: StatusListProps) => {
  const {
    entities,
    handleSortChange,
    paginationOptions,
    handleAddFilter,
    removeFilterValues,
    currentFilters,
  } = props;

  // const renderStatusList = () => {
  //   return getActiveItems(entities).map((status) => (
  //     <StatusTableRow status={status} key={status.id} />
  //   ));
  // };

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
            isSearchable={true}
          >
            Label
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="ref"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
            isSearchable={true}
          >
            Reference
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="creationStatus"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
            collapsing
          >
            Status
          </SortableHeaderCell>

          <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.children}
        {/* {renderStatusList()} */}
      </Table.Body>
    </Table>
  );
};

export default StatusList;
