import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Divider, Header, Segment } from 'semantic-ui-react';
import TicketPageActions from './TicketPageActions';
import TicketFields from '../TicketFields/TicketFields';
import { getDefaultValues } from '../../../utils/TicketUtils';
import TicketWorkflowEntries from '../TicketWorkflowEntries';
import { TicketContextConsumer } from '../../../context/TicketContext';
import { WorkflowWrapContext } from '../../../context/WorkflowContext';
import UserWrapper from '../../user/User';

const TicketPageWorkflowStatuses = (props) => {
  const {
    workflow,
    currentStatusNode,
    ticket,
    resolving,
    userHasInputPermission,
    ticketStateActions,
    currentUser,
    allowDataEntry,
    ticketActions,
  } = props;

  if (!workflow || !ticket) return <div>TicketPageWorkflowStatuses </div>;

  return (
    <React.Fragment>
      {workflow.statuses.map((status) => {
        const isCurrentNode = currentStatusNode
          ? currentStatusNode.statusId === status.id
          : false;
        const entries = ticket.workflowEntries
          ? ticket.workflowEntries.filter((entry) => {
              return (
                _.findIndex(status.fields, {
                  id: entry.workFlowStatusFieldId,
                }) !== -1
              );
            })
          : [];
        const { key: statusKey } = status;
        const isCurrentWorkflowField = (field) => {
          const { workflowStatusInputTypeId } = field;
          return workflowStatusInputTypeId === statusKey;
        };
        const isStatusWithoutKey = statusKey === null;
        const ticketEntries = !isStatusWithoutKey
          ? _.filter(ticket.entries, ({ field }) => {
              return isCurrentWorkflowField(field);
            })
          : [];
        const workflowStatusFields = !isStatusWithoutKey
          ? _.filter(_.get(ticket, 'activity.fields') || [], (field) => {
              return isCurrentWorkflowField(field);
            })
          : [];
        const entriesAreEmpty = entries.length === 0;
        const statusFieldsAreEmpty = workflowStatusFields.length === 0;
        const fieldsAreEmpty = status.fields.length === 0;
        const ticketEntriesAreEmpty = ticketEntries.length === 0;

        if (!isCurrentNode && entriesAreEmpty && ticketEntriesAreEmpty)
          return null;
        if (isCurrentNode && statusFieldsAreEmpty && fieldsAreEmpty)
          return null;

        return (
          <React.Fragment key={status.id}>
            <Header attached="top" size="large" inverted>
              {status.name}
            </Header>
            <Segment color={isCurrentNode ? 'green' : null} attached="bottom">
              {isCurrentNode && <TicketPageActions order={0} />}
              <TicketFields
                fields={workflowStatusFields}
                entries={ticketEntries}
                onSave={() =>
                  ticketStateActions.saveActivityFields(
                    ticket,
                    currentUser.details
                  )
                }
                enabled={userHasInputPermission}
                allowDataEntry={allowDataEntry}
              />
              {workflowStatusFields.length > 0 && status.fields.length > 0 && (
                <Divider />
              )}
              {isCurrentNode ? (
                <React.Fragment>
                  <TicketFields
                    defaults={getDefaultValues(ticket, currentStatusNode)}
                    fields={status.fields}
                    fieldsPerRow={1}
                    entries={[...ticket.workflowEntries, ...ticket.entries]}
                    onSave={ticketActions.saveWorkflowFields}
                    enabled={userHasInputPermission}
                    allowDataEntry={true}
                    resolving={resolving}
                    type="workflowFields"
                    workflowTypes={[statusKey]}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TicketWorkflowEntries entries={entries} />
                </React.Fragment>
              )}
            </Segment>
          </React.Fragment>
        );
      })}{' '}
    </React.Fragment>
  );
};

TicketPageWorkflowStatuses.propTypes = {
  workflow: PropTypes.object,
  currentStatusNode: PropTypes.object,
  ticket: PropTypes.object,
  resolving: PropTypes.bool,
  userHasInputPermission: PropTypes.bool,
  ticketStateActions: PropTypes.object,
  currentUser: PropTypes.object,
  allowDataEntry: PropTypes.bool,
  ticketActions: PropTypes.object,
};

export default UserWrapper(
  WorkflowWrapContext(TicketContextConsumer(TicketPageWorkflowStatuses))
);
