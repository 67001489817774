import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import StatusNodeTransitionListRow from './StatusNodeTransitionListRow';

const StatusNodeTransitionList = (props) => {
  const {
    transitions,
    statusNode,
    statusNodes,
    addTransition,
    updateTransition,
    removeTransition,
  } = props;
  return (
    <React.Fragment>
      <Table collapsing basic="very">
        <Table.Body>
          {transitions.map((transition, index) => (
            <StatusNodeTransitionListRow
              key={index}
              transition={transition}
              statusNode={statusNode}
              statusNodes={statusNodes}
              onChange={(event, data) => {
                updateTransition(event, data, transition);
              }}
              onRemove={(event) => {
                removeTransition(event, transition);
              }}
            />
          ))}
          <Table.Row>
            <Table.Cell colSpan="2">
              <Button onClick={addTransition}>Add Transition</Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

StatusNodeTransitionList.propTypes = {
  transitions: PropTypes.array.isRequired,
  statusNode: PropTypes.object,
  statusNodes: PropTypes.array,
  addTransition: PropTypes.func,
  updateTransition: PropTypes.func,
  removeTransition: PropTypes.func,
};

export default StatusNodeTransitionList;
