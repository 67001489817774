import { orderBy } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  Button,
  Checkbox,
  CheckboxProps,
  Dimmer,
  Icon,
  Loader,
  Table,
} from 'semantic-ui-react';
import { loadEntities, loadEntityTypes } from '../../actions/entityActions';
import { useAppSelector } from '../../actions/store';
import { Entity } from '../../types';
import * as types from '../../constants/actionTypes';
import { addEntriesToEntities } from '../../utils/EntityUtils';
import ProductList from '../products/ProductList';

type EntityAssignmentProps = {};

export const EntityAssignment = ({}: EntityAssignmentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accordionActive, setAccordionActive] = useState<string[]>([]);
  const dispatch = useDispatch();
  const entities = useAppSelector((state) =>
    orderBy(state.entity.entities, 'label')
  );
  const entitiesType = useAppSelector(
    (state) => state.entity.entityTypes.array
  );

  useEffect(() => {
    const fetchEntities = async () => {
      setIsLoading(true);
      await dispatch(
        loadEntities({
          //   where: { structuralNodeId: party.structuralNodeId },
          include: [{ entries: 'field' }, 'entityType'],
        })
      );
      await dispatch(loadEntityTypes());

      //   if (!entityType) dispatch(loadEntityType("PRODUCT"));
      setIsLoading(false);
    };

    fetchEntities();
  }, []);

  const handleSelectAll = (entities: Entity[]) => {
    dispatch({
      type: types.SELECT_ENTITY,
      entities,
    });
  };

  const handleDeselectAll = (entities: Entity[]) => {
    dispatch({
      type: types.DESELECT_ENTITY,
      entities,
    });
  };

  const loading = !entities || !entitiesType || isLoading;

  const entitiesWithEntries = addEntriesToEntities(entities);

  if (loading) {
    return (
      <Dimmer active={true} inverted>
        <Loader />
      </Dimmer>
    );
  }

  const handleAccordionClick = (entityTypeId: string) => {
    if (accordionActive.includes(entityTypeId)) {
      setAccordionActive(accordionActive.filter((id) => id !== entityTypeId));
    } else {
      setAccordionActive([...accordionActive, entityTypeId]);
    }
  };

  return (
    <div>
      <Accordion styled fluid>
        {entitiesType?.map((entityType) => {
          const entitiesOfType = entitiesWithEntries.filter((entity) => {
            return entity.entityTypeId === entityType.id;
          });
          return (
            <React.Fragment key={entityType.id}>
              <Accordion.Title
                key={entityType.id}
                active={accordionActive.includes(entityType.id)}
                index={0}
                onClick={() => handleAccordionClick(entityType.id)}
              >
                <Icon name="dropdown" />
                {entityType.label}
              </Accordion.Title>
              <Accordion.Content
                active={accordionActive.includes(entityType.id)}
              >
                <Button onClick={() => handleSelectAll(entitiesOfType)}>
                  Select All
                </Button>
                <Button onClick={() => handleDeselectAll(entitiesOfType)}>
                  Deselect All
                </Button>
                <ProductList entities={entitiesOfType || []} mode="SELECTION" />
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    </div>
  );
};
