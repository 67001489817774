import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Segment,
  Message,
} from 'semantic-ui-react';

import UserWrapper from './User';
import Text from './../common/fields/Text';

class ResetPasswordPage extends Component {
  state = {
    loading: false,
    token: '',
    formData: {
      email: '',
      newPassword: '',
      confirmPassword: '',
    },
    errors: {
      validationErrors: null,
      apiErrors: null,
    },
  };

  componentWillMount() {
    const url = new URL(window.location.href);
    this.setState({ token: url.searchParams.get('token') });
  }

  submitForm = () => {
    const { email, newPassword, confirmPassword } = this.state.formData;
    const { token } = this.state;
    const { userStateActions, history, eventsActions, constantStateActions } =
      this.props;
    this.setState({ loading: true });

    userStateActions
      .updatePassword(email, newPassword, confirmPassword, token)
      .then(() => {
        constantStateActions.loadConfig();
        constantStateActions.loadModelStructure();
        eventsActions.cleanEventsList();
        eventsActions.getEventsList({});
        history.push('/');
      })
      .catch((respErrors) => {
        console.log(respErrors);

        const validationErrors = respErrors.validationErrors;
        let errors = {};
        if (validationErrors) {
          errors = { validationErrors };
        } else {
          errors = {
            apiErrors:
              respErrors.message || respErrors.response?.data?.error?.message,
          };
        }

        this.setState({
          errors,
          loading: false,
        });
      });
  };

  handleChange = (event, { name, value }) => {
    const formData = { ...this.state.formData, [name]: value };
    this.setState({ formData });
  };

  renderError = () => {
    const { apiErrors, validationErrors } = this.state.errors;
    let errorContent = '';
    if (validationErrors && validationErrors.token)
      errorContent = validationErrors.token;
    if (apiErrors) errorContent = apiErrors;
    if (errorContent) return <Message error content={errorContent} />;
    return null;
  };

  render() {
    const { loading } = this.state;
    const { validationErrors } = this.state.errors;
    const { email, newPassword, confirmPassword } = this.state.formData;
    return (
      <Container className="login-page">
        <Grid stackable centered columns={3}>
          <Grid.Column>
            <Divider hidden />
            <Segment>
              <Form className="form-reset-password" onSubmit={this.submitForm}>
                <Text
                  label="Email"
                  value={email}
                  onChange={this.handleChange}
                  name="email"
                  errors={validationErrors}
                  debounce={false}
                />
                <Text
                  label="New Password"
                  value={newPassword}
                  onChange={this.handleChange}
                  name="newPassword"
                  type="password"
                  errors={validationErrors}
                  debounce={false}
                />
                <Text
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={this.handleChange}
                  name="confirmPassword"
                  type="password"
                  errors={validationErrors}
                  debounce={false}
                />
                <Button disabled={loading} loading={loading} type="submit">
                  Submit
                </Button>
                {this.renderError()}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

ResetPasswordPage.propTypes = {
  userStateActions: PropTypes.object.isRequired,
  history: PropTypes.object,
  eventsActions: PropTypes.object,
  constantStateActions: PropTypes.object,
};

export default UserWrapper(ResetPasswordPage);
