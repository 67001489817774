import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';
import { Table, Accordion } from 'semantic-ui-react';
import Moment from 'react-moment';
import PartyLabel from '../common/labels/PartyLabel';

class ExecutionPlanDetails extends Component {
  state = {
    showMoreDetails: false,
  };

  handleDetailsClick = () => {
    const { showMoreDetails } = this.state;
    this.setState({ showMoreDetails: !showMoreDetails });
  };

  render() {
    const { executionPlan, currentExecutionPlan } = this.props;
    const { showMoreDetails } = this.state;
    const existingExecutionPlan = executionPlan || currentExecutionPlan;
    return (
      <div>
        <Table
          compact
          basic="very"
          stackable
          className="execution-plan-details"
        >
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing verticalAlign="top">
                Assigned Party:
              </Table.Cell>
              <Table.Cell verticalAlign="top" collapsing>
                {existingExecutionPlan.assignedParty && (
                  <PartyLabel party={existingExecutionPlan.assignedParty} />
                )}
              </Table.Cell>
            </Table.Row>

            {existingExecutionPlan.reviewParty && (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell collapsing verticalAlign="top">
                    Review Party:
                  </Table.Cell>
                  <Table.Cell verticalAlign="top" collapsing>
                    {existingExecutionPlan.reviewParty && (
                      <PartyLabel party={existingExecutionPlan.reviewParty} />
                    )}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
          </Table.Body>
        </Table>

        <Accordion>
          <Accordion.Title onClick={() => this.handleDetailsClick()} as="h3">
            <p className="more-info-button">
              {showMoreDetails ? 'Less details' : 'More details'}
            </p>
          </Accordion.Title>
          <Accordion.Content active={showMoreDetails}>
            <Table
              compact
              basic="very"
              stackable
              className="execution-plan-details"
            >
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing verticalAlign="top">
                    Owner Party:
                  </Table.Cell>
                  <Table.Cell verticalAlign="top" collapsing>
                    {existingExecutionPlan.ownerParty && (
                      <PartyLabel party={existingExecutionPlan.ownerParty} />
                    )}
                  </Table.Cell>
                </Table.Row>

                {existingExecutionPlan.workflow && (
                  <Table.Row>
                    <Table.Cell collapsing verticalAlign="top">
                      Workflow:
                    </Table.Cell>
                    <Table.Cell verticalAlign="top">
                      {existingExecutionPlan.workflow.label}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell collapsing verticalAlign="top">
                    Created Date:
                  </Table.Cell>
                  <Table.Cell verticalAlign="top">
                    <Moment>{existingExecutionPlan.createdAt}</Moment>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing verticalAlign="top">
                    Last Modified:
                  </Table.Cell>
                  <Table.Cell verticalAlign="top">
                    <Moment>{existingExecutionPlan.modifiedAt}</Moment>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

ExecutionPlanDetails.propTypes = {
  executionPlan: PropTypes.object,
  currentExecutionPlan: PropTypes.object,
};

export default ExecutionPlanWrapContext(ExecutionPlanDetails);
