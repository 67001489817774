import useColumnHeight from '../../utils/hooks/useColumnHeight';

interface ScrollingContentProps {
  className?: string;
  children?: React.ReactNode;
}

export const ScrollingContent = ({
  children,
  className,
}: ScrollingContentProps) => {
  const [ref, columnHeight] = useColumnHeight('auto', 50);

  return (
    <div
      className={className}
      ref={ref}
      style={{
        height: columnHeight,
        overflowY: 'scroll',
      }}
    >
      {children}
    </div>
  );
};
