import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Moment from 'react-moment';
import toastr from 'toastr';

import { pluralize } from '../../utils/Pluralize';
import {
  LAUNCH_TYPES,
  REPEAT_TYPES,
} from '../../utils/ExecutionPlanLaunchConsts';
import { getWeekdays, getLaunchType } from '../../utils/ExecutionPlansUtils';
import ActionButtons from './../common/ActionButtons';
import ApplicableDateLabel from '../common/ApplicableDateLabel';

class ScheduledLaunchesListItem extends Component {
  state = {
    loading: false,
  };
  deleteScheduledExecutionPlanClicked = () => {
    const { showConfirmDialog } = this.props;
    showConfirmDialog(
      `Are you sure you want to delete this scheduled launch?`,
      this.deleteScheduledExecutionPlanOnConfirm
    );
  };
  deleteScheduledExecutionPlanOnConfirm = () => {
    const { deleteScheduledExecutionPlan } = this.props;
    const { executionPlanId, id } = this.props.schedule;
    this.setState({ loading: true });
    deleteScheduledExecutionPlan(executionPlanId, id).then(() => {
      this.setState({ loading: false });
      this.deleteScheduledExecutionPlanOnConfirmCallback();
    });
  };
  deleteScheduledExecutionPlanOnConfirmCallback = () => {
    toastr.success(`Scheduled launch is removed`);
  };
  render() {
    const { schedule } = this.props;
    const { loading } = this.state;
    const {
      startDate,
      dueDate,
      launchType,
      every,
      repeatType,
      nextLaunchDate,
      applicableDate,
      applicableDateTypeId,
    } = schedule;
    return (
      <Table.Row>
        <Table.Cell>
          {startDate && <Moment format="DD/MM/YY">{startDate}</Moment>}
        </Table.Cell>
        <Table.Cell>
          {dueDate && <Moment format="DD/MM/YY">{dueDate}</Moment>}
        </Table.Cell>
        <Table.Cell>{launchType && getLaunchType(launchType)}</Table.Cell>
        <Table.Cell>
          {nextLaunchDate && (
            <Moment format="DD/MM/YY">{nextLaunchDate}</Moment>
          )}
        </Table.Cell>
        <Table.Cell>
          {launchType === LAUNCH_TYPES.EVERY &&
            `${pluralize(repeatType, every, true)} ${
              repeatType === REPEAT_TYPES.WEEK
                ? `on ${getWeekdays(schedule)}`
                : ''
            }`}
        </Table.Cell>
        <Table.Cell>
          {applicableDate && (
            <ApplicableDateLabel
              applicableDate={applicableDate}
              applicableDateTypeId={applicableDateTypeId}
            />
          )}
        </Table.Cell>
        <Table.Cell width={1} textAlign="center">
          <ActionButtons
            loading={loading}
            className="u-show-on-hover__target"
            deleteClicked={this.deleteScheduledExecutionPlanClicked}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

ScheduledLaunchesListItem.propTypes = {
  schedule: PropTypes.object,
  deleteScheduledExecutionPlan: PropTypes.func,
  showConfirmDialog: PropTypes.func,
};

export default ScheduledLaunchesListItem;
