import update from 'immutability-helper';

import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function appReducer(state = initialState.appData, action) {
  switch (action.type) {
    case types.SAVE_START_ROUTE: {
      const { route } = action;
      return update(state, { $set: { startRoute: route } });
    }
    case types.SET_INITIAL_START_ROUTE: {
      return update(state, {
        $set: { startRoute: initialState.appData.startRoute },
      });
    }
    default:
      return state;
  }
}
