import React from 'react';
import * as PropTypes from 'prop-types';
//import { Card, Header } from 'semantic-ui-react';
import ReactHighcharts from 'react-highcharts';

import * as chartConfigs from './chartConfigBase';

const SankeyChartComponent = ({ data }) => {
  const createChartConfig = () => {
    return Object.assign({}, chartConfigs.sankey, {
      series: data,
    });
  };

  return <ReactHighcharts config={createChartConfig()} neverReflow={true} />;
};

SankeyChartComponent.propTypes = {
  data: PropTypes.array,
};

export default SankeyChartComponent;
