import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { formatValue } from '../../../../../utils/FormatUtils';
import { kpiValueFormats } from '../../../../../constants/config';

export const SecondStyleKpiComponent = ({
  label,
  header,
  format = kpiValueFormats.Number,
  buttonText,
  link,
  value,
  currencyType,
  onClick,
  classNames = '',
}) => {
  return (
    <div
      className={classNames + ' second'}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : '',
      }}
    >
      <div className="value-component-kpi">
        {formatValue(value, format, currencyType)}
      </div>
      <div className="label-component-kpi">{label || header}</div>
      {link && <Button primary>{buttonText}</Button>}
    </div>
  );
};

SecondStyleKpiComponent.propTypes = {
  link: PropTypes.string,
  buttonText: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  classNames: PropTypes.string,
  currencyType: PropTypes.string,
  onClick: PropTypes.func,
};
