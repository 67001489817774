import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import * as _ from 'lodash';
import update from 'immutability-helper/index';

import ProgrammeWrapper from '../programme/Programme';
import WizardSteps from './WizardSteps';
import UserWrapper from '../user/User';
import ErrorBoundary from './../common/ErrorBoundary';
import PermissionWrapper from '../permissions/Permission';
import * as ExecutionPlansUtils from '../../utils/ExecutionPlansUtils';
import CreateExecutionPlanComponents from './CreateExecutionPlanComponents';
import WizardWrapper from '../wizard/Wizard';
import { withRouter } from 'react-router-dom';
import WizardActions from '../wizard/WizardActions';

const WizardContainer = (props) => {
  const {
    stepComponents,
    wizardChoices,
    setWizardChoices,
    changeFunction,
    currentStep,
    steps,
  } = props;

  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    setValidForm(ExecutionPlansUtils.checkFormCompleted(wizardChoices));
  }, [wizardChoices]);

  const stepConfig = steps[currentStep];

  const onChange = (event, data) => {
    const { name, value, checked } = data;
    if (_.isEqual(wizardChoices[name], value)) return wizardChoices;
    let amendedWizardChoices = _.cloneDeep(wizardChoices);
    if (changeFunction) {
      amendedWizardChoices = changeFunction(name, value, amendedWizardChoices);
    }
    amendedWizardChoices = update(amendedWizardChoices, {
      [name]: {
        $set: checked || value,
      },
    });
    setWizardChoices(amendedWizardChoices);
  };

  const setOptionsValue = (key, value) => {
    setWizardChoices(
      update(wizardChoices, {
        meta: {
          options: {
            [key]: {
              $set: value,
            },
          },
        },
      })
    );
  };

  const Components = stepComponents || CreateExecutionPlanComponents;

  return (
    <Container fluid>
      <WizardSteps />
      {stepConfig.actionsPosition && stepConfig.actionsPosition === 'top' && (
        <WizardActions />
      )}
      <Components
        onChange={onChange}
        disabled={!validForm}
        setOptionsValue={setOptionsValue}
      />
      {!stepConfig.actionsPosition && <WizardActions />}
    </Container>
  );
};

WizardContainer.propTypes = {
  stepComponents: PropTypes.func,
  wizardChoices: PropTypes.object,
  setWizardChoices: PropTypes.func,
  changeFunction: PropTypes.func,
  currentStep: PropTypes.string,
  steps: PropTypes.object,
};

export default ErrorBoundary(
  withRouter(
    PermissionWrapper(
      WizardWrapper(ProgrammeWrapper(UserWrapper(WizardContainer)))
    )
  )
);
