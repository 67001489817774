import React, { FC } from 'react';
import EditableElement from './EditableElement';
import { Header, Icon } from 'semantic-ui-react';
import SubmitInput from './SubmitInput';

interface EditableHeaderProps {
  value: string;
  onSubmit: (any, { value }: { value: string }) => void;
  name?: string;
  size?: string;
  placeholder?: string;
}

const EditableHeader: FC<EditableHeaderProps> = ({
  value,
  onSubmit,
  name,
  placeholder,
  size = 'small',
}) => {
  return (
    <EditableElement
      value={value}
      name={name}
      element={
        <Header as="h3" disabled={!value} size={size}>
          {value || placeholder || 'Please enter value'}
          &nbsp;
          <Icon
            fitted
            link
            name="write"
            size="tiny"
            className="edit-icon"
            color="grey"
          />
        </Header>
      }
      form={SubmitInput}
      onSubmit={onSubmit}
    />
  );
};

export default EditableHeader;
