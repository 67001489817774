import React from 'react';
import * as _ from 'lodash';

const ProContents = (props) => {
  return null;

  const structure = _.get(props, 'data.data.ticketSets');

  if (!structure) return null;
  structure.push({
    programmeActivitySetsLabel: 'Underwriting Testing',
    programmeActivitySetsSort: 45,
  });

  structure.push({
    programmeActivitySetsLabel: 'Claims Testing',
    programmeActivitySetsSort: 90,
  });

  structure.push({
    programmeActivitySetsLabel: 'Accounting Testing',
    programmeActivitySetsSort: 100,
  });

  const activitySets = _.chain(structure)
    .uniqBy('programmeActivitySetsLabel')
    .orderBy('programmeActivitySetsSort')
    .value();
  const keyedByActivitySet = _.chain(structure)
    .filter((a) => a.programmeActivitySetsActivitiesLinkSort)
    .groupBy('programmeActivitySetsLabel')
    .value();

  return (
    <div className="pro-report-contents">
      <h2> Contents</h2>
      <h3>Executive Summary</h3>
      Summary of Findings
      <br />
      Material Changes
      <br />
      Binding Authority Summary
      <br />
      <h3>Recommendation Tables</h3>
      High Priority Recommendations
      <br />
      Medium Priority Recommendations
      <br />
      Low Priority Recommendations
      <br />
      Prior Audit Findings <br />
      Recommendations for London <br />
      {activitySets.map((as, index) => {
        const activities = _.orderBy(
          keyedByActivitySet[as.programmeActivitySetsLabel],
          'programmeActivitySetsActivitiesLinkActivityRef'
        );

        return (
          <React.Fragment key={index}>
            <h3>
              {index + 1} {as.programmeActivitySetsLabel}
            </h3>

            {activities.map((a, index) => (
              <React.Fragment key={index}>
                {a.programmeActivitySetsActivitiesLinkActivityRef}{' '}
                {a.programmeActivitySetsActivitiesLinkActivityLabel}
                <br />
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ProContents;
