import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PartyUserList from './PartyUserList';
import PartyWrapper from './Party';

import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';

const PartyUsers = ({ party, partyActions }) => {
  const { structuralNodeId } = party;

  const openCreateUserModal = () => {
    partyActions.openSaveUserModal();
  };

  return (
    <div className="row">
      <div className="column">
        <PartyUserList />
        <CheckPermission
          structuralNodeId={structuralNodeId}
          permissionType={permissionTypes.ADMIN}
        >
          <Button primary onClick={openCreateUserModal}>
            Add new user
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
};

PartyUsers.propTypes = {
  party: PropTypes.object,
  partyActions: PropTypes.object,
};

export default PartyWrapper(PartyUsers);
