import React from 'react';
import { Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import ReactMarkdown from 'react-markdown';

const serverSideRender =
  typeof process !== 'undefined' && !!process.env.SERVER_REND;

const ProExecSummary = (props) => {
  return null;

  const executionInfo = _.get(props, 'data.data.executionInfo.0');
  const entityInfo = _.get(props, 'data.data.entityInfo');

  if (!executionInfo || !entityInfo) return null;

  const location = _.chain(executionInfo.entries)
    .find({ field: { fieldKey: 'LOC' } })
    .get('value')
    .value();
  const date = _.chain(executionInfo.entries)
    .find({ field: { fieldKey: 'DOF' } })
    .get('value')
    .value();
  const staffInterviewed = _.chain(executionInfo.entries)
    .find({ field: { fieldKey: 'STFI' } })
    .get('value')
    .value();

  const umr = _.chain(entityInfo)
    .find({ entriesFieldId: 'BND-UMR' })
    .get('entriesValue')
    .value();

  return (
    <div className="pro-exec-summary">
      <h2>Executive Summary</h2>

      <Table
        verticalAlign="top"
        clear="all"
        celled={true}
        fixed={serverSideRender}
        border={serverSideRender ? 1 : null}
        cellSpacing={serverSideRender ? 0 : null}
        cellPadding={serverSideRender ? 5 : null}
      >
        <Table.Body>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3}>
              <strong>Coverholder</strong>
            </Table.Cell>
            <Table.Cell>{executionInfo.party.label}</Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3}>
              <strong>UMR</strong>
            </Table.Cell>
            <Table.Cell>{umr}</Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3}>
              <strong>Location</strong>
            </Table.Cell>
            <Table.Cell>{location}</Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3}>
              <strong>Date of Fieldwork</strong>
            </Table.Cell>
            <Table.Cell>{date}</Table.Cell>
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell width={3}>
              <strong>Staff Interviewed</strong>
            </Table.Cell>
            <Table.Cell>
              <ReactMarkdown source={staffInterviewed} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default ProExecSummary;
