import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import PartyWrapper from '../Party';
import FilterAndPaginate from '../../common/FilterAndPaginate';
import EntityList from './EntityList';
import Pagination from '../../common/Pagination';

interface PartyEntityListProps {
  paginationOptions: any;
  paginatedItems: any[];
  handleSortChange: (sortField: string) => void;
  handlePaginationChange: (e: any, data: any) => void;
  partyStateActions: any;
  removeFilterValues: (filters: string[]) => void;
  handleAddFilter: (filters: object) => void;
  currentFilters: any;
}

const PartyEntityList = (props: PartyEntityListProps) => {
  const {
    paginatedItems,
    handleSortChange,
    handlePaginationChange,
    paginationOptions,
    removeFilterValues,
    handleAddFilter,
    currentFilters,
  } = props;

  return (
    <React.Fragment>
      <Header as="h2">Party Entities</Header>
      {paginatedItems.length ? (
        <React.Fragment>
          <EntityList
            entities={paginatedItems || []}
            paginationOptions={paginationOptions}
            handleSortChange={handleSortChange}
            removeFilterValues={removeFilterValues}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
          />
          <Pagination
            activePage={paginationOptions.activePage}
            onPageChange={handlePaginationChange}
            totalPages={paginationOptions.totalPages}
            style={{ float: 'right' }}
          />
        </React.Fragment>
      ) : (
        <div className="row">
          <div className="column">
            <Header as="h4">
              There are no entities associated with this Party
            </Header>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

function mapOptions(props: any) {
  return {
    items: props.entities,
  };
}

export default FilterAndPaginate(mapOptions)(PartyEntityList);
