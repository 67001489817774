import React from 'react';
import FollowUpTicketList from './FollowUpTicketList';
import { Button, Header, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const FollowUpSidebar = () => {
  return (
    <React.Fragment>
      <Segment textAlign="left" basic padded="very">
        <Header style={{ display: 'inline-block' }}>Follow Up List</Header>

        <Button as={Link} to="/followup" primary floated="right" size="small">
          Launch Programme from list
        </Button>

        <FollowUpTicketList />
      </Segment>
    </React.Fragment>
  );
};

export default FollowUpSidebar;
