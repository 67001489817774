import _ from 'lodash';
import ArgTypes from '../ArgTypes';
import moment from 'moment';
let jsonLogic = require('json-logic-js');

import { formatApplicableDate } from '../../FormatUtils';

const ops = {
  formatApplicableDate,
};

jsonLogic.add_operation('_', _);
jsonLogic.add_operation('ops', ops);
jsonLogic.add_operation('Math', Math);
jsonLogic.add_operation('Number', Number);
jsonLogic.add_operation('JSON', JSON);
jsonLogic.add_operation('moment', moment);
jsonLogic.add_operation('length', (array) => array.length);

const logic = function (args) {
  const { dataSet, data, outputKey, operations, useAllData } = args;
  const dataToMap = data[dataSet];

  let outputValues;
  if (useAllData) {
    try {
      outputValues = jsonLogic.apply(operations, data);
    } catch (e) {
      outputValues = [];
    }
  } else {
    outputValues = _.chain(dataToMap)
      .map((value, index) => {
        let v = _.cloneDeep(value);
        _.forEach(operations, (operation, key) => {
          v[key] = jsonLogic.apply(operation, { ...value, index });
        });
        return v;
      })
      .value();
  }

  return {
    ...args,
    data: { ...data, [outputKey || 'mappedValues']: outputValues },
  };
};

logic.args = {
  dataSet: ArgTypes.string,
  outputKey: ArgTypes.string,
  useAllData: ArgTypes.bool,
  operations: ArgTypes.json,
};

export default logic;
