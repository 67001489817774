import React, { Component } from 'react';
import Fields from './fields/Fields';

interface FormFieldProps {
  field: any;
  value: string | object | number;
  errors?: object;
  handleFieldDataChange?: (field: object, value: any) => void;
  handleFieldOnFocus?: () => void;
  handleFieldOnBlur?: () => void;
  required?: boolean;
  editable?: boolean;
  hasException?: boolean;
  disabled?: boolean;
}

interface FormFieldState {
  hasError: boolean;
}

class FormField extends Component<FormFieldProps, FormFieldState> {
  constructor(props: FormFieldProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_: any): Partial<FormFieldState> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error('Error captured in FormField:', error, errorInfo);
  }

  onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: { value: any }
  ): void => {
    const { handleFieldDataChange, field } = this.props;
    if (handleFieldDataChange) handleFieldDataChange(field, data.value);
  };

  render(): React.ReactNode {
    const {
      field,
      value,
      errors,
      required,
      disabled,
      editable,
      handleFieldOnBlur,
      handleFieldOnFocus,
      hasException,
    } = this.props;

    if (this.state.hasError) {
      return <>Error occurred while rendering the field.</>;
    }

    const type = field.type || field.activityFieldTypeId;
    const Field = Fields[type];

    if (!Field) {
      return <>{type} field unavailable</>;
    }

    return (
      <Field
        value={value}
        {...field}
        {...field.args}
        error={hasException}
        width={16}
        errors={errors}
        required={required}
        onChange={this.onChange}
        onBlur={handleFieldOnBlur}
        onFocus={handleFieldOnFocus}
        disabled={disabled}
        editable={editable}
      />
    );
  }
}

export default FormField;
