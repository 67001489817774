import React from 'react';
import { orderBy } from 'lodash';

import colors from '../constants/colors';
import styles from './StackedBarProgress.module.css';
import { ExecutionExtended, ExecutionStatus } from '@brallum/vidende/src/types';
// import { ExecutionExtended } from '../../types';

interface StackedBarProgressProps {
  workflowStatusTypes: WorkflowStatusType[];
  execution: ExecutionExtended;
}

interface WorkflowStatusType {
  id: string;
  sort: number;
}

export const StackedBarProgress = ({
  workflowStatusTypes,
  execution,
}: StackedBarProgressProps) => {
  return (
    <div className={styles.Root}>
      {orderBy(workflowStatusTypes, ['sort'], ['desc']).map(
        (workflowStatusType) => {
          const status: ExecutionStatus = execution.statuses.find(
            ({ id }) => id === workflowStatusType.id
          );
          const { ticketCount } = execution;
          const percent = ticketCount
            ? (status?.ticketStatusCount / ticketCount) * 100
            : 0;
          return percent > 0 ? (
            <div
              key={workflowStatusType.id}
              className="bar"
              style={{
                width: `${percent || 0}%`,
                backgroundColor: `${colors[status?.colourId]}`,
              }}
            />
          ) : null;
        }
      )}
    </div>
  );
};

export default StackedBarProgress;
