import clsx from 'clsx';

import { FirstStyleKpiComponent } from './FirstStyleKpiComponent';
import { SecondStyleKpiComponent } from './SecondStyleKpiComponent';
import { ThirdStyleKpiComponent } from './ThirdStyleKpiComponent';

import './KpiComponent.scss';
import { ComponentSize, RAGState } from '../../../../../types';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

interface KpiComponentProps {
  value: string | number | { value: string | number; color?: SemanticCOLORS };
  format?: string;
  iconName?: SemanticICONS;
  label?: string;
  color?: SemanticCOLORS;
  currencyType?: string;
  style?: number | string;
  link?: string;
  buttonText?: string;
  drillThroughEnabled?: boolean;
  fullHighlight?: boolean;
  drillThroughProperties?: object;
  callback?: (data: { dataValue: object }) => void;
  state?: RAGState;
  labelSize?: ComponentSize;
  valueSize?: ComponentSize;
}

const KpiComponent = (props: KpiComponentProps) => {
  const {
    style,
    value,
    drillThroughEnabled,
    drillThroughProperties,
    callback,
    state,
    fullHighlight,
  } = props;

  const handleClick = drillThroughEnabled
    ? () => {
        if (callback) callback({ dataValue: drillThroughProperties });
      }
    : null;

  const classNames = clsx('component-dashboard-widget component-kpi ', {
    'component-kpi--error': state === 'ERROR',
    'component-kpi--exception': state === 'EXCEPTION',
    'component-kpi--no-results': state === 'NO_RESULTS',
    'component-kpi--unfinished': state === 'UNFINISHED',
    'component-kpi--positive': state === 'POSITIVE',
    'component-kpi--none': state === 'NONE',
    'component-kpi--caution': state === 'CAUTION',
    'component-kpi--full-highlight': fullHighlight,
  });

  const currentValue =
    typeof value === 'object' && 'value' in value ? value.value : value;

  const color =
    props.color ||
    (typeof value === 'object' && 'color' in value ? value.color : null);
  switch (style) {
    case 1:
    case '1': {
      return (
        <FirstStyleKpiComponent
          {...props}
          value={currentValue}
          onClick={handleClick}
          classNames={classNames}
          color={color}
        />
      );
    }
    case 2:
    case '2': {
      return (
        <SecondStyleKpiComponent
          {...props}
          value={currentValue}
          onClick={handleClick}
          classNames={classNames}
        />
      );
    }
    case 3:
    case '3': {
      return (
        <ThirdStyleKpiComponent
          {...props}
          value={currentValue}
          onClick={handleClick}
          color={color}
        />
      );
    }
    default: {
      return (
        <FirstStyleKpiComponent
          {...props}
          value={currentValue}
          onClick={handleClick}
          classNames={classNames}
          color={color}
        />
      );
    }
  }
};

export default KpiComponent;

KpiComponent.fields = [
  {
    id: 'value',
    required: false,
    label: 'Value',
    activityFieldType: 'Text',
  },
  {
    id: 'fullHighlight',
    required: false,
    label: 'Full Highlight',
    activityFieldType: 'CheckBoxBool',
  },
  {
    id: 'format',
    required: false,
    label: 'Format',
    activityFieldType: 'RadioGroup',
    options: {
      values: ['Number', 'Currency', 'Percentage', 'Date'],
    },
  },
  {
    id: 'style',
    required: false,
    label: 'Style',
    activityFieldType: 'RadioGroup',
    options: {
      values: ['1', '2', '3'],
    },
  },
  {
    id: 'state',
    required: false,
    label: 'State',
    activityFieldType: 'Select',
    options: {
      values: [
        'DEFAULT',
        'ERROR',
        'EXCEPTION',
        'NO_RESULTS',
        'UNFINISHED',
        'POSITIVE',
        'CAUTION',
      ],
    },
  },
  {
    id: 'labelSize',
    required: false,
    label: 'Label Size',
    activityFieldType: 'Select',
    options: {
      values: ['tiny', 'small', 'medium', 'large', 'huge'],
    },
  },
  {
    id: 'valueSize',
    required: false,
    label: 'Value Size',
    activityFieldType: 'Select',
    options: {
      values: ['tiny', 'small', 'medium', 'large', 'huge'],
    },
  },
];
