import * as permissionTypes from '../../constants/permissionTypes';

export function addPermissionsToActivitiesList(user, activities) {
  return activities
    ? activities.map((activity) => addPermissionsToActivity(user, activity))
    : [];
}

export function addPermissionsToActivity(user, activity) {
  const permissions = {
    [permissionTypes.VIEW]: user.permissions[permissionTypes.VIEW].includes(
      activity.structuralNodeId
    ),
    [permissionTypes.CREATE]: user.permissions[permissionTypes.CREATE].includes(
      activity.structuralNodeId
    ),
    [permissionTypes.EDIT]: user.permissions[permissionTypes.EDIT].includes(
      activity.structuralNodeId
    ),
  };

  return Object.assign({}, activity, {
    permissions,
  });
}
