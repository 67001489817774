import { Result, StatusResultArguments } from '../../api/statusApi';
import { MetricResultViewer } from '../status/MetricResultViewer';

type MetricResultSidebarProps = {
  result: Result;
  args: StatusResultArguments;
};

export const MetricResultSidebar = ({
  result,
  args,
}: MetricResultSidebarProps) => {
  if (!result) return null;
  return <MetricResultViewer result={result} args={args} />;
};
