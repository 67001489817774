import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Button, Dropdown, Label } from 'semantic-ui-react';

const TicketResolutionButtons = (props) => {
  const resolutions = _.orderBy(props.resolutions, 'resolutionType.sort');
  const currentResolution =
    resolutions.length > 0
      ? _.find(statusResolutions, { statusId: resolutions[0].statusId })
      : null;

  const { statusResolutions, loading, onClick, singleButton, disabled } = props;

  const currentResolutionType = currentResolution
    ? _.find(resolutions, { id: currentResolution.statusResolutionId })
    : null;

  const checkIfResolved = (resolution) => {
    return _.find(statusResolutions, {
      statusId: resolution.statusId,
      statusResolutionId: resolution.id,
    });
  };

  return (
    <React.Fragment>
      {singleButton ? (
        <Button.Group
          color={
            currentResolutionType && currentResolutionType.resolutionType
              ? currentResolutionType.resolutionType?.colourId
              : 'grey'
          }
          size="small"
        >
          <Dropdown
            loading={loading}
            text={
              currentResolutionType
                ? currentResolutionType.labelOverride
                  ? currentResolutionType.labelOverride.toUpperCase()
                  : currentResolutionType.resolutionType.label
                : 'Resolve'
            }
            floating
            labeled
            button
            className="ui right labeled icon button"
            disabled={disabled}
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Resolve this ticket" />
              {resolutions.map((availableResolution) => {
                const resolution = checkIfResolved(availableResolution);

                return (
                  <Dropdown.Item
                    key={availableResolution.id}
                    onClick={(event) =>
                      onClick(event, availableResolution, currentResolution)
                    }
                    selected={!!resolution}
                  >
                    <Label color={availableResolution.resolutionType?.colourId}>
                      {availableResolution.labelOverride
                        ? availableResolution.labelOverride.toUpperCase()
                        : availableResolution.resolutionType?.label}
                    </Label>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      ) : (
        <React.Fragment>
          <Button.Group size="small">
            {resolutions.map((availableResolution) => {
              const resolution = checkIfResolved(availableResolution);
              return (
                <Button
                  key={availableResolution.id}
                  color={availableResolution.resolutionType?.colourId}
                  onClick={(event) =>
                    onClick(event, availableResolution, currentResolution)
                  }
                  loading={loading}
                  basic={!resolution}
                  disabled={disabled}
                >
                  {availableResolution.labelOverride
                    ? availableResolution.labelOverride.toUpperCase()
                    : availableResolution.resolutionType?.label}
                </Button>
              );
            })}
          </Button.Group>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TicketResolutionButtons.propTypes = {
  resolutions: PropTypes.array,
  loading: PropTypes.bool,
  singleButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  statusResolutions: PropTypes.array,
  disabled: PropTypes.bool,
};

export default TicketResolutionButtons;
