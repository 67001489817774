import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function tagReducer(state = initialState.tags, action) {
  switch (action.type) {
    case types.LOAD_TAGS_SUCCESS:
      return action.tags;
    case types.CREATE_TAG_SUCCESS:
      return [...state, Object.assign({}, action.tag)];
    default:
      return state;
  }
}
