import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Flag, Form } from 'semantic-ui-react';
import { countryOptions } from '../../../constants/country';
import FieldToolTip from './FieldToolTip';

const Country = (props) => {
  const {
    label,
    value,
    onChange,
    name,
    disabled = false,
    required,
    editable = true,
    error
  } = props;

  if (!editable) {
    const country = _.find(countryOptions, { value });
    if (!country) return null;
    return (
      <React.Fragment>
        <Flag name={country.flag} />
        {country.text}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form.Field required={required} error={error}>
        <label>
          {label}
          <FieldToolTip {...props} />
        </label>
        <Form.Select
          name={name}
          placeholder="Select Country"
          fluid
          selection
          options={countryOptions}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Form.Field>
    </React.Fragment>
  );
};

Country.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

Country.defaultProps = {
  value: '',
};

export default Country;
