import React from 'react';
import * as PropTypes from 'prop-types';

const FragmentComponent = (props) => {
  return <React.Fragment>{props.content}</React.Fragment>;
};

FragmentComponent.propTypes = {
  content: PropTypes.func,
};

export default FragmentComponent;
