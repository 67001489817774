import _ from 'lodash';
import apiTools from './apiTools';
import { ticketFilter, ticketPreviewFilter } from './../constants/apiFilters';

class TicketApi {
  static getTickets(filter = {}) {
    return apiTools
      .get(
        'ExecutionTicketListView',
        {
          filter: Object.assign({}, ticketPreviewFilter, filter),
        },
        true
      )
      .then((response) => {
        return response.data;
      });
  }
  static getTicketsCount(where = {}) {
    return apiTools
      .get('ExecutionTicketListView/count', {
        where,
      })
      .then((response) => {
        return response.data;
      });
  }

  static getStatusResolutions(filter = {}) {
    return apiTools
      .get('ExecutionTicketWorkflowStatusResolutions', {
        filter: Object.assign(
          {},
          {
            limit: 10,
            order: 'createdAt DESC',
            include: [
              {
                relation: 'ticket',
                scope: {
                  include: ['activity'],
                },
              },
              'modifiedBy',
              'resolution',
            ],
          },
          filter
        ),
      })
      .then((response) => {
        return response.data;
      });
  }

  static getTicket({ id, isPreview }) {
    const filter = isPreview ? ticketPreviewFilter : ticketFilter;
    return apiTools
      .get(`ExecutionTicketListView/${id}`, {
        filter,
      })
      .then((response) => {
        return response.data;
      });
  }

  static searchTicketPrincipals(
    query,
    includeDefaultGroups = false,
    restrictToAssigned = false
  ) {
    return apiTools
      .get(`Principals/search`, {
        q: query,
        includeDefaultGroups,
        restrictToAssigned,
      })
      .then((response) => {
        return response.data;
      });
  }

  static saveTicketEntries(ticketId, entries) {
    return apiTools
      .post(
        `ExecutionTickets/${ticketId}/entries`,
        entries.map((entry) => {
          const { activityFieldId, value } = entry;
          return { activityFieldId, value };
        })
      )
      .then((response) => {
        return response.data;
      });
  }

  static saveTicketWorkflowEntries(ticket, entries) {
    return apiTools
      .post(
        `ExecutionTickets/${ticket.id}/workflowEntries`,
        entries.map((entry) => {
          let { workFlowStatusFieldId, value } = entry;
          if (_.isPlainObject(value)) value = JSON.stringify(value);
          return { workFlowStatusFieldId, value };
        })
      )
      .then((response) => {
        return response.data;
      });
  }
  static getTicketWorkflowEntries(ticket) {
    return apiTools
      .get(`ExecutionTickets/${ticket.id}/workflowEntries`, {
        filter: {
          include: ['field', 'modifiedBy'],
          where: {
            current: true,
          },
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static transitionTicket(ticket, transitionId) {
    return apiTools
      .post(`ExecutionTickets/${ticket.id}/transition`, {
        transitionId,
      })
      .then((response) => {
        return response.data;
      });
  }

  static assignTicket(ticket, principalId) {
    return apiTools
      .post(`ExecutionTickets/${ticket.id}/assign`, {
        principalId,
      })
      .then((response) => {
        return response.data;
      });
  }

  static assignTickets(tickets, principalId) {
    return apiTools
      .post(`ExecutionTickets/bulkAssign`, {
        tickets,
        principalId,
      })
      .then((response) => {
        return response.data;
      });
  }

  static resolveTicket(ticket, statusId, statusResolutionId) {
    return apiTools
      .post(`ExecutionTickets/${ticket.id}/createStatusResolution`, {
        statusId,
        statusResolutionId,
      })
      .then((response) => {
        return response.data;
      });
  }

  static saveTicket(ticket) {
    const apiMethod = ticket.id ? apiTools.patch : apiTools.post;
    return apiMethod(`ExecutionTickets`, ticket).then((response) => {
      return response.data;
    });
  }

  static deleteAttachment(ticket, attachmentId) {
    const apiMethod = apiTools.delete;
    return apiMethod(
      `ExecutionTickets/${ticket.id}/attachments/${attachmentId}`
    ).then((response) => {
      return response;
    });
  }

  static openAttachment(ticket, attachmentId) {
    const url = apiTools.generateUrl(
      `ExecutionTickets/${ticket.id}/downloadAttachments/${attachmentId}`
    );
    window.open(url, '_blank');
  }

  static getMinAmount(ticketId) {
    return apiTools
      .get(`ExecutionTickets/${ticketId}/workflowEntries`, {
        filter: {
          include: ['minAmount', 'modifiedBy'],
          where: {
            current: true,
          },
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static getTicketForRedirect(id) {
    return apiTools
      .get(`ExecutionTickets/${id}`, {
        filter: {
          include: [
            {
              relation: 'executionTicketSet',
              scope: {
                include: ['category'],
              },
            },
          ],
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static addExistingAttachment(ticketId, selectedAttachments) {
    return apiTools
      .post(`ExecutionTickets/${ticketId}/addAttachments`, {
        id: selectedAttachments,
      })
      .then((response) => {
        return response.data;
      });
  }

  static getFilteredTickets(params) {
    return apiTools
      .get('ExecutionTicketListView/filterByResolution', {
        ...params,
      })
      .then((response) => {
        return response.data;
      });
  }

  static createImplementationExecution(params) {
    return apiTools
      .post('/ExecutionTickets/createImplementationExecution', {
        ...params,
      })
      .then((response) => {
        return response.data;
      });
  }

  static addDeadline(ticketId, deadline) {
    return apiTools
      .post(`/ExecutionTickets/${ticketId}/deadlines`, deadline)
      .then((response) => {
        return response.data;
      });
  }

  static removeDeadline(ticketId, deadlineId) {
    return apiTools
      .delete(`/ExecutionTickets/${ticketId}/deadlines/${deadlineId}`)
      .then((response) => {
        return response.data;
      });
  }

  static getSimpleTicket({ id }) {
    return apiTools.get(`ExecutionTickets/${id}`).then((response) => {
      return response.data;
    });
  }
}

export default TicketApi;
