import * as types from '../constants/actionTypes';

export default function executionPlansReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_EXECUTION_PLANS_SUCCESS:
      return action.executionPlans;
    default:
      return state;
  }
}
