import { ExecutionField } from '../types';
import apiTools from './apiTools';
import LoopbackFilter from './apiTypes';

class ExecutionFieldApi {
  static getExecutionFields(
    filterArg: LoopbackFilter = {}
  ): Promise<ExecutionField[]> {
    const filter = {
      ...filterArg,
    };
    return apiTools.get('ExecutionFields', { filter }).then((response) => {
      return response.data;
    });
  }
}

export default ExecutionFieldApi;
