import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Form, Button, Header } from 'semantic-ui-react';
import * as _ from 'lodash';

import RichTextArea from '../../common/fields/RichTextArea';
import Select from '../../common/fields/Select';
import Text from '../../common/fields/Text';
import { checkModalFormDataValid } from '../../../utils/Validation';

class AttachmentDetailsForm extends Component {
  state = {
    description: this.props.attachment.description,
    type: this.props.attachment.type,
    classification: this.props.attachment.classification,
    label: this.props.attachment.label,
    url: this.props.attachment.originalFileName,
    isEdited: false,
    isModalFormDataValid: this.props.attachment ? true : false,
    validations: {
      url: { isValid: !!this.props.attachment.originalFileName },
      label: { isValid: !!this.props.attachment.label },
    },
  };

  onChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  uploadAttachment = () => {
    const { description, type, classification, url, label } = this.state;
    const {
      description: descriptionProps,
      type: typeProps,
      classification: classificationProps,
      originalFileName: urlProps,
      label: labelProps,
    } = this.props.attachment;
    const { uploadAttachmentSuccess, ticketId, userDetails } = this.props;
    const { uploadAttachmentDetails } = this.props.attachmentStateActions;
    const { id } = this.props.attachment;
    let uploadData = { id };
    if (url !== urlProps) {
      uploadData['originalFileName'] = url;
    }
    if (label !== labelProps) {
      uploadData['label'] = label;
    }
    if (description !== descriptionProps) {
      uploadData['description'] = description;
    }
    if (type !== typeProps) {
      uploadData['type'] = type;
    }
    if (classification !== classificationProps) {
      uploadData['classification'] = classification;
    }
    uploadAttachmentDetails(uploadData, userDetails, ticketId).then(() => {
      uploadAttachmentSuccess();
    });
  };

  getIsEdited = () => {
    const {
      type,
      classification,
      description,
      label,
      originalFileName,
    } = this.props.attachment;
    const {
      type: stateType,
      classification: stateClassification,
      description: stateDescription,
      label: stateLabel,
      url: stateUrl,
    } = this.state;
    return !_.isEqual(
      { type, classification, description, label, url: originalFileName },
      {
        type: stateType,
        classification: stateClassification,
        description: stateDescription,
        label: stateLabel,
        url: stateUrl,
      }
    );
  };

  handleOnFocus = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = true;
    this.setState({ validations });
  };

  handleOnBlur = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = false;
    this.checkValidation(name, this.state[name]);
    this.setState({ validations });
  };

  checkValidation = (name, value) => {
    const { validations } = this.state;
    const modalFormDataValid = checkModalFormDataValid(
      validations,
      name,
      value
    );
    this.setState({ ...modalFormDataValid });
  };

  getErrors = (propertryName) => {
    const { validations } = this.state;
    const validation = validations[propertryName];
    const isValidationErrors = !_.isEmpty(validation.errors);
    return !validation.hasFocus && isValidationErrors ? validation.errors : {};
  };

  render() {
    const {
      description,
      type,
      classification,
      url,
      label,
      isModalFormDataValid,
    } = this.state;

    const {
      attachmentType,
      attachmentClassification,
    } = this.props.attachmentsConstants;
    const { isExternalLink } = this.props.attachment;
    const errorMessage = this.props.attachment.errorMessage;
    const isEdited = this.getIsEdited();
    return (
      <Form className="attachment-details-form">
        {isExternalLink && (
          <Form.Group>
            <Text
              width={'8'}
              label="URL"
              onChange={this.onChange}
              name="url"
              value={url}
              errors={this.getErrors('url')}
              onBlur={() => this.handleOnBlur('url')}
              onFocus={() => this.handleOnFocus('url')}
            />
            <Text
              width={'8'}
              label="Label"
              onChange={this.onChange}
              name="label"
              value={label}
              errors={this.getErrors('label')}
              onBlur={() => this.handleOnBlur('label')}
              onFocus={() => this.handleOnFocus('label')}
            />
          </Form.Group>
        )}
        <Form.Field>
          <RichTextArea
            label="Description"
            name="description"
            value={description}
            onChange={this.onChange}
          />
        </Form.Field>
        <Form.Group>
          <Select
            width={'8'}
            label="Type"
            name="type"
            options={attachmentType}
            value={type}
            onChange={this.onChange}
          />
          <Select
            width={'8'}
            label="Classification"
            options={attachmentClassification}
            value={classification}
            onChange={this.onChange}
            name="classification"
          />
        </Form.Group>
        <Button
          positive
          type="submit"
          onClick={() => this.uploadAttachment()}
          disabled={!isEdited || (!isModalFormDataValid && isExternalLink)}
        >
          Update
        </Button>
        {errorMessage && (
          <Header as="h5" color="red">
            {errorMessage}
          </Header>
        )}
      </Form>
    );
  }
}

AttachmentDetailsForm.propTypes = {
  attachmentsConstants: PropTypes.object,
  attachment: PropTypes.object,
  attachmentStateActions: PropTypes.object,
  uploadAttachmentSuccess: PropTypes.func,
  uploadAttachmentFailed: PropTypes.func,
  type: PropTypes.string,
  ticketId: PropTypes.number,
  userDetails: PropTypes.object,
};

export default AttachmentDetailsForm;
