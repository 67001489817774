import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Form } from 'semantic-ui-react';

import Select from '../common/fields/Select';
import ActionButtons from '../common/ActionButtons';
import Number from '../common/fields/Number';
import Integer from '../common/fields/Integer';

const BudgetListField = ({
  value,
  editable,
  onChange,
  fieldIndex,
  currencies,
  deleteField,
  validationErrors = {},
  handleOnFocus,
  handleOnBlur,
}) => {
  const { currency, premium, developmentPeriod } = value;

  const getValidationErrors = (name) => {
    if (validationErrors[name]) {
      return validationErrors[name].hasFocus === false &&
        validationErrors[name].errors
        ? validationErrors
        : {};
    }
    return {};
  };

  const handleFieldChange = ({ name, value }, fieldIndex) => {
    onChange(name, value, fieldIndex);
  };

  return (
    <Table.Row>
      <Table.Cell>
        {editable ? (
          <Form>
            <Select
              options={currencies}
              name="currency"
              value={currency}
              onChange={(e, data) => handleFieldChange(data, fieldIndex)}
              errors={getValidationErrors('currency')}
              onFocus={() => handleOnFocus('currency', fieldIndex)}
              onBlur={() => handleOnBlur('currency', fieldIndex)}
            />
          </Form>
        ) : (
          <span>{currency}</span>
        )}
      </Table.Cell>
      <Table.Cell>
        <Number
          placeholder="Premium"
          value={premium}
          name="premium"
          onChange={(e, data) => handleFieldChange(data, fieldIndex)}
          fluid
          errors={getValidationErrors('premium')}
          onFocus={(name) => handleOnFocus(name, fieldIndex)}
          onBlur={(name) => handleOnBlur(name, fieldIndex)}
          type={'number'}
          editable={editable}
        />
      </Table.Cell>
      <Table.Cell>
        <Integer
          placeholder="Development Period"
          value={developmentPeriod}
          name="developmentPeriod"
          onChange={(e, data) => handleFieldChange(data, fieldIndex)}
          fluid
          errors={getValidationErrors('developmentPeriod')}
          onFocus={(name) => handleOnFocus(name, fieldIndex)}
          onBlur={(name) => handleOnBlur(name, fieldIndex)}
          editable={editable}
        />
      </Table.Cell>

      {editable && (
        <Table.Cell collapsing>
          <ActionButtons deleteClicked={() => deleteField(fieldIndex)} />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

BudgetListField.propTypes = {
  value: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  fieldIndex: PropTypes.number,
  currencies: PropTypes.array,
  deleteField: PropTypes.func,
  validationErrors: PropTypes.object,
  handleOnFocus: PropTypes.func,
  handleOnBlur: PropTypes.func,
};

export default BudgetListField;
