export default {
  createActivityModalOpen: false,
  editQuestion: false,
  cloneActivity: false,
  uiStates: {},
  showModal: false,
  modalOptions: {},
  isMenuExpanded: true,
  sidebarOptions: {},
};
