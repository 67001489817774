import React from 'react';
import { Dropdown, Table } from 'semantic-ui-react';
import _ from 'lodash';

import FormBuilderItem from './FormBuilderItem';
import { ActivityFieldType, Field, FieldType, FieldTypeId } from '../../types';

interface Option {
  id: string;
  name: string;
}

interface FormBuilderProps {
  activityFieldTypes: ActivityFieldType[];
  fields: Field[];
  addField: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: { value: FieldTypeId; label?: string; fieldKey?: string }
  ) => void;
  onFieldChange: (index: number, value: Partial<Field>) => void;
  isTableView?: boolean;
  layout?: string;
  workflowStatusInputTypes?: Option[];
}

const FormBuilder = ({
  activityFieldTypes,
  addField,
  fields,
  onFieldChange,
  isTableView = true,
  layout,
  workflowStatusInputTypes,
}: FormBuilderProps) => {
  const handlePaste = async (event: ClipboardEvent) => {
    const pastedData = event.clipboardData.getData('Text');
    const rows = pastedData.split('\n'); // Assuming each line represents a row from the spreadsheet

    for (const row of rows) {
      if (!row.trim()) continue; // Skip empty lines

      const [label, fieldKey, activityFieldTypeId = 'Text'] = row.split('\t'); // Splitting by tab character for tab-delimited data

      addField({} as any, {
        value: activityFieldTypeId as FieldTypeId,
        label,
        fieldKey: fieldKey || label.toUpperCase(),
      });
    }
    window.removeEventListener('paste', handlePaste);
  };

  const handleMouseOver = () => {
    window.addEventListener('paste', handlePaste);
  };
  const handleMouseLeave = () => {
    window.removeEventListener('paste', handlePaste);
  };

  if (!fields) return null;
  return (
    <div>
      {isTableView ? (
        <Table celled>
          {fields.length > 0 && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Label</Table.HeaderCell>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Options</Table.HeaderCell>
                <Table.HeaderCell>Data Field Reference</Table.HeaderCell>
                <Table.HeaderCell>Display Short Label</Table.HeaderCell>
                <Table.HeaderCell collapsing>Data Field</Table.HeaderCell>
                <Table.HeaderCell collapsing>Required</Table.HeaderCell>
                <Table.HeaderCell collapsing />
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {_.sortBy(
              fields.map((field, index) =>
                Object.assign({}, field, { originalIndex: index })
              ),
              ['sort']
            ).map((field) =>
              field.activityFieldTypeId === 'Threshold' ? (
                <FormBuilderItem
                  key={field.originalIndex}
                  field={field}
                  onFieldChange={onFieldChange}
                  activityFieldTypes={activityFieldTypes}
                  layout={layout}
                  workflowStatusInputTypes={workflowStatusInputTypes}
                />
              ) : null
            )}

            <Table.Row>
              <Table.Cell colSpan={8} textAlign="center">
                <Dropdown
                  text="Add field"
                  icon="add circle"
                  floating
                  labeled
                  button
                  scrolling
                  className="icon"
                  upward
                >
                  <Dropdown.Menu>
                    <Dropdown.Header content="Form Fields" />
                    {activityFieldTypes.map((option) => (
                      <Dropdown.Item
                        key={option.id}
                        text={option.name}
                        value={option.id}
                        onClick={addField}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <div>
          <div>
            {_.sortBy(
              fields.map((field, index) => ({
                ...field,
                originalIndex: index,
              })),
              ['sort']
            ).map((field) =>
              field.activityFieldTypeId === 'Threshold' ? null : (
                <FormBuilderItem
                  key={field.originalIndex}
                  field={field}
                  onFieldChange={onFieldChange}
                  activityFieldTypes={activityFieldTypes}
                  layout={layout}
                  workflowStatusInputTypes={workflowStatusInputTypes}
                />
              )
            )}
          </div>
          <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
            <Dropdown
              text="Add field"
              icon="add circle"
              floating
              labeled
              button
              scrolling
              className="icon"
              upward
            >
              <Dropdown.Menu>
                <Dropdown.Header content="Form Fields" />
                {activityFieldTypes.map((option) => (
                  <Dropdown.Item
                    key={option.id}
                    text={option.name}
                    value={option.id}
                    onClick={addField}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormBuilder;
