import React from 'react';

const ProgrammeContext = React.createContext();

export const ProgrammeContextConsumer = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <ProgrammeContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </ProgrammeContext.Consumer>
      );
    }
  };

export default ProgrammeContext;
