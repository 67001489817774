import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import TicketRow from '../ticket/TicketRow/TicketRow';

class ExecutionTicketRow extends Component {
  render() {
    const { uiState, item, executionActions } = this.props;
    const { splitView, selectedTicketId } = uiState;
    return (
      <TicketRow
        {...this.props}
        onClick={executionActions.ticketClicked}
        compactView={splitView}
        isSelected={Number(selectedTicketId) === Number(item.id)}
      />
    );
  }
}

ExecutionTicketRow.propTypes = {
  uiState: PropTypes.object,
  item: PropTypes.object,
  executionActions: PropTypes.object,
};

export default ExecutionContextConsumer(ExecutionTicketRow);
