import { Segment, Message, Label, SemanticCOLORS } from 'semantic-ui-react';
import {
  LogicResult,
  LogicResultUnion,
  ResultValue,
} from '../../api/statusApi';
import { convertRAGStateToColor } from '../../utils/thresholds/convertToRag';
import { RAGState } from '../../types';
import { useMemo } from 'react';

interface Props {
  resultError?: Error;
  logicResult?: LogicResult;
}
const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];

export const StatusTestResult = ({ resultError, logicResult }: Props) => {
  let RAGresult: SemanticCOLORS;
  let numValues: number;

  // if (
  //   logicResult &&
  //   !Array.isArray(logicResult) &&
  //   typeof logicResult.result === 'string'
  // ) {
  //   console.log(logicResult);
  //   if (logicResult.thresholdResult) {
  //     RAGresult = convertRAGStateToColor(logicResult.thresholdResult);
  //     console.log(RAGresult);
  //   } else {
  //     RAGresult = logicResult.result as SemanticCOLORS;
  //   }
  // }

  // if (logicResult && Array.isArray(logicResult)) {
  //   console.log(logicResult);
  //   numValues = logicResult.length;
  //   let results = logicResult.map((logicResultItem) => logicResultItem.result);
  //   results = [...new Set(results)];
  //   if (results.length === 1) RAGresult = results[0] as SemanticCOLORS;
  //   RAGresult = 'grey';

  //   if (results.some((result) => result === 'orange')) RAGresult = 'orange';
  //   if (results.some((result) => result === 'purple')) RAGresult = 'purple';
  //   if (results.some((result) => result === 'red')) RAGresult = 'red';
  // }

  const color = useMemo(() => {
    if (logicResult && logicResult.thresholdResult) {
      return convertRAGStateToColor(logicResult.thresholdResult);
    }
    if (logicResult && typeof logicResult.result === 'string') {
      return convertRAGStateToColor(logicResult.result as RAGState);
    }
  }, [logicResult]);
  return (
    <>
      {resultError && (
        <Message negative>
          <Message.Header>{resultError.message}</Message.Header>
          <pre>{resultError.stack}</pre>
        </Message>
      )}

      {logicResult && (
        <Message color={color}>{JSON.stringify(logicResult.result)}</Message>
      )}

      {/* {RAGresult && colors.includes(RAGresult) ? (
        <>
          <Label size="large" color={RAGresult}>
            {RAGresult}
          </Label>{' '}
          {numValues && <span>{numValues}</span>}
        </>
      ) : Array.isArray(logicResult) ? (
        JSON.stringify(logicResult.map((result) => result.result))
      ) : (
        <span>
          {JSON.stringify(logicResult.result)}{' '}
          {logicResult.thresholdResult && (
            <span>{logicResult.thresholdResult}</span>
          )}
        </span>
      )} */}
    </>
  );
};
