import React, { Component } from 'react';
import { Dropdown, Checkbox, Form } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';

import { mapToOptions } from './../../utils/React';
import ActionButtons from '../common/ActionButtons';

class AssignWorkflow extends Component {
  state = {
    assignWorkflowCheckbox: undefined,
    saving: false,
  };

  handleInputChanged = (event, { name, checked }) => {
    this.setState({ [name]: checked });
  };

  setActivitySetWorkflow = (e, { value }) => {
    this.setState({ saving: true });
    const { updateWorkflow } = this.props;
    const activitySetId = this.props.planActivitySet.id;
    const workflowId = value;
    updateWorkflow(activitySetId, workflowId).then(() =>
      this.setState({ saving: false })
    );
  };

  removeWorkflowFromActivitySet = () => {
    this.setState({ saving: true });
    const { updateWorkflow } = this.props;
    const activitySetId = this.props.planActivitySet.id;
    this.setState({ assignWorkflowCheckbox: true });
    updateWorkflow(activitySetId).then(() => this.setState({ saving: false }));
  };
  render() {
    const { assignWorkflowCheckbox, saving } = this.state;
    const { workflows, planActivitySet } = this.props;
    const workflowId = planActivitySet.workflowId;
    const assignWorkflow = assignWorkflowCheckbox
      ? assignWorkflowCheckbox
      : !!planActivitySet.workflowId;
    return (
      <Form>
        <Form.Group className="assign-workflow-form">
          <Checkbox
            onChange={this.handleInputChanged}
            checked={assignWorkflow}
            name="assignWorkflowCheckbox"
            label="Assign a workflow"
            className="checkbox-workflow-activity-set"
          />
        </Form.Group>
        {assignWorkflow && (
          <Form.Group className="assign-workflow-form">
            <Dropdown
              fluid
              selection
              className="dropdown-workflow-activity-set"
              options={mapToOptions(workflows)}
              placeholder="Select the Workflow"
              onChange={(e, data) => {
                this.setActivitySetWorkflow(e, data);
              }}
              value={workflowId}
              disabled={saving}
              loading={saving}
            />
            {workflowId && (
              <ActionButtons
                disabled={saving}
                loading={saving}
                className="remove-workflow-button-action-set"
                removeClicked={this.removeWorkflowFromActivitySet}
              />
            )}
          </Form.Group>
        )}
      </Form>
    );
  }
}

AssignWorkflow.propTypes = {
  workflows: PropTypes.array,
  executionPlan: PropTypes.object,
  planActivitySet: PropTypes.object,
  updateWorkflow: PropTypes.func,
};

export default AssignWorkflow;
