import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { PartyWrapContext } from '../../context/PartyContext';
import RelationshipListItem from './RelationshipListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { getActiveItems } from '../../utils/PartyUtils';
import ConfirmWrapper from './../common/ConfirmWrapper';

const RelationshipList = (props) => {
  const {
    relationships,
    handleSortChange,
    paginationOptions,
    handleAddFilter,
    removeFilterValues,
    currentFilters,
  } = props;
  const { openSaveRelationshipModal } = props.partyActions;
  const { deleteRelationshipFromParty } = props.partyStateActions;
  const { showConfirmDialog } = props.confirmPopupActions;
  const renderRelationshipList = () => {
    return getActiveItems(relationships).map((relationship) => (
      <RelationshipListItem
        relationship={relationship}
        deletePartyRelationship={deleteRelationshipFromParty}
        editPartyRelationship={openSaveRelationshipModal}
        showConfirmDialog={showConfirmDialog}
        key={relationship.id}
      />
    ));
  };

  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
          >
            Entity Label
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="relationshipType"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
          >
            Entity Type
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="toParty"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
          >
            Entity relates to
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="createdAt"
            handleSortChange={handleSortChange}
          >
            Created At
          </SortableHeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderRelationshipList()}</Table.Body>
    </Table>
  );
};

RelationshipList.propTypes = {
  relationships: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  partyStateActions: PropTypes.object,
  partyActions: PropTypes.object,
  confirmPopupActions: PropTypes.object,
  removeFilterValues: PropTypes.func,
  handleAddFilter: PropTypes.func,
  currentFilters: PropTypes.object,
};

export default PartyWrapContext(ConfirmWrapper(RelationshipList));
