import { types } from '@babel/core';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Header } from 'semantic-ui-react';
import { RAGStatusResult, Result } from '../../../../../api/statusApi';
import {
  getStatusFlags,
  StatusFlags,
} from '../../../../../utils/data/statusUtils';
import DebugComponent from '../DebugComponent';

import './RAGHeader.scss';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import SectionHeader from '../SectionHeader/SectionHeader';
import { ComponentSize, RAGState } from '../../../../../types';

interface RAGHeaderProps {
  header?: string;
  ragLabel?: string;
  chartOptions: any;
  maxHeight: number;
  data?: PropsData;
  ragMetric: string;
  size?: ComponentSize;
}

export interface PropsData {
  selection: null;
  data: DataData;
}

export interface DataData {
  main: RAGStatusResult;
}

export interface Main {
  results: Result[];
}

const RAGHeader = (props: RAGHeaderProps) => {
  const {
    header,
    data,
    chartOptions,
    maxHeight,
    ragMetric,
    ragLabel,
    size,
  } = props;

  const { ragResult, args } = useMemo(() => {
    const args = data?.data?.main?.arguments;
    if (data?.data?.main?.results && ragMetric) {
      const result = data?.data?.main?.results.find(
        (result) => result.ref === ragMetric
      );

      return { ragResult: result, args };
    }
    return { args };
  }, [data, ragMetric]);

  let statusFlags: StatusFlags = {};

  if (ragResult) {
    statusFlags = getStatusFlags(ragResult);
  }

  const classNames = clsx('rag-header', {
    'rag-header--error': statusFlags.hasError,
    'rag-header--exception': statusFlags.hasException,
    'rag-header--no-results': statusFlags.hasNoResults,
    'rag-header--unfinished': statusFlags.hasUnfinished,
    'rag-header--positive': statusFlags.hasPositive,
  });

  let label = ragResult?.label;
  if (header) label = header;
  if (ragLabel) label = ragLabel;

  const ragState: RAGState = useMemo(() => {
    if (!ragResult) {
      return 'NONE';
    }
    if (statusFlags.hasError) {
      return 'ERROR';
    }
    if (statusFlags.hasException) {
      return 'EXCEPTION';
    }
    if (statusFlags.hasNoResults) {
      return 'NO_RESULTS';
    }
    if (statusFlags.hasUnfinished) {
      return 'UNFINISHED';
    }
    if (statusFlags.hasPositive) {
      return 'POSITIVE';
    }
    if (statusFlags.hasCaution) {
      return 'CAUTION';
    }
    return 'DEFAULT';
  }, [statusFlags]);

  return (
    <StatusPopupWrapper result={ragResult} args={args}>
      <SectionHeader
        maxHeight={maxHeight}
        header={label}
        size={size}
        state={ragState}
      />
    </StatusPopupWrapper>
  );
};

RAGHeader.fields = [
  {
    id: 'ragMetric',
    required: false,
    label: 'RAG Metric',
    activityFieldType: 'ModelSearchField',
    options: {
      modelName: 'Status',
      valueProperty: 'ref',
    },
  },
  {
    id: 'ragLabel',
    required: false,
    label: 'RAG Metric Label',
    activityFieldType: 'Text',
    options: null,
  },
  {
    id: 'size',
    required: false,
    label: 'Size',
    activityFieldType: 'Select',
    options: {
      values: ['tiny', 'small', 'medium', 'large', 'huge'],
    },
  },
];

export default RAGHeader;
