import React from 'react';
import * as PropTypes from 'prop-types';
import ActivitySets from '../activitySet/ActivitySets';
import { Grid, Label } from 'semantic-ui-react';
import EditableHeader from '../common/EditableHeader';
import ActionButtons from '../common/ActionButtons';

const ProgrammeCategory = (props) => {
  const {
    programme,
    category,
    selected,
    selectCategory,
    index,
    updateCategory,
    removeCategory,
  } = props;

  function filterActivities() {
    return programme.activitySets.filter(
      (activitySet) => activitySet.categoryTempId === category.uid
    );
  }

  return (
    <Grid.Column
      index={index}
      onClick={() => selectCategory(category.uid)}
      className={selected ? 'programme-categories__column--selected' : ''}
    >
      <EditableHeader
        placeholder="Add category name"
        value={category.label}
        name="label"
        onSubmit={(event, data) => updateCategory(category, data)}
      />

      <Label size="mini" attached="top right">
        <ActionButtons
          removeClicked={(e) => {
            e.stopPropagation();
            removeCategory(category);
          }}
        />
      </Label>

      <ActivitySets
        activitySets={filterActivities()}
        layout="LIST"
        {...props}
        showActions="remove up down"
        addClicked={props.setActivitySetCategory}
        removeClicked={props.setActivitySetCategory}
      />
    </Grid.Column>
  );
};

ProgrammeCategory.propTypes = {
  category: PropTypes.object.isRequired,
  programme: PropTypes.object.isRequired,
  selectCategory: PropTypes.func,
  updateCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  setActivitySetCategory: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
};

export default ProgrammeCategory;
