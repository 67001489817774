import { useState } from 'react';
import {
  Accordion,
  Container,
  Dropdown,
  Form,
  Header,
  Icon,
  Label,
  List,
  Popup,
} from 'semantic-ui-react';
import {
  Party,
  ExecutionExtended,
  FairValueTicket,
  Execution,
} from '../../types';
import { Link } from 'react-router-dom';

import './FairValueDistributorView.scss';
import ExecutionStatusOverview from '@vidende/components/ExecutionStatusOverview';
import { StackedBarProgress } from '@vidende/components/StackedBarProgress';

interface ListPartyProps {
  party: Party;
  parties: Party[];
  statusFilter?: string[];
  productClassFilter?: string[];
  productFilter?: string[];
  fairValueTickets: FairValueTicket[];
  topLevel?: boolean;
  workflowStatusTypes: any;
  LinkRenderer?: (ticket: FairValueTicket) => JSX.Element;
  showProducts?: boolean;
}

const ListParty = ({
  party,
  parties,
  statusFilter,
  productFilter,
  productClassFilter,
  fairValueTickets,
  topLevel,
  workflowStatusTypes,
  LinkRenderer,
  showProducts = true,
}: ListPartyProps) => {
  const [open, setOpen] = useState(false);

  const distributors = party.partyRelationships?.filter(
    (relationship) =>
      relationship.partyRelationshipTypeId === 'DIST' ||
      relationship.partyRelationshipTypeId === 'SUBDIST'
  );

  const hasContent = party.ticketSets.length > 0 || distributors?.length > 0;

  // const products = orderBy(party.ticketSets?.map(ticketSet => {
  //     const ticket = ticketSet.tickets?.[0]

  //     if (!ticket) return null;
  //     const extendedTicket = ticketsById[ticket.id]
  //     if (!extendedTicket) return null;
  //     const execution = executionsById[ticket.executionId];
  //     if (!execution) return null;

  //     // const productName =  execution.entries?.find(entry => entry.field.fieldKey === "CD-FV-PRODUCT")?.value;
  //     // const productClass =  execution.entries?.find(entry => entry.field.fieldKey === "CD-FV-CLASS")?.value;

  //     const productEntity = execution.entities?.find(entity => entity.entityTypeId === "PRODUCT");
  //     const productName =  extendedTicket.executionTicketSetLabel;
  //     const productClass =  "";

  //     const entities = extendedTicket.executionTicketSet?.entities?.filter(entity => entity.entityTypeId === "PRODUCT") || []

  //     return {
  //         label: productName,
  //         productClass,
  //     statusNodeColor: extendedTicket.statusNodeColor,
  //     statusTypeId: extendedTicket.statusTypeId,
  //     id: extendedTicket.id,
  //     executionId: execution.id,
  //     entities
  // }

  // }), 'label');

  const products = fairValueTickets.filter(
    (ticket) => ticket.partyId === party.id
  );

  const numComments = products.reduce((previous, current) => {
    return previous + current.commentsCount;
  }, 0);

  const numExceptions = products.reduce((previous, current) => {
    return previous + current.numException;
  }, 0);

  // if (products.length === 0 && !topLevel) return null;

  const renderContent = () => {
    if (!hasContent && !topLevel) return null;
    return (
      <>
        <List.List className="ui divided middle aligned list distributor-product-list ">
          {showProducts &&
            products &&
            products.map((product) => {
              if (!product) return null;

              if (
                statusFilter &&
                statusFilter?.length > 0 &&
                statusFilter.indexOf(product.statusTypeId) === -1
              )
                return null;
              if (
                productFilter?.length > 0 &&
                !productFilter.find((productId) =>
                  product.productIds?.includes(productId)
                )
              )
                return null;

              if (
                productClassFilter?.length > 0 &&
                !productClassFilter.find((productClass) =>
                  product.productClasses?.includes(productClass)
                )
              )
                return null;

              return (
                <List.Item>
                  <List.Icon
                    name="box"
                    size="large"
                    color={product.statusColour}
                  />
                  <List.Content>
                    {/* <Link to={`/ticket/${product.id}`}> */}
                    {LinkRenderer
                      ? LinkRenderer(product)
                      : product.ticketSetLabel}
                  </List.Content>
                  <List.Content
                    floated="right"
                    style={{ padding: 0, display: 'flex' }}
                  >
                    {product.commentsCount > 0 && (
                      <List.Icon name="comments outline" />
                    )}
                    {product.numException > 0 && product.exceptionInfo && (
                      <Popup
                        trigger={
                          <List.Icon name="exclamation triangle" color="red" />
                        }
                        content={
                          <List divided>
                            {product.exceptionInfo.exceptions.map(
                              (exception) => (
                                <List.Item>{exception.rule}</List.Item>
                              )
                            )}
                          </List>
                        }
                        position="left center"
                      />
                    )}
                  </List.Content>
                </List.Item>
              );
            })}
        </List.List>

        {distributors.map((distributorRelationship) => {
          const distributor = parties.find(
            (party) => party.id === distributorRelationship.toPartyId
          );
          // if (!distributor || distributor.ticketSets.length === 0) return null;
          if (!distributor) return null;
          return (
            <List.List>
              <ListParty
                party={distributor}
                statusFilter={statusFilter}
                productFilter={productFilter}
                productClassFilter={productClassFilter}
                fairValueTickets={fairValueTickets}
                parties={parties}
                workflowStatusTypes={workflowStatusTypes}
                LinkRenderer={LinkRenderer}
                showProducts={showProducts}
              />
            </List.List>
          );
        })}
      </>
    );
  };

  if (topLevel) {
    const execution = {
      ticketCount: products.length,
      statuses: [
        {
          id: 'TODO',
          name: 'To Do',
          sort: 1,
          colourId: 'grey',
          ticketStatusCount: products.filter(
            (product) => product.statusTypeId === 'TODO'
          ).length,
        },
        {
          id: 'APPROVAL',
          name: 'Awaiting Approval',
          sort: 2,
          colourId: 'blue',
          ticketStatusCount: products.filter(
            (product) => product.statusTypeId === 'APPROVAL'
          ).length,
        },
        {
          id: 'REJECTED',
          name: 'Rejected',
          sort: 3,
          colourId: 'red',
          ticketStatusCount: products.filter(
            (product) => product.statusTypeId === 'REJECTED'
          ).length,
        },
        {
          id: 'DONE',
          name: 'Done',
          sort: 4,
          colourId: 'green',
          ticketStatusCount: products.filter(
            (product) => product.statusTypeId === 'DONE'
          ).length,
        },
        {
          id: 'ACTION',
          name: 'Action',
          sort: 4,
          colourId: 'orange',
          ticketStatusCount: products.filter(
            (product) => product.statusTypeId === 'ACTION'
          ).length,
        },
      ],
    };

    const numRejected = products.filter(
      (product) => product.statusTypeId === 'REJECTED'
    ).length;

    const isExpandable = products.length > 0;

    return (
      <>
        <Accordion.Title
          active={hasContent && open}
          index={0}
          onClick={() => (isExpandable ? setOpen(!open) : null)}
          className="distributor-accordion-title"
        >
          {isExpandable && <Icon name="dropdown" />}
          <Header>{party.label}</Header>

          <div className="distributor-right-content">
            <Popup
              wide="very"
              content={
                <ExecutionStatusOverview
                  workflowStatusTypes={workflowStatusTypes}
                  execution={execution as ExecutionExtended}
                />
              }
              position="left center"
              on={['hover', 'click']}
              trigger={
                <div className="distributor-progress-bar-container">
                  <StackedBarProgress
                    workflowStatusTypes={workflowStatusTypes}
                    execution={execution as ExecutionExtended}
                  />
                </div>
              }
            />
            {showProducts && (
              <div className="distributor-status-labels">
                <Popup
                  wide
                  trigger={
                    <Label size="large">
                      <Icon name="box" /> {products.length}
                    </Label>
                  }
                  content={'Products Selected'}
                  position="top center"
                />
                <Popup
                  wide
                  trigger={
                    <Label size="large">
                      <Icon name="building" /> {distributors.length}
                    </Label>
                  }
                  content={'Sub Distributors added'}
                  position="top center"
                />
                <Popup
                  wide
                  trigger={
                    <Label size="large">
                      <Icon name="comments" /> {numComments}
                    </Label>
                  }
                  content={'Comments'}
                  position="top center"
                />
                <Popup
                  wide
                  trigger={
                    <Label size="large" color={numRejected > 0 ? 'red' : null}>
                      <Icon name="exclamation triangle" /> {numRejected}
                    </Label>
                  }
                  content={'Products with Exception'}
                  position="top center"
                />
              </div>
            )}
          </div>
        </Accordion.Title>
        <Accordion.Content
          active={open}
          className="distributor-accordion-content"
        >
          <List>
            <List.Item>{renderContent()}</List.Item>
          </List>
        </Accordion.Content>
      </>
    );
  }

  return (
    <List.Item className="fair-value-view-party-item">
      <List.Icon name="building" size="large" />

      <List.Content
        className={
          hasContent
            ? open
              ? 'fair-value-view-party-item-content__open'
              : 'fair-value-view-party-item-content__closed'
            : ''
        }
      >
        <List.Header onClick={() => setOpen(!open)}>
          {party.label}

          {hasContent ? (
            open ? (
              <Icon name="minus" size="small" />
            ) : (
              <Icon name="plus" size="small" />
            )
          ) : null}
        </List.Header>

        {renderContent()}
      </List.Content>
    </List.Item>
  );
};

export default ListParty;
