import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Select as SSelect } from 'semantic-ui-react';
import * as _ from 'lodash';

import FieldErrors from './FieldErrors';
import { mapToOptions } from '../../../utils/React';
import FieldToolTip from './FieldToolTip';

const Select = (props) => {
  const {
    label,
    options,
    onChange,
    value,
    name,
    disabled,
    editable = true,
    required,
    errors,
    onFocus,
    onBlur,
    width,
    className,
    loading,
    sortItemsAlphabetically = false,
    clearable = false,
    compact,
    fluid,
    open,
    error
  } = props;
  const fieldErrors = errors ? errors[name] : undefined;

  if (!editable) {
    return <React.Fragment>{value}</React.Fragment>;
  }
  return (
    <React.Fragment>
      <Form.Field required={required} width={width} error={error}>
        <label>
          {label}
          <FieldToolTip {...props} />
        </label>
        <Form.Field
          control={SSelect}
          options={mapToOptions(options, null, sortItemsAlphabetically)}
          placeholder={label}
          value={value}
          name={name}
          disabled={disabled || !editable}
          error={!!fieldErrors}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={className}
          loading={loading}
          selectOnBlur={false}
          clearable={clearable}
          compact={compact}
          fluid={fluid}
          open={open}
        />
      </Form.Field>
      {!_.isEmpty && <FieldErrors errors={fieldErrors} />}
    </React.Fragment>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  errors: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  sortItemsAlphabetically: PropTypes.bool,
  clearable: PropTypes.bool,
  compact: PropTypes.bool,
  fluid: PropTypes.bool,
  open: PropTypes.bool,
};

export default Select;
