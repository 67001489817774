import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Dimmer,
  Loader,
  Modal,
  Form,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import * as _ from 'lodash';
import moment from 'moment';

import { mapToOptions } from '../../utils/React';
import Date from '../common/fields/Date';
import { filterTypes, dateProperties } from '../../constants/filterConstants';

class AddFilterModal extends Component {
  state = {
    saving: false,
    selectedValue: [],
    fromDate: moment(),
    toDate: moment(),
    isDateFilter: false,
    searchValue: null,
    minDate: {},
    maxDate: {},
  };

  componentWillMount() {
    const { property, filters } = this.props;
    const isDate = _.includes(dateProperties, property);
    const currentFilters = _.filter(filters, { property });
    isDate
      ? this.findMinMaxDates(currentFilters)
      : this.setFilterValues(currentFilters);
  }

  updateFilterValue = (e, data) => {
    this.setState({ selectedValue: data.value });
  };

  applyFilter = () => {
    const { property, applyFilter, isSearchable, onClose } = this.props;
    const { selectedValue, searchValue, isDateFilter } = this.state;
    let filterData = [];
    const { fromDate, toDate } = this.state;
    if (isDateFilter && fromDate && toDate) {
      filterData.push({
        property,
        values: { from: fromDate, to: toDate },
        type: filterTypes.DATE,
      });
    } else {
      selectedValue.length &&
        filterData.push({
          property,
          values: selectedValue,
          type: filterTypes.FILTER,
        });
    }
    if (isSearchable && searchValue)
      filterData.push({
        property,
        values: searchValue,
        type: filterTypes.SEARCH,
      });
    applyFilter(filterData, property);
    onClose();
  };

  resetFilter = () => {
    const { resetFilter, onClose } = this.props;
    resetFilter();
    onClose();
  };

  findMinMaxDates = (currentFilters) => {
    const { values } = this.props;
    const datesMoment = _.filter(
      _.map(values, (value) => value && moment(value, moment.ISO_8601)),
      (item) => item != null
    );
    const minDate = moment.min(datesMoment);
    const maxDate = moment.max(datesMoment);
    this.setState({ minDate, maxDate, isDateFilter: true }, () => {
      currentFilters.length
        ? this.setInitialValue(currentFilters)
        : this.setState({ toDate: maxDate, fromDate: minDate });
    });
  };

  handleOnDateChange = (e, val) => {
    this.setState({ [val.name]: moment(val.value) });
  };

  applyDateFilter = () => {
    const { property, applyFilter } = this.props;
    const { fromDate, toDate } = this.state;
    applyFilter(property, { from: fromDate, to: toDate });
  };

  applySearch = () => {
    const { property, applySearch } = this.props;
    const { searchValue } = this.state;
    applySearch(property, searchValue);
  };

  setInitialValue = (currentFilters) => {
    const {
      values: { from, to },
    } = _.head(currentFilters);
    this.setState({ toDate: to, fromDate: from });
  };

  handleInputChanged = (event, { name, value, checked }) => {
    this.setState({ [name]: value || checked });
  };

  setFilterValues = (currentFilters) => {
    const { isSearchable } = this.props;
    const filterValues = this.getCurrentValues(
      currentFilters.length,
      currentFilters,
      filterTypes.FILTER
    );
    const searchValues = this.getCurrentValues(
      isSearchable,
      currentFilters,
      filterTypes.SEARCH
    );
    this.setState(
      {
        selectedValue: this.getValues(filterValues) || [],
        searchValue: this.getValues(searchValues) || '',
      },
      () => {
        if (isSearchable && this.textInput) this.textInput.focus();
      }
    );
  };

  getValues = (array) => {
    return _.get(_.head(array), 'values');
  };

  getCurrentValues = (condition, currentFilters, type) => {
    return condition ? _.filter(currentFilters, { type }) : [];
  };

  render() {
    const {
      saving,
      selectedValue,
      fromDate,
      toDate,
      isDateFilter,
      minDate,
      maxDate,
      searchValue,
    } = this.state;
    const { friendlyName, values, isSearchable, isFilterable, onClose } =
      this.props;
    return (
      <Modal
        open={true}
        onClose={() => onClose()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>Add filter for {friendlyName} column</Modal.Header>
        <Modal.Content>
          {!isDateFilter ? (
            <Form onSubmit={this.applyFilter}>
              {isFilterable && (
                <Form.Field>
                  <Dropdown
                    placeholder="Select value"
                    fluid
                    selection
                    options={mapToOptions(
                      _.filter(values, (value) => value != undefined),
                      null,
                      true
                    )}
                    onChange={this.updateFilterValue}
                    value={selectedValue}
                    multiple
                  />
                </Form.Field>
              )}
              {isSearchable && (
                <Form.Field>
                  <Input
                    ref={(input) => {
                      this.textInput = input;
                    }}
                    placeholder="Search..."
                    name="searchValue"
                    value={searchValue}
                    onChange={this.handleInputChanged}
                  />
                </Form.Field>
              )}
            </Form>
          ) : (
            <Form>
              <Form.Field>
                <Date
                  label="From"
                  name="fromDate"
                  value={fromDate}
                  onChange={this.handleOnDateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectsStart={true}
                  startDate={fromDate}
                  endDate={toDate}
                />
                <Date
                  label="To"
                  name="toDate"
                  value={toDate}
                  onChange={this.handleOnDateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectsEnd={true}
                  startDate={fromDate}
                  endDate={toDate}
                />
              </Form.Field>
            </Form>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button positive onClick={this.applyFilter} compact color="red">
            Apply
          </Button>
          <Button negative onClick={this.resetFilter} compact color="red">
            Reset
          </Button>
          <Button secondary onClick={() => onClose()} compact color="red">
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AddFilterModal.propTypes = {
  property: PropTypes.string,
  values: PropTypes.array,
  applyFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  filters: PropTypes.array,
  friendlyName: PropTypes.string,
  isSearchable: PropTypes.bool,
  applySearch: PropTypes.func,
  isFilterable: PropTypes.bool,
  searchValue: PropTypes.object,
};

export default AddFilterModal;
