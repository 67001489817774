import { Button, Icon, List, Popup } from 'semantic-ui-react';
import { useAppSelector } from '../../../actions/store';
import { useContext, useEffect, useState } from 'react';
import { ModelInstanceLabel } from '../../common/modelRenderers/ModelInstanceLabel';
import { ModelInstanceInfo } from './ModelInstanceInfo';
import { ModelStructure } from '../../../modelStrutureTypes';
import { OpenCloseContext } from './ModelTreeRoot';

const getFlattenedChildren = (
  modelName: string,
  modelObject: any,
  flattenConfig: string[],
  modelStructure: ModelStructure
) => {
  let currentObjects = [modelObject];
  let currentModelName = modelName;

  flattenConfig.forEach((relationship) => {
    currentObjects = currentObjects
      .map((obj) =>
        obj[relationship]
          ?.sort((a: any, b: any) => {
            if (a.options?.sort && b.options?.sort) {
              return a.options.sort - b.options.sort;
            }
            return modelStructure[currentModelName]?.properties?.sort
              ? a.sort - b.sort
              : 0;
          })
          .map((child: any) => {
            const modelTo =
              modelStructure[currentModelName].relationships[relationship]
                .modelTo;
            return {
              ...child,
              modelName: modelTo,
            };
          })
      )
      .flat();
    currentModelName =
      modelStructure[currentModelName].relationships[relationship].modelTo;
  });
  return currentObjects;
};

type ModelTreeItemProps = {
  modelObject: any;
  modelName: string;
  relationshipConfig: Record<string, string[]>;
  initialOpen?: boolean;
  flattenBy?: Record<string, string[]>;
};

export const ModelTreeItem = ({
  modelName,
  modelObject,
  relationshipConfig,
  initialOpen = false,
  flattenBy,
}: ModelTreeItemProps) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const modelStructure = useAppSelector(
    (state) => state.constants.modelStructure
  );

  const { openAll, setOpenAll } = useContext(OpenCloseContext);

  useEffect(() => {
    if (openAll === true) {
      setIsOpen(true);
    }
    if (openAll === false) {
      setIsOpen(false);
    }
  }, [openAll]);

  const modelConfig = modelStructure[modelName];

  const hasChildren = relationshipConfig[modelName]?.length > 0;
  const flattenConfig = flattenBy?.[modelName];

  const relationshipsToRender = relationshipConfig[modelName] || [];

  const flattenedChildren = flattenConfig
    ? getFlattenedChildren(
        modelName,
        modelObject,
        flattenConfig,
        modelStructure
      )
    : null;

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setOpenAll(null);
  };

  if (!modelObject) return null;

  return (
    <List.Item>
      {!flattenConfig && (
        <>
          {hasChildren && (
            <List.Icon
              name={isOpen ? 'minus' : 'plus'}
              size="small"
              onClick={() => (hasChildren ? handleToggle() : {})}
            />
          )}
          <List.Content>
            <Popup
              size="small"
              content={
                <ModelInstanceInfo
                  modelObject={modelObject}
                  modelName={modelName}
                />
              }
              trigger={
                <span>
                  <ModelInstanceLabel
                    modelInstance={modelObject}
                    modelName={modelName}
                  />
                </span>
              }
            />

            {isOpen && hasChildren && (
              <>
                {relationshipsToRender.map((relationship) => {
                  const sortedChildren =
                    modelStructure[modelName]?.properties?.sort &&
                    modelObject[relationship]
                      ? [...modelObject[relationship]].sort(
                          (a, b) => a.sort - b.sort
                        )
                      : modelObject[relationship];

                  return (
                    <List.List key={relationship}>
                      {sortedChildren?.map((child: any) => (
                        <ModelTreeItem
                          key={child.id}
                          modelObject={child}
                          modelName={
                            modelConfig.relationships[relationship].modelTo
                          }
                          relationshipConfig={relationshipConfig}
                        />
                      ))}
                    </List.List>
                  );
                })}
              </>
            )}
          </List.Content>
        </>
      )}

      {flattenConfig && (
        <List.List>
          {flattenedChildren?.map((child: any) => (
            <ModelTreeItem
              key={child.id}
              modelObject={child}
              modelName={child.modelName}
              relationshipConfig={relationshipConfig}
              flattenBy={flattenBy}
            />
          ))}
        </List.List>
      )}
    </List.Item>
  );
};
