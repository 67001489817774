import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';

import PartyLabel from '../common/labels/PartyLabel';
import UserLabel from '../common/labels/UserLabel';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import KpiComponent from '../common/dashboard/components/KpiComponent/KpiComponent';
import moment from 'moment/moment';

const DetailsExecutionOverview = (props) => {
  const execution = props.execution || props.currentExecution;
  const {
    ownerParty,
    assignedParty,
    reviewParty,
    ownerPartyUser,
    reviewPartyUser,
    assignedPartyUser,
    dueDate,
  } = execution;
  const daysLate = moment().diff(execution.dueDate, 'days');
  const dateLateColor =
    daysLate > 0 ? 'red' : daysLate > -5 ? 'orange' : 'purple';
  return (
    <Grid divided="vertically">
      <Grid.Row columns={3}>
        <Grid.Column>
          {ownerParty && (
            <React.Fragment>
              <Header sub>Owner Party</Header>
              <PartyLabel party={ownerParty} />
            </React.Fragment>
          )}
          {assignedParty && (
            <React.Fragment>
              <Header sub>Assign Party</Header>
              <PartyLabel party={assignedParty} />
            </React.Fragment>
          )}
          {reviewParty && (
            <React.Fragment>
              <Header sub>Review Party</Header>
              <PartyLabel party={reviewParty} />
            </React.Fragment>
          )}
        </Grid.Column>
        <Grid.Column>
          {ownerPartyUser && (
            <React.Fragment>
              <Header sub>Owner Party User</Header>
              <UserLabel user={ownerPartyUser} />
            </React.Fragment>
          )}
          {assignedPartyUser && (
            <React.Fragment>
              <Header sub>Assign Party User</Header>
              <UserLabel user={assignedPartyUser} />
            </React.Fragment>
          )}
          {reviewPartyUser && (
            <React.Fragment>
              <Header sub>Review Party User</Header>
              <UserLabel user={reviewPartyUser} />
            </React.Fragment>
          )}
        </Grid.Column>
        <Grid.Column>
          {dueDate && (
            <Segment className="dashboard-widget kpi">
              <KpiComponent
                format={'Date'}
                label="Due Date"
                style={1}
                value={dueDate}
                color={dateLateColor}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DetailsExecutionOverview.propTypes = {
  execution: PropTypes.object,
  currentExecution: PropTypes.object,
};

export default ExecutionContextConsumer(DetailsExecutionOverview);
