import { useEffect, useState, useMemo } from 'react';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Header,
  Segment,
  Dimmer,
  Loader,
  Label,
  Icon,
} from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

let ReactJson;
if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  ReactJson = React.Fragment;
} else {
  ReactJson = require('react-json-view').default;
}

import WidgetComponents from './DashboardWidgetComponents';
import { applyDataMapping } from '../../../utils/data/DataMapper';
import ActionButtons from '../ActionButtons';
import { mergeDataCall } from '../../../utils/data/DataUtils';
import hashSum from 'hash-sum';
import SimpleErrorWrapper from '../SimpleErrorWrapper';

const DashboardWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState(null);

  const checkProperty = (dashboardProperties) => {
    const { config } = props;
    if (!config.allowedProperties) return false;

    const allowedPropertyKeys = config.allowedProperties.map((p) => p.name);

    const usedProperties = _.pick(dashboardProperties, allowedPropertyKeys);

    return hashSum(usedProperties);
  };

  const { dashboardProperties } = props;
  const dataCallsHash = checkProperty(dashboardProperties);

  useEffect(() => {
    refreshData();
  }, [dataCallsHash]);

  const refreshData = (force) => {
    const { forceChartRefresh } = props;
    if (!props.chartActions || !props.apiCalls) {
      setLoading(false);
      return;
    }
    const {
      apiCalls,
      chartActions,
      id,
      dataMapping,
      config,
      dashboardWhereFilters,
      dashboardProperties,
      chartData,
    } = props;
    const dataCalls = _.zipObject(
      apiCalls.map((d) => d.name),
      apiCalls.map((d) => d.apiCall.arguments)
    );
    const { applyFilters = true } = config;
    if (config.mockData || config.noDataCall || !dataMapping) {
      setLoading(false);
      return;
    }

    let clonedDataCalls;

    if (applyFilters) {
      clonedDataCalls = mergeDataCall({
        dashboardWhereFilters,
        dashboardProperties,
        dataCalls,
        id,
        allowedProperties: config.allowedProperties,
        extraParams: config.apiParams,
      });
    } else {
      clonedDataCalls = _.cloneDeep(dataCalls);
    }

    const dataCallsHash = hashSum(clonedDataCalls);

    if (
      chartData &&
      chartData.data &&
      chartData.data.length > 0 &&
      chartData.dataCallsHash &&
      forceChartRefresh === false
    ) {
      if (chartData.dataCallsHash === dataCallsHash && !force) {
        setLoading(false);
        return;
      }
    }

    setLoading(true);

    chartActions
      .loadDashboardWidgetData(clonedDataCalls, id, dataCallsHash)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        toastr.error(error);
        setLoading(false);
        throw error;
      });
  };

  const chartCallback = (data) => {
    const { config, onSetDashboadData } = props;
    const { dataValue } = data;
    const {
      clickType = data.clickType || 'drillThrough',
      propertyToSet,
      multiSelect,
      drillThroughEnabled,
    } = config;

    if (data.args && data.args.stackBy && data.stack) {
      dataValue[data.args.stackBy] = data.stack;
    }

    switch (clickType) {
      case 'redirect': {
        if (props.performRedirect) props.performRedirect({ dataValue, config });
        break;
      }
      case 'setData': {
        if (onSetDashboadData) onSetDashboadData(data);
        break;
      }
      case 'drillThrough': {
        if (drillThroughEnabled !== false)
          props.performDrillthrough({ dataValue });
        break;
      }
      case 'selection': {
        let newSelection;
        const args = _.map(dataValue, (value, key) => {
          if (multiSelect) {
            if (selection && selection.indexOf(value) !== -1) {
              newSelection = _.without(selection, value);
            } else {
              // newSelection = selection ? [...selection, value] : [value];
              newSelection = [value];
            }
          } else {
            newSelection = selection === value ? '' : value;
          }

          return {
            property: propertyToSet || key,
            value: newSelection,
          };
        })[0];

        dashboardCallback(args);

        setSelection(newSelection);

        break;
      }
    }
  };

  const dashboardCallback = (args) => {
    const { dashboardCallback, id, config } = props;

    if (config.drillThroughEnabled) return chartCallback(args);
    if (dashboardCallback) dashboardCallback({ id, ...args });
  };

  const {
    config,
    type,
    chartData,
    config: {
      header,
      hideHeader,
      plainStyle,
      extraStyles = {},
      borderless = false,
      refresh = false,
      maxHeight = false,
      extraPadding = false,
      testData,
      extraMapping,
      propertyToSet,
      apiParams,
    },
    dataMapping,
    editable,
    editActions,
    extraData,
    style,
    showDebug,
    useDemoData,
    onDataMapped,
    onMouseOver,
  } = props;

  const extraStyle = {};
  if (maxHeight) extraStyle.maxHeight = `${maxHeight}px`;

  let formattedChartData = useMemo(() => {
    if (
      (chartData && chartData.data) ||
      extraData ||
      (testData && useDemoData)
    ) {
      let dataToMap = [
        ...((chartData && chartData.data) || []),
        ...(extraData || []),
      ];

      if (testData && (useDemoData || config.useDemoData)) {
        dataToMap = [...dataToMap, ...testData];
      }
      const mappedData = applyDataMapping(
        dataToMap,
        dataMapping,
        chartCallback,
        {
          selection,
        },
        extraMapping
      );

      if (props.onDataMapped) onDataMapped(mappedData);

      return mappedData;
    }
  }, [dataCallsHash, chartData, extraData, testData]);
  const Component = WidgetComponents[type];
  const combinedStyle = Object.assign({}, style, extraStyle, extraStyles);

  let externalValue;
  if (propertyToSet && dashboardProperties) {
    if (type !== 'dateRange') {
      externalValue = dashboardProperties[propertyToSet];
    } else {
      const fromProperty = propertyToSet.fromDate;
      const fromDate = dashboardProperties[fromProperty];
      const toProperty = propertyToSet.toDate;
      const toDate = dashboardProperties[toProperty];
      externalValue = {
        fromDate,
        toDate,
      };
    }
  }

  return (
    <React.Fragment>
      {showDebug && (
        <React.Fragment>
          <ReactJson src={formattedChartData} collapsed={1} />
          <ActionButtons refreshClicked={() => refreshData(true)} />
        </React.Fragment>
      )}
      <Segment
        attached="bottom"
        basic={plainStyle ? true : false}
        className={`dashboard-widget ${type} ${
          selection && selection.length > 0 ? 'has-selection' : ''
        }
            ${borderless ? 'borderless' : ''}
            ${loading ? 'isLoading' : ''}
            ${maxHeight ? 'has-max-height' : ''}
            ${extraPadding ? 'extra-padding' : ''}
            `}
        style={combinedStyle}
        onMouseOver={onMouseOver}
      >
        {loading && (
          <Dimmer active={loading} inverted>
            <Loader disabled={!loading} size="tiny" />
          </Dimmer>
        )}

        {refresh && (
          <Label as="a" className="dashboard-widget-refresh-button" size="mini">
            <Icon name="refresh" onClick={() => refreshData(true)} />
          </Label>
        )}
        {!hideHeader && (
          <Header
            as={plainStyle ? 'h1' : 'h2'}
            attached={plainStyle ? '' : 'top'}
            block={plainStyle ? false : true}
            className={plainStyle ? '' : 'header-dashboard-widget'}
          >
            {header}
          </Header>
        )}
        {config.mockData ? (
          <SimpleErrorWrapper>
            <Component
              {...config}
              callback={dashboardCallback}
              externalValue={externalValue}
              dashboardProperties={dashboardProperties}
            />
          </SimpleErrorWrapper>
        ) : formattedChartData ? (
          <SimpleErrorWrapper>
            <Component
              {...formattedChartData}
              {...config}
              callback={dashboardCallback}
              externalValue={externalValue}
              dashboardProperties={dashboardProperties}
            />
          </SimpleErrorWrapper>
        ) : (
          <SimpleErrorWrapper>
            <Component
              {...config}
              callback={dashboardCallback}
              externalValue={externalValue}
              dashboardProperties={dashboardProperties}
            />
          </SimpleErrorWrapper>
        )}
        {editable && (
          <ActionButtons
            className="dashboard-action-buttons"
            size="medium"
            hideMode={true}
            {...editActions}
            refreshClicked={() => refreshData(true)}
          />
        )}
      </Segment>
    </React.Fragment>
  );
};

DashboardWidget.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dataCalls: PropTypes.object,
  dataMapping: PropTypes.object,
  config: PropTypes.object.isRequired,
  uiActions: PropTypes.object,
  chartActions: PropTypes.object,
  chartData: PropTypes.object,
  dashboardCallback: PropTypes.func,
  chartCallback: PropTypes.func,
  performDrillthrough: PropTypes.func,
  dashboardWhereFilters: PropTypes.object,
  dashboardProperties: PropTypes.object,
  modelStructure: PropTypes.object,
  editActions: PropTypes.object,
  style: PropTypes.object,
  extraData: PropTypes.array,
  editable: PropTypes.bool,
  forceChartRefresh: PropTypes.bool,
  showDebug: PropTypes.bool,
  useDemoData: PropTypes.bool,
  onDataMapped: PropTypes.func,
  apiCalls: PropTypes.array,
  extraMapping: PropTypes.object,
  performRedirect: PropTypes.func,
  onMouseOver: PropTypes.func,
};

export default DashboardWidget;
