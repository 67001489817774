import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import toastr from 'toastr';

import ActionButtons from './../common/ActionButtons';

class ThresholdListItem extends Component {
  state = {
    loading: false,
  };
  deleteAdminThresholdClicked = () => {
    const { threshold, showConfirmDialog } = this.props;
    const { reference } = threshold;
    showConfirmDialog(
      `Are you really want to delete ${reference} threshold?`,
      this.deleteThresholdOnConfirm
    );
  };
  deleteThresholdOnConfirm = () => {
    const { threshold, removeAdminThreshold } = this.props;
    const { id } = threshold;
    this.setState({ loading: true });
    removeAdminThreshold(id).then(this.deleteThresholdOnConfirmCallback);
  };
  deleteThresholdOnConfirmCallback = () => {
    const { threshold } = this.props;
    const { reference } = threshold;
    this.setState({ loading: false });
    toastr.success(`Threshold ${reference} is removed`);
  };
  editAdminThresholdClicked = () => {
    const { threshold, editAdminThreshold } = this.props;
    editAdminThreshold(threshold);
  };
  render() {
    const { threshold } = this.props;
    const { label, reference, description } = threshold;
    const { loading } = this.state;
    return (
      <Table.Row>
        <Table.Cell width={2}>{label}</Table.Cell>
        <Table.Cell width={2}>{reference}</Table.Cell>
        <Table.Cell width={2}>{description}</Table.Cell>
        <Table.Cell width={1} textAlign="center">
          <ActionButtons
            className="u-show-on-hover__target"
            deleteClicked={this.deleteAdminThresholdClicked}
            editClicked={this.editAdminThresholdClicked}
            loading={loading}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

ThresholdListItem.propTypes = {
  threshold: PropTypes.object.isRequired,
  showConfirmDialog: PropTypes.func,
  removeAdminThreshold: PropTypes.func,
  editAdminThreshold: PropTypes.func,
};

export default ThresholdListItem;
