import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import {
  Confirm,
  Container,
  Dimmer,
  Header,
  Loader,
  Button,
} from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

import ActivitiesList from './ActivitiesList';
import ErrorBoundary from './../common/ErrorBoundary';
import * as activitiesActions from '../../actions/activitiesActions';
import * as activityActions from '../../actions/activityActions';
import * as uiActions from '../../actions/uiActions';
import CheckPermission from '../common/CheckPermission';
import * as permissionTypes from './../../constants/permissionTypes';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

class ActivitiesPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      saving: false,
      loading: false,
      activityToDelete: {},
      deleteConfirmationOpen: false,
      filters: {
        label: '',
        ref: '',
        activityStatusId: 0,
        structuralNodeId: 0,
        primaryTag: false,
        tags: [],
      },
    };

    this.loadActivities = this.loadActivities.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.confirmDeleteActivity = this.confirmDeleteActivity.bind(this);
    this.cancelDeleteActivity = this.cancelDeleteActivity.bind(this);
  }

  componentDidMount() {
    this.loadActivities();
  }

  resetFilter = () => {
    this.setState(
      {
        filters: {
          label: '',
          ref: '',
          activityStatusId: 0,
          structuralNodeId: 0,
          primaryTag: false,
          tags: [],
        },
      },
      () => {
        this.loadActivities();
      }
    );
  };
  updateFilterValue = (event, { name, value }, reload = false) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (reload) this.loadActivities();
      }
    );
  };
  loadActivities() {
    this.setState({
      loading: true,
    });
    this.props.actions.loadActivities(this.state.filters).then(() => {
      this.setState({ loading: false });
    });
  }
  viewActivity = (activity) => {
    this.props.history.push(`/question/${activity.id}`);
  };

  editActivity = (activity) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {
        activityId: activity.id,
      },
    });
  };
  cloneActivity = (activity) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {
        activityId: activity.id,
        cloneActivity: true,
      },
    });
  };
  deleteActivity(activity) {
    this.setState({
      activityToDelete: activity,
      deleteConfirmationOpen: true,
    });
  }
  confirmDeleteActivity() {
    this.setState({
      saving: true,
      deleteConfirmationOpen: false,
    });

    const newValue = Object.assign({}, this.state.activityToDelete, {
      activityStatusId: _.find(this.props.activityStatuses, {
        label: 'DEACTIVATED',
      }).id,
    });
    this.props.activityActions
      .saveActivity(newValue)
      .then(() => {
        toastr.success('Activity deleted.');
        this.setState({ saving: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ saving: false });
      });
  }
  cancelDeleteActivity() {
    this.setState({
      activityToDelete: {},
      deleteConfirmationOpen: false,
    });
  }
  createNewActivityHandle = () => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {},
    });
  };
  render() {
    const { activityStatuses, tags, parties } = this.props;

    const activities = [...this.props.activities].splice(0, 30);

    const { filters, loading, saving } = this.state;
    return (
      <Container fluid>
        <Dimmer active={loading || saving} inverted>
          <Loader />
        </Dimmer>
        <Header as="h2">Question Bank</Header>
        <ActivitiesList
          activities={activities}
          editActivity={this.editActivity}
          cloneActivity={this.cloneActivity}
          viewActivity={this.viewActivity}
          deleteActivity={this.deleteActivity}
          updateFilterValue={this.updateFilterValue}
          loadActivities={this.loadActivities}
          resetFilter={this.resetFilter}
          activityStatuses={activityStatuses}
          tags={tags}
          parties={parties}
          filters={filters}
        />
        <CheckPermission
          permissionType={permissionTypes.SUPER}
          structuralNodeId={2}
        >
          <Button onClick={this.createNewActivityHandle} primary>
            Create New Question
          </Button>
        </CheckPermission>
        <Confirm
          open={this.state.deleteConfirmationOpen}
          content="Are you sure you want to delete this question?"
          onConfirm={this.confirmDeleteActivity}
          onCancel={this.cancelDeleteActivity}
        />
      </Container>
    );
  }
}

ActivitiesPage.propTypes = {
  activities: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  parties: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  uiActions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activityStatuses: PropTypes.object.isRequired,
  activityActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    activities: state.activities,
    tags: state.tags,
    parties: state.parties.list,
    activityStatuses: _.keyBy(state.constants.activityStatuses, 'id'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activitiesActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default ErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ActivitiesPage)
);
