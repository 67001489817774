import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import * as _ from 'lodash';

import './ActionButtons.scss';

const ActionButtons = ({
  size = 'mini',
  color = 'blue',
  inverted = false,
  vertical = false,
  compact = false,
  className,
  editClicked,
  configureClicked,
  notifyClicked,
  deleteClicked,
  addClicked,
  minusClicked,
  removeClicked,
  upClicked,
  downClicked,
  leftClicked,
  rightClicked,
  copyClicked,
  pasteClicked,
  viewClicked,
  hideClicked,
  saveClicked,
  downloadClicked,
  refreshClicked,
  infoClicked,
  launchGovernanceProgrammeClicked,
  showActions,
  addUser,
  resetPassword,
  disabled = false,
  loading = false,
  hideMode = false,
  style,
  data,
}) => {
  const [hidden, setHidden] = React.useState(false);

  const toShow = _.fromPairs(
    showActions.split(' ').map((action) => [action, true])
  );
  const handleClick = (e, clickFunc) => {
    e.data = data;
    clickFunc(e);
  };
  return (
    <Button.Group
      size={size}
      className={`${className} hide-for-print ${compact ? 'compact' : ''}  ${
        hideMode ? 'hide-mode' : ''
      } action-buttons`}
      style={style}
      vertical={vertical}
    >
      {deleteClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="trash alternate outline"
          onClick={(e) => {
            handleClick(e, deleteClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {hideMode && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="configure"
          onClick={() => {}}
          disabled={disabled}
          loading={loading}
          className="hide-mode-button"
        />
      )}
      {upClicked && toShow.up && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="angle up"
          onClick={(e) => {
            handleClick(e, upClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {downClicked && toShow.down && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="angle down"
          onClick={(e) => {
            handleClick(e, downClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {leftClicked && toShow.left && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="angle left"
          onClick={(e) => {
            handleClick(e, leftClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {rightClicked && toShow.right && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="angle right"
          onClick={(e) => {
            handleClick(e, rightClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {refreshClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="refresh"
          onClick={(e) => {
            handleClick(e, refreshClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {notifyClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="envelope"
          onClick={(e) => {
            handleClick(e, notifyClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {editClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="write"
          onClick={(e) => {
            handleClick(e, editClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {configureClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="configure"
          onClick={(e) => {
            handleClick(e, configureClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {downloadClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="download"
          onClick={(e) => {
            handleClick(e, downloadClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {saveClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="save"
          onClick={(e) => {
            handleClick(e, saveClicked);
          }}
          className="save-button"
          disabled={disabled}
          loading={loading}
        />
      )}
      {viewClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="eye"
          onClick={(e) => {
            handleClick(e, viewClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {hideClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="eye slash"
          onClick={(e) => {
            handleClick(e, hideClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {copyClicked && toShow.copy && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="copy"
          onClick={(e) => {
            handleClick(e, copyClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {pasteClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="paste"
          onClick={(e) => {
            handleClick(e, pasteClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {launchGovernanceProgrammeClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="rocket"
          onClick={(e) => {
            handleClick(e, launchGovernanceProgrammeClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {addClicked && toShow.add && (
        <Button
          basic
          color={color}
          icon="add"
          onClick={(e) => {
            handleClick(e, addClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {minusClicked && toShow.minus && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="minus"
          onClick={(e) => {
            handleClick(e, minusClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {removeClicked && toShow.remove && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="remove"
          onClick={(e) => {
            handleClick(e, removeClicked);
          }}
          disabled={disabled}
          loading={loading}
          data={data}
        />
      )}
      {addUser && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="user plus"
          onClick={(e) => {
            handleClick(e, addUser);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {resetPassword && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="key"
          onClick={(e) => {
            handleClick(e, resetPassword);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
      {infoClicked && (
        <Button
          inverted={inverted}
          basic
          color={color}
          icon="info"
          onClick={(e) => {
            handleClick(e, infoClicked);
          }}
          disabled={disabled}
          loading={loading}
        />
      )}
    </Button.Group>
  );
};

ActionButtons.propTypes = {
  notifyClicked: PropTypes.func,
  refreshClicked: PropTypes.func,
  editClicked: PropTypes.func,
  configureClicked: PropTypes.func,
  viewClicked: PropTypes.func,
  hideClicked: PropTypes.func,
  deleteClicked: PropTypes.func,
  addClicked: PropTypes.func,
  removeClicked: PropTypes.func,
  upClicked: PropTypes.func,
  downClicked: PropTypes.func,
  leftClicked: PropTypes.func,
  rightClicked: PropTypes.func,
  copyClicked: PropTypes.func,
  pasteClicked: PropTypes.func,
  downloadClicked: PropTypes.func,
  saveClicked: PropTypes.func,
  minusClicked: PropTypes.func,
  infoClicked: PropTypes.func,
  launchGovernanceProgrammeClicked: PropTypes.func,
  addUser: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  showActions: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  resetPassword: PropTypes.func,
  disabled: PropTypes.bool,
  hideMode: PropTypes.bool,
  loading: PropTypes.bool,
  vertical: PropTypes.bool,
  compact: PropTypes.bool,
  style: PropTypes.object,
  data: PropTypes.object,
};

ActionButtons.defaultProps = {
  showActions: 'add remove delete edit down up left right copy minus',
  className: '',
};

export default ActionButtons;
