import * as _ from 'lodash';

export function createConfig(callConfig, data) {
  const newCallConfig = _.cloneDeep(callConfig);
  if (!newCallConfig.params.relationFilter.wheres)
    newCallConfig.params.relationFilter.wheres = {};

  _.forEach(data, (value, key) => {
    const k = key.split('.');
    newCallConfig.params.relationFilter.wheres[
      [...k].splice(0, k.length - 1).join('.')
    ] = {
      [_.last(k)]: value,
    };
  });

  newCallConfig.endpoint = 'filter';
  newCallConfig.params.includeData = true;
  return newCallConfig;
}

export function generatePermissionsFilter(levels) {
  return {
    include: [
      {
        relation: 'children',
        scope: levels > 0 ? generatePermissionsFilter(levels - 1) : null,
      },
      {
        relation: 'principalRoles',
        scope: {
          include: [
            {
              relation: 'roleDefinition',
              scope: { include: ['permissions'] },
            },
            'principal',
          ],
        },
      },
    ],
  };
}
