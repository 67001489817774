import React from 'react';
import { Form } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import Date from '../common/fields/Date';
import ApplicableDate from './ApplicableDate';

const LaunchNowForm = ({
  formData,
  handleFormDataChange,
  horizontal = false,
}) => {
  const { dueDate, applicableDate, applicableDateTypeId } = formData;
  if (horizontal)
    return (
      <Form.Group widths="equal">
        <React.Fragment>
          <Form.Field>
            <Date
              label="Due Date"
              value={dueDate}
              onChange={handleFormDataChange}
              name="dueDate"
              minDate={moment()}
              isClearable={true}
            />
          </Form.Field>
          <ApplicableDate
            applicableDate={applicableDate}
            applicableDateTypeId={applicableDateTypeId}
            onChange={handleFormDataChange}
          />
        </React.Fragment>
      </Form.Group>
    );

  return (
    <React.Fragment>
      <Form.Field>
        <Date
          label="Due Date"
          value={dueDate}
          onChange={handleFormDataChange}
          name="dueDate"
          minDate={moment()}
          isClearable={true}
        />
      </Form.Field>
      <ApplicableDate
        applicableDate={applicableDate}
        applicableDateTypeId={applicableDateTypeId}
        onChange={handleFormDataChange}
      />
    </React.Fragment>
  );
};

LaunchNowForm.propTypes = {
  formData: PropTypes.object,
  handleFormDataChange: PropTypes.func,
  horizontal: PropTypes.bool,
};

export default LaunchNowForm;
