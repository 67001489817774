import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Table } from 'semantic-ui-react';

import TicketLinksTableRow from './TicketLinksTableRow';

const TicketLinksTable = ({ relationships, label }) => {
  return (
    <React.Fragment>
      <Header as="h4">{label}</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Label</Table.HeaderCell>
            <Table.HeaderCell>Relationship Type</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Assigned Principal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(relationships, (relationship) => (
            <TicketLinksTableRow
              relationship={relationship}
              key={relationship.id}
            />
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

TicketLinksTable.propTypes = {
  relationships: PropTypes.array,
  label: PropTypes.string,
};

export default TicketLinksTable;
