const UIDGenerator = (function () {
  let id = 0;

  function get() {
    id++;
    return id;
  }

  return {
    get: get,
  };
})();

export default UIDGenerator;
