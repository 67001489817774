import React, { useContext, useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import {
  Dropdown,
  Form,
  Grid,
  Header,
  Label,
  List,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import * as _ from 'lodash';
import { APICallContext } from './ApiCallPage';
import DebugComponent from '../common/dashboard/components/DebugComponent';
import update from 'immutability-helper';
import hashSum from 'hash-sum';
import JSONBuilder from '../common/fields/JSONBuilder';

const CallBuilder = (props) => {
  const { onChange } = props;

  const value = useContext(APICallContext);

  const { options, models, modelArguments } = value;
  const builderOptions = _.get(options, 'builder');
  const builderOptionsHash = hashSum(builderOptions);

  const [joins, setJoins] = useState(builderOptions.joins || []);
  const [groups, setGroups] = useState(builderOptions.groups || []);
  const [wheres, setWheres] = useState(builderOptions.wheres || []);
  const localBuilderOptions = { joins, groups, wheres };
  const localBuilderOptionsHash = hashSum(localBuilderOptions);

  useEffect(() => {
    if (builderOptionsHash !== localBuilderOptionsHash) {
      const { joins = [], groups = [], wheres = [] } = builderOptions;
      // Promise.resolve().then(() => {
      setJoins(joins);
      setGroups(groups);
      setWheres(wheres);
      // });
    }
  }, [builderOptionsHash]);

  useEffect(() => {
    if (builderOptionsHash !== localBuilderOptionsHash) {
      onChange(localBuilderOptions);
    }
  }, [localBuilderOptionsHash]);

  const onRelationshipChosen = (event, data) => {
    const { name, value } = data;

    if (_.isNumber(name)) {
      addJoinPart(name, value);
    } else {
      addNewJoin(value, modelArguments.model);
    }
  };

  const addJoinPart = (index, relationshipName) => {
    const join = joins[index];
    const targetModel =
      models[_.last(join).targetModel].relationships[relationshipName].modelTo;
    const newJoinPart = {
      relationshipName,
      targetModel,
    };

    setJoins(
      update(joins, {
        [index]: {
          $push: [newJoinPart],
        },
      })
    );
  };

  const removeJoinPart = (joinIndex, partIndex) => {
    if (partIndex === 0) {
      setJoins(
        update(joins, {
          $splice: [[joinIndex, 1]],
        })
      );
    } else {
      setJoins(
        update(joins, {
          [joinIndex]: {
            $splice: [[partIndex]],
          },
        })
      );
    }
  };

  const addNewJoin = (relationshipName, fromModel) => {
    const targetModel =
      relationshipName === 'base'
        ? modelArguments.model
        : models[fromModel].relationships[relationshipName].modelTo;
    const newJoin = [
      {
        relationshipName,
        targetModel,
        joins: [],
      },
    ];

    setJoins(
      update(joins, {
        $push: [newJoin],
      })
    );
  };

  const addGroupFromJoin = (joinIndex, partIndex) => {
    const join = joins[joinIndex];
    const relationships = [...join].splice(0, partIndex + 1);
    const targetModel = _.last(relationships).targetModel;

    setGroups(
      update(groups, {
        $push: [
          {
            relationships,
            targetModel,
            property: [null],
          },
        ],
      })
    );
  };

  const addWhereFromJoin = (joinIndex, partIndex) => {
    const join = joins[joinIndex];
    const relationships = [...join].splice(0, partIndex + 1);
    const targetModel = _.last(relationships).targetModel;

    setWheres(
      update(wheres, {
        $push: [
          {
            relationships,
            targetModel,
            properties: [
              {
                property: null,
                value: null,
              },
            ],
          },
        ],
      })
    );
  };

  const getRelations = (model) => {
    const { models } = value;

    const base = [
      {
        key: 'base',
        text: 'base',
        value: 'base',
      },
    ];

    if (!models[model]) return [];
    else
      return [
        ...base,
        ..._.map(models[model].relationships, (relationship, name) => {
          return {
            key: name,
            text: name,
            value: name,
          };
        }),
      ];
  };
  const removeGroup = (index) => {
    setGroups(
      update(groups, {
        $splice: [[index, 1]],
      })
    );
  };

  const changeGroupItem = (groupIndex, name, value) => {
    setGroups(
      update(groups, {
        [groupIndex]: {
          [name]: {
            $set: value,
          },
        },
      })
    );
  };

  const onGroupPropertyChange = (event, { name, value }, index) => {
    changeGroupItem(name, 'property', value, index);

    setGroups(
      update(groups, {
        [name]: {
          property: {
            [index]: {
              $set: value,
            },
          },
        },
      })
    );
  };
  const getProperties = (model) => {
    if (!models[model]) return [];
    else
      return _.map(models[model].properties, (relationship, name) => {
        return {
          key: name,
          text: name,
          value: name,
        };
      });
  };
  const removeGroupProperty = (groupIndex, index) => {
    setGroups(
      update(groups, {
        [groupIndex]: {
          property: {
            $splice: [[index, 1]],
          },
        },
      })
    );
  };
  const addGroupProperty = (index) => {
    setGroups(
      update(groups, {
        [index]: {
          property: {
            $push: [null],
          },
        },
      })
    );
  };
  const removeWhere = (index) => {
    setWheres(
      update(wheres, {
        $splice: [[index, 1]],
      })
    );
  };
  const changeWhereItem = (whereIndex, index, name, value) => {
    setWheres(
      update(wheres, {
        [whereIndex]: {
          properties: {
            [index]: {
              [name]: {
                $set: value,
              },
            },
          },
        },
      })
    );
  };
  const onWherePropertyChange = (whereIndex, propertyIndex, value) => {
    changeWhereItem(whereIndex, propertyIndex, 'property', value);
  };
  const onWhereValueChange = (whereIndex, propertyIndex, value) => {
    changeWhereItem(whereIndex, propertyIndex, 'value', value);
  };
  const removeWhereProperty = (whereIndex, index) => {
    setWheres(
      update(wheres, {
        [whereIndex]: {
          properties: {
            $splice: [[index, 1]],
          },
        },
      })
    );
  };
  const addWhereProperty = (index) => {
    setWheres(
      update(wheres, {
        [index]: {
          properties: {
            $push: [
              {
                property: null,
                value: null,
              },
            ],
          },
        },
      })
    );
  };

  return (
    <Grid columns={3} divided>
      <DebugComponent value={value} builderOptions={builderOptions} />

      <Grid.Row>
        <Grid.Column>
          <Header>Joins</Header>

          <Form>
            <Form.Field>
              <label>Add new Join</label>
              <Dropdown
                selectOnBlur={false}
                fluid
                selection
                onChange={onRelationshipChosen}
                options={getRelations(modelArguments.model)}
                value={null}
              />
            </Form.Field>
          </Form>

          {joins.map((join, index) => (
            <Segment key={index}>
              <List>
                {join.map((joinPart, joinPartIndex) => (
                  <List.Item key={joinPartIndex}>
                    <Label
                      as="a"
                      onClick={() => addGroupFromJoin(index, joinPartIndex)}
                    >
                      {joinPart.relationshipName} ({joinPart.targetModel})
                    </Label>
                    <ActionButtons
                      removeClicked={() => removeJoinPart(index, joinPartIndex)}
                      addClicked={() => {
                        addWhereFromJoin(index, joinPartIndex);
                      }}
                    />
                  </List.Item>
                ))}
              </List>

              <Form>
                <Form.Field>
                  <label>Add relationship to join</label>
                  <Dropdown
                    selectOnBlur={false}
                    fluid
                    selection
                    name={index}
                    onChange={onRelationshipChosen}
                    options={getRelations(_.last(join).targetModel)}
                    value={null}
                  />
                </Form.Field>
              </Form>
            </Segment>
          ))}
        </Grid.Column>
        <Grid.Column>
          <Header>Groups</Header>

          {groups.map((group, index) => (
            <Segment key={index}>
              <Label attached="top right">
                <ActionButtons removeClicked={() => removeGroup(index)} />
              </Label>

              <List>
                {group.relationships.map((relationship, index) => (
                  <List.Item key={index}>
                    <Label>{relationship.relationshipName}</Label>
                  </List.Item>
                ))}
              </List>

              <Form>
                <Form.Field>
                  <label>Property</label>
                  {group.property.map((prop, propindex) => (
                    <React.Fragment key={propindex}>
                      <Dropdown
                        selectOnBlur={false}
                        selection
                        name={index}
                        onChange={(event, data) =>
                          onGroupPropertyChange(event, data, propindex)
                        }
                        options={getProperties(group.targetModel)}
                        value={prop}
                      />
                      <ActionButtons
                        removeClicked={() =>
                          removeGroupProperty(index, propindex)
                        }
                      />
                    </React.Fragment>
                  ))}
                </Form.Field>
                <Form.Field />
                {/*</Form.Group>*/}
              </Form>
              <ActionButtons addClicked={() => addGroupProperty(index)} />
            </Segment>
          ))}
        </Grid.Column>
        <Grid.Column>
          <Header>Wheres</Header>
          {/*<Form>*/}
          {/*<Form.Field>*/}
          {/*<label>Add Where Model</label>*/}
          {/*<Dropdown*/}
          {/*selectOnBlur={false}*/}
          {/*fluid*/}
          {/*selection*/}
          {/*onChange={onWhereModelChosen}*/}
          {/*options={availableGroupModels}*/}
          {/*value={null}*/}
          {/*/>*/}
          {/*</Form.Field>*/}
          {/*</Form>*/}

          {wheres.map((where, index) => (
            <Segment key={index}>
              <Label attached="top right">
                <ActionButtons removeClicked={() => removeWhere(index)} />
              </Label>

              <List>
                {where.relationships.map((relationship, index) => (
                  <List.Item key={index}>
                    <Label>{relationship.relationshipName}</Label>
                  </List.Item>
                ))}
              </List>

              {where.properties.map((property, propindex) => (
                <Form key={propindex}>
                  <Form.Field>
                    <label>Property</label>
                    <Dropdown
                      selectOnBlur={false}
                      fluid
                      selection
                      name={index}
                      onChange={(event, { value }) =>
                        onWherePropertyChange(index, propindex, value)
                      }
                      options={getProperties(where.targetModel)}
                      value={property.property}
                    />
                  </Form.Field>
                  <JSONBuilder
                    value={property.value ? JSON.parse(property.value) : ''}
                    valueDefault={null}
                    onChange={(event, { value }) =>
                      onWhereValueChange(
                        index,
                        propindex,
                        JSON.stringify(value)
                      )
                    }
                    allowModeChange={true}
                  />
                  <Form.Field
                    control={TextArea}
                    placeholder="Value"
                    name={index}
                    onChange={(event, { value }) =>
                      onWhereValueChange(index, propindex, value)
                    }
                    value={property.value || ''}
                  />
                  <ActionButtons
                    removeClicked={() => removeWhereProperty(index, propindex)}
                  />
                </Form>
              ))}

              <ActionButtons addClicked={() => addWhereProperty(index)} />
            </Segment>
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

CallBuilder.propTypes = {
  onChange: PropTypes.func,
};

export default CallBuilder;
