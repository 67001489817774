import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';

import PartyWrapper from '../Party';

import PartyEntityList from './PartyEntityList';

import CheckPermission from '../../common/CheckPermission';

import * as permissionTypes from '../../../constants/permissionTypes';
import { loadEntities } from '../../../actions/entityActions';
import { useAppSelector } from '../../../actions/store';
import * as uiActions from '../../../actions/uiActions';
import { MODAL_COMPONENTS } from '../../common/ModalRoot';

const PartyEntitys = ({ partyActions, party }) => {
  const dispatch = useDispatch();
  const entities = useAppSelector((state) => state.entity.entities);

  const { structuralNodeId } = party;

  useEffect(() => {
    dispatch(loadEntities({ where: { structuralNodeId } }));
  }, []);

  const handleShowAddEntityModal = () => {
    dispatch(
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.CREATE_ENTITY_MODAL,
        modalProps: {
          partyId: party.id,
          onEntityCreated: () => {
            dispatch(loadEntities({ where: { structuralNodeId } }));
          },
        },
      })
    );
  };

  return (
    <div className="row">
      <div className="column">
        <PartyEntityList entities={entities} />
        <CheckPermission
          structuralNodeId={structuralNodeId}
          permissionType={permissionTypes.ADMIN}
        >
          <Button primary onClick={handleShowAddEntityModal}>
            Add new entity
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
};

PartyEntitys.propTypes = {
  partyActions: PropTypes.object,
  party: PropTypes.object,
};

export default PartyWrapper(PartyEntitys);
