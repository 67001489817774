import _ from 'lodash';
import update from 'immutability-helper/index';

import { checkifEdited } from '../../utils/React';

export function updateFieldEntry(execution, activityField, value, edited) {
  let newExecution = {};
  const index = _.findIndex(execution.entries, {
    programmeFieldId: activityField.id,
  });
  if (index !== -1) {
    newExecution = update(execution, {
      entries: {
        [index]: {
          value: { $set: value },
          edited: { $set: edited },
          field: { $set: activityField },
        },
      },
    });
  } else {
    newExecution = update(execution, {
      entries: {
        $push: [
          {
            value: value,
            edited: edited,
            programmeFieldId: activityField.id,
            fieldKey: activityField.fieldKey,
            field: activityField,
          },
        ],
      },
    });
  }
  return checkifEdited(newExecution, execution, 'edited', 'entries');
}
