import React from 'react';
import * as PropTypes from 'prop-types';
import { Table, Icon, Label, Item } from 'semantic-ui-react';

import { getActiveFilters } from '../../utils/PaginationFilters';

const SortableHeaderCell = (props) => {
  const {
    sortedBy,
    direction,
    handleSortChange,
    name,
    handleAddFilter,
    children,
    removeFilterValues,
    currentFilters,
    isSearchable = false,
    isFilterable = true,
    hiddenColumns,
  } = props;

  const columnName = children.toString();

  const activeFilters = currentFilters
    ? getActiveFilters(currentFilters, name)
    : 0;

  const calculateRightPadding = () => {
    if (activeFilters) return 55;
    if (handleAddFilter) return 30;
  };

  const renderFilter = () => {
    return activeFilters ? (
      <Label className="header-filter-icon">
        <Icon
          onClick={() =>
            handleAddFilter(name, columnName, isSearchable, isFilterable)
          }
          name="filter"
          floated="right"
        />
        {activeFilters}
        <Icon name="delete" onClick={() => removeFilterValues(name)} />
      </Label>
    ) : (
      <Icon
        onClick={() =>
          handleAddFilter(name, columnName, isSearchable, isFilterable)
        }
        name="filter"
        className="header-filter-icon"
      />
    );
  };

  if (hiddenColumns && hiddenColumns.includes(name)) {
    return null;
  }

  return (
    <Table.HeaderCell
      singleLine
      sorted={sortedBy === name ? direction : null}
      style={{ position: 'relative', paddingRight: calculateRightPadding() }}
    >
      <span className="table-header">
        <span onClick={() => handleSortChange(name)}>{children}</span>
        {handleAddFilter && renderFilter()}
      </span>
    </Table.HeaderCell>
  );
};

SortableHeaderCell.propTypes = {
  sortedBy: PropTypes.string,
  name: PropTypes.string,
  direction: PropTypes.string,
  handleSortChange: PropTypes.func,
  children: PropTypes.string,
  handleAddFilter: PropTypes.func,
  currentFilters: PropTypes.array,
  removeFilterValues: PropTypes.func,
  isSearchable: PropTypes.bool,
  isFilterable: PropTypes.bool,
};

export default SortableHeaderCell;
