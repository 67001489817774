import React from 'react';
import { Item } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';

import ExecutionWrapper from './../execution/Execution';
import FilterAndPaginate from '../common/FilterAndPaginate';
import ExecutionPlanWrapper from './ExecutionPlan';
import Pagination from '../common/Pagination';
import ScheduledLaunchesList from './ScheduledLaunchesList';

const PaginatedScheduledLaunchesList = (props) => {
  const { schedules } = props.executionPlan;
  const {
    paginationOptions,
    paginatedItems,
    handleSortChange,
    handlePaginationChange,
    handleAddFilter,
    currentFilters,
    removeFilterValues,
  } = props;
  return (
    <React.Fragment>
      {schedules && schedules.length > 0 ? (
        <React.Fragment>
          <ScheduledLaunchesList
            schedules={paginatedItems.length > 0 ? paginatedItems : []}
            paginationOptions={paginationOptions}
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            removeFilterValues={removeFilterValues}
          />
          <Pagination
            activePage={paginationOptions.activePage}
            onPageChange={handlePaginationChange}
            totalPages={paginationOptions.totalPages}
            style={{ float: 'right' }}
          />
        </React.Fragment>
      ) : (
        <Item>
          <Item.Content>
            <Item.Header as="h4">
              THERE ARE NO LAUNCHES SCHEDULED FOR THIS PLAN
            </Item.Header>
            <Item.Meta />
            <Item.Extra>
              Click the "Launch the Programme" button to get started.
            </Item.Extra>
          </Item.Content>
        </Item>
      )}
    </React.Fragment>
  );
};

PaginatedScheduledLaunchesList.propTypes = {
  executionPlanStateActions: PropTypes.object,
  match: PropTypes.object,
  executionPlan: PropTypes.object,
  paginationOptions: PropTypes.object,
  paginatedItems: PropTypes.array,
  handleSortChange: PropTypes.func,
  handlePaginationChange: PropTypes.func,
  handleAddFilter: PropTypes.func,
  currentFilters: PropTypes.array,
  removeFilterValues: PropTypes.func,
};

function mapOptions(props) {
  const { schedules } = props.executionPlan;
  return {
    items: schedules && schedules.length ? schedules : [],
  };
}

export default ExecutionPlanWrapper(
  ExecutionWrapper(
    FilterAndPaginate(mapOptions)(PaginatedScheduledLaunchesList)
  )
);
