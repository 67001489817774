/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';
import * as _ from 'lodash';
import Dropzone from 'react-dropzone';
import * as PropTypes from 'prop-types';

import commonApi from '../../../api/commonApi';
import { bytesInMegaByte } from '../../../constants/config';

const AttachmentDropContext = React.createContext();

function AttachmentDrop(
  mapFileDropOptions = null,
  uploadSuccessCallback = null
) {
  return (ComponentToWrap) => {
    class AttachmentDrop extends Component {
      static getDerivedStateFromProps(nextProps) {
        if (mapFileDropOptions) {
          const options = mapFileDropOptions(nextProps);
          return { ...options };
        }
        return null;
      }

      constructor() {
        super();
        this.fileDropRef = React.createRef();
        this.state = {
          accept: '',
          files: [],
          queue: [],
          dropzoneActive: false,
          uploading: false,
          modelType: null,
          model: null,
          options: {
            allowDrop: true,
          },
          savedFiles: [],
        };
        this.files = [];
        this.updateFilesStateThrottle = _.throttle(
          () => this.updateFilesState(),
          100
        );
      }

      onDragEnter() {
        if (this.state.uploading) return;
        this.setState({
          dropzoneActive: true,
        });
      }

      onDragLeave() {
        this.setState({
          dropzoneActive: false,
        });
      }

      mapFilesToState = (files) => {
        return files.map((file) => {
          return {
            progress: file.progress || 0,
            uploading: file.uploading || false,
            uploaded: file.uploaded || false,
            errorMessage: file.errorMessage || '',
            name: file.name,
          };
        });
      };

      updateFilesState = () => {
        this.setState({
          files: this.mapFilesToState(this.files),
        });
      };

      getSuccessfullySavedData = (values) => {
        const successfullyUploaded = _.filter(values, (value) => value != null);
        return [].concat(...successfullyUploaded.map((value) => value.data));
      };

      onDrop(files) {
        const { maxFileSize, currentUser } = this.props;
        const maxFileSizeMb = (maxFileSize / bytesInMegaByte).toFixed(1);
        const { model, modelType, uploading } = this.state;
        if (uploading) return;
        if (model) {
          this.files = [...this.files, ...files];
          if (this.files.length > 0) {
            this.setState({
              files: this.mapFilesToState(this.files),
              uploading: true,
              dropzoneActive: false,
            });
            const promises = _.map(this.files, (file) => {
              file.uploading = true;
              if (file.size < maxFileSize) {
                return commonApi
                  .uploadAttachment(
                    modelType,
                    model.id,
                    file,
                    (progressEvent) => {
                      let percentCompleted = Math.floor(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      file.progress = percentCompleted;
                      this.updateFilesStateThrottle();
                    }
                  )
                  .then((result) => {
                    this.updateFilesStateThrottle();
                    file.uploading = false;
                    file.uploaded = true;
                    return result;
                  })
                  .catch(({ message }) => {
                    file.uploading = false;
                    file.errorMessage = message;
                    return Promise.resolve();
                  });
              }
              file.uploading = false;
              file.errorMessage = `Maximum file size is ${maxFileSizeMb} MB`;
              return Promise.resolve();
            });

            Promise.all(promises).then((values) => {
              const savedFiles = this.getSuccessfullySavedData(values);
              if (uploadSuccessCallback) {
                this.setState({ savedFiles }, () => {
                  uploadSuccessCallback(model, savedFiles, currentUser);
                });
              }
              this.updateFilesStateThrottle();
              this.updateFilesState();
              const stillUploading = !!_.find(this.files, { uploading: true });
              const delay =
                this.files.length === 1 && _.first(this.files).errorMessage
                  ? 3000
                  : 1000;
              if (!stillUploading) {
                _.delay(() => {
                  this.updateFilesState();
                }, delay);
              }
            });
          }
        }
      }

      allowDrop = () => {
        const { options } = this.state;
        this.setState({
          options: { ...options, allowDrop: true },
        });
      };

      openFileDialog = () => {
        const { current } = this.fileDropRef;
        current ? current.open() : null;
      };

      closeUploadList = () => {
        this.files = [];
        this.setState({
          dropzoneActive: false,
          uploading: false,
        });
        const { allowDrop } = this.state.options;
        !allowDrop ? this.allowDrop() : null;
      };

      renderAttachmentDropContextContextProvider = () => {
        const { files, uploading, dropzoneActive, savedFiles } = this.state;
        const value = {
          openFileDialog: this.openFileDialog,
          files,
          uploading,
          dropzoneActive,
          savedFiles,
        };
        return (
          <AttachmentDropContext.Provider value={value}>
            <ComponentToWrap
              openFileDialog={this.openFileDialog}
              closeUploadList={this.closeUploadList}
              {...this.props}
              {...value}
            />
          </AttachmentDropContext.Provider>
        );
      };

      onFileDialogCancel = () => {
        this.allowDrop();
      };

      render() {
        const { accept, options } = this.state;
        return (
          <Dropzone
            disableClick
            style={{ position: 'relative' }}
            accept={accept}
            onDrop={this.onDrop.bind(this)}
            onDragEnter={this.onDragEnter.bind(this)}
            onDragLeave={this.onDragLeave.bind(this)}
            onFileDialogCancel={this.onFileDialogCancel}
            ref={this.fileDropRef}
            disabled={!options.allowDrop}
            className="dropzone"
          >
            {this.renderAttachmentDropContextContextProvider()}
          </Dropzone>
        );
      }
    }

    AttachmentDrop.propTypes = {
      maxFileSize: PropTypes.number,
      currentUser: PropTypes.object,
    };

    return AttachmentDrop;
  };
}

export default AttachmentDrop;

export const WrapAttachmentDropContext = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <AttachmentDropContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </AttachmentDropContext.Consumer>
      );
    }
  };
