import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Label, Table } from 'semantic-ui-react';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import FormField from '../common/FormField';
import { FieldAuthor } from '../common/FormFieldAuthor';
import EntityLabel from '../common/labels/EntityLabel';
import PartyLabel from '../common/labels/PartyLabel';
import { ExecutionTicketEntry } from '../../types';
type EntriesTableProps = {
  entries: {
    entry: ExecutionTicketEntry;
    isException?: boolean;
    isTargetField?: boolean;
  }[];
};

export const EntriesTable = ({ entries }: EntriesTableProps) => {
  const [showAllEntries, setShowAllEntries] = useState(true);

  const filteredEntries = showAllEntries
    ? entries
    : entries.filter((entry) => entry.isTargetField);

  return (
    <Table celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Field</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Programme</Table.HeaderCell>
          <Table.HeaderCell>Applicable Date</Table.HeaderCell>
          <Table.HeaderCell>Entity</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredEntries.map(({ entry, isException }) => (
          <Table.Row key={entry.id} negative={isException}>
            <Table.Cell>
              <Link to={`/ticket/${entry.executionTicketId}`}>
                {entry.label}
              </Link>
              {entry.fieldKey && (
                <div>
                  <Label size="tiny">{entry.fieldKey}</Label>
                </div>
              )}
            </Table.Cell>
            <Table.Cell>
              <FormField
                field={{
                  type: entry.activityFieldTypeId,
                  args: {
                    outputArray: true,
                  },
                }}
                value={entry.value}
                editable={false}
              />
              <FieldAuthor
                user={{
                  firstName: entry.firstName,
                  lastName: entry.lastName,
                }}
                modifiedDate={entry.createdAt}
                dateFormat="DD/MM/YYYY"
              />
            </Table.Cell>
            <Table.Cell>
              {entry.executionLabel}
              <div>
                <PartyLabel partyId={entry.partyId} />
              </div>
            </Table.Cell>
            <Table.Cell>
              {entry.applicableDate && (
                <ApplicableDateLabel
                  applicableDateTypeId={entry.applicableDateTypeId}
                  applicableDate={entry.applicableDate}
                />
              )}
            </Table.Cell>
            <Table.Cell>
              <EntityLabel
                entity={{
                  id: entry.entityId || entry.executionEntityId,
                  label: entry.entityLabel || entry.executionEntityLabel,
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
