import React from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import TicketRow from './TicketRow/TicketRow';
import TicketSetActions from './TicketSetActions';
import RelationshipLabel from '../common/labels/RelationshipLabel';

const TicketSet = ({
  currentExecution,
  ticketSet,
  showHeader = true,
  ticketComponent,
}) => {
  let count = 0;
  const TicketComponent = ticketComponent || TicketRow;
  const sortedTickets = _.orderBy(ticketSet.tickets, 'sort');

  return (
    <React.Fragment>
      {showHeader && (
        <React.Fragment>
          <div className="execution-ticket-set-header">
            <TicketSetActions
              label={ticketSet.label}
              ticketSet={ticketSet}
              currentExecution={currentExecution}
            />

            {ticketSet.assignedPartyRelationship && (
              <RelationshipLabel
                relationship={ticketSet.assignedPartyRelationship}
                linkToSidebar={true}
              />
            )}
          </div>
        </React.Fragment>
      )}
      <div className="ticket-set">
        {sortedTickets.map((item) => {
          count++;
          return (
            <TicketComponent
              key={item.id}
              item={item}
              stripe={count % 2 === 0}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

TicketSet.propTypes = {
  ticketSet: PropTypes.object.isRequired,
  currentExecution: PropTypes.object,
  onTicketClick: PropTypes.func,
  showHeader: PropTypes.bool,
  selectedTicketId: PropTypes.number,
  ticketComponent: PropTypes.func,
};

export default TicketSet;
