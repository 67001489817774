import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import {
  Container,
  Dimmer,
  Loader,
  Header,
  List,
  Segment,
} from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Tags from '../common/Tags';
import ActivityDetails from './ActivityDetails';
import ActivityFields from './ActivityFields';
import ActivityActionButtons from './ActivityActionButtons';
import NotFoundPage from '../NotFoundPage';
import * as activityActions from '../../actions/activityActions';
import * as uiActions from '../../actions/uiActions';
import ErrorBoundary from './../common/ErrorBoundary';
import { MODAL_COMPONENTS } from '../common/ModalRoot';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';

class ActivityPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      error: false,
      currentValues: [1, 2],
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    this.props.actions
      .loadActivity(this.props.activtyId, 'CURRENT')
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ loading: false, error: error });
      });
  }

  mapDropDownOptions() {
    return this.props.tags.map((tag) => {
      return {
        key: tag.id,
        text: tag.label,
        value: tag.id,
      };
    });
  }

  combineTags = () => {
    const { activity } = this.props;
    return activity.primaryTag
      ? [activity.primaryTag, ...activity.tags]
      : activity.tags;
  };

  handleChange = (e, { value }) => this.setState({ currentValues: value });

  editActivity = (activity) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {
        activityId: activity.id,
      },
    });
  };
  cloneActivity = (activity) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {
        activityId: activity.id,
        cloneActivity: true,
      },
    });
  };

  handleMenuAction = (event, { name }) => {
    const { activity } = this.props;
    switch (name) {
      case 'edit':
        this.editActivity(activity);
        break;
      case 'clone':
        this.cloneActivity(activity);
        break;
    }
  };

  render() {
    const { activity } = this.props;
    const { loading, error } = this.state;

    if (loading || !activity) {
      return (
        <Dimmer active={loading} inverted>
          <Loader disabled={!loading} />
        </Dimmer>
      );
    }
    return (
      <Container>
        {error && <NotFoundPage />}
        {!loading && activity.id && (
          <React.Fragment>
            <Segment attached="bottom">
              <Header as="h2">{activity.label}</Header>
              <ActivityDetails {...this.props} />
              <Header as="h3">Tags</Header>
              <Tags tags={this.combineTags() || []} />
              <Header as="h3">Used in Programmes</Header>
              <List relaxed>
                {activity.activitySets.map((as, index) => (
                  <List.Item key={index}>
                    <Link to={`/programme/${as.programme.id}`}>
                      {as.programme.label}
                    </Link>
                  </List.Item>
                ))}
              </List>
              <Header as="h3">Fields</Header>
              <ActivityFields {...this.props} />
            </Segment>
            <CheckPermission
              structuralNodeId={activity.structuralNodeId}
              permissionType={permissionTypes.ADMIN}
            >
              <ActivityActionButtons handleMenuAction={this.handleMenuAction} />
            </CheckPermission>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

ActivityPage.propTypes = {
  activtyId: PropTypes.string.isRequired,
  activity: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  uiActions: PropTypes.object.isRequired,
  activityFieldTypes: PropTypes.array.isRequired,
  activityResolutionTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const activtyId = ownProps.match.params.id;

  return {
    activtyId,
    activity: state.activity.byId[activtyId],
    tags: state.tags,
    activityFieldTypes: state.constants.activityFieldTypes,
    activityResolutionTypes: state.constants.activityResolutionTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default ErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ActivityPage)
);
