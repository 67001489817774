import React from 'react';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import TicketSet from '../ticket/TicketSet';
import ExecutionTicketRow from './ExecutionTicketRow';
import * as PropTypes from 'prop-types';

const ExecutionTicketSet = (props) => {
  const { principalFilter, ticketSet } = props;

  let ticketSetFiltered;

  if (principalFilter) {
    ticketSetFiltered = {
      ...ticketSet,
      tickets: ticketSet.tickets.filter(
        (ticket) => ticket.assignedPrincipalId === principalFilter.id
      ),
    };
    if (ticketSetFiltered.tickets.length === 0) return null;
  }

  return (
    <TicketSet
      ticketComponent={ExecutionTicketRow}
      {...props}
      ticketSet={ticketSetFiltered || ticketSet}
    />
  );
};

ExecutionTicketSet.propTypes = {
  principalFilter: PropTypes.object,
  ticketSet: PropTypes.object,
};

export default ExecutionContextConsumer(ExecutionTicketSet);
