import { Container, Header } from 'semantic-ui-react';
import { ThresholdMatrix } from './ThresholdMatrix';

const ThresholdsPage = () => {
  return (
    <Container>
      <Header as="h1">Thresholds</Header>
      <ThresholdMatrix />
    </Container>
  );
};

export default ThresholdsPage;
