/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import hashSum from 'hash-sum';
import update from 'immutability-helper/index';
import { Header } from 'semantic-ui-react';

import * as dashboardActions from '../../actions/dashboardActions';
import * as listFiltersActions from '../../actions/listFiltersActions';

import chartApi from '../../api/chartApi';
import dashboardApi from '../../api/dashboardApi';
import {
  setQueryStringValue,
  getQueryStringValue,
  getStringifiedValue,
} from '../../utils/QueryString';

import Dashboard from './Dashboard';
import AddDashboardItemModal from './AddDashboardItemModal';
import DashboardItemOptionsModal from './DashboardItemOptionsModal';
import DashboardExportButtons from './DashboardExportButtons/DashboardExportButtons';

class DashboardContainer extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  getInitialDashboard = () => {
    const {
      stateFilters,
      history,
      enableQueryString,
      initialProperties = {},
      layoutSchema,
    } = this.props;
    if (!enableQueryString) return initialProperties || {};
    const stringValue = getQueryStringValue();
    const { pathname } = history.location;
    const stateFiltersPath = stateFilters[pathname];
    const stateFiltersValue = stateFiltersPath ? stateFiltersPath.filters : {};
    return _.isEmpty(stringValue) ? stateFiltersValue : stringValue || {};
  };

  state = {
    charts: [],
    addItemModalOpen: false,
    loadingExport: false,
    dashboardWhereFilters: {},
    dashboardProperties: this.getInitialDashboard(),
    dataFromWidgets: {},
    selectedItems: [],
  };

  componentDidMount() {
    const { layoutSchema, enableQueryString } = this.props;
    this.fetchCharts();
    const initialDashboard = this.getInitialDashboard();
    this.fetchDashboardSchema();
    if (layoutSchema || !enableQueryString) return;
    const { history, setFilters } = this.props;
    const { pathname } = history.location;

    setQueryStringValue(initialDashboard);
    setFilters(pathname, initialDashboard);
  }

  componentDidUpdate() {
    const {
      layoutSchema,
      initialProperties = {},
      dashboardProperiesCanBeUpdated,
      enableQueryString,
      locationSearch,
      setFilters,
      history,
    } = this.props;
    const { dashboardProperties } = this.state;
    const shouldUpdateState =
      dashboardProperiesCanBeUpdated &&
      !_.isEqual(initialProperties, dashboardProperties);

    if (shouldUpdateState) {
      this.setState({ dashboardProperties: initialProperties }, () => {
        if (enableQueryString) {
          setQueryStringValue(dashboardProperties);
        }
      });
    }
    if (enableQueryString) {
      const { pathname } = history.location;

      const initial = this.getInitialDashboard();
      if (!_.isEqual(dashboardProperties, initial)) {
        this.setState({ dashboardProperties: initial }, () => {
          setFilters(pathname, initial);
          setQueryStringValue(initial);
        });
      }

      const stringifiedDashboardValue = getStringifiedValue(
        dashboardProperties
      );

      if (locationSearch !== stringifiedDashboardValue) {
        setQueryStringValue(initial);
      }
    }
    if (layoutSchema) return;
    this.fetchDashboardSchema();
  }

  fetchDashboardSchema = () => {
    const { fetchDashboardSchema, id } = this.props;
    if (this.state.fetchingSchema) return;
    this.setState({
      fetchingSchema: true,
    });
    fetchDashboardSchema(id)
      .then((schema) => {
        this.setState({
          fetchingSchema: false,
        });
      })
      .catch(() => {
        this.setState({
          fetchingSchema: false,
        });
      });
  };

  fetchCharts = () => {
    if (!this.props.editable) return;
    chartApi.getCharts().then((charts) => {
      this.setState({ charts: _.orderBy(charts, 'label') });
    });
  };

  changeStateValue = (event, { name, value }) => {
    this.setState({
      [name]: value,
    });
    if (name === 'selectedChartId') {
      this.setState({
        selectedChartComponent: null,
      });
    }
    if (name === 'selectedChartComponent') {
      this.setState({
        selectedChartId: null,
      });
    }
  };

  openAddItemModal = ({ columnId, rowId }) => {
    this.setState({
      addItemModalOpen: true,
      selectedColumnId: columnId,
      selectedRowId: rowId,
    });
  };

  closeDashboardItemModal = () => {
    this.setState({
      addItemModalOpen: false,
    });
  };

  openConfigModal = ({ args, options, type, extraFields }) => {
    this.setState({
      configModalOpen: true,
      selectedItemArgs: args,
      selectedExtraFields: extraFields,
      selectedItemOptions: options,
      selectedItemType: type,
    });
  };

  closeConfigModal = () => {
    this.setState({
      configModalOpen: false,
    });
  };

  updateItemOptions = (options) => {
    if (this.state.selectedItems.length > 0) {
      this.updateMultipleItemOptions(options);
      return;
    }
    const { setElementOptions, id } = this.props;
    const { selectedItemArgs } = this.state;
    setElementOptions({ id, options, ...selectedItemArgs });
    this.closeConfigModal();
  };

  saveDashboardItem = () => {
    this.closeDashboardItemModal();
    const { id, addDashboardItem } = this.props;
    const {
      selectedRowId,
      selectedColumnId,
      selectedChartId,
      selectedChartComponent,
      charts,
    } = this.state;
    const chart = _.find(charts, { id: selectedChartId });
    let options = {};
    if (selectedChartComponent) {
      options = {
        component: selectedChartComponent,
      };
    }
    addDashboardItem({
      id,
      columnId: selectedColumnId,
      chart,
      rowId: selectedRowId,
      options,
    });
  };

  pasteDashboardItem = ({ item, rowId, columnId }) => {
    console.log('pasteDashboardItem', item, rowId, columnId);
    const { id, addDashboardItem } = this.props;

    addDashboardItem({
      id,
      columnId: columnId,
      chart: item.chart,
      sort: item.sort,
      rowId: rowId,
      options: item.options,
    });
  };

  updateItem = ({ item, arg, value, rowId, columnId, save = false }) => {
    console.log(item, arg, value);

    const { setElementOptions, saveDashboardItemOptions, id } = this.props;

    const newOptions = {
      ...item.options,
      [arg]: value,
    };

    setElementOptions({
      id,
      options: newOptions,
      rowId,
      columnId,
      itemId: item.id,
      type: 'item',
    });

    if (!save) return;
    saveDashboardItemOptions({
      id,
      options: newOptions,
      rowId,
      columnId,
      itemId: item.id,
      type: 'item',
    });
  };

  addDashboardComponent = ({ itemType, rowId, columnId, sort }) => {
    const { id, addDashboardRow, addDashboardColumn } = this.props;
    switch (itemType) {
      case 'ROW': {
        addDashboardRow({ id, options: sort ? { sort } : {} });
        break;
      }
      case 'COLUMN': {
        addDashboardColumn({ id, rowId, sort });
        break;
      }
      case 'ITEM': {
        this.openAddItemModal({ columnId, rowId, sort });
        break;
      }
    }
  };

  removeDashboardComponent = ({ itemType, rowId, columnId, itemId }) => {
    const {
      id,
      removeDashboardRow,
      removeDashboardColumn,
      removeDashboardItem,
    } = this.props;
    switch (itemType) {
      case 'ROW': {
        removeDashboardRow({ id, rowId });
        break;
      }
      case 'COLUMN': {
        removeDashboardColumn({ id, rowId, columnId });
        break;
      }
      case 'ITEM': {
        removeDashboardItem({ id, rowId, columnId, itemId });
        break;
      }
    }
  };

  dashboardAction = ({
    actionType,
    rowId,
    columnId,
    itemId,
    data,
    type,
    args,
    extraFields,
  }) => {
    const {
      id,
      setColumnOption,
      saveDashboardColumnOptions,
      saveDashboardRowOptions,
      saveDashboardItemOptions,
      saveDashboardOptions,
    } = this.props;

    switch (actionType) {
      case 'SET_COLUMN_OPTION': {
        setColumnOption({ id, rowId, columnId, data });
        break;
      }
      case 'SAVE_DASHBOARD_OPTIONS': {
        saveDashboardOptions({ id, options: data });
        break;
      }
      case 'SAVE_COLUMN_OPTIONS': {
        saveDashboardColumnOptions({ id, rowId, columnId, options: data });
        break;
      }
      case 'SAVE_ROW_OPTIONS': {
        saveDashboardRowOptions({ id, rowId, options: data });
        break;
      }
      case 'SAVE_ITEM_OPTIONS': {
        saveDashboardItemOptions({
          id,
          rowId,
          columnId,
          itemId,
          options: data,
        });
        break;
      }
      case 'OPEN_ITEM_OPTIONS': {
        this.openConfigModal({ args, options: data, type, extraFields });
        break;
      }
    }
  };

  setDashboadData = (args) => {
    const { dataFromWidgets } = this.state;

    const { toSet, data } = args;

    if (hashSum(dataFromWidgets[toSet]) === hashSum(data)) return;

    this.setState(
      update(this.state, {
        dataFromWidgets: {
          [toSet]: {
            $set: data,
          },
        },
      })
    );
  };

  dashboardCallback = (args) => {
    const { onPropertyChange, enableQueryString } = this.props;
    const { property, value } = args;
    let newStateValue;
    if (_.isNull(value)) {
      newStateValue = update(this.state, {
        dashboardProperties: {
          $unset: [property],
        },
      });
    } else {
      console.log(this.state);
      newStateValue = update(this.state, {
        dashboardProperties: {
          [property]: {
            $set: value,
          },
        },
      });
    }
    this.setState(newStateValue);
    const { dashboardProperties } = newStateValue;
    if (enableQueryString) {
      const { history, setFilters } = this.props;
      const { pathname } = history.location;
      setQueryStringValue(dashboardProperties);
      setFilters(pathname, dashboardProperties);
    }
    if (onPropertyChange) onPropertyChange(dashboardProperties);
  };

  handleExport = (event, { name }) => {
    const { dashboardProperties } = this.state;

    const { id, initialProperties, layoutSchema } = this.props;
    this.setState({ loadingExport: true });
    dashboardApi
      .openDashboardExport(
        id,
        initialProperties,
        name,
        layoutSchema,
        layoutSchema.options.customFileName || layoutSchema.label,
        dashboardProperties
      )
      .finally(() => {
        this.setState({ loadingExport: false });
      });
  };

  // deleteDashboardComponent = ({ itemType, itemId }) => {
  //   const { id } = this.props;
  //   switch (itemType) {
  //     case 'ROW': {
  //       this.props.addDashboardRow({ id });
  //       break;
  //     }
  //     case 'COLUMN': {
  //       this.props.addDashboardColumn({ id, rowId: parentId });
  //       break;
  //     }
  //   }
  // };

  selectEditItem = (item) => {
    // this.setState({
    console.log(item);
    this.setState({
      selectedItems: [...this.state.selectedItems, item],
    });
  };

  clearSelectedItems = () => {
    this.setState({
      selectedItems: [],
    });
  };

  openEditMultipleItemsModel = () => {
    const { sharedExtraFields, sharedOptions } = this.getSharedAttributes(
      this.state.selectedItems
    );

    this.openConfigModal({
      args: {
        type: 'item',
      },
      options: sharedOptions,
      type: 'item',
      extraFields: sharedExtraFields,
    });
  };

  updateMultipleItemOptions = (options) => {
    const { id, setElementOptions, saveDashboardItemOptions } = this.props;
    const { selectedItemArgs } = this.state;

    for (const itemInfo of this.state.selectedItems) {
      const { item, args } = itemInfo;

      setElementOptions({
        id: id,
        options: { ...item.options, ...options },
        ...args,
      });

      saveDashboardItemOptions({
        id: this.props.id,
        options: { ...item.options, ...options },
        ...args,
      });
    }
    this.closeConfigModal();
    this.setState({ selectedItems: [] });
  };

  getSharedAttributes(data) {
    // Initialize variables for shared extraFields and options
    let sharedExtraFields = [];
    let sharedOptions = {};

    // Check if there's any data at all
    if (data.length === 0) return { sharedExtraFields, sharedOptions };

    // Initialize sharedExtraFields with the fields from the first item
    sharedExtraFields = [...data[0].extraFields];

    // Initialize sharedOptions with the options from the first item
    sharedOptions = { ...data[0].item.options };

    // Iterate over the rest of the items
    for (let i = 1; i < data.length; i++) {
      // Check each sharedExtraField against the current item
      sharedExtraFields = sharedExtraFields.filter((sharedField) => {
        return data[i].extraFields.some(
          (field) => JSON.stringify(field) === JSON.stringify(sharedField)
        );
      });

      // Check each sharedOption against the current item
      for (let option in sharedOptions) {
        if (
          !data[i].item.options.hasOwnProperty(option) ||
          data[i].item.options[option] !== sharedOptions[option]
        ) {
          delete sharedOptions[option];
        }
      }
    }

    return { sharedExtraFields, sharedOptions };
  }

  render() {
    const {
      layoutSchema,
      editable,
      id,
      forceChartRefresh,
      extraData,
      showHeader = false,
      history,
      setFilters,
      stateFilters,
    } = this.props;
    const {
      addItemModalOpen,
      configModalOpen,
      charts,
      selectedChartId,
      selectedChartComponent,
      dashboardWhereFilters,
      selectedItemOptions,
      selectedExtraFields,
      selectedItemType,
      dataFromWidgets,
      dashboardProperties,
      loadingExport,
      selectedItems,
    } = this.state;

    const schemaDashboardProperties =
      layoutSchema?.options?.dashboardProperties || {};

    if (!layoutSchema || layoutSchema.downloadFailed) return null;

    let combinedExtraData = extraData || [];

    if (dataFromWidgets) {
      combinedExtraData = [
        ..._.map(dataFromWidgets, (d, k) => ({ key: k, data: d })),
        ...combinedExtraData,
      ];
    }

    return (
      <React.Fragment>
        {showHeader && layoutSchema.options && (
          <Header
            className={
              layoutSchema.options.hideHeaderForPrint === 'true'
                ? 'hide-for-print'
                : ''
            }
            as="h2"
          >
            {layoutSchema.label}
          </Header>
        )}
        {layoutSchema.options.formats &&
          layoutSchema.options.formats.length > 0 && (
            <DashboardExportButtons
              formats={layoutSchema.options.formats}
              onClick={this.handleExport}
              loading={loadingExport}
            />
          )}
        {layoutSchema && (
          <Dashboard
            data={layoutSchema}
            editable={editable}
            id={id}
            onAddItem={this.addDashboardComponent}
            onPasteItem={this.pasteDashboardItem}
            onRemoveItem={this.removeDashboardComponent}
            onSetDashboadData={this.setDashboadData}
            onClearSelectedItems={this.clearSelectedItems}
            onUpdateItem={this.updateItem}
            openEditMultipleItemsModel={this.openEditMultipleItemsModel}
            dashboardAction={this.dashboardAction}
            dashboardCallback={this.dashboardCallback}
            dashboardWhereFilters={dashboardWhereFilters}
            dashboardProperties={{
              ...dashboardProperties,
              ...schemaDashboardProperties,
            }}
            forceChartRefresh={forceChartRefresh}
            extraData={combinedExtraData}
            history={history}
            setFilters={setFilters}
            stateFilters={stateFilters}
            onSelectEditItem={this.selectEditItem}
            selectedItems={selectedItems}
          />
        )}
        {addItemModalOpen && (
          <AddDashboardItemModal
            open={addItemModalOpen}
            charts={charts}
            onChange={this.changeStateValue}
            selectedChartId={selectedChartId}
            selectedChartComponent={selectedChartComponent}
            closeModal={this.closeDashboardItemModal}
            onSave={this.saveDashboardItem}
          />
        )}
        {configModalOpen && (
          <DashboardItemOptionsModal
            open={configModalOpen}
            options={selectedItemOptions}
            extraFields={selectedExtraFields}
            type={selectedItemType}
            closeModal={this.closeConfigModal}
            onSave={this.updateItemOptions}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    layoutSchema: state.dashboards[props.id] || null,
    stateFilters: state.listFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardSchema: (id) =>
      dispatch(dashboardActions.fetchDashboardSchema(id)),
    addDashboardRow: (args) => dispatch(dashboardActions.addDashboardRow(args)),
    removeDashboardRow: (args) =>
      dispatch(dashboardActions.removeDashboardRow(args)),
    addDashboardColumn: (args) =>
      dispatch(dashboardActions.addDashboardColumn(args)),
    removeDashboardColumn: (args) =>
      dispatch(dashboardActions.removeDashboardColumn(args)),
    addDashboardItem: (args) =>
      dispatch(dashboardActions.addDashboardItem(args)),
    removeDashboardItem: (args) =>
      dispatch(dashboardActions.removeDashboardItem(args)),
    setColumnOption: (args) => dispatch(dashboardActions.setColumnOption(args)),
    saveDashboardColumnOptions: (args) =>
      dispatch(dashboardActions.saveDashboardColumnOptions(args)),
    saveDashboardRowOptions: (args) =>
      dispatch(dashboardActions.saveDashboardRowOptions(args)),
    saveDashboardItemOptions: (args) =>
      dispatch(dashboardActions.saveDashboardItemOptions(args)),
    saveDashboardOptions: (args) =>
      dispatch(dashboardActions.saveDashboardOptions(args)),
    setElementOptions: (args) =>
      dispatch(dashboardActions.setElementOptions(args)),
    setFilters: (rt, fltr) => dispatch(listFiltersActions.setFilters(rt, fltr)),
  };
};

DashboardContainer.propTypes = {
  fetchDashboardSchema: PropTypes.func.isRequired,
  addDashboardRow: PropTypes.func.isRequired,
  addDashboardColumn: PropTypes.func.isRequired,
  addDashboardItem: PropTypes.func.isRequired,
  removeDashboardItem: PropTypes.func.isRequired,
  removeDashboardRow: PropTypes.func.isRequired,
  removeDashboardColumn: PropTypes.func.isRequired,
  setColumnOption: PropTypes.func.isRequired,
  saveDashboardColumnOptions: PropTypes.func.isRequired,
  saveDashboardRowOptions: PropTypes.func.isRequired,
  saveDashboardItemOptions: PropTypes.func.isRequired,
  saveDashboardOptions: PropTypes.func.isRequired,
  setElementOptions: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  layoutSchema: PropTypes.object,
  initialProperties: PropTypes.object,
  editable: PropTypes.bool,
  showHeader: PropTypes.bool,
  forceChartRefresh: PropTypes.bool,
  extraData: PropTypes.array,
  history: PropTypes.object,
  onPropertyChange: PropTypes.func,
  dashboardProperiesCanBeUpdated: PropTypes.bool,
  setFilters: PropTypes.func,
  stateFilters: PropTypes.object,
  enableQueryString: PropTypes.bool,
  locationSearch: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
