import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Menu, Header } from 'semantic-ui-react';
import * as _ from 'lodash';
import { withRouter } from 'react-router';

import { pluralize } from './../../utils/Pluralize';

import './ExecutionPlanMenu.scss';

const ExecutionPlanMenu = ({
  categories,
  history,
  match: {
    params: { id, category },
  },
  numberOfQuestions,
  menuItemChanged,
}) => {
  const [isInitial, setIsInitial] = useState(true);

  const sortCategories = _.sortBy(categories, ['sort']);

  useEffect(() => {
    setIsInitial(false);
    const { id, label } = sortCategories[0];
    const labelKebabCased = _.kebabCase(label);
    if (
      (categories.length === 1 &&
        category !== `${id}` &&
        category !== 'runningProgrammes' &&
        category !== 'scheduledLaunches') ||
      !category
    ) {
      changeItemMenu(`${labelKebabCased}`, id);
    }
  }, []);

  useEffect(() => {
    if (!isInitial && !category) {
      history.push('/executionPlans');
    } else {
      setMenuItem(category);
    }
  }, [category]);

  const setMenuItem = (name) => {
    if (
      name === 'runningProgrammes' ||
      name === 'scheduledLaunches' ||
      name === 'settings'
    ) {
      changeItemMenu(name);
    } else {
      const category = _.find(
        categories,
        (category) => _.kebabCase(category.label) === name
      );
      const { id } = { ...category };
      changeItemMenu(name, id);
    }
  };

  const handleItemClick = (e, value) => {
    const { name, index } = value;
    changeItemMenu(name, index);
  };

  const changeItemMenu = (name, categoryId) => {
    if (name != category) {
      history.push(`/executionPlan/${id}/${name}`);
    }
    menuItemChanged(categoryId || name);
  };

  const getNumberOfQuestions = (numberOfQuestions, categoryId) => {
    return `${pluralize(
      'Questions set',
      _.find(numberOfQuestions, { categoryId }).numberOfQuestions,
      true
    )} selected`;
  };

  return (
    <Menu vertical fluid>
      <Menu.Item>
        <Header as="h5">Scoping</Header>
        <Menu.Menu>
          {categories.length
            ? _.map(sortCategories, (currentCategory) => {
                const { id: categoryId, label } = currentCategory;
                const labelKebabCased = _.kebabCase(label);

                return (
                  <Menu.Item
                    name={`${labelKebabCased}`}
                    key={id}
                    index={id}
                    active={category === `${labelKebabCased}`}
                    onClick={handleItemClick}
                    className="item-execution-plan-menu"
                  >
                    <span>{label}</span>
                    <p>{getNumberOfQuestions(numberOfQuestions, categoryId)}</p>
                  </Menu.Item>
                );
              })
            : null}
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Header as="h5">Scheduling</Header>
        <Menu.Menu>
          <Menu.Item
            onClick={handleItemClick}
            name="runningProgrammes"
            active={category === `runningProgrammes`}
          >
            <span>Running programmes</span>
          </Menu.Item>
          <Menu.Item
            onClick={handleItemClick}
            name="scheduledLaunches"
            active={category === `scheduledLaunches`}
          >
            <span>Scheduled launches</span>
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item
        onClick={handleItemClick}
        name="settings"
        active={category === 'settings'}
      >
        Settings
      </Menu.Item>
    </Menu>
  );
};

ExecutionPlanMenu.propTypes = {
  categories: PropTypes.array,
  menuItemChanged: PropTypes.func,
  numberOfQuestions: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(ExecutionPlanMenu);
