import { Button } from 'semantic-ui-react';

import PartyWrapper from './Party';

import PartyRelationshipList from './PartyRelationshipList';

import CheckPermission from '../common/CheckPermission';

import * as PermissionTypes from '../../constants/permissionTypes';
import { Party } from '../../types';
import EventList from '../common/events/EventList';

interface PartyActions {
  openSaveRelationshipModal: () => void;
}

interface PartyRelationshipsProps {
  partyActions: PartyActions;
  party: Party;
}

const PartyRelationships = (props: PartyRelationshipsProps) => {
  const { partyActions, party } = props;
  const { structuralNodeId } = party;

  const openCreateRelationshipModal = () => {
    partyActions.openSaveRelationshipModal();
  };

  return (
    <div className="row">
      <div className="column">
        {party.events && (
          <EventList events={{ eventsList: party.events, isFailed: false }} />
        )}
      </div>
    </div>
  );
};

export default PartyWrapper(PartyRelationships);
