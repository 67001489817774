import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import Slider, { Range } from 'rc-slider';
import * as _ from 'lodash';
import { Header, Input, Table } from 'semantic-ui-react';
import update from 'immutability-helper/index';
import { complexityLevels, severityLevels } from '../../../../constants/config';
const Handle = Slider.Handle;

class AnalyseAndImplementOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      severityLevels: props.value.severityLevels || severityLevels,
      complexityLevels: props.value.complexityLevels || complexityLevels,
      analyseDays: props.value.analyseDays || 10,
      analyseModifier: props.value.analyseModifier || [2, 4, 6, 8, 10],
      implementDays: props.value.implementDays || 50,
      implementModifier: props.value.implementModifier || [10, 20, 30, 40, 50],
    };
  }

  //onChange

  componentDidMount = () => {
    const {
      severityLevels,
      complexityLevels,
      analyseDays,
      analyseModifier,
      implementDays,
      implementModifier,
    } = this.state;

    this.props.onChange({
      severityLevels,
      complexityLevels,
      analyseDays,
      analyseModifier,
      implementDays,
      implementModifier,
    });
  };

  componentWillUpdate = (nextProps, nextState) => {
    if (!_.isEqual(nextState, this.state)) {
      const {
        severityLevels,
        complexityLevels,
        analyseDays,
        analyseModifier,
        implementDays,
        implementModifier,
      } = nextState;

      this.props.onChange({
        severityLevels,
        complexityLevels,
        analyseDays,
        analyseModifier,
        implementDays,
        implementModifier,
      });
    }
  };

  remapModifier = (max, modifier) => {
    const len = modifier.length;
    return modifier.map((val, index) => {
      return Math.round(((index + 1) / len) * max);
    });
  };

  handleChange = (event, { name, value }) => {
    this.setState(
      update(this.state, {
        [name]: {
          $set: value,
        },
      }),
      () => {
        if (name === 'analyseDays') {
          const newModifier = this.remapModifier(
            value,
            this.state.analyseModifier
          );
          this.setState(
            update(this.state, {
              analyseModifier: {
                $set: newModifier,
              },
            })
          );
        }
        if (name === 'implementDays') {
          const newModifier = this.remapModifier(
            value,
            this.state.implementModifier
          );
          this.setState(
            update(this.state, {
              implementModifier: {
                $set: newModifier,
              },
            })
          );
        }
      }
    );
  };

  handle = (labels) => {
    return (handleProps) => {
      const { value, dragging, index, ...restProps } = handleProps;
      const text = `${labels[index].label} - ${value} days`;
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={text}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };
  };

  render() {
    const {
      severityLevels,
      complexityLevels,
      analyseDays,
      analyseModifier,
      implementDays,
      implementModifier,
    } = this.state;
    return (
      <div>
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header size="small">Analysis Maximum Days</Header>
                <Slider
                  onChange={(value) =>
                    this.handleChange({}, { name: 'analyseDays', value })
                  }
                  value={analyseDays}
                  min={10}
                  max={50}
                />
              </Table.Cell>

              <Table.Cell collapsing>
                <Input
                  style={{ width: 50 }}
                  // label={{ basic: true, content: 'days' }}
                  // labelPosition="right"
                  value={analyseDays}
                  onChange={this.handleChange}
                  name="analyseDays"
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Header size="small">Severity mapping</Header>
                <Range
                  count={5}
                  value={analyseModifier}
                  pushable
                  max={analyseDays}
                  min={1}
                  handle={this.handle(severityLevels)}
                  trackStyle={[
                    { backgroundColor: 'red' },
                    { backgroundColor: 'orange' },
                    { backgroundColor: 'yellow' },
                    { backgroundColor: 'green' },
                  ]}
                  onChange={(value) =>
                    this.handleChange({}, { name: 'analyseModifier', value })
                  }
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header size="small">Implementation Maximum Days</Header>
                <Slider
                  onChange={(value) =>
                    this.handleChange({}, { name: 'implementDays', value })
                  }
                  value={implementDays}
                  min={10}
                  max={100}
                />
              </Table.Cell>

              <Table.Cell collapsing>
                <Input
                  style={{ width: 50 }}
                  // label={{ basic: true, content: 'days' }}
                  // labelPosition="right"
                  value={implementDays}
                  onChange={this.handleChange}
                  name="implementDays"
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Header size="small">Complexity mapping</Header>
                <Range
                  count={5}
                  value={implementModifier}
                  pushable
                  max={implementDays}
                  min={1}
                  handle={this.handle(complexityLevels)}
                  trackStyle={[
                    { backgroundColor: 'green' },
                    { backgroundColor: 'yellow' },
                    { backgroundColor: 'orange' },
                    { backgroundColor: 'red' },
                  ]}
                  onChange={(value) =>
                    this.handleChange({}, { name: 'implementModifier', value })
                  }
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}

AnalyseAndImplementOptions.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default AnalyseAndImplementOptions;
