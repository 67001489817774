import _ from 'lodash';

import * as permissionTypes from '../constants/permissionTypes';
import { replaceSymbolsToLowerCase } from './CommonUtils';

export function hasUserAttachFilesPermission(user, execution) {
  const isAdmin = user.checkPermissionForNode(
    execution.structuralNodeId,
    permissionTypes.ADMIN
  );
  if (isAdmin) return true;
  const { partyId } = user.details;
  const { reviewPartyId, ownerPartyId } = execution;
  if (_.has(execution, 'reviewPartyId') && _.has(execution, 'ownerPartyId')) {
    return partyId === reviewPartyId || partyId === ownerPartyId;
  }
}

export function getExecutionCategory(ticket) {
  const categoryId = ticket.executionTicketSet?.activitySet?.categoryId;
  const { categories } = ticket.execution.programme;
  const category = _.find(categories, { id: categoryId });

  return category
    ? replaceSymbolsToLowerCase(category.label, '-')
    : 'uncategorised';
}

export function getUnassignedUsers(data, filterArray) {
  if (filterArray) {
    return data.filter((item) =>
      filterArray
        .map((key) => item['key'] != key)
        .reduce((x, y) => x && y, true)
    );
  }

  return data;
}
