import React, { useState, useContext, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Button, Form, Message } from 'semantic-ui-react';

import { TicketContextConsumer } from '../../../context/TicketContext';
import FormFields from '../../common/FormFields';
import ConfirmWrapper from '../../common/ConfirmWrapper';
import usePrevious from '../../../utils/hooks/usePrevious';

import './TicketFields.scss';
import ConfirmationModalContext from '../../../context/ConfirmationModalContext';
import toastr from 'toastr';

const TicketFields = (props) => {
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { confirmPopupActions } = useContext(ConfirmationModalContext);

  useEffect(() => {
    if (prevDefaults && props.defaults) {
      if (
        prevTicket.id === props.ticket.id &&
        !_.isEqual(prevDefaults, props.defaults)
      ) {
        props.ticketStateActions.setDefaultValue(
          props.fields,
          props.defaults,
          props.entries,
          props.resolving,
          props.ticket,
          confirmPopupActions
        );
      }
    }
  }, [props.defaults]);
  useEffect(() => {
    if (!entries || !entries.length) return;
    const isEdited = _.find(entries, { edited: true });
    if (prevTicket && props.ticket) {
      if (prevTicket.id !== props.ticket.id && !isEdited) {
        setIsSaveButtonVisible(false);
      }
    }
    setIsSaveButtonVisible(!!isEdited);
  }, [props.ticket.id]);
  const prevDefaults = usePrevious(props.defaults);
  const prevTicket = usePrevious(props.ticket);

  const handleOnChange = (field, isValid) => {
    setIsSaveButtonVisible(isValid);
    const { ticketStateActions, currentTicket } = props;
    ticketStateActions.updateFieldEntry(
      currentTicket,
      { ...field },
      field.value
    );
  };

  const dataEntryAllowed = () => {
    const { enabled = true, allowDataEntry } = props;
    return enabled && allowDataEntry;
  };

  const onSave = () => {
    setIsSaving(true);
    props
      .onSave()
      .then(() => {
        setIsSaveButtonVisible(false);
      })
      .catch((e) => {
        console.log(e);
        toastr.error('There was an issue with saving the fields');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const { savable = true, entries, showFieldKeys } = props;
  const ticket = props.ticket || props.currentTicket;
  const fields = props.fields ? props.fields : ticket.activity.fields;
  const ticketDataEntryAllowed = dataEntryAllowed();
  const sortedFields = _.sortBy(fields, ['sort']);
  const isSaveVisible = ticketDataEntryAllowed && savable;

  const exceptions = ticket.exceptionInfo?.exceptions || [];
  const exceptionFields = exceptions.reduce((prev, current) => {
    return [...prev, ...(current.affectedFields || [])];
  }, []);

  if (sortedFields.length === 0) return null;

  let onClickProps = {};

  if (!ticketDataEntryAllowed) {
    onClickProps = {
      onClick: () =>
        toastr.error(
          'You do not have permission to enter data into this ticket'
        ),
    };
  }

  return (
    <>
      {exceptions && exceptions.length > 0 && (
        <Message
          error
          header="This ticket contains the following exceptions:"
          list={exceptions.map((exception) => exception.rule)}
        />
      )}
      <div className="ticket-fields-wrapper">
        <Form size="small" className="ticket-fields" {...onClickProps}>
          <Form.Field className="form-actions">
            {isSaveVisible && sortedFields.length > 6 && (
              <Button
                disabled={isSaving || !ticketDataEntryAllowed}
                loading={isSaving}
                onClick={onSave}
                inverted
              >
                Save Changes
              </Button>
            )}
          </Form.Field>

          <FormFields
            fields={sortedFields}
            onChange={handleOnChange}
            entries={entries}
            exceptionFields={exceptionFields}
            fieldIdKey="activityFieldId"
            workflowFieldIdKey="workFlowStatusFieldId"
            dataEntryAllowed={ticketDataEntryAllowed}
            parentId={ticket.id}
            showFieldKeys={showFieldKeys}
          />

          <Form.Field className="form-actions">
            {isSaveVisible && (
              <Button
                disabled={isSaving || !dataEntryAllowed}
                loading={isSaving}
                onClick={onSave}
                inverted
              >
                Save Changes
              </Button>
            )}
          </Form.Field>
        </Form>
      </div>
    </>
  );
};

TicketFields.propTypes = {
  ticketStateActions: PropTypes.object,
  ticket: PropTypes.object,
  currentTicket: PropTypes.object,
  fields: PropTypes.array,
  defaults: PropTypes.object,
  entries: PropTypes.array,
  fieldsPerRow: PropTypes.number,
  onSave: PropTypes.func,
  savable: PropTypes.bool,
  enabled: PropTypes.bool,
  showFieldKeys: PropTypes.bool,
  allowDataEntry: PropTypes.bool,
  confirmPopupActions: PropTypes.object,
  resolving: PropTypes.bool,
};

export default TicketContextConsumer(ConfirmWrapper(TicketFields));
