import { useState, ChangeEvent, FormEvent } from 'react';
import {
  Accordion,
  Form,
  Button,
  List,
  Segment,
  Icon,
} from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import { StatusComposite, StatusReport } from '../../types';
import EditableHeader from '../common/EditableHeader';
import { update } from 'lodash';
import { on } from 'events';

type StatusCompositesEditorProps = {
  statusReport: StatusReport;
  onAddStatusComposite: (statusComposite: StatusComposite) => Promise<void>;
  onSelectStatusComposite: (statusComposite: StatusComposite) => void;
  onRemoveStatus: (statusComposite: StatusComposite, statusKey: string) => void;
  onUpdateStatusComposite: (statusComposite: StatusComposite) => Promise<void>;
};

const initialFormState: StatusComposite = {
  label: '',
  description: '',
  ref: '',
  meta: {},
  sort: 0,
  metrics: [],
};

export const StatusCompositesEditor = ({
  statusReport,
  onAddStatusComposite,
  onSelectStatusComposite,
  onRemoveStatus,
  onUpdateStatusComposite,
}: StatusCompositesEditorProps) => {
  const [formState, setFormState] = useState<StatusComposite>(initialFormState);

  const [addingNewStatusComposite, setAddingNewStatusComposite] = useState<
    boolean
  >(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleInputChange = (
    _event: ChangeEvent<HTMLElement>,
    data: {
      name: string;
      value: string;
    }
  ) => {
    const { name, value } = data;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await onAddStatusComposite(formState);
    setFormState(initialFormState);
  };

  const handleAccordionClick = (
    index: number,
    statusComposite: StatusComposite
  ) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      onSelectStatusComposite(null);
      return;
    }
    setActiveIndex(index);
    onSelectStatusComposite(statusComposite);
  };

  return (
    <div>
      {addingNewStatusComposite && (
        <Form onSubmit={handleSubmit}>
          <Form.Input
            label="Label"
            name="label"
            value={formState.label}
            onChange={handleInputChange}
          />
          <Form.TextArea
            label="Description"
            name="description"
            value={formState.description}
            onChange={handleInputChange}
          />
          <Form.Input
            label="Ref"
            name="ref"
            value={formState.ref}
            onChange={handleInputChange}
          />
          <Button type="submit" primary>
            Save
          </Button>
          <Button
            onClick={() => {
              setAddingNewStatusComposite(false);
              setFormState(initialFormState);
            }}
          >
            Cancel
          </Button>
        </Form>
      )}
      {!addingNewStatusComposite && (
        <Button onClick={() => setAddingNewStatusComposite(true)}>
          Add new
        </Button>
      )}

      <Accordion>
        {statusReport.statusComposites?.map((statusComposite, index) => (
          <div key={statusComposite.id}>
            <Accordion.Title
              active={activeIndex === index}
              onClick={() => handleAccordionClick(index, statusComposite)}
            >
              <Icon name="dropdown" />
              {statusComposite.label} ({statusComposite.metrics.length})
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <Segment basic>
                <EditableHeader
                  placeholder="Label"
                  size="large"
                  value={statusComposite.label}
                  onSubmit={(_, { value: label }) => {
                    onUpdateStatusComposite({ ...statusComposite, label });
                  }}
                />
                <EditableHeader
                  placeholder="Reference"
                  size="medium"
                  value={statusComposite.ref}
                  onSubmit={(_, { value: ref }) => {
                    onUpdateStatusComposite({ ...statusComposite, ref });
                  }}
                />
                <EditableHeader
                  placeholder="Description"
                  size="tiny"
                  value={statusComposite.description}
                  onSubmit={(_, { value: description }) => {
                    onUpdateStatusComposite({
                      ...statusComposite,
                      description,
                    });
                  }}
                />
                <List>
                  {statusComposite.metrics.map((metric, index) => {
                    const metricStatus = statusReport.statuses.find(
                      (status) => status.ref === metric
                    );
                    const metricField = statusReport.activityFields.find(
                      (field) => field.fieldKey === metric
                    );
                    return (
                      <List.Item key={index}>
                        <List.Header>
                          {metricStatus?.label || metricField?.label}{' '}
                          <ActionButtons
                            removeClicked={() =>
                              onRemoveStatus(statusComposite, metric)
                            }
                          />
                        </List.Header>
                        <p>{metric}</p>
                      </List.Item>
                    );
                  })}
                </List>
              </Segment>
            </Accordion.Content>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default StatusCompositesEditor;
