import React from 'react';
import * as PropTypes from 'prop-types';

import EventListContainer from '../common/events/EventListContainer';
import { TARGET_MODELS } from '../../utils/EventConsts';

const TicketHistory = (props) => {
  const { currentTicket } = props;

  const filterWhere = {
    targetModel: TARGET_MODELS.EXECUTION_TICKET,
    targetId: currentTicket.id,
  };

  return (
    <div>
      <EventListContainer filterWhere={filterWhere} showLink={false} />
    </div>
  );
};

TicketHistory.propTypes = {
  currentTicket: PropTypes.object,
};

export default TicketHistory;
