import React from 'react';
import * as PropTypes from 'prop-types';

import TicketLinksTable from './TicketLinksTable';

const TicketLinks = ({ currentTicket }) => {
  const { toTicketRelationships, fromTicketRelationships } = currentTicket;
  return (
    <React.Fragment>
      {!!toTicketRelationships.length && (
        <TicketLinksTable
          relationships={toTicketRelationships}
          label={'To this ticket'}
        />
      )}
      {!!fromTicketRelationships.length && (
        <TicketLinksTable
          relationships={fromTicketRelationships}
          label={'From this ticket'}
        />
      )}
    </React.Fragment>
  );
};

TicketLinks.propTypes = {
  currentTicket: PropTypes.object,
};

export default TicketLinks;
