import * as types from '../constants/actionTypes';

export default function createUserReducer(name = '') {
  return function userReducer(
    state = { loggedIn: false, __type: name },
    action
  ) {
    switch (action.type) {
      case `${types.USER_LOGIN_SUCCESS}_${name}`: {
        const { response } = action;
        return { ...state, ...response, loggedIn: true };
      }
      case `${types.UPDATE_USER_TICKET_COUNT}`: {
        const { count } = action;
        return { ...state, ticketCount: count };
      }
      case `${types.USER_LOGOUT}_${name}`: {
        return { loggedIn: false };
      }
      case `${types.UPDATE_PASSWORD_SUCCESS}`: {
        const { response } = action;
        return { ...response, loggedIn: true };
      }
      default:
        return state;
    }
  };
}
