import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, Form, Grid } from 'semantic-ui-react';
import DebugComponent from '../../common/dashboard/components/DebugComponent';
import * as _ from 'lodash';
import Fields from '../../common/fields/Fields';

const DataMapper = (props) => {
  const { mapper, availableMaps, onFieldChange, mappedData } = props;

  const handleFieldChange = (event, data) => {
    onFieldChange(data);
  };

  const availableMapsOptions = _.map(availableMaps, (item, index) => ({
    key: index,
    text: index,
    value: index,
  }));
  const map = availableMaps[mapper.name];
  if (!map) return null;

  const inputDataSet = mapper.args['dataSet'] || 'main';
  const outputDataSet =
    mapper.args['outputKey'] || mapper.args['dataSet'] || 'main';
  const inputData = _.get(mappedData, inputDataSet);
  const outputData = _.get(mappedData, outputDataSet);

  return (
    <Grid columns={2}>
      <Grid.Column>
        <Form>
          <Dropdown
            selection
            options={availableMapsOptions}
            value={mapper.name}
            fluid
          />
          {map.args &&
            _.map(map.args, (arg, index) => {
              const Field = Fields[arg.field];
              return (
                <Field
                  onChange={handleFieldChange}
                  label={index}
                  name={index}
                  value={mapper.args[index]}
                  type={arg.type}
                  key={index}
                  allowModeChange={true}
                  alwaysReturnString={false}
                />
              );
            })}
        </Form>
      </Grid.Column>
      <Grid.Column>
        <DebugComponent inputData={inputData} outputData={outputData} />
      </Grid.Column>
    </Grid>
  );
};

DataMapper.propTypes = {
  mapper: PropTypes.object,
  availableMaps: PropTypes.object,
  onFieldChange: PropTypes.func,
  mappedData: PropTypes.object,
};

export default DataMapper;
