import _ from 'lodash';

export default function (args) {
  const { dataSet, values, include = [], outputKey, data } = args;
  const dataToFilter = data[dataSet];

  let filteredData = _.chain(dataToFilter)
    .map((row) => {
      const a = [];
      const picked = _.pick(row, include);
      _.forEach(values, (value) => {
        const { key, label } = value;
        const val = _.get(row, key);

        if (val)
          a.push({
            label,
            value: val,
            ...picked,
            ...value.include,
          });
      });

      return a;
    })
    .value();

  return { ...args, data: { ...data, [outputKey || dataSet]: filteredData } };
}
