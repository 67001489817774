import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';

const TicketStats = (props) => {
  const { attachmentsCount, commentsCount } = props;
  if (!attachmentsCount && !commentsCount) return null;
  return (
    <div className="ticket-stats">
      {!!attachmentsCount && (
        <Label basic>
          <Icon name="attach" /> {attachmentsCount}
        </Label>
      )}
      {!!commentsCount && (
        <Label basic>
          <Icon name="comments" /> {commentsCount}
        </Label>
      )}
    </div>
  );
};

TicketStats.propTypes = {
  attachmentsCount: PropTypes.number,
  commentsCount: PropTypes.number,
};

export default TicketStats;
