import React from 'react';
import * as PropTypes from 'prop-types';
import { Header, List } from 'semantic-ui-react';

import PrincipalRole from './PrincipalRole';
import ActionButtons from './../common/ActionButtons';
import { PermissionContextConsumer } from '../../context/PermissionContext';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export const PermissionNodeComponent = (props) => {
  const [showChildren, setShowChildren] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const { id, name, label, type, children, principalRoles } = props.node;
  const { openCreatePermissionModal, currentNodeId, addUser } = props;

  return (
    <List.Item>
      <List.Icon name="folder" />
      <List.Content>
        <Header as={Link} to={`/permission/${id}`}>
          {id} - {label}
        </Header>
        {type && <List.Description>{type.name}</List.Description>}
        <List.Description>
          <ActionButtons
            addClicked={() =>
              openCreatePermissionModal(id, name, currentNodeId)
            }
            addUser={() => addUser(id, name, currentNodeId)}
            viewClicked={!showChildren ? () => setShowChildren(true) : null}
            hideClicked={showChildren ? () => setShowChildren(false) : null}
          />
        </List.Description>
        {principalRoles && principalRoles.length > 0 && (
          <>
            <List.Description>
              <Header>
                Access{' '}
                <ActionButtons
                  viewClicked={!showUsers ? () => setShowUsers(true) : null}
                  hideClicked={showUsers ? () => setShowUsers(false) : null}
                />
              </Header>
            </List.Description>

            {showUsers && (
              <List.List>
                {principalRoles.map((principalRole, index) => {
                  return <PrincipalRole key={index} data={principalRole} />;
                })}
              </List.List>
            )}
          </>
        )}
        {children && (
          <List.List>
            {showChildren &&
              children.map((childNode, index) => {
                return (
                  <PermissionNodeComponent
                    key={index}
                    node={childNode}
                    openCreatePermissionModal={openCreatePermissionModal}
                    addUser={addUser}
                    currentNodeId={currentNodeId}
                  />
                );
              })}
          </List.List>
        )}
      </List.Content>
    </List.Item>
  );
};

PermissionNodeComponent.propTypes = {
  node: PropTypes.object.isRequired,
  openCreatePermissionModal: PropTypes.func,
  currentNodeId: PropTypes.string.isRequired,
  addUser: PropTypes.func,
};

export default PermissionContextConsumer(PermissionNodeComponent);
