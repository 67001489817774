import { types } from '@babel/core';
import { useMemo } from 'react';
import clsx from 'clsx';
import { Header } from 'semantic-ui-react';
import { Result } from '../../../../../api/statusApi';
import {
  getStatusFlags,
  StatusFlags,
} from '../../../../../utils/data/statusUtils';
import DebugComponent from '../DebugComponent';

import './SectionHeader.scss';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import { ComponentSize, RAGState } from '../../../../../types';

interface SectionHeaderProps {
  header?: string;
  maxHeight?: number;
  state?: RAGState;
  size?: ComponentSize;
}

export interface PropsData {
  selection: null;
  data: DataData;
}

export interface DataData {
  main: Main;
}

export interface Main {
  results: Result[];
}

const SectionHeader = (props: SectionHeaderProps) => {
  const { header, state, size = 'medium' } = props;

  const classNames = clsx('rag-header', {
    'rag-header--error': state === 'ERROR',
    'rag-header--exception': state === 'EXCEPTION',
    'rag-header--no-results': state === 'NO_RESULTS',
    'rag-header--unfinished': state === 'UNFINISHED',
    'rag-header--positive': state === 'POSITIVE',
    'rag-header--caution': state === 'CAUTION',
    'rag-header--none': state === 'NONE',
  });

  return (
    <div className={classNames} {...props}>
      <Header size={size}>{header}</Header>
    </div>
  );
};

SectionHeader.fields = [
  {
    id: 'state',
    required: false,
    label: 'State',
    activityFieldType: 'Select',
    options: {
      values: [
        'DEFAULT',
        'ERROR',
        'EXCEPTION',
        'NO_RESULTS',
        'UNFINISHED',
        'POSITIVE',
        'CAUTION',
      ],
    },
  },
  {
    id: 'size',
    required: false,
    label: 'Size',
    activityFieldType: 'Select',
    options: {
      values: ['tiny', 'small', 'medium', 'large', 'huge'],
    },
  },
];

export default SectionHeader;
