import apiTools from './apiTools';

class ExecutionPlanApi {
  static getExecutionPlans() {
    return apiTools
      .get('ExecutionPlans', {
        filter: {
          order: 'createdAt DESC',
          limit: 500,
          include: ['programme', 'assignedParty', 'ownerParty'],
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static getExecutionPlan(id) {
    return apiTools
      .get(`ExecutionPlans/${id}`, {
        filter: {
          include: [
            'assignedPartyUser',
            'reviewParty',
            'reviewPartyUser',
            'ownerPartyUser',
            'workflow',
            'schedules',
            'ownerParty',
            {
              relation: 'assignedParty',
              scope: {
                include: [
                  {
                    relation: 'partyRelationships',
                    scope: {
                      include: ['relationshipType', 'toParty', 'fromParty'],
                    },
                  },
                ],
              },
            },
            {
              relation: 'programme',
              scope: {
                include: [
                  {
                    relation: 'activitySets',
                    scope: {
                      include: ['activities', 'activitiesLink'],
                    },
                  },
                  'categories',
                  'status',
                ],
              },
            },
            {
              relation: 'activitySets',
              scope: {
                include: [
                  {
                    relation: 'partyRelationships',
                    scope: {
                      include: ['relationshipType', 'toParty', 'fromParty'],
                    },
                  },
                  {
                    relation: 'entities',
                    scope: {
                      include: ['entityType'],
                    },
                  },
                ],
              },
            },
          ],
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static saveExecutionPlan(executionPlan) {
    const apiMethod = executionPlan.id ? apiTools.patch : apiTools.post;
    return apiMethod(`ExecutionPlans`, executionPlan).then((response) => {
      return response.data;
    });
  }

  static launchNowExecutionPlan(executionPlanId, requestData) {
    return apiTools
      .post(`ExecutionPlans/${executionPlanId}/execute`, requestData)
      .then((response) => {
        return response.data;
      });
  }

  static addActivitySet(executionPlan, activitySetId) {
    return apiTools
      .post(`ExecutionPlans/${executionPlan.id}/activitySets`, {
        activitySetId,
      })
      .then((response) => {
        return response.data;
      });
  }

  //ExecutionPlans/1/activitySets/2?
  static removeActivitySet(executionPlan, planActivitySetId) {
    return apiTools
      .delete(
        `ExecutionPlans/${executionPlan.id}/activitySets/${planActivitySetId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static activitySetAddRelationship(planActivitySetId, relationshipId) {
    return apiTools
      .put(
        `ExecutionPlanActivitySets/${planActivitySetId}/partyRelationships/rel/${relationshipId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static activitySetRemoveRelationship(planActivitySetId, relationshipId) {
    return apiTools
      .delete(
        `ExecutionPlanActivitySets/${planActivitySetId}/partyRelationships/rel/${relationshipId}`
      )
      .then((response) => {
        return response;
      });
  }

  static activitySetAddEntity(planActivitySetId, entityId) {
    return apiTools
      .put(
        `ExecutionPlanActivitySets/${planActivitySetId}/entities/rel/${entityId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static activitySetRemoveEntity(planActivitySetId, entityId) {
    return apiTools
      .delete(
        `ExecutionPlanActivitySets/${planActivitySetId}/entities/rel/${entityId}`
      )
      .then((response) => {
        return response;
      });
  }

  static launchScheduleExecutionPlan(executionPlanId, launchSchedule) {
    return apiTools
      .post(`ExecutionPlans/${executionPlanId}/schedules`, launchSchedule)
      .then((response) => {
        return response.data;
      });
  }

  static updateActivitySetWorkflow(activitySetId, workflowId = null) {
    const apiMethod = workflowId ? apiTools.put : apiTools.delete;
    const apiPath = workflowId
      ? `ExecutionPlanActivitySets/${activitySetId}/workflow/${workflowId}`
      : `/ExecutionPlanActivitySets/${activitySetId}/workflow`;

    return apiMethod(apiPath).then((response) => {
      return response.data;
    });
  }

  static removeScheduledExecutionPlan(executionPlanId, scheduledLaunchId) {
    return apiTools
      .delete(
        `ExecutionPlans/${executionPlanId}/schedules/${scheduledLaunchId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static assignRelationshipToActivitySetsExectuionPlan(
    relationshipId,
    activitySetIds
  ) {
    return apiTools
      .put(
        `PartyRelationships/${relationshipId}/executionPlanActivitySets`,
        activitySetIds
      )
      .then((response) => {
        return response.data;
      });
  }

  static removeRelationshipToActivitySetsExectuionPlan(
    relationshipId,
    activitySetIds
  ) {
    return apiTools
      .delete(
        `PartyRelationships/${relationshipId}/executionPlanActivitySets/`,
        activitySetIds
      )
      .then((response) => {
        return response.data;
      });
  }

  static assignQuestionSetToPrincipal(
    executionPlanId,
    activitySetId,
    assignedPrincipalId
  ) {
    return apiTools
      .put(`ExecutionPlans/${executionPlanId}/activitySets/${activitySetId}`, {
        assignedPrincipalId,
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default ExecutionPlanApi;
