import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const UserLabel = (props) => {
  const { user, deleteUser, asLink = false } = props;

  if (asLink)
    return (
      <Link to="#">
        {user.firstName} {user.lastName}
      </Link>
    );
  return (
    <Label color="grey" image>
      <Icon name="user" />
      &nbsp;{user.firstName} {user.lastName}
      {deleteUser ? (
        <Icon
          name="delete"
          onClick={() => {
            deleteUser();
          }}
        />
      ) : null}
    </Label>
  );
};

UserLabel.propTypes = {
  user: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
  asLink: PropTypes.bool,
};

export default UserLabel;
