import { useDispatch } from 'react-redux';
import { Header, Popup } from 'semantic-ui-react';
import { Result, StatusResultArguments } from '../../../../api/statusApi';
import * as uiActions from '../../../../actions/uiActions';

type StatusPopupWrapperProps = {
  result: Result;
  args: StatusResultArguments;
  children: any;
};

export const StatusPopupWrapper = ({
  children,
  result,
  args,
}: StatusPopupWrapperProps) => {
  const dispatch = useDispatch();

  if (!result) return children;
  const handleClick = () => {
    dispatch(
      uiActions.showSidebar({
        sidebarType: 'METRIC_RESULT_VIEWER',
        sidebarProps: {
          result,
          args,
        },
        sidebarConfig: { width: 'wide' },
      })
    );
  };
  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        height: '100%',
      }}
    >
      <Popup trigger={children} disabled={!result?.label}>
        {result?.label && result.label.length > 0 && (
          <div>
            <Header size="small">{result.label}</Header>
            {result.description && <p>{result.description}</p>}
          </div>
        )}
      </Popup>
    </div>
  );
};
