/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */

import { useEffect } from 'react';
import { Form, Checkbox, Item, Select, Message } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';
import * as _ from 'lodash';

import FormFields from '../../common/FormFields';
import Text from '../../common/fields/Text';
// import BudgetList from './BudgetList';
import './SaveEntityModalForm.scss';
import { EntityEntry } from '../../../types';
import { useAppSelector } from '../../../actions/store';
import { loadEntityType } from '../../../actions/entityActions';
import { loadExecutionPlans } from '../../../actions/executionPlanActions';
import { FieldErrors } from '../../../utils/Validation';
import DateField from '../../common/fields/Date';

export interface EntityFormData {
  label: string;
  executionPlanId: number | null;
  launchExecution: boolean;
  entries: EntityEntry[];
  dueDate?: string;
  coManufactured?: boolean;
  distributorId?: string;
}

interface Props {
  formData: EntityFormData;
  errors?: FieldErrors;
  // parties: any[];
  // currencies: any[];
  // entityTypes: any[];
  handleInputChanged: (
    event: any,
    data: { name: string; value?: string; checked?: boolean }
  ) => void;
  onFieldChange: (
    event: any,
    data: { name: string; value?: string; checked?: boolean }
  ) => void;
  handleOnBlur?: (fieldName: string) => void;
  handleOnFocus?: (fieldName: string) => void;
  hideLaunchExecutionPlan?: boolean;
  hideDistributor?: boolean;
  // getCurrentEntityFields: (partyEntityTypeId: string) => any[];
  // onEntityTypeChange: (event: any, data: any) => void;
  // partyStateActions: {
  //   loadParties: () => Promise<void>;
  // };
  type: string;
}

const SaveEntityModalForm = (props: Props) => {
  const dispatch = useDispatch();
  const entityType = useAppSelector(
    (state) => state.entity.entityTypes.byId[props.type]
  );

  const executionPlans = useAppSelector((state) => state.executionPlans);
  const party = useAppSelector((state) => state.party);

  const distributors =
    party?.partyRelationships
      ?.filter((relationship) => relationship.toParty)
      .map((relationship) => relationship.toParty) || [];

  // const [isPartiesLoading, setIsPartiesLoading] = useState(false);
  // const [validations, setValidations] = useState(props.validations);

  useEffect(() => {
    if (entityType) return;
    dispatch(loadEntityType(props.type));
  }, [props.type]);

  useEffect(() => {
    if (executionPlans.length > 0) return;
    dispatch(loadExecutionPlans());
  }, []);
  // state = {
  //   isPartiesLoading: false,
  //   validations: props.validations
  // };
  // componentDidMount() {
  //   const { parties } = props;
  //   if (!parties.length) {
  //     const { partyStateActions } = props;
  //     setState({ isPartiesLoading: true });
  //     partyStateActions
  //       .loadParties()
  //       .then(() => setState({ isPartiesLoading: false }));
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   if (!_.isEqual(props.validations, prevProps.validations)) {
  //     setState({ validations: props.validations });
  //   }
  // }

  const {
    // currencies,
    // parties,
    // entityTypes,
    handleInputChanged,
    handleOnBlur,
    handleOnFocus,
    onFieldChange,
    hideDistributor = true,
    hideLaunchExecutionPlan = true,
    // getCurrentEntityFields,
    // onEntityTypeChange,
  } = props;
  const {
    label,
    entries,
    executionPlanId,
    launchExecution,
    dueDate,
    distributorId,
    coManufactured,
  } = props.formData;

  const executionPlanOptions = executionPlans.map((executionPlan) => ({
    key: executionPlan.id,
    value: executionPlan.id,
    text: `${executionPlan.label} - ${executionPlan.ownerParty?.label}`,
  }));

  const distributorOptions = distributors.map((distributor) => ({
    key: distributor.id,
    value: distributor.id,
    text: distributor.label,
  }));

  // const fields = getCurrentEntityFields(partyEntityTypeId);
  return (
    <Form>
      {/* <Form.Group widths="equal">
          <Form.Field>
            <Select
              name="fromPartyId"
              options={parties ? parties : []}
              label="Entity owner"
              value={fromPartyId}
              errors={getErrors('fromPartyId')}
              required={true}
              disabled={isPartiesLoading}
              loading={isPartiesLoading}
              onChange={handleInputChanged}
              onBlur={() => handleOnBlur('fromPartyId')}
              onFocus={() => handleOnFocus('fromPartyId')}
              sortItemsAlphabetically={true}
            />
          </Form.Field>
          <Form.Field>
            <Select
              name="toPartyId"
              options={parties ? parties : []}
              label="Entity relates to"
              value={toPartyId}
              errors={getErrors('toPartyId')}
              required={false}
              clearable
              disabled={isPartiesLoading}
              loading={isPartiesLoading}
              onChange={handleInputChanged}
              onBlur={() => handleOnBlur('toPartyId')}
              onFocus={() => handleOnFocus('toPartyId')}
              sortItemsAlphabetically={true}
            />
          </Form.Field>
        </Form.Group> */}
      <Form.Group widths="equal">
        <Form.Field>
          <Text
            label="Label"
            value={label}
            name="label"
            errors={props.errors}
            required={true}
            onChange={handleInputChanged}
            onBlur={handleOnBlur ? () => handleOnBlur('label') : undefined}
            onFocus={handleOnFocus ? () => handleOnFocus('label') : undefined}
          />
        </Form.Field>
        {/* <Form.Field>
            <Select
              name="partyEntityTypeId"
              options={entityTypes ? entityTypes : []}
              label="Select entity type"
              value={partyEntityTypeId}
              errors={getErrors('partyEntityTypeId')}
              required={true}
              onChange={handleEntityChanged}
              onBlur={() => handleOnBlur('partyEntityTypeId')}
              onFocus={() => handleOnFocus('partyEntityTypeId')}
            />
          </Form.Field> */}
      </Form.Group>
      <Form.Field>
        <Item.Group relaxed={false} className="item-group-save-entity">
          {entityType && (
            <FormFields
              fields={
                entityType
                  ? entityType.fields.sort(
                      (a, b) => (a.sort || 0) - (b.sort || 0)
                    )
                  : []
              }
              onChange={onFieldChange}
              entries={entries || []}
              fieldIdKey="entityTypeFieldId"
              fieldsPerRow={2}
            />
          )}
        </Item.Group>
      </Form.Field>

      {!hideLaunchExecutionPlan && (
        <Form.Field>
          <Form.Field>
            <Checkbox
              label={`Launch Programme when created`}
              name="launchExecution"
              onChange={handleInputChanged}
              checked={launchExecution}
            />
          </Form.Field>
        </Form.Field>
      )}

      {!hideLaunchExecutionPlan && launchExecution && (
        <Form.Group>
          <Form.Field
            width={12}
            error={
              !!props.errors?.executionPlanId
                ? {
                    content: props.errors?.executionPlanId.toString(),
                  }
                : null
            }
            control={Select}
            name="executionPlanId"
            options={executionPlanOptions}
            label="Select Execution Plan"
            value={executionPlanId}
            required={true}
            onChange={handleInputChanged}
            onBlur={
              handleOnBlur ? () => handleOnBlur('executionPlanId') : undefined
            }
            onFocus={
              handleOnFocus ? () => handleOnFocus('executionPlanId') : undefined
            }
          ></Form.Field>
          <Form.Field
            width={4}
            control={DateField}
            label="Due Date"
            value={dueDate}
            onChange={handleInputChanged}
            name="dueDate"
          ></Form.Field>
        </Form.Group>
      )}

      {!hideDistributor && (
        <Form.Field>
          <Form.Field>
            <Checkbox
              label={`This is a co-manufactured product`}
              name="coManufactured"
              onChange={handleInputChanged}
              checked={coManufactured}
            />
          </Form.Field>
        </Form.Field>
      )}
      {!hideDistributor && coManufactured && (
        <>
          {distributorOptions.length > 0 ? (
            <Form.Field
              error={
                !!props.errors?.distributorId
                  ? {
                      content: props.errors?.distributorId.toString(),
                    }
                  : null
              }
              control={Select}
              name="distributorId"
              options={distributorOptions}
              label="Select distributor"
              value={distributorId}
              required={true}
              onChange={handleInputChanged}
              onBlur={
                handleOnBlur ? () => handleOnBlur('distributorId') : undefined
              }
              onFocus={
                handleOnFocus ? () => handleOnFocus('distributorId') : undefined
              }
            ></Form.Field>
          ) : (
            <Message
              style={{ display: 'block' }}
              error
              content="You have not added any distributors"
            />
          )}
        </>
      )}
    </Form>
  );
};

export default SaveEntityModalForm;
