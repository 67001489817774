import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';

import { TicketContextConsumer } from './../../../context/TicketContext';
import { WrapUserContext } from './../../../context/UserContext';
import AttachmentsListContainer from './../../common//attachments/AttachmentsListContainer';

const FileUpload = (props) => {
  const {
    currentTicket,
    label,
    required,
    ticketStateActions,
    ticketActions,
    attachmentsConstants,
    disabled,
    error
  } = props;
  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <AttachmentsListContainer
        modelStateActions={ticketStateActions}
        currentModel={currentTicket}
        modelActions={ticketActions}
        attachmentsConstants={attachmentsConstants}
        disabled={disabled}
      />
    </Form.Field>
  );
};

FileUpload.propTypes = {
  currentTicket: PropTypes.object,
  label: PropTypes.string,
  ticketStateActions: PropTypes.object,
  openFileDialog: PropTypes.func,
  required: PropTypes.bool,
  ticketActions: PropTypes.object,
  attachmentsConstants: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default WrapUserContext(TicketContextConsumer(FileUpload));
