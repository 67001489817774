import { Checkbox, Form, Input, Message } from 'semantic-ui-react';
import { FieldErrors } from '../../utils/Validation';

// create the props type for the component
type CreateDistributorFormProps = {
  // add the props you need
  // for example:
  fieldErrors?: FieldErrors;
  error?: string[];
  data: DistributorFormData;
  hideReference?: boolean;
  onChange: (
    event: any,
    data: { name: string; value?: string; checked: boolean }
  ) => void;
};

export const distributorFormValidationSchema = {
  email: {
    email: true,
    required: { error: 'Please supply the email' },
  },

  firstName: {
    required: { error: 'Please supply a first name' },
  },
  lastName: {
    required: { error: 'Please supply a last name' },
  },
  label: {
    required: { error: 'Please supply a label for distributor' },
  },
};

export type DistributorFormData = {
  firstName: string;
  lastName: string;
  email: string;
  label: string;
  ref: string;
  isAdmin: boolean;
  sendNotification: boolean;
};

export const CreateDistributorForm = ({
  fieldErrors,
  data,
  onChange,
  error,
  hideReference,
}: CreateDistributorFormProps) => {
  const { firstName, lastName, email, ref, label, sendNotification } = data;

  return (
    <>
      <Form>
        <Form.Field
          control={Input}
          id="label"
          name="label"
          label="Distributor Name"
          placeholder="Distributor Name"
          error={
            fieldErrors?.label
              ? {
                  content: fieldErrors.label.toString(),
                }
              : null
          }
          value={label}
          onChange={onChange}
        />
        {!hideReference && (
          <Form.Field
            control={Input}
            id="ref"
            name="ref"
            label="Distributor Reference"
            placeholder="Distributor Reference"
            error={
              fieldErrors?.ref
                ? {
                    content: fieldErrors.ref.toString(),
                  }
                : null
            }
            value={ref}
            onChange={onChange}
          />
        )}
        <Form.Field
          control={Input}
          fluid
          placeholder="Primary Contact First Name"
          label="Primary Contact First Name"
          value={firstName}
          name="firstName"
          onChange={onChange}
          error={
            fieldErrors?.firstName
              ? {
                  content: fieldErrors.firstName.toString(),
                }
              : null
          }
        />
        <Form.Field
          control={Input}
          fluid
          placeholder="Primary Contact Last Name"
          label="Primary Contact Last Name"
          value={lastName}
          name="lastName"
          onChange={onChange}
          error={
            fieldErrors?.lastName
              ? {
                  content: fieldErrors.lastName.toString(),
                }
              : null
          }
        />
        <Form.Field
          control={Input}
          fluid
          placeholder="Primary Contact Email"
          label="Primary Contact Email"
          value={email}
          name="email"
          onChange={onChange}
          error={
            fieldErrors?.email
              ? {
                  content: fieldErrors.email.toString(),
                }
              : null
          }
        />
        {/* <Form.Field>
          <Form.Field>
            <Checkbox
              label="Send notification"
              name="sendNotification"
              onChange={onChange}
              checked={sendNotification}
            />
          </Form.Field>
        </Form.Field> */}
      </Form>
      {error && <Message error>{error.toString()}</Message>}
    </>
  );
};
