import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import ConversationComments from './ConversationComments';
import RichTextArea from '../common/fields/RichTextArea';
import { WrapUserContext } from '../../context/UserContext';
import * as conversationActions from '../../actions/conversationActions';
import conversationApi from '../../api/conversationApi';
import { Comment, Conversation } from '../../types';
import { buildCommentTree } from '../../utils/CommentUtils';

interface ConversationProps {
  conversationId: number;
  currentUser?: any;
  type?: string;
  override?: string;
  readOnly?: boolean;
}

const ConversationComponent = ({
  conversationId,
  type = 'Comment',
  currentUser,
  readOnly = false,
}: ConversationProps) => {
  const [currentConversation, setCurrentConversation] =
    useState<Conversation>();
  const [isCommenting, setIsCommenting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [parentComment, setParentComment] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [editingComment, setEditingComment] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchConversation = async () => {
    setIsLoading(true);
    const conversation = await conversationApi.getConversation(conversationId);
    setCurrentConversation(conversation);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!conversationId) return;

    fetchConversation();
  }, [conversationId]);

  const onChange = (event, { value }: { value: string }) => {
    setNewComment(value);
  };

  const addComment = () => {
    setNewComment('');
    setIsCommenting(true);
    setIsEditing(false);
  };

  const cancel = () => {
    setNewComment('');
    setIsCommenting(false);
    setIsReplying(false);
    setParentComment(null);
  };

  const onSave = async () => {
    setIsSaving(true);

    let comment: any = { content: newComment };

    if (isEditing) {
      comment = { ...editingComment, content: newComment };
    }
    if (isReplying) {
      comment = { content: newComment, parentCommentId: parentComment.id };
    }

    await conversationApi.saveComment(currentConversation, comment);

    setNewComment('');
    setIsCommenting(false);
    setIsSaving(false);
    setIsReplying(false);
    setParentComment(null);

    await fetchConversation();
  };

  const onDelete = async (
    event: React.MouseEvent<HTMLButtonElement>,
    comment: any,
    deleted: boolean = true
  ) => {
    await conversationApi.saveComment(currentConversation, {
      ...comment,
      deleted,
    });

    await fetchConversation();
  };

  const onEdit = (event: React.MouseEvent<HTMLButtonElement>, comment: any) => {
    setNewComment(comment.content);
    setIsCommenting(true);
    setIsEditing(true);
    setIsReplying(false);
    setEditingComment(comment);
  };

  const onReply = (
    event: React.MouseEvent<HTMLButtonElement>,
    comment: any
  ) => {
    setNewComment('');
    setIsCommenting(true);
    setIsReplying(true);
    setIsEditing(false);
    setParentComment(comment);
  };

  if (isLoading && !currentConversation)
    return (
      <Dimmer active={isLoading} inverted>
        <Loader disabled={!isLoading} />
      </Dimmer>
    );

  if (!currentConversation) return null;

  const getSaveButtonLabel = () => {
    if (isEditing) return 'Save';
    if (isReplying) return 'Add Reply';
    return `Add`;
  };

  const commentTree = buildCommentTree(currentConversation?.comments || []);

  return (
    <div>
      <ConversationComments
        comments={commentTree}
        currentUser={currentUser}
        onEdit={onEdit}
        onDelete={onDelete}
        onReply={onReply}
        readOnly={readOnly}
      />

      {isLoading && <Loader disabled={!isLoading} />}
      <Form>
        {isCommenting && (
          <React.Fragment>
            <Form.Field>
              <RichTextArea
                label={
                  parentComment && isReplying
                    ? `Replying to ${parentComment.creator.label}'s comment`
                    : null
                }
                value={newComment}
                onChange={onChange}
              />
            </Form.Field>
            <Form.Field>
              <Button
                content={getSaveButtonLabel()}
                primary
                onClick={onSave}
                loading={isSaving}
                disabled={isSaving || newComment === ''}
              />
              <Button
                content={`Cancel`}
                basic
                onClick={cancel}
                loading={isSaving}
                disabled={isSaving}
              />
            </Form.Field>
          </React.Fragment>
        )}
        {!readOnly && !isCommenting && (
          <Form.Field>
            <Button
              content={`Add ${type}`}
              labelPosition="left"
              icon="edit"
              primary
              onClick={addComment}
              loading={isSaving}
              disabled={isSaving}
            />
          </Form.Field>
        )}
      </Form>
    </div>
  );
};

export default WrapUserContext(ConversationComponent);
