import permissionApi from '../api/permissionApi';
import * as types from '../constants/actionTypes';
import { checkValidation, transformValidation } from '../utils/Validation';
import ValidationSchema from '../utils/ValidationSchema';

export function loadPermission(id, filter = null, name = null) {
  return function (dispatch) {
    return permissionApi
      .getPermission(id, !name ? filter : null)
      .then((permission) => {
        if (name) {
          return dispatch({
            type: `${types.LOAD_PERMISSION_SUCCESS}_${name}`,
            permission,
          });
        }
        return dispatch({
          type: `${types.LOAD_PERMISSION_SUCCESS}`,
          structuralNode: permission,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}

export function loadPermissionTypes() {
  return (dispatch) => {
    return permissionApi.getPermissionTypes().then((permissionTypes) => {
      return dispatch({
        type: types.LOAD_PERMISSION_TYPES_SUCCESS,
        permissionTypes,
      });
    });
  };
}

export function loadRoleDefinitions() {
  return (dispatch) => {
    return permissionApi.getRoleDefinitions().then((roleDefinitions) => {
      return dispatch({
        type: types.LOAD_ROLE_DEFINITIONS_SUCCESS,
        roleDefinitions,
      });
    });
  };
}

export function loadPermissions() {
  return function (dispatch) {
    return permissionApi.getPermissions().then((permissions) => {
      return dispatch({
        type: types.LOAD_PERMISSIONS_SUCCESS,
        permissions,
      });
    });
  };
}

export function createPermission(id, name, typeId) {
  return () => {
    const newPermission = { name, typeId };
    const validation = checkValidation(
      newPermission,
      ValidationSchema.createPermission
    );

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    return permissionApi.createPermission(id, name, typeId);
  };
}

export function addPrincipalRole(structuralNodeId, data) {
  return (dispatch) => {
    return permissionApi.addPrincipalRole(structuralNodeId, data)
    .then((principalRole) => {
      return dispatch({
        type: types.ADD_PERMISSION_SUCCESS,
        principalRole,
      });
    });
  };
}

export function deleteRole(
  structuralNodeId,
  principalRoleId,
  isExecution = null
) {
  return (dispatch) => {
    return permissionApi
      .deleteRole(structuralNodeId, principalRoleId)
      .then(() => {
        if (isExecution) {
          return dispatch({
            type: types.DELETE_PERMISSION_SUCCESS,
            principalRoleId,
            structuralNodeId,
          });
        }
        return dispatch({
          type: types.DELETE_PERMISSION_SUCCESS,
          principalRoleId,
          structuralNodeId,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}

export function updatePrincipalRole(structuralNodeId, data) {
  return function (dispatch) {
    return permissionApi
      .updateRole(structuralNodeId, data)
      .then((principalRole) => {
        return dispatch({
          type: types.UPDATE_PERMISSION_SUCCESS,
          principalRole,
          roleDefinition: data.roleDefinition,
        });
      });
  };
}

export function addViewPrincipalRole(structuralNodeId, data) {
  return function (dispatch) {
    return permissionApi
      .addPrincipalRole(structuralNodeId, { ...data, roleDefinitionId: 'View' })
      .then((principalRole) => {
        return dispatch({
          type: types.ADD_VIEW_PERMISSION_SUCCESS,
          principalRole,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}
