import apiTools from './apiTools';
import storeProvider from '../store/storeProvider';

function buildEndpoint(modelName: string, endpoint: string) {
  const model = storeProvider.getStore().store.getState().constants
    .modelStructure[modelName];

  let endpointPrefix = `${modelName}s`;
  // replace ys with ies

  if (endpointPrefix.endsWith('ys')) {
    endpointPrefix = endpointPrefix.slice(0, -2) + 'ies';
  }

  if (model && model.plural) endpointPrefix = model.plural;
  return `${endpointPrefix}/${endpoint}`;
}

class UtilsApi {
  static modelCall({ model, endpoint, params = {} }) {
    return apiTools
      .get(buildEndpoint(model, endpoint), Object.assign({}, params))
      .then((response) => {
        return response.data;
      });
  }

  static groupModel(modelName, relationFilter) {
    const model = storeProvider.getStore().store.getState().constants
      .modelStructure[modelName];
    let endpoint = `${modelName}s`;
    if (model && model.plural) endpoint = model.plural;

    return apiTools
      .get(`${endpoint}/group`, { relationFilter })
      .then((response) => {
        return response.data;
      });
  }
  static filterModel(modelName, relationFilter, includeData = false) {
    const model = storeProvider.getStore().store.getState().constants
      .modelStructure[modelName];
    let endpoint = `${modelName}s`;
    if (model && model.plural) endpoint = model.plural;

    return apiTools
      .get(`${endpoint}/filter`, { relationFilter, includeData })
      .then((response) => {
        return response.data;
      });
  }

  static getModelStructure() {
    return apiTools.get('Utils/modelStructure').then((response) => {
      return response.data;
    });
  }

  static getModelList(model) {
    return apiTools.get(`${model}s/`).then((response) => {
      return response.data;
    });
  }

  static importModel(model, file, progressCallback, params) {
    return apiTools
      .upload(`${model}s/import`, file, progressCallback, params)
      .then((response) => {
        return response;
      });
  }

  static getCurrencies() {
    return apiTools.get('Currencies').then((response) => {
      return response.data;
    });
  }

  static getConfig() {
    return apiTools.get('Utils/config').then((response) => {
      return response.data;
    });
  }
}

export default UtilsApi;
