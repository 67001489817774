import React, { Component, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import * as _ from 'lodash';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

const Currency = (props) => {
  // state = {
  //   value: ''
  // };

  const [value, setValue] = useState(props.value);

  // componentWillMount() {
  //   const { value } = props;
  //   setState({ value });
  // }

  const onBlur = (e) => {
    // const { value } = state;
    const { onChange, value: initialValue } = props;
    if (value !== initialValue) {
      const newValue = value != undefined ? Number(value).toFixed(2) : value;
      // setState({ value: newValue }, () => onChange(e, state));
      setValue(newValue);
      onChange(e, { value });
    }
  };

  const handleInputChanged = (event, { name, value: newValue }) => {
    // setState({ [name]: value });
    setValue(newValue);
  };

  const {
    label,
    disabled = false,
    required,
    editable = true,
    errors,
    name,
    error
  } = props;
  // const { value } = state;
  const fieldErrors = errors ? errors[name] : undefined;

  if (!editable) {
    const formattedValue =
      value != undefined ? Number(value).toFixed(2) : value;
    return <React.Fragment>{formattedValue}</React.Fragment>;
  }

  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Form.Input
        type={'number'}
        placeholder={label}
        className="currency"
        onBlur={onBlur}
        value={value || ''}
        onChange={handleInputChanged}
        name="value"
        disabled={disabled}
        error={!!fieldErrors}
      />
      {_.isArray(fieldErrors) && <FieldErrors errors={fieldErrors} />}
    </Form.Field>
  );
};

Currency.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  errors: PropTypes.object,
};

Currency.defaultProps = {
  value: '',
};

export default Currency;
