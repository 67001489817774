import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Table } from 'semantic-ui-react';
const Markdown = (props) => {
  return (
    <ReactMarkdown {...props} escapeHtml={false} renderers={{ table: Table }} />
  );
};

Markdown.propTypes = {};

export default Markdown;

Markdown.fields = [
  {
    id: 'source',
    required: false,
    label: 'Source',
    activityFieldType: 'RichTextArea',
  },
];
