import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import * as _ from 'lodash';

import ProgrammeReportsTable from './ProgrammeReportsTable';
import ProgrammeWrapper from './Programme';
import executionApi from '../../api/executionApi';
import { mapToOptions } from '../../utils/React';

const ProgrammeReports = ({
  reports,
  programmeStateActions,
  programmeType,
}) => {
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    executionApi.getDashboards().then((result) => {
      setDashboards(result);
      setIsLoading(false);
    });
  }, []);

  const dropDownValues = _.map(reports, (report) => report.id);
  const unusedDashboards = _.filter(
    dashboards,
    (dashboard) => !_.includes(dropDownValues, dashboard.id)
  );
  const dashboardOptions = mapToOptions(unusedDashboards);
  const isReportsTableVisible = reports.length > 0;

  const handleReportsChange = (event, { value }) => {
    const newItem = dashboardOptions.find((option) => option.key === value);
    const { value: id, text } = { ...newItem };
    const newReportItem = { id, label: text, reportLabel: text };
    programmeStateActions.updateProgrammeOptions(programmeType, {
      reports: [...reports, newReportItem],
    });
  };

  const handleDeleteReportClicked = (id) => {
    const reportsUpdated = _.filter(reports, (report) => report.id !== id);
    programmeStateActions.updateProgrammeOptions(programmeType, {
      reports: reportsUpdated,
    });
  };

  const handleReportLabelChanged = (id, newReportLabel) => {
    const newValues = _.map(reports, (report) => {
      const updatedReport = { ...report };
      if (report.id === id) {
        updatedReport.reportLabel = newReportLabel;
      }
      return updatedReport;
    });
    programmeStateActions.updateProgrammeOptions(programmeType, {
      reports: newValues,
    });
  };

  return (
    <React.Fragment>
      {!isLoading && (
        <Dropdown
          placeholder="Add report"
          fluid
          selection
          options={dashboardOptions}
          value={null}
          onChange={handleReportsChange}
        />
      )}
      {isReportsTableVisible && (
        <ProgrammeReportsTable
          reports={reports}
          handleDeleteReportClicked={handleDeleteReportClicked}
          handleReportLabelChanged={handleReportLabelChanged}
        />
      )}
    </React.Fragment>
  );
};

ProgrammeReports.propTypes = {
  reports: PropTypes.array,
  programmeStateActions: PropTypes.func,
  programmeType: PropTypes.string,
};

export default ProgrammeWrapper(ProgrammeReports);
