import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Segment, Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import DebugTableHeadings from './DebugTableHeadings';
import ErrorBoundary from '../common/ErrorBoundary';

class DebugTable extends Component {
  render() {
    let { data } = this.props;
    if (!data) return null;
    if (_.isPlainObject(data)) {
      data = [data];
    }

    const headings = data.length > 0 ? _.keys(data[0]) : [];

    return (
      <Segment style={{ overflow: 'auto' }}>
        <Table>
          <DebugTableHeadings headings={headings} />

          <Table.Body>
            {data.map((row, index) => (
              <Table.Row key={index}>
                {_.map(row, (value, index) => {
                  if (_.isPlainObject(value))
                    return (
                      <Table.Cell key={index}>Object ({index})</Table.Cell>
                    );
                  if (Array.isArray(value))
                    return <Table.Cell key={index}>Array ({index})</Table.Cell>;
                  return <Table.Cell key={index}>{value}</Table.Cell>;
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

DebugTable.propTypes = {
  data: PropTypes.array,
};

export default ErrorBoundary(DebugTable);
