import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';

const SelectRelationship = ({
  options,
  disabled = false,
  onChange,
  value,
  label,
  className,
  showLabel = true,
  clearable = false,
  loading,
}) => {
  return (
    <Form>
      <Form.Field inline>
        {showLabel && <label>{label}</label>}
        <Dropdown
          placeholder={label}
          fluid
          selection
          value={value}
          options={options}
          onChange={onChange}
          disabled={disabled}
          loading={loading || disabled}
          selectOnBlur={false}
          className={className}
          clearable={clearable}
          search
        />
      </Form.Field>
    </Form>
  );
};

SelectRelationship.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  loading: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default SelectRelationship;
