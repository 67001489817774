import React from 'react';

const AdminContext = React.createContext();

export const AdminContextConsumer = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <AdminContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </AdminContext.Consumer>
      );
    }
  };

export default AdminContext;
