import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
// import { JsonEditor as Editor } from 'jsoneditor-react';

let Editor;
if (typeof window !== 'undefined') {
  Editor = require('jsoneditor-react').JsonEditor;
}

import ActionButtons from '../ActionButtons';

import './JSONBuilder.scss';

const JSONBuilder = (props) => {
  const { valueDefault = {} } = props;
  const {
    label,
    required,
    value = valueDefault,
    onChange,
    name,
    startMode = 0,
    allowModeChange = false,
  } = props;

  const modes = ['tree', 'text'];

  const [modeIndex, setModeIndex] = useState(startMode);

  const handleOnChange = (value) => {
    onChange({}, { name, value: value || valueDefault });
  };

  const handleModeChange = () => {
    if (modeIndex + 1 >= modes.length) return setModeIndex(0);
    setModeIndex(modeIndex + 1);
  };

  const mode = modes[modeIndex];

  return (
    <React.Fragment>
      <Form.Field required={required} className="json-builder">
        <label> {label}</label>
        {mode === 'tree' && (
          <Editor
            value={value}
            onChange={handleOnChange}
            search={false}
            mode={mode}
          />
        )}
        {mode === 'text' && (
          <Editor
            value={value}
            onChange={handleOnChange}
            search={false}
            mode={mode}
          />
        )}
        {allowModeChange && <ActionButtons refreshClicked={handleModeChange} />}
      </Form.Field>
    </React.Fragment>
  );
};

JSONBuilder.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  allowModeChange: PropTypes.bool,
  startMode: PropTypes.number,
  valueDefault: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default JSONBuilder;
