import * as types from '../constants/actionTypes';
import initialState from './initialState';
import update from 'immutability-helper/index';
import _ from 'lodash';
import storeProvider from '../store/storeProvider';
import { addPermissionsToActivitiesList } from './helpers/activityHelper';

export default function activitiesReducer(
  state = initialState.activities,
  action
) {
  switch (action.type) {
    case types.LOAD_ACTIVITIES_SUCCESS: {
      const currentUser = storeProvider.getStore().store.getState().currentUser;
      return addPermissionsToActivitiesList(currentUser, action.activities);
    }
    case types.SAVE_ACTIVITY_SUCCESS: {
      const { activity } = action;
      const index = _.findIndex(state, { id: activity.id });
      if (index !== -1) {
        const activities = update(state, {
          [index]: { $set: Object.assign({}, state[index], activity) },
        });
        return activities;
      }
      return state;
    }
    default:
      return state;
  }
}
