import React from 'react';
import * as PropTypes from 'prop-types';
import Tags from '../common/Tags';
import { Card, Header, Label, Table, Dimmer } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import { WrapActivityContext } from '../../context/ActivityContext';
import * as permissionTypes from '../../constants/permissionTypes';
import ActivitiesListRowFields from './ActivitiesListRowFields';

const ActivitiesListRow = (props) => {
  const {
    activity,
    activityActions,
    editActivity,
    viewActivity,
    deleteActivity,
    removeActivity,
    addActivity,
    moveActivity,
    cloneActivity,
    layout = 'FULL',
    editable,
    activityStatuses,
    activeMenuItem,
  } = props;
  function removeClicked() {
    removeActivity(activity);
  }
  function deleteClicked() {
    deleteActivity(activity);
  }
  function editClicked(e) {
    e.preventDefault();
    if (editActivity) {
      editActivity(activity);
    } else {
      activityActions.editActivity(activity);
    }
  }
  function viewClicked(e) {
    e.preventDefault();
    if (viewActivity) {
      viewActivity(activity);
    } else {
      activityActions.viewActivity(activity);
    }
  }
  function addClicked() {
    addActivity(activity);
  }
  function upClicked() {
    moveActivity(activity, -1);
  }
  function downClicked() {
    moveActivity(activity, 1);
  }
  function copyClicked() {
    cloneActivity(activity);
  }

  const combineTags = () => {
    return activity.primaryTag
      ? [activity.primaryTag, ...activity.tags]
      : activity.tags;
  };

  function actionButtons(inverted) {
    return editable ? (
      <ActionButtons
        className="u-show-on-hover__target"
        editClicked={
          activity.permissions[permissionTypes.EDIT] ? editClicked : undefined
        }
        deleteClicked={
          deleteActivity && activity.permissions[permissionTypes.CREATE]
            ? deleteClicked
            : undefined
        }
        removeClicked={removeActivity ? removeClicked : undefined}
        addClicked={addActivity ? addClicked : undefined}
        upClicked={moveActivity ? upClicked : undefined}
        downClicked={moveActivity ? downClicked : undefined}
        copyClicked={
          cloneActivity && activity.permissions[permissionTypes.CREATE]
            ? copyClicked
            : undefined
        }
        inverted={inverted}
      />
    ) : (
      <ActionButtons
        className="u-show-on-hover__target"
        editClicked={
          activity.permissions[permissionTypes.EDIT] ? editClicked : undefined
        }
        viewClicked={
          activity.permissions[permissionTypes.VIEW] ? viewClicked : undefined
        }
        inverted={inverted}
      />
    );
  }
  switch (layout) {
    case 'COMPACT':
      const status = activityStatuses[activity.activityStatusId];
      return (
        <Dimmer.Dimmable
          as={Card}
          dimmed={true}
          size="medium"
          className="u-show-on-hover"
        >
          <Dimmer
            active={false}
            inverted={true}
            className="u-show-on-hover__target"
          >
            {actionButtons()}
          </Dimmer>
          <Card.Content>
            {activity.ref && <Header size="small">{activity.ref}</Header>}
            <Label color={status?.colourId} size="mini" attached="top right">
              {status?.label}
            </Label>
            <Card.Meta>{activity.label}</Card.Meta>
          </Card.Content>
        </Dimmer.Dimmable>
      );
    case 'CARD':
      return (
        <Dimmer.Dimmable
          as={Card}
          dimmed={true}
          size="medium"
          className="u-show-on-hover"
        >
          <Dimmer
            active={false}
            inverted={true}
            className="u-show-on-hover__target"
          >
            {actionButtons()}
            <Label size="mini" attached="bottom right" color="purple">
              V:{activity.version} S:{activity.sort}
            </Label>
          </Dimmer>
          <Card.Content>
            {activity.ref && <Header size="small">{activity.ref}</Header>}
            <Label color={status?.colourId} size="mini" attached="top right">
              {status?.label}
            </Label>
            <Card.Meta>{activity.label}</Card.Meta>
          </Card.Content>
        </Dimmer.Dimmable>
      );
    case 'FULL':
      return (
        <Table.Row>
          <Table.Cell>
            <a href="#" onClick={viewClicked}>
              {activity.label}
            </a>
          </Table.Cell>
          <Table.Cell collapsing>
            <Label color={status?.colourId}>{status?.label}</Label>
          </Table.Cell>
          <Table.Cell>
            {activity.primaryTag && (
              <Label>{activity.primaryTag.label.toUpperCase()}</Label>
            )}
          </Table.Cell>
          <Table.Cell>
            <Tags tags={combineTags() || []} />
          </Table.Cell>
          <Table.Cell>{activity.ref}</Table.Cell>
          <Table.Cell>{activity.permissionsNode.name}</Table.Cell>
          <Table.Cell>
            {activity.ownerParty && (
              <Label as="a" color="grey" image>
                {activity.ownerParty.label}
              </Label>
            )}
          </Table.Cell>
          <Table.Cell collapsing>{actionButtons()}</Table.Cell>
        </Table.Row>
      );
    case 'FIELDS':
      return (
        <ActivitiesListRowFields
          activity={activity}
          activeMenuItem={activeMenuItem}
        />
      );
  }
};

ActivitiesListRow.propTypes = {
  activity: PropTypes.object.isRequired,
  activityActions: PropTypes.object,
  activityStatuses: PropTypes.object,
  editActivity: PropTypes.func,
  viewActivity: PropTypes.func,
  deleteActivity: PropTypes.func,
  removeActivity: PropTypes.func,
  addActivity: PropTypes.func,
  moveActivity: PropTypes.func,
  cloneActivity: PropTypes.func,
  layout: PropTypes.string,
  activeMenuItem: PropTypes.string,
  editable: PropTypes.bool,
};

ActivitiesListRow.defaultProps = {
  editable: true,
};

export default WrapActivityContext(ActivitiesListRow);
