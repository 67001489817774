import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const DebugTableHeadings = (props) => {
  const { headings } = props;
  return (
    <Table.Header>
      <Table.Row>
        {headings.map((heading, index) => (
          <Table.HeaderCell key={index}>{heading}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

DebugTableHeadings.propTypes = {
  headings: PropTypes.array,
};

export default DebugTableHeadings;
