import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Button, Table } from 'semantic-ui-react';
import WorkflowStatusNodeListRow from './WorkflowStatusNodeListRow';

@WorkflowWrapContext
export default class WorkflowStatusNodeList extends Component {
  updateStatusNode = (event, { name, value }, statusNode) => {
    const { workflow, workflowStateActions } = this.props;

    if (name === 'isStartNode') {
      const currentStartNode = _.find(workflow.statusNodes, {
        isStartNode: true,
      });
      if (currentStartNode)
        workflowStateActions.updateStatusNode(
          workflow,
          currentStartNode,
          name,
          false
        );
    }
    workflowStateActions.updateStatusNode(workflow, statusNode, name, value);
  };
  addStatusNode = () => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.addStatusNode(workflow);
  };
  removeStatusNode = (event, statusNode) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.removeStatusNode(workflow, statusNode);
  };

  updateTransition = (event, { name, value }, statusNode, transition) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.updateTransition(
      workflow,
      statusNode,
      transition,
      name,
      value
    );
  };

  addTransition = (event, statusNode) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.addTransition(workflow, statusNode);
  };

  removeTransition = (event, statusNode, transition) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.removeTransition(workflow, statusNode, transition);
  };

  render() {
    const { statusNodes, statuses, startStatusNodeUid } = this.props.workflow;
    const transitionActions = {
      updateTransition: this.updateTransition,
      addTransition: this.addTransition,
      removeTransition: this.removeTransition,
    };
    return (
      <React.Fragment>
        <Table collapsing>
          <Table.Body>
            {statusNodes.map((statusNode, index) => (
              <WorkflowStatusNodeListRow
                key={index}
                statusNode={statusNode}
                statusNodes={statusNodes}
                statuses={statuses}
                onChange={(event, data) =>
                  this.updateStatusNode(event, data, statusNode)
                }
                onRemove={(event) => this.removeStatusNode(event, statusNode)}
                transitionActions={transitionActions}
                isStartStatusNode={startStatusNodeUid === statusNode.uid}
              />
            ))}
            <Table.Row>
              <Table.Cell colSpan="4">
                <Button onClick={this.addStatusNode}>Add Status Node</Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

WorkflowStatusNodeList.propTypes = {
  workflow: PropTypes.object,
  workflowStateActions: PropTypes.object,
};
