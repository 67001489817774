import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import toastr from 'toastr';

import { replaceSymbolsToLowerCase } from '../../utils/CommonUtils';
import ticketApi from '../../api/ticketApi';

class TicketRedirect extends Component {
  state = {
    loading: false,
  };
  componentWillMount() {
    const ticketId = _.get(this.props, 'match.params.id');
    this.setState({ loading: true });
    const { history } = this.props;
    ticketApi
      .getTicketForRedirect(ticketId)
      .then((ticket) => {
        const { executionTicketSet, executionId, id } = ticket;
        const categoryLabel = executionTicketSet.category
          ? replaceSymbolsToLowerCase(executionTicketSet.category.label)
          : 'uncategorised';
        this.setState({
          ticketId: id,
          categoryLabel,
          executionId,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error.response.status === 404
          ? history.replace('/notFoundPage')
          : toastr.error(error);
      });
  }
  render() {
    const { categoryLabel, ticketId, executionId, loading } = this.state;
    if (loading)
      return (
        <Dimmer active={loading} inverted>
          <Loader disabled={!loading} />
        </Dimmer>
      );
    return (
      <Redirect to={`/execution/${executionId}/${categoryLabel}/${ticketId}`} />
    );
  }
}

TicketRedirect.propTypes = {
  history: PropTypes.object,
};

export default TicketRedirect;
