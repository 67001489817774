import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

const Email = (props) => {
  const {
    label,
    value,
    onChange,
    disabled = false,
    errors,
    required,
    onFocus,
    onBlur,
    editable = true,
    name,
    error
  } = props;
  const fieldErrors = errors ? errors[name] : undefined;

  if (!editable) {
    return <React.Fragment>{value}</React.Fragment>;
  }

  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Form.Input
        icon="at"
        iconPosition="left"
        placeholder={label}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        error={!!fieldErrors}
      />
      {fieldErrors && <FieldErrors errors={fieldErrors} />}
    </Form.Field>
  );
};

Email.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  required: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Email.defaultProps = {
  value: '',
};

export default Email;
