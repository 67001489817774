import React from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import * as _ from 'lodash';
import { Label, Header } from 'semantic-ui-react';

import { defaultDateFormat } from '../../constants/config';

const TicketStatus = (props) => {
  const {
    statusNode,
    color,
    name,
    deadlineDate = null,
    currentStatusOverdue,
  } = props;
  const statusNodeColor =
    _.get(statusNode, 'status.statusType.colourId') || 'grey';
  const statusNodeLabel = _.get(statusNode, 'label') || '';
  return (
    <React.Fragment>
      <Label color={color || statusNodeColor} image>
        {name || statusNodeLabel}
      </Label>
      {deadlineDate && (
        <Header as="h5" color={currentStatusOverdue ? 'red' : 'black'}>
          <Moment format={defaultDateFormat}>{deadlineDate}</Moment>
        </Header>
      )}
    </React.Fragment>
  );
};

TicketStatus.propTypes = {
  statusNode: PropTypes.object,
  color: PropTypes.string,
  name: PropTypes.string,
  deadlineDate: PropTypes.string,
  currentStatusOverdue: PropTypes.bool,
};

export default TicketStatus;
