import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Dimmer, Grid, Header, Loader } from 'semantic-ui-react';
import ProgrammeWrapper from './Programme';
import ProgrammeDetails from './ProgrammeDetails';
import ProgrammeCategoryTabs from './ProgrammeCategoryTabs';
import ActivityWrapper from '../activity/Activity';
import ErrorBoundary from './../common/ErrorBoundary';

class ProgrammePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    this.props.programmeStateActions
      .loadProgramme(this.props.match.params.id, 'CURRENT')
      .then(() => {
        this.setState({ loading: false });
      });
  }

  launchProgramme = () => {
    this.setState({ loading: true });
    this.props.programmeStateActions
      .launchProgramme(this.props.currentProgramme.id, 'CURRENT')
      .then(({ execution }) => {
        this.props.history.push(`/execution/${execution.id}`);
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const programme = this.props.currentProgramme;

    return (
      <Container fluid>
        <Dimmer active={loading} inverted>
          <Loader disabled={!loading} />
        </Dimmer>
        {!loading && (
          <React.Fragment>
            <Grid celled="internally">
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Header size="medium" as="h2">
                    {programme.label}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid celled="internally">
              <Grid.Row columns={2}>
                <Grid.Column width={12}>
                  <ProgrammeCategoryTabs />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header as="h3">Details</Header>
                  <ProgrammeDetails />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

ProgrammePage.propTypes = {
  programmeActions: PropTypes.object.isRequired,
  programmeStateActions: PropTypes.object.isRequired,
  currentProgramme: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ErrorBoundary(ActivityWrapper(ProgrammeWrapper(ProgrammePage)));
