import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Icon, Card } from 'semantic-ui-react';
import Viewer from '../common/fields/TUIWrapperViewer';

const ProgrammeListItem = ({ programme, onSelect }) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>{programme.label}</Card.Header>
        <Card.Meta>
          <Viewer initialValue={programme.description} />
        </Card.Meta>
        <Card.Description />
        <Card.Content extra>
          {onSelect && (
            <Button primary floated="right" onClick={() => onSelect(programme)}>
              Choose this Programme
              <Icon name="right chevron" />
            </Button>
          )}
        </Card.Content>
      </Card.Content>
    </Card>
  );
};

ProgrammeListItem.propTypes = {
  programme: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

export default ProgrammeListItem;
