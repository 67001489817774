import * as types from '../constants/actionTypes';
import initialState from './initialState';
import update from 'immutability-helper';
import { mapObjectArraysToObject } from '../utils/React';

export default function dashboards(state = initialState.dashboards, action) {
  switch (action.type) {
    case types.FETCH_DASHBOARD_SCHEMA_SUCCESS: {
      const { id, schema } = action;

      const dashboardObject = mapObjectArraysToObject(
        Object.assign({}, schema),
        {
          rows: 'id',
          columns: 'id',
          items: 'id',
          apiCalls: 'name',
        }
      );

      return update(state, {
        [id]: {
          $set: dashboardObject,
        },
      });
    }
    case types.FETCH_DASHBOARD_SCHEMA_FAILED: {
      const { id } = action;

      return update(state, {
        [id]: {
          $set: {
            downloadFailed: true,
          },
        },
      });
    }
    case types.ADD_DASHBOARD_ROW_SUCCESS: {
      const { row } = action;
      row.columns = row.columns || {};
      return update(state, {
        [action.id]: {
          rows: {
            [row.id]: { $set: row },
          },
        },
      });
    }
    case types.REMOVE_DASHBOARD_ROW_SUCCESS: {
      const { rowId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            $unset: [rowId],
          },
        },
      });
    }
    case types.ADD_DASHBOARD_COLUMN_SUCCESS: {
      const { column, rowId } = action;
      column.items = column.items || [];
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [column.id]: { $set: column },
              },
            },
          },
        },
      });
    }
    case types.REMOVE_DASHBOARD_COLUMN_SUCCESS: {
      const { rowId, columnId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                $unset: [columnId],
              },
            },
          },
        },
      });
    }
    case types.ADD_DASHBOARD_ITEM_SUCCESS: {
      const { rowId, columnId, item, chart } = action;
      item.chart = chart;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  items: {
                    [item.id]: {
                      $set: item,
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
    case types.REMOVE_DASHBOARD_ITEM_SUCCESS: {
      const { rowId, columnId, itemId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  items: {
                    $unset: [itemId],
                  },
                },
              },
            },
          },
        },
      });
    }

    case types.SET_DASHBOARD_COLUMN_OPTION: {
      const { rowId, columnId, name, value } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  options: {
                    [name]: {
                      $set: value,
                    },
                  },
                  unsaved: {
                    $set: true,
                  },
                },
              },
            },
          },
        },
      });
    }

    case types.SET_DASHBOARD_COLUMN_OPTIONS: {
      const { rowId, columnId, value } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  options: {
                    $set: value,
                  },
                  unsaved: {
                    $set: true,
                  },
                },
              },
            },
          },
        },
      });
    }
    case types.SET_DASHBOARD_ITEM_OPTIONS: {
      const { rowId, columnId, itemId, value } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  items: {
                    [itemId]: {
                      options: {
                        $set: value,
                      },
                      unsaved: {
                        $set: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
    case types.DASHBOARD_ITEM_OPTIONS_SAVED_SUCCESS: {
      const { rowId, columnId, itemId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  items: {
                    [itemId]: {
                      unsaved: {
                        $set: false,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
    }
    case types.SET_DASHBOARD_ROW_OPTIONS: {
      const { rowId, value } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              options: {
                $set: value,
              },
              unsaved: {
                $set: true,
              },
            },
          },
        },
      });
    }

    case types.DASHBOARD_COLUMN_OPTIONS_SAVED_SUCCESS: {
      const { rowId, columnId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              columns: {
                [columnId]: {
                  unsaved: {
                    $set: false,
                  },
                },
              },
            },
          },
        },
      });
    }

    case types.DASHBOARD_ROW_OPTIONS_SAVED_SUCCESS: {
      const { rowId } = action;
      return update(state, {
        [action.id]: {
          rows: {
            [rowId]: {
              unsaved: {
                $set: false,
              },
            },
          },
        },
      });
    }

    case types.SET_DASHBOARD_OPTIONS: {
      const { value } = action;
      return update(state, {
        [action.id]: {
          options: {
            $set: value,
          },
          unsaved: {
            $set: true,
          },
        },
      });
    }
    case types.DASHBOARD_OPTIONS_SAVED_SUCCESS: {
      return update(state, {
        [action.id]: {
          unsaved: {
            $set: false,
          },
        },
      });
    }

    default:
      return state;
  }
}
