import React from 'react';
import {
  Form,
  Input,
  Label,
  Checkbox,
  Dropdown,
  Header,
} from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';

import Date from '../common/fields/Date';
import DaySelector from './DaySelector';
import { pluralize, getOrdinal } from '../../utils/Pluralize';
import { mapToOptions } from '../../utils/React';
import {
  END_TYPES,
  REPEAT_TYPES,
  WEEKDAYS,
} from '../../utils/ExecutionPlanLaunchConsts';
import ApplicableDate from './ApplicableDate';

const LaunchOnScheduleForm = ({
  formData,
  handleFormDataChange,
  setNewRepeatDays,
}) => {
  const {
    every,
    occurrences,
    endDate,
    endType,
    repeatDays,
    repeatType,
    startDate,
    applicableDate,
    applicableDateTypeId,
  } = formData;

  const handleRepeatDayChange = (e, { name, index }) => {
    const { repeatDays } = formData;
    const currentIndex = repeatDays.indexOf(index);
    currentIndex === -1
      ? repeatDays.push(index)
      : repeatDays.splice(currentIndex, 1);

    if (_.isEmpty(repeatDays)) {
      const currentDay = moment(startDate).locale('en-gb').weekday();
      const currentDayIndex = Math.pow(2, currentDay);
      handleFormDataChange(null, { name, value: [currentDayIndex] });
    } else {
      handleFormDataChange(null, { name, value: [...repeatDays] });
    }
  };

  const handleStartDateChange = (e, { name, value }) => {
    setNewRepeatDays(name, value);
  };

  const renderRepeatEvery = () => {
    switch (repeatType) {
      case REPEAT_TYPES.WEEK: {
        return (
          <DaySelector
            label="Repeat on"
            weekdays={WEEKDAYS}
            selectedDays={repeatDays}
            handleDayChanged={handleRepeatDayChange}
          />
        );
      }
      case REPEAT_TYPES.MONTH: {
        const currentDay = moment(startDate).date();
        return (
          <Header as="h4">{`(Launch with occur on ${currentDay}${getOrdinal(
            currentDay
          )} of each month) `}</Header>
        );
      }
      default: {
        null;
      }
    }
  };

  return (
    <React.Fragment>
      <Form.Field>
        <label>Start date</label>
        <Date
          value={startDate}
          onChange={handleStartDateChange}
          name="startDate"
          minDate={moment()}
          isClearable={true}
        />
        <label>Repeat every</label>
      </Form.Field>
      <Form.Field>
        <Input labelPosition="right">
          <Input
            name="every"
            type="number"
            value={every}
            onChange={handleFormDataChange}
            min={1}
            label={
              <Dropdown
                options={mapToOptions(REPEAT_TYPES)}
                onChange={handleFormDataChange}
                value={repeatType}
                name="repeatType"
              />
            }
            labelPosition="right"
          />
        </Input>
      </Form.Field>
      <Form.Field>{renderRepeatEvery()}</Form.Field>
      <Form.Field>
        <label>Ends</label>
        <Form.Field>
          <Checkbox
            radio
            label="Never"
            name="endType"
            value={END_TYPES.NEVER}
            checked={endType === END_TYPES.NEVER}
            onChange={handleFormDataChange}
          />
        </Form.Field>

        <Form.Field>
          <Checkbox
            radio
            label="On"
            name="endType"
            value={END_TYPES.DATE}
            checked={endType === END_TYPES.DATE}
            onChange={handleFormDataChange}
          />
        </Form.Field>
        <Form.Field>
          {endType === END_TYPES.DATE && (
            <Form.Field>
              <Date
                value={endDate}
                onChange={handleFormDataChange}
                name="endDate"
                minDate={moment(startDate)}
                isClearable={true}
              />
            </Form.Field>
          )}
        </Form.Field>

        <Form.Field>
          <Checkbox
            radio
            label="After"
            name="endType"
            value={END_TYPES.OCCURRENCES}
            checked={endType === END_TYPES.OCCURRENCES}
            onChange={handleFormDataChange}
          />
        </Form.Field>
        <Form.Field>
          {endType === END_TYPES.OCCURRENCES && (
            <Form.Field>
              <Input labelPosition="right">
                <Input
                  name="occurrences"
                  type="number"
                  value={occurrences}
                  onChange={handleFormDataChange}
                  min={1}
                />
                <Label>
                  {pluralize('occurence', occurrences, false, true)}
                </Label>
              </Input>
            </Form.Field>
          )}
        </Form.Field>
      </Form.Field>
      <Form.Field>
        <Date
          label="Due Date"
          value={formData.dueDate}
          onChange={handleFormDataChange}
          name="dueDate"
          minDate={moment()}
          isClearable={true}
        />
      </Form.Field>
      <Form.Field>
        <ApplicableDate
          applicableDate={applicableDate}
          applicableDateTypeId={applicableDateTypeId}
          handleFormDataChange={handleFormDataChange}
        />
      </Form.Field>
    </React.Fragment>
  );
};

LaunchOnScheduleForm.propTypes = {
  formData: PropTypes.object,
  handleFormDataChange: PropTypes.func,
  setNewRepeatDays: PropTypes.func,
};

export default LaunchOnScheduleForm;
