import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const ExecutionPlanBreadcrumbs = () => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section as={Link} to={`/executionPlans`} link>
        Programme Plans
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Scope Programme Plan</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default ExecutionPlanBreadcrumbs;
