import {
  PaginationProps,
  Pagination as SemanticPagination,
} from 'semantic-ui-react';

const Pagination = (props: PaginationProps) => {
  const { totalPages } = props;
  return (
    <>
      {typeof totalPages === 'number' && totalPages > 1 && (
        <SemanticPagination {...props} />
      )}
    </>
  );
};

export default Pagination;
