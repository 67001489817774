import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Table } from 'semantic-ui-react';

import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';

import TicketDeadlinesRow from './TicketDeadlinesRow';
import TicketAddDeadlineForm from './TicketAddDeadlineForm';

const TicketDeadlines = ({ currentTicket, currentStatusOverdue }) => {
  const { deadlines, structuralNodeId } = currentTicket;

  return (
    <React.Fragment>
      {deadlines.length ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Workflow Status</Table.HeaderCell>
              <Table.HeaderCell>Deadline Date</Table.HeaderCell>
              <Table.HeaderCell>Complete</Table.HeaderCell>
              <CheckPermission
                permissionType={permissionTypes.CREATE}
                structuralNodeId={structuralNodeId}
              >
                <Table.HeaderCell>Actions</Table.HeaderCell>{' '}
              </CheckPermission>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(deadlines, (deadline) => (
              <TicketDeadlinesRow
                key={deadline.workflowStatusId}
                currentTicketStatusNodeId={currentTicket.statusNodeId}
                status={status}
                deadline={deadline}
                currentStatusOverdue={!!currentStatusOverdue}
                structuralNodeId={structuralNodeId}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <p>No deadlines have been added</p>
      )}
      <CheckPermission
        permissionType={permissionTypes.CREATE}
        structuralNodeId={structuralNodeId}
      >
        <TicketAddDeadlineForm ticket={currentTicket} />
      </CheckPermission>
    </React.Fragment>
  );
};

TicketDeadlines.propTypes = {
  currentTicket: PropTypes.object,
  workflowActions: PropTypes.object,
  workflowStatusTypes: PropTypes.array,
  currentStatusOverdue: PropTypes.bool,
  workflow: PropTypes.object,
};

export default TicketDeadlines;
