import { Entity, EntityEntry } from '../types';
import apiTools from './apiTools';

// lo
export interface EntityFormData {
  label?: string;
  entries?: EntityEntry[];
  deleted?: boolean;
}

interface EntityCreationTask {
  type: 'LAUNCH_EXECUTION';
  executionPlanId: number;
  dueDate: string;
  partyId: string;
  granularPermissionTypeId?: string;
}

export interface CreateEntityData {
  entity: Pick<Entity, 'label' | 'entityTypeId'>;
  entityEntries?: Pick<EntityEntry, 'entityTypeFieldId' | 'value'>[];
  tasks?: EntityCreationTask[];
}

class EntityApi {
  static getEntities(filter: any = {}) {
    return apiTools
      .get('Entities', {
        filter: {
          ...filter,
          where: {
            ...(filter.where ? filter.where : {}),
            deleted: false,
          },
        },
      })
      .then((response) => {
        return response.data as Entity[];
      });
  }
  static getEntity(id: string, filter = {}): Promise<Entity> {
    return apiTools
      .get(`Entities/${id}`, {
        filter: {
          ...filter,
          include: ['entries', 'entityType'],
        },
      })
      .then((response) => {
        return response.data as Entity[];
      });
  }

  static createEntity(
    partyId: string,
    data: CreateEntityData
  ): Entity & { taskOutput: { type: string; executionId: number }[] } {
    return apiTools
      .post(`Parties/${partyId}/addEntity`, data)
      .then((response) => {
        return response.data as Entity & {
          taskOutput: { type: string; executionId: number }[];
        };
      });
  }

  static bulkQueueCreateEntities(
    partyId: string,
    data: {
      rows: CreateEntityData[];
      queueId?: string;
    }
  ): Promise<{
    queueId: string;
    itemCount: number;
  }> {
    return apiTools
      .post(`Parties/${partyId}/bulkAddEntities`, data)
      .then((response) => {
        return response.data;
      });
  }

  static updateEntity(entity: Entity, data: EntityFormData): Promise<Entity> {
    return apiTools
      .put(`Entities/${entity.id}`, { ...entity, ...data })
      .then((response) => {
        console.log(response);
        return response.data as Entity[];
      });
  }

  static getEntityType(type: string, filter = {}) {
    return apiTools
      .get(`EntityTypes/${type}`, {
        filter: {
          ...filter,
          include: ['fields'],
        },
      })
      .then((response) => {
        return response.data as Entity[];
      });
  }

  static getEntityTypes(filter = {}) {
    return apiTools
      .get(`EntityTypes`, {
        filter: {
          ...filter,
          include: ['fields'],
        },
      })
      .then((response) => {
        return response.data as Entity[];
      });
  }
}

export default EntityApi;
