import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

import ActionButtons from './../common/ActionButtons';
import {
  comparisonValuesThresholdModal,
  valueTypeValuesThresholdModal,
} from '../../constants/config';

const ThresholdListItem = ({
  threshold,
  showConfirmDialog,
  removeThreshold,
  editThreshold,
}) => {
  const {
    dataFieldReference,
    comparison,
    valueType,
    lowValue,
    highValue,
  } = threshold;

  const deleteThresholdClicked = () => {
    showConfirmDialog(
      `Are you sure you want to delete ${dataFieldReference} threshold?`,
      deleteThresholdOnConfirm
    );
  };
  const deleteThresholdOnConfirm = () => {
    const { partyId, id } = threshold;
    removeThreshold(id, partyId).then(deleteThresholdOnConfirmCallback);
  };
  const deleteThresholdOnConfirmCallback = () => {
    toastr.success(`Threshold ${dataFieldReference} is removed`);
  };
  const editThresholdClicked = () => {
    editThreshold(threshold);
  };
  return (
    <Table.Row>
      <Table.Cell width={2}>{dataFieldReference}</Table.Cell>
      <Table.Cell width={2}>
        {_.find(comparisonValuesThresholdModal, { id: comparison }).label}
      </Table.Cell>
      <Table.Cell width={2}>
        {_.find(valueTypeValuesThresholdModal, { id: valueType }).label}
      </Table.Cell>
      <Table.Cell width={2}>{lowValue}</Table.Cell>
      <Table.Cell width={2}>{highValue}</Table.Cell>
      <Table.Cell width={1} textAlign="center">
        <ActionButtons
          className="u-show-on-hover__target"
          deleteClicked={deleteThresholdClicked}
          editClicked={editThresholdClicked}
        />
      </Table.Cell>
    </Table.Row>
  );
};

ThresholdListItem.propTypes = {
  threshold: PropTypes.object.isRequired,
  showConfirmDialog: PropTypes.func,
  removeThreshold: PropTypes.func,
  editThreshold: PropTypes.func,
};

export default ThresholdListItem;
