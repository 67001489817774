import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import toastr from 'toastr';
import { connect } from 'react-redux';
import {
  Accordion,
  Confirm,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Loader,
  Rail,
  Segment,
  Icon,
  Form,
  Button,
} from 'semantic-ui-react';

import { MODAL_COMPONENTS } from '../common/ModalRoot';
import FormBuilder from '../common/FormBuilder';
import ErrorBoundary from './../common/ErrorBoundary';
import ViewSelector from '../common/ViewSelector';

import { ProgrammeFieldManager } from './programmeFieldManager/ProgrammeFieldManager';
import ProgrammeDetailsForm from './ProgrammeDetailsForm';
import ActivitiesList from '../activity/ActivitiesList';
import ActivitySetBuilder from '../activitySet/ActivitySetBuilder';
import CategoryBuilder from '../activitySet/CategoryBuilder';
import { extractFunctions } from '../../utils/React';
import * as storeTypes from '../../constants/storeTypes';
import ProgrammeWrapper from './Programme';
import ActivityWrapper from '../activity/Activity';
import * as programmeStateActions from '../../actions/programmeActions';
import './CreateProgrammePage.scss';

function mapStateToProps(state, props) {
  return {
    activities: state.activities,
    tags: state.tags,
    parties: state.parties.list,
    activityStatuses: _.keyBy(state.constants.activityStatuses, 'id'),
    programmeStatuses: state.constants.programmeStatuses,
    programme: props.match.params.id
      ? state.currentProgramme
      : state.newProgramme,
    programmeType: props.match.params.id ? storeTypes.CURRENT : storeTypes.NEW,
    activityFieldTypes: state.constants.activityFieldTypes,
  };
}

@ActivityWrapper
@ProgrammeWrapper
@connect(mapStateToProps)
class CreateProgrammePage extends Component {
  static propTypes = {
    programmeStateActions: PropTypes.object.isRequired,
    programmeActions: PropTypes.object.isRequired,
    programme: PropTypes.object.isRequired,
    activities: PropTypes.array.isRequired,
    activityStatuses: PropTypes.array.isRequired,
    programmeStatuses: PropTypes.array.isRequired,
    parties: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    match: PropTypes.object,
    query: PropTypes.object,
    programmeType: PropTypes.string,
    uiActions: PropTypes.object.isRequired,
    activityStateActions: PropTypes.object.isRequired,
    activityFieldTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.views = [
      {
        name: 'SETS',
        label: 'Question Sets',
      },
      {
        name: 'CATEGORIES',
        label: 'Categories',
      },
      {
        name: 'ACTIVITIES',
        label: 'Activities',
      },
      {
        name: 'FIELDS',
        label: 'Fields',
      },
      {
        name: 'FIELD_MANAGER',
        label: 'Field Manager',
      },
    ];

    this.state = {
      selectedActivitySetId: -1,
      selectedCategoryId: -1,
      confirmOpen: false,
      confirmCallback: () => {},
      saving: false,
      loading: false,
      loadingActivities: false,
      showDetails: !this.props.match.params.id,
      currentView: this.views[0],
      filters: {
        label: '',
        ref: '',
        activityStatusId: 0,
        structuralNodeId: 0,
        primaryTag: false,
        tags: [],
      },
    };

    this.addCategory = this.addCategory.bind(this);
  }

  componentWillMount() {
    if (this.props.match.params.id || this.props.query.clone) {
      this.setState({ loading: true });

      this.props.programmeStateActions
        .loadProgramme(
          this.props.match.params.id || this.props.query.clone,
          this.props.programmeType,
          true
        )
        .then(() => {
          this.setState({ loading: false });
        });
    }
  }

  resetFilter = () => {
    this.setState(
      {
        filters: {
          label: '',
          ref: '',
          activityStatusId: 0,
          structuralNodeId: 0,
          primaryTag: false,
          tags: [],
        },
      },
      () => {
        this.loadActivities();
      }
    );
  };
  updateFilterValue = (event, { name, value }, reload = false) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (reload) this.loadActivities();
      }
    );
  };

  loadActivities = () => {
    this.setState({
      loadingActivities: true,
    });
    this.props.activityStateActions
      .loadActivities(this.state.filters)
      .then(() => {
        this.setState({ loadingActivities: false });
      });
  };

  programmeDetailsOnChange = (event, data) => {
    this.props.programmeStateActions.updateProgrammeValue(
      this.props.programmeType,
      data.name,
      data.value
    );
  };

  addActivitySet = () => {
    this.props.programmeStateActions.addActivitySet(this.props.programmeType);
  };

  updateActivitySet = (activityIndex, data) => {
    this.props.programmeStateActions.updateActivitySet(
      this.props.programmeType,
      activityIndex,
      data.name,
      data.value
    );
  };

  updateCategory = (category, { name, value }) => {
    this.props.programmeStateActions.updateCategory(
      this.props.programmeType,
      category,
      name,
      value
    );
  };

  removeCategory = (category) => {
    this.setState({
      selectedCategoryId: -1,
    });
    this.props.programmeActions.removeCategory(
      this.props.programmeType,
      category
    );
  };

  addCategory() {
    this.props.programmeStateActions.addCategory(this.props.programmeType);
  }

  selectActivitySet = (activitySet) => {
    this.setState({
      selectedActivitySetId: activitySet.uid,
    });
  };

  selectCategory = (categoryId) => {
    this.setState({
      selectedCategoryId: categoryId,
    });
  };

  setActivitySetCategory = (
    activitySet,
    categoryId = this.state.selectedCategoryId
  ) => {
    this.props.programmeStateActions.addActivitySetToCategory(
      this.props.programmeType,
      categoryId,
      activitySet
    );
  };

  addActivity = (activity) => {
    this.props.programmeStateActions.addActivityToSet(
      this.props.programmeType,
      this.state.selectedActivitySetId,
      activity
    );
  };
  removeActivityFromSet = (activitySetIndex, activity) => {
    this.props.programmeStateActions.removeActivityFromSet(
      this.props.programmeType,
      activitySetIndex,
      activity
    );
  };

  moveActivitySet = (activitySet, increment) => {
    this.setState({
      selectedActivitySetId: null,
    });
    this.props.programmeStateActions.moveActivitySet(
      this.props.programmeType,
      activitySet,
      increment
    );
  };

  removeActivitySet = (activitySetIndex, confirm = true) => {
    if (confirm) {
      this.setState({
        confirmOpen: true,
        confirmCallback: this.removeActivitySet,
        confirmCallbackProps: [activitySetIndex, false],
        confirmMessage: 'Are you sure you want to remove this Question set?',
      });
    } else {
      this.props.programmeStateActions.removeActivitySet(
        this.props.programmeType,
        activitySetIndex
      );
    }
  };

  moveActivityInSet = (activitySetIndex, activity, increment) => {
    this.props.programmeStateActions.moveActivityInSet(
      this.props.programmeType,
      activitySetIndex,
      activity,
      increment
    );
  };

  handleCancel = () => {
    this.setState({
      confirmOpen: false,
    });
  };

  cloneActivity = (activity, activitySet) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {
        activityId: activity.id,
        cloneActivity: true,
      },
    });
    this.props.uiActions.setCreateActivityAction(
      programmeStateActions.addActivityToSet(
        this.props.programmeType,
        activitySet.uid,
        {}
      )
    );
  };

  createNewActivity = (activitySet) => {
    const { uiActions } = this.props;
    uiActions.showModal({
      modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
      modalProps: {},
    });
    this.props.uiActions.setCreateActivityAction(
      programmeStateActions.addActivityToSet(
        this.props.programmeType,
        activitySet.uid,
        {}
      )
    );
  };

  saveProgramme = () => {
    this.setState({ saving: true });
    this.props.programmeStateActions
      .saveStateProgramme(this.props.programmeType)
      .then(({ programme }) => {
        switch (this.props.programmeType) {
          case storeTypes.CURRENT:
            toastr.success('Programme saved.');
            break;
          case storeTypes.NEW:
            toastr.success('New Programme created.');
            this.props.programmeActions.gotoProgramme(programme);
            break;
        }
        this.setState({ saving: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ saving: false });
      });
  };

  handleViewMenuChange = (event, { view }) => {
    this.setState({ currentView: view });
  };

  filterActivities = () => {
    const { programme, activities } = this.props;
    let usedActivities = [];
    _.forEach(programme.activitySets, (as) => {
      usedActivities = [...usedActivities, ...as.activities.map((a) => a.id)];
    });
    const filteredActivities = activities.filter((activity) => {
      return usedActivities.indexOf(activity.id) === -1;
    });
    return _.sortBy(filteredActivities, ['sort']);
  };

  addField = (event, status, data) => {
    const {
      activityFieldTypes,
      programmeStateActions,
      programmeType,
    } = this.props;
    const fieldType = _.find(
      activityFieldTypes,
      (fieldType) => fieldType.id === data.value
    );
    programmeStateActions.addProgrammeField(programmeType, status, fieldType);
  };

  onFieldChange = (fieldIndex, status, newValue) => {
    const { programmeStateActions, programmeType } = this.props;
    if (_.isEmpty(newValue)) {
      this.deleteField(fieldIndex, status);
      return;
    }
    programmeStateActions.updateProgrammeField(
      programmeType,
      status,
      fieldIndex,
      newValue
    );
  };

  deleteField = (fieldIndex, status) => {
    const { programmeType, programmeStateActions } = this.props;
    programmeStateActions.removeProgrammeField(
      programmeType,
      status,
      fieldIndex
    );
  };

  render() {
    const {
      programme,
      programmeType,
      tags,
      parties,
      activityStatuses,
      activityFieldTypes,
    } = this.props;
    const {
      currentView,
      saving,
      loading,
      filters,
      loadingActivities,
      showDetails,
    } = this.state;
    const programmeFields = programme ? programme.fields : [];

    return (
      <Container fluid>
        <Confirm
          open={this.state.confirmOpen}
          content={this.state.confirmMessage}
          onCancel={this.handleCancel}
          onConfirm={() => {
            this.setState({
              confirmOpen: false,
            });
            this.state.confirmCallback.apply(
              this,
              this.state.confirmCallbackProps
            );
          }}
        />

        <Dimmer active={loading} inverted>
          <Loader disabled={!loading} />
        </Dimmer>

        <Grid columns={1}>
          <Grid.Column
            className={currentView.name === 'SETS' ? 'question-sets' : ''}
          >
            <Segment basic>
              <Header>
                {programmeType === storeTypes.CURRENT
                  ? 'Edit Programme'
                  : 'Create New Programme'}
              </Header>
              <Accordion>
                <Accordion.Title
                  active={showDetails}
                  onClick={() => {
                    this.setState({
                      showDetails: !showDetails,
                    });
                  }}
                >
                  <Icon name="dropdown" />
                  Details
                </Accordion.Title>
                <Accordion.Content active={showDetails}>
                  <ProgrammeDetailsForm
                    onChange={this.programmeDetailsOnChange}
                    {...this.props}
                    onSave={this.saveProgramme}
                    saving={saving}
                  />
                </Accordion.Content>
              </Accordion>
              <div>
                <Form.Field>
                  {programmeType === storeTypes.CURRENT &&
                    (programme.edited || programme.editedOptions) && (
                      <Button
                        type="submit"
                        onClick={this.saveProgramme}
                        loading={saving}
                        disabled={
                          saving ||
                          (!programme.edited && !programme.editedOptions)
                        }
                        inverted
                      >
                        Save Changes
                      </Button>
                    )}

                  {programmeType !== storeTypes.CURRENT && (
                    <Button
                      type="submit"
                      onClick={this.saveProgramme}
                      loading={saving}
                      disabled={saving}
                      inverted
                    >
                      Submit
                    </Button>
                  )}
                </Form.Field>
              </div>
              <ViewSelector
                views={this.views}
                currentView={currentView}
                onChange={this.handleViewMenuChange}
              />

              <Divider />
              <React.Fragment>
                {
                  {
                    SETS: (
                      <React.Fragment>
                        <ActivitySetBuilder
                          programme={programme}
                          {...extractFunctions(this)}
                          {...this.state}
                        />
                        <Rail
                          position="right"
                          close="very"
                          className="fixed-rail"
                        >
                          <Dimmer active={loadingActivities} inverted>
                            <Loader disabled={!loadingActivities} />
                          </Dimmer>
                          <ActivitiesList
                            activities={this.filterActivities()}
                            layout="COMPACT"
                            addActivity={this.addActivity}
                            updateFilterValue={this.updateFilterValue}
                            loadActivities={this.loadActivities}
                            resetFilter={this.resetFilter}
                            tags={tags}
                            parties={parties}
                            filters={filters}
                            activityStatuses={activityStatuses}
                          />
                        </Rail>
                      </React.Fragment>
                    ),
                    ACTIVITIES: (
                      <React.Fragment>
                        <ActivitySetBuilder
                          programme={programme}
                          layout="ACTIVITIES"
                          {...extractFunctions(this)}
                          {...this.state}
                        />
                      </React.Fragment>
                    ),
                    CATEGORIES: (
                      <CategoryBuilder
                        programme={programme}
                        {...this.state}
                        {...extractFunctions(this)}
                      />
                    ),
                    FIELDS: (
                      <React.Fragment>
                        <FormBuilder
                          activityFieldTypes={activityFieldTypes}
                          addField={(event, data) =>
                            this.addField(event, status, data)
                          }
                          fields={programmeFields}
                          onFieldChange={(fieldIndex, newValue) =>
                            this.onFieldChange(fieldIndex, status, newValue)
                          }
                        />
                      </React.Fragment>
                    ),
                    FIELD_MANAGER: (
                      <Segment className="programme-field-manager-container">
                        <ProgrammeFieldManager programme={programme} />
                      </Segment>
                    ),
                  }[currentView.name]
                }
              </React.Fragment>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default ErrorBoundary(CreateProgrammePage);

// export default ProgrammeWrapper(connect(mapStateToProps)(CreateProgrammePage));
