import React from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import Permission from '../permissions/Permission';
import ManagePermissions from '../permissions/ManagePermissions';
import ExecutionWrapper from './Execution';
import { PermissionContextConsumer } from '../../context/PermissionContext';

const ExecutionPermissionSettings = ({
  currentExecution,
  permissionStateActions,
  history,
}) => {
  const { masterStructuralNodeId, label } = currentExecution;
  return (
    <React.Fragment>
      <Header as="h3">Manage permissions for {label}</Header>
      <ManagePermissions
        permissionsNodeId={masterStructuralNodeId}
        modelStateActions={permissionStateActions}
        history={history}
      />
    </React.Fragment>
  );
};

ExecutionPermissionSettings.propTypes = {
  currentExecution: PropTypes.object,
  permissionStateActions: PropTypes.object,
  history: PropTypes.object,
};

export default Permission(
  PermissionContextConsumer(ExecutionWrapper(ExecutionPermissionSettings))
);
