import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Header, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import AnalyseAndImplementOptions from '../common/fields/options/AnalyseAndImplementOptions';
import Text from '../common/fields/Text';
import LaunchNowForm from '../executionPlan/LaunchNowForm';

const CreateFollowUpProgrammeSettings = (props) => {
  const { setOptionsValue, setValue, options } = props;

  const analyseAndImplement = _.get(
    options,
    'meta.options.AnalyseAndImplement'
  );
  return (
    <Segment>
      <Form>
        <Text
          value={options.label}
          onChange={setValue}
          name="label"
          label="Programme Label"
        />
        <LaunchNowForm
          horizontal={true}
          formData={options}
          handleFormDataChange={setValue}
        />
      </Form>

      {analyseAndImplement && (
        <Segment>
          <Header>Analysis & Implementation</Header>
          <AnalyseAndImplementOptions
            value={analyseAndImplement}
            onChange={(data) => {
              setOptionsValue('AnalyseAndImplement', data);
            }}
          />
        </Segment>
      )}
    </Segment>
  );
};

CreateFollowUpProgrammeSettings.propTypes = {
  setOptionsValue: PropTypes.func,
  setValue: PropTypes.func,
  options: PropTypes.object,
};

export default CreateFollowUpProgrammeSettings;
