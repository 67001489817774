import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Form, Checkbox, Divider, Select } from 'semantic-ui-react';

import { mapToOptions } from '../../utils/React';

import Fields from './fields/Fields';

import FormBuilderFieldType from './FormBuildFieldType';
import OptionsList from './OptionsList';
import SubmitInput from './SubmitInput';
import ActionButtons from './ActionButtons';

import FieldOptions from '../common/fields/options/FieldOptions';
import JSONBuilder from '../common/fields/JSONBuilder';
import { ModelSearch } from '../common/search/ModelSearch';
import { useState } from 'react';
import FormField from './FormField';

const FormBuilderItem = ({
  field,
  onFieldChange,
  activityFieldTypes,
  layout,
  workflowStatusInputTypes,
}) => {
  const [showDataFields, setShowDataFields] = useState(false);
  const {
    originalIndex,
    label,
    fieldKey,
    fieldType,
    options = {},
    dataFieldReference,
    dataField,
    required,
    displayShortLabel,
    activityFieldTypeId,
    workflowStatusInputTypeId,
    displayLogic,
    defaultValue,
    thresholdFieldRef,
    description,
  } = field;

  let FieldOption;

  if (FieldOptions[fieldType.name]) FieldOption = FieldOptions[fieldType.name];

  const handleFieldChange = (e, { name, value, checked }) => {
    const newValue = { ...field, [name]: value || checked };
    console.log('handleFieldChange', newValue);
    delete newValue.originalIndex;
    onFieldChange(originalIndex, newValue);
  };
  const handleFieldTypeChange = (fieldTypeId) => {
    if (activityFieldTypeId === fieldTypeId) return;
    const newFieldType = _.find(activityFieldTypes, { id: fieldTypeId });
    const newValue = {
      ...field,
      activityFieldTypeId: newFieldType.id,
      fieldType: newFieldType,
      options: newFieldType.requiresOptions ? options : [],
    };
    delete newValue.originalIndex;
    onFieldChange(originalIndex, newValue);
  };
  const handleChangeOptions = (optionsValue) => {
    const newValue = { ...field, options: optionsValue };
    onFieldChange(originalIndex, newValue);
  };
  const addOption = (event, data) => {
    if (data.value === '') return;
    const newValue = {
      ...field,
      options: {
        values: options.values ? [...options.values, data.value] : [data.value],
      },
    };
    delete newValue.originalIndex;
    onFieldChange(originalIndex, newValue);
  };

  const removeOption = (optionIndex) => {
    const newValue = {
      ...field,
      options: {
        values: options.values.filter((option, index) => index != optionIndex),
      },
    };
    delete newValue.originalIndex;
    onFieldChange(originalIndex, newValue);
  };

  const changeSort = (increment) => {
    const { sort } = field;
    const newValue = {
      ...field,
      sort: sort + increment,
    };
    delete newValue.originalIndex;
    onFieldChange(originalIndex, newValue);
  };
  const deleteField = () => {
    onFieldChange(originalIndex, {});
  };

  const statusTypes = _.map(workflowStatusInputTypes, ({ id }) => {
    const lowerCaseName = _.lowerCase(id);
    const name = _.upperFirst(lowerCaseName);
    return { id, name };
  });
  const statusTypeOptions = mapToOptions(statusTypes, 'name');

  if (layout === 'WIDE') {
    return (
      <React.Fragment key={originalIndex}>
        <Form>
          <Form.Group widths="equal">
            <Fields.Text
              // label="Field Label"
              placeholder="Field Label"
              value={label || ''}
              name="label"
              onChange={handleFieldChange}
            />
            <Form.Field>
              {/*<label>Data Field</label>*/}
              <FormBuilderFieldType
                value={field}
                activityFieldTypes={activityFieldTypes}
                changeFieldType={handleFieldTypeChange}
              />
            </Form.Field>
            {fieldType.requiresOptions ? (
              <Form.Field>
                {/*<label>Options</label>*/}
                {FieldOption ? (
                  <FieldOption value={options} onChange={handleChangeOptions} />
                ) : (
                  <React.Fragment>
                    <OptionsList
                      value={options.values || []}
                      onRemove={removeOption}
                      editable={true}
                    />
                    <SubmitInput
                      placeholder="Add Option"
                      onSubmit={addOption}
                      name="option"
                    />
                  </React.Fragment>
                )}
              </Form.Field>
            ) : null}

            <Fields.Text
              // label="Field Key"
              placeholder="Field Key"
              value={fieldKey || ''}
              name="fieldKey"
              onChange={handleFieldChange}
            />

            <Form.Field width={5}>
              <label>Threshold</label>
              <ModelSearch
                name="thresholdFieldRef"
                modelName="ActivityField"
                value={thresholdFieldRef}
                onChange={handleFieldChange}
                valueProperty="fieldKey"
                refProperty="fieldKey"
                // include any other necessary props for ModelSearch
              />
            </Form.Field>

            <Fields.Text
              // label="Data Field Reference"
              placeholder="Data Field Reference"
              name="dataFieldReference"
              value={dataFieldReference || ''}
              onChange={handleFieldChange}
            />
            <Fields.Text
              // label="Display Short Label"
              placeholder="Display Short Label"
              name="displayShortLabel"
              value={displayShortLabel || ''}
              onChange={handleFieldChange}
            />
            <Form.Field width={4}>
              <label>Assign field to workflow status</label>
              <Form.Field
                control={Select}
                options={statusTypeOptions}
                placeholder="Workflow Status Type"
                onChange={handleFieldChange}
                value={workflowStatusInputTypeId}
                name="workflowStatusInputTypeId"
              />
            </Form.Field>
            <Form.Field>
              <label>Data Field</label>
              <Form.Input
                name="dataField"
                control={Checkbox}
                checked={dataField || false}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Required</label>
              <Form.Input
                name="required"
                control={Checkbox}
                checked={required || false}
                onChange={handleFieldChange}
              />
            </Form.Field>

            <Form.Field>
              {/*<label>Actions</label>*/}
              <ActionButtons
                upClicked={() => changeSort(-1)}
                downClicked={() => changeSort(1)}
                deleteClicked={deleteField}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {/*<Divider />*/}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment key={originalIndex}>
      <Form>
        <Form.Group widths="equal">
          <Fields.Text
            label="Field Label"
            value={label || ''}
            name="label"
            onChange={handleFieldChange}
            width={4}
          />
          <Fields.Text
            label="Field Key"
            value={fieldKey || ''}
            name="fieldKey"
            onChange={handleFieldChange}
            width={1}
          />
          <Form.Field width={1}>
            <label>Field Type</label>
            <FormBuilderFieldType
              value={field}
              activityFieldTypes={activityFieldTypes}
              changeFieldType={handleFieldTypeChange}
            />
          </Form.Field>
          {fieldType.requiresOptions ? (
            <Form.Field width={2}>
              <label>Options</label>
              {FieldOption ? (
                <FieldOption value={options} onChange={handleChangeOptions} />
              ) : (
                <React.Fragment>
                  <OptionsList
                    value={options.values || []}
                    onRemove={removeOption}
                    editable={true}
                  />
                  <SubmitInput
                    placeholder="Add Option"
                    onSubmit={addOption}
                    name="option"
                  />
                </React.Fragment>
              )}
            </Form.Field>
          ) : null}

          <Form.Field width={2}>
            <label>Actions</label>
            <ActionButtons
              upClicked={() => changeSort(-1)}
              downClicked={() => changeSort(1)}
              deleteClicked={deleteField}
              viewClicked={
                !showDataFields ? () => setShowDataFields(true) : null
              }
              hideClicked={
                showDataFields ? () => setShowDataFields(false) : null
              }
            />
          </Form.Field>
        </Form.Group>

        {showDataFields && (
          <>
            <Form.Group widths="equal">
              <Form.Field width={5}>
                <label>Threshold</label>
                <ModelSearch
                  name="thresholdFieldRef"
                  modelName="ActivityField"
                  value={thresholdFieldRef}
                  onChange={handleFieldChange}
                  valueProperty="fieldKey"
                  refProperty="fieldKey"
                  // include any other necessary props for ModelSearch
                />
              </Form.Field>
              {/* <Fields.Text
                label="Data Field Reference"
                name="dataFieldReference"
                value={dataFieldReference || ''}
                onChange={handleFieldChange}
                width={2}
              /> */}
              <Fields.Text
                label="Display Short Label"
                name="displayShortLabel"
                value={displayShortLabel || ''}
                onChange={handleFieldChange}
                width={6}
              />
              <Form.Field width={4}>
                <label>Assign field to workflow status</label>
                <Form.Field
                  control={Select}
                  options={statusTypeOptions}
                  placeholder="Workflow Status Type"
                  onChange={handleFieldChange}
                  value={workflowStatusInputTypeId}
                  name="workflowStatusInputTypeId"
                />
              </Form.Field>
              <Form.Field width={1}>
                <label>Data Field</label>
                <Form.Input
                  name="dataField"
                  control={Checkbox}
                  checked={dataField || false}
                  onChange={handleFieldChange}
                />
              </Form.Field>
              <Form.Field width={1}>
                <label>Required</label>
                <Form.Input
                  name="required"
                  control={Checkbox}
                  checked={required || false}
                  onChange={handleFieldChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field width={8}>
                <Fields.Text
                  label="Description"
                  value={description || ''}
                  name="description"
                  onChange={handleFieldChange}
                />
                <label>Default Value</label>
                <FormField
                  field={field}
                  value={defaultValue}
                  name="defaultValue"
                  handleFieldDataChange={(e, data) => {
                    console.log('handleFieldDataChange', data);
                    handleFieldChange(e, {
                      name: 'defaultValue',
                      value: data,
                    });
                  }}
                />
              </Form.Field>
              <Form.Field width={8}>
                <label>Display Logic</label>
                <JSONBuilder
                  name="displayLogic"
                  value={displayLogic}
                  onChange={handleFieldChange}
                  allowModeChange={true}
                />
                <label>Options</label>
                <JSONBuilder
                  name="options"
                  value={options}
                  onChange={handleFieldChange}
                  allowModeChange={true}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}
      </Form>

      <Divider />
    </React.Fragment>
  );
};

FormBuilderItem.propTypes = {
  activityFieldTypes: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  layout: PropTypes.string,
  workflowStatusInputTypes: PropTypes.array,
};

export default FormBuilderItem;
