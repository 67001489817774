import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Container, Header, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DashboardContainer from '../dashboard/DashboardContainer';
import { defaultDashboards } from '../../constants/config';
import DetailsExecutionOverview from './DetailsExecutionOverview';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import ExecutionAttachmentList from './ExecutionAttachmentList';
import PrincipalListFromNode from '../permissions/PrincipalListFromNode';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';
import ExecutionFields from './ExecutionFields';
import ExecutionEvents from './ExecutionEvents';

class ExecutionOverview extends Component {
  render() {
    const execution = this.props.execution || this.props.currentExecution;
    const { programme } = execution;
    const dashboardOptions =
      _.get(programme, 'options.overviewDashboard') ||
      defaultDashboards.execution;
    const { fields } = { ...programme };

    if (!execution.id) return null;
    const daysLate = moment().diff(execution.dueDate, 'days');
    const showProgrammeDetails = fields && fields.length > 0;
    const columnsWidth = showProgrammeDetails ? 2 : 1;

    return (
      <Container fluid className="execution-overview">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <DetailsExecutionOverview />
              <DashboardContainer
                id={dashboardOptions.id}
                initialProperties={{
                  'relationFilter.wheres.execution': [{ id: execution.id }],
                }}
                forceChartRefresh={true}
                extraData={[
                  {
                    key: 'executionData',
                    data: {
                      dueDate: execution.dueDate,
                      color: daysLate > 0 ? 'red' : 'green',
                    },
                  },
                ]}
              />
            </Grid.Column>
            <Grid.Column stretched>
              <ExecutionEvents />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid columns={columnsWidth} stackable>
          <Grid.Row>
            {showProgrammeDetails && (
              <Grid.Column>
                <ExecutionFields fields={fields} />
              </Grid.Column>
            )}
            <Grid.Column>
              <ExecutionAttachmentList />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CheckPermission
          structuralNodeId={execution.masterStructuralNodeId}
          permissionType={permissionTypes.CREATE}
        >
          <Header as="h3">
            Users & User groups who can view this programme
          </Header>
          <PrincipalListFromNode
            structuralNodeId={execution.masterStructuralNodeId}
          />
          <Link to={`/execution/${execution.id}/settings/permissions`}>
            Manage Permissions
          </Link>
        </CheckPermission>
      </Container>
    );
  }
}

ExecutionOverview.propTypes = {
  execution: PropTypes.object,
  currentExecution: PropTypes.object,
  executionStateActions: PropTypes.object,
  executionActions: PropTypes.object,
  openFileDialog: PropTypes.func,
};

export default ExecutionContextConsumer(ExecutionOverview);
