import React, { useState } from 'react';
import { Table, Checkbox, Select } from 'semantic-ui-react';
import _ from 'lodash';
import toastr from 'toastr';
import { PermissionStateActions } from './PermissionStateActions'; // Assuming you have this file
import { ExecutionTicketSet, PrincipalRole } from '../../types';
import { useAppSelector } from '../../actions/store';
import { mapToOptions } from '../../utils/React';

interface Props {
  ticketSet: ExecutionTicketSet; // Replace 'any' with the actual type
  principalRole: PrincipalRole; // Replace 'any' with the actual type
  permissionStateActions: PermissionStateActions;
}

const ExecutionViewMatrixSettingsCell = (props: Props) => {
  const [saving, setSaving] = useState(false);

  const roleDefinitions = useAppSelector(
    (state) => state.constants.roleDefinitions
  );

  const switchViewPermisison = (
    isChecked: boolean,
    structuralNodeId: number,
    principalId: string
  ) => {
    console.log({ principalId });
    setSaving(true);
    if (isChecked) {
      props.permissionStateActions
        .addViewPrincipalRole(structuralNodeId, {
          principalId,
          structuralNodeId,
        })
        .then(() => {
          setSaving(false);
        })
        .catch(({ message }) => {
          toastr.error(message);
          setSaving(false);
        });
    } else {
      props.permissionStateActions
        .deleteRole(structuralNodeId, principalId, true)
        .then(() => {
          setSaving(false);
        })
        .catch(({ message }) => {
          toastr.error(message);
          setSaving(false);
        });
    }
  };

  const { ticketSet, principalRole } = props;
  const principalRolePrincipalId = principalRole.principalId;
  const { principalRoles } = ticketSet.permissionsNode;
  const currentPrincipal = principalRoles.find(
    (p) => p.principalId === principalRolePrincipalId
  );

  let roleDefinitionOptions = mapToOptions(
    roleDefinitions.filter((role) => role.id !== 'Superuser'),
    null,
    true
  );

  const handleRoleDefinitionChange = async (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string }
  ) => {
    setSaving(true);
    try {
      if (value === '') {
        await props.permissionStateActions.deleteRole(
          ticketSet.structuralNodeId,
          currentPrincipal.id,
          true
        );
        setSaving(false);
      } else {
        const roleDefinition = roleDefinitions.find(
          (roleDefinition) => roleDefinition.id === value
        );
        if (currentPrincipal) {
          await props.permissionStateActions.updatePrincipalRole(
            ticketSet.structuralNodeId,
            { ...currentPrincipal, roleDefinitionId: value, roleDefinition }
          );
        } else {
          await props.permissionStateActions.addPrincipalRole(
            ticketSet.structuralNodeId,

            {
              principalId: principalRolePrincipalId,
              roleDefinitionId: value,
              structuralNodeId: ticketSet.structuralNodeId,
            }
          );
        }

        setSaving(false);
      }
    } catch (e) {
      toastr.error(e);
      setSaving(false);
    }
  };

  return (
    <Table.Cell textAlign="center">
      <Select
        placeholder="Select role"
        options={roleDefinitionOptions}
        value={currentPrincipal?.roleDefinitionId || ''}
        name="roleDefinitions"
        onChange={handleRoleDefinitionChange}
        selectOnBlur={false}
        clearable
        fluid
        loading={saving}
        disabled={saving}
      />
    </Table.Cell>
  );
};

export default ExecutionViewMatrixSettingsCell;
