import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import ActivityResolutionList from './ActivityResolutionList';
import { Grid, Header } from 'semantic-ui-react';

const ActivityWorkflowSettings = (props) => {
  const { activity, activityResolutionTypes, updateResolution } = props;

  const resolutionLabels = _.get(activity, 'meta.resolutionLabels') || {};

  const workflowKeys = [
    {
      id: 'ASSESSMENT',
      label: 'Assessment',
    },
    {
      id: 'REVIEW',
      label: 'Review',
    },
  ];
  const getResolutions = (statusKey) => {
    const labels = resolutionLabels[statusKey];
    if (!labels) return [];

    const v = _.map(labels, (label, key) => {
      return {
        activityResolutionTypeId: key,
        labelOverride: label.labelOverride || '',
        available: label.available === false ? false : true,
      };
    });
    return v;
  };

  const toggleResolution = (statusKey, resolution, value) => {
    const resolutions = getResolutions(statusKey);
    const currentResolution = _.find(resolutions, {
      activityResolutionTypeId: resolution.id,
    });

    let newValue = value === false ? { available: false } : { available: true };

    if (newValue && currentResolution && currentResolution.available === false)
      newValue = null;

    updateResolution(statusKey, resolution, newValue);
  };

  const handleOnChange = (statusKey, resolution, value) => {
    const newValue = { labelOverride: value.labelOverride };
    updateResolution(statusKey, resolution, newValue);
  };

  return (
    <React.Fragment>
      <Header>Resolutions</Header>
      <Grid columns={workflowKeys.length}>
        {workflowKeys.map((workflowKey) => (
          <Grid.Column key={workflowKey.id}>
            <Header>{workflowKey.label}</Header>
            <ActivityResolutionList
              activityResolutionTypes={activityResolutionTypes}
              editable={true}
              resolutions={getResolutions(workflowKey.id)}
              allowNotes={false}
              showUseDefault={true}
              onChange={(resolution, value) => {
                handleOnChange(workflowKey.id, resolution, value);
              }}
              toggleResolution={(resolution, value) => {
                toggleResolution(workflowKey.id, resolution, value);
              }}
            />
          </Grid.Column>
        ))}
      </Grid>
    </React.Fragment>
  );
};

ActivityWorkflowSettings.propTypes = {
  activity: PropTypes.object,
  activityResolutionTypes: PropTypes.array,
  updateResolution: PropTypes.func,
};

export default ActivityWorkflowSettings;
