import _ from 'lodash';
import * as types from '../constants/actionTypes';
import executionPlanApi from '../api/executionPlanApi';
import partyApi from '../api/partyApi';
import ValidationSchema from '../utils/ValidationSchema';
import {
  checkValidation,
  transformValidation,
  generateValidationOnRecurringScheduleSchema,
} from '../utils/Validation';
import { LAUNCH_TYPES } from '../utils/ExecutionPlanLaunchConsts';

export function loadExecutionPlan(id, name) {
  return function (dispatch) {
    return executionPlanApi.getExecutionPlan(id).then((executionPlan) => {
      return dispatch({
        type: `${types.LOAD_EXECUTION_PLAN_SUCCESS}_${name}`,
        executionPlan,
      });
    });
  };
}
export function loadExecutionPlans() {
  return function (dispatch) {
    return executionPlanApi.getExecutionPlans().then((executionPlans) => {
      return dispatch({
        type: types.LOAD_EXECUTION_PLANS_SUCCESS,
        executionPlans,
      });
    });
  };
}
export function saveExecutionPlan(executionPlan) {
  return function (dispatch) {
    return executionPlanApi
      .saveExecutionPlan(executionPlan)
      .then((executionPlan) => {
        return dispatch({
          type: `${types.SAVE_EXECUTION_PLAN_SUCCESS}_${name}`,
          executionPlan,
        });
      });
  };
}
export function patchExecutionPlan(executionPlan) {
  const name = executionPlan.__type;
  return function (dispatch) {
    return executionPlanApi
      .saveExecutionPlan(executionPlan)
      .then((executionPlan) => {
        return dispatch({
          type: `${types.PATCH_EXECUTION_PLAN_SUCCESS}_${name}`,
          executionPlan,
        });
      });
  };
}
export function updateExecutionPlan(executionPlan) {
  return function (dispatch) {
    return executionPlanApi
      .saveExecutionPlan(executionPlan)
      .then((executionPlan) => {
        return dispatch({
          type: `${types.UPDATE_EXECUTION_PLAN_SUCCESS}_${name}`,
          executionPlan,
        });
      });
  };
}
export function launchNowExecutionPlan(executionPlanId, requestData) {
  return function (dispatch) {
    return executionPlanApi
      .launchNowExecutionPlan(executionPlanId, requestData)
      .then((execution) => {
        return dispatch({
          type: `${types.LAUNCH_NOW_EXECUTION_PLAN_SUCCESS}_${name}`,
          execution,
        });
      });
  };
}
export function addActivitySet(executionPlan, activitySet) {
  return function (dispatch) {
    return executionPlanApi
      .addActivitySet(executionPlan, activitySet.id)
      .then((activitySet) => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_ADD_ACTIVITY_SET_SUCCESS}_${executionPlan.__type}`,
          activitySet,
        });
      });
  };
}

export function removeActivitySet(executionPlan, planActivitySet) {
  return function (dispatch) {
    return executionPlanApi
      .removeActivitySet(executionPlan, planActivitySet.id)
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_REMOVE_ACTIVITY_SET_SUCCESS}_${executionPlan.__type}`,
          planActivitySet,
        });
      });
  };
}

export function activitySetAddRelationship(
  executionPlan,
  planActivitySet,
  relationship
) {
  return function (dispatch) {
    return executionPlanApi
      .activitySetAddRelationship(planActivitySet.id, relationship.id)
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_ACTIVITY_SET_ADD_RELATIONSHIP_SUCCESS}_${executionPlan.__type}`,
          planActivitySet,
          relationship,
        });
      });
  };
}

export function activitySetAddEntity(
  executionPlan,
  planActivitySet,
  entity
) {
  return function (dispatch) {
    return executionPlanApi
      .activitySetAddEntity(planActivitySet.id, entity.id)
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_ACTIVITY_SET_ADD_ENTITY_SUCCESS}_${executionPlan.__type}`,
          planActivitySet,
          entity,
        });
      });
  };
}


export function activitySetRemoveEntity(
  executionPlan,
  planActivitySet,
  entity
) {
  return function (dispatch) {
    return executionPlanApi
      .activitySetRemoveEntity(planActivitySet.id, entity.id)
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_ACTIVITY_SET_REMOVE_ENTITY_SUCCESS}_${executionPlan.__type}`,
          planActivitySet,
          entity,
        });
      });
  };
}


export function activitySetRemoveRelationship(
  executionPlan,
  planActivitySet,
  relationship
) {
  return function (dispatch) {
    return executionPlanApi
      .activitySetRemoveRelationship(planActivitySet.id, relationship.id)
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_PLAN_ACTIVITY_SET_REMOVE_RELATIONSHIP_SUCCESS}_${executionPlan.__type}`,
          planActivitySet,
          relationship,
        });
      });
  };
}

export function updateActivitySetWorkflow(activitySetId, workflowId) {
  return function (dispatch) {
    return executionPlanApi
      .updateActivitySetWorkflow(activitySetId, workflowId)
      .then(() => {
        return dispatch({
          type: `${types.UPDATE_ACTIVITY_SET_WORKFLOW_SUCCESS}`,
          activitySetId,
          workflowId,
        });
      });
  };
}

export function getPartyRelationships(ownerPartyId, assignedPartyId) {
  return function (dispatch) {
    const promise1 = partyApi.getPartyRelationships(ownerPartyId, {
      include: ['fromParty', 'toParty', 'relationshipType'],
    });
    const promise2 = partyApi.getPartyRelationships(
      assignedPartyId,
      {
        include: ['fromParty', 'toParty', 'relationshipType'],
      },
      true
    );

    return Promise.all([promise1, promise2]).then((result) => {
      const partyRelationships = _.flatten(result);
      return dispatch({
        type: `${types.GET_EXECUTION_PLAN_OWNER_PARTY_RELATIONSHIP_SUCCESS}`,
        partyRelationships,
      });
    });
  };
}

export function launchScheduleExecutionPlan(executionPlanId, requestData) {
  return function (dispatch) {
    const validation = checkValidation(
      requestData,
      requestData.launchType === LAUNCH_TYPES.ONCE
        ? ValidationSchema.scheduleExecution
        : generateValidationOnRecurringScheduleSchema(requestData)
    );

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    return executionPlanApi
      .launchScheduleExecutionPlan(executionPlanId, { ...requestData })
      .then((response) => {
        return dispatch({
          type: `${types.LAUNCH_SCHEDULE_EXECUTION_PLAN_SUCCESS}`,
          response,
        });
      });
  };
}

export function deleteScheduledExecutionPlan(
  executionPlanId,
  scheduledLaunchId
) {
  return function (dispatch) {
    return executionPlanApi
      .removeScheduledExecutionPlan(executionPlanId, scheduledLaunchId)
      .then((response) => {
        return dispatch({
          type: `${types.REMOVE_SCHEDULED_EXECUTION_PLAN_SUCCESS}`,
          response,
          scheduledLaunchId,
        });
      });
  };
}

export function assignRelationshipToActivitySetsExectuionPlan(
  party,
  activitySetIds
) {
  return function (dispatch) {
    return executionPlanApi
      .assignRelationshipToActivitySetsExectuionPlan(party.id, activitySetIds)
      .then(() => {
        return dispatch({
          type: `${types.ASSIGN_RELATIONSHIP_TO_ACTIVITY_SETS_EXECUTION_PLAN}`,
          activitySetIds,
          party,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}

export function removeRelationshipToActivitySetsExectuionPlan(
  relationshipId,
  activitySetIds
) {
  return function (dispatch) {
    return executionPlanApi
      .removeRelationshipToActivitySetsExectuionPlan(
        relationshipId,
        activitySetIds
      )
      .then(() => {
        return dispatch({
          type: `${types.REMOVE_RELATIONSHIP_FROM_ACTIVITY_SETS_EXECUTION_PLAN}`,
          relationshipId,
          activitySetIds,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}

export function activityAssignToUser(
  executionPlanId,
  activitySetId,
  principalId
) {
  return function (dispatch) {
    return executionPlanApi
      .assignQuestionSetToPrincipal(executionPlanId, activitySetId, principalId)
      .then(() => {
        return dispatch({
          type: `${types.ASSIGN_ACTIVITY_SET_TO_USER_EXECUTION_PLAN}`,
          activitySetId,
          principalId,
        });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
}
