import React from 'react';

const FollowUpContext = React.createContext();

export const WrapFollowUpContext = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <FollowUpContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </FollowUpContext.Consumer>
      );
    }
  };

export default FollowUpContext;
