import { Icon } from 'semantic-ui-react';
import { Result } from '../../../../api/statusApi';
import { getStatusFlags } from '../../../../utils/data/statusUtils';

type Props = {
  status: Result;
};
export const RAGStatusIcon = ({ status }: Props) => {
  const { hasError, hasException, hasNoResults, hasUnfinished, hasCaution } =
    getStatusFlags(status);

  if (hasError)
    return (
      <Icon
        name="exclamation triangle"
        size="large"
        onClick={() =>
          console.log(
            status.statusResults.map(
              (r) =>
                !Array.isArray(r.logicResult) &&
                typeof r.logicResult.result === 'object' &&
                'error' in r.logicResult.result &&
                r.logicResult.result.error
            )
          )
        }
      />
    );
  if (hasException) return <Icon color="red" name="delete" size="large" />;
  if (hasUnfinished) return <Icon color="purple" name="delete" size="large" />;
  if (hasNoResults) return <Icon color="grey" name="minus" size="large" />;
  if (hasCaution) return <Icon color="orange" name="checkmark" size="large" />;
  return <Icon color="green" name="checkmark" size="large" />;
};

export default RAGStatusIcon;
