import {createContext, Component} from 'react';

const UserContext = createContext();

export const WrapUserContext = (ComponentToWrap) =>
  class WrapContext extends Component {
    render() {
      return (
        <UserContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </UserContext.Consumer>
      );
    }
  };

export default UserContext;
