import React from 'react';
import * as PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';

import { colors } from '../../../constants/config';
import { formatValue } from './../../../utils/FormatUtils';
import * as chartConfigs from './chartConfigBase';

const KpiPieComponent = ({ text, label, value, color, format }) => {
  const chartConfig = Object.assign({}, chartConfigs.gauge, {
    series: [
      {
        data: [
          {
            color: colors[color],
            radius: '84%',
            innerRadius: '72%',
            y: value,
          },
        ],
        dataLabels: {
          format: `<div style="text-align:center; margin-top: -5px; margin-left: 13px;"><span style="font-size:40px;color:${color}">${formatValue(
            text || value,
            format
          )}</span><div style="font-size: 9px;">${label}</div><br/>`,
        },
      },
    ],
  });

  return (
    <div className="component-dashboard-widget chart-component">
      <ReactHighcharts config={chartConfig} />
    </div>
  );
};

KpiPieComponent.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  format: PropTypes.string.isRequired,
  text: PropTypes.number,
  header: PropTypes.string,
  hideHeader: PropTypes.bool,
};

export default KpiPieComponent;
