import { Children, Fragment } from 'react';
import { Comment, Dimmer, Icon } from 'semantic-ui-react';
import Moment from 'react-moment';

import Image from '../common/Image';
import Viewer from '../common/fields/TUIWrapperViewer';
import { Comment as IComment } from '../../types';

interface ConversationCommentProps {
  comment: IComment;
  canEdit: boolean;
  onEdit: (event: any, comment: any) => void;
  onDelete: (event: any, comment: any, deleted: boolean) => void;
  onReply: (event: any, comment: any) => void;
  isCurrentUserCommentAuthor: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
}

const ConversationComment = ({
  comment,
  canEdit,
  isCurrentUserCommentAuthor,
  onEdit,
  onDelete,
  onReply,
  readOnly = false,
  children,
}: ConversationCommentProps) => {
  return (
    <Comment>
      {(comment.deleted && isCurrentUserCommentAuthor) || !comment.deleted ? (
        <Fragment>
          <Image
            renderComponent={Comment.Avatar}
            url={`Parties/${comment.creator.partyId}/icon`}
          />
          <Comment.Content>
            <Dimmer
              active={comment.deleted && canEdit}
              style={{
                pointerEvents: 'none',
                background: 'rgba(250, 252, 252, .85)',
              }}
            />
            <Comment.Author as="a">
              {comment.creator.firstName} {comment.creator.lastName}{' '}
              {comment.creator.party && `(${comment.creator.party.label})`}
            </Comment.Author>
            <Comment.Metadata>
              <div>
                <Moment>{comment.createdAt.toString()}</Moment>
              </div>
            </Comment.Metadata>
            <Comment.Text>
              <Viewer initialValue={comment.content} />
            </Comment.Text>
            <Comment.Actions>
              {!comment.deleted && (
                <Comment.Action onClick={(event) => onReply(event, comment)}>
                  <Icon name="reply" />
                  Reply
                </Comment.Action>
              )}
              {!readOnly && canEdit && (
                <Fragment>
                  {!comment.deleted && (
                    <Fragment>
                      <Comment.Action
                        onClick={(event) => onEdit(event, comment)}
                      >
                        <Icon name="edit" />
                        Edit
                      </Comment.Action>
                    </Fragment>
                  )}

                  <Comment.Action
                    onClick={(event) =>
                      onDelete(event, comment, !comment.deleted)
                    }
                  >
                    <Icon name="trash" />
                    {comment.deleted ? 'Undelete' : 'Delete'}
                  </Comment.Action>
                </Fragment>
              )}
            </Comment.Actions>
          </Comment.Content>
        </Fragment>
      ) : (
        '[comment deleted]'
      )}

      {children}
    </Comment>
  );
};

export default ConversationComment;
