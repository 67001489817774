import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import WorkflowStatusList from './WorkflowStatusList';
import WorkflowStatusNodeList from './WorkflowStatusNodeList';
import WorkflowWrapper from './Workflow';
import { Container, Dimmer, Header, Loader } from 'semantic-ui-react';
import WorkflowDetailsForm from './WorkflowDetailsForm';
import ErrorBoundary from './../common/ErrorBoundary';
import UserWrapper from '../user/User';

@WorkflowWrapper
class WorkflowPage extends Component {
  static propTypes = {
    workflows: PropTypes.object,
    workflowActions: PropTypes.object,
    workflowStateActions: PropTypes.object,
    editingWorkflow: PropTypes.object,
    savingWorkflow: PropTypes.bool,
    loadingWorkflow: PropTypes.bool,
    match: PropTypes.object,
    query: PropTypes.object,
    history: PropTypes.object,
    currentUser: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      saving: false,
      loading: false,
    };
  }

  componentWillMount() {
    if (this.props.match.params.id || this.props.query.clone) {
      const id = this.props.match.params.id || this.props.query.clone;
      this.props.workflowActions.setEditingWorkflow({ id });
    } else {
      this.props.workflowActions.editNewWorkflow();
    }
  }

  componentWillReceiveProps(nextProps) {
    // Check for redirect from New workflow
    if (nextProps.match.params.id && !this.props.match.params.id) {
      this.props.workflowActions.setEditingWorkflow({
        id: nextProps.match.params.id,
      });
    }
  }

  workflowDetailsOnChange = (event, data) => {
    const { editingWorkflow, workflowStateActions } = this.props;
    workflowStateActions.updateWorkflow(editingWorkflow, data.name, data.value);
  };
  saveWorkflow = () => {
    this.props.workflowActions.saveWorkflow();
  };
  render() {
    const { editingWorkflow, loadingWorkflow, savingWorkflow, currentUser } =
      this.props;
    return (
      <Container fluid>
        <Dimmer active={loadingWorkflow} inverted>
          <Loader disabled={!loadingWorkflow} />
        </Dimmer>
        {editingWorkflow && !loadingWorkflow && (
          <React.Fragment>
            <WorkflowDetailsForm
              onChange={this.workflowDetailsOnChange}
              workflow={editingWorkflow}
              onSave={this.saveWorkflow}
              saving={savingWorkflow}
              currentUser={currentUser}
            />
            <Header>Statuses</Header>
            <WorkflowStatusList workflow={editingWorkflow} />
            <Header>Statuse Nodes</Header>
            <WorkflowStatusNodeList workflow={editingWorkflow} />
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default ErrorBoundary(UserWrapper(WorkflowPage));
