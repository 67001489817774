/*eslint import/namespace: [2, { allowComputed: true }]*/

import React from 'react';
import * as PropTypes from 'prop-types';
import * as icons from '../../../../constants/emojiIcons';

const ServerRenderIcon = (props) => {
  const { name, color } = props;
  return (
    <React.Fragment>
      <span style={{ color }}>{icons[name]}</span>
    </React.Fragment>
  );
};

ServerRenderIcon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
};

export default ServerRenderIcon;
