import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Grid, Header } from 'semantic-ui-react';
import ModelInstanceRenderer from '../ModelInstanceRenderer';

const EventListComparison = (props) => {
  const { event } = props;
  const from = _.get(event, 'data.from');
  const to = _.get(event, 'data.to');
  return (
    <Grid columns={2} divided>
      <Grid.Row>
        {from && (
          <Grid.Column>
            <Header size="small">From</Header>
            <ModelInstanceRenderer model={from.model} data={from.data} />
          </Grid.Column>
        )}
        {to && (
          <Grid.Column>
            <Header size="small">To</Header>
            <ModelInstanceRenderer model={to.model} data={to.data} />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

EventListComparison.propTypes = {
  event: PropTypes.object,
  model: PropTypes.object,
};

export default EventListComparison;
