import React, { Component } from 'react';
import dashboardApi from '../../api/dashboardApi';
import { Button, Container, Form, Input, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class DashboardsPage extends Component {
  state = {
    dashboards: [],
    newDashboardTitle: '',
  };

  componentDidMount() {
    dashboardApi.getDashboards().then((dashboards) => {
      this.setState({ dashboards });
    });
  }

  addNewDashboard = () => {
    dashboardApi
      .saveDashboard({ label: this.state.newDashboardTitle })
      .then((newDashboard) => {
        this.setState({
          dashboards: [...this.state.dashboards, newDashboard],
        });
      });
  };

  updateStateValue = (event, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { dashboards, newDashboardTitle } = this.state;
    return (
      <Container>
        <List>
          {dashboards.map((dashboard) => (
            <List.Item
              key={dashboard.id}
              as={Link}
              to={`/dashboards/${dashboard.id}/edit`}
            >
              {dashboard.label}
            </List.Item>
          ))}
        </List>

        <Form>
          <Form.Field
            control={Input}
            placeholder="Dashboard Title"
            name="newDashboardTitle"
            onChange={this.updateStateValue}
            value={newDashboardTitle}
          />
          <Button onClick={this.addNewDashboard}>Add New Dashboard</Button>
        </Form>
      </Container>
    );
  }
}

DashboardsPage.propTypes = {};

export default DashboardsPage;
