import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';

import { TicketContextConsumer } from './../../../context/TicketContext';

const MoreInfoRead = (props) => {
  const {
    currentTicket,
    ticketActions,
    label,
    onChange,
    value,
    required,
    disabled,
  } = props;
  const valueBool = value.length ? JSON.parse(value) : false;
  const handleCheckboxClick = () => {
    if (!disabled)
      ticketActions.openMoreInfoSidebar(
        currentTicket,
        handleReadAll,
        valueBool
      );
  };

  const handleReadAll = (e, isReadAllChecked) => {
    onChange(e, { value: isReadAllChecked.toString() });
  };

  return (
    <Form.Field inline required={required}>
      <Checkbox
        label={label || 'Confirm you have read all the information'}
        checked={valueBool === true}
        onClick={handleCheckboxClick}
      />
    </Form.Field>
  );
};

MoreInfoRead.propTypes = {
  currentTicket: PropTypes.object,
  ticketActions: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TicketContextConsumer(MoreInfoRead);
