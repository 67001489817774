import { Icon, IconProps, SemanticICONS } from 'semantic-ui-react';
import { FieldTypeId } from '../../../types';

const fileTypeIconMap: Record<FieldTypeId, SemanticICONS> = {
  YesNo: 'checkmark',
  Text: 'font',
  Currency: 'dollar sign',
  Select: 'dropdown',
  Date: 'calendar alternate outline',
  Number: 'sort numeric down',
  TextArea: 'align justify',
  CheckboxGroup: 'check square outline',
  RadioGroup: 'dot circle outline',
  StarRating: 'star outline',
  Country: 'flag',
  Email: 'mail outline',
  FileUpload: 'file outline',
  Percentage: 'percent',
  RichTextArea: 'align justify',
  AnalyseAndImplement: 'check square outline',
  Integer: 'sort numeric down',
  MoreInfoRead: 'check square outline',
  ArrayField: 'list',
  ObjectField: 'list',
  JSONBuilder: 'list',
  FormSectionHeader: 'heading',
  CheckBoxBool: 'check square outline',
};

interface FieldTypeIconProps extends IconProps {
  type: FieldTypeId;
}

export const FieldTypeIcon = (props: FieldTypeIconProps) => {
  return <Icon name={fileTypeIconMap[props.type]} {...props} />;
};
