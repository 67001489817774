import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as partyActions from '../../actions/partyActions';
import * as uiActions from '../../actions/uiActions';
import PartiesContext from './../../context/PartiesContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

function PartiesWrapper(ComponentToWrap) {
  class Parties extends Component {
    openCreatePartyModal = () => {
      const { currentUser } = this.props;
      this.props.uiActions.showModal({
        modalType: MODAL_COMPONENTS.CREATE_PARTY_MODAL,
        modalProps: { currentUser },
      });
    };

    loadParties = () => {
      const { partyStateActions } = this.props;
      partyStateActions.loadParties();
    };

    render() {
      const { parties } = this.props;

      const partiesWithGroup = parties.map((party) => {
        const groupLabel = party.permissionsNode?.parents?.[0]?.label;

        return {
          ...party,
          groupLabel,
        };
      });
      // console.log(partiesWithGroup);

      const values = {
        parties: partiesWithGroup,
        partiesActions: extractFunctions(this),
      };
      return (
        <PartiesContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </PartiesContext.Provider>
      );
    }
  }

  Parties.propTypes = {
    parties: PropTypes.array,
    uiActions: PropTypes.object,
    currentUser: PropTypes.object,
    partyStateActions: PropTypes.object,
  };

  function mapStateToProps(state) {
    const partiesWithGroup = state.parties.list?.map((party) => {
      const groupLabel = party.permissionsNode?.parents?.[0]?.label;

      return {
        ...party,
        groupLabel,
      };
    });

    return {
      parties: partiesWithGroup,
      currentUser: state.currentUser.details,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      partyStateActions: bindActionCreators(partyActions, dispatch),
      uiActions: bindActionCreators(uiActions, dispatch),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Parties);
}

export default PartiesWrapper;
