// @ts-nocheck
import React, { MutableRefObject } from "react";
// import dynamic from 'next/dynamic';
import { EditorProps, Editor as EditorType } from "@toast-ui/react-editor";
import { TuiWithForwardedRefProps } from "./TuiWithForwardedRef";

// import { Editor } from '@toast-ui/react-editor'

// const Editor = dynamic<EditorProps>(() => import('@toast-ui/react-editor')
//   .then(m => m.Editor), { ssr: false });

// const Editor = dynamic<TuiWithForwardedRefProps>(
//   () => import('./TuiWithForwardedRef'),
//   {
//     ssr: false,
//   }
// );

// const Editor = import('./TuiWithForwardedRef')

// const EditorWithForwardRef = React.forwardRef<
//   EditorType | undefined, // object type
//   EditorProps // prop type
// >((props, ref) => (
//   <Editor {...props} forwardedRef={ref as MutableRefObject<EditorType>} />
// ));
// EditorWithForwardRef.displayName = 'EditorWithForwardRef'; // throws error if not set

// interface ToastUiEditorProps extends EditorProps {
//   forwardedRef: MutableRefObject<EditorType | undefined>;
// }
// const ToastEditor: React.FC<ToastUiEditorProps> = (props) => {
//   return (
//     <EditorWithForwardRef
//       {...props}
//       ref={props.forwardedRef}
//       initialEditType={props.initialEditType || 'wysiwyg'}
//       height={props.height || '300px'}
//       previewStyle={props.previewStyle || 'vertical'}
//     />
//   );
// };

// const ToastEditor: React.FC<EditorProps> = (props) => {
//   return <EditorType {...props}/>
// }

// const Editor =   dynamic(() => import('./TuiEditorWrapped'), { ssr: false })

let Editor;
if (
  typeof window === "undefined" ||
  (typeof window !== "undefined" && window.next)
) {
  const dynamic = require("next/dynamic");
  Editor = dynamic(() => import("./TuiEditorWrapped"), { ssr: false });
} else {
  console.log("window is defined");
  Editor = require("./TuiEditorWrapped").default;
}

// 2. Pass down to child components using forwardRef
const EditorWithForwardedRef = React.forwardRef((props, ref) => (
  <Editor {...props} forwardedRef={ref} />
));

export default EditorWithForwardedRef as ToastuiEditor;

// const EditorWithForwardRef = React.forwardRef<
//   EditorType | undefined, // object type
//   EditorProps // prop type
// >((props, ref) => (
//   <WrappedEditor {...props} forwardedRef={ref as MutableRefObject<EditorType>} />
// ));
// EditorWithForwardRef.displayName = 'EditorWithForwardRef'; // throws error if not set

// export default EditorWithForwardRef

// export default React.forwardRef(props,ref) => (<ToastEditor {...props} forwardedRef={ref as MutableRefObject<EditorType>}/>);
