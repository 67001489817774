import { Header, Message, Table } from 'semantic-ui-react';
import { QueueItem } from '../../api/queueApi';
import { queueActionDataKeyMap, queueActionLabelMap } from './QueueErrorViewer';
import Moment from 'react-moment';

type Props = {
  item: QueueItem;
};

export const QueueItemDetails = ({ item }: Props) => {
  const data = item.payload?.data[queueActionDataKeyMap[item.actionType]];
  const lastError = item.processingErrors?.[item.processingErrors.length - 1];
  return (
    <div>
      <Header>
        <strong>Action:</strong> {queueActionLabelMap[item.actionType]}
        <br />
        <strong>Index:</strong> {item.payload?.data?.index}
      </Header>
      {lastError && (
        <Header color="red">
          <strong>Error:</strong> {lastError.message} -{' '}
          <Moment>{lastError.time}</Moment>
        </Header>
      )}

      <Header>Input data:</Header>

      {data && (
        <Table celled>
          {Object.keys(data).map((key) => (
            <Table.Row key={key}>
              <Table.Cell collapsing>
                <strong>{key}</strong>
              </Table.Cell>
              <Table.Cell>{data[key]}</Table.Cell>
            </Table.Row>
          ))}
        </Table>
      )}
    </div>
  );
};
