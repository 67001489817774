import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as uiActions from '../../actions/uiActions';
import { bindActionCreators } from 'redux';
import * as workflowActions from '../../actions/workflowActions';
import WorkflowContext from '../../context/WorkflowContext';
import { extractFunctions } from '../../utils/React';
import { Confirm, Dimmer, Loader } from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';
import * as storeTypes from '../../constants/storeTypes';

function WorkflowWrapper(ComponentToWrap) {
  let loadingIds = [];
  class Workflow extends Component {
    state = {
      deleteConfirmationOpen: false,
      workflowToDelete: {},
      saving: false,
      loading: false,
    };

    deleteWorkflow = (workflow) => {
      this.setState({
        workflowToDelete: workflow,
        deleteConfirmationOpen: true,
      });
    };

    cloneWorkflow = (workflow) => {
      this.props.history.push(`/workflow?clone=${workflow.id}`);
    };

    editWorkflow = (workflow) => {
      this.props.history.push(`/workflow/${workflow.id}/edit`);
    };

    gotoWorkflow = (workflow) => {
      this.props.history.push(`/workflow/${workflow.id}`);
    };

    setCanonicalWorkflow = ({ id, ticketId }) => {
      const { workflows } = this.props;
      const { loading } = this.state;
      if (workflows.byId[id]) {
        this.setState({ workflowId: id });
      } else {
        // if Workflow is already loading don't load it again
        if (loading && loading === id) return;
        this.setState({ loading: id });
        this.loadWorkflowRequest(id, ticketId, () => {
          this.setState({ loading: false, workflowId: id });
        });
      }
    };

    setEditingWorkflow = ({ id }) => {
      const { workflows, workflowStateActions } = this.props;
      const { loading } = this.state;
      if (workflows.byId[id]) {
        workflowStateActions.setWorkflow(workflows.byId[id]);
      } else {
        if (loading && loading === id) return;
        this.setState({ loading: id });
        workflowStateActions
          .loadWorkflow({
            id,
            alsoSetWorkflow: true,
          })
          .then(() => {
            this.setState({ loading: false });
          });
      }
    };

    editNewWorkflow = () => {
      const { workflowStateActions } = this.props;
      workflowStateActions.setWorkflow();
    };

    get workflow() {
      const { workflows } = this.props;
      const { workflowId } = this.state;

      if (workflowId && workflows.byId[workflowId]) {
        return workflows.byId[workflowId];
      }
    }

    saveWorkflow = () => {
      const { editingWorkflow, workflowStateActions } = this.props;
      this.setState({ saving: true });
      workflowStateActions
        .saveStateWorkflow(editingWorkflow)
        .then((result) => {
          toastr.success(
            editingWorkflow.id ? 'Workflow saved' : 'New Workflow created'
          );
          this.setState({ saving: false });
          if (!editingWorkflow.id) {
            this.props.history.push(`/workflow/${result.savedWorkflow.id}`);
          }
        })
        .catch((error) => {
          toastr.error(error);
          this.setState({ saving: false });
          throw error;
        });
    };

    confirmDeleteActivity = () => {
      this.setState({
        saving: true,
        deleteConfirmationOpen: false,
      });

      const newValue = Object.assign({}, this.state.workflowToDelete, {
        programStatusId: _.find(this.props.workflowStatuses, {
          label: 'DEACTIVATED',
        }).id,
      });

      this.props.workflowStateActions
        .saveWorkflow(newValue)
        .then(() => {
          toastr.success('Workflow deleted.');
          this.setState({ saving: false });
        })
        .catch((error) => {
          toastr.error(error);
          this.setState({ saving: false });
        });
    };
    cancelDeleteActivity = () => {
      this.setState({
        deleteConfirmationOpen: false,
      });
    };

    loadWorkflow = ({ id, ticketId }) => {
      const { workflows } = this.props;
      if (workflows.byId[id]) {
        this.setState({ workflowId: id });
      } else {
        // if Workflow is already loading don't load it again
        if (_.includes(loadingIds, id)) {
          return;
        }
        loadingIds.push(id);
        this.loadWorkflowRequest(id, ticketId, () => {
          _.remove(loadingIds, (item) => item === id);
          this.setState({ loading: false });
        });
      }
    };

    loadWorkflowRequest = (id, ticketId, callback) => {
      const { workflowStateActions } = this.props;
      workflowStateActions
        .loadWorkflow({
          id,
          ticketId,
        })
        .then(callback);
    };

    getStatusNode = (statusNodeId) => {
      const { workflows } = this.props;
      return workflows.statusNodeById[statusNodeId];
    };

    getStatusByWorkflowStatus = (workflowStatusId) => {
      const { workflows } = this.props;
      return _.find(
        workflows.statusNodeById,
        (workflow) => workflow.statusId === workflowStatusId
      );
    };

    render() {
      const { loading, saving } = this.state;
      const {
        workflows,
        editingWorkflow,
        workflowStateActions,
        activityStateActions,
        statusTypes,
        statusPermissionTypes,
        transitionPermissionTypes,
        transitionConditionTypes,
        transitionPostFunctionTypes,
        activityResolutionTypes,
        activityFieldTypes,
      } = this.props;

      const values = {
        workflow: this.workflow,
        editingWorkflow,
        workflows,
        statusTypes,
        statusPermissionTypes,
        transitionPermissionTypes,
        transitionConditionTypes,
        transitionPostFunctionTypes,
        activityResolutionTypes,
        activityFieldTypes,
        workflowStateActions: workflowStateActions,
        activityStateActions: activityStateActions,
        workflowActions: extractFunctions(this),
        loadingWorkflow: loading,
        savingWorkflow: saving,
      };
      return (
        <WorkflowContext.Provider value={values}>
          <Dimmer active={this.state.saving} inverted>
            <Loader disabled={!this.state.saving} />
          </Dimmer>
          <ComponentToWrap {...values} {...this.props} />
          <Confirm
            open={this.state.deleteConfirmationOpen}
            content="Are you sure you want to delete this workflow?"
            onConfirm={this.confirmDeleteActivity}
            onCancel={this.cancelDeleteActivity}
          />
        </WorkflowContext.Provider>
      );
    }
  }

  Workflow.propTypes = {
    workflow: PropTypes.object,
    editingWorkflow: PropTypes.object,
    workflowStateActions: PropTypes.object,
    uiActions: PropTypes.object.isRequired,
    workflows: PropTypes.object.isRequired,
    history: PropTypes.object,
    currentUser: PropTypes.object,
    activityStateActions: PropTypes.object,
    statusTypes: PropTypes.array,
    statusPermissionTypes: PropTypes.array,
    transitionPermissionTypes: PropTypes.array,
    transitionConditionTypes: PropTypes.array,
    transitionPostFunctionTypes: PropTypes.array,
    workflowStatuses: PropTypes.array,
    activityResolutionTypes: PropTypes.array,
    activityFieldTypes: PropTypes.array,
  };

  function mapStateToProps(state) {
    return {
      uiStatus: state.uiStatus,
      workflows: state.workflows,
      editingWorkflow: state[`Workflow${storeTypes.EDITING}`],
      statusTypes: state.constants.workflowStatusTypes,
      statusPermissionTypes: state.constants.workflowStatusPermissionTypes,
      transitionConditionTypes:
        state.constants.workflowTransitionConditionTypes,
      transitionPermissionTypes:
        state.constants.workflowTransitionPermissionTypes,
      transitionPostFunctionTypes:
        state.constants.workflowTransitionPostFunctionTypes,
      activityResolutionTypes: state.constants.activityResolutionTypes,
      activityFieldTypes: state.constants.activityFieldTypes,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      workflowStateActions: bindActionCreators(workflowActions, dispatch),
      uiActions: bindActionCreators(uiActions, dispatch),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Workflow);
}

export default WorkflowWrapper;
