import * as types from '../constants/actionTypes';
import _ from 'lodash';
import initialState from './initialState';
import update from 'immutability-helper/index';

export default function constantsReducer(
  state = initialState.constants,
  action
) {
  switch (action.type) {
    case types.LOAD_PERMISSION_TYPES_SUCCESS: {
      return update(state, {
        permissionTypes: { $set: action.permissionTypes },
      });
    }
    case types.LOAD_ROLE_DEFINITIONS_SUCCESS: {
      return update(state, {
        roleDefinitions: { $set: action.roleDefinitions },
      });
    }
    case types.CONSTANTS_LOAD_SUCCESS_MODEL_STRUCTURE:
      return update(state, {
        modelStructure: { $set: action.data },
      });
    case `${types.LOAD_AVAILABLE_RISK_THRESHOLDS_SUCCESS}`: {
      const { riskThresholds } = action;
      return { ...state, riskThresholds };
    }
    case `${types.UPDATE_ADMIN_THRESHOLD_SUCCESS}`: {
      const { threshold } = action;
      const thresholdIndex = _.findIndex(state.riskThresholds, {
        id: threshold.id,
      });
      if (thresholdIndex != -1) {
        return update(state, {
          riskThresholds: {
            [thresholdIndex]: {
              $set: threshold,
            },
          },
        });
      }
      return update(state, {
        riskThresholds: {
          $push: [threshold],
        },
      });
    }
    case `${types.REMOVE_ADMIN_THRESHOLD_SUCCESS}`: {
      const { thresholdId } = action;
      const thresholdIndex = _.findIndex(state.riskThresholds, {
        id: thresholdId,
      });
      return update(state, {
        riskThresholds: {
          $splice: [[thresholdIndex, 1]],
        },
      });
    }

    case `${types.LOAD_CONFIG_SUCCESS}`: {
      const {
        WorkflowStatusType,
        WorkflowTransitionConditionType,
        PartyRelationshipType,
        WorkflowTransitionPostFunctionType,
        ActivityResolutionType,
        ActivityFieldType,
        ProgramStatus,
        ActivityStatus,
        Currency,
        AttachmentClassification,
        AttachmentType,
        RoleDefinition,
        WorkflowStatusInputType,
        ExecutionState,
        EventType,
        options,
      } = action.config;
      const configData = {
        workflowStatusTypes: WorkflowStatusType,
        workflowTransitionConditionTypes: WorkflowTransitionConditionType,
        workflowTransitionPostFunctionTypes: WorkflowTransitionPostFunctionType,
        partyRelationshipTypes: PartyRelationshipType,
        programmeStatuses: ProgramStatus,
        activityFieldTypes: ActivityFieldType,
        activityResolutionTypes: ActivityResolutionType,
        activityStatuses: ActivityStatus,
        currencies: Currency,
        attachmentClassification: AttachmentClassification,
        attachmentType: AttachmentType,
        roleDefinitions: RoleDefinition,
        workflowStatusInputType: WorkflowStatusInputType,
        executionStateTypes: ExecutionState,
        eventTypes: EventType,
        options,
      };
      return { ...state, ...configData };
    }

    default:
      return state;
  }
}
