import React, { Component, createContext, useContext } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import FilterAndPaginate from '@vidende/components/FilterAndPaginate';
import DistributorTable from './DistributorTable';
import Pagination from '../common/Pagination';
import { NotificationType } from '../../api/partyApi';
import { DistributorInformation } from '../../types';

interface DistributorListProps {
  paginationOptions: any;
  paginatedItems: any[];
  items: any[];
  handleSortChange: (sortField: string) => void;
  handlePaginationChange: (e: any, data: any) => void;
  partyStateActions: any;
  removeFilterValues: (filters: string[]) => void;
  handleAddFilter: (filters: object) => void;
  currentFilters: any;
  hiddenColumns?: string[];
}

export const DistributorListContext = createContext<{
  handleSendNotification?: (
    distributorId: string,
    type: NotificationType
  ) => void;
  handleEditPartyLabel?: (
    distributor: DistributorInformation,
    label: string
  ) => void;
  LinkComponent?: React.ComponentType<any>;
  PermissionCheckComponent?: React.ComponentType<any>;
  canEdit?: boolean;
}>({
  handleSendNotification: () => {},
});

export const useDistributorListContext = () => {
  return useContext(DistributorListContext);
};

const DistributorList = (props: DistributorListProps) => {
  const {
    paginatedItems,
    handleSortChange,
    handlePaginationChange,
    paginationOptions,
    removeFilterValues,
    handleAddFilter,
    currentFilters,
    items,
    hiddenColumns,
  } = props;

  return (
    <React.Fragment>
      {items && items.length ? (
        <React.Fragment>
          <DistributorTable
            distributors={paginatedItems || []}
            paginationOptions={paginationOptions}
            handleSortChange={handleSortChange}
            removeFilterValues={removeFilterValues}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            hiddenColumns={hiddenColumns}
          />
          <Pagination
            activePage={paginationOptions.activePage}
            onPageChange={handlePaginationChange}
            totalPages={paginationOptions.totalPages}
            style={{ float: 'right' }}
          />
          {paginatedItems.length === 0 && (
            <Segment className="row">
              <div className="column">
                <Header as="h3">
                  No distributors found with the selected filters
                </Header>
              </div>
            </Segment>
          )}
        </React.Fragment>
      ) : (
        <Segment className="row">
          <div className="column">
            <Header as="h3">
              There are no distributors associated with your Party.
            </Header>
          </div>
        </Segment>
      )}
    </React.Fragment>
  );
};

function mapOptions(props: any) {
  return {
    items: props.distributors,
  };
}

export default FilterAndPaginate(mapOptions)(DistributorList);
