import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import FilterAndPaginate from '../common/FilterAndPaginate';
import ProductTable from './ProductTable';
import Pagination from '../common/Pagination';

export type ProductListMode = 'SELECTION' | 'LIST';

interface ProductListProps {
  paginationOptions: any;
  paginatedItems: any[];
  handleSortChange: (sortField: string) => void;
  handlePaginationChange: (e: any, data: any) => void;
  partyStateActions: any;
  removeFilterValues: (filters: string[]) => void;
  handleAddFilter: (filters: object) => void;
  currentFilters: any;
  mode?: ProductListMode;
}

const ProductList = (props: ProductListProps) => {
  const {
    paginatedItems,
    handleSortChange,
    handlePaginationChange,
    paginationOptions,
    removeFilterValues,
    handleAddFilter,
    currentFilters,
    mode,
  } = props;

  return (
    <React.Fragment>
      <Header as="h2">Products</Header>
      {paginatedItems.length ? (
        <React.Fragment>
          <ProductTable
            entities={paginatedItems || []}
            paginationOptions={paginationOptions}
            handleSortChange={handleSortChange}
            removeFilterValues={removeFilterValues}
            handleAddFilter={handleAddFilter}
            currentFilters={currentFilters}
            mode={mode}
          />
          <Pagination
            activePage={paginationOptions.activePage}
            onPageChange={handlePaginationChange}
            totalPages={paginationOptions.totalPages}
          />
        </React.Fragment>
      ) : (
        <Segment className="row">
          <div className="column">
            <Header as="h3">
              There are no products associated with your Party.
            </Header>
          </div>
        </Segment>
      )}
    </React.Fragment>
  );
};

function mapOptions(props: any) {
  return {
    items: props.entities,
  };
}

export default FilterAndPaginate(mapOptions)(ProductList);
