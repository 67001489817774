import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import dashboardApi from '../../api/dashboardApi';
import Select from '../common/fields/Select';
import { Form } from 'semantic-ui-react';

const ActivityDashboardSettings = (props) => {
  const { activity, updateActivityState } = props;
  const [dashboards, setDashboards] = useState([]);
  useEffect(() => {
    dashboardApi.getDashboards().then((dashboards) => {
      setDashboards(dashboards);
    });
  }, []);

  const handleChange = (e, { value }) => {
    updateActivityState(e, {
      name: 'meta',
      value: { ...activity.meta, dashboardId: value },
    });
  };

  const value = _.get(activity, 'meta.dashboardId');
  return (
    <Form>
      <Select options={dashboards} value={value} onChange={handleChange} />
    </Form>
  );
};

ActivityDashboardSettings.propTypes = {
  activity: PropTypes.object,
  updateActivityState: PropTypes.func,
};

export default ActivityDashboardSettings;
