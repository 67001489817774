import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import * as chartActions from '../../../actions/chartActions';
import { bindActionCreators } from 'redux';
import * as uiActions from '../../../actions/uiActions';
import DashboardWidget from './DashboardWidget';
import { SIDEBAR_COMPONENTS } from '../SidebarRoot';
import { mergeDataCall } from '../../../utils/data/DataUtils';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

class DashboardWidgetContainer extends Component {
  chartCallback = (data) => {
    const { dataCalls, uiActions, dashboardWhereFilters } = this.props;

    const clonedDataCalls = mergeDataCall({
      dashboardWhereFilters,
      dataCalls,
    });

    const baseConfig = clonedDataCalls['main'];
    uiActions.showSidebar({
      sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
      sidebarProps: { baseConfig, data: data.dataValue },
      sidebarConfig: { width: 'very wide' },
    });
  };

  performDrillthrough = ({ dataValue }) => {
    const {
      apiCalls,
      uiActions,
      dashboardWhereFilters,
      dashboardProperties,
      config,
      modelStructure,
    } = this.props;
    const dataCalls = _.zipObject(
      apiCalls.map((d) => d.name),
      apiCalls.map((d) => d.apiCall.arguments)
    );
    const { endpoint } = dataCalls.main;

    const clonedDataCalls = mergeDataCall({
      dashboardWhereFilters,
      dashboardProperties,
      dataCalls,
      allowedProperties: config.allowedProperties,
    });

    if (endpoint.indexOf('miData') !== -1) {
      const filteredEndpoint = endpoint.split('/')[1];

      const modelInfo = modelStructure[dataCalls.main.model];

      const endpointConfig = _.find(modelInfo.miData, {
        endpoint: filteredEndpoint,
      });

      _.forEach(dataValue, (value, key) => {
        const param = _.find(endpointConfig.params, (param) => {
          return param.label === key || param.name === key;
        });

        if (param) {
          clonedDataCalls.main.params[param.name] = value;
        }

        clonedDataCalls.main.params.drillThrough = true;
      });

      const baseConfig = clonedDataCalls['main'];
      uiActions.showSidebar({
        sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
        sidebarProps: {
          baseConfig,
          data: dataValue,
          header: `Selected ${modelInfo.emailString}s`,
          sidebarOptions: { width: 'very wide' },
        },
        sidebarConfig: { width: 'very wide' },
      });
    } else {
      const modelInfo = this.props.modelStructure[dataCalls.main.model];
      const baseConfig = clonedDataCalls.main;
      baseConfig.endpoint = 'filter';
      baseConfig.params.includeData = true;

      const newWheres = {};
      _.forEach(dataValue, (value, key) => {
        const v = key.split('.');
        newWheres[[...v].splice(0, v.length - 1).join('.')] = [
          { [_.last(v)]: value },
        ];
      });
      baseConfig.params.relationFilter.wheres = _.merge(
        {},
        baseConfig.params.relationFilter.wheres,
        newWheres
      );
      uiActions.showSidebar({
        sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
        sidebarProps: {
          baseConfig,
          data: dataValue,
          header: `Selected ${modelInfo.emailString}s`,
        },
        sidebarConfig: { width: 'very wide' },
      });
    }
  };

  performRedirect = ({ dataValue }) => {
    const {
      config,
      modelStructure,
      history,
      showDebug,
      dashboardProperties,
    } = this.props;

    const {
      modelType,
      propertyMapping,
      allowedProperties,
      clickThroughData = {},
    } = config;

    const modelInfo = modelStructure[modelType];

    const filterValue = { ...clickThroughData };
    _.map(dataValue, (val, key) => {
      if (propertyMapping[key]) filterValue[propertyMapping[key]] = val;
    });

    if (dashboardProperties && allowedProperties) {
      _.forEach(dashboardProperties, (value, key) => {
        const allowedProperty = _.find(allowedProperties, { name: key });
        if (allowedProperty) {
          if (propertyMapping[allowedProperty.name])
            filterValue[propertyMapping[allowedProperty.name]] = value;
        }
      });
    }
    const stringifiedFiltersValue = qs.stringify(filterValue, {
      arrayFormat: 'comma',
    });

    const redirectUrl = `/${modelInfo.searchUrl}?${stringifiedFiltersValue}`;
    if (showDebug) {
      this.props.dashboardCallback({ stringifiedFiltersValue, redirectUrl });
    } else {
      history.push(redirectUrl);
    }
  };

  render() {
    return (
      <DashboardWidget
        {...this.props}
        chartCallback={this.chartCallback}
        chartActions={this.props.chartActions}
        performDrillthrough={this.performDrillthrough}
        performRedirect={this.performRedirect}
      />
    );
  }
}

DashboardWidgetContainer.propTypes = {
  apiCalls: PropTypes.array,
  dataCalls: PropTypes.object,
  dashboardWhereFilters: PropTypes.object,
  uiActions: PropTypes.object,
  chartActions: PropTypes.object,
  dashboardProperties: PropTypes.object,
  modelStructure: PropTypes.object,
  config: PropTypes.object,
  history: PropTypes.object,
  showDebug: PropTypes.bool,
  dashboardCallback: PropTypes.func,
  onMouseOver: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    chartData: state.chartData[props.id] || null,
    modelStructure: state.constants.modelStructure,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
    chartActions: bindActionCreators(chartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardWidgetContainer)
);
