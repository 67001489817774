import React from 'react';
import { Form } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import Date from '../common/fields/Date';
import ApplicableDate from './ApplicableDate';

const LaunchLaterForm = ({ formData, handleFormDataChange }) => {
  const { launchDate, dueDate, applicableDate, applicableDateTypeId } =
    formData;
  return (
    <React.Fragment>
      <Form.Field>
        <Date
          label="Launch Date"
          value={launchDate}
          onChange={handleFormDataChange}
          name="launchDate"
          minDate={moment()}
          isClearable={true}
        />
      </Form.Field>
      <Form.Field>
        <Date
          label="Due Date"
          value={dueDate}
          onChange={handleFormDataChange}
          name="dueDate"
          minDate={moment()}
          isClearable={true}
        />
      </Form.Field>
      <Form.Field>
        <ApplicableDate
          applicableDate={applicableDate}
          applicableDateTypeId={applicableDateTypeId}
          handleFormDataChange={handleFormDataChange}
        />
      </Form.Field>
    </React.Fragment>
  );
};

LaunchLaterForm.propTypes = {
  formData: PropTypes.object,
  handleFormDataChange: PropTypes.func,
};

export default LaunchLaterForm;
