import { Button } from 'semantic-ui-react';

import PartyWrapper from './Party';

import PartyRelationshipList from './PartyRelationshipList';

import CheckPermission from '../common/CheckPermission';

import * as PermissionTypes from '../../constants/permissionTypes';
import { Party } from '../../types';

interface PartyActions {
  openSaveRelationshipModal: () => void;
}

interface PartyRelationshipsProps {
  partyActions: PartyActions;
  party: Party;
}

const PartyRelationships = (props: PartyRelationshipsProps) => {
  const { partyActions, party } = props;
  const { structuralNodeId } = party;

  const openCreateRelationshipModal = () => {
    partyActions.openSaveRelationshipModal();
  };

  return (
    <div className="row">
      <div className="column">
        <PartyRelationshipList />
        <CheckPermission
          structuralNodeId={structuralNodeId}
          permissionType={PermissionTypes.ADMIN}
        >
          <Button primary onClick={openCreateRelationshipModal}>
            Add new relationship
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
};

export default PartyWrapper(PartyRelationships);
