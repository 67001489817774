import React from 'react';
import { Header } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import { PartyWrapContext } from './../../context/PartyContext';

const PartyDetails = ({ party }) => {
  return (
    <div>
      <Header as="h1">
        {party.label} {party.status === 'INACTIVE' && '(Inactive)'}
        <Header.Subheader>{party.ref}</Header.Subheader>
      </Header>
    </div>
  );
};

PartyDetails.propTypes = {
  party: PropTypes.object,
};

export default PartyWrapContext(PartyDetails);
