import _ from 'lodash';
import executionApi from '../api/executionApi';
import * as types from '../constants/actionTypes';
import { getAttachmentsId } from '../utils/CommonUtils';
import {
  ADDING_FAIR_VALUE,
  ADDING_FAIR_VALUE_SUCCESS,
} from '../constants/actionTypes';

export function loadExecution(id, name) {
  return function (dispatch) {
    return executionApi.getExecution(id).then((execution) => {
      executionApi
        .getExecutions({
          where: {
            programId: execution.programId,
            partyId: execution.partyId,
            executionPlanId: execution.executionPlanId,
          },
        })
        .then((executions) => {
          const orderedExecutions = _.orderBy(
            executions,
            'applicableDate',
            'desc'
          );
          const currentExecutionIndex = _.findIndex(orderedExecutions, {
            id: execution.id,
          });
          const previousExecution =
            orderedExecutions[currentExecutionIndex + 1];
          if (previousExecution) {
            return dispatch({
              type: `${types.EXECUTION_UPDATE_VALUE}_${name}`,
              name: 'previousExecutionId',
              value: previousExecution.id,
            });
          }
        });
      return dispatch({
        type: `${types.LOAD_EXECUTION_SUCCESS}_${name}`,
        execution,
      });
    });
  };
}

export function loadExecutions(filter) {
  return function (dispatch) {
    return executionApi.getExecutions(filter).then((executions) => {
      return dispatch({
        type: types.LOAD_EXECUTIONS_SUCCESS,
        executions,
      });
    });
  };
}

export function addAttachments(executionId, attachments, currentUser) {
  return {
    type: `${types.EXECUTION_ADD_ATTACHMENTS}`,
    executionId,
    attachments,
    currentUser,
  };
}

export function deleteAttachment(execution, attachment) {
  return function (dispatch) {
    return executionApi.deleteAttachment(execution, attachment.id).then(() => {
      return dispatch({
        type: `${types.EXECUTION_DELETE_ATTACHMENT_SUCCESS}`,
        execution,
        attachment,
      });
    });
  };
}

export function addExistingAttachment(execution, attachments) {
  return function (dispatch) {
    return executionApi
      .addExistingAttachment(execution.id, getAttachmentsId(attachments))
      .then(() => {
        return dispatch({
          type: `${types.EXECUTION_ADD_EXISTING_ATTACHMENTS}`,
          attachments,
          execution,
        });
      });
  };
}

export function fetchMatrixRole(executionId) {
  return function (dispatch) {
    return executionApi.loadMatrixRole(executionId).then((response) => {
      return dispatch({
        type: `${types.LOAD_EXECUTION_MATRIX_ROLE_SUCCESS}`,
        executionId,
        response,
      });
    });
  };
}

export function setAssignedPartyFilter(partyId) {
  return {
    type: `${types.EXECUTION_SET_FILTER}`,
    partyId,
  };
}

export function saveExecutionDetails(execution) {
  return function (dispatch) {
    return executionApi.saveExecutionDetails(execution).then((response) => {
      return dispatch({
        type: `${types.SAVE_EXECUTION_DETAILS_SUCCESS}`,
        response,
      });
    });
  };
}

export function loadExecutionsCount(where) {
  return function () {
    return executionApi.getExecutionsCount(where).then((result) => {
      return result;
    });
  };
}

export function getExecutionTicketDeadlineCount(executionId) {
  return function (dispatch) {
    return executionApi
      .getExecutionTicketDeadlineCount(executionId)
      .then(({ count }) => {
        return dispatch({
          type: `${types.SET_EXECUTION_TICKET_DEADLINE_COUNT}`,
          count,
        });
      });
  };
}

export function updateFieldEntry(activityField, value, edited = true) {
  return {
    type: `${types.EXECUTION_UPDATE_ENTRY}`,
    activityField,
    value,
    edited,
  };
}

export function saveFieldEntries(execution, currentUserDetails) {
  return function (dispatch) {
    dispatch({
      type: `${types.SAVING_EXECUTION_ENTRIES}`,
      execution,
    });
    return executionApi
      .saveExecutionEntries(execution, execution.entries)
      .then((result) => {
        return dispatch({
          type: `${types.SAVE_EXECUTION_ENTRIES_SUCCESS}`,
          currentUserDetails,
          result,
        });
      });
  };
}

export function addFairValue(execution, distributors) {
  return function (dispatch) {
    dispatch({
      type: `${types.ADDING_FAIR_VALUE}`,
      execution,
    });
    return executionApi.addFairValue(execution, distributors).then((result) => {
      return dispatch({
        type: `${types.ADDING_FAIR_VALUE_SUCCESS}`,
        result,
      });
    });
  };
}
