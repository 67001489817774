import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import TicketWrapper from '../Ticket';
import ErrorBoundary from '../../common/ErrorBoundary';
import { Container, Dimmer, Divider, Loader } from 'semantic-ui-react';
import TicketPageMenu from './TicketPageMenu';
import TicketPageHeader from './TicketPageHeader';
import TicketPageActions from './TicketPageActions';
import DashboardContainer from '../../dashboard/DashboardContainer';
import * as _ from 'lodash';
import TicketOverview from '../TicketOverview';
import TicketFields from '../TicketFields/TicketFields';
import UserWrapper from '../../user/User';
import TicketPageWorkflowStatuses from './TicketPageWorkflowStatuses';
import TicketTabs from '../TicketTabs';

import './TicketPage.scss';

const TicketPage = (props) => {
  const {
    ticketId,
    ticketActions,
    workflowActions,
    ticketStateActions,
    ticket,
    isTicketLoading,
    fullPageView = true,
    OverrideComponent,
    workflow,
    isExecutionLoading,
    currentStatusNode,
    dashboardId,
    currentExecution,
    currentFields,
    currentUser,
    userHasInputPermission,
    allowDataEntry,
  } = props;
  const fieldsRef = useRef();
  const [isActionButtonsVisible, setIsActionButtonsVisible] = useState(false);
  const [showFieldKeys, setShowFieldKeys] = useState(false);
  const workflowId = _.get(ticket, 'workflowId');
  const showLoader = !ticket || !workflow || isTicketLoading;

  const ticketSeId = ticket?.executionTicketSetId;

  const entityIds = useMemo(() => {
    const ticketSet = currentExecution?.ticketSets.find(
      (ts) => ts.id === ticketSeId
    );
    if (!ticketSet) return null;

    const entityIds = ticketSet?.entities?.map((entity) => entity.id);

    return entityIds;
  }, [ticketSeId, currentExecution]);

  useEffect(() => {
    return window.removeEventListener('resize', () => setHeight());
  }, []);

  useEffect(() => {
    ticketActions.setTicket({ id: ticketId, isPreview: false });
    setHeight();
  }, [ticketId]);

  useEffect(() => {
    if (!workflowId) return;
    workflowActions.setCanonicalWorkflow({
      id: workflowId,
      ticketId: ticket.id,
    });
  }, [workflowId]);

  useEffect(() => {
    if (!showLoader) {
      setHeight();
      window.addEventListener('resize', () => setHeight());
    }
  }, [showLoader]);

  const { clientHeight } = fieldsRef ? { ...fieldsRef.current } : null;

  useEffect(() => {
    if (clientHeight) {
      setHeight();
    }
  }, [clientHeight]);

  const setHeight = () => {
    setTimeout(() => {
      if (fieldsRef && fieldsRef.current) {
        const fieldsHeight = fieldsRef.current.clientHeight;
        const isActionButtonCanBeVisible = fieldsHeight > 400;
        setIsActionButtonsVisible(isActionButtonCanBeVisible);
      }
    }, 1);
  };

  const toggleShowFieldKeys = () => {
    console.log('toggleShowFieldKeys');
    setShowFieldKeys(!showFieldKeys);
  };

  const ContainerComponent = fullPageView ? Container : 'div';
  const containerProps = fullPageView ? { fluid: true } : {};

  if (OverrideComponent) return <OverrideComponent ticket={ticket} />;

  if (showLoader)
    return (
      <ContainerComponent {...containerProps} className="ticket-page">
        {showLoader && (
          <Dimmer active={!isExecutionLoading} inverted>
            <Loader />
          </Dimmer>
        )}
      </ContainerComponent>
    );

  const workflowStatusFields = _.get(currentStatusNode, 'status.fields.length');
  const x = currentStatusNode.status.key
    ? _.filter(ticket.activity.fields, {
        workflowStatusInputTypeId: currentStatusNode.status.key,
      }).length
    : 0;

  const renderActionsAtTop = workflowStatusFields === 0 && x === 0;
  const renderBottomActions = renderActionsAtTop && isActionButtonsVisible;

  const fieldsWithoutThresholds = currentFields?.filter(
    (field) => field.activityFieldTypeId !== 'Threshold'
  );

  return (
    <ContainerComponent {...containerProps} className="ticket-page">
      <TicketPageMenu onToggleShowFieldKeys={toggleShowFieldKeys} />
      <TicketPageHeader />
      {renderActionsAtTop && <TicketPageActions order={0} />}
      {dashboardId && currentExecution && (
        <DashboardContainer
          id={dashboardId}
          editable={false}
          showHeader={false}
          forceChartRefresh={true}
          initialProperties={{
            currentAndPreviousExecution: [
              currentExecution.id,
              currentExecution.previousExecutionId,
            ],
            executionId: currentExecution.id,
            ticketId: ticket?.id,
            EntityIds: entityIds,
            entityId: entityIds && entityIds.length === 1 ? entityIds[0] : null,
            'relationFilter.wheres.execution': [{ id: currentExecution.id }],
            'filter.where.executionId': currentExecution.id,
            ExecutionId: currentExecution.id,
            execution: currentExecution,
          }}
        />
      )}

      {_.chain(ticket.toTicketRelationships)
        .filter({ ticketRelationshipTypeId: 'IMPLEMENTS' })
        .map((t) => <TicketOverview key={t.id} ticketId={t.fromTicketId} />)
        .value()}

      <div ref={fieldsRef}>
        <TicketFields
          entries={ticket.entries}
          fields={fieldsWithoutThresholds}
          onSave={() =>
            ticketStateActions.saveActivityFields(ticket, currentUser.details)
          }
          enabled={userHasInputPermission}
          allowDataEntry={allowDataEntry}
          showFieldKeys={showFieldKeys}
        />
      </div>

      {renderBottomActions && <TicketPageActions order={1} />}

      <TicketPageWorkflowStatuses />

      <Divider hidden />

      <TicketTabs />
    </ContainerComponent>
  );
};

TicketPage.propTypes = {
  ticketId: PropTypes.number,
  ticketActions: PropTypes.object,
  workflowActions: PropTypes.object,
  ticketStateActions: PropTypes.object,
  ticket: PropTypes.object,
  isTicketLoading: PropTypes.bool,
  fullPageView: PropTypes.bool,
  OverrideComponent: PropTypes.func,
  workflow: PropTypes.object,
  isExecutionLoading: PropTypes.bool,
  currentStatusNode: PropTypes.object,
  dashboardId: PropTypes.string,
  currentExecution: PropTypes.object,
  currentFields: PropTypes.array,
  currentUser: PropTypes.object,
  userHasInputPermission: PropTypes.bool,
  allowDataEntry: PropTypes.bool,
};

export default ErrorBoundary(UserWrapper(TicketWrapper(TicketPage)));
