import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, List } from 'semantic-ui-react';
import UIDGenerator from '../../../utils/UIDGenerator';
import * as _ from 'lodash';
import FieldToolTip from './FieldToolTip';

const CheckboxGroup = (props) => {
  const {
    label,
    options,
    value,
    onChange,
    disabled = false,
    outputArray = false,
    required,
    editable = true,
    error,
  } = props;
  const u = UIDGenerator.get();

  const values = outputArray ? value || [] : value ? value.split(',') : [];

  if (!editable) {
    return (
      <List bulleted>
        {values.map((val, index) => (
          <List.Item key={index}>{val}</List.Item>
        ))}
      </List>
    );
  }

  const handleOnChange = (event, data) => {
    let newValue = data.checked
      ? [...values, data.value]
      : _.pull(values, data.value);
    if (!outputArray) newValue = newValue.join(',');

    onChange(event, {
      value: newValue,
    });
  };

  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      {_.map(options, (option, index) => (
        <Form.Checkbox
          key={index}
          label={option}
          name={`radioGroup${u}`}
          value={option}
          checked={values.indexOf(option) !== -1}
          onChange={handleOnChange}
          disabled={disabled}
        />
      ))}
    </Form.Field>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  outputArray: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  value: '',
};

export default CheckboxGroup;
