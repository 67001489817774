import React from 'react';
import * as PropTypes from 'prop-types';
import ActionButtons from '../common/ActionButtons';
import { Dropdown, Input, Select, Table } from 'semantic-ui-react';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { mapToOptions } from '../../utils/React';

const StatusNodeTransitionListRow = (props) => {
  const {
    transition,
    onChange,
    onRemove,
    statusNode,
    statusNodes,
    transitionConditionTypes,
    transitionPostFunctionTypes,
  } = props;
  const conditionValues = transition.conditions
    ? transition.conditions.map((c) => c.id)
    : [];
  const postFunctionValues = transition.postFunctionTypes
    ? transition.postFunctionTypes.map((c) => c.id)
    : [];
  function conditionsOnChange(event, data) {
    const value = data.value.map((v) => ({
      id: v,
    }));
    onChange(event, Object.assign({}, data, { value }));
  }
  function postFunctionsOnChange(event, data) {
    const value = data.value.map((v) => ({
      id: v,
    }));
    onChange(event, Object.assign({}, data, { value }));
  }
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>
          <Input
            value={transition.label || ''}
            name="label"
            onChange={onChange}
          />
        </Table.Cell>
        <Table.Cell>
          <Select
            name="toStatusNodeUid"
            onChange={onChange}
            value={transition.toStatusNodeUid}
            options={statusNodes
              .filter((sn) => sn.uid !== statusNode.uid)
              .map((statusNode) => ({
                key: statusNode.uid,
                value: statusNode.uid,
                text: statusNode.label,
              }))}
          />
        </Table.Cell>
        <Table.Cell>
          <ActionButtons removeClicked={onRemove} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan={3}>
          <Dropdown
            placeholder="Transition Conditions"
            fluid
            value={conditionValues}
            multiple
            selection
            options={mapToOptions(transitionConditionTypes)}
            onChange={conditionsOnChange}
            name="conditions"
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan={3}>
          <Dropdown
            placeholder="Transition Post Function"
            fluid
            value={postFunctionValues}
            multiple
            selection
            options={mapToOptions(transitionPostFunctionTypes)}
            onChange={postFunctionsOnChange}
            name="postFunctionTypes"
          />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

StatusNodeTransitionListRow.propTypes = {
  transition: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  statusNode: PropTypes.object,
  statusNodes: PropTypes.array,
  transitionConditionTypes: PropTypes.array,
  transitionPostFunctionTypes: PropTypes.array,
};

export default WorkflowWrapContext(StatusNodeTransitionListRow);
