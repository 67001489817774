import * as types from '../constants/actionTypes';
import { Entity, EntityType } from '../types';
import update from 'immutability-helper';

export type EntityState = {
  entities: Entity[];
  entitiesById: {
    [key: string]: Entity;
  };
  selectedEntities: string[];
  entityTypes: {
    array: EntityType[];
    byId: {
      [key: string]: EntityType;
    };
  };
};

type Action = {
  type: string;
  entities?: Entity[];
  entity?: Entity;
  entityType?: EntityType;
  entityTypes?: EntityType[];
};

export default function entityReducer(
  state: EntityState = {
    entities: [],
    entitiesById: {},
    selectedEntities: [],
    entityTypes: {
      array: [],
      byId: {},
    },
  },
  action: Action
): EntityState {
  switch (action.type) {
    case types.UPDATE_ENTITY_SUCCESS: {
      const index = state.entities.findIndex(
        (entity) => action.entity.id === entity.id
      );

      return {
        ...state,
        entities: state.entities.map((entity, i) => {
          if (i === index) {
            return action.entity;
          }
          return entity;
        }),
        entitiesById: {
          ...state.entitiesById,
          [action.entity.id]: action.entity,
        },
      };
    }
    case types.LOAD_ENTITIES_SUCCESS: {
      const entitiesById = action.entities.reduce((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
      }, {});

      return {
        ...state,
        entities: action.entities,
        entitiesById,
      };
    }

    case types.LOAD_ENTITY_SUCCESS: {
      const { entity } = action;

      return {
        ...state,
        entities: state.entities.some((e) => e.id === entity.id)
          ? state.entities.map((e) => (e.id === entity.id ? entity : e))
          : [...state.entities, entity],
        entitiesById: {
          ...state.entitiesById,
          [entity.id]: entity,
        },
      };
    }

    case types.SELECT_ENTITY:
      let selectedEntities = [];
      if (action.entity) {
        selectedEntities = state.selectedEntities.includes(action.entity.id)
          ? state.selectedEntities
          : [...state.selectedEntities, action.entity.id];
      }
      if (action.entities) {
        selectedEntities = action.entities.reduce(
          (acc, entity) => {
            if (!acc.includes(entity.id)) {
              acc.push(entity.id);
            }
            return acc;
          },
          [...state.selectedEntities]
        );
      }
      return {
        ...state,
        selectedEntities,
      };

    case types.DESELECT_ENTITY:
      let deselectedEntities = [];
      if (action.entity) {
        deselectedEntities = state.selectedEntities.filter(
          (id) => id !== action.entity.id
        );
      }
      if (action.entities) {
        deselectedEntities = state.selectedEntities.filter(
          (id) => !action.entities.map((e) => e.id).includes(id)
        );
      }
      return {
        ...state,
        selectedEntities: deselectedEntities,
      };
    case types.LOAD_ENTITY_TYPE_SUCCESS: {
      const entityType = action.entityType;

      return update(state, {
        entityTypes: {
          byId: { [entityType.id]: { $set: entityType } },
        },
      });
    }
    case types.LOAD_ENTITY_TYPES_SUCCESS: {
      const entityTypes = action.entityTypes;

      const entityTypesById = entityTypes.reduce((acc, entityType) => {
        acc[entityType.id] = entityType;
        return acc;
      }, {});
      return {
        ...state,
        entityTypes: {
          array: entityTypes,
          byId: entityTypesById,
        },
      };
    }
    default:
      return state;
  }
}
