import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import AdminWrapper from './Admin';
import ThresholdListItem from './ThresholdListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';
import ConfirmWrapper from './../common/ConfirmWrapper';

const ThresholdsList = (props) => {
  const { riskThresholds, handleSortChange, paginationOptions } = props;
  const { openSaveAdminThresholdModal } = props.adminActions;
  const { showConfirmDialog } = props.confirmPopupActions;
  const { removeAdminThreshold } = props.constantStateActions;
  const renderThresholdList = () => {
    return riskThresholds.map((riskThreshold) => {
      return (
        <ThresholdListItem
          key={riskThreshold.id}
          threshold={riskThreshold}
          removeAdminThreshold={removeAdminThreshold}
          editAdminThreshold={openSaveAdminThresholdModal}
          showConfirmDialog={showConfirmDialog}
        />
      );
    });
  };
  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
          >
            Label
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="reference"
            handleSortChange={handleSortChange}
          >
            Reference
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="description"
            handleSortChange={handleSortChange}
          >
            Description
          </SortableHeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderThresholdList()}</Table.Body>
    </Table>
  );
};

ThresholdsList.propTypes = {
  riskThresholds: PropTypes.array.isRequired,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  confirmPopupActions: PropTypes.object,
  constantStateActions: PropTypes.object,
  adminActions: PropTypes.object,
};

export default AdminWrapper(ConfirmWrapper(ThresholdsList));
