import { useState, useCallback, useEffect } from 'react';
import * as _ from 'lodash';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Segment,
  Message,
} from 'semantic-ui-react';
import Text from '../common/fields/Text';
import UserWrapper from './User';

import userApi from '../../api/userApi';
import { clearLocalStorage } from '../../reducers';
import { useLocation } from 'react-router-dom';

interface Props {
  userStateActions: {
    login: (email: string, password: string) => Promise<any>;
    verifySamlToken: (token: string) => Promise<any>;
    renewToken?: () => Promise<any>;
    verifySMSCode: (data: any) => Promise<any>;
  };
  constantStateActions: {
    loadConfig: () => Promise<any>;
    loadModelStructure: () => void;
  };
  appData: {
    startRoute: string;
  };
  appActions: {
    setInitialStartRoute: () => void;
  };
  eventsActions: {
    cleanEventsList: () => void;
    getEventsList: (data: any) => void;
  };
  history: {
    push: (path: string) => void;
  };
}

const LoginPage: React.FC<Props> = (props) => {
  const [busy, setBusy] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({
    validationErrors: null,
    apiErrors: null,
  });
  const location = useLocation();
  const samlToken = new URLSearchParams(location.search).get('samlToken');

  useEffect(() => {
    clearLocalStorage();
  }, []);

  // States introduced from the Login component for SMS verification
  const [telephone, setTelephone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verifyRequest, setVerifyRequest] = useState<{
    token: string;
    verify: 'PHONE_NUMBER' | 'PHONE_REQUEST' | 'SMS';
  }>();
  const [verifyTelephoneError, setVerifyTelephoneError] = useState<any>();
  const [verifySMSError, setVerifySMSError] = useState<any>();
  const [authType, setAuthType] = useState<null | string | undefined>();

  useEffect(() => {
    if (samlToken) {
      setBusy(true);
      props.userStateActions
        .verifySamlToken(samlToken)
        .then((result) => {
          if (result.response?.verify) {
            setVerifyRequest(result.response);
            setBusy(false);
            return;
          }

          props.constantStateActions
            .loadConfig()
            .then(() => {
              props.constantStateActions.loadModelStructure();
              props.eventsActions.cleanEventsList();
              props.eventsActions.getEventsList({});
              props.appActions.setInitialStartRoute();
              props.history.push(props.appData.startRoute);
            })
            .catch(() => {
              setBusy(false);
            });
        })
        .catch((error) => {
          handleApiError(error);
        });
    }
  }, [samlToken]);

  const handleApiError = (respErrors: any) => {
    const { validationErrors } = respErrors;
    let newErrors = {
      validationErrors: null,
      apiErrors: null,
    };
    if (validationErrors) {
      newErrors = { validationErrors, apiErrors: null };
    } else {
      newErrors = {
        apiErrors:
          _.get(respErrors, 'response.data.error.message') ||
          respErrors.message,
        validationErrors: null,
      };
    }
    console.log(newErrors);
    setErrors(newErrors);
    setBusy(false);
  };

  const loginSaml = async () => {
    setBusy(true);
    try {
      const res = await userApi.loginSaml(formData.email);
      if (res?.loginLink) {
        window.location.assign(res.loginLink);
      }
      setBusy(false);
    } catch (error) {
      handleApiError(error);
    }
  };

  const getAuthType = async () => {
    setBusy(true);
    try {
      const res = await userApi.getAuthType(formData.email);
      // authType is password(null) by default or in case of error
      setBusy(false);
      setAuthType(res?.authType || null);
      if (res?.authType?.toUpperCase() === 'OKTA') {
        loginSaml();
      }
    } catch (error) {
      setAuthType(null);
      handleApiError(error);
    }
  };

  const submitForm = useCallback(() => {
    setBusy(true);
    setErrors({ validationErrors: null, apiErrors: null });

    _.delay(() => {
      const { email, password } = formData;
      props.userStateActions
        .login(email, password)
        .then((result) => {
          if (result.response?.verify) {
            setVerifyRequest(result.response);
            setBusy(false);
            return;
          }

          props.constantStateActions
            .loadConfig()
            .then(() => {
              props.constantStateActions.loadModelStructure();
              props.eventsActions.cleanEventsList();
              props.eventsActions.getEventsList({});
              props.appActions.setInitialStartRoute();
              props.history.push(props.appData.startRoute);
            })
            .catch(() => {
              setBusy(false);
            });
        })
        .catch((respErrors) => {
          handleApiError(respErrors);
        });
    }, 300);
  }, [formData, props]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    { name, value }: { name: string; value: string }
  ) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Telephone Verification
  const handlePhoneRequest = async () => {
    if (!verifyRequest || !telephone) return;

    setBusy(true);

    try {
      const result = await userApi.verifyTelephoneNumber({
        telephoneNumber: telephone,
        token: verifyRequest?.token,
      });
      setBusy(false);

      if ('verify' in result) {
        setVerifyRequest(result);
        return;
      }
    } catch (err) {
      setBusy(false);
      setVerifyTelephoneError(err);
    }
  };

  // Handle SMS Code Verification
  const handleVerifySMSCode = async () => {
    if (!verifyRequest || !verificationCode) return;

    setBusy(true);

    try {
      await props.userStateActions.verifySMSCode({
        token: verifyRequest?.token,
        code: verificationCode,
      });

      props.constantStateActions
        .loadConfig()
        .then(() => {
          setBusy(false);
          props.constantStateActions.loadModelStructure();
          props.eventsActions.cleanEventsList();
          props.eventsActions.getEventsList({});
          props.appActions.setInitialStartRoute();
          props.history.push(props.appData.startRoute);
        })
        .catch(() => {
          setBusy(false);
        });
    } catch (err) {
      setVerifySMSError(err);
      setBusy(false);
    }
  };

  const resetForm = () => {
    setTelephone('');
    setVerificationCode('');
    setVerifyRequest(undefined);
  };

  if (verifyRequest && verifyRequest.verify === 'PHONE_REQUEST') {
    return (
      <Container className="login-page">
        <Divider hidden />
        <div className="logo-login" />
        <Grid stackable centered columns={3}>
          <Grid.Column>
            <Divider hidden />
            <Segment>
              <Form
                className="form-phone-request"
                onSubmit={handlePhoneRequest}
                error={!!verifyTelephoneError}
              >
                <Message>
                  Please enter your phone number to receive a verification code,
                  you will only need to do this once.
                  <br />
                  Please include your country code with no spaces, e.g.
                  +441234567890.
                </Message>
                <Text
                  label="Telephone Number"
                  value={telephone}
                  onChange={(_, { value }) => setTelephone(value)}
                  name="telephone"
                  debounce={false}
                />
                <Button type="submit" loading={busy} disabled={busy}>
                  Submit
                </Button>
                {verifyTelephoneError && (
                  <Message error>
                    {_.get(verifyTelephoneError, 'message')}
                    <br />
                    Click{' '}
                    <a href="#" onClick={resetForm}>
                      here
                    </a>{' '}
                    to return to the login page.
                  </Message>
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  if (
    verifyRequest &&
    (verifyRequest.verify === 'PHONE_NUMBER' || verifyRequest.verify === 'SMS')
  ) {
    return (
      <Container className="login-page">
        <Divider hidden />
        <div className="logo-login" />
        <Grid stackable centered columns={3}>
          <Grid.Column>
            <Divider hidden />
            <Segment>
              <Form
                className="form-phone-request"
                onSubmit={handleVerifySMSCode}
                error={!!verifySMSError}
              >
                <Message>
                  Please enter the verification code sent to your phone number.
                </Message>
                <Text
                  label="Verification Code"
                  value={verificationCode}
                  onChange={(_, { value }) => setVerificationCode(value)}
                  name="verificationCode"
                  debounce={false}
                />
                <Button type="submit" disabled={busy} loading={busy}>
                  Submit
                </Button>
                {verifySMSError && (
                  <Message error>
                    {_.get(verifySMSError, 'message')}
                    <br />
                    Click{' '}
                    <a href="#" onClick={resetForm}>
                      here
                    </a>{' '}
                    to return to the login page.
                  </Message>
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  if (authType === undefined || authType?.toUpperCase() === 'OKTA') {
    return (
      <Container className="login-page">
        <Divider hidden />
        <div className="logo-login" />
        <Grid stackable centered columns={3}>
          <Grid.Column>
            <Divider hidden />
            <Segment>
              <Form className="form-login" onSubmit={getAuthType}>
                <Text
                  label="Email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  errors={errors.validationErrors}
                  debounce={false}
                />
                <Button disabled={busy} loading={busy}>
                  Submit
                </Button>
                {errors.apiErrors && (
                  <Message error content={errors.apiErrors} />
                )}
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className="login-page">
      <Divider hidden />
      <div className="logo-login" />
      <Grid stackable centered columns={3}>
        <Grid.Column>
          <Divider hidden />
          <Segment>
            <Form className="form-login" onSubmit={submitForm}>
              <Text
                label="Email"
                value={formData.email}
                onChange={handleChange}
                name="email"
                errors={errors.validationErrors}
                debounce={false}
              />
              <Text
                label="Password"
                value={formData.password}
                onChange={handleChange}
                name="password"
                type="password"
                errors={errors.validationErrors}
                debounce={false}
              />
              <Button disabled={busy} loading={busy}>
                Submit
              </Button>
              {errors.apiErrors && <Message error content={errors.apiErrors} />}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default UserWrapper(LoginPage);
