import React from 'react';
import { Link } from 'react-router-dom';

import ErrorBoundary from './common/ErrorBoundary';
import { Container, Header } from 'semantic-ui-react';

const NotFoundPage = () => {
  return (
    <Container>
      <Header>The content you requested is not available</Header>
      <Link to="/"> Click here to return to the homepage </Link>
    </Container>
  );
};

export default ErrorBoundary(NotFoundPage);
