import React from 'react';
import * as PropTypes from 'prop-types';
import { Menu, Dropdown } from 'semantic-ui-react';

const ViewSelector = ({ views, currentView, onChange }) => {
  return (
    <Menu borderless secondary className="view-selector">
      <Dropdown icon="content" pointing="right" button className="icon">
        <Dropdown.Menu>
          {views.map((view) => (
            <Dropdown.Item
              onClick={onChange}
              selected={currentView.name === view.name}
              view={view}
              key={view.name}
            >
              {view.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

ViewSelector.propTypes = {
  views: PropTypes.array.isRequired,
  currentView: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ViewSelector;
