import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Table, Select } from 'semantic-ui-react';
import * as _ from 'lodash';

import ActionButtons from '../common/ActionButtons';
import { PermissionContextConsumer } from '../../context/PermissionContext';
import PrincipalLabel from '../common/PrincipalLabel';
import PartyLabel from '../common/labels/PartyLabel';
import { mapToOptions } from '../../utils/React';

const PrincipalRoleRow = ({
  principalRole,
  isAdminPermission,
  roleDefinitions = [],
  includeSuperUser = false,
  showConfirmDialog,
  permissionActions,
  onDelete,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRoleDefinitionChanged = (e, { value }) => {
    if (principalRole.roleDefinitionId !== value) {
      const { principal } = principalRole;
      const { structuralNodeId } = principalRole;
      const newValue = {
        id: principalRole.id,
        structuralNodeId: structuralNodeId,
        principalId: principal.id,
        roleDefinitionId: value,
        roleDefinition: _.find(roleDefinitions, { id: value }),
      };
      if (onChange) {
        onChange(newValue);
        return;
      }
      setLoading(true);
      permissionActions
        .updatePrincipalRole(structuralNodeId, newValue)
        .then(() => setLoading(false));
    }
  };

  const deletePermissionClicked = () => {
    if (onDelete) {
      onDelete(principalRole);
      return;
    }
    showConfirmDialog(
      `Are you sure you want to delete this permission?`,
      deletePermissionOnConfirm
    );
  };

  const deletePermissionOnConfirm = () => {
    permissionActions.deletePrincipalRole(principalRole);
  };

  let roleDefinitionOptions = mapToOptions(roleDefinitions);

  if (!includeSuperUser)
    roleDefinitionOptions = roleDefinitionOptions.map((role) => {
      if (role.key === 'Superuser') return { ...role, disabled: true };
      return role;
    });

  const { principal } = principalRole;
  const { party } = principal.userGroup || principal.user;

  return (
    <Table.Row>
      <Table.Cell>
        <PrincipalLabel principal={principal} />
      </Table.Cell>
      <Table.Cell>
        <PartyLabel party={party} />
      </Table.Cell>
      <Table.Cell>
        <Select
          options={roleDefinitionOptions}
          value={principalRole.roleDefinitionId}
          name="roleDefinitions"
          disabled={loading || !isAdminPermission}
          onChange={handleRoleDefinitionChanged}
          loading={loading}
          selectOnBlur={false}
        />
      </Table.Cell>
      {isAdminPermission && (
        <Table.Cell>
          <ActionButtons deleteClicked={deletePermissionClicked} />
        </Table.Cell>
      )}
    </Table.Row>
  );
};

PrincipalRoleRow.propTypes = {
  principalRole: PropTypes.object.isRequired,
  permissionActions: PropTypes.object,
  roleDefinitions: PropTypes.array,
  isAdminPermission: PropTypes.bool,
  includeSuperUser: PropTypes.bool,
  showConfirmDialog: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  principalRoles: PropTypes.array,
};

export default PermissionContextConsumer(PrincipalRoleRow);
