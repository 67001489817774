import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as _ from 'lodash';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Ref,
  Segment,
} from 'semantic-ui-react';
import * as update from 'immutability-helper/index';
import * as toastr from 'toastr';

import DebugTable from '../debug/DebugTable';
import WidgetComponents from '../common/dashboard/DashboardWidgetComponents';
import utilsApi from '../../api/utilsApi';
import chartApi from '../../api/chartApi';
import DebugObject from '../debug/DebugObject';
import ErrorBoundary from './../common/ErrorBoundary';
import DashboardWidgetContainer from '../common/dashboard/DashboardWidgetContainer';
import DebugComponent from '../common/dashboard/components/DebugComponent';
import DataMappers from './chartBuilder/DataMappers';

import './ChartBuilder.scss';
import JSONBuilder from '../common/fields/JSONBuilder';
import ChartApiCallsList from './ChartAPICallsList';

type WidgetConfigType = {
  nameColumn?: string | null;
  yColumn?: string | null;
  title: string;
  type: string;
  endpoint: string;
};

type ChartConfigType = {
  header: string;
  chartOptions: {
    plotOptions: {
      column: {
        stacking: string;
      };
      series: {
        stacking: string;
      };
    };
  };
  testData?: any;
};

type ChartType = {
  id: string;
  label: string;
  options: {
    type: string;
    dataCalls: {
      main: {
        model: string;
        endpoint: string;
        params: {
          relationFilter?: {
            joins: any[];
            groups: Record<string, unknown>;
            wheres: Record<string, unknown>;
          };
          AwaitingOverdueComplete: string;
          [key: string]: any;
        };
      };
    };
    dataMapping: {
      data: {
        mappers: string[];
        args: {
          series: {
            dataSet: string;
            dataRow: string;
          }[];
          yColumn: {
            dataSet: string;
            dataRow: string;
          };
        };
      };
    };
    config: {
      header: string;
      chartOptions: {
        plotOptions: {
          column: {
            stacking: string;
          };
          series: {
            stacking: string;
          };
        };
      };
    };
    builder: {
      joins: any[];
      groups: any[];
      wheres: any[];
    };
  };
  apiCalls: any;
};
type DataMappingType = {
  data: {
    mappers: string[];
    args: {
      series: {
        dataSet: string;
        dataRow: string;
      }[];
      yColumn: {
        dataSet: string;
        dataRow: string;
      };
    };
  };
};

type KPIConfigType = {
  title: string;
  label: string;
  icon: string;
  color: string;
};

type StateType = {
  loading: boolean;
  settingsString: string;
  chartId?: string;
  models: Record<string, unknown>;
  relationFilter: {
    joins: any[];
    groups: Record<string, unknown>;
    wheres: Record<string, unknown>;
  };
  baseModel: string;
  joins: any[];
  groups: any[];
  wheres: any[];
  groupTestData: any[];
  filterTestData: any[];
  endpointParams: {
    [key: string]: any;
  };
  endpointParamsString: string;

  columnHeight?: number;

  count: number;
  data: any[];
  result: any[];

  widgetConfig: WidgetConfigType;

  chartConfig: ChartConfigType;

  chartConfigString: string;

  dataMapping?: DataMappingType;

  dataMappingString: string;

  KPIConfig: KPIConfigType;

  debugSetValue: Record<string, unknown>;
  debugSetDataValue: Record<string, unknown>;
  mappedData: Record<string, unknown>;

  apiCalls?: any;
};

const initialState = (isNew: boolean): StateType => ({
  loading: true,
  settingsString: '',
  models: {},
  relationFilter: { joins: [], groups: {}, wheres: {} },
  baseModel: 'ExecutionTicket',
  joins: [],
  groups: [],
  wheres: [],
  groupTestData: [],
  filterTestData: [],
  endpointParams: {},
  endpointParamsString: '{}',

  count: 0,
  data: [],
  result: [],

  widgetConfig: {
    nameColumn: null,
    yColumn: null,
    title: 'Pie Chart Title',
    type: 'pie',
    endpoint: 'group',
  },

  chartConfig: {
    header: 'Resolution by party',
    chartOptions: {
      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          stacking: 'normal',
        },
      },
    },
  },

  chartConfigString: '',

  dataMapping: isNew
    ? {
        data: {
          mappers: ['pie'],
          args: {
            series: [
              {
                dataSet: 'main',
                dataRow: 'assignedPrincipal.user.email',
              },
            ],
            yColumn: {
              dataSet: 'main',
              dataRow: 'count',
            },
          },
        },
      }
    : null,

  dataMappingString: '',

  KPIConfig: {
    title: 'KPI Title',
    label: 'Things',
    icon: 'plane',
    color: 'green',
  },

  debugSetValue: {},
  debugSetDataValue: {},
  mappedData: {},
});

type Props = {
  chartIdToLoad?: string;
};

const WidgetBuilderPage: React.FC = ({ chartIdToLoad }: Props) => {
  const [state, setState] = useState<StateType>(initialState(!chartIdToLoad));
  const componentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await utilsApi.getModelStructure();
      setState((prevState: StateType) => ({
        ...prevState,
        models: result,
      }));

      if (chartIdToLoad) {
        const chart = await chartApi.getChart({ id: chartIdToLoad });
        optionsFromChart({ chart });
        setState((prevState: StateType) => ({
          ...prevState,
          loading: false,
        }));
      } else {
        setState((prevState: StateType) => ({
          ...prevState,
          loading: false,
        }));
      }
    };
    fetchData();
    window.addEventListener('resize', resizeDebounced);

    return () => {
      window.removeEventListener('resize', resizeDebounced);
    };
  }, []);

  const resizeDebounced = (): any => {
    return _.debounce(resize, 150);
  };

  const resize = (): void => {
    if (!componentRef.current) return;
    const columnHeight =
      window.innerHeight - componentRef.current.getBoundingClientRect().top;
    const newState = { columnHeight };
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const optionsFromChart = ({ chart }: { chart: ChartType }): void => {
    const { options, label, apiCalls } = chart;
    const relationFilter = options.dataCalls.main.params.relationFilter;
    const { config, dataMapping, builder } = options;
    const { joins, groups, wheres } = builder;

    setState((prevState) => ({
      ...prevState,
      apiCalls,
      relationFilter,
      widgetConfig: {
        title: label,
        type: options.type,
        endpoint: options.dataCalls.main.endpoint,
      },
      chartConfig: config,
      dataMapping,
      joins,
      groups,
      wheres,
      chartId: chart.id,
      baseModel: options.dataCalls.main.model,
      endpointParams: options.dataCalls.main.params,
    }));
  };

  const onFormChange = (
    event: React.SyntheticEvent,
    { name, value }: { name: string; value: string }
  ): void => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDataMapperChange = (data: string[]): void => {
    const newState = update(state, {
      dataMapping: {
        data: {
          mappers: {
            $set: data,
          },
        },
      },
    });

    setState(newState);
  };

  const getChartTypes = (): { key: string; text: string; value: string }[] => {
    return _.keys(WidgetComponents).map((name: string) => ({
      key: name,
      text: name,
      value: name,
    }));
  };

  const configChange = (configName: string, name: string, value: any): void => {
    setState(
      update(state, {
        [configName]: {
          [name]: {
            $set: value,
          },
        },
      })
    );
  };

  const pieConfigChange = (
    event: React.SyntheticEvent,
    { name, value }: { name: string; value: string }
  ): void => {
    configChange('widgetConfig', name, value);
  };

  const KPIConfigChange = (
    event: React.SyntheticEvent,
    { name, value }: { name: string; value: string }
  ): void => {
    configChange('KPIConfig', name, value);
  };

  const saveChart = ({ saveNew = false }: { saveNew?: boolean } = {}): void => {
    const {
      widgetConfig,
      baseModel,
      relationFilter,
      dataMapping,
      chartConfig,
      chartId,
      joins,
      groups,
      wheres,
      endpointParams,
    } = state;

    const params = Object.assign({}, endpointParams);
    if (relationFilter)
      params.relationFilter = Object.assign({}, relationFilter);
    const options = {
      type: widgetConfig.type,
      dataCalls: {
        main: {
          model: baseModel,
          endpoint: widgetConfig.endpoint,
          params,
        },
      },
      dataMapping,
      config: {
        ...chartConfig,
        header: widgetConfig.title,
      },
      builder: {
        joins,
        groups,
        wheres,
      },
    };

    chartApi
      .saveChart({
        label: widgetConfig.title,
        options,
        id: saveNew ? null : chartId,
      })
      .then(() => {
        toastr.success('Chart Saved');
      });
  };

  // Render method from the original class component
  // Replace `this.state` with `state`, and `this.someMethod` with `someMethod`

  const {
    groupTestData,
    widgetConfig,
    data,
    count,
    dataMapping,
    chartConfig,
    chartId,
    debugSetValue,
    debugSetDataValue,
    mappedData,
    loading,
    columnHeight,
    apiCalls = [],
  } = state;

  return (
    <Container fluid ref={componentRef}>
      {/* <Ref innerRef={registerRef}> */}
      <Grid columns={2} className="chart-builder">
        <Grid.Column width={10} style={{ height: columnHeight }}>
          <Segment>
            <Header>Config</Header>
            <Grid columns={1} divided padded>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Field>
                      <label>Chart Type</label>
                      <Dropdown
                        selectOnBlur={false}
                        fluid
                        selection
                        name="type"
                        onChange={pieConfigChange}
                        options={getChartTypes()}
                        value={widgetConfig.type}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Field
                        control={Input}
                        placeholder="Title"
                        name="title"
                        onChange={pieConfigChange}
                        value={widgetConfig.title}
                      />
                    </Form.Field>
                    <ChartApiCallsList
                      apiCalls={apiCalls}
                      onAdd={({ selectedCall, name }) => {
                        chartApi
                          .addApiCall({
                            apiCallId: selectedCall.id,
                            name,
                            chartId,
                          })
                          .then(({ data }) => {
                            setState((prevState) => ({
                              ...prevState,
                              apiCalls: [
                                ...prevState.apiCalls,
                                { ...data, apiCall: selectedCall },
                              ],
                            }));
                          });
                      }}
                      onRemove={({ id }) => {
                        chartApi
                          .removeApiCall({ chartApiCallId: id, chartId })
                          .then(() => {
                            setState((prevState) => ({
                              ...prevState,
                              apiCalls: _.filter(prevState.apiCalls, (c) => {
                                return c.id !== id;
                              }),
                            }));
                          });
                      }}
                    />
                    <JSONBuilder
                      label="Chart Config"
                      value={chartConfig}
                      name="chartConfig"
                      onChange={onFormChange}
                      allowModeChange={true}
                    />
                    <JSONBuilder
                      label="Data Mapping"
                      value={dataMapping}
                      name="dataMapping"
                      onChange={onFormChange}
                      allowModeChange={true}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button.Group>
                    <Button onClick={() => saveChart()}>Save Chart</Button>
                    {chartId && (
                      <Button onClick={() => saveChart({ saveNew: true })}>
                        Save New Chart
                      </Button>
                    )}
                  </Button.Group>
                  <Segment>
                    <Header>Data Mapping</Header>
                    {dataMapping?.data?.mappers && (
                      <DataMappers
                        mappers={dataMapping.data.mappers}
                        onChange={handleDataMapperChange}
                        mappedData={mappedData}
                      />
                    )}
                  </Segment>
                  <DebugComponent values={debugSetValue} />
                  <DebugComponent values={debugSetDataValue} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <DebugTable data={groupTestData} />
          <DebugObject obj={{ count, data }} />
        </Grid.Column>
        <Grid.Column width={6} style={{ height: columnHeight }}>
          <DashboardWidgetContainer
            id="widgetBuilderChart"
            type={widgetConfig.type}
            apiCalls={apiCalls}
            dataMapping={dataMapping}
            config={{
              ...chartConfig,
              header: widgetConfig.title,
              refresh: true,
            }}
            extraData={chartConfig.testData}
            showDebug={true}
            useDemoData={true}
            dashboardCallback={(d) => {
              setState((prevState) => ({
                ...prevState,
                debugSetValue: d,
              }));
            }}
            onSetDashboadData={(d) => {
              setState((prevState) => ({
                ...prevState,
                debugSetDataValue: d,
              }));
            }}
            onDataMapped={(data) => {
              setState((prevState) => ({
                ...prevState,
                mappedData: _.get(data, 'data.data'),
              }));
            }}
          />
        </Grid.Column>
      </Grid>
      {/* </Ref> */}
    </Container>
  );
};

export default ErrorBoundary(WidgetBuilderPage);
