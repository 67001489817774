import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Button, Icon, List } from 'semantic-ui-react';
import toastr from 'toastr';
import RelationshipLabel from '../common/labels/RelationshipLabel';
import EntityLabel from '../common/labels/EntityLabel';
import SelectRelationship from './SelectRelationship';

export default class ExecutionPlanActivitySet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      savingRelationship: false,
      savingEntity: false,
    };
  }

  handleAddRelationship = (event, { value }) => {
    const { planActivitySet, onAddRelationship, relationships } = this.props;
    const relationship = _.find(relationships, { id: value });
    this.setState({ savingRelationship: true });
    onAddRelationship(planActivitySet, relationship)
      .then(() => {
        this.setState({ savingRelationship: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ savingRelationship: false });
      });
  };

  removeRelationship = (relationship) => {
    const { planActivitySet, onRemoveRelationship } = this.props;
    this.setState({ savingRelationship: true });
    onRemoveRelationship(planActivitySet, relationship)
      .then(() => {
        this.setState({ savingRelationship: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ savingRelationship: false });
      });
  };

  handleAddEntity = (event, { value }) => {
    const { planActivitySet, onAddEntity, entities } = this.props;
    const entity = _.find(entities, { id: value });
    this.setState({ savingEntity: true });
    onAddEntity(planActivitySet, entity)
      .then(() => {
        this.setState({ savingEntity: false });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ savingEntity: false });
      });
  };

  removeEntity = (entity) => {
    const { planActivitySet, onRemoveEntity } = this.props;
    this.setState({ savingEntity: true });
    onRemoveEntity(planActivitySet, entity)
      .then(() => {
        this.setState({ savingEntity: false });
      })
      .catch((error) => {
        toastr.error(error);
        console.log(error);
        this.setState({ savingEntity: false });
      });
  };

  getRelationshipOptions = () => {
    const { relationships, planActivitySet } = this.props;
    if (relationships) {
      return _.chain(relationships)
        .filter((relationship) => {
          if (relationship.deleted) return false;
          if (!planActivitySet.partyRelationships) return true;
          return (
            _.findIndex(planActivitySet.partyRelationships, {
              id: relationship.id,
            }) === -1
          );
        })
        .map((item, index) => {
          return {
            key: item.id || index,
            content: <RelationshipLabel relationship={item} />,
            value: item.id || item,
          };
        })
        .value();
    } else return [];
  };

  getEntityOptions = () => {
    const { entities, planActivitySet } = this.props;
    if (entities) {
      return _.chain(entities)
        .orderBy(['entityTypeId', 'label'], ['asc', 'asc'])
        .filter((entity) => {
          if (
            planActivitySet.entities &&
            planActivitySet.entities.find((e) => e.id === entity.id)
          )
            return false;
          return true;
        })
        .map((item, index) => {
          return {
            key: item.id || index,
            content: `${item.label} (${item.entityTypeId})`,
            value: item.id || item,
          };
        })
        .value();
    } else return [];
  };

  render() {
    const { planActivitySet, executionPlan, entities } = this.props;
    const { savingRelationship, savingEntity } = this.state;
    return (
      <React.Fragment>
        <SelectRelationship
          label="Assign relationships"
          options={this.getRelationshipOptions()}
          disabled={savingRelationship}
          value={null}
          onChange={this.handleAddRelationship}
        />
        {planActivitySet.partyRelationships &&
          planActivitySet.partyRelationships.length > 0 && (
            <List
              divided
              verticalAlign="middle"
              className="assigned-relationships-list"
            >
              {planActivitySet.partyRelationships &&
                planActivitySet.partyRelationships.map((relationship) => (
                  <List.Item key={relationship.id}>
                    <List.Content floated="right">
                      <Button
                        icon
                        size="mini"
                        onClick={() => {
                          this.removeRelationship(relationship);
                        }}
                        disabled={savingRelationship}
                        loading={savingRelationship}
                      >
                        <Icon name="delete" />
                      </Button>
                    </List.Content>
                    <List.Content>
                      <RelationshipLabel
                        relationship={relationship}
                        linkToSidebar={true}
                        hideFromParty={
                          executionPlan.assignedPartyId ===
                          relationship.fromPartyId
                        }
                      />
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          )}

        <SelectRelationship
          label="Assign entities"
          options={this.getEntityOptions()}
          disabled={savingRelationship}
          value={null}
          onChange={this.handleAddEntity}
        />
        {planActivitySet.entities && planActivitySet.entities.length > 0 && (
          <List
            divided
            verticalAlign="middle"
            className="assigned-relationships-list"
          >
            {planActivitySet.entities &&
              planActivitySet.entities.map((entity) => (
                <List.Item key={entity.id}>
                  <List.Content floated="right">
                    <Button
                      icon
                      size="mini"
                      onClick={() => {
                        this.removeEntity(entity);
                      }}
                      disabled={savingEntity}
                      loading={savingEntity}
                    >
                      <Icon name="delete" />
                    </Button>
                  </List.Content>
                  <List.Content>
                    <EntityLabel entity={entity} />
                  </List.Content>
                </List.Item>
              ))}
          </List>
        )}
      </React.Fragment>
    );
  }
}

ExecutionPlanActivitySet.propTypes = {
  planActivitySet: PropTypes.object,
  executionPlan: PropTypes.object,
  relationships: PropTypes.array,
  entities: PropTypes.array,
  onAddRelationship: PropTypes.func,
  onRemoveRelationship: PropTypes.func,
  onRemove: PropTypes.func,
};
