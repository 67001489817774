/* eslint-disable react/no-danger */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Label, Table } from 'semantic-ui-react';
import Moment from 'react-moment';

import RichTextArea from '../common/fields/RichTextArea';

const ActivityDetails = (props) => {
  const { activity } = props;
  return (
    <Table compact collapsing basic="very" stackable>
      <Table.Body>
        <Table.Row>
          <Table.HeaderCell collapsing>Status:</Table.HeaderCell>
          <Table.Cell>
            <Label color={activity.status.colourId}>
              {activity.status.label}
            </Label>
          </Table.Cell>
        </Table.Row>
        {activity.description ? (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Description:
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <RichTextArea
                editable={false}
                value={activity.description}
                onChange={() => {}}
              />
            </Table.Cell>
          </Table.Row>
        ) : null}
        {activity.ref ? (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Reference:
            </Table.Cell>
            <Table.Cell verticalAlign="top">{activity.ref}</Table.Cell>
          </Table.Row>
        ) : null}
        {activity.primaryTag ? (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Primary Tag:
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <Label>{activity.primaryTag.label.toUpperCase()}</Label>
            </Table.Cell>
          </Table.Row>
        ) : null}
        <Table.Row>
          <Table.Cell collapsing verticalAlign="top">
            Creator:
          </Table.Cell>
          <Table.Cell verticalAlign="top">
            <Label as="a" color="grey" image>
              {activity.creator.firstName} {activity.creator.lastName}
              {/*<Label.Detail>Company Name</Label.Detail>*/}
            </Label>
          </Table.Cell>
        </Table.Row>
        {activity.ownerParty ? (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Owner Party:
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <Label as="a" color="grey" image>
                {activity.ownerParty.label}
                {/*<Label.Detail>Company Name</Label.Detail>*/}
              </Label>
            </Table.Cell>
          </Table.Row>
        ) : null}
        <Table.Row />
        {activity.moreInfo ? (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              More Info:
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <RichTextArea
                editable={false}
                value={activity.moreInfo}
                onChange={() => {}}
              />
            </Table.Cell>
          </Table.Row>
        ) : null}
        {activity.version && (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Version:
            </Table.Cell>
            <Table.Cell verticalAlign="top">{activity.version}</Table.Cell>
          </Table.Row>
        )}
        {activity.versionNotes && (
          <Table.Row>
            <Table.Cell collapsing verticalAlign="top">
              Version Notes:
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <RichTextArea
                editable={false}
                value={activity.versionNotes}
                onChange={() => {}}
              />
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell collapsing verticalAlign="top">
            Created Date:
          </Table.Cell>
          <Table.Cell verticalAlign="top">
            <Moment>{activity.createdAt}</Moment>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing verticalAlign="top">
            Last Modified:
          </Table.Cell>
          <Table.Cell verticalAlign="top">
            <Moment>{activity.modifiedAt}</Moment>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

ActivityDetails.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityDetails;
