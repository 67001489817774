import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { applyResolutionLabelOverride } from '../../../utils/TicketUtils';
import { Header, Message } from 'semantic-ui-react';
import TicketResolutionButtons from '../TicketResolutionButtons';
import TicketTransitionButtons from '../TicketTransitionButtons';
import { TicketContextConsumer } from '../../../context/TicketContext';

const TicketPageActions = (props) => {
  const {
    ticket,
    resolving,
    transitioning,
    ticketActions,
    transitionErrors = [],
    userHasInputPermission,
    currentStatusNode,
    order,
  } = props;
  const { currentResolutionType, id } = ticket;

  return (
    <React.Fragment>
      <div className="ticket-page-actions">
        <div className="column-1">
          {currentStatusNode.status.resolutions.length > 0 && (
            <React.Fragment>
              <Header as="h3" size="small">
                Resolution:
              </Header>
              <TicketResolutionButtons
                resolutions={applyResolutionLabelOverride(
                  currentStatusNode.status,
                  _.get(ticket, 'meta.resolutionLabels')
                )}
                statusResolutions={ticket.statusResolutions}
                onClick={ticketActions.resolutionClicked}
                loading={resolving}
                disabled={!userHasInputPermission}
              />
            </React.Fragment>
          )}
        </div>

        <div className="column-2">
          {currentStatusNode.transitions.length > 0 && (
            <React.Fragment>
              <Header as="h4">Transition this ticket: </Header>
              <TicketTransitionButtons
                currentStatusNode={currentStatusNode}
                onClick={ticketActions.transitionClicked}
                loading={transitioning}
                dropdown={false}
                currentResolutionType={currentResolutionType}
                order={order}
                ticketId={id}
                disabled={!userHasInputPermission}
              />
            </React.Fragment>
          )}
        </div>
      </div>

      {transitionErrors.length > 0 && (
        <Message
          header={`Transitioning is not allowed for the following reason${
            transitionErrors.length > 1 ? 's' : ''
          }:`}
          error
          list={transitionErrors}
        />
      )}
    </React.Fragment>
  );
};

TicketPageActions.propTypes = {
  ticket: PropTypes.object,
  resolving: PropTypes.bool,
  transitioning: PropTypes.bool,
  ticketActions: PropTypes.object,
  transitionErrors: PropTypes.array,
  userHasInputPermission: PropTypes.bool,
  currentStatusNode: PropTypes.object,
  order: PropTypes.number,
};

export default TicketContextConsumer(TicketPageActions);
