import * as types from '../constants/actionTypes';
import initialState from './initialState';
import _ from 'lodash';
import update from 'immutability-helper';

import UIDGenerator from '../utils/UIDGenerator';
import { checkifEdited } from '../utils/React';
import * as programmeTypes from '../constants/storeTypes';
import storeProvider from '../store/storeProvider';
import {
  addPermissionsToActivitiesList,
  addPermissionsToActivity,
} from './helpers/activityHelper';
import * as formBuilderHelper from './helpers/formBuilderHelper';

export default function createProgrammeReducer(name = '') {
  return function programmeReducer(state = initialState.newProgramme, action) {
    switch (action.type) {
      case `${types.LOAD_PROGRAMME_SUCCESS}_${name}`: {
        const currentUser = storeProvider
          .getStore()
          .store.getState().currentUser;
        const { programme } = action;

        let categories = programme.categories.map((c) =>
          Object.assign({}, c, { uid: UIDGenerator.get() })
        );
        const activitySets = programme.activitySets.map((as) => {
          let activities = as.activitiesLink.map((al) => {
            return {
              sort: al.sort,
              ...al.activity,
            };
          });

          activities = _.orderBy(activities, 'sort');

          const activitySet = Object.assign({}, as, {
            uid: UIDGenerator.get(),
            categoryTempId: as.categoryId
              ? _.find(categories, { id: as.categoryId }).uid
              : -1,
            activities: addPermissionsToActivitiesList(currentUser, activities),
          });
          if (name === programmeTypes.NEW) delete activitySet.id;
          delete activitySet.activitiesLink;
          return activitySet;
        });

        if (name === programmeTypes.NEW) {
          delete programme.id;
          delete programme.createdAt;
          delete programme.modifiedAt;
          programme.programStatusId = 1;
          categories = categories.map((c) => {
            delete c.id;
            return c;
          });
        }

        return update(programme, {
          activitySets: { $set: _.orderBy(activitySets, 'sort') },
          categories: { $set: categories },
        });
      }

      case `${types.SAVE_PROGRAMME_SUCCESS}_${name}`: {
        const programme = update(state, {
          edited: { $set: false },
          editedOptions: { $set: false },
        });
        return programme;
      }

      case `${types.ADD_PROGRAMME_FIELD}_${name}`: {
        const { fieldType, label, options } = action;
        const newState = update(state, {
          fields: {
            $set: formBuilderHelper.addField(
              state.fields,
              fieldType,
              label,
              options
            ),
          },
        });
        return checkifEdited(newState, state);
      }

      case `${types.UPDATE_PROGRAMME_FIELD}_${name}`: {
        const { fieldIndex, value } = action;

        const newState = update(state, {
          fields: {
            $set: formBuilderHelper.updateField(
              state.fields,
              fieldIndex,
              value
            ),
          },
        });

        return checkifEdited(newState, state);
      }

      case `${types.REMOVE_PROGRAMME_FIELD}_${name}`: {
        const { status } = action;
        const statusIndex = _.findIndex(state.fields, { uid: status.uid });
        let newState = update(state, {
          fields: { $splice: [[statusIndex, 1]] },
        });
        return checkifEdited(newState, state);
      }

      case `${types.UPDATE_PROGRAMME}_${name}`: {
        const programme = update(state, {
          [action.keyName]: { $set: action.value },
        });
        return checkifEdited(programme, state, 'editedOptions');
      }

      case `${types.PROGRAMME_UPDATE_ACTIVITY_SET}_${name}`: {
        const programme = update(state, {
          activitySets: {
            [action.activitySetIndex]: {
              [action.keyName]: { $set: action.value },
            },
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.PROGRAMME_UPDATE_CATEGORY}_${name}`: {
        const index = _.findIndex(state.categories, {
          uid: action.category.uid,
        });
        const programme = update(state, {
          categories: {
            [index]: {
              [action.keyName]: { $set: action.value },
            },
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.PROGRAMME_REMOVE_CATEGORY}_${name}`: {
        const index = _.findIndex(state.categories, {
          uid: action.category.uid,
        });

        const newActivitySets = state.activitySets.map((as) => {
          if (as.categoryTempId && as.categoryTempId === action.category.uid) {
            return Object.assign({}, as, { categoryTempId: -1 });
          }
          return as;
        });

        const programme = update(state, {
          categories: { $splice: [[index, 1]] },
          activitySets: {
            $set: newActivitySets,
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.PROGRAMME_ADD_CATEGORY}_${name}`: {
        const programme = update(state, {
          categories: {
            $push: [
              {
                label: '',
                uid: UIDGenerator.get(),
              },
            ],
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.ADD_ACTIVITY_TO_SET}_${name}`: {
        const { activitySetId, value } = action;
        const currentUser = storeProvider
          .getStore()
          .store.getState().currentUser;

        const activitySetIndex = _.findIndex(state.activitySets, {
          uid: activitySetId,
        });
        const activitySet = state.activitySets[activitySetIndex];

        if (_.findIndex(activitySet.activities, { id: value.id }) != -1)
          return state;

        let activity = Object.assign({}, action.value, {
          sort: activitySet.activities.length,
        });

        activity = addPermissionsToActivity(currentUser, activity);

        const programme = update(state, {
          activitySets: {
            [activitySetIndex]: { activities: { $push: [activity] } },
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.ADD_ACTIVITY_SET_TO_CATEGORY}_${name}`: {
        const activitySetIndex = _.findIndex(state.activitySets, {
          uid: action.activity.uid,
        });

        const programme = update(state, {
          activitySets: {
            [activitySetIndex]: { categoryTempId: { $set: action.categoryId } },
          },
        });
        return checkifEdited(programme, state);
      }

      case `${types.REMOVE_ACTIVITY_FROM_SET}_${name}`: {
        let activitySet = state.activitySets[action.activitySetIndex];
        const indexToRemove = _.findIndex(activitySet.activities, {
          id: action.value.id,
        });

        let newState = update(state, {
          activitySets: {
            [action.activitySetIndex]: {
              activities: { $splice: [[indexToRemove, 1]] },
            },
          },
        });

        newState.activitySets[action.activitySetIndex].activities =
          newState.activitySets[action.activitySetIndex].activities.map(
            (as, index) => Object.assign({}, as, { sort: index })
          );

        return checkifEdited(newState, state);
      }

      case `${types.REMOVE_ACTIVITY_SET}_${name}`: {
        const programme = update(state, {
          activitySets: { $splice: [[action.activitySetIndex, 1]] },
        });
        return checkifEdited(programme, state);
      }

      case `${types.MOVE_ACTIVITY_SET}_${name}`: {
        const { activitySet, increment } = action;

        const set = _.findIndex(state.activitySets, { uid: activitySet.uid });
        const setToSwap = set + increment;
        if (setToSwap < 0 || setToSwap >= state.activitySets.length)
          return state;

        let oldSort = activitySet.sort;
        if (_.isNil(oldSort)) oldSort = set;

        let proposedNewSort = state.activitySets[setToSwap].sort;
        if (_.isNil(proposedNewSort)) proposedNewSort = setToSwap;

        let programme = update(state, {
          activitySets: {
            [set]: {
              sort: { $set: proposedNewSort },
            },
            [setToSwap]: {
              sort: { $set: oldSort },
            },
          },
        });

        programme = update(state, {
          activitySets: { $set: _.orderBy(programme.activitySets, 'sort') },
        });

        return checkifEdited(programme, state);
      }

      case `${types.MOVE_ACTIVITY_IN_SET}_${name}`: {
        const { increment, activity } = action;

        let activities = _.cloneDeep(
          state.activitySets[action.activitySetIndex].activities
        );
        activities = _.orderBy(activities, 'sort');
        let order = 1;
        for (const activity of activities) {
          activity.sort = order;
          order++;
        }

        const activityIndex = _.findIndex(activities, { id: activity.id });

        if (
          activityIndex === -1 ||
          (activityIndex === 0 && increment === -1) ||
          (activityIndex === activities.length - 1 && increment === 1)
        )
          return state;

        const oldSort = activities[activityIndex].sort;
        const newSort = activities[activityIndex + increment].sort;

        activities[activityIndex].sort = newSort;
        activities[activityIndex + increment].sort = oldSort;

        const newState = update(state, {
          activitySets: {
            [action.activitySetIndex]: {
              activities: { $set: activities },
            },
          },
        });

        return checkifEdited(newState, state);
      }

      case `${types.PROGRAMME_ADD_ACTIVITY_SET}_${name}`: {
        const sortValue =
          _.chain(state.activitySets).maxBy('sort').get('sort') || 0;
        let newActivitySet = Object.assign(
          {},
          initialState.activitySet,
          action.value,
          {
            uid: UIDGenerator.get(),
            label: `Question Set ${state.activitySets.length + 1}`,
            sort: sortValue + 1,
          }
        );
        const programme = update(state, {
          activitySets: { $push: [newActivitySet] },
        });
        return checkifEdited(programme, state);
      }

      case `${types.UPDATE_PROGRAMME_OPTIONS}_${name}`: {
        const newOptions = state.options
          ? { $merge: action.value }
          : { $set: action.value };
        const programme = update(state, {
          options: newOptions,
        });
        return checkifEdited(programme, state, 'editedOptions');
      }

      default:
        return state;
    }
  };
}
