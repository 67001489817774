import React from 'react';
import * as PropTypes from 'prop-types';
import PrincipalRoleTable from '../permissions/PrincipalRoleTable';
import AddUserPermission from '../permissions/AddUserPermission';
import {
  addRoleToPermissions,
  changeRoleInPermissions,
  removeRoleFromPermissions,
} from '../../utils/PermissionsUtils';

const PermissionsBuilder = (props) => {
  const { permissions, onChange } = props;

  const handleAddPermission = (role) => {
    onChange(addRoleToPermissions(permissions, role));
  };
  const handleDeletePermission = (role) => {
    onChange(removeRoleFromPermissions(permissions, role));
  };
  const handleChangePermission = (role) => {
    onChange(changeRoleInPermissions(permissions, role));
  };
  return (
    <div>
      <PrincipalRoleTable
        principalRoles={permissions}
        isAdminPermission={true}
        onDelete={handleDeletePermission}
        onChange={handleChangePermission}
      />
      <AddUserPermission onAdd={handleAddPermission} />
    </div>
  );
};

PermissionsBuilder.propTypes = {
  permissions: PropTypes.array,
  onChange: PropTypes.func,
};

export default PermissionsBuilder;
