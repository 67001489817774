/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react';
import { Form, Checkbox, Item } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';

import FormFields from '../common/FormFields';
import Select from '../common/fields/Select';
import Text from '../common/fields/Text';
import BudgetList from './BudgetList';
import './SaveRelationshipModalForm.scss';

class SaveRelationshipModalForm extends Component {
  state = {
    isPartiesLoading: false,
    validations: this.props.validations,
  };
  componentDidMount() {
    const { parties } = this.props;
    if (!parties.length) {
      const { partyStateActions } = this.props;
      this.setState({ isPartiesLoading: true });
      partyStateActions
        .loadParties()
        .then(() => this.setState({ isPartiesLoading: false }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.validations, prevProps.validations)) {
      this.setState({ validations: this.props.validations });
    }
  }

  getErrors = (propertryName) => {
    const { validations } = this.props;
    const validation = validations[propertryName];
    const isValidationErrors = !_.isEmpty(validation.errors);
    return !validation.hasFocus && isValidationErrors ? validation.errors : {};
  };

  render() {
    const { isPartiesLoading } = this.state;
    const {
      currencies,
      parties,
      relationshipTypes,
      isAllowBudget,
      handleInputChanged,
      handleOnBlur,
      handleOnFocus,
      handleRelationshipChanged,
      getCurrentRelationshipFields,
      onRelationshipTypeChange,
      handleBudgetValueChanged,
      handleBudgetFieldRemove,
      addBudget,
    } = this.props;
    const {
      fromPartyId,
      toPartyId,
      label,
      partyRelationshipTypeId,
      isMutual,
      entries,
      budgets,
    } = this.props.formData;
    const fields = getCurrentRelationshipFields(partyRelationshipTypeId);
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <Select
              name="fromPartyId"
              options={parties ? parties : []}
              label="Entity owner"
              value={fromPartyId}
              errors={this.getErrors('fromPartyId')}
              required={true}
              disabled={isPartiesLoading}
              loading={isPartiesLoading}
              onChange={handleInputChanged}
              onBlur={() => handleOnBlur('fromPartyId')}
              onFocus={() => handleOnFocus('fromPartyId')}
              sortItemsAlphabetically={true}
            />
          </Form.Field>
          <Form.Field>
            <Select
              name="toPartyId"
              options={parties ? parties : []}
              label="Entity relates to"
              value={toPartyId}
              errors={this.getErrors('toPartyId')}
              required={false}
              clearable
              disabled={isPartiesLoading}
              loading={isPartiesLoading}
              onChange={handleInputChanged}
              onBlur={() => handleOnBlur('toPartyId')}
              onFocus={() => handleOnFocus('toPartyId')}
              sortItemsAlphabetically={true}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Text
              label="Label"
              value={label}
              name="label"
              errors={this.getErrors('label')}
              required={true}
              onChange={handleInputChanged}
              onBlur={() => handleOnBlur('label')}
              onFocus={() => handleOnFocus('label')}
            />
          </Form.Field>
          <Form.Field>
            <Select
              name="partyRelationshipTypeId"
              options={relationshipTypes ? relationshipTypes : []}
              label="Select entity type"
              value={partyRelationshipTypeId}
              errors={this.getErrors('partyRelationshipTypeId')}
              required={true}
              onChange={handleRelationshipChanged}
              onBlur={() => handleOnBlur('partyRelationshipTypeId')}
              onFocus={() => handleOnFocus('partyRelationshipTypeId')}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Item.Group relaxed={false} className="item-group-save-relationship">
            <FormFields
              fields={fields}
              onChange={onRelationshipTypeChange}
              entries={entries}
              fieldIdKey="partyRelationshipTypeFieldId"
              fieldsPerRow={2}
            />
          </Item.Group>
        </Form.Field>
        <Form.Group>
          <Form.Field>
            <Checkbox
              label="Is mutual"
              name="isMutual"
              onChange={handleInputChanged}
              defaultChecked={isMutual}
              checked={isMutual}
            />
          </Form.Field>
        </Form.Group>
        {isAllowBudget && (
          <Form.Field>
            <BudgetList
              budgets={budgets}
              addBudget={() => addBudget()}
              currencies={currencies}
              onFieldChange={handleBudgetValueChanged}
              onFieldDelete={handleBudgetFieldRemove}
            />
          </Form.Field>
        )}
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    currencies: state.constants.currencies,
    parties: state.parties.list,
    relationshipTypes: state.constants.partyRelationshipTypes,
  };
}

SaveRelationshipModalForm.propTypes = {
  validations: PropTypes.object,
  currencies: PropTypes.array,
  parties: PropTypes.array,
  relationshipTypes: PropTypes.array,
  isAllowBudget: PropTypes.bool,
  handleInputChanged: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleOnFocus: PropTypes.func,
  handleRelationshipChanged: PropTypes.func,
  getCurrentRelationshipFields: PropTypes.func,
  onRelationshipTypeChange: PropTypes.func,
  handleBudgetValueChanged: PropTypes.func,
  handleBudgetFieldRemove: PropTypes.func,
  addBudget: PropTypes.func,
  partyStateActions: PropTypes.object,
  formData: PropTypes.object,
};

export default connect(mapStateToProps)(SaveRelationshipModalForm);
