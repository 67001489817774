import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import PartyWrapper from './Party';

import AddressesList from './AddressesList';

import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';

const PartyAddresses = ({ party, partyActions }) => {
  const { structuralNodeId } = party;

  const openAddPartyAddressModal = () => {
    partyActions.openSavePartyAddressModal();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="column">
          <AddressesList />
        </div>
      </div>
      <CheckPermission
        structuralNodeId={structuralNodeId}
        permissionType={permissionTypes.ADMIN}
      >
        <div className="row">
          <div className="column">
            <Button primary onClick={openAddPartyAddressModal}>
              Add new address
            </Button>
          </div>
        </div>
      </CheckPermission>
    </React.Fragment>
  );
};

PartyAddresses.propTypes = {
  partyActions: PropTypes.object,
  party: PropTypes.object,
};

export default PartyWrapper(PartyAddresses);
