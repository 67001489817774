import CheckBoxBool from '../CheckBoxBool';

export type ThresholdOptionsType = {
  inverted?: boolean;
};

interface ThresholdOptionsProps {
  value?: ThresholdOptionsType;
  onChange?: (any) => void;
}

const ThresholdOptions = ({ value, onChange }: ThresholdOptionsProps) => {
  const handleChange = (event: null, data: { value: string; name: string }) => {
    if (onChange)
      onChange({
        ...value,
        [data.name]: data.value,
      });
  };
  return (
    <>
      <CheckBoxBool
        name="inverted"
        label="Inverted (Lower is better)"
        value={value?.inverted}
        onChange={handleChange}
      />
    </>
  );
};

export default ThresholdOptions;
