import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Header, Segment, Form, Button } from 'semantic-ui-react';
import * as _ from 'lodash';

import UserWrapper from '../user/User';
import FormFields from '../common/FormFields';
import * as permissionTypes from '../../constants/permissionTypes';
import ExecutionWrapper from './Execution';

import './ExecutionFields.scss';

const ExecutionFields = ({
  fields,
  execution,
  currentExecution,
  executionStateActions,
  currentUser,
}) => {
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const executionNow = execution || currentExecution;

  const { entries } = executionNow;

  const ticketDataEntryAllowed = currentUser.checkPermissionForNode(
    executionNow.structuralNodeId,
    permissionTypes.REVIEW
  );

  const handleOnChange = (field, isValid) => {
    setIsSaveButtonVisible(isValid);
    executionStateActions.updateFieldEntry({ ...field }, field.value);
  };

  const handleSaveClicked = () => {
    setIsSaving(true);
    executionStateActions
      .saveFieldEntries(executionNow, currentUser.details)
      .then(() => {
        setIsSaveButtonVisible(false);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const sortedFields = _.orderBy(fields, 'sort');
  return (
    <Segment>
      <Header as="h3">Programme Details</Header>
      <Form size="small" className="ticket-fields">
        <FormFields
          fields={sortedFields}
          entries={entries}
          onChange={handleOnChange}
          fieldIdKey="programmeFieldId"
          workflowFieldIdKey="id"
          dataEntryAllowed={ticketDataEntryAllowed}
        />
      </Form>
      <Form.Field className="form-actions">
        {isSaveButtonVisible && (
          <Button
            disabled={isSaving || !ticketDataEntryAllowed}
            loading={isSaving}
            onClick={handleSaveClicked}
            inverted
          >
            Save Changes
          </Button>
        )}
      </Form.Field>
    </Segment>
  );
};

ExecutionFields.propTypes = {
  fields: PropTypes.array,
  execution: PropTypes.object,
  currentExecution: PropTypes.object,
  executionStateActions: PropTypes.object,
  currentUser: PropTypes.object,
};

export default ExecutionWrapper(UserWrapper(ExecutionFields));
