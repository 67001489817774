import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';

const Percentage = (props) => {
  const {
    label,
    value,
    onChange,
    disabled = false,
    required,
    editable = true,
    error,
  } = props;

  if (!editable) {
    return <React.Fragment>{value}%</React.Fragment>;
  }

  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Input
        type={'number'}
        placeholder={label}
        value={value || ''}
        onChange={onChange}
        icon="percent"
        disabled={disabled}
      />
    </Form.Field>
  );
};

Percentage.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
};

Percentage.defaultProps = {
  value: '',
};

export default Percentage;
