import React from 'react';

const PartiesContext = React.createContext();

export const PartiesWrapContext = (ComponentToWrap) =>
  class PartiesWrapContext extends React.Component {
    render() {
      return (
        <PartiesContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </PartiesContext.Consumer>
      );
    }
  };

export default PartiesContext;
