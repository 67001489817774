import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import UserSearch from '../common/search/UserSearch';
import { Button, Form, Header } from 'semantic-ui-react';
import UserWrapper from '../user/User';
import toastr from 'toastr';

const LoginAsUser = (props) => {
  const [userId, setUserId] = useState(null);

  const userSelected = (e, { value }) => {
    setUserId(value);
  };

  const login = () => {
    props.userStateActions.loginAsUser(userId).then(() => {
      toastr.success('Logged in as user.');
    });
  };

  return (
    <React.Fragment>
      <Header as="h2">Login As User</Header>
      <Form>
        <Form.Group>
          <Form.Input width={12}>
            <UserSearch
              value={userId}
              onChange={userSelected}
              sortItemsAlphabetically={true}
            />
          </Form.Input>
          <Form.Input width={4}>
            <Button fluid onClick={login}>
              Submit{' '}
            </Button>
          </Form.Input>
        </Form.Group>
      </Form>
    </React.Fragment>
  );
};

LoginAsUser.propTypes = {
  userStateActions: PropTypes.object,
};

export default UserWrapper(LoginAsUser);
