import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Dimmer, Header, Loader } from 'semantic-ui-react';
import { WrapFollowUpContext } from '../../context/FollowUpContext';
import TicketList from '../ticket/TicketList';
import PartyLabel from '../common/labels/PartyLabel';
import TicketRowFollowUp from '../ticket/TicketRowFollowUp';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import ActionButtons from '../common/ActionButtons';

const FollowUpTicketList = (props) => {
  const { followUpList, ticketsById, ticketStateActions, onChange } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const ticketsByIdArray = _.map(ticketsById, (ticket) => ticket.id);
    const tickets = _.without(followUpList, ...ticketsByIdArray);
    if (tickets.length > 0) {
      setLoading(true);
      ticketStateActions.loadTickets({ tickets }).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (onChange) onChange(null, { name: 'tickets', value: followUpList });
  }, [followUpList]);

  const tickets = followUpList.map((id) => ticketsById[id]);
  const executionList = _.chain(tickets)
    .uniqBy('executionId')
    .map()
    .orderBy(['assignedPartyLabel', 'executionLabel'])
    .value();

  const groupedByExecution = _.groupBy(tickets, 'executionId');

  const ticketsByExecution = (executionId) => {
    return groupedByExecution[executionId];
  };

  const handleRemoveTicket = (e) => {
    const { id } = e.data;
    ticketStateActions.removeTicketFromFollowUpList(id);
  };
  const handleRemoveTickets = (ids) => {
    ticketStateActions.removeTicketsFromFollowUpList(ids);
  };

  if (loading)
    return (
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
    );

  return (
    <React.Fragment>
      {_.map(executionList, (execution) => {
        if (!execution) return null;

        const tickets = ticketsByExecution(execution.executionId);
        return (
          <React.Fragment>
            <Header>
              {execution.executionLabel}{' '}
              {execution.executionApplicableDate && (
                <span>
                  {' '}
                  -{' '}
                  <ApplicableDateLabel
                    applicableDateTypeId={
                      execution.executionApplicableDateTypeId
                    }
                    applicableDate={execution.executionApplicableDate}
                  />
                </span>
              )}
              <PartyLabel
                party={{
                  label: execution.assignedPartyLabel,
                  id: execution.assignedPartyId,
                }}
              />
              <ActionButtons
                removeClicked={() => {
                  handleRemoveTickets(tickets.map((ticket) => ticket.id));
                }}
                style={{ float: 'right' }}
              />
            </Header>

            <TicketList
              tickets={ticketsByExecution(execution.executionId)}
              onClick={() => {}}
              loading={false}
              disableInput={true}
              ticketComponent={TicketRowFollowUp}
              extraRowProps={{
                showTicketSet: true,
              }}
              actions={{
                removeClicked: handleRemoveTicket,
              }}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
//onClick={(e) => {handleClick(e,removeClicked);}}
FollowUpTicketList.propTypes = {
  followUpList: PropTypes.array,
  ticketsById: PropTypes.object,
  ticketStateActions: PropTypes.object,
  onChange: PropTypes.func,
};

export default WrapFollowUpContext(FollowUpTicketList);
