import React from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Button, Icon, Table } from 'semantic-ui-react';
import toastr from 'toastr';

import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';
import PartyLabel from '../common/labels/PartyLabel';

const UserListItem = ({
  user,
  editUser,
  resetPassword,
  saveUser,
  showConfirmDialog,
  structuralNodeId,
  userIsAdmin,
  includeParty,
}) => {
  const editUserClicked = () => {
    editUser(user);
  };
  const resetPasswordClicked = () => {
    showConfirmDialog(
      `Are you sure you want to reset the password for ${user.firstName} ${user.lastName}?`,
      resetPasswordOnConfirm
    );
  };
  const resetPasswordOnConfirm = () => {
    resetPassword(user.id).then(resetPasswordOnConfirmCallback);
  };
  const resetPasswordOnConfirmCallback = () => {
    toastr.success(
      `Password reset email has been sent to ${user.firstName} ${user.lastName}`
    );
  };

  const toggleUserClicked = () => {
    showConfirmDialog(
      `Are you sure you want to ${user.inactive ? 'activate' : 'deactivate'} ${
        user.firstName
      } ${user.lastName}?`,
      toggleUserConfirm
    );
  };

  const toggleUserConfirm = async () => {
    try {
      const result = await saveUser(
        user.firstName,
        user.lastName,
        user.email,
        user.partyId,
        user.isAdmin,
        user.id,
        !user.inactive
      );
      if (result) {
        toastr.success(
          `${user.firstName} ${user.lastName} has been ${
            user.inactive ? 'activated' : 'deactivated'
          }`
        );
      } else {
        toastr.error(error);
      }
    } catch (error) {
      toastr.error(error);
    }
  };
  return (
    <Table.Row>
      <Table.Cell>{user.firstName}</Table.Cell>
      <Table.Cell>{user.lastName}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      {includeParty && (
        <Table.Cell>
          <PartyLabel party={user.party} />
        </Table.Cell>
      )}
      <CheckPermission
        structuralNodeId={structuralNodeId}
        permissionType={permissionTypes.ADMIN}
        override={userIsAdmin}
      >
        <Table.Cell>
          {user.loginDetails && user.loginDetails.lastSuccessfulLogin && (
            <Moment>{user.loginDetails.lastSuccessfulLogin}</Moment>
          )}
        </Table.Cell>
        <Table.Cell>
          {user.loginDetails && user.loginDetails.locked ? (
            <Icon color="red" name="x" />
          ) : (
            ''
          )}
        </Table.Cell>
        <Table.Cell>
          {user.inactive ? (
            <Icon color="red" name="x" />
          ) : (
            <Icon color="green" name="check" />
          )}
        </Table.Cell>
        <Table.Cell collapsing textAlign="center">
          <Button onClick={editUserClicked}>Edit User</Button>
          <Button onClick={resetPasswordClicked}>Reset Password</Button>
          <Button onClick={toggleUserClicked}>
            {user.inactive ? 'Activate' : 'Deactivate'} user
          </Button>
        </Table.Cell>
      </CheckPermission>
    </Table.Row>
  );
};

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
  editUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  showConfirmDialog: PropTypes.func.isRequired,
  structuralNodeId: PropTypes.number,
  userIsAdmin: PropTypes.bool,
  includeParty: PropTypes.bool,
};

export default UserListItem;
