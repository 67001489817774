import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import PartyLabel from '../common/labels/PartyLabel';
import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';

const ExecutionPlanListItem = ({ executionPlan, executionPlanActions }) => {
  return (
    <Table.Row>
      <Table.Cell singleLine>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            executionPlanActions.gotoExecutionPlan(executionPlan);
          }}
        >
          {executionPlan.label}
        </a>
      </Table.Cell>
      <Table.Cell>
        {executionPlan.ownerParty && (
          <PartyLabel party={executionPlan.ownerParty} />
        )}
      </Table.Cell>
      <Table.Cell>
        {executionPlan.assignedParty && (
          <PartyLabel party={executionPlan.assignedParty} />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

ExecutionPlanListItem.propTypes = {
  executionPlan: PropTypes.object,
  executionPlanActions: PropTypes.object.isRequired,
};

export default ExecutionPlanWrapContext(ExecutionPlanListItem);
