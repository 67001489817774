import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import programmeApi from '../../api/programmeApi';
import ProgrammeList from './ProgrammeList';

class ProgrammeListLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programmes: [],
    };
  }

  componentDidMount() {
    programmeApi.getProgrammes().then((result) => {
      this.setState({
        programmes: result,
        loading: false,
      });
    });
  }

  render() {
    const { programmes } = this.state;
    const { onSelect } = this.props;
    return <ProgrammeList programmes={programmes} onSelect={onSelect} />;
  }
}

ProgrammeListLoader.propTypes = {
  onSelect: PropTypes.func,
};

export default ProgrammeListLoader;
