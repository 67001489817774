import update from 'immutability-helper';
import _ from 'lodash';

import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function attachmentsReducer(
  state = initialState.attachments,
  action
) {
  switch (action.type) {
    case `${types.LOAD_ATTACHMENTS_SUCCESS}`: {
      const { attachments } = action;
      const newAttachments = _.keyBy(attachments, 'id');
      return update(state, {
        data: { $set: newAttachments },
      });
    }
    case `${types.UPDATE_ATTACHMENT_SUCCESS}`: {
      const { attachment, currentUser } = action;

      const attachmentId = attachment.id;
      return update(state, {
        data: {
          [attachmentId]: {
            $set: { ...attachment, creator: currentUser, errorMessage: '' },
          },
        },
      });
    }
    case `${types.UPDATE_ATTACHMENT_FAILED}`: {
      const { attachmentId, error } = action;
      const currentAttachment = state.data[attachmentId];
      return update(state, {
        data: {
          [attachmentId]: {
            $set: { ...currentAttachment, errorMessage: error.message },
          },
        },
      });
    }
    default:
      return state;
  }
}
