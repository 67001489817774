import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import workflowApi from '../../api/workflowApi';
import WorkflowList from './WorkflowList';

class WorkflowListLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflows: [],
    };
  }

  componentDidMount() {
    workflowApi.getWorkflows().then((result) => {
      this.setState({
        workflows: result,
        loading: false,
      });
    });
  }

  render() {
    const { workflows } = this.state;
    const { onSelect } = this.props;
    return <WorkflowList workflows={workflows} onSelect={onSelect} />;
  }
}

WorkflowListLoader.propTypes = {
  onSelect: PropTypes.func,
};

export default WorkflowListLoader;
