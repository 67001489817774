import { Container, Header } from 'semantic-ui-react';

import QueueDetails from './QueueDetails';

interface Props {
  match?: {
    params?: {
      id?: string;
    };
  };
}

const QueuePage = (props: Props) => {
  const queueId = props.match.params.id;

  return <Container>{queueId && <QueueDetails queueId={queueId} />}</Container>;
};

export default QueuePage;
