import update from 'immutability-helper/index';

export default function arrayReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_MAIN_VALUE': {
      const { value } = action;
      return { ...state, value };
    }
    case 'UPDATE_VALUE': {
      const { index, value } = action;
      const newState = update(state, {
        value: {
          [index]: {
            $set: value,
          },
        },
      });
      return newState;
    }
    case 'REMOVE_VALUE': {
      const { index } = action;
      const newState = update(state, {
        value: {
          $splice: [[index, 1]],
        },
      });
      return newState;
    }
    case 'DUPLICATE_VALUE': {
      const { index } = action;

      const newState = update(state, {
        value: {
          $push: [state.value[index]],
        },
      });
      return newState;
    }
    case 'MOVE_VALUE_UP': {
      const { index } = action;
      if (index === 0) return state;
      const newState = update(
        update(state, {
          value: { $splice: [[index, 1, state.value[index - 1]]] },
        }),
        {
          value: {
            $splice: [[index - 1, 1, state.value[index]]],
          },
        }
      );
      return newState;
    }
    case 'MOVE_VALUE_DOWN': {
      const { index } = action;
      if (index === state.value.length - 1) return state;
      const newState = update(
        update(state, {
          value: { $splice: [[index, 1, state.value[index + 1]]] },
        }),
        {
          value: {
            $splice: [[index + 1, 1, state.value[index]]],
          },
        }
      );
      return newState;
    }
    case 'ADD_VALUE': {
      const { value } = action;
      const newState = update(state, {
        value: {
          $push: [value],
        },
      });
      return newState;
    }
    default:
      return state;
  }
}
