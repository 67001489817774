import { Container } from 'semantic-ui-react';
import BulkImportEntity from './BulkImportEntity';

interface Props {
  match: {
    params: {
      id: string;
      type: string;
    };
  };
}

const BulkImportEntityPage = (props: Props) => {
  return (
    <Container>
      <BulkImportEntity
        type={props.match.params.type}
        partyId={props.match.params.id}
      />
    </Container>
  );
};

export default BulkImportEntityPage;
