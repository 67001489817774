import qs from 'query-string';
import * as _ from 'lodash';

const QS_OPTIONS = { arrayFormat: 'comma' };

const setQueryStringWithoutPageReload = (qsValue) => {
  const { protocol, host, pathname, search } = window.location;
  const baseUrl = `${protocol}//${host}${pathname}`;
  const newurl = qsValue.length > 1 ? `${baseUrl}${qsValue}` : `${baseUrl}`;
  if (search !== qsValue) {
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const setQueryStringValue = (value) => {
  const stringifiedValue = getStringifiedValue(value);

  setQueryStringWithoutPageReload(`?${stringifiedValue}`);
};

export const getQueryStringValue = (
  queryString = window.location.search,
  shouldReturnArray = true
) => {
  const parsed = qs.parse(queryString, QS_OPTIONS);
  const parsedObject = shouldReturnArray ? makeArray(parsed) : parsed;
  return parsedObject;
};

export const getStringifiedValue = (value) => {
  return qs.stringify(value, QS_OPTIONS);
};

const makeArray = (parsedObject) => {
  const keys = _.keys(parsedObject);
  const result = {};
  _.forEach(keys, (key) => {
    const property = parsedObject[key];
    const isArray = _.isArray(property);
    const array = isArray ? property : [property];
    result[key] = array;
  });
  return result;
};
