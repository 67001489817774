const fieldOptions = {
  dashboard: [
    {
      id: 'formats',
      required: false,
      label: 'Formats',
      activityFieldType: 'CheckboxGroup',
      options: { values: ['print', 'docx', 'xlsx', 'csv', 'pdf'] },
      args: {
        outputArray: true,
      },
    },
    {
      id: 'hideHeaderForPrint',
      required: false,
      label: 'Hide header for print',
      activityFieldType: 'YesNo',
      options: null,
    },
    {
      id: 'customFileName',
      required: false,
      label: 'Custom File Name',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'dashboardProperties',
      required: false,
      label: 'Dashboard Properties',
      activityFieldType: 'JSONBuilder',
      options: null,
      args: {
        allowModeChange: true,
      },
    },{
      id: 'metricReport',
      required: false,
      label: 'Metric Report',
      activityFieldType: 'ModelSearchField',
      options: {
        modelName: 'StatusReport',
        valueProperty: 'id',
      },
    },
    {
      id: 'metricReportDimension',
      required: false,
      label: 'Metric Report Dimension',
      activityFieldType: 'Select',
      options: { values: ['all'
      , 'applicableDate' 
      , 'assignedPartyId'
      , 'assignedEntityId'] 
    },
      args: {
        outputArray: true,
      },
    },
    
  ],
  column: [
    {
      id: 'width',
      required: false,
      label: 'Width',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'sort',
      required: false,
      label: 'Sort',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'gridArea',
      required: false,
      label: 'Grid Area',
      activityFieldType: 'Text',
      options: null,
    },
  ],
  row: [
    {
      id: 'sort',
      required: false,
      label: 'Sort',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'height',
      required: false,
      label: 'Height',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'showOnTab',
      required: false,
      label: 'Show on tab',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'showIfPropertySet',
      required: false,
      label: 'Show if property set',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'hideForPrint',
      required: false,
      label: 'Hide for print',
      activityFieldType: 'YesNo',
      options: null,
    },
    {
      id: 'pageBreakBefore',
      required: false,
      label: 'Page break before row',
      activityFieldType: 'YesNo',
      options: null,
    },
    {
      id: 'gridMode',
      required: false,
      label: 'Grid Mode',
      activityFieldType: 'YesNo',
      options: null,
    },
    {
      id: 'gridModeColumns',
      required: false,
      label: 'Grid Mode Columns',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'gridModeRows',
      required: false,
      label: 'Grid Mode Rows',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'extraStyles',
      required: false,
      label: 'CSS',
      activityFieldType: 'JSONBuilder',
      options: null,
      args: {
        allowModeChange: true,
      },
    },
  ],
  item: [
    {
      id: 'sort',
      required: false,
      label: 'Sort',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'width',
      required: false,
      label: 'Width',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'maxHeight',
      required: false,
      label: 'Max Height',
      activityFieldType: 'Number',
      options: null,
    },
    {
      id: 'header',
      required: false,
      label: 'Header',
      activityFieldType: 'Text',
      options: null,
    },
    {
      id: 'hideHeader',
      required: false,
      label: 'Hide Header',
      activityFieldType: 'CheckBoxBool',
      options: null,
    },
    {
      id: 'extraPadding',
      required: false,
      label: 'Extra Padding',
      activityFieldType: 'CheckBoxBool',
      options: null,
    },
    {
      id: 'plainStyle',
      required: false,
      label: 'Plain Style',
      activityFieldType: 'CheckBoxBool',
      options: null,
    },
    {
      id: 'disableGrow',
      required: false,
      label: 'Disable Grow',
      activityFieldType: 'YesNo',
      options: null,
    },
    {
      id: 'extraMapping',
      required: false,
      label: 'ExtraMapping',
      activityFieldType: 'JSONBuilder',
      options: null,
      args: {
        allowModeChange: true,
      },
    },
    {
      id: 'extraStyles',
      required: false,
      label: 'CSS',
      activityFieldType: 'JSONBuilder',
      options: null,
      args: {
        allowModeChange: true,
      },
    },
    {
      id: 'apiParams',
      required: false,
      label: 'Extra API Params',
      activityFieldType: 'JSONBuilder',
      options: null,
      args: {
        allowModeChange: true,
      },
    },
  ],
};

export default fieldOptions;
