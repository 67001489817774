import { MouseEvent } from 'react';
import { Header, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import {
  colors,
  kpiValueFormats,
  lightColors,
} from '../../../../../constants/config';
import { ComponentSize } from '../../../../../types';
import { formatValue } from '../../../../../utils/FormatUtils';

interface FirstStyleKpiComponentProps {
  color?: SemanticCOLORS;
  iconName?: SemanticICONS;
  label?: string;
  header?: string;
  value: number | string;
  format?: string;
  classNames?: string;
  currencyType?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  labelSize?: ComponentSize;
  valueSize?: ComponentSize;
}

export const FirstStyleKpiComponent = ({
  color,
  iconName,
  label,
  header,
  value,
  format = kpiValueFormats.Number,
  currencyType,
  onClick,
  classNames = '',
  labelSize = 'medium',
  valueSize = 'medium',
}: FirstStyleKpiComponentProps) => {
  return (
    <a
      className={classNames}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : '',
      }}
    >
      <div
        className="highlight-bar"
        style={{
          background: `linear-gradient(0deg, ${lightColors[color]} 0%,  ${colors[color]} 100%)`,
        }}
      />
      <div className="content-component-kpi">
        {iconName && (
          <div className="icon-wrapper-component-kpi">
            <Icon
              name={iconName}
              className="icon-component-kpi"
              color={color}
            />
          </div>
        )}

        <div className="values-component-kpi">
          <div className="label-component-kpi">
            <Header size={labelSize}>{label || header}</Header>
          </div>
          <div className="value-component-kpi">
            <Header size={valueSize}>
              {formatValue(value, format, currencyType)}
            </Header>
          </div>
        </div>
      </div>
    </a>
  );
};
