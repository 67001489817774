import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import EventListDropDownContainer from './EventListDropDownContainer';
import { markNewEvents } from '../../../actions/eventsActions';
import EventList from './EventList';

const EventMenuItem = ({ newEvents, markNewEvents, loading, events }) => {
  return (
    <Dropdown
      item
      icon={
        <span>
          <Icon name="bell" color={newEvents ? 'blue' : 'black'} size="large" />
          {!!newEvents && newEvents}
        </span>
      }
      className="right"
      selectOnBlur={false}
      onClick={() => newEvents && markNewEvents()}
    >
      <Dropdown.Menu style={{ padding: '1em' }}>
        <EventList
          className="event-list-dropdown"
          loading={loading}
          events={events}
          showComparison={false}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

EventMenuItem.propTypes = {
  newEvents: PropTypes.number,
  markNewEvents: PropTypes.func,
  events: PropTypes.object,
  loading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    newEvents: state.events.newEvents,
    events: state.events,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    markNewEvents: bindActionCreators(markNewEvents, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventMenuItem);
