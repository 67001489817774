import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Table, Input, Button, Dropdown, Form } from 'semantic-ui-react';
import StatusDropdown from '../common/StatusDropdown';
import TagsInput from '../common/TagsInput';
import { mapToOptions } from '../../utils/React';
import * as nodeTypes from '../../constants/nodeTypes';
import * as permissionTypes from '../../constants/permissionTypes';

const ActivitiesFilterRow = (props) => {
  const {
    filters,
    updateFilterValue,
    loadActivities,
    resetFilter,
    activityStatuses,
    tags,
    parties,
    currentUser,
    asTableRow = true,
  } = props;

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') loadActivities(event);
  };

  const buildFields = (FieldType, inline = true) => {
    return (
      <React.Fragment>
        <FieldType>
          <Input
            value={filters.label}
            onChange={updateFilterValue}
            name="label"
            onKeyUp={handleKeyUp}
            size="mini"
            fluid
            placeholder="Search by label"
          />
        </FieldType>
        <FieldType>
          <StatusDropdown
            statuses={
              activityStatuses
                ? [
                    { id: 0, label: 'ALL', colour: { name: 'grey' } },
                    ..._.map(activityStatuses),
                  ]
                : []
            }
            onChange={(event, data) => {
              updateFilterValue(event, data, true);
            }}
            value={filters.activityStatusId}
            name="activityStatusId"
            inline={inline}
          />
        </FieldType>
        <FieldType>
          <TagsInput
            tags={tags}
            value={filters.primaryTag || {}}
            onChange={(e, data) => {
              updateFilterValue(
                e,
                {
                  name: 'primaryTag',
                  value: data.value,
                },
                true
              );
            }}
            name="primaryTag"
            multiple={false}
            size="mini"
            inline={inline}
          />
        </FieldType>
        <FieldType>
          <TagsInput
            tags={tags}
            value={filters.tags}
            onChange={updateFilterValue}
            name="tags"
            multiple={true}
            size="mini"
            inline={inline}
          />
        </FieldType>
        <FieldType>
          <Input
            value={filters.ref}
            onChange={updateFilterValue}
            name="ref"
            onKeyUp={handleKeyUp}
            size="mini"
            placeholder="Search by reference"
          />
        </FieldType>
        <FieldType>
          <Dropdown
            options={mapToOptions(
              currentUser.nodesList(
                nodeTypes.QuestionGroup,
                permissionTypes.VIEW
              )
            )}
            onChange={(e, data) => {
              updateFilterValue(e, data, true);
            }}
            value={filters.structuralNodeId || null}
            placeholder="Group"
            name="structuralNodeId"
            size="mini"
            fluid
            selection={!inline}
            inline={inline}
          />
        </FieldType>
        <FieldType>
          <Dropdown
            options={mapToOptions(parties, null, true)}
            onChange={(e, data) => {
              updateFilterValue(e, data, true);
            }}
            value={filters.ownerPartyId}
            placeholder="Owner Party"
            name="ownerPartyId"
            size="mini"
            fluid
            selection={!inline}
            inline={inline}
          />
        </FieldType>
        <FieldType>
          <Button.Group size="mini">
            <Button onClick={loadActivities} icon="refresh" />
            <Button onClick={resetFilter} icon="undo" />
          </Button.Group>
        </FieldType>
      </React.Fragment>
    );
  };

  return asTableRow ? (
    <Table.Row className="activity-filter-row">
      {buildFields(Table.Cell)}
    </Table.Row>
  ) : (
    <Form className="activity-filter-row">
      {buildFields(Form.Field, false)}
    </Form>
  );
};

ActivitiesFilterRow.propTypes = {
  filters: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateFilterValue: PropTypes.func,
  loadActivities: PropTypes.func,
  resetFilter: PropTypes.func,
  activityStatuses: PropTypes.object,
  tags: PropTypes.array,
  parties: PropTypes.array,
  asTableRow: PropTypes.bool,
};

export default ActivitiesFilterRow;
