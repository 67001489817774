import React, { Component } from 'react';
import { Header, Dimmer, Loader, Button } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';

import AdminWrapper from './Admin';
import ThresholdsList from './ThresholdsList';
import Pagination from '../common/Pagination';
import FilterAndPaginate from '../common/FilterAndPaginate';

class ManageRiskThresholds extends Component {
  static propTypes = {
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    constantStateActions: PropTypes.object,
    adminActions: PropTypes.object,
  };

  state = {
    loading: false,
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const { loadThresholds } = this.props.constantStateActions;
    loadThresholds()
      .then(() => this.setState({ loading: false }))
      .catch((error) => {
        toastr.error(error);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      paginatedItems,
      handleSortChange,
      handlePaginationChange,
      paginationOptions,
      adminActions,
    } = this.props;
    const { loading } = this.state;
    const openSaveAdminThresholdModal = () => {
      adminActions.openSaveAdminThresholdModal();
    };
    return (
      <React.Fragment>
        {loading && (
          <Dimmer active={loading} inverted>
            <Loader disabled={!loading} />
          </Dimmer>
        )}
        {paginatedItems && (
          <React.Fragment>
            <Header as="h2">Manage Risk Thresholds</Header>
            <ThresholdsList
              riskThresholds={paginatedItems || []}
              paginationOptions={paginationOptions}
              handleSortChange={handleSortChange}
              adminActions={adminActions}
            />
            <Pagination
              activePage={paginationOptions.activePage}
              onPageChange={handlePaginationChange}
              totalPages={paginationOptions.totalPages}
              style={{ float: 'right' }}
            />
            <Button primary onClick={openSaveAdminThresholdModal}>
              Add new risk threshold
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapOptions(props) {
  return {
    items: props.riskThresholds || [],
  };
}

export default AdminWrapper(
  FilterAndPaginate(mapOptions)(ManageRiskThresholds)
);
