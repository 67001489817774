import React from 'react';
import * as PropTypes from 'prop-types';
import { Form, Rating } from 'semantic-ui-react';
import FieldToolTip from './FieldToolTip';

const StarRating = (props) => {
  const { label, value, onChange, name, disabled = false, required, error } = props;
  return (
    <Form.Field required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Rating
        size="huge"
        rating={value ? value : null}
        maxRating={5}
        onRate={(event, data) => {
          onChange(event, {
            value: data.rating.toString(),
            name,
          });
        }}
        disabled={disabled}
      />
    </Form.Field>
  );
};

StarRating.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
};

StarRating.defaultProps = {
  value: '',
};

export default StarRating;
