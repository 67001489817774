import { Icon } from 'semantic-ui-react';
import DataTableStyledCell from './dashboard/reportComponents/DataTableStyledCell';
import InstanceLink from './InstanceLink';
import AnalyseAndImplement from './dashboard/reportComponents/AnalyseAndImplement';
import ReportList from './dashboard/reportComponents/ReportList';
import TicketResolutionIcons from '../ticket/TicketResolutionIcons';
import ReportAttachmentList from './dashboard/components/ReportAttachmentList';
import Markdown from './dashboard/components/Markdown';

export default {
  Icon: Icon,
  TicketResolutionIcons: TicketResolutionIcons,
  StyledCell: DataTableStyledCell,
  InstanceLink: InstanceLink,
  Markdown: Markdown,
  AnalyseAndImplement: AnalyseAndImplement,
  ReportList: ReportList,
  ReportAttachmentList: ReportAttachmentList,
};
