import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Form, Header, Message } from 'semantic-ui-react';

import './FormSectionHeader.scss';
import { Field } from '../../../types';

interface Props extends Pick<Field, 'label' | 'description'> {
  options?: { style: 'Message' | 'Header'; args: any };
}

const FormSectionHeader = (props: Props) => {
  const { label, description, options } = props;

  const args = options?.args || {};

  if (options?.style === 'Message') {
    return (
      <Form.Field className="form-section-header" width={16}>
        <Message {...args}>
          <Message.Header>{label}</Message.Header>
          {description && <p>{description}</p>}
        </Message>
        <Divider hidden />
      </Form.Field>
    );
  }

  return (
    <Form.Field className="form-section-header" width={16}>
      <Header size="large" {...args}>
        {label}
      </Header>
      {description && <div>{description}</div>}
    </Form.Field>
  );
};

FormSectionHeader.propTypes = {
  label: PropTypes.string,
};

export default FormSectionHeader;
