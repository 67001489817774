import * as types from '../constants/actionTypes';
import update from 'immutability-helper';

export default function dashboardReducer(state = {}, action) {
  switch (action.type) {
    case types.LOAD_DASHBOARD_WIDGET_DATA_SUCCESS: {
      const { data, dataCallsHash } = action;
      return update(state, { $merge: { [`${dataCallsHash}`]: { data } } });
    }
    default:
      return state;
  }
}
