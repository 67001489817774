import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Segment, Loader, Dimmer, Header } from 'semantic-ui-react';
import * as _ from 'lodash';

import AttachmentsList from './AttachmentsList';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';

const AddAttachmentModalRecent = (props) => {
  const [editingMode, switchEditingMode] = useState(0);
  const {
    loading,
    attachmentsData,
    selectedAttachments,
    attachmentsConstants,
    attachmentStateActions,
  } = props;
  const { addValue, removeValue } = props.attachmentActions;
  const activeIndexes = _.map(
    selectedAttachments,
    (selectedAttachment) => selectedAttachment.id
  );
  const attachments = _.reverse(
    _.map(attachmentsData, (attachment) => {
      return attachment;
    })
  );
  return (
    <Segment
      className={`recent-attachments-segment ${editingMode && 'editing-mode'}`}
    >
      <Dimmer active={loading} inverted>
        <Loader disabled={!loading} />
      </Dimmer>
      {attachments && attachments.length > 0 ? (
        <AttachmentsList
          attachments={attachments || []}
          allowSelection={true}
          showDetails={false}
          allowEditDetails={false}
          activeIndexes={activeIndexes}
          onAddValue={addValue}
          onRemoveValue={removeValue}
          attachmentsConstants={attachmentsConstants}
          switchEditingMode={(editingModeEnable) =>
            switchEditingMode(editingModeEnable)
          }
          attachmentStateActions={attachmentStateActions}
        />
      ) : (
        <Header as="h4">There is no data available</Header>
      )}
    </Segment>
  );
};

AddAttachmentModalRecent.propTypes = {
  loading: PropTypes.bool,
  attachmentsData: PropTypes.array,
  selectedAttachments: PropTypes.array,
  attachmentActions: PropTypes.object,
  attachmentsConstants: PropTypes.object,
  attachmentStateActions: PropTypes.object,
};

export default WrapAttachmentsContext(AddAttachmentModalRecent);
