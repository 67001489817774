import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import partyApi from '../../../api/partyApi';
import RelationshipLabel from '../labels/RelationshipLabel';
import SelectRelationship from '../../executionPlan/SelectRelationship';
import * as PropTypes from 'prop-types';

const RelationshipEntitySearch = (props) => {
  const { onChange, partyId, relationshipType, label } = props;

  const [options, setOptions] = useState([]);
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getRelationships() {
    setLoading(true);
    const where = {};

    if (relationshipType) where.partyRelationshipTypeId = relationshipType;
    const relationships = await partyApi.getPartyRelationships(partyId, {
      where,
      include: [
        'relationshipType',
        'toParty',
        'fromParty',
        'budgets',
        'entries',
      ],
    });
    setEntities(relationships);
    const mapped = relationships.map((rel) => ({
      key: rel.id,
      content: <RelationshipLabel relationship={rel} />,
      text: rel.label,
      value: rel.id,
    }));

    setOptions(mapped);

    setLoading(false);
  }

  const handleSelection = (event, data) => {
    const entity = _.find(entities, { id: data.value });

    onChange(event, entity);
  };

  useEffect(() => {
    getRelationships();
  }, []);

  return (
    <div>
      <SelectRelationship
        label={label}
        options={options}
        // disabled={savingRelationship}
        value={null}
        onChange={handleSelection}
        loading={loading}
      />
    </div>
  );
};

export default RelationshipEntitySearch;

RelationshipEntitySearch.propTypes = {
  onChange: PropTypes.func,
  partyId: PropTypes.string,
  relationshipType: PropTypes.string,
  label: PropTypes.string,
};
