import update from 'immutability-helper/index';
import { checkifEdited } from '../../utils/React';
import _ from 'lodash';

export function updateTicketResolution(
  ticket,
  newResolution,
  resolutionProperty
) {
  const resolution = {
    ...newResolution,
    resolution: resolutionProperty,
  };
  const currentResolutionIndex = _.findIndex(ticket.statusResolutions, {
    statusId: newResolution.statusId,
  });
  let newTicket = update(ticket, {
    saving: { $set: false },
    statusResolutions:
      currentResolutionIndex !== -1
        ? { [currentResolutionIndex]: { $set: resolution } }
        : { $push: [resolution] },
    currentResolutionType: {
      $set: resolutionProperty.activityResolutionTypeId,
    },
  });
  return newTicket;
}

export function updateRemoveTicketResolution(ticket, statusId) {
  const currentResolutionIndex = _.findIndex(ticket.statusResolutions, {
    statusId,
  });
  let newTicket = update(ticket, {
    saving: { $set: false },
    currentResolutionType: { $set: null },
    statusResolutions: { $splice: [[currentResolutionIndex, 1]] },
  });
  return newTicket;
}

export function updateFieldEntry(ticket, activityField, value, edited = true) {
  let newTicketValue = {};
  const fieldsArray = activityField.workflowStatusId
    ? 'workflowEntries'
    : 'entries';
  const fieldKey = activityField.workflowStatusId
    ? 'workFlowStatusFieldId'
    : 'activityFieldId';

  const index = _.findIndex(ticket[fieldsArray], {
    [fieldKey]: activityField.id,
  });
  if (index !== -1) {
    newTicketValue = update(ticket, {
      [fieldsArray]: {
        [index]: {
          value: { $set: value },
          edited: { $set: edited },
          field: { $set: activityField },
        },
      },
    });
  } else {
    newTicketValue = update(ticket, {
      [fieldsArray]: {
        $push: [
          {
            value: value,
            edited: edited,
            [fieldKey]: activityField.id,
            fieldKey: activityField.fieldKey,
            field: activityField,
          },
        ],
      },
    });
  }
  return checkifEdited(newTicketValue, ticket, 'edited', fieldsArray);
}
