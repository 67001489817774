/* eslint-disable react/display-name */

import React, { useState, useEffect, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Tab } from 'semantic-ui-react';

import Conversation from '../conversation/Conversation';
import { TicketContextConsumer } from '../../context/TicketContext';
import { WrapUserContext } from '../../context/UserContext';
import AttachmentsListContainer from '../common/attachments/AttachmentsListContainer';
import TicketHistory from './TicketHistory';
import TicketDeadlines from './TicketDeadlines';
import TicketLinks from './TicketLinks';
import TicketFields from './TicketFields/TicketFields';

const TicketTabs = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {
    currentTicket,
    ticketStateActions,
    ticketActions,
    attachmentsConstants,
    currentStatusOverdue,
    currentUser,
  } = props;

  const thresholdFields = currentTicket?.activity?.fields?.filter(
    (field) => field.activityFieldTypeId === 'Threshold'
  );

  const panes = useMemo(() => {
    const panes = [
      {
        menuItem: 'Comments',
        render: () => (
          <Tab.Pane>
            <Conversation
              conversationId={currentTicket.conversationId}
              type="Comment"
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Attachments',
        render: () => (
          <Tab.Pane>
            <AttachmentsListContainer
              modelStateActions={ticketStateActions}
              currentModel={currentTicket}
              modelActions={ticketActions}
              attachmentsConstants={attachmentsConstants}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'History',
        render: () => (
          <Tab.Pane>
            <TicketHistory currentTicket={currentTicket} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Deadlines',
        render: () => (
          <Tab.Pane>
            <TicketDeadlines
              currentTicket={currentTicket}
              currentStatusOverdue={currentStatusOverdue}
            />
          </Tab.Pane>
        ),
      },
    ];
    if (thresholdFields && thresholdFields.length > 0) {
      panes.push({
        menuItem: 'Thresholds',
        render: () => (
          <Tab.Pane>
            <TicketFields
              entries={currentTicket.entries}
              fields={thresholdFields}
              onSave={() =>
                ticketStateActions.saveActivityFields(
                  currentTicket,
                  currentUser.details
                )
              }
              showFieldKeys={true}
            />
          </Tab.Pane>
        ),
      });
    }
    return panes;
  }, [thresholdFields]);

  const { toTicketRelationships, fromTicketRelationships } = currentTicket;

  const showTicketLinksTab =
    _.get(toTicketRelationships, 'length') ||
    _.get(fromTicketRelationships, 'length');

  if (showTicketLinksTab) {
    panes.push({
      menuItem: 'Ticket Links',
      render: () => (
        <Tab.Pane>
          <TicketLinks currentTicket={currentTicket} />
        </Tab.Pane>
      ),
    });
  }

  useEffect(() => {
    if (activeTabIndex >= panes.length) {
      setActiveTabIndex(0);
    }
  }, [currentTicket.id]);

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTabIndex(activeIndex);
  };

  return (
    <Tab
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
      panes={panes}
    />
  );
};

TicketTabs.propTypes = {
  currentTicket: PropTypes.object,
  recommendationOverride: PropTypes.string,
  ticketStateActions: PropTypes.object,
  ticketActions: PropTypes.object,
  openAddAttachmentDialog: PropTypes.func,
  openFileDialog: PropTypes.func,
  attachmentsConstants: PropTypes.object,
  currentStatusOverdue: PropTypes.bool,
};

export default WrapUserContext(TicketContextConsumer(TicketTabs));
