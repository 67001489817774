import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Input, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import StatusDropdown from '../common/StatusDropdown';
import ActivityResolutionList from '../activity/ActivityResolutionList';
import FormBuilder from '../common/FormBuilder';

const WorkflowStatusListRow = (props) => {
  const {
    status,
    onChange,
    onRemove,
    statusTypes,
    activityResolutionTypes,
    activityFieldTypes,
    toggleResolution,
    onResolutionChange,
    addField,
    onFieldChange,
  } = props;
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>
          <Input value={status.name || ''} name="name" onChange={onChange} />
        </Table.Cell>
        <Table.Cell>
          <Input
            value={status.key || ''}
            name="key"
            onChange={onChange}
            placeholder="Key"
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            value={status.order || ''}
            name="order"
            onChange={onChange}
            placeholder="Order"
          />
        </Table.Cell>
        <Table.Cell>
          <StatusDropdown
            statuses={statusTypes}
            onChange={onChange}
            value={status.statusTypeId}
            name="statusTypeId"
          />
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            label="Add resolution"
            checked={status.addResolution}
            name="addResolution"
            onChange={onChange}
          />
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            label="Allow data entry"
            checked={status.allowDataEntry}
            name="allowDataEntry"
            onChange={onChange}
          />
        </Table.Cell>

        <Table.Cell>
          <ActionButtons removeClicked={onRemove} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan="6">
          <FormBuilder
            activityFieldTypes={activityFieldTypes}
            addField={(event, data) => addField(event, status, data)}
            fields={status.fields}
            onFieldChange={(fieldIndex, newValue) =>
              onFieldChange(fieldIndex, status, newValue)
            }
            isTableView={false}
          />
        </Table.Cell>
      </Table.Row>
      {status.addResolution ? (
        <Table.Row>
          <Table.Cell colSpan="6">
            <ActivityResolutionList
              activityResolutionTypes={activityResolutionTypes}
              resolutions={status.resolutions}
              toggleResolution={(resolutionType, makeAvailable) =>
                toggleResolution(status, resolutionType, makeAvailable)
              }
              onChange={(resolutionType, value) =>
                onResolutionChange(status, resolutionType, value)
              }
              editable={true}
              allowNotes={false}
            />
          </Table.Cell>
        </Table.Row>
      ) : null}
    </React.Fragment>
  );
};

WorkflowStatusListRow.propTypes = {
  status: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  statusTypes: PropTypes.array,
  activityResolutionTypes: PropTypes.array,
  toggleResolution: PropTypes.func,
  onResolutionChange: PropTypes.func,
  activityFieldTypes: PropTypes.array,
  addField: PropTypes.func,
  onFieldChange: PropTypes.func,
};

export default WorkflowStatusListRow;
