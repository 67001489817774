import { Dropdown } from 'semantic-ui-react';

type StatusScopeEditorProps = {
  scope: string[];
  onChange: (scope: string[]) => void;
};

const StatusScopeEditor = ({ scope, onChange }) => {
  const availableScopes = ['ApplicableDate', 'Entity', 'AssignedParty'];

  const options = availableScopes.map((scope) => ({
    key: scope,
    text: scope,
    value: scope,
  }));

  return (
    <div>
      <p>The scope dictates the context of the status.</p>
      <ul>
        <li>
          ApplicableDate: the status will be relevant to a specific date or
          period (such as a quarter)
        </li>
        <li>
          Entity, the status will be relevant to an Entity such as a product.{' '}
        </li>
        <li>
          {' '}
          AssignedParty, the status will be relevant to a specific Party.
        </li>
      </ul>
      <Dropdown
        placeholder="Scope"
        fluid
        multiple
        selection
        options={options}
        value={scope || []}
        onChange={(_, { value }) => onChange(value)}
      />
    </div>
  );
};

export default StatusScopeEditor;
