import React from 'react';

const ActivityContext = React.createContext();

export const WrapActivityContext = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <ActivityContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </ActivityContext.Consumer>
      );
    }
  };

export default ActivityContext;
