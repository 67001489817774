import update from 'immutability-helper/index';
import _ from 'lodash';

export function addField(fieldsArray, fieldType, label, options, fieldKey) {
  return [
    ...fieldsArray,
    {
      activityFieldTypeId: fieldType.id,
      label: label,
      sort: fieldsArray.length,
      options: options,
      fieldType: fieldType,
      fieldKey: fieldKey,
    },
  ];
}

export function removeField(fieldsArray, fieldIndex) {
  const oldValue = fieldsArray[fieldIndex];
  let fields = update(fieldsArray, { $splice: [[fieldIndex, 1]] });

  fields = fields.map((field) => {
    return Object.assign({}, field, {
      sort: field.sort > oldValue.sort ? field.sort - 1 : field.sort,
    });
  });

  return fields;
}

export function updateField(fieldsArray, fieldIndex, value) {
  const fields = [...fieldsArray];

  const oldValue = fields[fieldIndex];

  if (oldValue.sort !== value.sort) {
    if (value.sort < 0 || value.sort >= fields.length) return fieldsArray;
    const swapFieldIndex = _.findIndex(fields, { sort: value.sort });
    fields[swapFieldIndex] = Object.assign({}, fields[swapFieldIndex], {
      sort: oldValue.sort,
    });
  }

  fields[fieldIndex] = value;

  return fields;
}

export function updateActivityById({ state, activity, id }) {
  const newState = update(state, {
    byId: {
      [id]: {
        $set: activity,
      },
    },
  });
  return newState;
}
