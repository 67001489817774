import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, Header, Menu, Segment } from 'semantic-ui-react';
import TicketBreadcrumbs from '../TicketBreadcrumbs';
import PartyLabel from '../../common/labels/PartyLabel';
import { TicketContextConsumer } from '../../../context/TicketContext';

import { copyFieldsToClipboard } from '../../../utils/copyFieldsToClipboard';
import { getFeatures } from '../../../utils/Features';

const { FOLLOW_UP } = getFeatures();

const TicketPageMenu = (props) => {
  const {
    ticket,
    ticketStateActions,
    fullPageView,
    onToggleShowFieldKeys,
  } = props;

  if (!ticket) return null;

  const ticketStyle = fullPageView
    ? 'ticket-page-breadcrumb'
    : 'ticket-page-label';

  const handleCopyFieldsToClipboard = () => {
    const fields = ticket?.activity?.fields;
    if (!fields) return;
    copyFieldsToClipboard(fields);
  };

  return (
    <React.Fragment>
      {fullPageView && (
        <PartyLabel
          party={{
            label: ticket.assignedPartyLabel,
            id: ticket.assignedPartyId,
          }}
        />
      )}
      <Menu size="tiny" secondary className="ticket-page-menu">
        <Menu.Item fitted className={ticketStyle}>
          {fullPageView ? (
            <Segment vertical className="ticket-breadcrumbs">
              <TicketBreadcrumbs />
            </Segment>
          ) : (
            <Header size="medium" as="h2">
              {ticket.label}
            </Header>
          )}
        </Menu.Item>

        <Menu.Menu position="right">
          <Dropdown item icon="ellipsis horizontal">
            <Dropdown.Menu>
              {FOLLOW_UP && (
                <Dropdown.Item
                  onClick={() => {
                    ticketStateActions.addTicketToFollowUpList(ticket.id);
                  }}
                >
                  Follow up
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleCopyFieldsToClipboard}>
                Copy fields to clipboard
              </Dropdown.Item>
              <Dropdown.Item onClick={onToggleShowFieldKeys}>
                Toggle show field keys
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </React.Fragment>
  );
};

TicketPageMenu.propTypes = {
  ticket: PropTypes.object,
  ticketStateActions: PropTypes.object,
  fullPageView: PropTypes.bool,
  onToggleShowFieldKeys: PropTypes.func,
};

export default TicketContextConsumer(TicketPageMenu);
