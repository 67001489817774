import React from 'react';
import * as PropTypes from 'prop-types';
import Fields from '../../fields/Fields';

Field.propTypes = {
  type: PropTypes.string,
};

function Field(props) {
  const { type } = props;

  const Field = Fields[type];
  if (!Field) return <React.Fragment>{type} field unavailable</React.Fragment>;

  return <Field onChange={() => {}} {...props} />;
}

export default Field;
