import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import {
  Table,
  Dimmer,
  Loader,
  Segment,
  Header,
  Icon,
  Grid,
  Ref,
} from 'semantic-ui-react';

import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import ExecutionListItem from './ExecutionListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { progressColumnName } from '../../constants/filterConstants';
import Pagination from '../common/Pagination';

import './ExecutionList.scss';
import useColumnHeight from '../../utils/hooks/useColumnHeight';
import PaginationDetails from '../common/PaginationDetails';

const ExecutionList = (props) => {
  const {
    executions,
    compact = false,
    handleSortChange,
    paginationOptions,
    handleAddFilter,
    currentFilters,
    removeFilterValues,
    loading,
    activePage,
    handlePaginationChange,
    totalPages,
    executionsPerPage,
    totalExecutions,
  } = props;

  const [refContainer, columnHeight] = useColumnHeight('auto', 50);

  return (
    <Grid>
      <Grid.Column className="execution-list">
        <Ref innerRef={refContainer}>
          <Grid.Row columns={2}>
            {(loading || executions.length > 0) && (
              <div
                className="execution-list-scroll"
                style={{ height: columnHeight }}
              >
                <Table celled sortable>
                  {!compact && (
                    <Table.Header>
                      <Table.Row>
                        <SortableHeaderCell
                          {...paginationOptions}
                          name="label"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                          isSearchable={true}
                          isFilterable={false}
                        >
                          Name
                        </SortableHeaderCell>
                        <SortableHeaderCell
                          {...paginationOptions}
                          name="applicableDate"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                        >
                          Applicable Date
                        </SortableHeaderCell>
                        <SortableHeaderCell
                          {...paginationOptions}
                          name="status"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                        >
                          Status
                        </SortableHeaderCell>
                        <SortableHeaderCell
                          {...paginationOptions}
                          name="ownerPartyLabel"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                        >
                          Owner Party
                        </SortableHeaderCell>
                        <SortableHeaderCell
                          {...paginationOptions}
                          name="partyLabel"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                        >
                          Assigned Party
                        </SortableHeaderCell>

                        <SortableHeaderCell
                          {...paginationOptions}
                          name="dueDate"
                          handleSortChange={handleSortChange}
                          handleAddFilter={handleAddFilter}
                          currentFilters={currentFilters}
                          removeFilterValues={removeFilterValues}
                        >
                          Due Date
                        </SortableHeaderCell>

                        <SortableHeaderCell
                          {...paginationOptions}
                          name={progressColumnName}
                          handleSortChange={handleSortChange}
                        >
                          Progress
                        </SortableHeaderCell>
                      </Table.Row>
                    </Table.Header>
                  )}
                  {loading && (
                    <Dimmer active={loading} inverted>
                      <Loader />
                    </Dimmer>
                  )}
                  <Table.Body>
                    {executions.map((execution, index) => (
                      <ExecutionListItem
                        key={index}
                        execution={execution}
                        compact={compact}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </div>
            )}
          </Grid.Row>
        </Ref>
        <Grid className="pagination-grid">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Pagination
                activePage={activePage}
                boundaryRange={0}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={handlePaginationChange}
                disabled={loading}
                size="tiny"
              />
            </Grid.Column>
            {!loading && totalExecutions > 0 && (
              <Grid.Column
                verticalAlign="middle"
                floated="right"
                textAlign="right"
              >
                <PaginationDetails
                  totalItems={totalExecutions}
                  itemsPerPage={executionsPerPage}
                  activePage={activePage}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
        {executions.length === 0 && !loading && (
          <Segment placeholder basic>
            <Header icon>
              <Icon name="list" />
              No programmes were found which match your search
            </Header>
          </Segment>
        )}
      </Grid.Column>
    </Grid>
  );
};

ExecutionList.propTypes = {
  executions: PropTypes.array.isRequired,
  workflowStatusTypes: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  handleAddFilter: PropTypes.func,
  currentFilters: PropTypes.array,
  removeFilterValues: PropTypes.func,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  totalExecutions: PropTypes.number,
  executionsPerPage: PropTypes.number,
};

export default ExecutionContextConsumer(ExecutionList);
