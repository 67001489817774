import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Table, Button } from 'semantic-ui-react';

import BudgetListField from './BudgetListField';
import { BUDGET_FIELDS } from '../../utils/PartyConsts';
import FieldErrors from '../common/fields/FieldErrors';

class BudgetList extends Component {
  state = {
    budgetFieldsValidations: [],
  };
  componentDidMount() {
    const { budgets } = this.props;
    if (!_.isEmpty(budgets) || budgets.length > 0) this.setBudgetValidation();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.budgets.length !== this.props.budgets.length) {
      this.setBudgetValidation();
    }
  }
  setBudgetValidation = () => {
    const { budgets } = this.props;
    const validations = _.map(budgets, (budget) => {
      let result = {};
      _.map(BUDGET_FIELDS, (key) => {
        const isEmpty = budget[key] === '';
        result[key] = { errors: isEmpty, hasFocus: true };
      });
      return result;
    });
    this.setState({ budgetFieldsValidations: validations });
  };
  checkBudgetValidations = (name, value, fieldIndex) => {
    const validations = this.checkFieldValidation(name, value, fieldIndex);
    this.setState({ budgetFieldsValidations: validations }, () => {
      const { onFieldChange } = this.props;
      onFieldChange(
        { name, value, fieldIndex },
        this.checkValidation(validations)
      );
    });
  };
  checkFieldValidation = (name, value, fieldIndex) => {
    const { budgetFieldsValidations } = this.state;
    const newBudgetFieldsValidations = [...budgetFieldsValidations];
    const field = { ...budgetFieldsValidations[fieldIndex] };
    if (name) field[name].errors = value === '';
    newBudgetFieldsValidations[fieldIndex] = field;
    return newBudgetFieldsValidations;
  };
  getIsBudgetsObjectsEqual = (prevProps) => {
    return !_.isEqual(this.props.budgets, prevProps.budgets);
  };
  handleOnFocus = (name, index) => {
    this.changeFocus(name, index, true);
  };
  handleOnBlur = (name, index) => {
    this.changeFocus(name, index, false);
  };
  changeFocus = (name, index, value) => {
    const { budgetFieldsValidations } = this.state;
    const newBudgetFieldsValidations = [...budgetFieldsValidations];
    const field = { ...newBudgetFieldsValidations[index] };
    if (name) field[name].hasFocus = value;
    newBudgetFieldsValidations[index] = field;
    this.setState({ budgetFieldsValidations: newBudgetFieldsValidations });
  };

  handleFieldDelete = (fieldIndex) => {
    const { onFieldDelete } = this.props;
    const { budgetFieldsValidations } = this.state;
    const validations = [...budgetFieldsValidations];
    validations.splice(fieldIndex, 1);
    onFieldDelete(fieldIndex, this.checkValidation(validations));
  };
  checkValidation = (validations) => {
    return _.every(
      _.map(validations, (validation) =>
        _.every(_.map(BUDGET_FIELDS, (key) => validation[key].errors == false))
      )
    );
  };
  checkIsValid = (validations) => {
    return _.some(
      _.map(validations, (validation) =>
        _.some(
          _.map(
            BUDGET_FIELDS,
            (key) =>
              validation[key].hasFocus === false &&
              validation[key].errors == true
          )
        )
      )
    );
  };
  isShowError = () => {
    const { budgetFieldsValidations } = this.state;
    return this.checkIsValid(budgetFieldsValidations);
  };
  render() {
    const { budgetFieldsValidations } = this.state;
    const { budgets, addBudget, currencies } = this.props;
    return (
      <React.Fragment>
        <label>Add Budgets</label>

        <Table celled>
          {budgets.length > 0 ? (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Currency</Table.HeaderCell>
                <Table.HeaderCell>Premium</Table.HeaderCell>
                <Table.HeaderCell>Development Period</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
          ) : null}
          <Table.Body>
            {_.map(budgets, (budget, index) => (
              <BudgetListField
                key={index}
                value={budget}
                fieldIndex={index}
                onChange={this.checkBudgetValidations}
                editable={true}
                currencies={currencies}
                deleteField={this.handleFieldDelete}
                validationErrors={budgetFieldsValidations[index]}
                handleOnFocus={this.handleOnFocus}
                handleOnBlur={this.handleOnBlur}
              />
            ))}
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={8}>
                <Button onClick={addBudget}>Add budget</Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              {this.isShowError() && (
                <Table.Cell textAlign="center" colSpan={8}>
                  <FieldErrors
                    errors={['Please pass all of the budget fields']}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

BudgetList.propTypes = {
  budgets: PropTypes.array,
  addBudget: PropTypes.func,
  currencies: PropTypes.array,
  onFieldChange: PropTypes.func,
  addBudgetButtonIsActive: PropTypes.bool,
  onFieldDelete: PropTypes.func,
};

export default BudgetList;
