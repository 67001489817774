import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';

import * as uiActions from '../../actions/uiActions';
import * as permissionActions from '../../actions/permissionActions';
import PermissionContext from '../../context/PermissionContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from './../common/ModalRoot';
import { permissionFilter } from '../../constants/apiFilters';

function PermissionWrapper(ComponentToWrap) {
  class Permission extends Component {
    static propTypes = {
      permissionStateActions: PropTypes.object,
      currentPermission: PropTypes.object,
      uiActions: PropTypes.object.isRequired,
      history: PropTypes.object,
      permissionTypes: PropTypes.array.isRequired,
      roleDefinitions: PropTypes.array.isRequired,
      structuralNode: PropTypes.object,
    };

    openCreatePermissionModal = (id, name, currentNodeId) => {
      this.props.uiActions.showModal({
        modalType: MODAL_COMPONENTS.CREATE_PERMISSION_MODAL,
        modalProps: {
          id,
          name,
          currentNodeId,
          permissionTypes: this.props.permissionTypes,
        },
      });
    };

    openAddUserModal = (id, name, currentNodeId) => {
      this.props.uiActions.showModal({
        modalType: MODAL_COMPONENTS.PERMISSION_ADD_USER_MODAL,
        modalProps: {
          id,
          name,
          currentNodeId,
          roleDefinitions: this.props.roleDefinitions,
        },
      });
    };

    openManagePermissionsModal = (permissionsNodeId, name) => {
      const { uiActions, permissionStateActions, roleDefinitions } = this.props;
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.MANAGE_PERMISSIONS_MODAL,
        modalProps: {
          permissionsNodeId,
          name,
          roleDefinitions,
          modelStateActions: permissionStateActions,
        },
      });
    };

    deletePrincipalRole = (principal) => {
      const { permissionStateActions } = this.props;
      return permissionStateActions
        .deleteRole(principal.structuralNodeId, principal.id)
        .then(() => {
          toastr.success('Role was deleted');
        })
        .catch((e) => toastr.error(e.message));
    };

    updatePrincipalRole = (structuralNodeId, data) => {
      const { permissionStateActions } = this.props;
      return permissionStateActions
        .updatePrincipalRole(structuralNodeId, data)
        .then(() => {
          toastr.success('Role was updated');
        })
        .catch((e) => toastr.error(e.message));
    };

    addPrincipalRole = (structuralNodeId, data) => {
      const { permissionStateActions } = this.props;
      return permissionStateActions
        .addPrincipalRole(structuralNodeId, data)
        .then(() => {
          permissionStateActions
            .loadPermission(structuralNodeId, permissionFilter)
            .then(() => {
              toastr.success('Principal is added');
            });
        })
        .catch((e) => {
          console.error(e);
          toastr.error(e.message);
        });
    };

    render() {
      const {
        currentPermission,
        permissionStateActions,
        roleDefinitions,
        structuralNode,
      } = this.props;

      const values = {
        currentPermission,
        permissionStateActions,
        roleDefinitions,
        structuralNode,
        permissionActions: extractFunctions(this),
      };
      return (
        <PermissionContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </PermissionContext.Provider>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      currentPermission: state.permissions.currentPermission,
      permissionTypes: state.constants.permissionTypes,
      roleDefinitions: state.constants.roleDefinitions,
      structuralNode: state.permissions.structuralNode,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      permissionStateActions: bindActionCreators(permissionActions, dispatch),
      uiActions: bindActionCreators(uiActions, dispatch),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(Permission);
}

export default PermissionWrapper;
