import _ from 'lodash';
import * as types from '../constants/actionTypes';
import programmeApi from '../api/programmeApi';
import ValidationSchema from '../utils/ValidationSchema';
import { checkValidation, transformValidation } from '../utils/Validation';

export function updateProgrammeValue(name, keyName, value) {
  return { type: `${types.UPDATE_PROGRAMME}_${name}`, keyName, value };
}

export function updateProgrammeOptions(name, value) {
  return { type: `${types.UPDATE_PROGRAMME_OPTIONS}_${name}`, value };
}

export function addProgrammeField(
  name,
  status,
  fieldType,
  label = '',
  options = {}
) {
  return {
    type: `${types.ADD_PROGRAMME_FIELD}_${name}`,
    status,
    fieldType,
    label,
    options,
  };
}

export function updateProgrammeField(name, status, fieldIndex, value) {
  return {
    type: `${types.UPDATE_PROGRAMME_FIELD}_${name}`,
    status,
    fieldIndex,
    value,
  };
}

export function removeProgrammeField(name, status, fieldIndex) {
  return {
    type: `${types.REMOVE_PROGRAMME_FIELD}_${name}`,
    status,
    fieldIndex,
  };
}

export function addActivitySet(name, value = {}) {
  return { type: `${types.PROGRAMME_ADD_ACTIVITY_SET}_${name}`, value };
}

export function updateActivitySet(name, activitySetIndex, keyName, value) {
  return {
    type: `${types.PROGRAMME_UPDATE_ACTIVITY_SET}_${name}`,
    activitySetIndex,
    keyName,
    value,
  };
}

export function updateCategory(name, category, keyName, value) {
  return {
    type: `${types.PROGRAMME_UPDATE_CATEGORY}_${name}`,
    category,
    keyName,
    value,
  };
}
export function removeCategory(name, category) {
  return {
    type: `${types.PROGRAMME_REMOVE_CATEGORY}_${name}`,
    category,
  };
}
export function addCategory(name, value) {
  return {
    type: `${types.PROGRAMME_ADD_CATEGORY}_${name}`,
    value,
  };
}

export function addActivityToSet(name, activitySetId, value) {
  return {
    type: `${types.ADD_ACTIVITY_TO_SET}_${name}`,
    activitySetId,
    value,
  };
}

export function addActivitySetToCategory(name, categoryId, activity) {
  return {
    type: `${types.ADD_ACTIVITY_SET_TO_CATEGORY}_${name}`,
    categoryId,
    activity,
  };
}

export function removeActivityFromSet(name, activitySetIndex, value) {
  return {
    type: `${types.REMOVE_ACTIVITY_FROM_SET}_${name}`,
    activitySetIndex,
    value,
  };
}

export function removeActivitySet(name, activitySetIndex) {
  return {
    type: `${types.REMOVE_ACTIVITY_SET}_${name}`,
    activitySetIndex,
  };
}

export function moveActivitySet(name, activitySet, increment) {
  return {
    type: `${types.MOVE_ACTIVITY_SET}_${name}`,
    activitySet,
    increment,
  };
}

export function moveActivityInSet(name, activitySetIndex, activity, increment) {
  return {
    type: `${types.MOVE_ACTIVITY_IN_SET}_${name}`,
    activitySetIndex,
    activity,
    increment,
  };
}

export function saveStateProgramme(name) {
  return function (dispatch, getState) {
    const programme = _.cloneDeep(getState()[name.toLowerCase() + 'Programme']);

    const validation = checkValidation(programme, ValidationSchema.programme);

    if (!validation.valid) {
      return Promise.reject(transformValidation(validation));
    }

    if (programme.editedOptions && !programme.edited) {
      delete programme.activitySets;
      delete programme.categories;
    }

    return programmeApi.saveProgramme(programme).then((programme) => {
      return dispatch({
        type: `${types.SAVE_PROGRAMME_SUCCESS}_${name}`,
        programme,
      });
    });
  };
}

export function saveProgramme(programme) {
  return function (dispatch) {
    return programmeApi.saveProgramme(programme).then((programme) => {
      dispatch({
        type: `${types.SAVE_PROGRAMME_SUCCESS}`,
        programme,
      });
    });
  };
}

export function launchProgramme(id, name) {
  return function (dispatch) {
    return programmeApi.launchProgramme(id).then((execution) => {
      return dispatch({
        type: `${types.CREATE_EXECUTION_SUCCESS}_${name}`,
        execution,
      });
    });
  };
}

export function loadProgramme(id, name, includeFields = false) {
  return function (dispatch) {
    return programmeApi.getProgramme(id, includeFields).then((programme) => {
      return dispatch({
        type: `${types.LOAD_PROGRAMME_SUCCESS}_${name}`,
        programme,
      });
    });
  };
}

export function loadProgrammes() {
  return function (dispatch) {
    return programmeApi.getProgrammes().then((programmes) => {
      return dispatch({
        type: types.LOAD_PROGRAMMES_SUCCESS,
        programmes,
      });
    });
  };
}
