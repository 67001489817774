import { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Button,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  List,
  Loader,
  Select,
  Table,
} from 'semantic-ui-react';
import { useAppSelector } from '../../actions/store';
import { Field, Status, StatusComposite, StatusReport } from '../../types';
import EditableHeader from '../common/EditableHeader';
import { loadStatuses } from '../../actions/statusActions';
import { useDispatch } from 'react-redux';
import statusApi, {
  RAGStatusResult,
  searchDimensionOptions,
  StatusReportRunArgs,
} from '../../api/statusApi';
import { StatusTestResult } from './StatusTestResult';
import { EntitySearch } from '../common/search/EntitySearch';
import ActionButtons from '../common/ActionButtons';
import ApplicableDate from '../executionPlan/ApplicableDate';
import { isEqual, omit, set } from 'lodash';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import { FieldSelector } from '../activity/FieldSelector';
import { FieldSearch } from '../activity/FieldSearch';
import DashboardContainer from '../dashboard/DashboardContainer';
import { StatusPopupWrapper } from '../common/dashboard/components/StatusPopupWrapper';
import EntityLabel from '../common/labels/EntityLabel';
import useColumnHeight from '../../utils/hooks/useColumnHeight';
import StatusCompositesEditor from './StatusCompositesEditor';
import { on } from 'events';
import { creationStatusOptions } from './StatusEditor';

interface Props {
  statusReport: StatusReport;
  onAddStatus: (status: Status) => void;
  onRemoveStatus: (status: Status) => void;
  onSaveStatus: (status: Status) => void;
  onAddField: (field: Field | Field[]) => void;
  onRemoveField: (field: Field) => void;
  onSave: (statusReport: StatusReport) => void;
  onAddComposite: (statusComposite: StatusComposite) => Promise<void>;
  onUpdateComposite: (statusComposite: StatusComposite) => Promise<void>;
}

export const StatusReportEditor = ({
  onAddStatus,
  onRemoveStatus,
  onAddField,
  onRemoveField,
  statusReport,
  onAddComposite,
  onUpdateComposite,
  onSave,
  onSaveStatus,
}: Props) => {
  const dispatch = useDispatch();
  const [isRunning, setIsRunning] = useState(false);
  const [currentStatusReport, setCurrentStatusReport] = useState<StatusReport>(
    statusReport
  );
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [ref, columnHeight] = useColumnHeight('auto', 50);
  const currentUser = useAppSelector((state) => state.currentUser);
  const [selectedStatusComposite, setSelectedStatusComposite] = useState<
    StatusComposite
  >();
  const [selectedPartyId, setSelectedPartyId] = useState<string>(
    currentUser.details.partyId
  );
  const [selectedEntityId, setSelectedEntityId] = useState<string>();
  const [selectedApplicableDate, setSelectedApplicableDate] = useState<
    string[]
  >();
  const [
    selectedApplicableDateTypeId,
    setSelectedApplicableDateTypeId,
  ] = useState<string>();
  const [selectedDimension, setSelectedDimension] = useState<
    StatusReportRunArgs['dimension']
  >('applicableDate');

  const [filterValues, setFilterValues] = useState<{
    PartyIds?: string[];
    applicableDate?: string[];
    EntityIds?: string[];
  }>({});
  const [runResults, setRunResults] = useState<RAGStatusResult>();

  const statuses = useAppSelector((state) =>
    state.status.statuses.filter((status) => !status.deleted)
  );

  const fetchStatuses = async () => {
    dispatch(loadStatuses());
  };
  const runStatusReport = async () => {
    setIsRunning(true);
    const result = await statusApi.runStatusReport(statusReport, {
      assignedPartyId: filterValues.PartyIds,
      assignedEntityId: filterValues.EntityIds,
      applicableDate: filterValues.applicableDate,
      dimension: selectedDimension,
    });
    setRunResults(result);
    setIsRunning(false);
  };

  useEffect(() => {
    runStatusReport();
  }, [
    selectedApplicableDate,
    selectedEntityId,
    selectedPartyId,
    selectedDimension,
    statusReport.statuses.length,
    filterValues,
  ]);

  useEffect(() => {
    fetchStatuses();
  }, []);

  const isEdited = useMemo(() => {
    const currentStatusReportWithoutStatuses = omit(
      currentStatusReport,
      'statuses'
    );
    const statusReportWithoutStatuses = omit(
      statusReport,
      'statuses',
      'activityFields'
    );

    return !isEqual(
      currentStatusReportWithoutStatuses,
      statusReportWithoutStatuses
    );
  }, [currentStatusReport, statusReport]);

  const handleSave = () => {
    onSave(currentStatusReport);
  };

  const handleToggleAccordion = (name: string) => {
    if (openAccordions.indexOf(name) !== -1) {
      setOpenAccordions(openAccordions.filter((o) => o !== name));
    } else {
      setOpenAccordions([...openAccordions, name]);
    }
  };

  const buildResultsHeaders = () => {
    if (selectedDimension === 'applicableDate' && filterValues.applicableDate) {
      return filterValues.applicableDate.map((date) => (
        <Table.HeaderCell>
          <ApplicableDateLabel
            applicableDate={date}
            applicableDateTypeId={selectedApplicableDateTypeId}
          />
        </Table.HeaderCell>
      ));
    }
    if (selectedDimension === 'assignedEntityId' && filterValues.EntityIds) {
      return filterValues.EntityIds?.map((entityId) => (
        <Table.HeaderCell>
          <EntityLabel entityId={entityId} />
        </Table.HeaderCell>
      ));
    }
    return <Table.HeaderCell>Result</Table.HeaderCell>;
  };

  const handleFieldSelected = (field: Field | Field[]) => {
    console.log(field);
    onAddField(field);
  };

  const handleAddMetricToComposite = (metricKey: string) => {
    if (!selectedStatusComposite) return;
    const updatedComposite: StatusComposite = {
      ...selectedStatusComposite,
      metrics: [...selectedStatusComposite.metrics, metricKey],
    };

    setSelectedStatusComposite(updatedComposite);

    onUpdateComposite(updatedComposite);
  };

  const handleRemoveMetricFromComposite = (
    statusComposite: StatusComposite,
    metricKey: string
  ) => {
    const updatedComposite: StatusComposite = {
      ...statusComposite,
      metrics: statusComposite.metrics.filter((m) => m !== metricKey),
    };

    if (selectedStatusComposite.id === statusComposite.id) {
      setSelectedStatusComposite(updatedComposite);
    }
    onUpdateComposite(updatedComposite);
  };

  // sort statuses by ref
  const resportStatuses = statusReport.statuses?.sort((a, b) =>
    a.ref.localeCompare(b.ref)
  );

  // sort fields by label
  const reportFields = statusReport.activityFields?.sort((a, b) =>
    a.fieldKey.localeCompare(b.fieldKey)
  );

  const statusComposites = statusReport.statusComposites;

  return (
    <Grid className="status-editor">
      <Grid.Row>
        <Grid.Column width={16}>
          <EditableHeader
            placeholder="Label"
            size="large"
            value={currentStatusReport.label}
            onSubmit={(_, { value: label }) =>
              setCurrentStatusReport({ ...currentStatusReport, label })
            }
          />
          <EditableHeader
            placeholder="Reference value"
            size="medium"
            value={currentStatusReport.ref}
            onSubmit={(_, { value: ref }) =>
              setCurrentStatusReport({ ...currentStatusReport, ref })
            }
          />

          <Form.Group>
            <Form.Field
              control={Select}
              options={[
                { text: 'Available', value: 'AVAILABLE' },
                { text: 'Editing', value: 'EDITING' },
              ]}
              onChange={(e, { value: creationStatus }) => {
                setCurrentStatusReport({
                  ...currentStatusReport,
                  creationStatus,
                });
              }}
              value={currentStatusReport.creationStatus}
              placeholder="Creation Status"
            />
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={12}>
          {isEdited && (
            <Button primary onClick={handleSave}>
              Save
            </Button>
          )}
          <div ref={ref} style={{ height: columnHeight, overflowY: 'scroll' }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Label</Table.HeaderCell>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  {buildResultsHeaders()}
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {resportStatuses &&
                  resportStatuses.map((status) => {
                    const runResult = runResults?.results.find(
                      (r) => r.id === status.id
                    );

                    return (
                      <Table.Row>
                        <Table.Cell>
                          <a href={`/metrics/${status.id}`} target="_blank">
                            {status.label}
                          </a>
                        </Table.Cell>
                        <Table.Cell collapsing>{status.ref}</Table.Cell>
                        <Table.Cell collapsing>
                          <Form.Field
                            control={Select}
                            options={creationStatusOptions}
                            onChange={(e, { value: creationStatus }) => {
                              onSaveStatus({
                                ...status,
                                creationStatus,
                              });
                            }}
                            value={status.creationStatus}
                            placeholder="Creation Status"
                          />
                        </Table.Cell>

                        {runResult && runResult.statusResults.length === 0 && (
                          <Table.Cell></Table.Cell>
                        )}

                        {runResult &&
                          runResult.statusResults.map((result) => (
                            <Table.Cell>
                              <StatusPopupWrapper
                                result={runResult}
                                args={{
                                  applicableDates: filterValues.applicableDate,
                                  assignedPartyId: filterValues.PartyIds,
                                  assignedEntityId: filterValues.EntityIds,
                                  dimension: selectedDimension,
                                }}
                              >
                                <div>
                                  <StatusTestResult
                                    logicResult={result.logicResult}
                                  />
                                </div>
                              </StatusPopupWrapper>
                            </Table.Cell>
                          ))}
                        <Table.Cell>
                          <ActionButtons
                            removeClicked={() => onRemoveStatus(status)}
                            addClicked={
                              selectedStatusComposite
                                ? () => handleAddMetricToComposite(status.ref)
                                : null
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}

                {reportFields &&
                  reportFields.map((field) => {
                    const runResult = runResults?.results.find(
                      (r) => r.ref === field.fieldKey
                    );

                    return (
                      <Table.Row>
                        <Table.Cell>
                          {field.label} ({field.activityFieldTypeId})
                        </Table.Cell>
                        <Table.Cell collapsing>{field.fieldKey}</Table.Cell>
                        <Table.Cell collapsing></Table.Cell>

                        {runResult && runResult.statusResults.length === 0 && (
                          <Table.Cell></Table.Cell>
                        )}

                        {runResult &&
                          runResult.statusResults.map((result) => (
                            <Table.Cell>
                              <StatusPopupWrapper
                                result={runResult}
                                args={{
                                  applicableDates: filterValues.applicableDate,
                                  assignedPartyId: filterValues.PartyIds,
                                  assignedEntityId: filterValues.EntityIds,
                                  dimension: selectedDimension,
                                }}
                              >
                                <div>
                                  <StatusTestResult
                                    logicResult={result.logicResult}
                                  />
                                </div>
                              </StatusPopupWrapper>
                            </Table.Cell>
                          ))}

                        {!runResult && <Table.Cell>n/a</Table.Cell>}

                        <Table.Cell>
                          <ActionButtons
                            removeClicked={() => onRemoveField(field)}
                            addClicked={
                              selectedStatusComposite
                                ? () =>
                                    handleAddMetricToComposite(field.fieldKey)
                                : null
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}

                {statusComposites.map((composite) => {
                  const runResult = runResults?.results.find(
                    (r) => r.ref === composite.ref
                  );

                  return (
                    <Table.Row>
                      <Table.Cell>{composite.label}</Table.Cell>
                      <Table.Cell collapsing>{composite.ref}</Table.Cell>
                      <Table.Cell></Table.Cell>

                      {runResult && runResult.statusResults.length === 0 && (
                        <Table.Cell></Table.Cell>
                      )}

                      {runResult &&
                        runResult.statusResults.map((result) => (
                          <Table.Cell>
                            <StatusPopupWrapper
                              result={runResult}
                              args={{
                                applicableDates: filterValues.applicableDate,
                                assignedPartyId: filterValues.PartyIds,
                                assignedEntityId: filterValues.EntityIds,
                                dimension: selectedDimension,
                              }}
                            >
                              <div>
                                <StatusTestResult
                                  logicResult={result.logicResult}
                                />
                              </div>
                            </StatusPopupWrapper>
                          </Table.Cell>
                        ))}

                      {!runResult && <Table.Cell>n/a</Table.Cell>}

                      <Table.Cell>
                        <ActionButtons removeClicked={() => {}} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>

        <Grid.Column width={4}>
          <Accordion styled>
            <Accordion.Title
              active={openAccordions.includes('statuses')}
              index={0}
              onClick={() => handleToggleAccordion('statuses')}
            >
              <Icon name="dropdown" />
              Add Metric
            </Accordion.Title>
            <Accordion.Content active={openAccordions.includes('statuses')}>
              <List>
                {statuses &&
                  statuses.map((status) => (
                    <List.Item>
                      <List.Header>
                        {status.label}
                        <a>
                          <Icon
                            name="plus"
                            onClick={() => onAddStatus(status)}
                          />
                        </a>
                      </List.Header>
                      {status.ref && <p>{status.ref}</p>}
                    </List.Item>
                  ))}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={openAccordions.includes('addFields')}
              index={0}
              onClick={() => handleToggleAccordion('addFields')}
            >
              <Icon name="dropdown" />
              Add Fields
            </Accordion.Title>
            <Accordion.Content active={openAccordions.includes('addFields')}>
              <FieldSearch onSelection={handleFieldSelected} />
              <FieldSelector
                onSelect={handleFieldSelected}
                addedFields={
                  statusReport.activityFields?.map((field) => field.fieldKey) ||
                  []
                }
              />
            </Accordion.Content>

            <Accordion.Title
              active={openAccordions.includes('compositeMetrics')}
              index={0}
              onClick={() => handleToggleAccordion('compositeMetrics')}
            >
              <Icon name="dropdown" />
              Composite Metrics
            </Accordion.Title>
            <Accordion.Content
              active={openAccordions.includes('compositeMetrics')}
            >
              <StatusCompositesEditor
                statusReport={statusReport}
                onAddStatusComposite={onAddComposite}
                onSelectStatusComposite={setSelectedStatusComposite}
                onRemoveStatus={handleRemoveMetricFromComposite}
                onUpdateStatusComposite={onUpdateComposite}
              />
            </Accordion.Content>

            <Accordion.Title
              active={openAccordions.includes('testParameters')}
              index={1}
              onClick={() => handleToggleAccordion('testParameters')}
            >
              <Icon name="dropdown" />
              Test Parameters
            </Accordion.Title>
            <Accordion.Content
              active={openAccordions.includes('testParameters')}
            >
              {isRunning && (
                <Dimmer active={isRunning} inverted>
                  <Loader disabled={!isRunning} />
                </Dimmer>
              )}
              <DashboardContainer
                id="BD5D7D80-CE5B-11ED-997B-AD349777734D"
                onPropertyChange={setFilterValues}
                initialProperties={filterValues}
                forceChartRefresh={false}
                dashboardProperiesCanBeUpdated={true}
              />
              <Form>
                <Form.Field>
                  <label>Dimension</label>
                  <Form.Field
                    control={Select}
                    value={selectedDimension}
                    onChange={(_, { value }) => setSelectedDimension(value)}
                    options={searchDimensionOptions}
                    search
                    placeholder="Select Dimension"
                    name="dimension"
                  />
                </Form.Field>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
