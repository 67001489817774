import * as React from 'react';
let ReactJson;
if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  ReactJson = React.Fragment;
} else {
  ReactJson = require('react-json-view').default;
}

const DebugComponent = (props) => {
  return (
    <div>
      <ReactJson src={props} collapsed={1} groupArraysAfterLength={10} />
    </div>
  );
};

export default DebugComponent;
