import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';

import * as uiActions from '../../actions/uiActions';

class RemoveUserFromUserGroupModal extends Component {
  state = {
    saving: false,
    userGroup: null,
    user: null,
  };

  componentWillMount() {
    const { user, userGroup } = this.props;
    this.setState({ user, userGroup });
  }

  removeUserFromUserGroup = () => {
    const { userGroup, user } = this.state;
    const { partyStateActions, uiActions } = this.props;
    this.setState({ saving: true });

    partyStateActions
      .removeUserFromUserGroup(userGroup.id, user.id)
      .then(() => {
        toastr.success(
          `${user.firstName} ${user.lastName} is deleted from ${userGroup.label}`
        );
        uiActions.closeModal();
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ saving: false });
        throw error;
      });
  };

  render() {
    const { saving } = this.state;
    const { uiActions, user, userGroup } = this.props;
    const { firstName, lastName } = user;
    const { label } = userGroup;

    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
          Are you sure you want to remove {firstName} {lastName} from user group{' '}
          {label}?
        </Modal.Content>

        <Modal.Actions>
          <Button positive onClick={this.removeUserFromUserGroup}>
            Remove
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

RemoveUserFromUserGroupModal.propTypes = {
  user: PropTypes.object,
  userGroup: PropTypes.object.isRequired,
  partyStateActions: PropTypes.object,
  uiActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(RemoveUserFromUserGroupModal);
