import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SaveUserModal from '../party/SaveUserModal';
import AssignTicketSetModal from '../ticket/AssignTicketSetModal';
import SaveRelationshipModal from '../party/SaveRelationshipModal';
import SaveEntityModal from '../party/entity/SaveEntityModal';
import SaveUserGroupModal from '../party/SaveUserGroupModal';
import CreatePartyModal from '../parties/CreatePartyModal';
import AddUserToUserGroupModal from '../party/AddUserToUserGroupModal';
import RemoveUserFromUserGroupModal from '../party/RemoveUserFromUserGroupModal';
import SavePartyAddressModal from '../party/SavePartyAddressModal';
import CreatePermissionModal from '../permissions/CreatePermissionModal';
import AddFilterModal from './AddFilterModal';
import PermissionAddUserModal from '../permissions/PermissionAddUserModal';
import QuestionSetModal from '../executionPlan/QuestionSetModal';
import LaunchExecutionPlanModal from '../executionPlan/LaunchExecutionPlanModal';
import SaveCreateThresholdModal from '../party/SaveCreateThresholdModal';
import SaveAdminThresholdModal from '../admin/SaveAdminThresholdModal';
import AddAttachmentModal from './attachments/AddAttachmentModal';
import ManagePermissionsModal from '../permissions/ManagePermissionsModal';
import EditAttachmentDetailsModal from './attachments/EditAttachmentDetailsModal';
import CreateActivityModal from '../activity/CreateActivityModal';
import CreateDistributorModal from '../distributors/CreateDistributorModal.tsx';
import CreateEntityModal from '../party/entity/CreateEntityModal';

import BulkRemindDistributorsModal from '../distributors/BulkRemindDistributorsModal';
import './ModalRoot.scss';
import UserWrapper from '../user/User';
import LaunchGovernanceProgrammeModal from '../party/entity/LaunchGovernanceProgrammeModal';

const ModalRoot = ({ modal }) => {
  const { modalProps, modalType } = modal.modalOptions;
  if (modal.showModal) {
    if (!modalType) {
      return null;
    }
    switch (modalType) {
      case MODAL_COMPONENTS.CREATE_DISTRIBUTOR_MODAL: {
        return <CreateDistributorModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.CREATE_ENTITY_MODAL: {
        return <CreateEntityModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_USER_MODAL: {
        return <SaveUserModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.ASSIGN_TICKET_MODAL: {
        return <AssignTicketSetModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_RELATIONSHIP_MODAL: {
        return <SaveRelationshipModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_ENTITY_MODAL: {
        return <SaveEntityModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_USERGROUP_MODAL: {
        return <SaveUserGroupModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.CREATE_PARTY_MODAL: {
        return <CreatePartyModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.ADD_USER_TO_USERGROUP_MODAL: {
        return <AddUserToUserGroupModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.REMOVE_USER_FROM_USERGROUP: {
        return <RemoveUserFromUserGroupModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_PARTY_ADDRESS_MODAL: {
        return <SavePartyAddressModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.CREATE_PERMISSION_MODAL: {
        return <CreatePermissionModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.ADD_FILTER_MODAL: {
        return <AddFilterModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.PERMISSION_ADD_USER_MODAL: {
        return <PermissionAddUserModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.QUESTION_SET_MODAL: {
        return <QuestionSetModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.LAUNCH_EXECUTION_PLAN_MODAL: {
        return <LaunchExecutionPlanModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_CREATE_THRESHOLD_MODAL: {
        return <SaveCreateThresholdModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.SAVE_ADMIN_THRESHOLD_MODAL: {
        return <SaveAdminThresholdModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.ADD_EXISTING_ATTACHMENT: {
        return <AddAttachmentModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.EDIT_ATTACHMENT_DETAILS: {
        return <EditAttachmentDetailsModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.MANAGE_PERMISSIONS_MODAL: {
        return <ManagePermissionsModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL: {
        return <CreateActivityModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.BULK_REMIND_DISTRIBUTORS_MODAL: {
        return <BulkRemindDistributorsModal {...modalProps} />;
      }
      case MODAL_COMPONENTS.LAUNCH_GOVERNANCE_PROGRAMME_MODAL: {
        return <LaunchGovernanceProgrammeModal {...modalProps} />;
      }
    }
  } else return null;
};

ModalRoot.propTypes = {
  modal: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    modal: state.uiStatus,
  };
}

export default UserWrapper(connect(mapStateToProps)(ModalRoot));

export const MODAL_COMPONENTS = {
  SAVE_USER_MODAL: 'SAVE_USER_MODAL',
  CREATE_DISTRIBUTOR_MODAL: 'CREATE_DISTRIBUTOR_MODAL',
  CREATE_ENTITY_MODAL: 'CREATE_ENTITY_MODAL',
  ASSIGN_TICKET_MODAL: 'ASSIGN_TICKET_MODAL',
  SAVE_RELATIONSHIP_MODAL: 'SAVE_RELATIONSHIP_MODAL',
  SAVE_ENTITY_MODAL: 'SAVE_ENTITY_MODAL',
  SAVE_USERGROUP_MODAL: 'SAVE_USERGROUP_MODAL',
  CREATE_PARTY_MODAL: 'CREATE_PARTY_MODAL',
  ADD_USER_TO_USERGROUP_MODAL: 'ADD_USER_TO_USERGROUP_MODAL',
  REMOVE_USER_FROM_USERGROUP: 'REMOVE_USER_FROM_USERGROUP',
  SAVE_PARTY_ADDRESS_MODAL: 'SAVE_PARTY_ADDRESS_MODAL',
  CREATE_PERMISSION_MODAL: 'CREATE_PERMISSION_MODAL',
  ADD_FILTER_MODAL: 'ADD_FILTER_MODAL',
  PERMISSION_ADD_USER_MODAL: 'PERMISSION_ADD_USER_MODAL',
  QUESTION_SET_MODAL: 'QUESTION_SET_MODAL',
  LAUNCH_EXECUTION_PLAN_MODAL: 'LAUNCH_EXECUTION_PLAN_MODAL',
  SAVE_CREATE_THRESHOLD_MODAL: 'SAVE_CREATE_THRESHOLD_MODAL',
  SAVE_ADMIN_THRESHOLD_MODAL: 'SAVE_THRESHOLD_ADMIN_MODAL',
  ADD_EXISTING_ATTACHMENT: 'ADD_EXISTING_ATTACHMENT',
  MANAGE_PERMISSIONS_MODAL: 'MANAGE_PERMISSIONS_MODAL',
  EDIT_ATTACHMENT_DETAILS: 'EDIT_ATTACHMENT_DETAILS',
  CREATE_ACTIVITY_MODAL: 'CREATE_ACTIVITY_MODAL',
  BULK_IMPORT_ENTITY_MODAL: 'BULK_IMPORT_ENTITY_MODAL',
  BULK_REMIND_DISTRIBUTORS_MODAL: 'BULK_REMIND_DISTRIBUTORS_MODAL',
  LAUNCH_GOVERNANCE_PROGRAMME_MODAL: 'LAUNCH_GOVERNANCE_PROGRAMME_MODAL',

  /* other modals */
};
