/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Container,
  Header,
  Icon,
  Label,
  Menu,
  Grid,
  Dimmer,
  Loader,
  Button,
} from 'semantic-ui-react';
import { NavLink, Route, Switch } from 'react-router-dom';
import toastr from 'toastr';

import UserWrapper from '../user/User';
import ExecutionWrapper from './Execution';

import ExecutionFull from './ExecutionFull';
import ExecutionOverview from './ExecutionOverview';
import ExecutionReports from './ExecutionReports';
import ExecutionSettings from './ExecutionSettings';
import ExecutionSelectRelationship from './ExecutionSelectRelationship';
import ExecutionGantt from './ExecutionGantt';

import ErrorBoundary from './../common/ErrorBoundary';
import PartyLabel from '../common/labels/PartyLabel';
import EntityLabel from '../common/labels/EntityLabel';
import ApplicableDateLabel from '../common/ApplicableDateLabel';
import PrincipalSearch from '../common/PrincipalSearch';

import * as permissionTypes from '../../constants/permissionTypes';

import './ExecutionPage.scss';

class ExecutionPage extends Component {
  state = {
    showAddDistributorsModal: false,
    isSavingDistributors: false,
  };

  componentDidMount() {
    const { currentExecution, match } = this.props;
    const { id } = currentExecution;
    if (!id || id != match.params.id) {
      const { executionActions } = this.props;
      executionActions.loadExecution(match.params.id, 'CURRENT');
    }
  }

  componentDidUpdate() {
    const { currentExecution, match } = this.props;
    const { id } = currentExecution;
    const { isExecutionLoading } = this.props;
    if (isExecutionLoading || !id) return;
    if (id != match.params.id) {
      const { executionActions } = this.props;
      executionActions.loadExecution(match.params.id, 'CURRENT');
    }
  }

  isTicketsActive = () => {
    const { category } = this.props.match.params;
    if (
      category !== 'overview' &&
      category !== 'reports' &&
      category !== 'settings' &&
      category !== 'gantt'
    )
      return true;
  };

  handleSendDistributors = async (distributors) => {
    const { executionStateActions, currentExecution, executionActions } =
      this.props;

    this.setState({ isSavingDistributors: true });

    try {
      await executionStateActions.addFairValue(currentExecution, distributors);

      executionActions.loadExecution(currentExecution.id, 'CURRENT');

      this.setState({ isSavingDistributors: false });

      this.setState({ showAddDistributorsModal: false });
    } catch (e) {
      toastr.error(e);
      this.setState({ isSavingDistributors: false });
    }
  };

  render() {
    const {
      currentExecution,
      currentUser,
      isExecutionLoading,
      principalFilter,
      setFilterPrincipal,
    } = this.props;
    const execution = currentExecution;
    const {
      id,
      programme,
      structuralNodeId,
      label,
      applicableDate,
      applicableDateTypeId,
      executionStateId,
      party,
      executionTicketDeadlineCount,
      entities,
    } = { ...execution };
    const reportsAvailable = _.get(programme, 'options.reports') || [];
    const isAdmin = currentUser.checkPermissionForNode(
      structuralNodeId,
      permissionTypes.ADMIN
    );

    return (
      <React.Fragment>
        <Dimmer active={isExecutionLoading} inverted>
          <Loader disabled={!isExecutionLoading} />
        </Dimmer>

        <Container fluid className="execution-page">
          {!isExecutionLoading && (
            <div className="execution-heading hide-for-print">
              <Grid verticalAlign="middle">
                <Grid.Column width={6}>
                  <Header size="medium" as="h2">
                    {`${label} ${applicableDate ? ' - ' : ''}`}
                    <ApplicableDateLabel
                      applicableDate={applicableDate}
                      applicableDateTypeId={applicableDateTypeId}
                    />{' '}
                    <Label>{executionStateId}</Label>
                    <div>
                      <PartyLabel party={party} />
                      {entities &&
                        entities.map((entity) => {
                          return <EntityLabel entity={entity} />;
                        })}
                    </div>
                  </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Header size="small">View:</Header>
                  <Menu compact icon className="half-padding">
                    <Menu.Item
                      as={NavLink}
                      name="Overview"
                      to={`/execution/${id}/overview`}
                    >
                      <Icon name="dashboard" size="large" />
                    </Menu.Item>

                    <Menu.Item
                      as={NavLink}
                      name="Tickets"
                      to={`/execution/${id}/`}
                      isActive={this.isTicketsActive}
                    >
                      <Icon name="list alternate" size="large" />
                    </Menu.Item>

                    {!!executionTicketDeadlineCount && (
                      <Menu.Item
                        as={NavLink}
                        name="Tickets"
                        to={`/execution/${id}/gantt`}
                      >
                        <Icon name="calendar alternate outline" size="large" />
                      </Menu.Item>
                    )}

                    {reportsAvailable.length > 0 && (
                      <Menu.Item
                        as={NavLink}
                        name="Reports"
                        to={`/execution/${id}/reports`}
                      >
                        <Icon name="clipboard" size="large" />
                      </Menu.Item>
                    )}
                    {isAdmin && (
                      <Menu.Item
                        as={NavLink}
                        name="Settings"
                        to={`/execution/${id}/settings/`}
                      >
                        <Icon name="settings" size="large" />
                      </Menu.Item>
                    )}
                  </Menu>
                </Grid.Column>
                <Grid.Column
                  width={5}
                  floated="right"
                  className="execution-filters"
                >
                  {/* <Button
                    onClick={() =>
                      this.setState({ showAddDistributorsModal: true })
                    }
                  >
                    Add Distributors
                  </Button> */}
                  <PrincipalSearch
                    onChange={(e, { value }) => {
                      setFilterPrincipal(value);
                    }}
                    onClose={() => {}}
                    clearable={true}
                    isAutofocus={false}
                    currentPrincipal={principalFilter}
                    label="Filter by assigned"
                  />
                  <ExecutionSelectRelationship execution={execution} />
                </Grid.Column>
              </Grid>
            </div>
          )}

          {execution && (
            <Switch>
              <Route
                path="/execution/:executionId/overview"
                component={ExecutionOverview}
              />
              <Route
                path="/execution/:executionId/reports/:dashboardId?"
                component={ExecutionReports}
              />
              <Route
                path="/execution/:executionId/gantt"
                component={ExecutionGantt}
              />
              {isAdmin && (
                <Route
                  path="/execution/:id/settings/:tab?"
                  component={ExecutionSettings}
                />
              )}
              <Route
                path="/execution/:id/:category?/:ticketId?"
                component={ExecutionFull}
              />
            </Switch>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

ExecutionPage.propTypes = {
  currentExecution: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  location: PropTypes.object,
  executionActions: PropTypes.object,
  isExecutionLoading: PropTypes.bool,
  principalFilter: PropTypes.object,
  setFilterPrincipal: PropTypes.func,
};

export default ErrorBoundary(UserWrapper(ExecutionWrapper(ExecutionPage)));
