import { useMemo } from 'react';
import {
  RAGStatusResult,
  StatusReportRunArgs,
} from '../../../../../api/statusApi';
import { RAGIcon } from '../../status/RAGIcon';
import { RAGStatusIcon } from '../../status/RAGStatusIcon';
import { Table } from 'semantic-ui-react';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import { get } from 'lodash';
import WidgetComponents from '../../DashboardWidgetComponents';
import { mapArgs } from '../../../../../utils/data/mapArgs';
import SimpleErrorWrapper from '../../../SimpleErrorWrapper';
import { mergeCells } from '../../../../../utils/data/mergeCells';

interface ComponentProps {
  name: string;
  args?: Record<string, any>;
  components?: ComponentProps[];
}

interface MetricTableColumn {
  key?: string;
  args?: Record<string, any>;
  header: string;
  components: ComponentProps[];
}
interface MetricTableProps {
  data?: PropsData;
  ragMetrics?: string;
  columns?: MetricTableColumn[];
}

export interface DashboardProperties {
  PartyIds?: string[];
  applicableDate?: string[];
  dimension?: StatusReportRunArgs['dimension'];
}

export interface PropsData {
  selection: null;
  data: DataData;
}

export interface DataData {
  main: RAGStatusResult;
}

const MetricTable = ({ data, ragMetrics, columns = [] }: MetricTableProps) => {
  const mergedColumns = ['category', 'subcategory'];
  const metrics = useMemo(() => {
    if (data?.data?.main?.results) {
      let metricResults = data?.data?.main?.results;
      if (ragMetrics) {
        const metricsArray = ragMetrics.split(',');
        metricResults = data?.data?.main?.results.filter((result) =>
          metricsArray.some((metricString) => result.ref.includes(metricString))
        );
      }

      const flatResults = metricResults.map((result) => ({
        ...result,
        ...result.meta,
      }));

      const merged = mergeCells<(typeof flatResults)[number], any>(
        flatResults,
        mergedColumns
      );
      return merged;
    }
  }, [data, ragMetrics]);

  const resultArgs = data?.data?.main?.arguments;

  const headers = columns.map((column) => column.header);

  const rowData = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      const columnsData = columns.map((column) => {
        return {
          ...column,
          value: column.key ? get(metric, column.key) : null,
        };
      });

      return { columns: columnsData, metric };
    });
  }, [metrics, columns]);

  const renderComponent = (component, metric) => {
    let Component = WidgetComponents[component.name];

    if (['div', 'span', 'p', 'a'].includes(component.name)) {
      Component = component.name;
    }

    if (!Component) {
      return `${component.name} not found`;
    }

    const args = mapArgs(component.args, { ...metric, resultArgs });

    const nestedComponents =
      component.components?.length === 1
        ? renderComponent(component.components[0], metric)
        : (component.components || []).map((nestedComponent) => {
            return renderComponent(nestedComponent, metric);
          });

    return <Component {...args}>{nestedComponents}</Component>;
  };

  return (
    <div className="metric-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            {headers.map((header) => (
              <Table.HeaderCell>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>{' '}
        <Table.Body>
          {rowData &&
            rowData.map((row) => {
              return (
                <Table.Row>
                  {row.columns.map((cell, index) => {
                    const isLastColumn = index === row.columns.length - 1;
                    const isMerge =
                      cell.key && mergedColumns.includes(cell.key);
                    const rowSpan = isMerge ? row.metric.span[cell.key] : 1;
                    const style = isMerge
                      ? {
                          borderRight: '1px solid rgba(34, 36, 38, 0.1)',
                          borderLeft: 'none',
                        }
                      : isLastColumn
                      ? {}
                      : { borderLeft: 'none' };

                    if (isMerge && row.metric.span[cell.key] === 0) {
                      return null;
                    }

                    const args = cell.args || {};
                    if (cell.components) {
                      return (
                        <Table.Cell rowSpan={rowSpan} style={style} {...args}>
                          <SimpleErrorWrapper>
                            {cell.components.map((component) =>
                              renderComponent(component, row.metric)
                            )}
                          </SimpleErrorWrapper>
                        </Table.Cell>
                      );
                    }
                    return (
                      <Table.Cell rowSpan={rowSpan} style={style}>
                        {cell.value}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

MetricTable.fields = [
  {
    id: 'ragMetrics',
    required: false,
    label: 'RAG Metrics',
    activityFieldType: 'Text',
    options: null,
  },
  {
    id: 'columns',
    required: false,
    label: 'Columns',
    activityFieldType: 'JSONBuilder',
    args: {
      allowModeChange: true,
    },
  },
];

export default MetricTable;
