import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Form, Dropdown } from 'semantic-ui-react';

import { mapToOptions } from '../../utils/React';
import Text from '../common/fields/Text';
import UserSearch from '../common/search/UserSearch';
import { granularPermissionTypes } from '../../constants/config';
import { PermissionContextConsumer } from '../../context/PermissionContext';

const ExecutionPlanDetailsForm = ({
  formData,
  workflows,
  roleDefinitions = [],
  handleFormDataChanged,
  labelErrors,
  handleOnBlur,
  handleOnFocus,
}) => {
  const {
    assignedPartyId,
    ownerPartyId,
    reviewPartyId,
    label,
    workflowId,
    granularPermissionTypeId,
    assignedPartyUserId,
    ownerPartyUserId,
    reviewPartyUserId,
    relationPermissions,
  } = formData;

  let roleDefinitionOptions = mapToOptions(roleDefinitions, null, true).filter(
    (role) => role.key !== 'Superuser'
  );
  return (
    <Grid>
      <Grid.Column mobile={16} tablet={8} computer={6}>
        <Form>
          <Text
            value={label}
            fluid={true}
            label="Label"
            onChange={(e, data) => {
              handleFormDataChanged(e, data);
            }}
            name="label"
            errors={labelErrors}
            onBlur={() => handleOnBlur('label')}
            onFocus={() => handleOnFocus('label')}
          />
          <Form.Field>
            <label>Workflow</label>
            <Dropdown
              fluid
              selection
              className="dropdown-workflow-activity-set"
              options={mapToOptions(workflows)}
              placeholder="Select the Workflow"
              onChange={(e, data) => {
                handleFormDataChanged(e, data);
              }}
              name="workflowId"
              value={workflowId}
            />
          </Form.Field>
          {ownerPartyId && (
            <Form.Field>
              <label>Owner Party User</label>
              <UserSearch
                onChange={handleFormDataChanged}
                value={ownerPartyUserId}
                disabled={!ownerPartyId}
                filterByParty={ownerPartyId}
                name="ownerPartyUserId"
              />
            </Form.Field>
          )}
          {assignedPartyId && (
            <Form.Field>
              <label>Assigned Party User</label>
              <UserSearch
                onChange={handleFormDataChanged}
                value={assignedPartyUserId}
                disabled={!assignedPartyId}
                filterByParty={[assignedPartyId, ownerPartyId]}
                name="assignedPartyUserId"
              />
            </Form.Field>
          )}
          {reviewPartyId && (
            <Form.Field>
              <label>Review Party User</label>
              <UserSearch
                onChange={handleFormDataChanged}
                value={reviewPartyUserId}
                disabled={!reviewPartyId}
                filterByParty={[reviewPartyId, ownerPartyId]}
                name="reviewPartyUserId"
              />
            </Form.Field>
          )}
          <Form.Field floated="right">
            <label>Allow granular permissions</label>

            <Dropdown
              options={mapToOptions(granularPermissionTypes)}
              fluid
              selection
              value={granularPermissionTypeId}
              name="granularPermissionTypeId"
              onChange={handleFormDataChanged}
            />
          </Form.Field>

          {granularPermissionTypeId === 'RELATION' && (
            <Form.Field floated="right">
              <label>Grant permissions to related parties</label>

              <Dropdown
                options={roleDefinitionOptions}
                fluid
                selection
                multiple
                value={relationPermissions}
                name="relationPermissions"
                onChange={handleFormDataChanged}
              />
            </Form.Field>
          )}
        </Form>
      </Grid.Column>
    </Grid>
  );
};

ExecutionPlanDetailsForm.propTypes = {
  formData: PropTypes.object,
  workflows: PropTypes.array,
  roleDefinitions: PropTypes.array,
  handleFormDataChanged: PropTypes.func,
  labelErrors: PropTypes.object,
  handleOnBlur: PropTypes.func,
  handleOnFocus: PropTypes.func,
};

export default PermissionContextConsumer(ExecutionPlanDetailsForm);
