import {
  EntityEntry,
  Field,
  FieldTypeId,
  Status,
  StatusReport,
  StatusComposite,
  RAGState,
  ExecutionTicketEntry,
} from '../types';
import apiTools from './apiTools';

export interface RAGStatusResult {
  id: string;
  label: string;
  entries: EntityEntry[];
  results: Result[];
  arguments: StatusResultArguments;
}

export interface StatusResultArguments {
  assignedPartyId?: string[];
  assignedEntityId?: string[];
  applicableDates?: string[];
  dimension?: 'all' | 'applicableDate' | 'assignedPartyId' | 'assignedEntityId';
}

export type ValueRendererMode =
  | 'RAG'
  | 'LATEST_VALUE'
  | 'COMPARE_LATEST_VALUE'
  | 'TOTAL'
  | 'AVERAGE';

export interface Result {
  id: string;
  label: string;
  description?: string;
  type?: FieldTypeId;
  targetFields?: string[];
  ref?: string;
  sort: number;
  meta: Meta;
  statusResults: StatusResult[];
}

export type DimensionValue =
  | 'all'
  | 'applicableDate'
  | 'assignedPartyId'
  | 'assignedEntityId';
export interface StatusResult {
  relevantEntries: ExecutionTicketEntry[];
  logicResult: LogicResultUnion;
  dimensionValue?: DimensionValue;
}

export type LogicResultUnion = LogicResult[] | LogicResult;

export interface LogicResult {
  result: ResultValue;
  thresholdResult?: RAGState;
  accrossValue: string;
  accrossValueKey: string;
  label?: string;
  ref?: string;
  relevantEntries?: ExecutionTicketEntry[];
}

export type Threshold = {
  upper: number;
  lower?: number;
  inverted?: boolean;
};

export type ResultValue =
  | null
  | 'green'
  | 'red'
  | 'grey'
  | 'purple'
  | 'orange'
  | string
  | number
  | Threshold
  | { error: string };

export interface Meta {
  category?: string;
  subcategory?: string;
  prod?: string;
  code?: string;
}

export type StatusReportRunArgs = {
  assignedPartyId?: string[];
  assignedEntityId?: string[];
  applicableDate?: string | string[];
  dimension?: 'all' | 'applicableDate' | 'assignedPartyId' | 'assignedEntityId';
};

export const dimensionLabels = {
  applicableDate: 'Applicable Date',
  assignedPartyId: 'Assigned Party',
  assignedEntityId: 'Assigned Entity',
  all: 'All',
};

export const searchDimensionOptions: {
  value: StatusReportRunArgs['dimension'];
  text: string;
}[] = [
  {
    value: 'applicableDate',
    text: 'Applicable Date',
  },
  {
    value: 'assignedEntityId',
    text: 'Assigned Entity',
  },
  {
    value: 'assignedPartyId',
    text: 'Assigned Party',
  },
  {
    value: 'all',
    text: 'All',
  },
];

class StatusApi {
  static getStatuses(filterArg: any = {}) {
    const filter = {
      ...filterArg,
      order: 'sort ASC',
    };
    return apiTools.get('Statuses', { filter }).then((response) => {
      return response.data;
    });
  }
  static getStatusReports(filterArg: any = {}) {
    const filter = {
      ...filterArg,
    };
    return apiTools.get('StatusReports', { filter }).then((response) => {
      return response.data;
    });
  }
  static createStatusReport(status: StatusReport) {
    return apiTools.post('StatusReports', status).then((response) => {
      return response.data;
    });
  }

  static addStatusToReport(args: {
    statusReportId: string;
    statusId: string;
    sort: number;
  }) {
    return apiTools
      .put(
        `StatusReports/${args.statusReportId}/statuses/rel/${args.statusId}`,
        args
      )
      .then((response) => {
        return response.data;
      });
  }
  static removeStatusFromReport(args: {
    statusReportId: string;
    statusId: string;
  }) {
    return apiTools
      .delete(
        `StatusReports/${args.statusReportId}/statuses/rel/${args.statusId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static addFieldToReport(args: {
    statusReportId: string;
    activityFieldId: string;
    sort: number;
  }) {
    return apiTools
      .put(
        `StatusReports/${args.statusReportId}/activityFields/rel/${args.activityFieldId}`,
        args
      )
      .then((response) => {
        return response.data;
      });
  }
  static removeFieldFromReport(args: {
    statusReportId: string;
    activityFieldId: string;
  }) {
    return apiTools
      .delete(
        `StatusReports/${args.statusReportId}/activityFields/rel/${args.activityFieldId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  static addStatusCompositeToReport(args: {
    statusReportId: string;
    statusComposite: StatusComposite;
  }) {
    return apiTools
      .post(
        `StatusReports/${args.statusReportId}/statusComposites`,
        args.statusComposite
      )
      .then((response) => {
        return response.data;
      });
  }

  static updateStatusComposite(args: {
    statusReportId: string;
    statusComposite: StatusComposite;
  }) {
    return apiTools
      .put(
        `StatusReports/${args.statusReportId}/statusComposites/${args.statusComposite.id}`,
        args.statusComposite
      )
      .then((response) => {
        return response.data;
      });
  }

  static getStatusReport(id: string) {
    return apiTools
      .get(`StatusReports/${id}`, {
        filter: {
          include: ['statuses', 'activityFields', 'statusComposites'],
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static updateStatusReport(statusReport: StatusReport) {
    return apiTools.patch('StatusReports', statusReport).then((response) => {
      return response.data;
    });
  }

  static runStatusReport(
    statusReport: StatusReport,
    args: StatusReportRunArgs
  ): Promise<RAGStatusResult> {
    return apiTools
      .get(`StatusReports/${statusReport.id}/run`, args)
      .then((response) => {
        return response.data;
      });
  }

  static getStatus(id: string) {
    return apiTools.get(`Statuses/${id}`).then((response) => {
      return response.data;
    });
  }
  static updateStatus(status: Status) {
    return apiTools.patch('Statuses', status).then((response) => {
      return response.data;
    });
  }
  static createStatus(status: Status) {
    return apiTools.post('Statuses', status).then((response) => {
      return response.data;
    });
  }
  static fairValueStatus(args: { productId: string }) {
    return apiTools.post('Statuses/fairValueStatus', args).then((response) => {
      return response.data;
    });
  }
  static testStatus(status: Status, testData: any) {
    return apiTools
      .post(`Statuses/${status.id}/testStatus`, testData)
      .then((response) => {
        return response.data;
      });
  }
}

export default StatusApi;
