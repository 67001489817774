import React, { Component } from 'react';
import { Form, Icon, Button } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import business from 'moment-business';
import update from 'immutability-helper/index';
import moment from 'moment';
import Moment from 'react-moment';
import * as _ from 'lodash';

import Select from './Select';
import Date from './Date';
import FieldToolTip from './FieldToolTip';
import ConfirmWrapper from '../../common/ConfirmWrapper';

class AnalyseAndImplement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getValue(),
    };
  }

  componentDidUpdate = (prevProps) => {
    const currentValue = this.props.value;
    if (currentValue && !_.isEqual(currentValue, prevProps.value)) {
      this.setState({
        value: this.getValue(),
      });
    } else if (prevProps.value && !currentValue) {
      this.setState({
        value: {},
      });
    }
  };

  getValue = () => {
    const { value } = this.props;
    if (value) {
      return _.isString(value) ? JSON.parse(this.props.value) : value;
    }
    return {};
  };

  handleOnChange = (event, { name, value }) => {
    const stateValue = this.state.value;
    if (stateValue[name]) {
      this.showConfirmPopup(name, value);
    } else {
      this.changeTheValue(name, value);
    }
  };

  changeTheValue = (name, value) => {
    let newValue = update(this.state.value, {
      [name]: {
        $set: value,
      },
    });

    if (name === 'severity') {
      let now = moment();
      const daysToAdd = _.reverse([...this.props.options.analyseModifier])[
        value - 1
      ];
      business.addWeekDays(now, daysToAdd);
      newValue.analysisCompletionDate = now.toISOString();
    }

    if (
      (name === 'complexity' ||
        name === 'severity' ||
        name === 'analysisCompletionDate') &&
      newValue.complexity
    ) {
      let now = moment(newValue.analysisCompletionDate);
      const daysToAdd = this.props.options.implementModifier[
        newValue.complexity - 1
      ];
      business.addWeekDays(now, daysToAdd);
      newValue.implementationDate = now.toISOString();
    }

    if (
      newValue.severity &&
      newValue.complexity &&
      newValue.analysisCompletionDate &&
      newValue.implementationDate
    ) {
      this.props.onChange({}, { value: newValue, name: this.props.name });
    }

    this.setState({
      value: newValue,
    });
  };

  handleReset = () => {
    this.setState({
      value: {},
    });
  };

  showConfirmPopup = (name, value) => {
    const { confirmPopupActions } = this.props;

    const { showConfirmDialog } = confirmPopupActions;
    showConfirmDialog(
      `You have already populated data to this field, do you really want to override it?`,
      () => {
        this.changeTheValue(name, value);
      },
      () => {},
      'Yes',
      'No'
    );
  };

  render() {
    const {
      label,
      options,
      editable = true,
      disabled = false,
      required,
    } = this.props;
    const {
      severity,
      complexity,
      analysisCompletionDate,
      implementationDate,
    } = this.state.value;
    return (
      <React.Fragment>
        {editable ? (
          <Form.Field className="field-text-area" required={required}>
            <label>
              {label}
              <FieldToolTip {...this.props} />
            </label>
            <Form.Group widths="equal">
              <Select
                options={options.severityLevels}
                label="Severity"
                name="severity"
                value={severity}
                onChange={this.handleOnChange}
                disabled={disabled}
              />
              <Date
                label="Analysis Completion Date"
                value={analysisCompletionDate}
                onChange={this.handleOnChange}
                name="analysisCompletionDate"
                minDate={moment()}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Select
                options={_.reverse([...options.complexityLevels])}
                label="Complexity"
                name="complexity"
                value={complexity}
                onChange={this.handleOnChange}
                disabled={disabled || !severity}
              />
              <Date
                label="Implementation Date"
                value={implementationDate}
                disabled={disabled || !severity || !complexity}
                onChange={this.handleOnChange}
                name="implementationDate"
                minDate={moment(analysisCompletionDate)}
              />
            </Form.Group>
            {!disabled && (
              <Form.Group>
                <Form.Field>
                  <Button
                    icon
                    size="small"
                    onClick={this.handleReset}
                    floated="right"
                  >
                    <Icon name="refresh" />
                    <label>Reset</label>
                  </Button>
                </Form.Field>
              </Form.Group>
            )}
          </Form.Field>
        ) : (
          <div>
            Analysis Completion Date:{' '}
            <Moment format="DD/MM/YYYY">{analysisCompletionDate}</Moment>
            <br />
            Implementation Date:{' '}
            <Moment format="DD/MM/YYYY">{implementationDate}</Moment>
          </div>
        )}
      </React.Fragment>
    );
  }
}

AnalyseAndImplement.propTypes = {
  options: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  confirmPopupActions: PropTypes.object,
};

export default ConfirmWrapper(AnalyseAndImplement);
