import { useContext, useState } from 'react';
import Moment from 'react-moment';
import toastr from 'toastr';
import { Button, Icon, Input, Popup, Table } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';

import ActionButtons from '../common/ActionButtons';
import { useDistributorListContext } from './DistributorList';
import { DistributorInformation } from '../../types';
import CheckPermission from '../common/CheckPermission';

import * as permissionTypes from '../../constants/permissionTypes';
//import ConfirmationModalContext from '../../context/ConfirmationModalContext';
import { loadDistributorInfos, deactivate } from '../../actions/partyActions';

interface Props {
  distributor: DistributorInformation;
  hiddenColumns?: string[];
}

const getNotificationInfo = (distributorStage: number) => {
  switch (distributorStage) {
    case 10:
      return {
        colour: 'red' as const,
        message: 'Welcome Message Reminder',
        type: 'NOTIFY_USER_FAIR_VALUE' as const,
      };
    case 20:
      return {
        colour: 'yellow' as const,
        message: 'Select Products Reminder',
        type: 'SELECT_PRODUCTS_REMINDER' as const,
      };

    case 30:
      return {
        colour: 'green' as const,
        message: 'Complete Forms Reminder',
        type: 'INCOMPLETE_PRODUCT_FORMS' as const,
      };

    default:
      return undefined;
  }
};

const DistributorListItem = ({ distributor, hiddenColumns = [] }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [label, setLabel] = useState(distributor.label);

//  const { confirmPopupActions } = useContext(ConfirmationModalContext);
  const dispatch = useDispatch();

  const { colour, message, type } =
    getNotificationInfo(distributor.distributorStage) || {};

  const {
    handleSendNotification,
    handleEditPartyLabel,
    LinkComponent,
    PermissionCheckComponent,
    canEdit,
  } = useDistributorListContext();

  const handleNotifyClicked = async () => {
    setIsLoading(true);
    await handleSendNotification(distributor.id, type);
    setIsLoading(false);
  };

  const handleEditLabel = async () => {
    setIsSaving(true);
    try {
      await handleEditPartyLabel(distributor, label);
      toastr.success('Label updated');
    } catch (error) {
      toastr.error(error);
    }

    setIsSaving(false);
    setIsEditing(false);
  };

  const handleLabelChange = (e: any, { value }) => {
    setLabel(value);
  };

  const handleDoesNotDistribute = async () => {
//    confirmPopupActions.showConfirmDialog(
//      'Are you sure this Distributor does not distribute your products?',
//      doesNotDistributeOnConfirm
//    );
//  };

//  const doesNotDistributeOnConfirm = async () => {
    setIsLoading(true);
    try {
      await dispatch(deactivate(distributor.id, 'PARTY_NOTDISTRIBUTE'));
      await dispatch(loadDistributorInfos());
    } catch (error) {
      toastr.error(error.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <Table.Row>
      <Table.Cell
        active={isEditing}
        style={{
          padding: isEditing ? '0 0.5em' : undefined,
        }}
      >
        {!isEditing && (
          <>
            {LinkComponent ? (
              <LinkComponent
                to={`/party/${distributor.id}`}
                partyId={distributor.id}
              >
                {distributor.label}
              </LinkComponent>
            ) : (
              <span>{distributor.label}</span>
            )}
          </>
        )}
        {isEditing && (
          <Input size="mini" value={label} onChange={handleLabelChange}>
            <input
              style={{
                padding: '0 0.5em',
              }}
            />

            <Button
              icon="check"
              size="mini"
              onClick={handleEditLabel}
              loading={isSaving}
              color="purple"
            />
            <Button
              icon="x"
              size="mini"
              onClick={() => {
                setIsEditing(false);
                setLabel(distributor.label);
              }}
              loading={isSaving}
              color="black"
            />
          </Input>
        )}
      </Table.Cell>
      {!hiddenColumns.includes('ref') && (
        <Table.Cell collapsing>{distributor.ref}</Table.Cell>
      )}
      {!hiddenColumns.includes('neverLoggedIn') && (
        <Table.Cell collapsing>
          {distributor.neverLoggedIn ? (
            <Icon color="red" name="x" />
          ) : (
            <Icon color="green" name="check" />
          )}
        </Table.Cell>
      )}
      {!hiddenColumns.includes('distributorDataRequests') && (
        <Table.Cell collapsing>
          {distributor.distributorDataRequests}
        </Table.Cell>
      )}
      {!hiddenColumns.includes('emailBounces') && (
        <Table.Cell collapsing>{distributor.emailBounces}</Table.Cell>
      )}
      {!hiddenColumns.includes('productCount') && (
        <Table.Cell collapsing>{distributor.productCount}</Table.Cell>
      )}
      {!hiddenColumns.includes('directDistributor') && (
        <Table.Cell collapsing>
          {distributor.directDistributor ? '' : <Icon name="check" />}
        </Table.Cell>
      )}

      {!hiddenColumns.includes('actions') && (
        <Table.Cell collapsing>
          <>
            {PermissionCheckComponent ? (
              <PermissionCheckComponent
                structuralNodeId={distributor.structuralNodeId}
                permissionType={permissionTypes.ADMIN}
              >
                <>
                  {canEdit && handleEditPartyLabel && (
                    <Popup
                      content="Edit Distributor Label"
                      trigger={
                        <Button
                          basic
                          loading={isLoading}
                          icon="edit"
                          onClick={() => setIsEditing(true)}
                          compact
                          disabled={isEditing}
                        />
                      }
                    />
                  )}
                  {canEdit && !distributor.doesNotDistribute && (
                    <Popup
                      content="Does Not Distribute"
                      trigger={
                        <Button
                          basic
                          loading={isLoading}
                          icon="dont"
                          onClick={handleDoesNotDistribute}
                          compact
                          disabled={isEditing}
                        />
                      }
                    />
                  )}

                  {handleSendNotification &&
                    distributor.distributorStage !== 0 &&
                    distributor.distributorStage !== 100 && (
                      <Popup
                        content={message}
                        trigger={
                          <Button
                            basic
                            loading={isLoading}
                            icon="mail"
                            onClick={handleNotifyClicked}
                            color={colour}
                            compact
                          />
                        }
                      />
                    )}
                </>
              </PermissionCheckComponent>
            ) : (
              <></>
            )}
          </>
        </Table.Cell>
      )}
    </Table.Row>
  );
};
export default DistributorListItem;
