import React from 'react';
import * as PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import qs from 'query-string';
import UserWrapper from './User';
import { clearLocalStorage } from '../../reducers';

function PrivateRoute(props) {
  const { component: Component, currentUser, ...rest } = props;
  const redirectToLogin = (location) => {
    const { appActions, history } = props;
    appActions.saveStartRoute(location.pathname);
    clearLocalStorage()
    history.push('/login');
    return null;
  };
  if (!currentUser.loggedIn) redirectToLogin(props.location);
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser.loggedIn ? (
          <Component
            {...rest}
            {...props}
            query={qs.parse(props.location.search)}
          />
        ) : (
          redirectToLogin(props.location)
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  appActions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(UserWrapper(PrivateRoute));
