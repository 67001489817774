import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Form,
  Header,
  Segment,
} from 'semantic-ui-react';

import utilsApi from '../../api/utilsApi';
import executionApi from '../../api/executionApi';
import { mapToOptions } from '../../utils/React';
import UserWrapper from '../user/User';
import DebugObject from '../debug/DebugObject';
import ManageRiskThresholds from './ManageRiskThresholds';
import LoginAsUser from './LoginAsUser';
import SetAPIPath from './SetAPIPath';
import JSONBuilder from '../common/fields/JSONBuilder';

const api_url = window.JSW_CONFIG ? window.JSW_CONFIG.apiUrl : '/api/';

class AdminPage extends Component {
  state = {
    models: {},
    targetImportExportModel: 'Workflow',
    targetModelId: null,
    modelList: [],
    chosenNodeId: 2,
    StructuralNodeList: [],
    update: false,
    duplicate: false,
    importResult: {},
    loading: false,
  };

  componentDidMount() {
    utilsApi.getModelStructure().then((result) => {
      this.setState({
        models: result,
      });
    });
    utilsApi.getModelList('StructuralNode').then((result) => {
      this.setState({
        StructuralNodeList: result,
      });
    });
    this.getModelData();
  }

  getModelData = () => {
    const { targetImportExportModel } = this.state;

    utilsApi.getModelList(targetImportExportModel).then((result) => {
      this.setState({
        modelList: result,
      });
    });
  };

  onFormChange = (event, { name, value }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        switch (name) {
          case 'targetImportExportModel':
            this.setState({
              targetModelId: null,
            });
            this.getModelData();
        }
      }
    );
  };

  handleFileUpload = (event) => {
    this.setState({
      chosenFile: event.target.files[0],
    });
  };

  importModel = () => {
    this.setState({
      importResult: {},
      loading: true,
    });
    const {
      chosenFile,
      targetImportExportModel,
      chosenNodeId,
      update,
      duplicate,
    } = this.state;
    utilsApi
      .importModel(targetImportExportModel, chosenFile, null, {
        structuralNodeId: chosenNodeId,
        update,
        duplicate,
      })
      .then((result) => {
        this.setState({
          importResult: result.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          importResult: error,
          loading: false,
        });
      });
  };

  importEntries = () => {
    this.setState({
      importResult: {},
      loading: true,
    });
    const { chosenFile } = this.state;
    executionApi
      .importEntries(chosenFile, null)
      .then((result) => {
        console.log(result);
        this.setState({
          importResult: result.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          importResult: error,
          loading: false,
        });
      });
  };

  render() {
    const {
      models,
      modelList,
      targetImportExportModel,
      targetModelId,
      importResult,
      exportFilter,
      chosenNodeId,
      StructuralNodeList,
      update,
      duplicate,
      loading,
    } = this.state;

    const { currentUser } = this.props;

    let modelOptions = _.map(models, (model, name) => {
      return {
        key: name,
        text: name,
        value: name,
      };
    });
    modelOptions = _.orderBy(modelOptions, 'text');
    return (
      <Container>
        <Segment>
          <Header as="h2">Import/Export</Header>
          <Form>
            <Form.Field>
              <Dropdown
                label="Target Model"
                fluid
                selection
                name="targetImportExportModel"
                onChange={this.onFormChange}
                options={modelOptions}
                value={targetImportExportModel}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                label="Target Model Instance"
                fluid
                selection
                name="targetModelId"
                onChange={this.onFormChange}
                options={[
                  { key: 'all', text: 'All', value: 'all' },
                  ...mapToOptions(modelList),
                ]}
                value={targetModelId}
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                label="Structural Node"
                fluid
                selection
                search
                name="chosenNodeId"
                onChange={this.onFormChange}
                options={mapToOptions(StructuralNodeList)}
                value={chosenNodeId}
              />
            </Form.Field>
            <JSONBuilder
              name="exportFilter"
              value={exportFilter}
              onChange={this.onFormChange}
              allowModeChange={true}
            />
            <Form.Field>
              <input type="file" onChange={this.handleFileUpload} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Update"
                onChange={() => {
                  this.onFormChange(
                    {},
                    {
                      name: 'update',
                      value: !update,
                    }
                  );
                }}
                checked={update}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Duplicate"
                onChange={() => {
                  this.onFormChange(
                    {},
                    {
                      name: 'duplicate',
                      value: !duplicate,
                    }
                  );
                }}
                checked={duplicate}
              />
            </Form.Field>
            <Form.Field>
              <Button
                onClick={this.importModel}
                loading={loading}
                disabled={loading}
              >
                Import
              </Button>
              <Button
                href={`${api_url}${targetImportExportModel}s/${targetModelId}/export?access_token=${
                  currentUser.token
                }&file=1${
                  exportFilter
                    ? `&filter=${encodeURIComponent(
                        JSON.stringify(exportFilter)
                      )}`
                    : ''
                }`}
                target="_blank"
              >
                Export
              </Button>
            </Form.Field>
          </Form>
          <DebugObject obj={importResult} />
        </Segment>
        <Segment>
          <Form>
            <Form.Field>
              <input type="file" onChange={this.handleFileUpload} />
            </Form.Field>
            <Form.Field>
              <Button
                onClick={this.importEntries}
                loading={loading}
                disabled={loading}
              >
                Import
              </Button>
            </Form.Field>
          </Form>
        </Segment>
        <Segment>
          <ManageRiskThresholds />
        </Segment>
        <Segment>
          <LoginAsUser />
        </Segment>
        <Segment>
          <SetAPIPath />
        </Segment>
      </Container>
    );
  }
}

AdminPage.propTypes = {
  currentUser: PropTypes.object,
};

export default UserWrapper(AdminPage);
