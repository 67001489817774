import { Icon, Label, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { RAGStatusResult } from '../../../../../api/statusApi';
import { Entity } from '../../../../../types';

interface Props {
  data: {
    data?: {
      main?: RAGStatusResult;
      products?: Entity[];
    };
  };
}

const sortDirectMap: { [key: string]: 'ascending' | 'descending' } = {
  asc: 'ascending',
  desc: 'descending',
};

const FairValueProductList = (props: Props) => {
  const [sortColumn, setSortColumn] = useState('productClass');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const allResults = props.data?.data?.main;
  const products = props.data?.data?.products;

  const productRows = useMemo(() => {
    return products?.map((product) => {
      const { entries, label } = product;
      const productClass = entries?.find(
        (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS'
      )?.value;
      const productUMR = entries?.find(
        (entry) => entry.entityTypeFieldId === 'PRODUCT_UMR'
      )?.value;
      // const negativeResults = results.filter((result) => {
      //   const hasException = result.statusResults.some((statusResult) =>
      //     !Array.isArray(statusResult.logicResult)
      //       ? statusResult.logicResult.result === 'red'
      //       : false
      //   );
      //   return hasException;
      // });

      // const executionId = result.results[0]?.statusResults
      //   .map((statusResult) => statusResult.relevantEntries)
      //   .flat()
      //   .find((entry) => !!entry.executionEntityId)?.executionId;

      const executionId = product.executions?.[0]?.id;

      return {
        ...product,
        productClass,
        numNegativeResults: 0,
        executionId,
        productUMR,
      };
    });
  }, [products]);

  // return <pre>{JSON.stringify(productRows, null, '  ')}</pre>;

  const exceptionCountMap = useMemo(() => {
    if (!allResults) return {};
    const exceptionCountMap: { [key: string]: number } = {};
    allResults?.results.forEach((result) => {
      result.statusResults.forEach((statusResult) => {
        if (Array.isArray(statusResult.logicResult)) return;

        if (statusResult.logicResult.result === 'EXCEPTION') {
          exceptionCountMap[statusResult.dimensionValue] =
            exceptionCountMap[statusResult.dimensionValue] + 1 || 1;
        }
      });
    });

    return exceptionCountMap;
  }, [allResults]);

  const handleChangeSort = (newSortColumn: string) => {
    if (newSortColumn === sortColumn)
      return setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(newSortColumn);
    setSortDirection('asc');
  };

  if (!productRows) return null;

  const productRowsSorted = orderBy(productRows, [sortColumn], [sortDirection]);

  return (
    <Table sortable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              sortColumn === 'label' ? sortDirectMap[sortDirection] : null
            }
            onClick={() => handleChangeSort('label')}
          >
            Product Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortColumn === 'productClass'
                ? sortDirectMap[sortDirection]
                : null
            }
            onClick={() => handleChangeSort('productClass')}
          >
            Product Class
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>Reference</Table.HeaderCell>
          <Table.HeaderCell>Exceptions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {productRowsSorted.map(
          (
            {
              label,
              productClass,
              executionId,
              numNegativeResults,
              productUMR,
              id,
            },
            index
          ) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <Link
                    to={`/execution/${executionId}/reports/FF58AC90-821C-11ED-9746-4740A31726A1`}
                  >
                    {label}
                  </Link>
                </Table.Cell>
                <Table.Cell>{productClass}</Table.Cell>
                <Table.Cell>{productUMR}</Table.Cell>
                <Table.Cell>
                  {exceptionCountMap[id] && (
                    <Link
                      to={`/execution/${executionId}/reports/FF58AC90-821C-11ED-9746-4740A31726A1`}
                    >
                      <Label size="large" color="red">
                        <Icon name="times" size="large" />{' '}
                        <strong>{exceptionCountMap[id]}</strong>
                      </Label>
                    </Link>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </Table>
  );
};

export default FairValueProductList;
