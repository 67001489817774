import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { WorkflowWrapContext } from '../../context/WorkflowContext';
import { Button, Table } from 'semantic-ui-react';
import WorkflowStatusListRow from './WorkflowStatusListRow';

@WorkflowWrapContext
export default class WorkflowStatusList extends Component {
  updateStatus = (event, { name, value, checked }, status) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.updateStatus(workflow, status, name, value || checked);
  };
  addStatus = () => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.addStatus(workflow);
  };
  removeStatus = (event, status) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.removeStatus(workflow, status);
  };

  onResolutionChange = (status, resolutionType, value) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.updateStatusResolution(
      workflow,
      status,
      resolutionType,
      value
    );
  };

  toggleResolution = (status, resolutionType, makeAvailable) => {
    const { workflow, workflowStateActions } = this.props;
    if (makeAvailable) {
      workflowStateActions.addStatusResolution(
        workflow,
        status,
        resolutionType
      );
    } else {
      workflowStateActions.removeStatusResolution(
        workflow,
        status,
        resolutionType
      );
    }
  };

  addField = (event, status, data) => {
    const { workflow, workflowStateActions } = this.props;
    const fieldType = _.find(
      this.props.activityFieldTypes,
      (fieldType) => fieldType.id === data.value
    );
    workflowStateActions.addStatusField(workflow, status, fieldType);
  };

  deleteField = (fieldIndex, status) => {
    const { workflow, workflowStateActions } = this.props;
    workflowStateActions.removeStatusField(workflow, status, fieldIndex);
  };

  onFieldChange = (fieldIndex, status, newValue) => {
    const { workflow, workflowStateActions } = this.props;
    if (_.isEmpty(newValue)) {
      this.deleteField(fieldIndex, status);
      return;
    }
    workflowStateActions.updateStatusField(
      workflow,
      status,
      fieldIndex,
      newValue
    );
  };

  render() {
    const {
      statusTypes,
      activityResolutionTypes,
      activityFieldTypes,
    } = this.props;
    const { statuses } = this.props.workflow;
    return (
      <React.Fragment>
        <Table>
          <Table.Body>
            {statuses.map((status, index) => (
              <WorkflowStatusListRow
                key={index}
                status={status}
                statusTypes={statusTypes}
                activityResolutionTypes={activityResolutionTypes}
                activityFieldTypes={activityFieldTypes}
                onResolutionChange={this.onResolutionChange}
                toggleResolution={this.toggleResolution}
                onChange={(event, data) =>
                  this.updateStatus(event, data, status)
                }
                onRemove={(event) => this.removeStatus(event, status)}
                addField={this.addField}
                onFieldChange={this.onFieldChange}
              />
            ))}
            <Table.Row>
              <Table.Cell colSpan="2">
                <Button onClick={this.addStatus}>Add Status</Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

WorkflowStatusList.propTypes = {
  workflow: PropTypes.object,
  workflowStateActions: PropTypes.object,
  statusTypes: PropTypes.array,
  activityResolutionTypes: PropTypes.array,
  activityFieldTypes: PropTypes.array,
};
