import slugify from 'slugify/index';
import _ from 'lodash';

export default function (args) {
  const { dataSet } = args;
  const data = args.data[dataSet];

  const filteredData = data.filter(
    (dataPoint) => !!dataPoint.analyseAndImplement
  );

  const sets = _.uniq(filteredData, 'activitySetLabel').map((dataPoint) => {
    return {
      id: `TICKETSET-${slugify(dataPoint.activitySetLabel)}`,
      text: dataPoint.activitySetLabel,
      ref: dataPoint.activitySetLabel,
      open: 1,
      ticketSet: true,
      type: 'ticketSet',
    };
  });

  const tasks = filteredData.map((dataPoint) => {
    const analyseAndImplement = JSON.parse(dataPoint.analyseAndImplement);
    return {
      dataPoint,

      id: `TICKET-${dataPoint.id}`,
      originalId: dataPoint.id,
      start_date: new Date(analyseAndImplement.analysisCompletionDate),
      end_date: new Date(analyseAndImplement.implementationDate),
      text: dataPoint.activityLabel,
      ref: dataPoint.id,
      open: 1,
      parent: `TICKETSET-${slugify(dataPoint.activitySetLabel)}`,
      type: 'ticket',
    };
  });

  return { tasks: { data: [...sets, ...tasks] } };
}
