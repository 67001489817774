import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import RelationshipEntitySearch from '../common/search/RelationshipEntitySearch';
import programmeApi from '../../api/programmeApi';
import BinderMatrix from './BinderMatrix';
import * as PropTypes from 'prop-types';

const BinderScoping = (props) => {
  const { options, onChange } = props;

  const [binders, setBinders] = useState([]);
  const [programme, setProgramme] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    async function fetchProgramme() {
      const programmeResult = await programmeApi.getProgramme(
        options.programme.id
      );

      setProgramme(programmeResult);
    }

    fetchProgramme();
  }, [options.programme.id]);

  const handleAddBinder = (event, data) => {
    if (_.find(binders, { id: data.id })) return;

    const entriesMapped = {};
    data.entries.forEach((entry) => {
      entriesMapped[entry.partyRelationshipTypeFieldId] = entry;
    });

    const mappedBinder = {
      ...data,
      entriesMapped,
    };
    setBinders([...binders, mappedBinder]);

    const clone = _.clone(selectedValues);
    _.forEach(programme.activitySets, (activitySet) => {
      _.set(clone, [data.id, activitySet.id], 'Binder');
    });

    onChange(clone);
    setSelectedValues(clone);
  };

  const handleRemoveBinder = (binderIndex) => {
    setBinders(binders.filter((b, index) => index !== binderIndex));
  };

  return (
    <React.Fragment>
      <RelationshipEntitySearch
        label="Add Binder"
        partyId={options.ownerParty.id}
        relationshipType="BND"
        value={binders}
        onChange={handleAddBinder}
      />

      {programme && binders && (
        <BinderMatrix
          onRemoveBinder={handleRemoveBinder}
          programme={programme}
          binders={binders}
          onChange={onChange}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
      )}
    </React.Fragment>
  );
};

export default BinderScoping;

BinderScoping.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func,
};
