import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Message, Header, Grid } from 'semantic-ui-react';
import * as _ from 'lodash';

import ExecutionWrapper from './Execution';
import { checkValidation } from '../../utils/Validation';
import ExecutionDetailsForm from './ExecutionDetailsForm';
import toastr from 'toastr';

class ExecutionDetailsSettings extends Component {
  state = {
    formData: {
      label: this.props.currentExecution.label,
      applicableDate: this.props.currentExecution.applicableDate,
      applicableDateTypeId: this.props.currentExecution.applicableDateTypeId,
      dueDate: this.props.currentExecution.dueDate,
      partyId: this.props.currentExecution.partyId,
      ownerPartyId: this.props.currentExecution.ownerPartyId,
      reviewPartyId: this.props.currentExecution.reviewPartyId,
      assignedPartyUserId: this.props.currentExecution.assignedPartyUserId,
      reviewPartyUserId: this.props.currentExecution.reviewPartyUserId,
      ownerPartyUserId: this.props.currentExecution.ownerPartyUserId,
      executionStateId: this.props.currentExecution.executionStateId,
    },
    isSaveButtonVisible: false,
    saving: false,
    apiError: null,
    validations: {
      label: { isValid: true },
    },
    isFormDataValid: true,
  };
  handleFormDataChange = (e, { name, value, checked }) => {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value || checked || null,
        },
      }),
      () => {
        this.checkIsUpdated();
      }
    );
  };
  checkIsUpdated = () => {
    const { label, applicableDate, dueDate } = this.props.currentExecution;
    const formDataProps = { label, applicableDate, dueDate };
    const { formData } = this.state;
    const isEqual = _.isEqual(formDataProps, formData);
    this.setState({ isSaveButtonVisible: !isEqual });
  };
  saveExecutionDetails = () => {
    const { executionStateActions } = this.props;
    const { id } = this.props.currentExecution;
    const { formData } = this.state;
    this.setState({ saving: true, apiError: null });
    executionStateActions
      .saveExecutionDetails({ id, ...formData })
      .then(() => {
        this.setState({ saving: false, isSaveButtonVisible: false });
        toastr.success('Changes saved');
      })
      .catch(({ message }) => {
        this.setState({ apiError: message, saving: false });
      });
  };
  handleOnFocus = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = true;
    this.setState({ validations });
  };

  handleOnBlur = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = false;
    this.checkValidation(name, this.state.formData[name]);
    this.setState({ validations });
  };
  checkValidation = (name, value) => {
    const validation = checkValidation(
      { [name]: value },
      {
        [name]: {
          required: {
            error: `Please supply the ${name}`,
          },
        },
      }
    );
    const { validations } = this.state;
    validations[name].errors = validation.errors;
    validations[name].isValid = validation.valid;
    this.setState({
      validations,
      isFormDataValid: _.every(validations, { isValid: true }),
    });
  };
  getErrors = (propertryName) => {
    const { validations } = this.state;
    const validation = validations[propertryName];
    const isValidationErrors = !_.isEmpty(validation.errors);
    return !validation.hasFocus && isValidationErrors ? validation.errors : {};
  };
  render() {
    const {
      formData,
      isSaveButtonVisible,
      saving,
      apiError,
      isFormDataValid,
    } = this.state;
    const propsLabel = this.props.currentExecution.label;
    return (
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={6}>
          <Header content={`Edit details for ${propsLabel}`} />
          <ExecutionDetailsForm
            execution={formData}
            handleFormDataChange={this.handleFormDataChange}
            getErrors={this.getErrors}
            handleOnBlur={this.handleOnBlur}
            handleOnFocus={this.handleOnFocus}
            onSave={this.saveExecutionDetails}
            saving={saving}
            isValid={isFormDataValid}
            isEdited={isSaveButtonVisible}
          />
          {apiError && <Message error content={apiError} />}
        </Grid.Column>
      </Grid>
    );
  }
}

ExecutionDetailsSettings.propTypes = {
  currentExecution: PropTypes.object,
  executionStateActions: PropTypes.object,
};

export default ExecutionWrapper(ExecutionDetailsSettings);
