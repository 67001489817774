import * as types from '../constants/actionTypes';
import eventApi from '../api/eventApi';

export function getEventsList(filter) {
  return function (dispatch) {
    return eventApi
      .getEventsList(filter)
      .then((events) => {
        dispatch({
          type: types.LOAD_EVENTS_SUCCESS,
          events,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.LOAD_EVENTS_FAILED,
          errorMessage: err.message,
        });
      });
  };
}

export function markNewEvents() {
  return function (dispatch) {
    return dispatch({
      type: types.RESET_NEW_EVENTS,
    });
  };
}

export function cleanEventsList() {
  return function (dispatch) {
    return dispatch({
      type: types.CLEAN_NEW_EVENTS,
    });
  };
}
