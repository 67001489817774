import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const mapValues = function (args) {
  const { dataSet, data, outputKey, values, mapping } = args;
  const dataToMap = data[dataSet];

  const outputValues = _.chain(dataToMap)
    .map((value) => {
      let v = _.cloneDeep(value);

      _.forEach(values, (_mapValue, mapKey) => {
        let mapValue = _mapValue;
        let fallbackToString = true;
        if (mapValue[0] === `~`) {
          mapValue = mapValue.substr(1);
          fallbackToString = false;
        }

        const fallback = fallbackToString ? mapValue : null;

        let valueToMap = _.get(v, mapValue) || fallback;

        if (mapping) {
          const mappedValue = _.get(mapping, valueToMap);
          if (mappedValue) valueToMap = mappedValue;
        }
        v[mapKey] = valueToMap;
      });

      return v;
    })
    .value();

  return {
    ...args,
    data: { ...data, [outputKey || 'mappedValues']: outputValues },
  };
};

export default mapValues;

mapValues.args = {
  dataSet: ArgTypes.string,
  outputKey: ArgTypes.string,
  label: ArgTypes.string,
  values: ArgTypes.json,
};
