import { Button, Icon, Label, Popup, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Entity } from '../../../../../types';
import { useMemo, useState } from 'react';
import { orderBy, uniqBy } from 'lodash';

import { useDispatch } from 'react-redux';
import { SIDEBAR_COMPONENTS } from '../../../SidebarRoot';
import { RAGStatusResult, Result } from '../../../../../api/statusApi';
import * as uiActions from '../../../../../actions/uiActions';

interface Props {
  data: {
    data: {
      main: RAGStatusResult[];
    };
  };
}

const sortDirectMap: { [key: string]: 'ascending' | 'descending' } = {
  asc: 'ascending',
  desc: 'descending',
};

const FairValueExceptionList = (props: Props) => {
  return null;

  const dispatch = useDispatch();

  const [sortColumn, setSortColumn] = useState('productLabel');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const allResults = props.data.data.main;

  const prod4Statuses = useMemo(() => {
    const results = allResults?.[0].results;
    if (!results) return [];
    return orderBy(results, 'sort').map((result) => result.meta?.prod);
  }, [allResults]);

  const rows = useMemo(() => {
    type Return = Result & {
      productClass: string;
      productUMR: string;
      productLabel: string;
      relevantEntries: any;
      exceptionEntries: any;
    };
    const result: Return[] = allResults
      ?.map((result) => {
        const { entries, label, results } = result;
        const productClass = entries?.find(
          (entry) => entry.entityTypeFieldId === 'PRODUCT_CLASS'
        )?.value;
        const productUMR = entries?.find(
          (entry) => entry.entityTypeFieldId === 'PRODUCT_UMR'
        )?.value;
        const negativeResults = results.filter((result) => {
          const hasException = result.statusResults.some((statusResult) =>
            !Array.isArray(statusResult.logicResult)
              ? statusResult.logicResult.result === 'red'
              : false
          );
          return hasException;
        });

        return {
          negativeResults,
          productClass,
          productUMR,
          productLabel: label,
        };
      })
      .reduce(
        (accum, current) => {
          if (!current.negativeResults) return accum;
          const r = current.negativeResults.map((result) => {
            const { productLabel, productClass, productUMR } = current;

            const relevantEntries = uniqBy(
              result.statusResults
                .map((statusResult) => statusResult.relevantEntries)
                .flat()
                .map((statusResult) => {
                  return {
                    ...statusResult,
                    field: {
                      id: statusResult.fieldId,
                      label: statusResult.label,
                      activityFieldTypeId: statusResult.activityFieldTypeId,
                      activityFieldType: statusResult.activityFieldTypeId,
                      options: null,
                    },
                  };
                }),
              'id'
            );

            const exceptionEntries = result.statusResults
              .filter((statusResult) =>
                !Array.isArray(statusResult.logicResult)
                  ? statusResult.logicResult.result === 'red'
                  : false
              )
              .map((statusResult) => statusResult.relevantEntries)
              .flat();
            const ticketIds = relevantEntries.map(
              (entry) => entry.executionTicketId
            );

            return {
              ...result,
              productLabel,
              productClass,
              productUMR,
              relevantEntries,
              exceptionEntries,
            };
          });
          return [...accum, ...r];
        },

        []
      );

    return result;
  }, [allResults]);

  //   return <pre>{JSON.stringify(rows, null, "  ")}</pre>;

  const handleChangeSort = (newSortColumn: string) => {
    if (newSortColumn === sortColumn)
      return setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(newSortColumn);
    setSortDirection('asc');
  };

  const handleShowTickets = (
    relevantEntries: any[],
    exceptionIds: number[]
  ) => {
    const ticketIds = relevantEntries.map((entry) => entry.executionTicketId);
    dispatch(
      uiActions.showSidebar({
        sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
        sidebarProps: {
          baseConfig: {
            model: 'ExecutionTicket',
            endpoint: '',
            params: {
              filter: {
                limit: 10,

                where: { id: { inq: ticketIds } },
              },
            },
            relevantEntries,
            exceptionIds,
          },
          data: {},
        },
        sidebarConfig: { width: 'very wide' },
      })
    );
  };

  if (!allResults) return null;

  const rowsSorted = orderBy(rows, [sortColumn], [sortDirection]);

  // return <pre>{JSON.stringify(rows,null, '  ')}</pre>

  return (
    <Table striped sortable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              sortColumn === 'productLabel'
                ? sortDirectMap[sortDirection]
                : null
            }
            onClick={() => handleChangeSort('productLabel')}
          >
            Product Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortColumn === 'productClass'
                ? sortDirectMap[sortDirection]
                : null
            }
            onClick={() => handleChangeSort('productClass')}
          >
            Product Class
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortColumn === 'meta.prod' ? sortDirectMap[sortDirection] : null
            }
            onClick={() => handleChangeSort('meta.prod')}
          >
            PROD4
          </Table.HeaderCell>
          <Table.HeaderCell collapsing></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rowsSorted.map(
          (
            {
              label,
              relevantEntries,
              exceptionEntries,
              productLabel,
              meta,
              productClass,
              id,
            },
            index
          ) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{productLabel}</Table.Cell>
                <Table.Cell>{productClass}</Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={<span>{meta.prod}</span>}
                    content={<div>{label}</div>}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    basic
                    size="tiny"
                    icon="eye"
                    onClick={() =>
                      handleShowTickets(
                        relevantEntries,
                        exceptionEntries.map((entry) => entry.executionTicketId)
                      )
                    }
                  />
                </Table.Cell>
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </Table>
  );
};

export default FairValueExceptionList;
