export const LOAD_ACTIVITIES_SUCCESS = 'LOAD_ACTIVITIES_SUCCESS';

export const LOAD_ACTIVITY_SUCCESS = 'LOAD_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_META = 'UPDATE_ACTIVITY_META';
export const RESET_ACTIVITY = 'RESET_ACTIVITY';
export const ADD_ACTIVITY_FIELD = 'ADD_ACTIVITY_FIELD';
export const REMOVE_ACTIVITY_FIELD = 'REMOVE_ACTIVITY_FIELD';
export const UPDATE_ACTIVITY_FIELD = 'UPDATE_ACTIVITY_FIELD';
export const UPDATE_RESOLUTION_LABEL = 'UPDATE_RESOLUTION_LABEL';
export const SAVE_ACTIVITY = 'SAVE_ACTIVITY';
export const SAVE_ACTIVITY_SUCCESS = 'SAVE_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_WORKFLOW_FIELD_DEFAULT =
  'ADD_ACTIVITY_RESOLUTION_NOTE';
export const REMOVE_ACTIVITY_WORKFLOW_FIELD_DEFAULT =
  'REMOVE_ACTIVITY_WORKFLOW_FIELD_DEFAULT';
export const UPDATE_ACTIVITY_WORKFLOW_FIELD_DEFAULT =
  'UPDATE_ACTIVITY_WORKFLOW_FIELD_DEFAULT';

export const UPDATE_PROGRAMME = 'UPDATE_PROGRAMME';
export const ADD_PROGRAMME_FIELD = 'ADD_PROGRAMME_FIELD';
export const UPDATE_PROGRAMME_FIELD = 'UPDATE_PROGRAMME_FIELD';
export const REMOVE_PROGRAMME_FIELD = 'REMOVE_PROGRAMME_FIELD';
export const SAVE_PROGRAMME_SUCCESS = 'SAVE_PROGRAMME_SUCCESS';
export const PROGRAMME_ADD_ACTIVITY_SET = 'PROGRAMME_ADD_ACTIVITY_SET';
export const PROGRAMME_UPDATE_ACTIVITY_SET = 'PROGRAMME_UPDATE_ACTIVITY_SET';
export const ADD_ACTIVITY_TO_SET = 'ADD_ACTIVITY_TO_SET';
export const REMOVE_ACTIVITY_FROM_SET = 'REMOVE_ACTIVITY_FROM_SET';
export const REMOVE_ACTIVITY_SET = 'REMOVE_ACTIVITY_SET';
export const MOVE_ACTIVITY_SET = 'MOVE_ACTIVITY_SET';
export const MOVE_ACTIVITY_IN_SET = 'MOVE_ACTIVITY_IN_SET';
export const ADD_ACTIVITY_SET_TO_CATEGORY = 'ADD_ACTIVITY_SET_TO_CATEGORY';
export const PROGRAMME_UPDATE_CATEGORY = 'PROGRAMME_UPDATE_CATEGORY';
export const PROGRAMME_REMOVE_CATEGORY = 'PROGRAMME_REMOVE_CATEGORY';
export const PROGRAMME_ADD_CATEGORY = 'PROGRAMME_ADD_CATEGORY';
export const LOAD_PROGRAMME_SUCCESS = 'LOAD_PROGRAMME_SUCCESS';
export const LOAD_PROGRAMMES_SUCCESS = 'LOAD_PROGRAMMES_SUCCESS';

export const CREATE_EXECUTION_SUCCESS = 'CREATE_EXECUTION_SUCCESS';
export const LOAD_EXECUTION_SUCCESS = 'LOAD_EXECUTION_SUCCESS';
export const LOAD_EXECUTIONS_SUCCESS = 'LOAD_EXECUTIONS_SUCCESS';

export const LOAD_TICKET_SUCCESS = 'LOAD_TICKET_SUCCESS';
export const LOAD_TICKET = 'LOAD_TICKET';
export const SET_TICKET = 'SET_TICKET';
export const LOAD_TICKETS_SUCCESS = 'LOAD_TICKETS_SUCCESS';
export const TICKET_UPDATE_ENTRY = 'TICKET_UPDATE_ENTRY';
export const TICKET_ADD_ATTACHMENTS = 'TICKET_ADD_ATTACHMENTS';
export const TICKET_DELETE_ATTACHMENT_SUCCESS =
  'TICKET_DELETE_ATTACHMENT_SUCCESS';
export const TICKET_UPDATE_WORKFLOW_ENTRY = 'TICKET_UPDATE_WORKFLOW_ENTRY';
export const SAVE_TICKET_ENTRIES_SUCCESS = 'SAVE_TICKET_ENTRIES_SUCCESS';
export const SAVE_TICKET_WORKFLOW_ENTRIES_SUCCESS =
  'SAVE_TICKET_WORKFLOW_ENTRIES_SUCCESS';
export const SAVING_TICKET_WORKFLOW_ENTRIES = 'SAVING_TICKET_WORKFLOW_ENTRIES';
export const SAVING_TICKET_ENTRIES = 'SAVING_TICKET_ENTRIES';
export const SAVING_TICKET_TRANSITION = 'SAVING_TICKET_TRANSITION';
export const SAVING_TICKET_TRANSITION_SUCCESS =
  'SAVING_TICKET_TRANSITION_SUCCESS';
export const SAVING_TICKET_RESOLUTION = 'SAVING_TICKET_RESOLUTION';
export const SAVING_TICKET_RESOLUTION_SUCCESS =
  'SAVING_TICKET_RESOLUTION_SUCCESS';
export const SAVING_TICKET_REMOVE_RESOLUTION_SUCCESS =
  'SAVING_TICKET_REMOVE_RESOLUTION_SUCCESS';
export const SAVING_TICKET = 'SAVING_TICKET';
export const SAVING_TICKET_SUCCESS = 'SAVING_TICKET_SUCCESS';
export const SAVING_TICKETS_SUCCESS = 'SAVING_TICKETS_SUCCESS';
export const TICKET_ADD_TO_FOLLOW_UP_LIST = 'TICKET_ADD_TO_FOLLOW_UP_LIST';
export const TICKET_REMOVE_FROM_FOLLOW_UP_LIST =
  'TICKET_REMOVE_FROM_FOLLOW_UP_LIST';
export const TICKETS_ADD_TO_FOLLOW_UP_LIST = 'TICKETS_ADD_TO_FOLLOW_UP_LIST';
export const TICKETS_REMOVE_FROM_FOLLOW_UP_LIST =
  'TICKETS_REMOVE_FROM_FOLLOW_UP_LIST';

export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_EXTRA_AUTH_REQUIRED = 'USER_EXTRA_AUTH_REQUIRED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const UPDATE_USER_TICKET_COUNT = 'UPDATE_USER_TICKET_COUNT';

export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';

export const LOAD_PARTIES_SUCCESS = 'LOAD_PARTIES_SUCCESS';
export const LOAD_PARTY_SUCCESS = 'LOAD_PARTY_SUCCESS';
export const LOAD_DISTRIBUTOR_INFOS_SUCCESS = 'LOAD_DISTRIBUTOR_INFOS_SUCCESS';

export const LOAD_PERMISSION_SUCCESS = 'LOAD_PERMISSION_SUCCESS';
export const LOAD_PERMISSIONS_SUCCESS = 'LOAD_PERMISSIONS_SUCCESS';
export const LOAD_PERMISSION_TYPES_SUCCESS = 'LOAD_PERMISSION_TYPES_SUCCESS';
export const LOAD_ROLE_DEFINITIONS_SUCCESS = 'LOAD_ROLE_DEFINITIONS_SUCCESS';

export const CONSTANTS_LOAD_SUCCESS_MODEL_STRUCTURE =
  'CONSTANTS_LOAD_SUCCESS_MODEL_STRUCTURE';

export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const SET_WORKFLOW = 'SET_WORKFLOW';
export const LOAD_WORKFLOWS_SUCCESS = 'LOAD_WORKFLOWS_SUCCESS';
export const LOAD_WORKFLOW_SUCCESS = 'LOAD_WORKFLOW_SUCCESS';
export const SAVE_WORKFLOW_SUCCESS = 'SAVE_WORKFLOW_SUCCESS';
export const WORKFLOW_ADD_STATUS = 'WORKFLOW_ADD_STATUS';
export const WORKFLOW_REMOVE_STATUS = 'WORKFLOW_REMOVE_STATUS';
export const WORKFLOW_UPDATE_STATUS = 'WORKFLOW_UPDATE_STATUS';
export const ADD_WORKFLOW_STATUS_FIELD = 'ADD_WORKFLOW_STATUS_FIELD';
export const UPDATE_WORKFLOW_STATUS_FIELD = 'UPDATE_WORKFLOW_STATUS_FIELD';
export const REMOVE_WORKFLOW_STATUS_FIELD = 'REMOVE_WORKFLOW_STATUS_FIELD';
export const WORKFLOW_ADD_STATUS_NODE = 'WORKFLOW_ADD_STATUS_NODE';
export const WORKFLOW_REMOVE_STATUS_NODE = 'WORKFLOW_REMOVE_STATUS_NODE';
export const WORKFLOW_UPDATE_STATUS_NODE = 'WORKFLOW_UPDATE_STATUS_NODE';
export const WORKFLOW_ADD_STATUS_RESOLUTION = 'WORKFLOW_ADD_STATUS_RESOLUTION';
export const WORKFLOW_REMOVE_STATUS_RESOLUTION =
  'WORKFLOW_REMOVE_STATUS_RESOLUTION';
export const WORKFLOW_UPDATE_STATUS_RESOLUTION =
  'WORKFLOW_UPDATE_STATUS_RESOLUTION';
export const WORKFLOW_ADD_STATUS_PERMISSION = 'WORKFLOW_ADD_STATUS_PERMISSION';
export const WORKFLOW_REMOVE_STATUS_PERMISSION =
  'WORKFLOW_REMOVE_STATUS_PERMISSION';
export const WORKFLOW_UPDATE_STATUS_PERMISSION =
  'WORKFLOW_UPDATE_STATUS_PERMISSION';
export const WORKFLOW_ADD_TRANSITION = 'WORKFLOW_ADD_TRANSITION';
export const WORKFLOW_REMOVE_TRANSITION = 'WORKFLOW_REMOVE_TRANSITION';
export const WORKFLOW_UPDATE_TRANSITION = 'WORKFLOW_UPDATE_TRANSITION';
export const WORKFLOW_ADD_TRANSITION_PERMISSION =
  'WORKFLOW_ADD_TRANSITION_PERMISSION';
export const WORKFLOW_REMOVE_TRANSITION_PERMISSION =
  'WORKFLOW_REMOVE_TRANSITION_PERMISSION';
export const WORKFLOW_UPDATE_TRANSITION_PERMISSION =
  'WORKFLOW_UPDATE_TRANSITION_PERMISSION';

export const LOAD_EXECUTION_PLAN_SUCCESS = 'LOAD_EXECUTION_PLAN_SUCCESS';
export const LOAD_EXECUTION_PLANS_SUCCESS = 'LOAD_EXECUTION_PLANS_SUCCESS';
export const SAVE_EXECUTION_PLAN_SUCCESS = 'SAVE_EXECUTION_PLAN_SUCCESS';
export const PATCH_EXECUTION_PLAN_SUCCESS = 'PATCH_EXECUTION_PLAN_SUCCESS';
export const UPDATE_EXECUTION_PLAN_SUCCESS = 'UPDATE_EXECUTION_PLAN_SUCCESS';
export const EXECUTION_PLAN_ADD_ACTIVITY_SET_SUCCESS =
  'EXECUTION_PLAN_ADD_ACTIVITY_SET_SUCCESS';
export const EXECUTION_PLAN_REMOVE_ACTIVITY_SET_SUCCESS =
  'EXECUTION_PLAN_REMOVE_ACTIVITY_SET_SUCCESS';
export const EXECUTION_PLAN_ACTIVITY_SET_ADD_RELATIONSHIP_SUCCESS =
  'EXECUTION_PLAN_ACTIVITY_SET_ADD_RELATIONSHIP_SUCCESS';
export const EXECUTION_PLAN_ACTIVITY_SET_REMOVE_RELATIONSHIP_SUCCESS =
  'EXECUTION_PLAN_ACTIVITY_SET_REMOVE_RELATIONSHIP_SUCCESS';
export const EXECUTION_PLAN_ACTIVITY_SET_ADD_ENTITY_SUCCESS =
  'EXECUTION_PLAN_ACTIVITY_SET_ADD_ENTITY_SUCCESS';
export const EXECUTION_PLAN_ACTIVITY_SET_REMOVE_ENTITY_SUCCESS =
  'EXECUTION_PLAN_ACTIVITY_SET_REMOVE_ENTITY_SUCCESS';

export const CREATE_USERGROUP_SUCCESS = 'CREATE_USERGROUP_SUCCESS';
export const CREATE_PARTY_SUCCESS = 'CREATE_PARTY_SUCCESS';
export const UPDATE_PARTY_SUCCESS = 'UPDATE_PARTY_SUCCESS';
export const REMOVE_USER_FROM_USERGROUP_SUCCESS =
  'REMOVE_USER_FROM_USERGROUP_SUCCESS';
export const ADD_USER_TO_USERGROUP_SUCCESS = 'ADD_USER_TO_USERGROUP_SUCCESS';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const DELETE_RELATIONSHIP_FROM_PARTY_SUCCESS =
  'DELETE_RELATIONSHIP_FROM_PARTY_SUCCESS';
export const DELETE_USERGROUP_SUCCESS = 'DELETE_USERGROUP_SUCCESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ALL_ADDRESSES_SUCCESS = 'DELETE_ALL_ADDRESSES_SUCCESS';
export const UPDATE_USERGROUP_SUCCESS = 'UPDATE_USERGROUP_SUCCESS';
export const SAVE_ADDRESS_TO_PARTY_SUCCESS = 'SAVE_ADDRESS_TO_PARTY_SUCCESS';
export const SAVE_RELATIONSHIP_SUCCESS = 'SAVE_RELATIONSHIP_SUCCESS';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const UPLOAD_PARTY_IMAGE = 'UPLOAD_PARTY_IMAGE_SUCCESS';

export const SET_CREATE_ACTIVITY_ACTION = 'SET_CREATE_ACTIVITY_ACTION';
export const REGISTER_UI_STATE = 'REGISTER_UI_STATE';
export const CHANGE_UI_STATE = 'CHANGE_UI_STATE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const EXPAND_MENU = 'EXPAND_MENU';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';

export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const CLOSE_CONTEXT_MENU = 'CLOSE_CONTEXT_MENU';

export const LOAD_DASHBOARD_WIDGET_DATA_SUCCESS =
  'LOAD_DASHBOARD_WIDGET_DATA_SUCCESS';
export const FETCH_DASHBOARD_SCHEMA_SUCCESS = 'FETCH_DASHBOARD_SCHEMA_SUCCESS';
export const FETCH_DASHBOARD_SCHEMA_FAILED = 'FETCH_DASHBOARD_SCHEMA_FAILED';
export const ADD_DASHBOARD_ROW_SUCCESS = 'ADD_DASHBOARD_ROW_SUCCESS';
export const ADD_DASHBOARD_COLUMN_SUCCESS = 'ADD_DASHBOARD_COLUMN_SUCCESS';
export const ADD_DASHBOARD_ITEM_SUCCESS = 'ADD_DASHBOARD_ITEM_SUCCESS';
export const REMOVE_DASHBOARD_ROW_SUCCESS = 'REMOVE_DASHBOARD_ROW_SUCCESS';
export const REMOVE_DASHBOARD_COLUMN_SUCCESS =
  'REMOVE_DASHBOARD_COLUMN_SUCCESS';
export const REMOVE_DASHBOARD_ITEM_SUCCESS = 'REMOVE_DASHBOARD_ITEM_SUCCESS';
export const SET_DASHBOARD_COLUMN_OPTION = 'SET_DASHBOARD_COLUMN_OPTION';
export const SET_DASHBOARD_COLUMN_OPTIONS = 'SET_DASHBOARD_COLUMN_OPTIONS';
export const SET_DASHBOARD_ROW_OPTIONS = 'SET_DASHBOARD_ROW_OPTIONS';
export const SET_DASHBOARD_ITEM_OPTIONS = 'SET_DASHBOARD_ITEM_OPTIONS';
export const SET_DASHBOARD_OPTIONS = 'SET_DASHBOARD_OPTIONS';
export const DASHBOARD_COLUMN_OPTIONS_SAVED_SUCCESS =
  'DASHBOARD_COLUMN_OPTIONS_SAVED_SUCCESS';
export const DASHBOARD_ROW_OPTIONS_SAVED_SUCCESS =
  'DASHBOARD_ROW_OPTIONS_SAVED_SUCCESS';
export const DASHBOARD_ITEM_OPTIONS_SAVED_SUCCESS =
  'DASHBOARD_ITEM_OPTIONS_SAVED_SUCCESS';
export const DASHBOARD_OPTIONS_SAVED_SUCCESS =
  'DASHBOARD_OPTIONS_SAVED_SUCCESS';

export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAILED = 'LOAD_EVENTS_FAILED';
export const RESET_NEW_EVENTS = 'RESET_NEW_EVENTS';
export const CLEAN_NEW_EVENTS = 'CLEAN_NEW_EVENTS';

export const LAUNCH_NOW_EXECUTION_PLAN_SUCCESS =
  'LAUNCH_NOW_EXECUTION_PLAN_SUCCESS';
export const LAUNCH_SCHEDULE_EXECUTION_PLAN_SUCCESS =
  'LAUNCH_SCHEDULE_EXECUTION_PLAN_SUCCESS';

export const LOAD_SCHEDULED_LAUNCHES_LIST_SUCCESS =
  'LOAD_SCHEDULED_LAUNCHES_LIST_SUCCESS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const LOAD_ITEMS_SUCCESS = 'LOAD_ITEMS_SUCCESS';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';

export const SAVE_START_ROUTE = 'SAVE_START_ROUTE';
export const SET_INITIAL_START_ROUTE = 'SET_INITIAL_START_ROUTE';

export const UPDATE_ACTIVITY_SET_WORKFLOW_SUCCESS =
  'UPDATE_ACTIVITY_SET_WORKFLOW_SUCCESS';

export const SAVE_THRESHOLD_SUCCESS = 'SAVE_THRESHOLD_SUCCESS';
export const REMOVE_THRESHOLD_SUCCESS = 'REMOVE_THRESHOLD_SUCCESS';

export const GET_EXECUTION_PLAN_OWNER_PARTY_RELATIONSHIP_SUCCESS =
  'GET_EXECUTION_PLAN_OWNER_PARTY_RELATIONSHIP_SUCCESS';
export const REMOVE_SCHEDULED_EXECUTION_PLAN_SUCCESS =
  'REMOVE_SCHEDULED_EXECUTION_PLAN_SUCCESS';

export const EXECUTION_ADD_ATTACHMENTS = 'EXECUTION_ADD_ATTACHMENTS';
export const EXECUTION_ADD_EXISTING_ATTACHMENTS =
  'EXECUTION_ADD_EXISTING_ATTACHMENTS';
export const EXECUTION_DELETE_ATTACHMENT_SUCCESS =
  'EXECUTION_DELETE_ATTACHMENT_SUCCESS';
export const EXECUTION_UPDATE_VALUE = 'EXECUTION_UPDATE_VALUE';

export const LOAD_AVAILABLE_RISK_THRESHOLDS_SUCCESS =
  'LOAD_AVAILABLE_RISK_THRESHOLDS_SUCCESS';
export const UPDATE_ADMIN_THRESHOLD_SUCCESS = 'UPDATE_ADMIN_THRESHOLD_SUCCESS';
export const REMOVE_ADMIN_THRESHOLD_SUCCESS = 'REMOVE_ADMIN_THRESHOLD_SUCCESS';

export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const TICKET_ADD_EXISTING_ATTACHMENTS =
  'TICKET_ADD_EXISTING_ATTACHMENTS';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const LOAD_EXECUTION_MATRIX_ROLE_SUCCESS =
  'LOAD_EXECUTION_MATRIX_ROLE_SUCCESS';
export const ADD_VIEW_PERMISSION_SUCCESS = 'ADD_VIEW_PERMISSION_SUCCESS';
export const DELETE_VIEW_PERMISSION_SUCCESS = 'DELETE_VIEW_PERMISSION_SUCCESS';
export const ASSIGN_RELATIONSHIP_TO_ACTIVITY_SETS_EXECUTION_PLAN =
  'ASSIGN_RELATIONSHIP_TO_ACTIVITY_SETS_EXECUTION_PLAN';
export const REMOVE_RELATIONSHIP_FROM_ACTIVITY_SETS_EXECUTION_PLAN =
  'REMOVE_RELATIONSHIP_FROM_ACTIVITY_SETS_EXECUTION_PLAN';
export const EXECUTION_SET_FILTER = 'EXECUTION_SET_FILTER';
export const UPDATE_ATTACHMENT_SUCCESS = 'UPDATE_ATTACHMENT_SUCCESS';
export const LOAD_ATTACHMENTS_SUCCESS = 'LOAD_ATTACHMENTS_SUCCESS';
export const UPDATE_ATTACHMENT_FAILED = 'UPDATE_ATTACHMENT_FAILED';
export const SAVE_EXECUTION_DETAILS_SUCCESS = 'SAVE_EXECUTION_DETAILS_SUCCESS';
export const LOAD_ATTACHMENTS_FAILED = 'LOAD_ATTACHMENTS_FAILED';
export const SET_DEFAULT_VALUES_POPUP = 'SET_DEFAULT_VALUES_POPUP';

export const SET_LIST_FILTERS = 'SET_LIST_FILTERS';
export const SET_LIST_PAGINATION = 'SET_LIST_PAGINATION';

export const TICKET_ADD_DEADLINE_SUCCESS = 'TICKET_ADD_DEADLINE_SUCCESS';
export const TICKET_REMOVE_DEADLINE_SUCCESS = 'TICKET_REMOVE_DEADLINE_SUCCESS';
export const TICKET_ADD_DEADLINE = 'TICKET_ADD_DEADLINE';

export const SET_EXECUTION_TICKET_DEADLINE_COUNT =
  'SET_EXECUTION_TICKET_DEADLINE_COUNT';
export const EXECUTION_UPDATE_ENTRY = 'EXECUTION_UPDATE_ENTRY';
export const SAVING_EXECUTION_ENTRIES = 'SAVING_EXECUTION_ENTRIES';
export const SAVE_EXECUTION_ENTRIES_SUCCESS = 'SAVE_EXECUTION_ENTRIES_SUCCESS';
export const UPDATE_PROGRAMME_OPTIONS = 'UPDATE_PROGRAMME_OPTIONS';
export const ASSIGN_ACTIVITY_SET_TO_USER_EXECUTION_PLAN =
  'ASSIGN_ACTIVITY_SET_TO_USER_EXECUTION_PLAN';

export const ADDING_FAIR_VALUE = 'ADDING_FAIR_VALUE';
export const ADDING_FAIR_VALUE_SUCCESS = 'ADDING_FAIR_VALUE_SUCCESS';

export const FAIR_VALUE_LOAD_TICKETS_SUCCESS =
  'FAIR_VALUE_LOAD_TICKETS_SUCCESS';

export const LOAD_ENTITIES_SUCCESS = 'LOAD_ENTITIES_SUCCESS';
export const LOAD_ENTITY_SUCCESS = 'LOAD_ENTITY_SUCCESS';
export const SELECT_ENTITY = 'SELECT_ENTITY';
export const DESELECT_ENTITY = 'DESELECT_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const LOAD_ENTITY_TYPE_SUCCESS = 'LOAD_ENTITY_TYPE_SUCCESS';
export const LOAD_ENTITY_TYPES_SUCCESS = 'LOAD_ENTITY_TYPES_SUCCESS';

export const LOAD_STATUSES_SUCCESS = 'LOAD_STATUSES_SUCCESS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
