/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';
import * as _ from 'lodash';
import Dropzone from 'react-dropzone';
import { Segment } from 'semantic-ui-react';

import UploadProgressList from './../common/attachments/UploadProgressList';
import partyApi from '../../api/partyApi';

const UploadPartyIconContext = React.createContext();

function UploadPartyIcon(mapFileDropOptions, uploadSuccessCallback) {
  return (ComponentToWrap) => {
    class UploadPartyIcon extends Component {
      static getDerivedStateFromProps(nextProps) {
        if (mapFileDropOptions) {
          const options = mapFileDropOptions(nextProps);
          return { ...options };
        }
      }

      constructor() {
        super();
        this.fileDropRef = React.createRef();
        this.files = [];
        this.updateFilesStateThrottle = _.throttle(
          () => this.updateFilesState(),
          100
        );
      }

      state = {
        files: [],
        uploading: false,
        model: null,
        imageType: '',
      };

      mapFilesToState = (files) => {
        return files.map((file) => {
          return {
            progress: file.progress || 0,
            uploading: file.uploading || false,
            uploaded: file.uploaded || false,
            name: file.name,
          };
        });
      };

      updateFilesState = () => {
        this.setState({
          files: this.mapFilesToState(this.files),
        });
      };

      onDrop = (files) => {
        const { model, imageType } = this.state;
        if (model) {
          this.files = [...this.files, ...files];
          this.setState({
            files: this.mapFilesToState(this.files),
            uploading: true,
          });
          const promises = _.map(files, (file) => {
            file.uploading = true;
            return partyApi
              .uploadImage(
                model.id,
                file,
                (progressEvent) => {
                  let percentCompleted = Math.floor(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  file.progress = percentCompleted;

                  this.updateFilesStateThrottle();
                },
                imageType
              )
              .then((result) => {
                this.updateFilesStateThrottle();
                file.uploading = false;
                file.uploaded = true;
                return result;
              });
          });

          Promise.all(promises).then((values) => {
            if (uploadSuccessCallback) {
              uploadSuccessCallback(model, ...values, imageType);
            }

            this.updateFilesStateThrottle();

            const stillUploading = !!_.find(this.files, { uploading: true });

            if (!stillUploading) {
              _.delay(() => {
                this.files = [];
                this.updateFilesState();
                this.setState({
                  uploading: false,
                });
              }, 1000);
            }
          });
        }
      };

      openFileDialog = (imageType) => {
        this.setState({ imageType }, () => this.fileDropRef.current.open());
      };

      renderUploadPartyIconContextProvider = () => {
        return (
          <UploadPartyIconContext.Provider
            value={{
              openFileDialog: this.openFileDialog,
            }}
          >
            <ComponentToWrap
              openFileDialog={this.openFileDialog}
              {...this.props}
            />
          </UploadPartyIconContext.Provider>
        );
      };

      render() {
        const { files, uploading } = this.state;
        return (
          <Dropzone
            accept={'.png, .jpg, .jpeg'}
            disableClick
            style={{ position: 'relative' }}
            onDrop={this.onDrop}
            ref={this.fileDropRef}
            multiple={false}
          >
            {uploading && (
              <UploadProgressList
                files={files}
                visible={uploading}
                Wrapper={Segment}
                className="progress-list-party-page"
              />
            )}

            {this.renderUploadPartyIconContextProvider()}
          </Dropzone>
        );
      }
    }

    UploadPartyIcon.propTypes = {};

    return UploadPartyIcon;
  };
}

export default UploadPartyIcon;

export const WrapUploadPartyIconContext = (ComponentToWrap) =>
  class WrapContext extends React.Component {
    render() {
      return (
        <UploadPartyIconContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </UploadPartyIconContext.Consumer>
      );
    }
  };
