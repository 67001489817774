import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import ActivityForm from './ActivityForm';
import ShowHideButton from '../common/ShowHideButton';

ActivitiesListRowFields.propTypes = {
  activity: PropTypes.object.isRequired,
  activeMenuItem: PropTypes.string,
};

function ActivitiesListRowFields(props) {
  const { activity, activeMenuItem } = props;

  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Header as="h4">
        {activity.label} {activity.ref ? ` - ${activity.ref}` : ''}
        <ShowHideButton visible={visible} setVisible={setVisible} />
        {visible && (
          <ActivityForm
            activityId={activity.id}
            activeMenuItem={activeMenuItem}
            layout="WIDE"
          />
        )}
      </Header>
    </div>
  );
}

export default ActivitiesListRowFields;
