import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, Input, Select, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import StatusNodeTransitionList from './StatusNodeTransitionList';

const WorkflowStatusNodeListRow = (props) => {
  const {
    statusNode,
    statusNodes,
    statuses,
    onChange,
    onRemove,
    transitionActions,
  } = props;
  return (
    <Table.Row>
      <Table.Cell>
        <Input value={statusNode.label} name="label" onChange={onChange} />
      </Table.Cell>
      <Table.Cell>
        <Select
          name="statusUid"
          onChange={onChange}
          value={statusNode.statusUid}
          options={statuses.map((status) => ({
            key: status.uid,
            value: status.uid,
            text: status.name,
          }))}
        />
      </Table.Cell>
      <Table.Cell>
        <StatusNodeTransitionList
          statusNode={statusNode}
          transitions={statusNode.transitions}
          statusNodes={statusNodes}
          addTransition={(event) =>
            transitionActions.addTransition(event, statusNode)
          }
          updateTransition={(event, data, transition) =>
            transitionActions.updateTransition(
              event,
              data,
              statusNode,
              transition
            )
          }
          removeTransition={(event, transition) =>
            transitionActions.removeTransition(event, statusNode, transition)
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          label="Start"
          name="isStartNode"
          onChange={(event) => {
            if (!statusNode.isStartNode)
              onChange(event, { name: 'isStartNode', value: true });
          }}
          checked={statusNode.isStartNode}
        />
      </Table.Cell>
      <Table.Cell>
        <ActionButtons removeClicked={onRemove} />
      </Table.Cell>
    </Table.Row>
  );
};

WorkflowStatusNodeListRow.propTypes = {
  statusNode: PropTypes.object,
  statusNodes: PropTypes.array,
  statuses: PropTypes.array,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  transitionActions: PropTypes.object,
  isStartStatusNode: PropTypes.bool,
};

export default WorkflowStatusNodeListRow;
