import React from 'react';
import * as _ from 'lodash';
import DataTableWrapper from '../../components/DataTableComponent/DataTableWrapper';
import { Table } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const getEntries = (dataSet, list) => {
  return list.map((pt) => {
    const ticketId = pt.executionTicketListId;
    const dataRows = _.chain(dataSet)
      .filter({
        executionTicketListId: ticketId,
      })
      .keyBy('workflowEntriesFieldFieldKey')
      .value();
    return dataRows;
  });
};

const renderTable = (data) => {
  if (!data) return null;
  return (
    <DataTableWrapper
      className="pro-report-recc-table"
      items={[
        {
          columnNames: [
            'No.',
            'Section / Area',
            'Coverholder / UMR Specific',
            'Finding',
            'Recommendation',
            'Coverholder’s Response',
          ],
        },
      ]}
    >
      {data.map((row, index) => (
        <Table.Row key={index} verticalAlign="top">
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>
            {row.SEVR.activityRef && (
              <React.Fragment>
                {row.SEVR.activityRef}
                <br />
              </React.Fragment>
            )}
            {row.SEVR.executionTicketListLabel}
          </Table.Cell>
          <Table.Cell />
          <Table.Cell>
            <ReactMarkdown source={row.FIND.workflowEntriesValue} />
          </Table.Cell>
          <Table.Cell>
            <ReactMarkdown source={row.RECC.workflowEntriesValue} />
          </Table.Cell>
          <Table.Cell>
            <ReactMarkdown source={row.OBVS.workflowEntriesValue} />
          </Table.Cell>
        </Table.Row>
      ))}
    </DataTableWrapper>
  );
};

const ProRecommendationTable = (props) => {
  return null;

  const data = _.get(props, 'data.data.main');
  if (!data) return null;

  // cons;

  const highPriority = _.filter(data, {
    workflowEntriesFieldFieldKey: 'SEVR',
    workflowEntriesValue: 'High',
  });
  const highPriorityData = getEntries(data, highPriority);

  const mediumPriority = _.filter(data, {
    workflowEntriesFieldFieldKey: 'SEVR',
    workflowEntriesValue: 'Medium',
  });
  const mediumPriorityData = getEntries(data, mediumPriority);

  const lowPriority = _.filter(data, {
    workflowEntriesFieldFieldKey: 'SEVR',
    workflowEntriesValue: 'Low',
  });
  const lowPriorityData = getEntries(data, lowPriority);

  return (
    <div className="pro-report-reccs">
      <h2>High Priority Recommendations</h2>
      <h4>
        Recommendations which are considered critical to the business including
        but not limited to breaches of the binding authority agreement or of
        local legislation / regulation.
      </h4>

      {highPriorityData.length > 0 ? (
        renderTable(highPriorityData)
      ) : (
        <p>There are no High Priority Recommendations.</p>
      )}

      <h2>Medium Priority Recommendations</h2>
      <h4>Recommendations which are in line with best practice.</h4>
      {mediumPriorityData.length > 0 ? (
        renderTable(mediumPriorityData)
      ) : (
        <p>There are no Medium Priority Recommendations.</p>
      )}
      <h2>Low Priority Recommendations</h2>
      <h4>
        Recommendations which could improve the Coverholder’s efficiency and
        risk management without being material (i.e., housekeeping type,
        operational / administrative issues).
      </h4>

      {lowPriorityData.length > 0 ? (
        renderTable(lowPriorityData)
      ) : (
        <p>There are no Low Priority Recommendations.</p>
      )}
    </div>
  );
};

export default ProRecommendationTable;
