import { useState } from 'react';
import { ModelSearch } from './common/search/ModelSearch';
import { Container } from 'semantic-ui-react';

const TestPage = () => {
  const [partyId, setPartyId] = useState();
  const [metricId, setMetricId] = useState();

  return (
    <Container>
      <ModelSearch
        modelName="Party"
        label="Party"
        value={partyId}
        onChange={(event, data) => {
          console.log('onChange', { event, data });
          setPartyId(data.value);
        }}
      />
      <ModelSearch
        modelName="Status"
        label="Metric"
        value={metricId}
        valueProperty="ref"
        onChange={(event, data) => {
          console.log('onChange', { event, data });
          setMetricId(data.value);
        }}
      />
    </Container>
  );
};

export default TestPage;
