import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';

import UserSearch from '../common/search/UserSearch';
import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';

const ExecutionPlanAssignQuestionSet = ({
  executionPlan,
  activitySet,
  assignToUser,
}) => {
  const [assignedPartyUserId, setAssignedPartyUserId] = useState(null);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const { assignedPrincipalId } = activitySet;
    setAssignedPartyUserId(assignedPrincipalId);
  }, []);

  const { assignedParty, ownerParty, id: executionPlanId } = executionPlan;

  const onChange = (event, { value }) => {
    setIsPending(true);
    const { principalId } = value;
    const { id: activitySetId } = activitySet;

    setAssignedPartyUserId(principalId);
    assignToUser(executionPlanId, activitySetId, principalId).then(() => {
      setIsPending(false);
      toastr.success('Question Set is assigned');
    });
  };

  return (
    <Form>
      <Form.Field inline>
        <label>Auto assign to user/usergroup</label>
        <UserSearch
          onChange={onChange}
          principalId={assignedPartyUserId}
          disabled={!assignedParty}
          filterByParty={
            assignedParty ? [assignedParty.id, ownerParty.id] : null
          }
          name="assignedPartyUser"
          returnUserObject={true}
          sortItemsAlphabetically={true}
          saving={isPending}
        />
      </Form.Field>
    </Form>
  );
};

ExecutionPlanAssignQuestionSet.propTypes = {
  executionPlan: PropTypes.object,
  activitySet: PropTypes.object,
  assignToUser: PropTypes.func,
};

export default ExecutionPlanWrapContext(ExecutionPlanAssignQuestionSet);
