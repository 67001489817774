import { useState, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Grid } from 'semantic-ui-react';
import * as _ from 'lodash';

import ActionButtons from '../common/ActionButtons';
import DebugComponent from '../common/dashboard/components/DebugComponent';

import WidgetComponents from '../common/dashboard/DashboardWidgetComponents';

let DashboardWidget;

if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  DashboardWidget = require('../common/dashboard/DashboardWidget').default;
} else {
  DashboardWidget =
    require('../common/dashboard/DashboardWidgetContainer').default;
}

const serverRender =
  typeof process !== 'undefined' && !!process.env.SERVER_REND;

import './Dashboard.scss';
import { options } from 'toastr';
import { cp } from 'fs';

function Dashboard({
  data,
  editable = false,
  onAddItem,
  onPasteItem,
  onRemoveItem,
  onSelectEditItem,
  onClearSelectedItems,
  onUpdateItem,
  openEditMultipleItemsModel,
  dashboardCallback,
  dashboardAction,
  dashboardWhereFilters,
  dashboardProperties,
  forceChartRefresh,
  onSetDashboadData,
  extraData,
  history,
  selectedItems,
}) {
  const currentTab = dashboardProperties
    ? dashboardProperties.currentTab
    : null;

  const [copiedComponent, setCopiedComponent] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);

  const metricReportId = data?.options?.metricReport;
  const metricReportDimension = data?.options?.metricReportDimension;

  const apiCalls = metricReportId
    ? [
        {
          name: 'main',
          apiCall: {
            arguments: {
              model: 'StatusReport',
              endpoint: `${metricReportId}/run`,
              params: {
                dimension: metricReportDimension,
              },
            },
          },
        },
      ]
    : data?.options?.apiCalls;

  return (
    <Fragment>
      {editable && (
        <ActionButtons
          hideMode={false}
          vertical={false}
          style={{ right: -20 }}
          saveClicked={
            data.unsaved
              ? () => {
                  dashboardAction({
                    actionType: 'SAVE_DASHBOARD_OPTIONS',
                    data: data.options,
                  });
                }
              : null
          }
          configureClicked={
            selectedItems.length > 0
              ? () => {
                  openEditMultipleItemsModel();
                }
              : null
          }
          deleteClicked={
            selectedItems.length > 0
              ? () => {
                  onClearSelectedItems();
                }
              : null
          }
          editClicked={() => {
            dashboardAction({
              actionType: 'OPEN_ITEM_OPTIONS',
              data: data.options,
              type: 'dashboard',
              args: {
                type: 'dashboard',
              },
            });
          }}
        />
      )}
      {data.rows && (
        <Grid
          columns="equal"
          celled={editable}
          className={`vidende-dashboard ${editable ? '--editable' : ''}`}
          onPaste={(e) => {
            if (!activeComponent) return;
            e.preventDefault();
            let text = e.clipboardData.getData('text/plain');
            //remove tabs
            // text = text.replace(/\t/g, '');
            text = text.replace(/\r/g, '');

            const textArray = text.split('\n');
            console.log(textArray);
            const textArrayTabs = textArray.map((t) => t.split('\t'));
            console.log(textArrayTabs);

            // if (textArray.length === 1) {
            //   onUpdateItem({
            //     item: activeComponent.item,
            //     arg: 'header',
            //     value: text,
            //     rowId: activeComponent.rowId,
            //     columnId: activeComponent.columnId,
            //     save:true
            //   });
            // }
            if (textArray.length > 0) {
              // find active row
              const activeRow = data.rows[activeComponent.rowId];
              const activeColumn = activeRow.columns[activeComponent.columnId];
              console.log(activeColumn);
              const items = _.orderBy(activeColumn.items, 'options.sort');
              const activeItemIndex = _.findIndex(
                items,
                (item) => item.id === activeComponent.item.id
              );
              console.log(activeItemIndex);
              let i = 0;
              for (const text of textArray) {
                if (items[activeItemIndex + i]) {
                  onUpdateItem({
                    item: items[activeItemIndex + i],
                    arg: 'header',
                    value: text.replace(/\t/g, ''),
                    rowId: activeComponent.rowId,
                    columnId: activeComponent.columnId,
                    save: true,
                  });
                }
                i++;
              }
            }
          }}
        >
          {_.chain(data.rows)
            .orderBy('options.sort')
            .map((row) => {
              let style = {};
              if (row.options.extraStyles)
                style = { ...row.options.extraStyles };
              if (row.options.height) style.height = row.options.height;
              if (row.options.showOnTab) {
                const tabs = row.options.showOnTab.split(',');
                if (!tabs.includes(currentTab) && currentTab !== 'ALL')
                  return null;
              }
              if (row.options.showIfPropertySet && !editable) {
                if (!dashboardProperties[row.options.showIfPropertySet])
                  return null;
              }
              const className = `dashboard-row ${
                row.options.hideForPrint ? 'hide-for-print' : ''
              } ${row.options.gridMode === 'true' ? 'grid-mode' : ''}`;

              if (row.options.gridMode === 'true') {
                if (row.options.gridModeColumns)
                  style.gridTemplateColumns = row.options.gridModeColumns;
                if (row.options.gridModeRows)
                  style.gridTemplateRows = row.options.gridModeRows;
              }

              if (serverRender && row.options.hideForPrint) return null;
              return (
                <Fragment key={row.id}>
                  {row.options.pageBreakBefore === 'true' && (
                    <div className="page-break">&nbsp;</div>
                  )}

                  <Grid.Row stretched className={className} style={style}>
                    {_.chain(row.columns)
                      .orderBy('options.sort')
                      .map((column) => {
                        let style = {};
                        if (column.options.gridArea) {
                          style.gridArea = column.options.gridArea;
                        }
                        return (
                          <Grid.Column
                            key={column.id}
                            verticalAlign={column.options.verticalAlign}
                            width={column.options.width || undefined}
                            style={style}
                          >
                            <Fragment>
                              {' '}
                              {_.chain(column.items)
                                .orderBy('options.sort')
                                .map((item) => {
                                  const style = {};
                                  if (item.options.disableGrow)
                                    style.flexGrow = 'initial';
                                  if (item.options.width) {
                                    const width =
                                      (item.options.width / 16) * 100;
                                    style.width = `calc(${width}% - 1em)`;
                                    style.flexGrow = 'initial';
                                  }
                                  if (activeComponent?.item.id === item.id) {
                                    style.backgroundColor = '#ff980045';
                                  }

                                  const componentType = item.chart
                                    ? item.chart?.options.type
                                    : item.options?.component;

                                  const Component =
                                    WidgetComponents[componentType];

                                  if (!Component) {
                                    return (
                                      <div> {componentType} not found </div>
                                    );
                                  }

                                  const extraFields = Component.fields;

                                  let editActions = {
                                    deleteClicked: () => {
                                      onRemoveItem({
                                        itemType: 'ITEM',
                                        columnId: column.id,
                                        rowId: row.id,
                                        itemId: item.id,
                                      });
                                    },
                                    configureClicked: () => {
                                      dashboardAction({
                                        actionType: 'OPEN_ITEM_OPTIONS',
                                        options: row.id,
                                        type: 'item',
                                        data: item.options,
                                        extraFields,
                                        args: {
                                          columnId: column.id,
                                          rowId: row.id,
                                          type: 'item',
                                          itemId: item.id,
                                        },
                                      });
                                    },
                                    copyClicked: () => {
                                      console.log(item);
                                      setCopiedComponent(item);
                                    },
                                    downloadClicked: () => {
                                      // console.log(item);
                                      // setCopiedComponent(item);
                                      const args = {
                                        columnId: column.id,
                                        rowId: row.id,
                                        type: 'item',
                                        itemId: item.id,
                                      };
                                      onSelectEditItem({
                                        item,
                                        extraFields,
                                        args,
                                      });
                                    },
                                    editClicked: () => {
                                      history.push(
                                        `/widgetBuilder/${item.chartId}`
                                      );
                                    },
                                    saveClicked: item.unsaved
                                      ? () => {
                                          dashboardAction({
                                            actionType: 'SAVE_ITEM_OPTIONS',
                                            columnId: column.id,
                                            rowId: row.id,
                                            itemId: item.id,
                                            data: item.options,
                                          });
                                        }
                                      : null,
                                  };

                                  return (
                                    //flex-grow: initial;
                                    <DashboardWidget
                                      key={item.id}
                                      id={item?.id}
                                      apiCalls={
                                        item.chart?.apiCalls || apiCalls
                                      }
                                      dataMapping={
                                        apiCalls
                                          ? {
                                              data: {
                                                mappers: [],
                                                args: {},
                                              },
                                            }
                                          : null
                                      }
                                      {...item.chart?.options}
                                      config={{
                                        allowedProperties: apiCalls
                                          ? [
                                              {
                                                name: 'applicableDate',
                                                argument: 'applicableDate',
                                              },
                                              {
                                                name: 'PartyIds',
                                                argument: 'assignedPartyId',
                                              },
                                              {
                                                name: 'EntityIds',
                                                argument: 'assignedEntityId',
                                              },
                                              {
                                                name: 'dimension',
                                                argument: 'dimension',
                                              },
                                            ]
                                          : null,
                                        ...item.chart?.options.config,
                                        ...item.options,
                                        hideHeader: item.options.hideHeader
                                          ? true
                                          : item.chart?.options.config
                                              .hideHeader,
                                        header: item.options.header,
                                      }}
                                      chartData={item.chart?.data}
                                      dashboardCallback={dashboardCallback}
                                      onSetDashboadData={onSetDashboadData}
                                      dashboardWhereFilters={
                                        dashboardWhereFilters
                                      }
                                      dashboardProperties={dashboardProperties}
                                      editable={editable}
                                      forceChartRefresh={forceChartRefresh}
                                      style={style}
                                      editActions={editActions}
                                      extraData={extraData}
                                      type={componentType}
                                      onMouseOver={
                                        editable
                                          ? () => {
                                              setActiveComponent({
                                                item,
                                                rowId: row.id,
                                                columnId: column.id,
                                              });
                                            }
                                          : null
                                      }
                                    />
                                  );
                                })
                                .value()}
                            </Fragment>
                            <Fragment>
                              {editable && (
                                <ActionButtons
                                  className="dashboard-action-buttons"
                                  hideMode={true}
                                  addClicked={() =>
                                    onAddItem({
                                      itemType: 'ITEM',
                                      columnId: column.id,
                                      rowId: row.id,
                                    })
                                  }
                                  deleteClicked={() => {
                                    onRemoveItem({
                                      itemType: 'COLUMN',
                                      columnId: column.id,
                                      rowId: row.id,
                                    });
                                  }}
                                  leftClicked={() => {
                                    dashboardAction({
                                      actionType: 'SET_COLUMN_OPTION',
                                      columnId: column.id,
                                      rowId: row.id,
                                      data: {
                                        name: 'sort',
                                        value: _.isNumber(column.options.sort)
                                          ? column.options.sort - 1
                                          : 0,
                                      },
                                    });
                                  }}
                                  rightClicked={() => {
                                    dashboardAction({
                                      actionType: 'SET_COLUMN_OPTION',
                                      columnId: column.id,
                                      rowId: row.id,
                                      data: {
                                        name: 'sort',
                                        value: _.isNumber(column.options.sort)
                                          ? column.options.sort + 1
                                          : 0,
                                      },
                                    });
                                  }}
                                  upClicked={() => {
                                    dashboardAction({
                                      actionType: 'SET_COLUMN_OPTION',
                                      columnId: column.id,
                                      rowId: row.id,
                                      data: {
                                        name: 'width',
                                        value: _.isNumber(column.options.width)
                                          ? column.options.width + 1
                                          : 3,
                                      },
                                    });
                                  }}
                                  downClicked={() => {
                                    dashboardAction({
                                      actionType: 'SET_COLUMN_OPTION',
                                      columnId: column.id,
                                      rowId: row.id,
                                      data: {
                                        name: 'width',
                                        value: _.isNumber(column.options.width)
                                          ? column.options.width - 1
                                          : 3,
                                      },
                                    });
                                  }}
                                  removeClicked={() => {
                                    dashboardAction({
                                      actionType: 'SET_COLUMN_OPTION',
                                      columnId: column.id,
                                      rowId: row.id,
                                      data: {
                                        name: 'width',
                                        value: null,
                                      },
                                    });
                                  }}
                                  saveClicked={
                                    column.unsaved
                                      ? () => {
                                          dashboardAction({
                                            actionType: 'SAVE_COLUMN_OPTIONS',
                                            columnId: column.id,
                                            rowId: row.id,
                                            data: column.options,
                                          });
                                        }
                                      : null
                                  }
                                  pasteClicked={
                                    copiedComponent
                                      ? () => {
                                          onPasteItem({
                                            item: {
                                              ...copiedComponent,
                                              options: {
                                                ...copiedComponent.options,
                                                sort:
                                                  Object.keys(column.items)
                                                    .length + 1,
                                              },
                                            },

                                            columnId: column.id,
                                            rowId: row.id,
                                          });
                                        }
                                      : null
                                  }
                                  editClicked={() => {
                                    dashboardAction({
                                      actionType: 'OPEN_ITEM_OPTIONS',
                                      itemId: column.id,
                                      options: row.id,
                                      type: 'column',
                                      data: column.options,
                                      args: {
                                        columnId: column.id,
                                        rowId: row.id,
                                        type: 'column',
                                      },
                                    });
                                  }}
                                />
                              )}
                            </Fragment>
                          </Grid.Column>
                        );
                      })
                      .value()}
                    {editable && (
                      <ActionButtons
                        className="dashboard-action-buttons"
                        hideMode={true}
                        vertical={true}
                        style={{ right: -20 }}
                        addClicked={() => {
                          const sort = Object.keys(row.columns).length + 1;
                          onAddItem({
                            itemType: 'COLUMN',
                            rowId: row.id,
                            sort,
                          });
                        }}
                        deleteClicked={() => {
                          onRemoveItem({
                            itemType: 'ROW',
                            rowId: row.id,
                          });
                        }}
                        saveClicked={
                          row.unsaved
                            ? () => {
                                dashboardAction({
                                  actionType: 'SAVE_ROW_OPTIONS',
                                  rowId: row.id,
                                  data: row.options,
                                });
                              }
                            : null
                        }
                        editClicked={() => {
                          dashboardAction({
                            actionType: 'OPEN_ITEM_OPTIONS',
                            options: row.id,
                            type: 'row',
                            data: row.options,
                            args: {
                              rowId: row.id,
                              type: 'row',
                            },
                          });
                        }}
                      />
                    )}
                  </Grid.Row>
                </Fragment>
              );
            })
            .value()}
          {editable && (
            <Grid.Row>
              <Grid.Column>
                <Button
                  onClick={() =>
                    onAddItem({
                      itemType: 'ROW',
                      sort: Object.keys(data.rows || {}).length,
                    })
                  }
                >
                  Add Row
                </Button>
                <DebugComponent
                  dashboardProperties={dashboardProperties}
                  extraData={extraData}
                />
                <DebugComponent data={data} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </Fragment>
  );
}

Dashboard.propTypes = {
  data: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  forceChartRefresh: PropTypes.bool,
  onAddItem: PropTypes.func,
  onPasteItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onSelectEditItem: PropTypes.func,
  onUpdateItem: PropTypes.func,
  dashboardCallback: PropTypes.func,
  dashboardAction: PropTypes.func,
  onSetDashboadData: PropTypes.func,
  dashboardWhereFilters: PropTypes.object,
  dashboardProperties: PropTypes.object,
  history: PropTypes.object,
  extraData: PropTypes.array,
  enableQueryString: PropTypes.bool,
  setFilters: PropTypes.func,
  stateFilters: PropTypes.object,
};

export default Dashboard;
