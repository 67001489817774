import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import TicketStatus from './TicketStatus';
import PrincipalLabel from '../common/PrincipalLabel';

const TicketLinksTableRow = ({ relationship }) => {
  const {
    fromTicket,
    toTicket,
    ticketRelationshipType,
    fromTicketId,
    toTicketId,
    id,
  } = relationship;
  const ticket = fromTicket || toTicket;
  const {
    label,
    statusNodeLabel,
    statusNodeColor,
    assignedPrincipalLabel,
    assignedPrincipalType,
  } = ticket;
  let principal;
  if (assignedPrincipalLabel) {
    principal = {
      label: assignedPrincipalLabel,
      type: assignedPrincipalType,
    };
  }
  const relationshipTicketId = fromTicket ? fromTicketId : toTicketId;
  return (
    <Table.Row key={id}>
      <Table.Cell width={6}>
        <Link to={`/ticket/${relationshipTicketId}`}> {label} </Link>
      </Table.Cell>
      <Table.Cell width={3}>{ticketRelationshipType.label}</Table.Cell>
      <Table.Cell width={3}>
        <TicketStatus name={statusNodeLabel} color={statusNodeColor} />
      </Table.Cell>
      <Table.Cell width={4}>
        <PrincipalLabel principal={principal} />
      </Table.Cell>
    </Table.Row>
  );
};

TicketLinksTableRow.propTypes = {
  relationship: PropTypes.object,
};

export default TicketLinksTableRow;
