import React, { Component, useEffect, useState } from 'react';
import statusApi from '../../api/statusApi';
import { Container, Dimmer, Form, List, Loader } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StatusReport } from '../../types';
import toastr from 'toastr';
import { useAppSelector } from '../../actions/store';
import ConfirmWrapper from '../common/ConfirmWrapper';

interface Props extends RouteComponentProps<{ id: string }> {}

const StatusPage = (props: Props) => {
  const [statusReports, setStatusReports] = useState([]);
  const [newStatusReportLabel, setNewStatusReportLabel] = useState('');

  const statuses = useAppSelector((state) =>
    state.status.statuses.filter((status) => !status.deleted)
  );

  const currentUser = useAppSelector((state) => state.currentUser);

  const loadStatusReports = async () => {
    const result = await statusApi.getStatusReports();
    setStatusReports(result);
  };

  useEffect(() => {
    loadStatusReports();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newStatusReport: StatusReport = {
      label: newStatusReportLabel,
      creationStatus: 'EDITING',
      structuralNodeId: currentUser.details.party.structuralNodeId,
    };

    const result = await statusApi.createStatusReport(newStatusReport);

    toastr.success('New Metric Report created');
    props.history.replace(`/metricReports/${result.id}`);
  };
  return (
    <Container>
      <Dimmer active={!statuses} inverted>
        <Loader />
      </Dimmer>

      <List>
        {statusReports &&
          statusReports.map((statusReport) => (
            <List.Item key={statusReport.id}>
              <Link to={`/metricReports/${statusReport.id}`}>
                {statusReport.label}
              </Link>
            </List.Item>
          ))}
      </List>

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Input
            placeholder="Label"
            name="label"
            value={newStatusReportLabel}
            onChange={(e, { value }) => {
              setNewStatusReportLabel(value);
            }}
          />
          <Form.Button content="Create new Metric Report" />
        </Form.Group>
      </Form>
    </Container>
  );
};
// }

// StatusPage.propTypes = {};

export default ConfirmWrapper(StatusPage);
