import apiTools from './apiTools';

export type QueueStatus = {
  totalItems: number;
  completeItems: number;
  failedItems: number;
  processingItems: number;
  unprocessedItems: number;
};

export type QueueActionType =
  | 'SEND_EMAIL'
  | 'CREATE_ENTITY'
  | 'CREATE_PARTY'
  | 'LAUNCH_EXECUTION';

export interface QueueItem {
  id: string;
  queueId: string;
  actionType: QueueActionType;
  processingStartedAt: null;
  processingErrors: ProcessingError[];
  payload: any;
  failed: boolean;
  complete: boolean;
  retries: number;
  createdAt: Date;
  creatorId: string;
}

export interface ProcessingError {
  message: string;
  time: Date;
}

class QueueApi {
  static getQueueStatus(queueId): QueueStatus {
    return apiTools
      .get(`Queues/${queueId}/queueStatus`)
      .then((response: { data: QueueStatus }) => {
        return response.data;
      });
  }

  static getQueueItems(filter: any) {
    return apiTools.get(`Queues`, { filter }).then((response) => {
      return response.data;
    });
  }
}

export default QueueApi;
