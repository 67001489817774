import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Loader,
  Modal,
} from 'semantic-ui-react';
import * as _ from 'lodash';

import * as uiActions from '../../../actions/uiActions';
import * as userActions from '../../../actions/userActions';
import { loadParty } from '../../../actions/partyActions';
import { useAppSelector } from '../../../actions/store';

import { mapToOptions } from '../../../utils/React';
import { checkValidation, FieldErrors } from '../../../utils/Validation';
import validationSchema from '../../../utils/ValidationSchema';
import { loadEntityTypes } from '../../../actions/entityActions';
import { EntityType } from '../../../types';
import SaveEntityModalForm, { EntityFormData } from './SaveEntityModalForm';
import { findIndex } from 'lodash';
import entityApi from '../../../api/entityApi';

export const partyRelationshipsFilter = {
  include: [
    {
      relation: 'partyRelationships',

      scope: {
        where: { partyRelationshipTypeId: 'DIST' },
        order: 'label ASC',
        include: [
          'relationshipType',
          {
            relation: 'toParty',
          },
        ],
      },
    },
  ],
};

type Props = {
  partyId: string;
  entityTypeId?: string;
  onEntityCreated?: () => void;
};
const CreateEntityModal = ({
  partyId,
  entityTypeId,
  onEntityCreated,
}: Props) => {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string[]>();
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();

  const entityTypes = useAppSelector((state) => state.entity.entityTypes.array);
  const [entityType, setEntityType] = useState<EntityType>();

  const party = useAppSelector((state) => state.party);
  const partyGroupNode = party?.permissionsNode?.children?.[0];

  const executionPlans = useAppSelector((state) => state.executionPlans);

  const [formData, setFormData] = useState<EntityFormData>({
    label: '',
    executionPlanId: null,
    launchExecution: true,
    entries: [],
    dueDate: null,
    coManufactured: false,
    distributorId: null,
  });

  useEffect(() => {
    dispatch(loadParty(partyId, partyRelationshipsFilter));
  }, []);

  const handlefieldChange = ({ name, value, checked }) => {
    console.log({ name, value });
    const index = findIndex(formData.entries, {
      entityTypeFieldId: name,
    });
    if (index !== -1) {
      formData.entries[index].value = value;
      setFormData({ ...formData, entries: formData.entries });
    } else {
      const newValue = {
        entityTypeFieldId: name,
        value: value,
      };
      setFormData({ ...formData, entries: [...formData.entries, newValue] });
    }
  };
  const handleInputChanged = (event, { name, value, checked }) => {
    console.log({ name, value, checked });

    if (checked === true || checked === false) {
      setFormData({
        ...formData,
        [name]: checked,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(loadEntityTypes());
  }, []);

  useEffect(() => {
    if (
      formData.launchExecution &&
      !formData.executionPlanId &&
      executionPlans.length > 0
    ) {
      setFormData({
        ...formData,
        executionPlanId: executionPlans[0].id,
      });
    }
  }, [formData.launchExecution, executionPlans]);

  useEffect(() => {
    if (entityTypeId && entityTypes.length > 0 && !entityType) {
      const entityType = _.find(entityTypes, { id: entityTypeId });
      setEntityType(entityType);
    }
    //entityTypeId ? entityTypes.find((et) => et.id === entityTypeId) : null
  }, [entityTypes, entityTypeId]);

  const handleCloseModal = () => {
    dispatch(uiActions.closeModal());
  };

  const handleCreateEntity = async () => {
    setFieldErrors(null);

    const validation = checkValidation(formData, validationSchema.entity);

    if (!validation.valid) {
      setFieldErrors(validation.errors);
      return;
    }
    try {
      setSaving(true);
      const entityEntries = [...formData.entries];
      if (formData.distributorId) {
        entityEntries.push({
          entityTypeFieldId: 'PRODUCT_COMAN',
          value: formData.distributorId,
        });
        entityEntries.push({
          entityTypeFieldId: 'PRODUCT_ISCOMAN',
          value: true.toString(),
        });
      }

      await entityApi.createEntity(partyId, {
        entity: {
          label: formData.label,
          entityTypeId: entityType.id,
        },
        entityEntries,
        tasks:
          formData.launchExecution && formData.executionPlanId
            ? [
                {
                  executionPlanId: formData.executionPlanId,
                  type: 'LAUNCH_EXECUTION',
                  partyId,
                  granularPermissionTypeId: formData.distributorId
                    ? 'TICKETSET'
                    : undefined,
                  dueDate: formData.dueDate,
                },
              ]
            : [],
      });
      dispatch(userActions.renewToken());
      toastr.success('Entity Saved');
      setSaving(false);
      if (onEntityCreated) {
        onEntityCreated();
      }
      handleCloseModal();
    } catch (error) {
      toastr.error(error);
      setSaving(false);
    }
  };

  const handleChooseEntityType = (event, { value }) => {
    const entityType = _.find(entityTypes, { id: value });
    console.log({ entityType });
    setEntityType(entityType);
  };

  const [isMirissaGoingToSuperCamp, setIsMirissaGoingToSuperCamp] =
    useState<boolean>();

  return (
    <Modal
      open={true}
      onClose={() => uiActions.closeModal()}
      size="small"
      closeOnDimmerClick={false}
    >
      <Dimmer active={saving} inverted>
        <Loader disabled={!saving} />
      </Dimmer>
      <Modal.Header>
        {entityType ? `Add New ${entityType.label}` : 'Choose Entity Type'}
      </Modal.Header>
      <Modal.Content>
        <Form.Field>
          {!entityType && (
            <>
              <label>Entity Type</label>
              <Dropdown
                placeholder="Entity Type"
                fluid
                selection
                name="entityTypeId"
                onChange={handleChooseEntityType}
                options={entityTypes ? mapToOptions(entityTypes) : []}
                value={entityType ? entityType.id : null}
              />
            </>
          )}
          {entityType && (
            <SaveEntityModalForm
              key={entityType.id}
              formData={formData}
              handleInputChanged={handleInputChanged}
              hideLaunchExecutionPlan={false}
              hideDistributor={false}
              onFieldChange={handlefieldChange}
              type={entityType.id}
              errors={fieldErrors}
            />
          )}
        </Form.Field>
      </Modal.Content>

      <Modal.Actions>
        <Button
          positive
          onClick={handleCreateEntity}
          loading={saving}
          disabled={saving}
        >
          Save
        </Button>
        <Button
          secondary
          onClick={handleCloseModal}
          loading={saving}
          disabled={saving}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateEntityModal;
