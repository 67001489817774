import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import * as _ from 'lodash';

import * as uiActions from '../../actions/uiActions';
import SaveUserForm from './SaveUserForm';

class SaveUserModal extends Component {
  state = {
    partyId: null,
    firstName: '',
    lastName: '',
    email: '',
    saving: false,
    isAdmin: false,
  };

  componentWillMount() {
    const { user } = this.props;
    if (user) {
      const { userGroups } = this.props.party;
      const adminGroupIndex = _.findIndex(userGroups, (usergroup) =>
        usergroup.name.includes('Admins')
      );
      const isAdmin =
        adminGroupIndex != -1
          ? _.some(userGroups[adminGroupIndex].users, { id: user.id })
          : false;
      const { partyId, firstName, lastName, email } = user;
      this.setState({ partyId, firstName, lastName, email, isAdmin });
    } else {
      const { id } = this.props.party;
      this.setState({ partyId: id });
    }
  }

  handleInputChanged = (event, { name, value, checked }) => {
    this.setState({ [name]: value || checked });
  };

  createUser = () => {
    const { firstName, lastName, email, partyId, isAdmin } = this.state;
    const { userStateActions, user } = this.props;
    this.setState({ saving: true });
    userStateActions
      .saveUser(
        firstName,
        lastName,
        email,
        partyId,
        isAdmin,
        user ? user.id : null
      )
      .then(this.saveUserSuccess)
      .catch((error) => this.saveUserError(error));
  };

  saveUserSuccess = () => {
    const { uiActions, user } = this.props;
    toastr.success(
      user
        ? `${user.firstName} ${user.lastName} is updated`
        : 'New user is added'
    );
    uiActions.closeModal();
  };

  saveUserError = (error) => {
    toastr.error(error);
    this.setState({ saving: false });
    throw error;
  };

  render() {
    const { saving, partyId, firstName, lastName, email, isAdmin } = this.state;
    const { uiActions, user } = this.props;
    const stateUser = {
      partyId,
      firstName,
      lastName,
      email,
      isAdmin,
    };
    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>
          {user
            ? `Update ${user.firstName} ${user.lastName} user`
            : 'Create new user'}
        </Modal.Header>
        <Modal.Content>
          <SaveUserForm
            user={stateUser}
            handleInputChanged={this.handleInputChanged}
            isNewUser={!user}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button positive onClick={this.createUser}>
            {user ? 'Update' : 'Save'}
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SaveUserModal.propTypes = {
  party: PropTypes.object.isRequired,
  userStateActions: PropTypes.object,
  partyStateActions: PropTypes.object,
  uiActions: PropTypes.object,
  user: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SaveUserModal);
