import React from 'react';
import { Table } from 'semantic-ui-react';
import * as PropTypes from 'prop-types';
import { renderValue } from './helpers';

const DataTableRow = (props) => {
  const { data } = props;
  return (
    <Table.Row verticalAlign="top" valign="top">
      {data.map((col, index) => renderValue(col, index))}
    </Table.Row>
  );
};

DataTableRow.propTypes = {
  data: PropTypes.array,
};

export default DataTableRow;
