import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import PartyWrapper from './Party';
import FilterAndPaginate from '../common/FilterAndPaginate';
import UserGroupsList from './UserGroupsList';
import Pagination from '../common/Pagination';

class PartyRelatationshipList extends Component {
  static propTypes = {
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    partyStateActions: PropTypes.object,
  };

  render() {
    const {
      paginatedItems,
      handleSortChange,
      handlePaginationChange,
      paginationOptions,
      partyStateActions,
    } = this.props;

    return (
      <React.Fragment>
        <Header as="h2">User Groups</Header>
        {paginatedItems.length ? (
          <React.Fragment>
            <UserGroupsList
              relationships={paginatedItems || []}
              paginationOptions={paginationOptions}
              handleSortChange={handleSortChange}
              partyStateActions={partyStateActions}
            />
            <Pagination
              activePage={paginationOptions.activePage}
              onPageChange={handlePaginationChange}
              totalPages={paginationOptions.totalPages}
              style={{ float: 'right' }}
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="column">
              <Header as="h4">There are no usergroups in this party</Header>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapOptions(props) {
  return {
    items: props.party.userGroups,
  };
}

export default PartyWrapper(
  FilterAndPaginate(mapOptions)(PartyRelatationshipList)
);
