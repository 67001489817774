import Text from './Text';
import Currency from './Currency';
import Select from './Select';
import Date from './Date';
import Number from './Number';
import TextArea from './TextArea';
import CheckboxGroup from './CheckboxGroup';
import RadioGroup from './RadioGroup';
import StarRating from './StarRating';
import Country from './Country';
import Email from './Email';
import FileUpload from './FileUpload';
import Percentage from './Percentage';
let RichTextArea;
if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  RichTextArea = TextArea;
} else {
  RichTextArea = require('./RichTextArea').default;
}
import YesNo from './YesNo';
import AnalyseAndImplement from './AnalyseAndImplement';
import Integer from './Integer';
import MoreInfoRead from './MoreInfoRead';
import FormSectionHeader from './FormSectionHeader';
import ArrayField from './Array';
import ObjectField from './ObjectField';
import CheckBoxBool from './CheckBoxBool';
import ModelSearchField from './ModelSearchField';
import Threshold from './Threshold';
import Hidden from './Hidden';
let JSONBuilder;
if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  JSONBuilder = TextArea;
} else {
  JSONBuilder = require('./JSONBuilder').default;
}

const Fields = {
  Text,
  Currency,
  Select,
  Date,
  Number,
  TextArea,
  CheckboxGroup,
  RadioGroup,
  StarRating,
  Country,
  Email,
  FileUpload,
  Percentage,
  RichTextArea,
  AnalyseAndImplement,
  YesNo,
  Integer,
  MoreInfoRead,
  ArrayField,
  ObjectField,
  JSONBuilder,
  FormSectionHeader,
  CheckBoxBool,
  ModelSearchField,
  Threshold,
  Hidden
};
export default Fields;
