import React from 'react';
import * as PropTypes from 'prop-types';
import WorkflowListLoader from '../workflow/WorkflowListLoader';
import { WrapWizardContext } from '../../context/WizardContext';
import ExecutionPlanPartiesStep from '../executionPlan/ExecutionPlanPartiesStep';
import FollowUpTicketList from './FollowUpTicketList';
import CreateFollowUpProgrammeSettings from './CreateFollowUpProgrammeSettings';
import CreateFollowUpProgrammeConfirmation from './CreateFollowUpProgrammeConfirmation';
import workflowApi from '../../api/workflowApi';
import * as ExecutionPlansUtils from '../../utils/ExecutionPlansUtils';

const CreateFollowUpProgrammeComponents = (props) => {
  const {
    stepForward,
    currentStep,
    onChange,
    setOptionsValue,
    wizardChoices,
    setWizardChoices,
  } = props;

  const loadWorkflow = (workflowId) => {
    workflowApi.getWorkflow(workflowId).then((workflow) => {
      const updatedPlan = ExecutionPlansUtils.updateChoicesFromWorkflow(
        wizardChoices,
        workflow
      );
      setWizardChoices(updatedPlan);
    });
  };

  const workflowSelected = (workflow) => {
    loadWorkflow(workflow.id);
    stepForward();
  };

  switch (currentStep) {
    case 'TICKETS':
      return (
        <React.Fragment>
          <FollowUpTicketList onChange={onChange} />
        </React.Fragment>
      );
    case 'WORKFLOW':
      return (
        <React.Fragment>
          <WorkflowListLoader onSelect={workflowSelected} />
        </React.Fragment>
      );
    case 'PARTIES':
      return (
        <React.Fragment>
          <ExecutionPlanPartiesStep
            options={wizardChoices}
            needsReview={wizardChoices.needsReview}
            onChange={onChange}
          />
        </React.Fragment>
      );
    case 'SETTINGS':
      return (
        <React.Fragment>
          <CreateFollowUpProgrammeSettings
            options={wizardChoices}
            setOptionsValue={setOptionsValue}
            setValue={onChange}
          />
        </React.Fragment>
      );
    case 'CONFIRM':
      return <CreateFollowUpProgrammeConfirmation />;
    default:
      return null;
  }
};

CreateFollowUpProgrammeComponents.propTypes = {
  stepForward: PropTypes.func,
  currentStep: PropTypes.string,
  onChange: PropTypes.func,
  setOptionsValue: PropTypes.func,
  wizardChoices: PropTypes.object,
  setWizardChoices: PropTypes.func,
};

export default WrapWizardContext(CreateFollowUpProgrammeComponents);
