import React from 'react';
import Moment from 'react-moment';
import { User } from '../../types';

import './FormFieldAuthor.scss';

type FieldAuthorProps = {
  user: Pick<User, 'firstName' | 'lastName'>;
  modifiedDate: Date;
  dateFormat?: string;
};

export const FieldAuthor = ({
  user,
  modifiedDate,
  dateFormat,
}: FieldAuthorProps) => {
  return (
    <div className="form-field-author">
      {user.firstName} {user.lastName} -{' '}
      <Moment date={modifiedDate} format={dateFormat} />
    </div>
  );
};
