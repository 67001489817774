import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { PartyWrapContext } from '../../../context/PartyContext';
import EntitiesListItem from './EntityListItem';
import SortableHeaderCell from '../../common/SortableHeaderCell';
import { getActiveItems } from '../../../utils/PartyUtils';
import ConfirmWrapper from '../../common/ConfirmWrapper';

interface EntityListProps {
  entities: any[];
  handleSortChange: (sortField: string) => void;
  paginationOptions: object;
  handleAddFilter: (filters: object) => void;
  removeFilterValues: (filters: string[]) => void;
  currentFilters: object;
}

const EntityList = (props: EntityListProps) => {
  const {
    entities,
    handleSortChange,
    paginationOptions,
    handleAddFilter,
    removeFilterValues,
    currentFilters,
  } = props;

  const renderEntityList = () => {
    return getActiveItems(entities).map((entity) => (
      <EntitiesListItem entity={entity} key={entity.id} />
    ));
  };
  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
          >
            Entity Label
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="relationshipType"
            handleSortChange={handleSortChange}
            handleAddFilter={handleAddFilter}
            removeFilterValues={removeFilterValues}
            currentFilters={currentFilters}
          >
            Entity Type
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="createdAt"
            handleSortChange={handleSortChange}
          >
            Created At
          </SortableHeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderEntityList()}</Table.Body>
    </Table>
  );
};

export default EntityList;
