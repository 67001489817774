import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Header, Card, Button, Grid } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import KpiPieComponent from '../common/dashboard/KpiPieComponent';
import KpiComponent from '../common/dashboard/components/KpiComponent/KpiComponent';
import PieChartComponent from '../common/dashboard/PieChartComponent';
import SpiderChartComponent from '../common/dashboard/SpiderChartComponent';
import SankeyChartComponent from '../common/dashboard/SankeyChartComponent';
import { kpiValueFormats } from '../../constants/config';
import DashboardWidget from '../common/dashboard/DashboardWidget';
import { SIDEBAR_COMPONENTS } from '../common/SidebarRoot';
import * as uiActions from '../../actions/uiActions';
import ErrorBoundary from './../common/ErrorBoundary';

const pieChartData = [
  {
    innerSize: '60%',
    size: '80%',

    data: [
      {
        name: 'In progress',
        y: 12,
        color: 'orange',
      },
      {
        name: 'Awaiting Approval',
        y: 19,
        color: 'violet',
      },
      {
        name: 'Rejected',
        y: 37,
        color: 'red',
      },
      {
        name: 'Complete',
        y: 23,
        color: 'green',
      },
    ],
  },
  {
    innerSize: '80%',
    size: '100%',

    data: [
      {
        name: 'In progress',
        y: 12,
        color: 'orange',
      },
      {
        name: 'Awaiting Approval',
        y: 19,
        color: 'violet',
      },
      {
        name: 'Rejected',
        y: 37,
        color: 'red',
      },
      {
        name: 'Complete',
        y: 23,
        color: 'green',
      },
    ],
  },
];

const spiderChartData = {
  xAxisCategories: [
    'Sales',
    'Marketing',
    'Development',
    'Customer Support',
    'Information Technology',
    'Administration',
  ],
  series: [
    {
      name: 'Allocated Budget',
      data: [43000, 19000, 60000, 35000, 17000, 10000],
      pointPlacement: 'on',
    },
    {
      name: 'Actual Spending',
      data: [50000, 39000, 42000, 31000, 26000, 14000],
      pointPlacement: 'on',
    },
  ],
};

const sankeyChartData = [
  {
    keys: ['from', 'to', 'weight'],
    data: [
      ['Brazil', 'Portugal', 5],
      ['Brazil', 'France', 1],
      ['Brazil', 'Spain', 1],
      ['Brazil', 'England', 1],
      ['Canada', 'Portugal', 1],
      ['Canada', 'France', 5],
      ['Canada', 'England', 1],
      ['Mexico', 'Portugal', 1],
      ['Mexico', 'France', 1],
      ['Mexico', 'Spain', 5],
      ['Mexico', 'England', 1],
      ['USA', 'Portugal', 1],
      ['USA', 'France', 1],
      ['USA', 'Spain', 1],
      ['USA', 'England', 5],
      ['Portugal', 'Angola', 2],
      ['Portugal', 'Senegal', 1],
      ['Portugal', 'Morocco', 1],
      ['Portugal', 'South Africa', 3],
      ['France', 'Angola', 1],
      ['France', 'Senegal', 3],
      ['France', 'Mali', 3],
      ['France', 'Morocco', 3],
      ['France', 'South Africa', 1],
      ['Spain', 'Senegal', 1],
      ['Spain', 'Morocco', 3],
      ['Spain', 'South Africa', 1],
      ['England', 'Angola', 1],
      ['England', 'Senegal', 1],
      ['England', 'Morocco', 2],
      ['England', 'South Africa', 7],
      ['South Africa', 'China', 5],
      ['South Africa', 'India', 1],
      ['South Africa', 'Japan', 3],
      ['Angola', 'China', 5],
      ['Angola', 'India', 1],
      ['Angola', 'Japan', 3],
      ['Senegal', 'China', 5],
      ['Senegal', 'India', 1],
      ['Senegal', 'Japan', 3],
      ['Mali', 'China', 5],
      ['Mali', 'India', 1],
      ['Mali', 'Japan', 3],
      ['Morocco', 'China', 5],
      ['Morocco', 'India', 1],
      ['Morocco', 'Japan', 3],
    ],
    type: 'sankey',
    name: 'Sankey demo series',
  },
];

class DashboardDemoPage extends Component {
  static propTypes = {
    parties: PropTypes.array,
    partiesActions: PropTypes.object,
  };

  showSidebar = () => {
    const { uiActions } = this.props;
    uiActions.showSidebar({
      sidebarType: SIDEBAR_COMPONENTS.TICKET_LIST_SIDEBAR,
      sidebarProps: { header: 'Sidebar header' },
      sidebarConfig: { width: 'very wide' },
    });
  };

  render() {
    return (
      <Container>
        <React.Fragment>
          <div className="row">
            <div className="column">
              <Header as="h1">System Dashboard</Header>
            </div>
          </div>

          <Grid columns="equal">
            <Grid.Row stretched>
              <Grid.Column>
                <DashboardWidget
                  id="PIE-001"
                  type="pie"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'group',
                      params: {
                        relationFilter: {
                          joins: ['currentStatusNode'],
                          groups: { currentStatusNode: 'label' },
                          wheres: {
                            base: { executionId: '5' },
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    data: {
                      mappers: ['pie'],
                      args: {
                        series: [
                          {
                            dataSet: 'main',
                            dataRow: 'currentStatusNode.label',
                          },
                        ],
                        yColumn: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    header: 'Ticket Status',
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <DashboardWidget
                  id="PIE-002"
                  type="pie"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'group',
                      params: {
                        relationFilter: {
                          joins: [
                            'currentStatusNode',
                            'assignedPrincipal.user',
                          ],
                          groups: { 'assignedPrincipal.user': 'email' },
                          wheres: {
                            base: { executionId: { inq: [10, 11, 12, 13] } },
                            currentStatusNode: { label: 'Rejected' },
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    data: {
                      mappers: ['pie'],
                      args: {
                        series: [
                          {
                            dataSet: 'main',
                            dataRow: 'assignedPrincipal.user.email',
                          },
                        ],
                        yColumn: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    header: 'Who has rejected tickets?',
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column>
                <DashboardWidget
                  id="PIE-003"
                  type="pie"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'group',
                      params: {
                        relationFilter: {
                          joins: [
                            'statusResolutions.resolution.resolutionType.colour',
                            'execution.party',
                            'currentStatusNode',
                          ],
                          groups: {
                            'execution.party': 'label',
                            'statusResolutions.resolution.resolutionType':
                              'label',
                            'statusResolutions.resolution.resolutionType.colour':
                              'name',
                            currentStatusNode: 'label',
                          },
                          wheres: {
                            base: { executionId: { inq: [10, 11, 12, 13] } },
                            // WorkflowStatusResolution: { statusId: '1' }
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    data: {
                      mappers: ['pie'],
                      args: {
                        series: [
                          {
                            dataSet: 'main',
                            dataRow:
                              'statusResolutions.resolution.resolutionType.label',
                            dataColour:
                              'statusResolutions.resolution.resolutionType.colourId',
                          },
                          {
                            dataSet: 'main',
                            dataRow: 'execution.party.label',
                            dataColour:
                              'statusResolutions.resolution.resolutionType.colourId',
                          },
                          {
                            dataSet: 'main',
                            dataRow: 'currentStatusNode.label',
                            dataColour:
                              'statusResolutions.resolution.resolutionType.colourId',
                          },
                        ],
                        yColumn: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    header: 'Who has rejected tickets?',
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <DashboardWidget
                  id="BAR-001"
                  type="bar"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'group',
                      params: {
                        relationFilter: {
                          joins: [
                            'statusResolutions.resolution.resolutionType.colour',
                            'execution.party',
                          ],
                          groups: {
                            'execution.party': 'label',
                            'statusResolutions.resolution.resolutionType':
                              'label',
                            'statusResolutions.resolution.resolutionType.colour':
                              'name',
                          },
                          wheres: {
                            base: { executionId: { inq: [10, 11, 12, 13] } },
                            // WorkflowStatusResolution: { statusId: '1' }
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    data: {
                      mappers: ['bar'],
                      args: {
                        xAxis: {
                          dataSet: 'main',
                          dataRow: 'execution.party.label',
                        },
                        yAxis: {
                          dataSet: 'main',
                          dataRow:
                            'statusResolutions.resolution.resolutionType.label',
                          dataColour:
                            'statusResolutions.resolution.resolutionType.colourId',
                        },
                        yColumn: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    header: 'Resolution by party',
                    chartOptions: {
                      plotOptions: {
                        column: {
                          stacking: 'normal',
                        },
                        series: {
                          stacking: 'normal',
                        },
                      },
                    },
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <DashboardWidget
                  id="SPIDER-001"
                  type="spider"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'group',
                      params: {
                        relationFilter: {
                          joins: [
                            'activity.primaryTag',
                            'execution',
                            'statusResolutions.resolution.resolutionType.colour',
                          ],
                          groups: {
                            'activity.primaryTag': 'label',
                            'statusResolutions.resolution.resolutionType':
                              'label',
                            'statusResolutions.resolution.resolutionType.colour':
                              'name',
                          },
                          wheres: {
                            'statusResolutions.resolution.resolutionType': {
                              label: { inq: ['NEGATIVE', 'POSITIVE'] },
                            },
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    data: {
                      mappers: ['bar'],
                      args: {
                        xAxis: {
                          dataSet: 'main',
                          dataRow: 'activity.primaryTag.label',
                        },
                        yAxis: {
                          dataSet: 'main',
                          dataRow:
                            'statusResolutions.resolution.resolutionType.label',
                          dataColour:
                            'statusResolutions.resolution.resolutionType.colourId',
                        },
                        yColumn: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    header: 'Who has rejected tickets?',
                    chartOptions: {
                      plotOptions: {
                        column: {
                          stacking: 'normal',
                        },
                        series: {
                          stacking: 'normal',
                        },
                      },
                    },
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row stretched>
              <Grid.Column>
                <DashboardWidget
                  id="KPI-001"
                  type="kpi"
                  dataCalls={{
                    main: {
                      model: 'ExecutionTicket',
                      endpoint: 'filter',
                      params: {
                        relationFilter: {
                          joins: [
                            'currentStatusNode',
                            'assignedPrincipal.user',
                          ],
                          wheres: {
                            // ExecutionTicket: { executionId: '10' },
                            currentStatusNode: { label: 'Rejected' },
                          },
                        },
                      },
                    },
                  }}
                  dataMapping={{
                    value: {
                      mappers: ['kpi'],
                      args: {
                        dataValue: {
                          dataSet: 'main',
                          dataRow: 'count',
                        },
                      },
                    },
                  }}
                  config={{
                    format: 'Number',
                    iconName: 'frown',
                    label: 'Tickets',
                    header: 'Rejected',
                    iconColor: 'red',
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Header as="h1">
            that is all from API, next components are hardcoded!
          </Header>
          <Card.Group itemsPerRow={4}>
            <Card fluid>
              <Header as="h4" attached="top" block>
                Item growth
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiComponent
                    value={35}
                    format={kpiValueFormats.Percentage}
                    iconName="plane"
                    label="growth"
                    header="Item growth"
                    iconColor="green"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Item growth
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiComponent
                    value={42}
                    format={kpiValueFormats.Percentage}
                    iconName="users"
                    label="new users"
                    header="Item new users"
                    iconColor="blue"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Item profit
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiComponent
                    value={37}
                    format={kpiValueFormats.Currency}
                    currencyType="USD"
                    iconName="dollar"
                    label="profit"
                    header="Item profit"
                    iconColor="yellow"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Item sales
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiComponent
                    value={25}
                    format={kpiValueFormats.Currency}
                    currencyType="USD"
                    iconName="cart"
                    label="sales"
                    header="Item sales"
                    iconColor="red"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>
          </Card.Group>
          <Card.Group itemsPerRow={3}>
            <Card fluid>
              <Header as="h4" attached="top" block>
                Growth pie item
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiPieComponent
                    format={kpiValueFormats.Percentage}
                    label="growth"
                    value={45}
                    color="green"
                    header="Growth pie item"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Suppliers pie item
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiPieComponent
                    format={kpiValueFormats.Percentage}
                    label="suppliers"
                    value={78}
                    color="yellow"
                    header="Suppliers pie item"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Contracted pie item
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <KpiPieComponent
                    format={kpiValueFormats.Number}
                    label="contracted"
                    value={94}
                    color="blue"
                    text={10}
                    header="Contracted pie item"
                  />
                </Card.Meta>
              </Card.Content>
            </Card>
          </Card.Group>
          <Card.Group itemsPerRow={2}>
            <Card fluid>
              <Header as="h4" attached="top" block>
                Current Item Status
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <SpiderChartComponent
                    header="Current Item Status"
                    data={spiderChartData}
                  />
                </Card.Meta>
              </Card.Content>
            </Card>

            <Card fluid>
              <Header as="h4" attached="top" block>
                Sankey Diagram
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <SankeyChartComponent
                    header="Sankey Diagram"
                    data={sankeyChartData}
                  />
                </Card.Meta>
              </Card.Content>
            </Card>
          </Card.Group>
          <Card.Group itemsPerRow={1}>
            <Card fluid>
              <Header as="h4" attached="top" block>
                Current Item Status
              </Header>
              <Card.Content>
                <Card.Meta textAlign="center">
                  <PieChartComponent
                    header="Current Item Status"
                    data={pieChartData}
                  />
                </Card.Meta>
              </Card.Content>
            </Card>
          </Card.Group>
          <Card.Group itemsPerRow={1}>
            <Button onClick={this.showSidebar}>SHOW SIDEBAR</Button>
          </Card.Group>
        </React.Fragment>
      </Container>
    );
  }
}

DashboardDemoPage.propTypes = {
  uiActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default ErrorBoundary(
  connect(null, mapDispatchToProps)(DashboardDemoPage)
);
