import React, { Component } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';

import SelectRelationship from '../executionPlan/SelectRelationship';
import RelationshipLabel from '../common/labels/RelationshipLabel';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';

class ExecutionSelectRelationship extends Component {
  getRelationshipOptions = () => {
    const execution = this.props.currentExecution;
    if (execution && execution.relationships) {
      return _.map(execution.relationships, (relationship) => {
        const { id, label, fromParty, toParty } = relationship;
        return {
          key: id,
          content: <RelationshipLabel relationship={relationship} />,
          value: id,
          text: toParty
            ? `${label} (${toParty.label} - ${fromParty.label})`
            : `${label} (${fromParty.label})`,
        };
      });
    }
    return [];
  };
  dropdownHandler = (event, { value }) => {
    const { executionStateActions } = this.props;
    executionStateActions.setAssignedPartyFilter(
      _.isNumber(value) ? value : null
    );
  };
  render() {
    const filter = this.props.currentExecution.filter;
    const assignedPartyRelationshipId = filter
      ? filter.assignedPartyRelationship.partyId
      : null;
    const options = this.getRelationshipOptions();
    if (options.length === 0) return null;
    return (
      <SelectRelationship
        clearable={true}
        label="Filter by entity"
        options={options}
        disabled={false}
        value={assignedPartyRelationshipId}
        onChange={this.dropdownHandler}
        className="select-relationship-execution-plan"
        showLabel={false}
      />
    );
  }
}

ExecutionSelectRelationship.propTypes = {
  currentExecution: PropTypes.object,
  executionStateActions: PropTypes.object,
  executionsFilter: PropTypes.number,
};

export default ExecutionContextConsumer(ExecutionSelectRelationship);
