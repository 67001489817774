import React, { useState } from 'react';
import { Button, Container, Divider } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

import DashboardContainer from './DashboardContainer';
import ErrorBoundary from '../common/ErrorBoundary';
import { useAppSelector } from '../../actions/store';

interface DashboardPageProps extends RouteComponentProps<{ id: string }> {
  editMode?: boolean;
  showHeader?: boolean;
  id?: string;
  forceChartRefresh?: boolean;
  enableQueryString?: boolean;
}

const DashboardPage: React.FC<DashboardPageProps> = ({
  match,
  editMode = false,
  showHeader = true,
  forceChartRefresh = false,
  history,
  enableQueryString,
  id: propsId,
}) => {
  const currentUser = useAppSelector((state) => state.currentUser);

  const [editable, setEditable] = useState(editMode);
  const id = propsId || match.params.id;

  return (
    <Container>
      {editMode && (
        <React.Fragment>
          <Divider className="hide-for-print" />
          <Button
            as="a"
            attached="right"
            size="mini"
            className="hide-for-print"
            onClick={() => {
              setEditable(!editable);
            }}
          >
            Toggle Edit Mode
          </Button>
        </React.Fragment>
      )}
      <DashboardContainer
        id={id}
        key={id}
        editable={editable}
        showHeader={showHeader}
        forceChartRefresh={forceChartRefresh}
        history={history}
        enableQueryString={enableQueryString}
        locationSearch={window.location.search}
        initialProperties={{
          PartyIds: [currentUser.details.partyId],
        }}
      />
    </Container>
  );
};

export default ErrorBoundary(DashboardPage);
