import * as _ from 'lodash';

const mapAddress = {
  name: 'line1',
  postal_town: 'city',
  route: 'line2',
  administrative_area_level_2: 'stateProvinceCounty',
  postal_code: 'zipOrPostcode',
  country: 'countryId',
};

export function handleResponse(place) {
  if (place) {
    let { address_components, name } = place;
    let addressComponents = [{ types: ['name'], long_name: name }];
    addressComponents.push(...address_components);
    const result = _.uniqBy(
      _.map(addressComponents, (addressComponent) => {
        const { types, long_name, short_name } = addressComponent;
        const name = _.head(types);
        switch (name) {
          case 'country': {
            return {
              type: mapAddress[name],
              value: short_name.toLowerCase(),
            };
          }
          case 'route': {
            const value = `${search(addressComponents)} ${short_name}`;
            return { type: mapAddress[name], value };
          }
          default: {
            return { type: mapAddress[name], value: long_name };
          }
        }
      }),
      'value'
    );
    return result;
  }
}

function search(address_components) {
  const search = _.filter(address_components, (address_component) => {
    return address_component.types.includes('street_number');
  });
  return search.length > 0 ? _.head(search).long_name : '';
}
