import * as _ from 'lodash';

export const defaultDateFormat = 'D/MMM/YY';

export const defaultDateTimeFormat = 'D/MMM/YY h:mm A';

export const defaultDashboards = {
  execution: {
    id: '2F73E870-BDAC-11E8-9092-5D3BBC7DF6C3',
    label: 'Overview',
  },
  programmeStatus: {
    id: '27DB0E70-BA8E-11E8-9256-A77508DD45AA',
    label: 'Programme Status',
  },
  reviewManagement: {
    id: '2D392450-C336-11E8-BBAE-CF5BC5BBFFF1',
    label: 'Review Management',
  },
  riskDistribution: {
    id: '569715D0-CE14-11E8-BA5F-4947DF75D5D9',
    label: 'Risk Distribution',
  },
  fairvalueSummary: {
    id: 'D6F27EB0-7B06-11ED-B4F5-B5CF7BBDD9D7',
    label: 'Fair Value Summary',
  },
  fairvalueDistributorStatus: {
    id: 'E8B4F1B0-9C60-11EE-8AC0-D50C078C77CA',
    label: 'Distributor Status',
  },
  fairvalueProductStatus: {
    id: '51543DA0-9D94-11EE-B319-016538FE56DC',
    label: 'Product Status',
  },
  fairvaluePOGOverview: {
    id: '5B74FDB0-ABB8-11EE-935C-8BB808F8A153',
    label: 'POG Overview',
  },
};
export const rteConfigNote = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'LINK_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'class-name' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

export const defaultCurrency = 'GBP';

export const kpiValueFormats = {
  Percentage: 'Percentage',
  Currency: 'Currency',
  Number: 'Number',
  Date: 'Date',
};

export const fetchEventsTimeout = 110000

export const colors = {
  red: '#FC3B64',
  orange: '#F9926E',
  yellow: '#FBE43B',
  olive: '#B5CC18',
  green: '#6ED457',
  teal: '#26BCB1',
  blue: '#136ABA',
  violet: '#AF6EF9',
  purple: '#531686',
  pink: '#E03997',
  brown: '#A5673F',
  grey: '#767676',
  black: '#1B1C1D',
};

export const lightColors = {
  red: '#FC3B64',
  orange: '#F9926E',
  yellow: '#FBE43B',
  olive: '#B5CC18',
  green: '#6ED457',
  teal: '#26BCB1',
  blue: '#136ABA',
  violet: '#AF6EF9',
  purple: '#531686',
  pink: '#E03997',
  brown: '#A5673F',
  grey: '#767676',
  black: '#1B1C1D',
};

export const gradientColors = {};

_.forEach(colors, (color, key) => {
  gradientColors[key] = {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, color],
      [1, lightColors[key]], // darken
    ],
  };
});

export const comparisonValuesThresholdModal = [
  { id: 'GT', label: 'Greater Than' },
  { id: 'LT', label: 'Less Than' },
  { id: 'WR', label: 'Within Range' },
  { id: 'BR', label: 'Beyond Range' },
];

export const valueTypeValuesThresholdModal = [
  { id: 'P', label: 'Percentage' },
  { id: 'N', label: 'Numeric' },
];

export const severityLevels = [
  { label: 'Very High', id: 1 },
  { label: 'High', id: 2 },
  { label: 'Medium', id: 3 },
  { label: 'Low', id: 4 },
  { label: 'Very Low', id: 5 },
];

export const complexityLevels = [
  { label: 'Very Low', id: 1 },
  { label: 'Low', id: 2 },
  { label: 'Medium', id: 3 },
  { label: 'High', id: 4 },
  { label: 'Very High', id: 5 },
];

export const bytesInMegaByte = 1048576;

export const ticketsPerExecutionPageCall = 30;

export const executionPlanYearRange = 10;

export const granularPermissionTypes = [
  { label: 'None', id: 'NONE' },
  { label: 'Ticket Set', id: 'TICKETSET' },
  { label: 'Assigned Relationship', id: 'RELATION' },
];
