import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import toastr from 'toastr';
import { Button } from 'semantic-ui-react';

import { ExecutionPlanWrapContext } from '../../context/ExecutionPlanContext';
import SelectRelationship from './SelectRelationship';

import RelationshipLabel from '../common/labels/RelationshipLabel';

import './ExecutionPlanActivitySetsRelationship.scss';

class ExecutionPlanActivitySetsRelationship extends Component {
  state = {
    relationshipId: null,
    saving: false,
    options: [],
  };
  componentDidMount = () => {
    this.setState({ options: this.getRelationshipOptions() });
  };
  dropdownHandler = (event, { value }) => {
    this.setState({ relationshipId: value });
  };
  addToAllHandler = () => {
    const { executionPlanStateActions, executionPlan } = this.props;
    const { relationshipId } = this.state;
    const activitySetIds = this.findCurrentCategoryQuestionSets();
    const party = _.find(executionPlan.ownerParty.partyRelationships, {
      id: relationshipId,
    });
    this.setState({ saving: true });
    executionPlanStateActions
      .assignRelationshipToActivitySetsExectuionPlan(party, activitySetIds)
      .then(() => {
        this.setState({ saving: false, relationshipId: null });
      })
      .catch(({ message }) => {
        this.setState({ saving: false });
        toastr.error(message);
      });
  };
  removeFromAllHandler = () => {
    const { executionPlanStateActions } = this.props;
    const { relationshipId } = this.state;
    const activitySetIds = this.findCurrentCategoryQuestionSets();
    this.setState({ saving: true });
    executionPlanStateActions
      .removeRelationshipToActivitySetsExectuionPlan(
        relationshipId,
        activitySetIds
      )
      .then(() => {
        this.setState({ saving: false, relationshipId: null });
      })
      .catch(({ message }) => {
        this.setState({ saving: false });
        toastr.error(message);
      });
  };
  findCurrentCategoryQuestionSets = () => {
    const { executionPlan, activeCategoryId } = this.props;
    const currentCategoryActivitySets = _.filter(
      executionPlan.programme.activitySets,
      {
        categoryId: activeCategoryId,
      }
    );

    return _.filter(
      _.map(currentCategoryActivitySets, (currentCategoryActivitySet) => {
        const res = _.find(
          executionPlan.activitySets,
          (activitySet) =>
            activitySet.activitySetId === currentCategoryActivitySet.id
        );
        return res
          ? _.find(
              executionPlan.activitySets,
              (activitySet) =>
                activitySet.activitySetId === currentCategoryActivitySet.id
            ).id
          : null;
      }),
      (activitySet) => activitySet
    );
  };
  getRelationshipOptions = () => {
    const { executionPlan } = this.props;
    const { partyRelationships } = executionPlan.ownerParty;
    return _.map(partyRelationships, (partyRelationship) => ({
      key: partyRelationship.id,
      content: <RelationshipLabel relationship={partyRelationship} />,
      value: partyRelationship.id,
      text: `${partyRelationship.label} (${partyRelationship.fromParty.label} - ${partyRelationship.fromParty.label})`,
    }));
  };
  render() {
    const { relationshipId, saving, options } = this.state;
    return (
      <React.Fragment>
        <SelectRelationship
          label="Assign relationship"
          options={options}
          disabled={saving}
          value={relationshipId}
          onChange={this.dropdownHandler}
          className="select-relationship-execution-plan"
        />
        <Button
          primary
          onClick={this.addToAllHandler}
          disabled={!relationshipId}
        >
          Add to all
        </Button>
        <Button
          primary
          onClick={this.removeFromAllHandler}
          disabled={!relationshipId}
        >
          Remove from all
        </Button>
      </React.Fragment>
    );
  }
}

ExecutionPlanActivitySetsRelationship.propTypes = {
  executionPlan: PropTypes.object,
  executionPlanStateActions: PropTypes.object,
  activeCategoryId: PropTypes.string,
};

export default ExecutionPlanWrapContext(ExecutionPlanActivitySetsRelationship);
