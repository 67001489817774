import React from 'react';
import * as PropTypes from 'prop-types';
import WizardContainer from '../executionPlan/WizardContainer';
import CreateFollowUpProgrammeComponents from './CreateFollowUpProgrammeComponents';
import UserWrapper from '../user/User';
import {
  checkFormCompleted,
  checkFollowUpSettings,
} from '../../utils/ExecutionPlansUtils';
import toastr from 'toastr';
import ticketApi from '../../api/ticketApi';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WrapFollowUpContext } from '../../context/FollowUpContext';

const CreateFollowUpProgrammePage = (props) => {
  const { followUpList } = props;

  const steps = {
    TICKETS: {
      completed: false,
      current: true,
      stepNumber: 1,
      title: 'Tickets',
      description: (
        <span>
          Tickets to follow up
          <br />
        </span>
      ),
      icon: 'book',
      hideActions: false,
      actionsPosition: 'top',
    },
    WORKFLOW: {
      completed: false,
      current: false,
      stepNumber: 2,
      title: 'Workflow',
      description: 'Choose a workflow',
      icon: 'exchange',
      hideActions: true,
    },
    PARTIES: {
      completed: false,
      current: false,
      stepNumber: 3,
      title: 'Parties & Users',
      description: 'Choose Parties and Users',
      icon: 'users',
      formValidationFunction: checkFormCompleted,
    },
    SETTINGS: {
      completed: false,
      current: false,
      stepNumber: 4,
      title: 'Settings',
      description: 'Other settings',
      icon: 'settings',
      formValidationFunction: checkFollowUpSettings,
    },
    CONFIRM: {
      completed: false,
      current: false,
      stepNumber: 5,
      title: 'Confirm',
      description: 'Please confirm your selection',
      icon: 'info',
      formValidationFunction: checkFormCompleted,
      showSubmitButtons: true,
      submitButtonLabel: 'Launch Programme',
    },
  };

  const initialChoices = {
    ownerParty:
      props.currentUser && props.currentUser.details.party
        ? props.currentUser.details.party
        : null,
    meta: {
      options: {},
    },
    addAllSets: true,
    granularPermissionTypeId: 'NONE',
    permissions: [],
    tickets: followUpList,
  };

  const submitFunction = (wizardChoices) => {
    let formObject = {
      ownerPartyId: wizardChoices.ownerParty.id,
      ownerPartyUserId: wizardChoices.ownerPartyUser.id,
      assignedPartyId: wizardChoices.assignedParty.id,
      assignedPartyUserId: wizardChoices.assignedPartyUser.id,
      workflowId: wizardChoices.workflow.id,
      meta: wizardChoices.meta,
      addAllSets: wizardChoices.addAllSets,
      permissions: wizardChoices.permissions,
      label: wizardChoices.label,
      ticketIds: wizardChoices.tickets,
      dueDate: wizardChoices.dueDate,
      applicableDate: wizardChoices.applicableDate,
      applicableDateTypeId: wizardChoices.applicableDateTypeId,
    };

    if (wizardChoices.needsReview) {
      formObject = Object.assign({}, formObject, {
        reviewPartyId: wizardChoices.reviewParty.id,
        reviewPartyUserId: wizardChoices.reviewPartyUser.id,
      });
    }

    return ticketApi
      .createImplementationExecution(formObject)
      .then((execution) => {
        _.delay(() => {
          const { userActions, history } = props;
          userActions.renewToken().then(() => {
            history.push(`/execution/${execution.id}`);
          });
        }, 1000);
      })
      .catch((error) => {
        toastr.error(error);
        throw error;
      });
  };

  return (
    <React.Fragment>
      <WizardContainer
        submitFunction={submitFunction}
        initialChoices={initialChoices}
        stepComponents={CreateFollowUpProgrammeComponents}
        steps={steps}
      />
    </React.Fragment>
  );
};

CreateFollowUpProgrammePage.propTypes = {
  followUpList: PropTypes.array,
  currentUser: PropTypes.object,
};

export default withRouter(
  WrapFollowUpContext(UserWrapper(CreateFollowUpProgrammePage))
);
