import * as types from '../constants/actionTypes';
import commonApi from '../api/commonApi';
import * as MODELS from '../constants/models';

export function loadAttachments(filter = {}) {
  return function (dispatch) {
    return commonApi.getAttachments(filter).then((attachments) => {
      dispatch({
        type: types.LOAD_ATTACHMENTS_SUCCESS,
        attachments,
      });
    });
  };
}

export function uploadAttachmentDetails(
  attachment,
  userDetails,
  ticketId = null
) {
  return function (dispatch) {
    return commonApi
      .uploadAttachmentDetails(attachment)
      .then((attachment) => {
        dispatch({
          type: types.UPDATE_ATTACHMENT_SUCCESS,
          attachment,
          userDetails,
          ticketId,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_ATTACHMENT_FAILED,
          attachmentId: attachment.id,
          error,
        });
        throw error;
      });
  };
}

export function uploadExternalAttachments(
  modelType,
  currentModel,
  attachment,
  currentUser
) {
  return function (dispatch) {
    return commonApi
      .uploadExternalAttachment(modelType, currentModel.id, attachment)
      .then((attachments) => {
        if (modelType === MODELS.EXECUTION) {
          const executionId = currentModel.id;
          dispatch({
            type: types.EXECUTION_ADD_ATTACHMENTS,
            executionId,
            attachments,
            currentUser,
          });
        } else {
          dispatch({
            type: `${types.TICKET_ADD_ATTACHMENTS}`,
            ticket: currentModel,
            attachments,
            currentUser,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.LOAD_ATTACHMENTS_FAILED,
          attachmentId: attachment.id,
          error,
        });
        throw error;
      });
  };
}
