import React from 'react';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';

import UserWrapper from '../../user/User';
import { WrapFollowUpContext } from '../../../context/FollowUpContext';
import MenuItemsWithPoll from '../MenuItemsWithPoll';

const TopMenu = ({ userStateActions, currentUser }) => {
  return (
    <Menu secondary className="top-menu">
      {currentUser.loggedIn && (
        <Menu.Menu position="right">
          <Menu.Item>
            {currentUser.fullname} -{' '}
            {currentUser.details.party ? currentUser.details.party.label : ''}
          </Menu.Item>

          <Dropdown
            item
            icon={<Icon name="user" size="large" />}
            simple
            className="right"
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={userStateActions.logout}>
                Logout
              </Dropdown.Item>
              {currentUser.loggedInAsUser && (
                <Dropdown.Item onClick={userStateActions.returnToOriginalUser}>
                  Return to User
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <MenuItemsWithPoll />

          <Menu.Item href="https://docs.google.com/document/d/e/2PACX-1vSoazxfoL131KKZrxfowRI910f0K5bi6uDJ60Gvd9lB4MMiW2fY6WEilsMYWQMfjXL7r_mJQiobyPXy/pub" target="_blank">
            <Icon name="help circle" size="large" color="black" />
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
};

TopMenu.propTypes = {
  userStateActions: PropTypes.object.isRequired,
  uiActions: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  currentUsers: PropTypes.array,
  followUpList: PropTypes.array,
  events: PropTypes.object,
};

export default withRouter(WrapFollowUpContext(UserWrapper(TopMenu)));
