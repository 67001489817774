import React, { useState, useEffect } from 'react';
import chartApi from '../../api/chartApi';
import * as _ from 'lodash';
import DebugComponent from '../common/dashboard/components/DebugComponent';
import CallBuilder from './CallBuilder';
import utilsApi from '../../api/utilsApi';
import { Button, Dropdown, Form, Grid, Segment } from 'semantic-ui-react';
import JSONBuilder from '../common/fields/JSONBuilder';
import update from 'immutability-helper';
import DebugTable from '../debug/DebugTable';
import Text from '../common/fields/Text';
import toastr from 'toastr';

export const APICallContext = React.createContext();

const ApiCallPage = (props) => {
  const [label, setLabel] = useState('');
  const [options, setOptions] = useState({
    builder: {},
  });
  const [modelArguments, setArguments] = useState({
    model: 'ExecutionTicket',
  });
  const [models, setModels] = useState([]);
  const [testData, setTestData] = useState([]);

  let id = _.get(props, 'match.params.id');

  useEffect(() => {
    utilsApi.getModelStructure().then((result) => {
      setModels(result);
      chartApi.getAPICall({ id }).then((data) => {
        setLabel(data.label);
        setOptions(data.options);
        setArguments(data.arguments);
      });
    });
  }, []);

  const saveEndpoint = () => {
    chartApi.saveAPICall({ label, options, modelArguments, id }).then(() => {
      toastr.success('Chart Saved');
    });
  };
  const saveNewEndpoint = () => {
    chartApi
      .saveAPICall({ label, options, modelArguments })
      .then(({ data }) => {
        id = data.id;

        props.history.replace(`/apicalls/${id}`);

        toastr.success('New Chart Saved');
      });
  };

  const handleArgumentChange = (e, { name, value }) => {
    console.log({ name, value });
    setArguments(
      update(modelArguments, {
        [name]: {
          $set: value,
        },
      })
    );
  };
  const mergeFunc = (objValue, srcValue) => {
    if (_.isString(objValue)) {
      return [objValue, srcValue];
    }
  };
  const createFilter = (joins, groups, wheres) => {
    let filter = {};

    const mappedJoins = joins.map((join) => {
      if (join[0].relationshipName === 'base') return null;
      if (join.length === 1)
        return {
          [join[0].relationshipName]: true,
        };
      return _.reduce(
        _.reverse([...join]),
        function (result, value) {
          if (!result) return value['relationshipName'];
          return { [value['relationshipName']]: result };
        },
        null
      );
    });

    const mergedJoins = _.reduce(
      mappedJoins,
      (result, value) => {
        return _.mergeWith(result, value, mergeFunc);
      },
      {}
    );

    filter.joins = _.map(mergedJoins, (value, key) => {
      if (value === true) return key;
      return { [key]: value };
    });

    filter.groups = {};

    _.forEach(groups, (group) => {
      const key = group.relationships.map((r) => r.relationshipName).join('.');
      filter.groups[key] = group.property;
    });

    filter.wheres = {};

    _.forEach(wheres, (where) => {
      const key = where.relationships.map((r) => r.relationshipName).join('.');

      filter.wheres[key] = where.properties.map((prop) => {
        return { [prop.property]: JSON.parse(prop.value) };
      });
    });

    // filter.wheres = _.zipObject(
    //   wheres.map(where => where.model),
    //   wheres.map(where => ({ [where.property]: where.value }))
    // );

    return filter;
  };

  const handleBuilderChange = (data) => {
    Promise.resolve().then(() => {
      setOptions({
        ...options,
        builder: {
          ...data,
        },
      });
    });

    const { joins, groups, wheres } = data;

    const relationFilter = createFilter(joins, groups, wheres);
    handleArgumentChange(null, {
      name: 'params',
      value: {
        relationFilter,
      },
    });
  };

  const getModelEndpoints = (model) => {
    console.log({ model });
    if (!models[model]) return [];
    const base = [
      {
        key: 1000,
        text: 'filter',
        value: 'filter',
      },
      {
        key: 1001,
        text: 'group',
        value: 'group',
      },
      {
        key: 1002,
        text: 'get',
        value: '/',
      },
      {
        key: 1003,
        text: 'search',
        value: 'search',
      },
    ];
    return [
      ...base,
      ..._.map(models[model].miData, (value, index) => {
        return {
          key: index,
          text: value.description,
          value: `miData/${value.endpoint}`,
        };
      }),
    ];
  };

  const testEndpoint = () => {
    // const { baseModel, joins, groups, wheres } = this.state;
    // const filter = this.createFilter(joins, groups, wheres);
    //
    // this.setState({ relationFilter: filter });
    //
    // utilsApi.groupModel(baseModel, filter).then(groupTestData => {
    //   this.setState({ groupTestData }, () => {
    //     this.configChange('widgetConfig', 'endpoint', 'group');
    //   });
    // });

    const { model, endpoint, params } = modelArguments;

    utilsApi
      .modelCall({
        model,
        endpoint,
        params,
      })
      .then((data) => {
        window.TEST_DATA = data;
        setTestData(data);
      });
  };

  return (
    <APICallContext.Provider value={{ models, options, modelArguments }}>
      <DebugComponent options={options} modelArguments={modelArguments} />

      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Form>
                <Text
                  label="Label"
                  name="label"
                  onChange={(e, { value }) => {
                    setLabel(value);
                  }}
                  value={label}
                />
                <Form.Field>
                  <Dropdown
                    fluid
                    selection
                    name="model"
                    onChange={handleArgumentChange}
                    options={_.map(models, (model, name) => {
                      return {
                        key: name,
                        text: name,
                        // value: model.plural ? model.plural : name
                        value: name,
                      };
                    })}
                    value={modelArguments.model}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    fluid
                    selection
                    name="endpoint"
                    onChange={handleArgumentChange}
                    options={getModelEndpoints(modelArguments.model)}
                    value={modelArguments.endpoint}
                  />
                </Form.Field>

                <Button.Group>
                  {/*<Button onClick={testGroup}>Test Group</Button>*/}
                  {/*<Button onClick={testFilter}>Test Filter</Button>*/}
                  <Button onClick={testEndpoint}>Test Endpoint</Button>
                  <Button onClick={saveEndpoint}>Save</Button>
                  <Button onClick={saveNewEndpoint}>Save New</Button>
                </Button.Group>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <JSONBuilder
                label="Params"
                value={modelArguments}
                name="endpointParams"
                onChange={(e, { value }) => {
                  setArguments(value);
                }}
                allowModeChange={true}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {modelArguments.endpoint === 'group' && (
        <CallBuilder onChange={handleBuilderChange} />
      )}
      <Segment>
        <DebugTable data={testData} />{' '}
      </Segment>
    </APICallContext.Provider>
  );
};

ApiCallPage.propTypes = {};

export default ApiCallPage;
