export const filterTypes = {
  FILTER: 'FILTER',
  DATE: 'DATE',
  SEARCH: 'SEARCH',
};

export const dateProperties = [
  'createdAt',
  'dueDate',
  'launchDate',
  'applicableDate',
  'modifiedAt',
];

export const progressColumnName = 'completionPercentage';
