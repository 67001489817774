import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Modal, Form } from 'semantic-ui-react';
import * as _ from 'lodash';
import toastr from 'toastr';

import * as uiActions from '../../actions/uiActions';
import Text from '../common/fields/Text';
import { checkModalFormDataValid } from '../../utils/Validation';

class SaveAdminThresholdModal extends Component {
  state = {
    reference: undefined,
    description: undefined,
    label: undefined,
    saving: false,
    isModalFormDataValid: false,
    validations: {
      reference: {},
      description: {},
      label: {},
    },
  };

  componentWillMount() {
    const { threshold } = this.props;
    if (threshold) {
      const { reference, description, label } = threshold;
      this.setState({
        reference,
        description,
        label,
        validations: {
          reference: { isValid: true },
          description: { isValid: true },
          label: { isValid: true },
        },
        isModalFormDataValid: true,
      });
    }
  }

  handleInputChanged = (event, { name, value, checked }) => {
    this.setState({ [name]: value || checked }, () => {
      if (value !== undefined) {
        this.checkValidation(name, this.state[name]);
      }
    });
  };

  handleOnFocus = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = true;
    this.setState({ validations });
  };

  handleOnBlur = (name) => {
    const { validations } = this.state;
    validations[name].hasFocus = false;
    this.checkValidation(name, this.state[name]);
    this.setState({ validations });
  };

  checkValidation = (name, value) => {
    const { validations } = this.state;
    const modalFormDataValid = checkModalFormDataValid(
      validations,
      name,
      value
    );
    this.setState({ ...modalFormDataValid });
  };

  getErrors = (propertryName) => {
    const { validations } = this.state;
    const validation = validations[propertryName];
    const isValidationErrors = !_.isEmpty(validation.errors);
    return !validation.hasFocus && isValidationErrors ? validation.errors : {};
  };

  saveThreshold = () => {
    const { reference, description, label } = this.state;

    const { constantStateActions, threshold } = this.props;

    this.setState({ saving: true });

    const newThreshold = {
      label,
      reference,
      description,
    };

    constantStateActions
      .saveAdminThreshold(newThreshold, threshold ? threshold.id : null)
      .then(this.saveThresholdCallback)
      .catch((error) => this.saveThresholdError(error));
  };

  saveThresholdCallback = () => {
    const { uiActions, threshold } = this.props;
    threshold
      ? toastr.success(`Threshold ${threshold.reference} is updated `)
      : toastr.success('New threshold is added ');
    this.setState({ saving: false });
    uiActions.closeModal();
  };

  saveThresholdError = (error) => {
    toastr.error(error);
    this.setState({ saving: false });
    throw error;
  };

  render() {
    const {
      saving,
      label,
      reference,
      description,
      isModalFormDataValid,
    } = this.state;
    const { uiActions, threshold } = this.props;

    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>
          {threshold ? `Update threshold` : 'Create new threshold'}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Text
                label="Label"
                value={label}
                name="label"
                type="text"
                errors={this.getErrors('label')}
                onChange={this.handleInputChanged}
                onBlur={() => this.handleOnBlur('label')}
                onFocus={() => this.handleOnFocus('label')}
              />
            </Form.Field>
            <Form.Field>
              <Text
                label="Reference"
                value={reference}
                name="reference"
                type="text"
                errors={this.getErrors('reference')}
                onChange={this.handleInputChanged}
                onBlur={() => this.handleOnBlur('reference')}
                onFocus={() => this.handleOnFocus('reference')}
              />
            </Form.Field>
            <Form.Field>
              <Text
                label="Description"
                value={description}
                name="description"
                type="text"
                errors={this.getErrors('description')}
                onChange={this.handleInputChanged}
                onBlur={() => this.handleOnBlur('description')}
                onFocus={() => this.handleOnFocus('description')}
              />
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            positive
            onClick={this.saveThreshold}
            disabled={!isModalFormDataValid}
          >
            {threshold ? 'Update' : 'Save'}
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SaveAdminThresholdModal.propTypes = {
  threshold: PropTypes.object,
  constantStateActions: PropTypes.object,
  uiActions: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SaveAdminThresholdModal);
