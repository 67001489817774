/* eslint-disable react/no-did-mount-set-state */

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';

import * as uiActions from '../../actions/uiActions';
import { checkModalFormDataValid } from '../../utils/Validation';
import SaveRelationshipModalForm from './SaveRelationshipModalForm';

class SaveRelationshipModal extends Component {
  state = {
    formData: {
      fromPartyId: this.props.party.id,
      toPartyId: null,
      label: '',
      partyRelationshipTypeId: '',
      isMutual: false,
      entries: [],
      budgets: [],
    },
    saving: false,
    isRelationshipFieldsValid: false,
    isModalFormDataValid: false,
    isBudgetFieldsValid: true,
    validations: {
      fromPartyId: { isValid: true },
      toPartyId: { isValid: true },
      label: {},
      partyRelationshipTypeId: {},
    },
    budgetFieldsValidations: {},
  };

  componentDidMount() {
    const { relationship } = this.props;
    if (relationship) {
      const {
        fromPartyId,
        toPartyId,
        label,
        partyRelationshipTypeId,
        isMutual,
        entries,
        budgets = [],
      } = relationship;
      this.setState({
        formData: {
          fromPartyId,
          toPartyId,
          label,
          partyRelationshipTypeId,
          isMutual,
          budgets,
          entries: _.cloneDeep(entries),
        },
        validations: {
          fromPartyId: { isValid: true },
          toPartyId: { isValid: true },
          label: { isValid: true },
          partyRelationshipTypeId: { isValid: true },
        },
        isModalFormDataValid: true,
        isRelationshipFieldsValid: true,
        isBudgetFieldsValid: true,
      });
    } else {
      const { id } = this.props.party;
      this.setState({ fromPartyId: id });
    }
  }

  handleInputChanged = (event, { name, value, checked }) => {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value || checked,
        },
      }),
      () => {
        if (value !== undefined) {
          const { validations, isModalFormDataValid } = this.checkValidation(
            name,
            this.state.formData[name]
          );
          this.setState({
            validations,
            isModalFormDataValid,
          });
        }
      }
    );
  };

  handleOnFocus = (name) => {
    this.setState((prevState) => ({
      validations: {
        ...prevState.validations,
        [name]: { ...prevState.validations[name], hasFocus: true },
      },
    }));
  };

  handleOnBlur = (name) => {
    const { validations, isModalFormDataValid } = this.checkValidation(
      name,
      this.state.formData[name]
    );
    this.setState({
      validations: {
        ...validations,
        [name]: { ...validations[name], hasFocus: false },
      },
      isModalFormDataValid,
    });
  };

  handleRelationshipChanged = (event, { name, value }) => {
    this.setState(
      (prevState) => ({
        formData: { ...prevState.formData, [name]: value },
        entries: [],
      }),
      () => {
        const { validations, isModalFormDataValid } = this.checkValidation(
          name,
          this.state.formData[name]
        );
        this.setState({
          validations,
          isModalFormDataValid,
        });
        const { partyRelationshipTypeId } = this.state.formData;
        const { relationshipTypes } = this.props;
        const isRelationshipFieldsValid = !_.find(
          _.find(relationshipTypes, { id: partyRelationshipTypeId }).fields,
          { required: true }
        );
        this.setState({ isRelationshipFieldsValid });
      }
    );
  };

  createRelationship = () => {
    const {
      fromPartyId,
      toPartyId,
      label,
      partyRelationshipTypeId,
      isMutual,
      entries,
      budgets,
    } = this.state.formData;
    const {
      partyStateActions,
      parties,
      relationshipTypes,
      relationship,
    } = this.props;

    this.setState({ saving: true });
    const toParty = _.find(parties, (item) => item.id === toPartyId);
    const relationshipType = _.find(
      relationshipTypes,
      (item) => item.id === partyRelationshipTypeId
    );

    const isAllowBudget = this.getIsAllowBudget(partyRelationshipTypeId);

    const newRelationship = {
      toPartyId,
      label,
      partyRelationshipTypeId,
      isMutual,
      entries,
      budgets: isAllowBudget ? budgets : [],
    };

    partyStateActions
      .saveRelationship(
        newRelationship,
        toParty,
        relationship ? relationship.id : null,
        fromPartyId,
        relationshipType
      )
      .then(this.saveRelationshipCallback)
      .catch((error) => this.saveRelationshipError(error));
  };

  saveRelationshipCallback = () => {
    const { relationship, uiActions } = this.props;
    toastr.success(
      relationship
        ? `Entity ${relationship.label} is updated`
        : 'New entity is added '
    );
    uiActions.closeModal();
  };

  saveRelationshipError = (error) => {
    toastr.error(error);
    this.setState({ saving: false });
    throw error;
  };

  getCurrentRelationshipFields = (partyRelationshipTypeId) => {
    const { relationshipTypes } = this.props;
    return partyRelationshipTypeId
      ? _.find(relationshipTypes, { id: partyRelationshipTypeId }).fields
      : [];
  };

  getIsAllowBudget = (partyRelationshipTypeId) => {
    const { relationshipTypes } = this.props;
    return partyRelationshipTypeId
      ? _.find(relationshipTypes, { id: partyRelationshipTypeId }).allowBudget
      : false;
  };

  onRelationshipTypeChange = (field, isRelationshipFieldsValid) => {
    let { entries, partyRelationshipTypeId } = this.state.formData;
    const { name, value } = field;
    const index = _.findIndex(entries, {
      partyRelationshipTypeFieldId: name,
    });
    if (index !== -1) {
      entries[index].value = value;
      this.setState((prevState) => ({
        formData: { ...prevState.formData, entries },
        isRelationshipFieldsValid,
      }));
    } else
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          entries: [
            ...entries,
            {
              partyRelationshipTypeFieldId: name,
              value,
              partyRelationshipId: partyRelationshipTypeId,
            },
          ],
        },
        isRelationshipFieldsValid,
      }));
  };

  checkValidation = (name, value) => {
    const { validations } = this.state;
    const modalFormDataValid = checkModalFormDataValid(
      validations,
      name,
      value,
      {
        toPartyId: {},
      }
    );
    return modalFormDataValid;
  };

  addBudget = () => {
    const { budgets } = this.state.formData;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        budgets: [
          ...budgets,
          { currency: '', premium: '', developmentPeriod: '' },
        ],
      },
      isBudgetFieldsValid: false,
    }));
  };

  handleBudgetValueChanged = ({ name, value, fieldIndex }, isValid) => {
    const { budgets } = this.state.formData;
    const newBudgets = [...budgets];
    const currentValue = { ...newBudgets[fieldIndex] };
    currentValue[name] = value;
    newBudgets[fieldIndex] = currentValue;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        budgets: newBudgets,
      },
      isBudgetFieldsValid: isValid,
    }));
  };

  handleBudgetFieldRemove = (fieldIndex, isValid) => {
    const { budgets } = this.state.formData;
    const newBudgets = [...budgets];
    newBudgets.splice(fieldIndex, 1);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        budgets: newBudgets,
      },
      isBudgetFieldsValid: isValid,
    }));
  };

  render() {
    const {
      saving,
      formData,
      isRelationshipFieldsValid,
      isModalFormDataValid,
      isBudgetFieldsValid,
      validations,
    } = this.state;
    const {
      uiActions,
      relationship,
      partyStateActions,
      relationshipTypes,
    } = this.props;
    let { partyRelationshipTypeId } = this.state.formData;
    const isAllowBudget = this.getIsAllowBudget(partyRelationshipTypeId);
    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="small"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>
          {relationship
            ? `Update ${relationship.label} entity`
            : 'Create new entity'}
        </Modal.Header>
        <Modal.Content>
          <SaveRelationshipModalForm
            relationshipTypes={relationshipTypes}
            partyStateActions={partyStateActions}
            formData={formData}
            isAllowBudget={isAllowBudget}
            handleInputChanged={this.handleInputChanged}
            handleOnFocus={this.handleOnFocus}
            handleOnBlur={this.handleOnBlur}
            getCurrentRelationshipFields={this.getCurrentRelationshipFields}
            handleBudgetValueChanged={this.handleBudgetValueChanged}
            handleBudgetFieldRemove={this.handleBudgetFieldRemove}
            handleRelationshipChanged={this.handleRelationshipChanged}
            onRelationshipTypeChange={this.onRelationshipTypeChange}
            addBudget={this.addBudget}
            validations={validations}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            positive
            onClick={this.createRelationship}
            disabled={
              !isRelationshipFieldsValid ||
              !isModalFormDataValid ||
              (isAllowBudget && !isBudgetFieldsValid)
            }
          >
            {relationship ? 'Update' : 'Save'}
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SaveRelationshipModal.propTypes = {
  parties: PropTypes.array.isRequired,
  party: PropTypes.object.isRequired,
  relationshipTypes: PropTypes.array,
  partyStateActions: PropTypes.object,
  uiActions: PropTypes.object,
  relationship: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    parties: state.parties.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveRelationshipModal);
