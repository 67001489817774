/* eslint-disable react/no-did-mount-set-state */
import React, { useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Icon, Ref } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import TicketPage from '../../ticket/TicketPage/TicketPage';
import { TicketContextConsumer } from '../../../context/TicketContext';
import ExecutionTicketSets from '../ExecutionTicketSets';
import { ExecutionContextConsumer } from '../../../context/ExecutionContext';
import useColumnHeight from '../../../utils/hooks/useColumnHeight';

import './ExecutionSplitView.scss';

const ExecutionSplitView = (props) => {
  const { categoryName } = props;

  let ticketListRef;
  const [ref, columnHeight] = useColumnHeight('auto');

  useEffect(() => {
    if (ticketListRef) ticketListRef.scrollTop = 0;
  }, [categoryName]);

  const { ticketSets, uiState, currentExecution, match } = props;
  const { splitView, selectedTicketId } = uiState;
  const isExecutionLoading = currentExecution.id != match.params.id;

  return (
    <React.Fragment>
      <Grid
        columns={splitView ? 2 : 1}
        className={`execution-split-view ${splitView && 'isSplit'}`}
      >
        <Ref innerRef={ref}>
          <Grid.Column className="tickets-column" width={splitView ? 7 : 16}>
            <div
              className="ticket-list-scroll"
              style={{ height: columnHeight }}
            >
              <ExecutionTicketSets ticketSets={ticketSets} editable={false} />
            </div>
          </Grid.Column>
        </Ref>
        {splitView && (
          <Grid.Column
            className="ticket-column"
            style={{ height: columnHeight }}
            width={9}
          >
            <Icon
              className="close-button"
              onClick={() => {
                props.executionActions.updateUIState({
                  splitView: false,
                });
              }}
              link
              name="ellipsis vertical"
            />

            <TicketPage
              fullPageView={false}
              ticketId={selectedTicketId}
              isExecutionLoading={isExecutionLoading}
              currentExecution={currentExecution}
            />
          </Grid.Column>
        )}
      </Grid>
    </React.Fragment>
  );
};

ExecutionSplitView.propTypes = {
  ticketSets: PropTypes.array,
  currentTicket: PropTypes.object,
  executionActions: PropTypes.object,
  ticketStateActions: PropTypes.object,
  uiState: PropTypes.object,
  ticketActions: PropTypes.object,
  categoryName: PropTypes.string,
  currentExecution: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(
  ExecutionContextConsumer(TicketContextConsumer(ExecutionSplitView))
);
