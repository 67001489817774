import React from 'react';
import * as PropTypes from 'prop-types';
import PartyLabel from './PartyLabel';
import { Header, Icon, Label } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import * as uiActions from '../../../actions/uiActions';
import { connect } from 'react-redux';
import { SIDEBAR_COMPONENTS } from '../SidebarRoot';

require('./RelationshipLabel.scss');

const RelationshipLabel = (props) => {
  const { relationship, linkToSidebar = false, hideFromParty = false } = props;
  const sameParty = relationship.fromPartyId === relationship.toPartyId;

  const handleLabelClick = () => {
    if (!linkToSidebar) return;
    const { uiActions } = props;
    uiActions.showSidebar({
      sidebarType: SIDEBAR_COMPONENTS.RELATIONSHIP_INFO_SIDEBAR,
      sidebarProps: {
        relationshipId: relationship.id,
        partyId: relationship.fromPartyId,
      },
      sidebarConfig: { width: 'wide' },
    });
  };
  return (
    <div className={`relationship-label`}>
      <Header size="small">
        {relationship.label}
        <Label>{relationship.relationshipType.label}</Label>

        {linkToSidebar && (
          <Icon
            link
            name="info circle"
            color="black"
            onClick={handleLabelClick}
          />
        )}
      </Header>
      {!hideFromParty && (
        <PartyLabel party={relationship.fromParty} clickable={false} />
      )}{' '}
      {!sameParty && relationship.toParty && (
        <React.Fragment>
          {!hideFromParty && <Icon name="long arrow alternate right" />}{' '}
          <PartyLabel party={relationship.toParty} clickable={false} />
        </React.Fragment>
      )}
    </div>
  );
};

RelationshipLabel.propTypes = {
  relationship: PropTypes.object,
  uiActions: PropTypes.object.isRequired,
  linkToSidebar: PropTypes.bool,
  hideFromParty: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(RelationshipLabel);
