import { get } from 'lodash';

export type MergeCellProps<T, K extends keyof T> = {
  span: { [key in K]: number };
  class: { [key in K]: string };
};

function createInitialMergeCellProps<K extends PropertyKey>(
  mergeColumns: K[]
): MergeCellProps<Record<K, unknown>, K> {
  return mergeColumns.reduce(
    (acc, col) => {
      acc.span[col] = 1;
      acc.class[col] = '';
      return acc;
    },
    { span: {}, class: {} } as MergeCellProps<Record<K, unknown>, K>
  );
}

export function mergeCells<T extends object, K extends keyof T>(
  data: T[],
  mergeColumns: K[]
): Array<T & MergeCellProps<T, K>> {
  const currentValues = mergeColumns.reduce((acc, col) => {
    acc[col] = null;
    return acc;
  }, {} as Record<K, T[K]>);

  const currentSpans = mergeColumns.reduce((acc, col) => {
    acc[col] = 0;
    return acc;
  }, {} as Record<K, number>);

  const previousRows = {} as Record<K, T & MergeCellProps<T, K>>;

  const rows: Array<T & MergeCellProps<T, K>> = [];

  for (const row of data) {
    const { span, class: className } = createInitialMergeCellProps(
      mergeColumns
    );

    for (const col of mergeColumns) {
      if (row[col] === currentValues[col]) {
        const previousRow = previousRows[col];
        if (previousRow) {
          previousRow.span[col]++;
          previousRow.class[col] = 'merged';
        }
        currentSpans[col]++;
        span[col] = 0;
        className[col] = 'merged';
      } else {
        currentValues[col] = row[col];
        currentSpans[col] = 1;
        previousRows[col] = { ...row, span, class: className };
      }
    }

    rows.push({ ...row, span, class: className });
  }

  return rows;
}
