import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import toastr from 'toastr';

import Permission from './Permission';
import * as uiActions from '../../actions/uiActions';
import AddUserPermission from './AddUserPermission';
import { PermissionContextConsumer } from '../../context/PermissionContext';

class PermissionModal extends Component {
  state = {
    currentUser: {},
    currentUserRoleId: null,
    loading: false,
  };

  searchHandler(event, data) {
    this.setState({ currentUser: data.value });
  }

  dropdownHandler(event, data) {
    this.setState({ currentUserRoleId: data.value });
  }

  addUser() {
    const { id, uiActions, currentNodeId, loadPermission, addUser } =
      this.props;

    this.setState({ loading: true });

    addUser(id, this.state.currentUser.id, id, this.state.currentUserRoleId)
      .then(() => {
        toastr.success('User was added');
        loadPermission(currentNodeId, null, 'CURRENT');
        this.setState({ loading: false });
        uiActions.closeModal();
      })
      .catch((err) => {
        toastr.error(err);
        this.setState({ loading: false });
      });
  }

  render() {
    const { name } = this.props;
    const { closeModal } = this.props.uiActions;
    return (
      <div>
        <Modal size="small" open={true} onClose={() => closeModal()}>
          <Modal.Header>Add User or User Group to {name}</Modal.Header>
          <Modal.Content>
            <AddUserPermission structuralNodeId={this.props.id} />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => closeModal()}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

PermissionModal.propTypes = {
  uiActions: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  currentNodeId: PropTypes.string.isRequired,
  roleDefinitions: PropTypes.array.isRequired,
  addUser: PropTypes.func.isRequired,
  loadPermission: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
  };
};

export default Permission(
  PermissionContextConsumer(connect(null, mapDispatchToProps)(PermissionModal))
);
