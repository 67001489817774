import React from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Button, Segment, Header, Icon, Message } from 'semantic-ui-react';

import UploadProgressList from './UploadProgressList';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';
import AttachmentDrop from './AttachmentDrop';

const AddAttachmentModalUpload = (props) => {
  const uploadNewAttachment = () => {
    const { openFileDialog } = props;
    openFileDialog();
  };
  const {
    files,
    uploading,
    dropzoneActive,
    closeUploadList,
    attachmentsConstants,
    attachmentStateActions,
    savedFiles,
  } = props;
  const filesAreUploaded = _.every(files, (file) => file.uploaded);

  return (
    <React.Fragment>
      {uploading ? (
        <React.Fragment>
          {filesAreUploaded && (
            <Message success content="Uploaded Successfully" />
          )}
          <UploadProgressList
            files={files}
            visible={uploading}
            Wrapper={Segment}
            className="upload-attachments-progress-list"
            attachmentsConstants={attachmentsConstants}
            attachmentStateActions={attachmentStateActions}
            savedFiles={savedFiles}
          />
          <Button onClick={() => closeUploadList()}>Close upload list</Button>
        </React.Fragment>
      ) : (
        <Segment className="upload-attachments-segment">
          <Segment className="placeholder">
            <Header as="h4" icon>
              <Icon
                name={`
              ${dropzoneActive ? 'cloud upload' : 'file outline'}`}
              />
              {dropzoneActive
                ? 'Drop your files to upload'
                : 'Drag and drop a file or'}
            </Header>
            {!dropzoneActive && (
              <Button onClick={uploadNewAttachment}>Upload a file</Button>
            )}
          </Segment>
        </Segment>
      )}
    </React.Fragment>
  );
};

AddAttachmentModalUpload.propTypes = {
  uploadNewAttachment: PropTypes.func,
  openFileDialog: PropTypes.func,
  files: PropTypes.array,
  uploading: PropTypes.bool,
  dropzoneActive: PropTypes.bool,
  closeUploadList: PropTypes.func,
  attachmentsConstants: PropTypes.object,
  attachmentStateActions: PropTypes.object,
  savedFiles: PropTypes.array,
};

function mapAttachmentDrop(props) {
  const {
    currentModel,
    modelStateActions,
    modelType,
    maxFileSize,
    currentUser,
  } = props;
  return {
    model: {
      ...currentModel,
      modelStateActions,
    },
    modelType,
    maxFileSize,
    currentUser,
  };
}

function uploadSuccessCallback(props, files, currentUser) {
  props.modelStateActions.addAttachments(props, files, currentUser);
}

export default WrapAttachmentsContext(
  AttachmentDrop(
    mapAttachmentDrop,
    uploadSuccessCallback
  )(AddAttachmentModalUpload)
);
