import { Accordion, List, Menu } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ActivityListItem } from './ActivityListItem';
import { DraggableComponent } from '../../common/dragAndDrop/DraggableComponent';
import { ProgrammeField } from './ProgrammeFieldManager';
import { Activity } from '../../../types';

interface ActivityAccordionProps {
  activities: Activity[];
  onActivitySelect: (activityIndex: number) => void;
  selectedActivityIndex: number;
}

const ActivityList = ({
  activities,
  onActivitySelect,
  selectedActivityIndex,
}: ActivityAccordionProps) => {
  //   <Droppable droppableId="activity-list" type="activity">
  // {(provided) => (
  //   <div ref={provided.innerRef} {...provided.droppableProps}>
  return (
    <Menu vertical fluid size="mini">
      {activities.map((activity, index) => (
        <ActivityListItem
          selected={index === selectedActivityIndex}
          key={index}
          activity={activity}
          id={activity.id}
          index={index}
          onClick={() => onActivitySelect(index)}
        />
      ))}
    </Menu>
  );
  // {provided.placeholder}
  //   </div>
  // )}
  //   </Droppable>
};

export default ActivityList;
