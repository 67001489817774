import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import toastr from 'toastr';
import * as _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';

import PrincipalSearch from '../common/PrincipalSearch';
import PrincipalLabel from '../common/PrincipalLabel';
import * as uiActions from '../../actions/uiActions';
import * as ticketActions from '../../actions/ticketActions';

class AssignTicketSetModal extends Component {
  state = {
    currentPrincipal: null,
    saving: false,
    showAssignment: false,
  };

  handleChange = (event, data) => {
    const { value } = data;
    // Do not search if chosen principal hasn't changed
    if (
      this.state.currentPrincipal &&
      value &&
      this.state.currentPrincipal.id === value.id
    ) {
      this.setState({ showAssignment: false });
      return;
    }

    this.setState(
      {
        saving: false,
        showAssignment: false,
        currentPrincipal: value,
      },
      () => {
        this.handleClose();
      }
    );
  };

  handleClose = _.debounce(() => {
    if (this.state.saving) return;
    this.setState({ showAssignment: false });
  });

  labelClicked = () => {
    this.setState({ showAssignment: true });
  };

  assignClicked = () => {
    const { currentPrincipal } = this.state;
    const { assignObject, ticketActions, uiActions } = this.props;
    this.setState({ saving: true });
    ticketActions
      .bulkAssign(assignObject.tickets, currentPrincipal)
      .then(() => {
        toastr.success('Tickets assignment successful');
        this.setState({
          saving: false,
        });
      })
      .catch((error) => {
        toastr.error(error);
        this.setState({ saving: false });
        throw error;
      })
      .finally(() => {
        uiActions.closeModal();
      });
  };

  render() {
    const { currentPrincipal, saving, showAssignment } = this.state;
    const { uiActions, assignObject, isTicketSet } = this.props;
    const { label } = assignObject;

    return (
      <Modal
        open={true}
        onClose={() => uiActions.closeModal()}
        size="mini"
        closeOnDimmerClick={false}
      >
        <Dimmer active={saving} inverted>
          <Loader disabled={!saving} />
        </Dimmer>
        <Modal.Header>
          {`Assign ${isTicketSet ? 'Ticket Set' : 'Category'} - ${label}`}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Select Assignee:</label>
              <Form.Input>
                {showAssignment ? (
                  <PrincipalSearch
                    onChange={this.handleChange}
                    onClose={this.handleClose}
                    saving={saving}
                    showUnassigned={false}
                    addUnassignValue={true}
                  />
                ) : (
                  <PrincipalLabel
                    principal={currentPrincipal}
                    onClick={this.labelClicked}
                  />
                )}
              </Form.Input>
            </Form.Field>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button positive onClick={this.assignClicked}>
            Assign
          </Button>
          <Button secondary onClick={() => uiActions.closeModal()}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AssignTicketSetModal.propTypes = {
  uiActions: PropTypes.object,
  assignObject: PropTypes.object,
  ticketActions: PropTypes.object,
  isTicketSet: PropTypes.bool,
  category: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    party: state.party,
    parties: state.parties.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignTicketSetModal);
