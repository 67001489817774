import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, Header, Menu } from 'semantic-ui-react';

import Permission from '../permissions/Permission';
import { TicketContextConsumer } from '../../context/TicketContext';
import './TicketSetActions.scss';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';
import EntityLabel from '../common/labels/EntityLabel';
import { getFeatures } from '../../utils/Features';
const { FOLLOW_UP } = getFeatures();

const TicketSetActions = (props) => {
  const {
    ticketSet,
    ticketActions,
    ticketStateActions,
    permissionActions,
    currentExecution,
    label,
  } = props;
  const assignAll = () => {
    ticketActions.openAssignTicketsModal(ticketSet);
  };
  const handleManagePermissionsClick = () => {
    const { label, id } = ticketSet.permissionsNode;
    permissionActions.openManagePermissionsModal(id, label);
  };
  const followUpAll = () => {
    const idsToFollowUp = ticketSet.tickets.map((ticket) => ticket.id);
    ticketStateActions.addTicketsToFollowUpList(idsToFollowUp);
  };
  return (
    <React.Fragment>
      <Menu size="tiny" secondary>
        <Menu.Item fitted className="ticket-set-header">
          <Header size="large" as="h2">
            {label}
          </Header>

          <div className="ticket-set-header-entity-labels">
            {ticketSet.entities &&
              ticketSet.entities.length > 0 &&
              ticketSet.entities.map((entity) => {
                return (
                  <div>
                    <EntityLabel entity={entity} size="medium" />
                  </div>
                );
              })}
          </div>
        </Menu.Item>
        <Menu.Menu position="right">
          <Dropdown item icon="ellipsis horizontal">
            <Dropdown.Menu>
              {FOLLOW_UP && (
                <Dropdown.Item onClick={followUpAll}>
                  Follow up all
                </Dropdown.Item>
              )}
              <CheckPermission
                permissionType={permissionTypes.CREATE}
                structuralNodeId={ticketSet.structuralNodeId}
              >
                <Dropdown.Item onClick={assignAll}>Assign all</Dropdown.Item>
              </CheckPermission>
              {(currentExecution.granularPermissionTypeId === 'TICKETSET' ||
                currentExecution.granularPermissionTypeId === 'RELATION') && (
                <Dropdown.Item onClick={handleManagePermissionsClick}>
                  View Permissions
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </React.Fragment>
  );
};

TicketSetActions.propTypes = {
  currentExecution: PropTypes.object,
  ticketSet: PropTypes.object,
  ticketActions: PropTypes.object,
  permissionActions: PropTypes.object,
  ticketStateActions: PropTypes.object,
  label: PropTypes.string,
};

export default Permission(TicketContextConsumer(TicketSetActions));
