import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

class EditableElement extends Component {
  state = {
    showForm: false,
  };

  showForm = () => {
    this.setState({ showForm: true });
  };

  handleSubmitForm = (event, data) => {
    this.setState({ showForm: false });
    this.props.onSubmit(event, data);
  };

  render() {
    const { element, value, name } = this.props;
    const Form = this.props.form;
    const { showForm } = this.state;
    const newElement = React.cloneElement(element, { onClick: this.showForm });
    return (
      <React.Fragment>
        {showForm ? (
          <React.Fragment>
            <Form
              onSubmit={this.handleSubmitForm}
              value={value}
              name={name}
              focusOnRender={true}
              submitOnBlur={true}
              size="large"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>{newElement}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

EditableElement.propTypes = {
  element: PropTypes.element,
  form: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default EditableElement;
