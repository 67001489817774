import React from 'react';
import * as PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { PartyWrapContext } from '../../context/PartyContext';
import UserGroupListItem from './UserGroupListItem';
import SortableHeaderCell from '../common/SortableHeaderCell';
import { getActiveItems, getUndefaultUserGroups } from '../../utils/PartyUtils';
import ConfirmWrapper from './../common/ConfirmWrapper';
import * as permissionTypes from '../../constants/permissionTypes';
import UserWrapper from '../user/User';

const UserGroupsList = (props) => {
  const { party, handleSortChange, paginationOptions, currentUser } = props;
  const { userGroups } = party;
  const {
    openAddUserToUserGroupModal,
    openDeleteUserModal,
    openSaveUserGroupModal,
  } = props.partyActions;
  const { showConfirmDialog } = props.confirmPopupActions;
  const { deleteUserGroup } = props.partyStateActions;
  const isAdmin = currentUser.checkPermissionForNode(
    party.structuralNodeId,
    permissionTypes.ADMIN
  );
  const renderUserGroups = () => {
    return (
      userGroups &&
      getUndefaultUserGroups(getActiveItems(userGroups)).map((userGroup) => (
        <UserGroupListItem
          userGroup={userGroup}
          addUser={openAddUserToUserGroupModal}
          deleteUser={openDeleteUserModal}
          deleteUserGroup={deleteUserGroup}
          editUserGroup={openSaveUserGroupModal}
          showConfirmDialog={showConfirmDialog}
          key={userGroup.id}
          allowAdmin={isAdmin}
        />
      ))
    );
  };
  return (
    <Table celled sortable>
      <Table.Header>
        <Table.Row>
          <SortableHeaderCell
            {...paginationOptions}
            name="label"
            handleSortChange={handleSortChange}
          >
            Group Label
          </SortableHeaderCell>
          <SortableHeaderCell
            {...paginationOptions}
            name="Users List"
            handleSortChange={handleSortChange}
          >
            Users
          </SortableHeaderCell>
          {isAdmin && <Table.HeaderCell>Actions</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderUserGroups()}</Table.Body>
    </Table>
  );
};

UserGroupsList.propTypes = {
  party: PropTypes.object,
  compact: PropTypes.bool,
  handleSortChange: PropTypes.func,
  paginationOptions: PropTypes.object,
  partyActions: PropTypes.object,
  partyStateActions: PropTypes.object,
  confirmPopupActions: PropTypes.object,
  currentUser: PropTypes.object,
};

export default UserWrapper(PartyWrapContext(ConfirmWrapper(UserGroupsList)));
