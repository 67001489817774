import { orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Header,
  List,
  Message,
  Progress,
  Segment,
  Select,
} from 'semantic-ui-react';
import { loadEntityType } from '../../../actions/entityActions';
import { loadExecutionPlans } from '../../../actions/executionPlanActions';
import { useAppSelector } from '../../../actions/store';
import entityAPI, { CreateEntityData } from '../../../api/entityApi';
import { Entity, EntityEntry } from '../../../types';
import QueueDetails from '../../queue/QueueDetails';
import DateField from '../../common/fields/Date';

interface Props {
  type: string;
  partyId: string;
}

const BulkImportEntity = ({ type, partyId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queueId = useRef<string>(null);
  const [queueIdState, setQueueIdState] = useState<string | null>(null);

  const entityType = useAppSelector(
    (state) => state.entity.entityTypes.byId[type.toUpperCase()]
  );

  const executionPlans = useAppSelector((state) => state.executionPlans);
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const [results, setResults] = useState<
    { entity: Entity; executionId?: number }[]
  >([]);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [launchExecution, setLaunchExecution] = useState(true);
  const [executionPlanId, setExecutionPlanId] = useState<number | null>(null);
  const [dueDate, setDueDate] = useState<string>(new Date().toISOString());

  const sortedFields = orderBy(entityType?.fields, 'sort');

  useEffect(() => {
    if (!executionPlans || executionPlans.length === 0) {
      dispatch(loadExecutionPlans());
    }

    if (entityType) return;
    dispatch(loadEntityType(type));
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      entityType.label +
        ' Label\t' +
        sortedFields.map((field) => field.label).join('\t')
    );
    // toastr.success('Column headers copied to clipboard');
    setShowCopyMessage(true);
  };

  if (!entityType) return null;

  const executionPlanOptions = executionPlans.map((executionPlan) => ({
    key: executionPlan.id,
    value: executionPlan.id,
    text: `${executionPlan.label} - ${executionPlan.ownerParty?.label}`,
  }));

  return (
    <div>
      <Segment color="white" info>
        <Header size="large">Bulk import {entityType.label}s</Header>
        <Header>
          In order to import a list of {entityType.label}s, create a CSV files
          with the following columns to upload:
        </Header>
        <List bulleted>
          <List.Item>{entityType.label} Label (required)</List.Item>
          {sortedFields.map((field) => (
            <List.Item key={field.id}>
              {field.label}

              {field.options?.values &&
                !Array.isArray(field.options) &&
                !field.options?.directImport && (
                  <>
                    &nbsp; -{' '}
                    <strong>
                      Supply a comma delimited list of numbers to represent:{' '}
                    </strong>
                    <List.List>
                      {field.options?.values.map((v, index) => (
                        <List.Item key={index}>
                          {v} (Use {index})
                        </List.Item>
                      ))}
                    </List.List>
                  </>
                )}
            </List.Item>
          ))}
        </List>

        <p>
          <Button
            primary
            size="tiny"
            onClick={handleCopyToClipboard}
            icon="copy"
            content="Copy Headers to Clipboard"
          />
        </p>

        {showCopyMessage && (
          <Message
            info
            onDismiss={() => setShowCopyMessage(false)}
            header="Copied to clipboard"
            content="You can now paste the headers into a spreadsheet."
          />
        )}

        <Divider />

        <Form>
          <Form.Field>
            <Form.Field>
              <Checkbox
                label={`Launch Programme when created`}
                name="launchExecution"
                onChange={() => setLaunchExecution(!launchExecution)}
                checked={launchExecution}
              />
            </Form.Field>
          </Form.Field>

          {launchExecution && (
            <Form.Group>
              <Form.Field
                width={12}
                control={Select}
                name="executionPlanId"
                options={executionPlanOptions}
                label="Select Execution Plan"
                value={executionPlanId}
                required={true}
                onChange={(e, { value }) => setExecutionPlanId(value as number)}
              ></Form.Field>
              <Form.Field
                width={4}
                control={DateField}
                label="Due Date"
                value={dueDate}
                onChange={(e, { value }) => setDueDate(value)}
                name="dueDate"
              ></Form.Field>
            </Form.Group>
          )}
        </Form>
      </Segment>

      {isUploading && (
        <Segment>
          <Progress percent={completionPercentage} active>
            Processing...
          </Progress>
        </Segment>
      )}

      {uploadComplete && (
        <Message
          info
          header={`${entityType.label} creation complete`}
          // TODO: show uploaded entities
          // content={<List>
          //     {results.map(result => <List.Item>
          //         {result.executionId ? <Link to={`/execution/${result.executionId}`}>{result.entity.label}</Link> : result.entity.label}
          //     </List.Item>)}
          // </List>}
        />
      )}

      {!uploadComplete &&
        ((executionPlanId && launchExecution) || !launchExecution) && (
          <Importer<{ label: string }>
            onStart={(info) => console.log(info)}
            onComplete={(result) => {
              setUploadComplete(true);

              if (!queueId.current) return;
              history.push(`/queue/${queueId.current}`);
            }}
            dataHandler={async (rows) => {
              // const currentResults: typeof results = [];

              const data: CreateEntityData[] = rows.map((row) => {
                const entityEntries = Object.keys(row).reduce<
                  Pick<EntityEntry, 'entityTypeFieldId' | 'value'>[]
                >((accum, current) => {
                  const field = sortedFields.find(
                    (field) => field.id === current.toUpperCase()
                  );

                  if (!field) return accum;
                  let value = row[current];
                  if (['CheckboxGroup'].includes(field.activityFieldType)) {
                    value = value
                      .split(',')
                      .map((inx) => field.options.values[parseInt(inx)])
                      .join(',');
                  }

                  return [...accum, { entityTypeFieldId: current, value }];
                }, []);

                return {
                  entity: {
                    label: row.label,
                    entityTypeId: type.toUpperCase(),
                  },
                  entityEntries,
                  tasks: executionPlanId
                    ? [
                        {
                          executionPlanId: executionPlanId,
                          type: 'LAUNCH_EXECUTION',
                          dueDate: dueDate,
                        },
                      ]
                    : [],
                };
              });

              const result = await entityAPI.bulkQueueCreateEntities(partyId, {
                rows: data,
                queueId: queueId.current,
              });

              queueId.current = result.queueId;
              setQueueIdState(result.queueId);
            }}
          >
            <ImporterField name="label" label={entityType.label + ' Label'} />
            {entityType.fields.map((field) => (
              <ImporterField
                key={field.id}
                name={field.id}
                label={field.label}
                optional
              />
            ))}
          </Importer>
        )}
      {queueIdState && <QueueDetails queueId={queueIdState} />}
    </div>
  );
};

export default BulkImportEntity;
