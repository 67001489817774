import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import PartyWrapper from './Party';
import FilterAndPaginate from '../common/FilterAndPaginate';
import ThresholdsList from './ThresholdsList';
import Pagination from '../common/Pagination';

class PartyThresholdsList extends Component {
  static propTypes = {
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    partyStateActions: PropTypes.object,
    userStateActions: PropTypes.object,
  };

  render() {
    const {
      paginatedItems,
      handleSortChange,
      handlePaginationChange,
      paginationOptions,
      partyStateActions,
    } = this.props;
    return (
      <React.Fragment>
        <Header as="h2">Risk appetite</Header>
        <ThresholdsList
          riskThresholds={paginatedItems || []}
          paginationOptions={paginationOptions}
          handleSortChange={handleSortChange}
          partyStateActions={partyStateActions}
        />
        <Pagination
          activePage={paginationOptions.activePage}
          onPageChange={handlePaginationChange}
          totalPages={paginationOptions.totalPages}
          style={{ float: 'right' }}
        />
      </React.Fragment>
    );
  }
}

function mapOptions(props) {
  return {
    items: props.party.riskThresholds,
  };
}

export default PartyWrapper(FilterAndPaginate(mapOptions)(PartyThresholdsList));
