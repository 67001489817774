import React from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Header, Label, Segment } from 'semantic-ui-react';
import FormFields from '../common/FormFields';

import './PartyRelationshipInfo.scss';
import PartyLabel from '../common/labels/PartyLabel';

const PartyRelationshipInfo = (props) => {
  const { relationship } = props;

  const {
    label,
    relationshipType,
    partyRelationships,
    entries,
    fromParty,
    toParty,
  } = relationship;

  const entriesSorted = _.orderBy(entries, 'field.sort');

  return (
    <div className="party-relationship-info">
      <Header attached="top" inverted>
        {label} <Label>{relationshipType && relationshipType.label}</Label>
      </Header>
      <Segment attached>
        <div className="equal  fields">
          <div className="form-field ">
            <h4 className="ui header">Entity Owner</h4>
            <PartyLabel party={fromParty} />
          </div>
          {toParty && (
            <div className="form-field ">
              <h4 className="ui header">Entity Relates To</h4>
              <PartyLabel party={toParty} />
            </div>
          )}
        </div>

        {entries && (
          <FormFields
            fields={entriesSorted.map((entry) => entry.field)}
            onChange={() => {}}
            entries={entriesSorted}
            fieldIdKey="partyRelationshipTypeFieldId"
            fieldsPerRow={2}
            editable={false}
          />
        )}

        {partyRelationships && partyRelationships.length > 0 && (
          <React.Fragment>
            <Header>Sub Entities</Header>
            {partyRelationships.map((relationship) => (
              <PartyRelationshipInfo
                key={relationship.id}
                relationship={relationship}
              />
            ))}
          </React.Fragment>
        )}
      </Segment>
    </div>
  );
};

PartyRelationshipInfo.propTypes = {
  relationship: PropTypes.object,
};

export default PartyRelationshipInfo;
