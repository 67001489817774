import React, { ReactNode } from 'react';

import UserWrapper from '../user/User';

export interface CurrentUser {
  hasPermission: (nodeType: string, permissionType: string) => boolean;
  checkPermissionForNode: (
    structuralNodeId: number,
    permissionType: string
  ) => boolean;
  // define other methods or properties here
}

interface CheckPermissionProps {
  children: ReactNode;
  currentUser: CurrentUser;
  structuralNodeId?: number;
  permissionType?: string;
  nodeType?: string;
  override?: boolean;
}

const CheckPermission = ({
  currentUser,
  structuralNodeId,
  permissionType,
  children,
  nodeType,
  override,
}: CheckPermissionProps) => {
  if (override) return <>{children}</>;
  if (!permissionType) return null;

  const userHasPermission = nodeType
    ? currentUser.hasPermission(nodeType, permissionType)
    : currentUser.checkPermissionForNode(structuralNodeId!, permissionType);

  if (userHasPermission) return <>{children}</>;
  return null;
};

export default UserWrapper(CheckPermission);
