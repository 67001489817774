import React from 'react';
import * as PropTypes from 'prop-types';
import { withScriptjs } from 'react-google-maps';
import * as _ from 'lodash';
import { Input, Form } from 'semantic-ui-react';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';

const SearchBox = withScriptjs((props) => {
  let searchBox;
  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    const place = _.head(places);
    props.onPlacesChanged(place);
  };
  return (
    <Form className="google-standalone-search-box">
      <Form.Field>
        <StandaloneSearchBox
          ref={(el) => (searchBox = el)}
          bounds={props.bounds}
          onPlacesChanged={onPlacesChanged}
          autoComplete="new-password"
        >
          <Input fluid placeholder="Type the address" name="line1" />
        </StandaloneSearchBox>
      </Form.Field>
    </Form>
  );
});

SearchBox.propTypes = {
  onPlacesChanged: PropTypes.func,
};

export default SearchBox;
