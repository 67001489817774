import { Icon } from 'semantic-ui-react';
import { Result, ResultValue } from '../../../../api/statusApi';
import { getStatusFlags } from '../../../../utils/data/statusUtils';

type Props = {
  status: ResultValue;
};
export const RAGIcon = ({ status }: Props) => {
  if (!status) return null;
  if (typeof status === 'object' && 'error' in status && status?.error)
    return <Icon color="red" name="exclamation triangle" size="large" />;

  if (status === 'EXCEPTION')
    return <Icon color="red" name="delete" size="large" />;
  if (status === 'CAUTION')
    return <Icon color="orange" name="checkmark" size="large" />;
  if (status === 'POSITIVE')
    return <Icon color="green" name="checkmark" size="large" />;
  if (status === 'ERROR')
    return <Icon color="purple" name="delete" size="large" />;
  return <Icon color="grey" name="minus" size="large" />;
};
