import React, { Component } from 'react';
import WizardContext from '../../context/WizardContext';
import * as _ from 'lodash';
import update from 'immutability-helper';
import * as PropTypes from 'prop-types';

function WizardWrapper(ComponentToWrap) {
  class Wizard extends Component {
    constructor(props) {
      super(props);

      const { initialChoices } = props;

      this.state = {
        steps: props.steps,
        wizardChoices: initialChoices,
      };
    }
    stepClicked = (step) => {
      this.moveToStep(step);
    };
    moveToStep = (stepName) => {
      const { steps } = this.state;
      const currentStepName = _.findKey(steps, { current: true });
      const newSteps = update(steps, {
        [currentStepName]: {
          current: { $set: false },
        },
        [stepName]: {
          current: { $set: true },
        },
      });
      this.setState({ steps: newSteps });
    };

    stepForward = (currentStepComplete = true) => {
      const { steps } = this.state;
      const currentStepName = _.findKey(steps, { current: true });
      const currentStep = steps[currentStepName];
      let nextStepNumber = currentStep.stepNumber + 1;
      let nextStepName = _.findKey(steps, { stepNumber: nextStepNumber });

      const newSteps = update(this.state.steps, {
        [currentStepName]: {
          current: { $set: false },
          completed: { $set: currentStepComplete },
        },
        [nextStepName]: {
          current: { $set: true },
        },
      });
      this.setState({ steps: newSteps });
    };

    setWizardChoices = (newWizardChoices) => {
      this.setState({ wizardChoices: newWizardChoices });
    };

    handleSubmit = () => {
      const { submitFunction } = this.props;
      const { wizardChoices } = this.state;
      this.setState({ saving: true });

      submitFunction(wizardChoices).finally(() => {
        this.setState({ saving: false });
      });
    };

    render() {
      const { steps, wizardChoices, saving } = this.state;
      const currentStep = _.findKey(steps, { current: true });
      const {
        stepClicked,
        moveToStep,
        stepForward,
        setWizardChoices,
        handleSubmit,
      } = this;
      const values = {
        steps,
        stepClicked,
        moveToStep,
        stepForward,
        currentStep,
        wizardChoices,
        setWizardChoices,
        handleSubmit,
        saving,
      };
      return (
        <WizardContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </WizardContext.Provider>
      );
    }
  }

  Wizard.propTypes = {
    initialChoices: PropTypes.object,
    steps: PropTypes.object,
    submitFunction: PropTypes.func,
  };

  return Wizard;
}

export default WizardWrapper;
