import * as _ from 'lodash';
import { ticketsPerExecutionPageCall } from '../constants/config';
import ticketApi from '../api/ticketApi';
import * as types from '../constants/actionTypes';

export function getPrincipals(tickets) {
  return _.chain(tickets)
    .filter((ticket) => !!ticket.assignedPrincipalId)
    .map((ticket) =>
      Object.assign(
        {},
        ticket.assignedPrincipal.user,
        ticket.assignedPrincipal.userGroup,
        ticket.assignedPrincipal
      )
    )
    .uniqBy('id')
    .orderBy('user.lastName')
    .flatten()
    .value();
}

export function getStatusResolutions(tickets, resolutions) {
  let statusResolutions = [];

  _.forEach(tickets, (ticket) => {
    _.forEach(ticket.statusResolutions, (statusResolution) => {
      statusResolutions.push(statusResolution.resolution);
    });
  });

  statusResolutions = _.chain(statusResolutions)
    .filter((statusResolution) => {
      if (!resolutions) return true;
      return _.find(resolutions, { id: statusResolution.id });
    })
    .uniqBy('id')
    .orderBy('statusId')
    .value();

  return statusResolutions;
}

export function getValuesFromTickets(tickets, key) {
  return (
    _.chain(tickets)
      .filter((ticket) => !!_.get(ticket, key))
      .map((ticket) => _.get(ticket, key))
      .uniqBy('id')
      // .orderBy('label')
      .flatten()
      .value()
  );
}

export function filterTickets(tickets, filters) {
  let filteredTickets = tickets;

  window._ = _;
  window.filters = filters;

  _.forEach(filters, (value, key) => {
    if (!value || value.length === 0) return;

    switch (key) {
      case 'statusResolutions': {
        filteredTickets = filteredTickets.filter((ticket) => {
          let r = false;

          _.forEach(ticket.statusResolutions, (statusResolution) => {
            if (_.includes(value, statusResolution.statusResolutionId))
              r = true;
          });

          return r;
        });
        break;
      }
      case 'severityLevels': {
        filteredTickets = filteredTickets.filter((ticket) => {
          let r = false;
          _.forEach(ticket.workflowEntries, (workflowEntry) => {
            if (
              workflowEntry.value.severity &&
              _.includes(value, workflowEntry.value.severity)
            )
              r = true;
          });

          return r;
        });
        break;
      }
      case 'complexityLevels': {
        filteredTickets = filteredTickets.filter((ticket) => {
          let r = false;
          _.forEach(ticket.workflowEntries, (workflowEntry) => {
            if (
              workflowEntry.value.complexity &&
              _.includes(value, workflowEntry.value.complexity)
            )
              r = true;
          });

          return r;
        });
        break;
      }
      default: {
        filteredTickets = filteredTickets.filter((ticket) => {
          return _.includes(value, _.get(ticket, key));
        });
      }
    }
  });

  return filteredTickets;
}

export function getOrderedTicketsFromTicketSets(ticketSets) {
  return _.flatten(
    _.map(ticketSets, (ticketSet) => {
      return _.chain(ticketSet.tickets)
        .sortBy('sort')
        .map((ticket) => ticket.id)
        .value();
    })
  );
}

function formTicketGroups(tickets) {
  return _.chunk(tickets, ticketsPerExecutionPageCall);
}

export function generateFilter(ticketIds) {
  return {
    where: { id: { inq: ticketIds } },
  };
}

export function performChainOfRequests(dispatch, tickets, isPreview) {
  return new Promise((resolve) => {
    let index = 0;
    const ticketGroups = formTicketGroups(tickets);
    function request() {
      const filter = generateFilter(ticketGroups[index]);
      if (index < ticketGroups.length) {
        return ticketApi.getTickets(filter).then((tickets) => {
          index++;
          dispatch({
            type: types.LOAD_TICKETS_SUCCESS,
            tickets,
            isPreview,
          });
          return request();
        });
      } else {
        resolve();
      }
      return;
    }
    request();
  });
}

export function getTicketsFromExectutionToLoad(ticketSets) {
  const ticketsList = _.map(ticketSets, (ticketSet) => ticketSet.tickets);
  return _.flatten(ticketsList);
}
