import React from 'react';
import * as PropTypes from 'prop-types';
import { Label, Table } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';
import Moment from 'react-moment';
import * as permissionTypes from '../../constants/permissionTypes';
import CheckPermission from '../common/CheckPermission';

const ProgrammeTableRow = ({ programme, programmeActions }) => {
  return (
    <Table.Row>
      <Table.Cell singleLine>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            programmeActions.gotoProgramme(programme);
          }}
        >
          {programme.label}
        </a>
      </Table.Cell>
      <Table.Cell>
        {programme.creator && (
          <Label as="a" color="grey" image>
            {programme.creator.firstName} {programme.creator.lastName}
          </Label>
        )}
      </Table.Cell>
      <Table.Cell>
        {programme.ownerParty && (
          <Label as="a" color="grey" image>
            {programme.ownerParty.label}
          </Label>
        )}
      </Table.Cell>
      <Table.Cell collapsing>
        <Label color={programme.status?.colourId}>
          {programme.status.label}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Moment>{programme.modifiedAt}</Moment>
      </Table.Cell>
      <Table.Cell>
        <CheckPermission
          permissionType={permissionTypes.CREATE}
          structuralNodeId={programme.structuralNodeId}
        >
          <ActionButtons
            className="u-show-on-hover__target"
            editClicked={() => {
              programmeActions.editProgramme(programme);
            }}
            deleteClicked={() => {
              programmeActions.deleteProgramme(programme);
            }}
            copyClicked={() => {
              programmeActions.cloneProgramme(programme);
            }}
          />
        </CheckPermission>
      </Table.Cell>
    </Table.Row>
  );
};

ProgrammeTableRow.propTypes = {
  programme: PropTypes.object.isRequired,
  programmeActions: PropTypes.object.isRequired,
};

export default ProgrammeTableRow;
