import update from 'immutability-helper/index';
import _ from 'lodash';

import * as types from '../constants/actionTypes';
import UIDGenerator from '../utils/UIDGenerator';

export default function workflowsReducer(
  state = {
    list: [],
    byId: {},
    statusNodeById: {},
  },
  action
) {
  switch (action.type) {
    case types.LOAD_WORKFLOWS_SUCCESS:
      return Object.assign({}, state, {
        list: action.workflows,
      });
    case `${types.LOAD_WORKFLOW_SUCCESS}`: {
      const { workflow } = action;
      if (state.byId[workflow.id]) return state;
      let statusNodeById = { ...state.statusNodeById };
      workflow.statuses = workflow.statuses.map((status) =>
        Object.assign({}, status, {
          uid: UIDGenerator.get(),
          addResolution: status.resolutions && status.resolutions.length > 0,
        })
      );
      workflow.statusNodes = workflow.statusNodes.map((statusNode) => {
        const uid = UIDGenerator.get();
        if (workflow.startStatusNodeId === statusNode.id)
          workflow.startStatusNodeUid = uid;
        return Object.assign({}, statusNode, {
          uid,
          statusUid: _.find(workflow.statuses, { id: statusNode.statusId }).uid,
        });
      });
      workflow.statusNodes = workflow.statusNodes.map((statusNode) =>
        Object.assign({}, statusNode, {
          transitions: statusNode.transitions.map((transition) =>
            Object.assign({}, transition, {
              uid: UIDGenerator.get(),
              toStatusNodeUid: _.find(workflow.statusNodes, {
                id: transition.toStatusNodeId,
              }).uid,
            })
          ),
        })
      );
      _.forEach(workflow.statusNodes, (statusNode) => {
        const { statusId } = statusNode;
        const currentStatusNode = { ...statusNode };
        const currentStatus = workflow
          ? _.find(workflow.statuses, { id: statusId })
          : {};
        currentStatusNode.status = currentStatus;
        statusNodeById[statusNode.id] = currentStatusNode;
      });
      let newState = update(state, {
        byId: {
          [workflow.id]: { $set: workflow },
        },
        statusNodeById: { $set: statusNodeById },
      });

      return newState;
    }
    default:
      return state;
  }
}
