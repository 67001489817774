import { Icon } from 'semantic-ui-react';
import { ResultValue, ValueRendererMode } from '../../../../api/statusApi';
import { FieldTypeId } from '../../../../types';
import { formatValue } from '../../../../utils/FormatUtils';

interface ValueRendererProps {
  values: ResultValue[];
  mode?: ValueRendererMode;
  invertResult?: boolean;
  numberToRender?: number;
  type?: FieldTypeId;
}

export const ValueRenderer = ({
  values,
  mode = 'LATEST_VALUE',
  invertResult = false,
  numberToRender = 1,
  type = 'Number',
}: ValueRendererProps) => {
  const numbers = values.filter(
    (value) => typeof value === 'number'
  ) as number[];

  let content;

  switch (mode) {
    case 'COMPARE_LATEST_VALUE':
      if (numbers.length >= numberToRender + 1) {
        const latestNumbers = numbers.slice(-numberToRender - 1);
        const difference =
          latestNumbers[latestNumbers.length - 1] - latestNumbers[0];
        let icon, color;

        if (difference > 0) {
          icon = 'long arrow alternate up';
          color = invertResult ? 'red' : 'green';
        } else if (difference < 0) {
          icon = 'long arrow alternate down';
          color = invertResult ? 'green' : 'red';
        } else {
          icon = 'arrows alternate horizontal';
          color = 'grey';
        }

        content = (
          <div>
            <Icon size="large" name={icon} className={color} />
          </div>
        );
      } else {
        content = (
          <div>
            <Icon size="large" name="minus" className="grey" />
          </div>
        );
      }
      break;

    case 'TOTAL':
      const total = numbers.reduce((sum, value) => sum + value, 0);
      content = total;
      break;

    case 'AVERAGE':
      const average =
        numbers.length > 0
          ? numbers.reduce((sum, value) => sum + value, 0) / numbers.length
          : 0;
      content = average;
      break;

    case 'LATEST_VALUE':
    default:
      content = numbers
        .slice(-numberToRender)
        .map((value) => <div>{formatValue(value, type)}</div>);
  }

  return <>{content}</>;
};
