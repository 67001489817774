import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import PartiesWrapper from './Parties';
import FilterAndPaginate from '../common/FilterAndPaginate';
import PartiesList from './PartiesList';
import Pagination from '../common/Pagination';

class PartiesListWrapper extends Component {
  static propTypes = {
    paginationOptions: PropTypes.object,
    paginatedItems: PropTypes.array,
    handleSortChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    handleAddFilter: PropTypes.func,
    currentFilters: PropTypes.array,
    removeFilterValues: PropTypes.func,
  };

  render() {
    const {
      paginatedItems,
      handleSortChange,
      handlePaginationChange,
      paginationOptions,
      handleAddFilter,
      currentFilters,
      removeFilterValues,
    } = this.props;
    return (
      <React.Fragment>
        <PartiesList
          parties={paginatedItems || []}
          paginationOptions={paginationOptions}
          handleSortChange={handleSortChange}
          handleAddFilter={handleAddFilter}
          currentFilters={currentFilters}
          removeFilterValues={removeFilterValues}
        />
        <Pagination
          activePage={paginationOptions.activePage}
          onPageChange={handlePaginationChange}
          totalPages={paginationOptions.totalPages}
          style={{ float: 'right' }}
          handleAddFilter={handleAddFilter}
          currentFilters={currentFilters}
          removeFilterValues={removeFilterValues}
        />
      </React.Fragment>
    );
  }
}

function mapOptions(props) {
  return {
    items: props.parties,
  };
}

export default PartiesWrapper(
  FilterAndPaginate(mapOptions)(PartiesListWrapper)
);
