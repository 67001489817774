import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Label, Placeholder } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './PartyLabel.scss';
import { Party } from '../../../types';
import { useAppSelector } from '../../../actions/store';
import { loadParty } from '../../../actions/partyActions';

type PartyLabelProps = {
  party?: Pick<Party, 'id' | 'label' | 'status'>;
  partyId?: string;
  clickable?: boolean;
};

const PartyLabel: React.FC<PartyLabelProps> = ({
  party,
  partyId,
  clickable = true,
}) => {
  const dispatch = useDispatch();
  const partyFromState = useAppSelector((state) =>
    partyId ? state.parties?.byId[partyId] : undefined
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!party && partyId && !partyFromState) {
      setLoading(true);
      dispatch<any>(loadParty(partyId)).then(() => setLoading(false));
    }
  }, [dispatch, party, partyId, partyFromState]);

  const partyToRender = party || partyFromState;

  if (loading) {
    return (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  if (!partyToRender) return null;

  const renderLabel = () => (
    <Label color="grey" image>
      <Icon name="building" />
      {partyToRender.label} {party?.status === 'INACTIVE' && '(Inactive)'}
    </Label>
  );

  if (!clickable) return renderLabel();

  return (
    <Link to={`/party/${partyToRender.id}`} className="party-label">
      {renderLabel()}
    </Link>
  );
};

export default PartyLabel;
