import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import * as uiActions from '../../actions/uiActions';
import * as activityActions from '../../actions/activityActions';
import { extractFunctions } from '../../utils/React';
import ActivityContext from '../../context/ActivityContext';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

function ActivityWrapper(ComponentToWrap) {
  class Activity extends Component {
    viewActivity = (activity) => {
      this.props.history.push(`/question/${activity.id}`);
    };

    editActivity = (activity) => {
      const { uiActions } = this.props;
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.CREATE_ACTIVITY_MODAL,
        modalProps: {
          activityId: activity.id,
        },
      });
    };

    render() {
      const {
        currentActivity,
        activityStateActions,
        activityStatuses,
      } = this.props;

      const values = {
        currentActivity,
        activityStatuses,
        activityStateActions: activityStateActions,
        activityActions: extractFunctions(this),
      };
      return (
        <ActivityContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </ActivityContext.Provider>
      );
    }
  }

  Activity.propTypes = {
    activityStateActions: PropTypes.object.isRequired,
    currentActivity: PropTypes.object.isRequired,
    uiActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    activityStatuses: PropTypes.object,
  };

  function mapStateToProps(state) {
    return {
      activityStatuses: _.keyBy(state.constants.activityStatuses, 'id'),
      currentActivity: state.currentActivity,
      newActivity: state.newActivity,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      activityStateActions: bindActionCreators(activityActions, dispatch),
      uiActions: bindActionCreators(uiActions, dispatch),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Activity);
}

export default ActivityWrapper;
