import * as _ from 'lodash';
import React from 'react';
import { Header } from 'semantic-ui-react';

export function extractFunctions(collection) {
  return _.pickBy(collection, (object) => _.isFunction(object));
}

export function mapToOptions(
  array,
  textProperty = null,
  sortByText = false,
  valueProperty = 'id',
  labelModifier
) {
  let filteredArray = array;
  if (Array.isArray(filteredArray)) {
    filteredArray = array.filter(
      (item) => item && item?.programStatusId !== 'DEACTIVATED'
    );
  }

  const options = _.map(filteredArray, (item, index) => {
    let text = '';
    if (labelModifier) {
      text = labelModifier(item);
    } else {
      text = !textProperty
        ? item.label || item.name || item.reference || item.id || item
        : _.get(item, textProperty);
    }
    return {
      key: _.isNumber(item.id) ? item.id : item.code || item.id || index,
      text,
      value:
        _.isNumber(item[valueProperty]) || _.isString(item[valueProperty])
          ? item[valueProperty]
          : item.code || item,
    };
  });

  if (sortByText) return _.orderBy(options, 'text');

  return options;
}

export function mapRelationshipToOptions(relationships) {
  return relationships.map((item, index) => {
    if (!item)
      return {
        key: index,
        text: 'No assigned entity',
        value: -1,
      };
    return {
      key: item.id || index,
      text: `${item.label} - ${item.relationshipType.label} - ${item.toParty.label} `,
      value: item.id || item,
    };
  });
}

export function mapPrincipalsToOptions(principals, includeUnassigned) {
  if (!principals) return [];
  const optionsBase = includeUnassigned
    ? [
        {
          key: -1,
          text: 'Unassigned',
          value: null,
        },
      ]
    : [];
  return [
    ...optionsBase,
    ...principals.map((principal) => ({
      key: principal.id,
      text: `${principal.label} (${principal.partyLabel}) `,
      value: principal.id,
    })),
  ];
}

export function mapResolutionsToOptions(array) {
  return array.map((item, index) => {
    return {
      key: item.id || index,
      text: `${item.status.name} - ${
        item.labelOverride || item.resolutionType.label
      }`,
      value: item.id || item,
    };
  });
}

export function checkifEdited(
  newState,
  oldState,
  editedKey = 'edited',
  fieldsArray = null
) {
  if (newState.edited || _.isEqual(newState, oldState)) {
    return checkNewStateEdited(oldState, newState, editedKey, fieldsArray);
  } else {
    return Object.assign({}, newState, {
      [editedKey]: fieldsArray ? [fieldsArray] : true,
    });
  }
}

function checkNewStateEdited(oldState, newState, editedKey, fieldsArray) {
  if (newState.edited === true || !oldState.edited || !fieldsArray) {
    return newState;
  }
  if (!_.includes(oldState.edited, fieldsArray)) {
    return Object.assign({}, newState, {
      [editedKey]: [...oldState.edited, fieldsArray],
    });
  }
  return newState;
}

export function mapObjectArraysToObject(obj, keysMap) {
  return _.transform(obj, function (result, value, key) {
    // transform to a new object
    if (keysMap[key]) {
      const mapped = value.map((v) => mapObjectArraysToObject(v, keysMap));
      result[key] = _.keyBy(mapped, keysMap[key]);
    } else {
      result[key] = value;
    }
  });
}

export function mapThresholdsToOptions(thresholdList) {
  return _.map(thresholdList, (item) => {
    return {
      key: item.reference,
      value: item.reference,
      text: item.label,
      content: (
        <Header content={item.label} subheader={`${item.description}`} />
      ),
    };
  });
}
