import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as uiActions from '../../actions/uiActions';
import * as constantActions from '../../actions/constantActions';
import AdminContext from '../../context/AdminContext';
import { extractFunctions } from '../../utils/React';
import { MODAL_COMPONENTS } from '../common/ModalRoot';

function AdminWrapper(ComponentToWrap) {
  class Admin extends Component {
    openSaveAdminThresholdModal = (threshold = null) => {
      const { uiActions, constantStateActions } = this.props;
      uiActions.showModal({
        modalType: MODAL_COMPONENTS.SAVE_ADMIN_THRESHOLD_MODAL,
        modalProps: {
          threshold,
          constantStateActions,
        },
      });
    };

    render() {
      const values = {
        adminActions: extractFunctions(this),
      };
      return (
        <AdminContext.Provider value={values}>
          <ComponentToWrap {...values} {...this.props} />
        </AdminContext.Provider>
      );
    }
  }

  Admin.propTypes = {
    riskThresholds: PropTypes.array,
    constantStateActions: PropTypes.object,
    uiActions: PropTypes.object,
  };

  function mapStateToProps(state) {
    return {
      riskThresholds: state.constants.riskThresholds,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      constantStateActions: bindActionCreators(constantActions, dispatch),
      uiActions: bindActionCreators(uiActions, dispatch),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Admin);
}

export default AdminWrapper;
