/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Button, Checkbox, Container } from 'semantic-ui-react';

import { EVENT_LIST_LIMIT } from '../../../utils/EventConsts';
import eventApi from '../../../api/eventApi';
import EventList from './EventList';

class EventListContainer extends Component {
  state = {
    loading: true,
    noMoreEvents: false,
    events: {},
    oldestEventDate: moment.utc(),
    showHidden: false,
  };

  componentDidMount() {
    const { uploadOnDidMount = true } = this.props;
    if (uploadOnDidMount) {
      this.loadEvents(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { filterWhere } = this.props;
    const { filterWhere: filterWherePrevProps } = prevProps;
    const isFilterWhereNotEqual = !_.isEqual(filterWhere, filterWherePrevProps);
    if (isFilterWhereNotEqual) {
      this.setState({ oldestEventDate: moment.utc(), events: {} }, () => {
        this.loadEvents(true);
      });
    }
  }

  generateFilter = () => {
    const { filterWhere } = this.props;
    const { oldestEventDate } = this.state;
    const updatedOrValue = _.map(filterWhere.or, (orFilterValue) => {
      const andValue = { createdAt: { lt: moment(oldestEventDate) } };
      orFilterValue.and.push(andValue);
      return orFilterValue;
    });
    return {
      where: {
        or: updatedOrValue,
      },
    };
  };

  loadEvents = (isInit) => {
    this.setState({ loading: true });
    const { oldestEventDate, showHidden, events } = this.state;
    const { filterWhere = {}, filterWithOr = false } = this.props;
    const filter = filterWithOr
      ? this.generateFilter()
      : {
          where: {
            createdAt: { lt: moment(oldestEventDate) },
            ...filterWhere,
          },
        };

    eventApi.getEventsList(filter, showHidden).then((eventsList) => {
      if (eventsList.length === 0) {
        this.setState({ loading: false, noMoreEvents: true, events: {} });
        return;
      }
      const newEvents = isInit
        ? { eventsList }
        : { eventsList: [...events.eventsList, ...eventsList] };
      const oldestEventDate = _.last(eventsList).createdAt;
      const noMoreEvents = eventsList.length < EVENT_LIST_LIMIT;
      this.setState({
        events: newEvents,
        loading: false,
        oldestEventDate,
        noMoreEvents,
      });
    });
  };

  toggleShowHidden = () => {
    const { showHidden } = this.state;
    this.setState(
      { showHidden: !showHidden, oldestEventDate: moment.utc() },
      () => this.loadEvents(true, true)
    );
  };

  render() {
    const { loading, events, noMoreEvents, showHidden } = this.state;
    const { eventsList } = events;
    const { showLink, showHiddenButton = false } = this.props;
    return (
      <React.Fragment>
        {showHiddenButton && (
          <Container>
            <Checkbox
              label="Show hidden"
              onChange={this.toggleShowHidden}
              checked={showHidden}
              disabled={loading}
            />
          </Container>
        )}
        <EventList
          loading={_.isEmpty(events) && loading}
          events={events}
          size={eventsList && eventsList.length}
          showLink={showLink}
        />
        <div>
          {noMoreEvents ? (
            <p>No more events to load</p>
          ) : (
            <Button
              onClick={() => this.loadEvents(false)}
              loading={!_.isEmpty(events) && loading}
            >
              Load more
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

EventListContainer.propTypes = {
  showLink: PropTypes.bool,
  showHiddenButton: PropTypes.bool,
  filterWhere: PropTypes.object,
  filterWithOr: PropTypes.bool,
  uploadOnDidMount: PropTypes.bool,
};

export default EventListContainer;
