import React from 'react';
import * as PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import ActionButtons from '../common/ActionButtons';

const ActivitySetListItem = ({
  value,
  showActions,
  moveActivitySet,
  addClicked,
  removeClicked,
}) => {
  return (
    <List.Item>
      <List.Content floated="right">
        <ActionButtons
          addClicked={() => {
            addClicked(value);
          }}
          removeClicked={() => {
            removeClicked(value, -1);
          }}
          upClicked={() => {
            moveActivitySet(value, -1);
          }}
          downClicked={() => {
            moveActivitySet(value, 1);
          }}
          showActions={showActions}
        />
      </List.Content>
      <List.Content>
        <List.Header>{value.label}</List.Header>
        <List.Description>
          Activities in set: {value.activities.length}
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

ActivitySetListItem.propTypes = {
  value: PropTypes.object.isRequired,
  showActions: PropTypes.string,
  moveActivitySet: PropTypes.func,
  addClicked: PropTypes.func,
  removeClicked: PropTypes.func,
};

export default ActivitySetListItem;
