import _ from 'lodash';
import moment from 'moment';

import { WEEKDAYS, LAUNCH_TYPES } from '../utils/ExecutionPlanLaunchConsts';
import { executionPlanYearRange } from '../constants/config';
import update from 'immutability-helper';

export const getWeekdays = ({ daysOfWeek }) => {
  const res = _.map(
    _.filter(WEEKDAYS, (weekday) => {
      const { index } = weekday;
      return (daysOfWeek & index) === index;
    }),
    (obj) => obj.name
  );
  return res;
};

export const getLaunchType = (launchType) => {
  return launchType === LAUNCH_TYPES.EVERY ? 'Recurring' : 'One off';
};

export const getYearOptions = () => {
  const currentYear = moment().get('year');
  const minYear = currentYear - executionPlanYearRange;
  const maxYear = currentYear + executionPlanYearRange;
  const result = [];
  for (let i = minYear; i <= maxYear; i++) {
    result.push(i);
  }
  return result;
};

export const getAnalyseAndImplement = (workflow) => {
  let value = null;

  if (!workflow || !workflow.statuses) return null;
  for (const status of workflow.statuses) {
    if (status.fields && status.fields.length > 0) {
      for (const field of status.fields) {
        if (field.fieldType.name === 'AnalyseAndImplement') {
          value = field.options;
        }
      }
    }
  }
  return value;
};

export const checkIfNeedsReview = (workflow) => {
  if (!workflow) return false;
  return (
    _.filter(workflow.statuses, (s) => s.statusType.id === 'APPROVAL').length >
    0
  );
};

export const checkIfAnalyseAndImplement = (workflow) => {
  if (!workflow.id) return false;

  return (
    _.filter(workflow.statuses, (status) => {
      if (status.fields && status.fields.length > 0) {
        if (
          _.filter(
            status.fields,
            (field) => field.fieldType.name === 'AnalyseAndImplement'
          ).length > 0
        )
          return true;
      }
      return false;
    }).length > 0
  );
};

export const checkFormCompleted = (wizardChoices) => {
  if (
    !wizardChoices.ownerParty ||
    !wizardChoices.ownerPartyUser ||
    !wizardChoices.assignedParty ||
    !wizardChoices.assignedPartyUser ||
    !wizardChoices.workflow
  )
    return false;
  if (wizardChoices.needsReview) {
    if (!wizardChoices.reviewParty || !wizardChoices.reviewPartyUser)
      return false;
  }
  return true;
};

export const checkFollowUpSettings = (wizardChoices) => {
  if (!wizardChoices.label) return false;
  return true;
};

export const updateChoicesFromWorkflow = (wizardChoices, workflow) => {
  const needsReview = checkIfNeedsReview(workflow);
  const updatedPlan = update(wizardChoices, {
    workflow: {
      $set: workflow,
    },
    meta: {
      options: {
        AnalyseAndImplement: {
          $set: getAnalyseAndImplement(workflow),
        },
      },
    },
    needsReview: {
      $set: needsReview,
    },
  });
  if (!needsReview) {
    updatedPlan.reviewParty = null;
    updatedPlan.reviewPartyUser = null;
  }
  return updatedPlan;
};
