import { useMemo } from 'react';
import {
  RAGStatusResult,
  StatusReportRunArgs,
} from '../../../../../api/statusApi';
import { RAGIcon } from '../../status/RAGIcon';
import { RAGStatusIcon } from '../../status/RAGStatusIcon';
import { Table } from 'semantic-ui-react';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import { get } from 'lodash';
import WidgetComponents from '../../DashboardWidgetComponents';
import { mapArgs } from '../../../../../utils/data/mapArgs';
import SimpleErrorWrapper from '../../../SimpleErrorWrapper';
import { mergeCells } from '../../../../../utils/data/mergeCells';
import { stat } from 'fs';
import EntityLabel from '../../../labels/EntityLabel';

import './MetricMatrix.scss';

interface ComponentProps {
  name: string;
  args?: Record<string, any>;
  components?: ComponentProps[];
}

interface MetricMatrixColumn {
  key?: string;
  header: string;
  components: ComponentProps[];
}
interface MetricMatrixProps {
  data?: PropsData;
  ragMetrics?: string;
  columns?: MetricMatrixColumn[];
}

export interface DashboardProperties {
  PartyIds?: string[];
  applicableDate?: string[];
  dimension?: StatusReportRunArgs['dimension'];
}

export interface PropsData {
  selection: null;
  data: DataData;
}

export interface DataData {
  main: RAGStatusResult;
}

const MetricMatrix = ({
  data,
  ragMetrics,
  columns = [],
}: MetricMatrixProps) => {
  const mergedColumns = ['category', 'subcategory'];
  const metrics = useMemo(() => {
    if (data?.data?.main?.results) {
      let metricResults = data?.data?.main?.results;
      if (ragMetrics) {
        const metricsArray = ragMetrics.split(',');
        metricResults = data?.data?.main?.results.filter((result) =>
          metricsArray.some((metricString) => result.ref.includes(metricString))
        );
      }

      const flatResults = metricResults.map((result) => ({
        ...result,
        ...result.meta,
      }));

      const merged = mergeCells<typeof flatResults[number], any>(
        flatResults,
        mergedColumns
      );
      return merged;
    }
    return [];
  }, [data, ragMetrics]);

  const resultArgs = data?.data?.main?.arguments;

  const headers = ['', ...metrics.map((metric) => metric.subcategory)];
  const dimensions = metrics[0]?.statusResults.map(
    (statusResult) => statusResult.dimensionValue
  );

  const rowData = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      const columnsData = columns.map((column) => {
        return {
          ...column,
          value: column.key ? get(metric, column.key) : null,
        };
      });

      return { columns: columnsData, metric };
    });
  }, [metrics, columns]);

  const renderComponent = (component, metric) => {
    let Component = WidgetComponents[component.name];

    if (['div', 'span', 'p', 'a'].includes(component.name)) {
      Component = component.name;
    }

    if (!Component) {
      return `${component.name} not found`;
    }

    const args = mapArgs(component.args, { ...metric, resultArgs });

    const nestedComponents =
      component.components?.length === 1
        ? renderComponent(component.components[0], metric)
        : (component.components || []).map((nestedComponent) => {
            return renderComponent(nestedComponent, metric);
          });

    return <Component {...args}>{nestedComponents}</Component>;
  };

  const dimensionMetricMap = useMemo(() => {
    const dimensionMetricMap = metrics?.reduce((acc, metric) => {
      metric.statusResults.forEach((statusResult) => {
        const dimension = statusResult.dimensionValue;
        if (!acc[dimension]) {
          acc[dimension] = [];
        }

        const dimensionResult = metric.statusResults.filter(
          (result) => result.dimensionValue === dimension
        );

        acc[dimension].push({ ...metric, statusResults: dimensionResult });
      });
      return acc;
    }, {} as Record<string, typeof metrics>);

    return dimensionMetricMap;
  }, [metrics]);

  return (
    <div className="metric-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            {headers.map((header) => (
              <Table.HeaderCell className="rag-status-matrix-header-cell">
                {header}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>{' '}
        <Table.Body>
          {dimensions &&
            dimensions.map((dimension) => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <EntityLabel entityId={dimension} />
                  </Table.Cell>

                  {dimensionMetricMap[dimension].map((metric) => {
                    return (
                      <Table.Cell className="metric-matrix-result-cell">
                        <StatusPopupWrapper result={metric} args={resultArgs}>
                          <RAGStatusIcon status={metric} />
                        </StatusPopupWrapper>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

MetricMatrix.fields = [
  {
    id: 'ragMetrics',
    required: false,
    label: 'RAG Metrics',
    activityFieldType: 'Text',
    options: null,
  },
  {
    id: 'columns',
    required: false,
    label: 'Columns',
    activityFieldType: 'JSONBuilder',
    args: {
      allowModeChange: true,
    },
  },
];

export default MetricMatrix;
