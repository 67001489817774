import React, { useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { TicketContextConsumer } from '../../context/TicketContext';
import { Grid, Header, Icon, Ref, Segment } from 'semantic-ui-react';
import TicketPage from './TicketPage/TicketPage';
import TicketList from './TicketList';
import Pagination from '../common/Pagination';
import useColumnHeight from '../../utils/hooks/useColumnHeight';
import PaginationDetails from '../common/PaginationDetails';

import './TicketsSplitView.scss';

const TicketsSplitView = (props) => {
  const {
    tickets,
    loading,
    activePage,
    totalPages,
    handlePaginationChange,
    ticketsPerPage,
    totalTickets,
  } = props;

  const [splitView, setSplitView] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [refContainer, columnHeight, resizeDebounced] = useColumnHeight('auto', 50);
  console.log(refContainer);

  const ticketClicked = (ticket) => {
    setSelectedTicketId(ticket.id);
    setSplitView(true);
    resizeDebounced();
  };

  return (
    <React.Fragment>
      <Grid
        columns={splitView ? 2 : 1}
        className={`execution-split-view ${splitView && 'isSplit'}`}
      >
        <Ref innerRef={refContainer}>
          <Grid.Column className="tickets-column" width={splitView ? 7 : 16}>
            {loading || tickets.length > 0 ? (
              <React.Fragment>
                <div
                  className="ticket-list-scroll"
                  style={{ height: columnHeight }}
                >
                  <TicketList
                    tickets={tickets}
                    onClick={ticketClicked}
                    compactView={splitView}
                    loading={loading}
                    selectedTicketId={selectedTicketId}
                    extraRowProps={{
                      showProgramme: true,
                      showTicketSet: true,
                      showApplicableDate: true,
                      showAssignedParty: true,
                    }}
                  />
                </div>

                <Grid className="pagination-grid">
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Pagination
                        activePage={activePage}
                        boundaryRange={0}
                        siblingRange={1}
                        totalPages={totalPages}
                        onPageChange={handlePaginationChange}
                        disabled={loading}
                        size="tiny"
                      />
                    </Grid.Column>
                    {!loading && (
                      <Grid.Column
                        verticalAlign="middle"
                        floated="right"
                        textAlign="right"
                      >
                        <PaginationDetails
                          totalItems={totalTickets}
                          itemsPerPage={ticketsPerPage}
                          activePage={activePage}
                        />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </React.Fragment>
            ) : (
              <Segment placeholder basic>
                <Header icon>
                  <Icon name="briefcase" />
                  No tickets were found which match your search
                </Header>
              </Segment>
            )}
          </Grid.Column>
        </Ref>
        {splitView && (
          <Grid.Column
            className="ticket-column"
            style={{ height: columnHeight }}
            width={9}
          >
            <Icon
              className="close-button"
              onClick={() => {
                setSplitView(false);
              }}
              link
              name="ellipsis vertical"
            />

            <TicketPage
              ticketId={selectedTicketId}
              isExecutionLoading={false}
              fullPageView={true}
            />
          </Grid.Column>
        )}
      </Grid>
    </React.Fragment>
  );
};

TicketsSplitView.propTypes = {
  tickets: PropTypes.array,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  totalTickets: PropTypes.number,
  ticketsPerPage: PropTypes.number,
};

export default TicketContextConsumer(TicketsSplitView);
