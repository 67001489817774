import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import * as _ from 'lodash';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

import useDebounce from '../../../utils/hooks/useDebounce';
import './TextArea.scss';

const TextArea = (props) => {
  const {
    label,
    value: propsValue,
    onChange,
    editable = true,
    name,
    disabled = false,
    required,
    errors,
    error
  } = props;
  const [value, setValue] = useState(propsValue);
  const [isInitial, setIsInitial] = useState(true);
  const debouncedValue = useDebounce(value, 300);
  const fieldErrors = errors ? errors[name] : undefined;

  useEffect(() => {
    console.log('debouncedValue useEffect', { isInitial, debouncedValue });
    if (!isInitial) {
      onChange(null, { value: debouncedValue, name });
    } else {
      setIsInitial(false);
    }
  }, [debouncedValue]);

  useEffect(() => {
    console.log('propsValue useEffect', propsValue);

    setValue(propsValue);
  }, [propsValue]);

  const handleValueChanged = (e, { value }) => {
    console.log('handleValueChanged', value);
    setValue(value);
  };

  return (
    <React.Fragment>
      {editable ? (
        <Form.Field className="field-text-area" required={required} error={error}>
          <label>
            {label}
            <FieldToolTip {...props} />
          </label>
          <Form.TextArea
            name={name}
            placeholder={label}
            value={value || ''}
            onChange={handleValueChanged}
            disabled={disabled}
          />
          {_.isArray(fieldErrors) && <FieldErrors errors={fieldErrors} />}
        </Form.Field>
      ) : (
        <div>{value}</div>
      )}
    </React.Fragment>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.object,
};

TextArea.defaultProps = {
  value: '',
};

export default TextArea;
