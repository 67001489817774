import * as types from '../constants/actionTypes';

export function setFilters(route, filters) {
  return {
    type: types.SET_LIST_FILTERS,
    route,
    filters,
  };
}
export function setPagination(route, pagination) {
  return {
    type: types.SET_LIST_PAGINATION,
    route,
    pagination,
  };
}
