import _ from 'lodash';
import ArgTypes from '../ArgTypes';

const filter = function (args) {
  const { dataSet, filter, outputKey, data } = args;
  const dataToFilter = data[dataSet];

  let filteredData = dataToFilter;
  if (filter && dataToFilter) {
    filteredData = dataToFilter.filter((dataPoint) => {
      return !_.chain(dataPoint)
        .pick(filter)
        .map((v) => v)
        .every((val) => _.isNull(val) || val === 0)
        .value();
    });
  }

  return { ...args, data: { ...data, [outputKey || dataSet]: filteredData } };
};

filter.args = {
  dataSet: ArgTypes.string,
  outputKey: ArgTypes.string,
  filter: ArgTypes.string,
};

export default filter;
