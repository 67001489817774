import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Button, Header } from 'semantic-ui-react';

import PartiesListWrapper from './PartiesListWrapper';
import PartiesWrapper from './Parties';
import ErrorBoundary from './../common/ErrorBoundary';

class PartiesPage extends Component {
  static propTypes = {
    parties: PropTypes.array,
    partiesActions: PropTypes.object,
  };

  componentDidMount() {
    const { partiesActions } = this.props;
    partiesActions.loadParties();
  }

  render() {
    const { partiesActions } = this.props;
    const openCreateParty = () => {
      partiesActions.openCreatePartyModal();
    };

    return (
      <Container>
        <React.Fragment>
          <Header as="h2">Party Management</Header>
          <PartiesListWrapper />
          <Button primary onClick={openCreateParty}>
            Add new Party
          </Button>
        </React.Fragment>
      </Container>
    );
  }
}

export default ErrorBoundary(PartiesWrapper(PartiesPage));
