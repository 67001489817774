import React from 'react';
import { Card, Header, Progress } from 'semantic-ui-react';
import { ExecutionExtended } from '@brallum/vidende/src/types';

interface ExecutionStatusOverviewProps {
  workflowStatusTypes: WorkflowStatusType[];
  execution: ExecutionExtended;
}

interface WorkflowStatusType {
  id: string;
  sort: number;
  name: string; // added assuming workflowStatusType has a name property
}

export const ExecutionStatusOverview = ({
  workflowStatusTypes,
  execution,
}: ExecutionStatusOverviewProps) => {
  return (
    <Card.Group itemsPerRow={2}>
      {workflowStatusTypes.map((workflowStatusType) => {
        const status = execution.statuses.find(
          ({ id }) => id === workflowStatusType.id
        );
        const percent =
          (status?.ticketStatusCount / execution.ticketCount) * 100;
        return (
          <Card key={workflowStatusType.id}>
            <Card.Content>
              <Card.Description>
                <Header size="small">{status?.name}</Header>
                <Progress
                  percent={percent}
                  size="small"
                  color={status?.colourId}
                >
                  {percent ? percent.toFixed(1) : 0}% (
                  {status?.ticketStatusCount}/{execution.ticketCount})
                </Progress>
              </Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

export default ExecutionStatusOverview;
