import React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import UserWrapper from '../../user/User';
import CheckPermission from '../CheckPermission';

const MainMenuItem = ({ options, isMenuExpanded }) => {
  const {
    label,
    path,
    icon,
    permission,
    permissionType,
    external,
    structuralNodeId,
    hidden,
  } = options;

  if (hidden) {
    return null;
  }

  const Item = (
    <Menu.Item
      as={external ? 'a' : NavLink}
      to={path}
      href={path}
      target={external ? '_blank' : ''}
    >
      <Icon name={icon} />
      {isMenuExpanded ? <span>{label}</span> : null}
    </Menu.Item>
  );

  return permission || structuralNodeId ? (
    <CheckPermission
      nodeType={permission}
      permissionType={permissionType}
      structuralNodeId={structuralNodeId}
    >
      {Item}
    </CheckPermission>
  ) : (
    Item
  );
};

MainMenuItem.propTypes = {
  options: PropTypes.object.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default UserWrapper(MainMenuItem);
