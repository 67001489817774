import { FC, useEffect, useState } from 'react';
import { Button, Form, Message, Modal, Select } from 'semantic-ui-react';
import * as uiActions from '../../../actions/uiActions';
import { useDispatch } from 'react-redux';
import ConfirmWrapper from '../../common/ConfirmWrapper';
import DateField from '../../common/fields/Date';
import { useAppSelector } from '../../../actions/store';
import { loadExecutionPlans } from '../../../actions/executionPlanActions';
import ApplicableDateSelect from '../../executionPlan/ApplicableDateSelect';
import { FieldErrors, checkValidation } from '../../../utils/Validation';
import ValidationSchema from '../../../utils/ValidationSchema';
import { APPLICABLE_DATE_TYPES } from '../../../utils/ExecutionPlanLaunchConsts';
import executionApi from '../../../api/executionApi';
import { Entity } from '../../../types';
import toastr from 'toastr';

interface LaunchGovernanceProgrammeModalProps {
  confirmPopupActions: any;
  mode: 'bulk' | 'individual';
  product: Entity;
}

const options = [
  {
    key: 'coManufactured',
    value: true,
    text: 'Co-Manufactured Products',
  },
  {
    key: 'manufacturer',
    value: false,
    text: 'Manufacturer Products',
  },
];

const LaunchGovernanceProgrammeModal: FC<
  LaunchGovernanceProgrammeModalProps
> = ({ confirmPopupActions, mode, product }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { showConfirmDialog } = confirmPopupActions;
  const handleCloseModal = () => {
    dispatch(uiActions.closeModal());
  };

  const executionPlans = useAppSelector((state) => state.executionPlans);
  const config = useAppSelector((state) => state.constants.options);

  const [formData, setFormData] = useState({
    isComan: false,
    applicableDate: null,
    executionPlanId: null,
  });
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>();
  useEffect(() => {
    if (executionPlans.length > 0) return;
    dispatch(loadExecutionPlans());
  }, []);

  const toggleUserClicked = () => {
    setFieldErrors(null);

    const validation = checkValidation(
      formData,
      ValidationSchema.governanceProgramme
    );

    if (!validation.valid) {
      setFieldErrors(validation.errors);
      return;
    }
    showConfirmDialog('Are you sure?', toggleUserConfirm);
  };

  const toggleUserConfirm = async () => {
    try {
      setIsLoading(true);
      if (mode === 'bulk') {
        const res = await executionApi.bulkLaunchGovernanceProgramme({
          applicableDate: formData.applicableDate,
          executionPlanId: formData.executionPlanId,
          isComan: formData.isComan,
        });
      }
      if (mode === 'individual') {
        const res = await executionApi.launchGovernanceProgramme({
          applicableDate: formData.applicableDate,
          executionPlanId: formData.executionPlanId,
          entityId: product.id,
        });
      }
    } catch (error) {
      toastr.error(error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  const handleInputChanged = (event, { name, value, checked }) => {
    setFormData({
      ...formData,
      [name]: value ?? checked,
    });
  };

  const executionPlanOptions = executionPlans.map((executionPlan) => ({
    key: executionPlan.id,
    value: executionPlan.id,
    text: `${executionPlan.label} - ${executionPlan.ownerParty?.label}`,
  }));

  if (
    config.executionPlanDefaultGovernanceId &&
    config.executionPlanDefaultGovernanceLabel &&
    !executionPlanOptions.find(
      (executionPlan) =>
        executionPlan.key === config.executionPlanDefaultGovernanceId
    )
  ) {
    executionPlanOptions.push({
      key: config.executionPlanDefaultGovernanceId,
      value: config.executionPlanDefaultGovernanceId,
      text: config.executionPlanDefaultGovernanceLabel,
    });
  }

  return (
    <Modal open={true} onClose={handleCloseModal} size="tiny">
      <Modal.Header>
        Launch Governance Programme{' '}
        {mode === 'individual' ? `for "${product?.label}"` : null}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group>
            {mode === 'bulk' && (
              <Form.Field
                width={8}
                control={Select}
                name="isComan"
                options={options}
                label="Product Type"
                value={formData.isComan}
                error={
                  !!fieldErrors?.isComan
                    ? {
                        content: fieldErrors?.isComan.toString(),
                      }
                    : null
                }
                required={true}
                onChange={handleInputChanged}
                sortItemsAlphabetically={true}
              />
            )}

            <Form.Field
              width={mode === 'bulk' ? 8 : 16}
              name="applicableDate"
              control={ApplicableDateSelect}
              value={formData.applicableDate}
              error={
                !!fieldErrors?.applicableDate
                  ? {
                      content: fieldErrors?.applicableDate.toString(),
                    }
                  : null
              }
              applicableDateTypeId={APPLICABLE_DATE_TYPES.YEAR}
              applicableDate={formData.applicableDate}
              handleFormDataChange={handleInputChanged}
              required={true}
            />
          </Form.Group>
          <Form.Field
            control={Select}
            name="executionPlanId"
            options={executionPlanOptions}
            error={
              !!fieldErrors?.executionPlanId
                ? {
                    content: fieldErrors?.executionPlanId.toString(),
                  }
                : null
            }
            label="Select Execution Plan"
            value={formData.executionPlanId}
            required={true}
            onChange={handleInputChanged}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          onClick={toggleUserClicked}
          loading={isLoading}
          disabled={isLoading}
        >
          Launch
        </Button>
        <Button
          secondary
          onClick={handleCloseModal}
          loading={isLoading}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmWrapper(LaunchGovernanceProgrammeModal);
