import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Container, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import WorkflowWrapper from './Workflow';
import { WithContext as WorkflowTable } from './WorkflowTable';
import ErrorBoundary from './../common/ErrorBoundary';
import CheckPermission from '../common/CheckPermission';
import * as permissionTypes from './../../constants/permissionTypes';

@WorkflowWrapper
class WorkflowsPage extends Component {
  componentDidMount() {
    this.props.workflowStateActions.loadWorkflows();
  }

  render() {
    return (
      <Container>
        <Header as="h2">Workflow Management</Header>
        <WorkflowTable />
        <CheckPermission
          permissionType={permissionTypes.SUPER}
          structuralNodeId={2}
        >
          <Button as={Link} to="/workflow" primary>
            Create New Workflow
          </Button>
        </CheckPermission>
      </Container>
    );
  }
}

WorkflowsPage.propTypes = {
  workflowStateActions: PropTypes.object,
};

export default ErrorBoundary(WorkflowsPage);
