import React, { useEffect, useState, ChangeEvent, FocusEvent } from 'react';
import { Form, SemanticWIDTHS } from 'semantic-ui-react';
import _ from 'lodash';

import useDebounce from '../../../utils/hooks/useDebounce';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

interface TextProps {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: null, data: { value: string; name: string }) => void;
  disabled?: boolean;
  type?: string;
  errors?: Record<string, string[]>;
  error?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  fluid?: boolean;
  required?: boolean;
  editable?: boolean;
  debounce?: boolean;
  width?: SemanticWIDTHS;
}

const Text = (props: TextProps) => {
  const {
    label,
    placeholder,
    value: propsValue,
    onChange,
    name,
    disabled = false,
    type = 'text',
    errors,
    onFocus,
    onBlur,
    fluid,
    required,
    editable = true,
    width,
    debounce = true,
    error,
  } = props;
  const [value, setValue] = useState<string | undefined>(propsValue);
  const debouncedValue = useDebounce(value, 300);
  const fieldErrors = errors ? errors[name] : undefined;

  useEffect(() => {
    if (onChange && debouncedValue !== propsValue && debounce)
      onChange(null, { value: debouncedValue, name });
  }, [debouncedValue]);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  const handleValueChanged = (
    e: ChangeEvent<HTMLInputElement>,
    { value }: { value: string }
  ) => {
    if (debounce) {
      setValue(value);
    } else {
      onChange?.(null, { value, name });
    }
  };

  if (!editable) {
    return <React.Fragment>{value}</React.Fragment>;
  }

  return (
    <Form.Field required={required} width={width} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Form.Input
        name={name}
        placeholder={label || placeholder}
        value={debounce ? value || '' : propsValue}
        onChange={handleValueChanged}
        disabled={disabled}
        type={type}
        error={!!fieldErrors}
        onFocus={onFocus}
        onBlur={onBlur}
        fluid={fluid}
      />
      {_.isArray(fieldErrors) && <FieldErrors errors={fieldErrors} />}
    </Form.Field>
  );
};

Text.defaultProps = {
  value: '',
};

export default Text;
