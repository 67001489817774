import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import ExecutionWrapper from './Execution';
import EventListContainer from '../common/events/EventListContainer';
import { EVENT_TYPES, TARGET_MODELS } from '../../utils/EventConsts';

import './ExecutionEvents.scss';

const ExecutionEvents = ({ currentExecution }) => {
  const [executionTicketIds, setExecutionTicketIds] = useState([]);
  const [filterWhere, setFilterWhere] = useState({});

  useEffect(() => {
    const { ticketSets } = currentExecution;
    const currentExecutionTicketIds = _.flatten(
      _.map(ticketSets, (ticketSet) => {
        return _.map(ticketSet.tickets, (ticket) => ticket.id);
      })
    );
    setExecutionTicketIds(currentExecutionTicketIds);
  }, []);

  useEffect(() => {
    if (executionTicketIds.length > 0) {
      const { id: currentExecutionId } = currentExecution;
      const filter = {
        or: [
          {
            and: [
              { targetModel: TARGET_MODELS.EXECUTION_TICKET },
              { targetId: { inq: executionTicketIds } },
              {
                eventTypeId: {
                  inq: [EVENT_TYPES.TRANSITIONED_BY, EVENT_TYPES.COMMENT],
                },
              },
            ],
          },
          {
            and: [
              { targetModel: TARGET_MODELS.EXECUTION },
              { targetId: currentExecutionId },
              { eventTypeId: EVENT_TYPES.WAS_LAUNCHED },
            ],
          },
        ],
      };
      setFilterWhere(filter);
    }
  }, [executionTicketIds]);

  const isFilterWhereNotEmpty = !_.isEmpty(filterWhere);

  return (
    <Segment className="execution-events-segment" simple>
      <Header as="h3">Events</Header>
      {isFilterWhereNotEmpty && (
        <div className="execution-events-segment-list">
          <EventListContainer
            filterWhere={filterWhere}
            showHiddenButton={false}
            filterWithOr={true}
          />
        </div>
      )}
    </Segment>
  );
};

ExecutionEvents.propTypes = {
  currentExecution: PropTypes.object,
  targetModels: PropTypes.object,
};

export default ExecutionWrapper(ExecutionEvents);
