import React from 'react';
import * as PropTypes from 'prop-types';
import AttachmentsListContainer from '../../attachments/AttachmentsListContainer';
let apiTools;
if (typeof process !== 'undefined' && !process.env.SERVER_REND) {
  apiTools = require('../../../../api/apiTools').default;
}

const ReportAttachmentList = (props) => {
  const { attachments, model, modelId } = props;
  const modelActions = {
    openAttachment: (currentModel, attachment) => {
      const url = apiTools.generateUrl(
        `${model}/${modelId}/downloadAttachments/${attachment.id}`
      );
      window.open(url, '_blank');
    },
  };
  const currentModel = { attachments };
  return (
    <AttachmentsListContainer
      currentModel={currentModel}
      modelActions={modelActions}
      showDetails={false}
    />
  );
};

ReportAttachmentList.propTypes = {
  attachments: PropTypes.array,
  model: PropTypes.string,
  modelId: PropTypes.number,
};

export default ReportAttachmentList;
