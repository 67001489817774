import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import TicketPage from './TicketPage/TicketPage';
import TicketBreadcrumbs from './TicketBreadcrumbs';
import PartyLabel from '../common/labels/PartyLabel';
import { Header, List, Placeholder, Segment } from 'semantic-ui-react';
import { TicketContextConsumer } from '../../context/TicketContext';
import TicketMoreInfo from './TicketMoreInfo';
import TicketAssignment from './TicketAssignment';
import TicketStatus from './TicketStatus';
import TicketResolutionIconsContainer from './TicketResolutionIconsContainer';
import * as _ from 'lodash';
import moment from 'moment';
import WorkflowWrapper from '../workflow/Workflow';
import DashboardContainer from '../dashboard/DashboardContainer';
import TicketFields from './TicketFields/TicketFields';
import UserWrapper from '../user/User';
import TicketWorkflowEntries from './TicketWorkflowEntries';
import ActionButtons from '../common/ActionButtons';
import AttachmentsList from '../common/attachments/AttachmentsList';
import Conversation from '../conversation/Conversation';
import Viewer from '../common/fields/TUIWrapperViewer';

const Component = (props) => {
  const {
    ticket,
    workflowActions,
    ticketActions,
    currentExecution,
    ticketStateActions,
    currentUser,
    workflows,
    attachmentsConstants,
    isTicketLoading,
  } = props;

  const [entriesToShow, setEntriesToShow] = useState([]);

  const showEntries = (id) => {
    if (entriesToShow.includes(id)) return;
    setEntriesToShow([...entriesToShow, id]);
  };
  const hideEntries = (id) => {
    if (!entriesToShow.includes(id)) return;
    setEntriesToShow(_.without(entriesToShow, id));
  };

  const downloadAttachment = (attachment) => {
    ticketActions.openAttachment(ticket, attachment);
  };

  if (isTicketLoading)
    return (
      <Segment>
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    );
  if (!ticket || !ticket.id) return null;
  if (!ticket.activity) return null;

  const workflow = workflows.byId[ticket.workflowId];
  if (!workflow) return null;

  const currentStatusNode = ticket
    ? workflowActions.getStatusNode(ticket.statusNodeId)
    : null;

  let currentDeadlineDate, currentStatusOverdue;
  if (currentStatusNode) {
    const deadines = ticket ? ticket.deadlines : [];
    const deadline = _.find(deadines, {
      workflowStatusId: currentStatusNode.statusId,
    });
    currentDeadlineDate = deadline ? deadline.deadlineDate : null;
    currentStatusOverdue = moment().diff(moment(currentDeadlineDate)) > 0;
  }

  const currentUserDetails = currentUser.details;

  const dashboardId = _.get(ticket, 'activity.meta.dashboardId');

  return (
    <Segment>
      <Segment
        vertical
        style={{ marginBottom: '1em', paddingTop: 0 }}
        className="ticket-breadcrumbs"
      >
        <TicketBreadcrumbs />
        <PartyLabel
          party={{
            label: ticket.assignedPartyLabel,
            id: ticket.assignedPartyId,
          }}
        />
      </Segment>

      <div className="ticket-page-header">
        <div className="column-1">
          <Viewer initialValue={ticket.activity.description} />
          {ticket.activity.moreInfo && (
            <TicketMoreInfo
              ticket={ticket}
              showSidebar={ticketActions.openMoreInfoSidebar}
            />
          )}
        </div>

        <div className="column-2">
          <List floated="right">
            <List.Item>
              <List.Content>
                <List.Header>Assignee:</List.Header>
                <TicketAssignment disabled={true} />
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Content>
                <List.Header>Current Status: </List.Header>
                <TicketStatus
                  statusNode={currentStatusNode}
                  color={ticket.statusNodeColor}
                  deadlineDate={currentDeadlineDate}
                  currentStatusOverdue={currentStatusOverdue}
                />
              </List.Content>
            </List.Item>

            {ticket.statusResolutions.length > 0 && (
              <List.Item>
                <List.Content>
                  <List.Header>Resolutions</List.Header>

                  <TicketResolutionIconsContainer
                    size="large"
                    statusResolutions={ticket.statusResolutions}
                  />
                </List.Content>
              </List.Item>
            )}
          </List>
        </div>
      </div>

      {dashboardId && currentExecution && (
        <DashboardContainer
          id={dashboardId}
          editable={false}
          showHeader={false}
          forceChartRefresh={true}
          initialProperties={{
            currentAndPreviousExecution: [
              currentExecution.id,
              currentExecution.previousExecutionId,
            ],
            executionId: currentExecution.id,
          }}
        />
      )}

      {ticket.entries && ticket.entries.length > 0 && (
        <React.Fragment>
          <Header attached="top" size="large" inverted>
            Main Entries
            <ActionButtons
              showActions={entriesToShow.includes('main') ? 'minus' : 'add'}
              style={{ float: 'right' }}
              inverted={null}
              color="white"
              addClicked={() => {
                showEntries('main');
              }}
              minusClicked={() => {
                hideEntries('main');
              }}
            />
          </Header>
          {entriesToShow.includes('main') && (
            <Segment color="purple" attached="bottom">
              <TicketFields
                entries={ticket.entries}
                onSave={() =>
                  ticketStateActions.saveActivityFields(
                    ticket,
                    currentUserDetails
                  )
                }
                enabled={false}
                allowDataEntry={false}
              />
            </Segment>
          )}
        </React.Fragment>
      )}

      {workflow.statuses.map((status) => {
        const isCurrentNode = currentStatusNode
          ? currentStatusNode.statusId === status.id
          : false;
        const entries = ticket.workflowEntries
          ? ticket.workflowEntries.filter((entry) => {
              return (
                _.findIndex(status.fields, {
                  id: entry.workFlowStatusFieldId,
                }) !== -1
              );
            })
          : [];
        if (status.fields.length === 0) return;
        if (entries.length === 0 && !isCurrentNode) return;
        return (
          <React.Fragment key={status.id}>
            <Header attached="top" size="large" inverted>
              {status.name} Entries
              <ActionButtons
                style={{ float: 'right' }}
                showActions={
                  entriesToShow.includes(status.id) ? 'minus' : 'add'
                }
                addClicked={() => {
                  showEntries(status.id);
                }}
                minusClicked={() => {
                  hideEntries(status.id);
                }}
                color="white"
              />
            </Header>
            {entriesToShow.includes(status.id) && (
              <Segment color={isCurrentNode ? 'green' : null} attached="bottom">
                <TicketWorkflowEntries entries={entries} />
              </Segment>
            )}
          </React.Fragment>
        );
      })}

      {ticket.attachments && ticket.attachments.length > 0 && (
        <React.Fragment>
          <Header attached="top" size="large" inverted>
            Attachments
            <ActionButtons
              showActions={
                entriesToShow.includes('attachments') ? 'minus' : 'add'
              }
              style={{ float: 'right' }}
              inverted={null}
              color="white"
              addClicked={() => {
                showEntries('attachments');
              }}
              minusClicked={() => {
                hideEntries('attachments');
              }}
            />
          </Header>
          {entriesToShow.includes('attachments') && (
            <Segment attached="bottom">
              <AttachmentsList
                attachments={ticket.attachments || []}
                onDownload={downloadAttachment}
                showDetails={true}
                attachmentsConstants={attachmentsConstants}
                editAsModal={true}
                allowEditDetails={false}
                disabled={true}
              />
            </Segment>
          )}
        </React.Fragment>
      )}

      {ticket.comments && ticket.comments.length > 0 && (
        <React.Fragment>
          <Header attached="top" size="large" inverted>
            Comments
            <ActionButtons
              showActions={entriesToShow.includes('comments') ? 'minus' : 'add'}
              style={{ float: 'right' }}
              inverted={null}
              color="white"
              addClicked={() => {
                showEntries('comments');
              }}
              minusClicked={() => {
                hideEntries('comments');
              }}
            />
          </Header>
          {entriesToShow.includes('comments') && (
            <Segment attached="bottom">
              <Conversation
                readOnly={true}
                conversationId={ticket.conversationId}
                type="Comment"
              />
            </Segment>
          )}
        </React.Fragment>
      )}
    </Segment>
  );
};

Component.propTypes = {
  ticket: PropTypes.object,
  workflowActions: PropTypes.object,
  ticketActions: PropTypes.object,
  currentExecution: PropTypes.object,
  ticketStateActions: PropTypes.object,
  currentUser: PropTypes.object,
  workflows: PropTypes.object,
  attachmentsConstants: PropTypes.object,
  isTicketLoading: PropTypes.bool,
};

const TicketOverview = (props) => {
  return (
    <TicketPage
      {...props}
      OverrideComponent={UserWrapper(
        TicketContextConsumer(WorkflowWrapper(Component))
      )}
    />
  );
};

export default TicketOverview;
