import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Moment from 'react-moment';

import FieldToolTip from './FieldToolTip';
import FieldErrors from './FieldErrors';

// require('./Date.scss');

const DateField = (props) => {
  const {
    label,
    value,
    onChange,
    editable = true,
    name,
    disabled,
    minDate,
    maxDate,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    errors,
    error,
    required,
    onFocus,
    onBlur,
    isClearable,
    width,
    showLabel = true,
  } = props;
  const dateFormat = 'DD/MM/YYYY';
  const fieldErrors = errors ? errors[name] : undefined;
  const [currentValue, setCurrentValue] = useState(value);
  const [localValue, setLocalValue] = useState(
    value ? moment(value).format(dateFormat) : ''
  );

  useEffect(() => {
    setCurrentValue(value);
    setLocalValue(value ? moment(value).format(dateFormat) : '');
  }, [value]);

  const handleOnChange = (newValue) => {
    if (newValue) {
      const stringValue = moment
        .utc(newValue.valueOf() + newValue.utcOffset() * 60000)
        .toISOString();
      setLocalValue(newValue.format(dateFormat));
      if (stringValue === currentValue) return;
      onChange(
        {},
        {
          value: stringValue,
          name,
        }
      );
    } else if (isClearable) {
      setLocalValue('');
      setCurrentValue(null);
      onChange(
        {},
        {
          value: null,
          name,
        }
      );
    }
  };

  const handleOnChangeRaw = (event) => {
    setLocalValue(event.target.value);
  };
  const handleOnBlur = (event) => {
    const m = moment(event.target.value, 'D/M/YYYY');
    const withinDateRange = minDate
      ? m.isAfter(minDate)
      : true && maxDate
      ? m.isBefore(maxDate)
      : true;
    if (m.isValid() && withinDateRange) {
      handleOnChange(m);
    } else {
      setLocalValue(
        currentValue ? moment(currentValue).format(dateFormat) : ''
      );
    }

    if (onBlur) onBlur(event);
  };
  return (
    <React.Fragment>
      {editable ? (
        <Form.Field required={required} width={width} error={error}>
          {showLabel && (
            <label>
              {label}
              <FieldToolTip {...props} />
            </label>
          )}
          <Form.Field error={!!fieldErrors || error}>
            <DatePicker
              className="ui input"
              popperClassName="vidende-datepicker"
              onChange={handleOnChange}
              value={localValue}
              selected={value ? moment(value) : null}
              dateFormat={dateFormat}
              disabled={disabled}
              readOnly={false}
              minDate={minDate}
              maxDate={maxDate}
              selectsStart={selectsStart}
              selectsEnd={selectsEnd}
              startDate={startDate}
              endDate={endDate}
              onFocus={onFocus}
              isClearable={isClearable}
              showMonthDropdown
              showYearDropdown
              disabledKeyboardNavigation
              onChangeRaw={handleOnChangeRaw}
              onBlur={handleOnBlur}
              placeholderText={label}
            />
          </Form.Field>
          {fieldErrors && <FieldErrors errors={fieldErrors} />}
        </Form.Field>
      ) : (
        <Moment format="DD/MM/YY">{value}</Moment>
      )}
    </React.Fragment>
  );
};

DateField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  required: PropTypes.bool,
  errors: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isClearable: PropTypes.bool,
  showLabel: PropTypes.bool,
  width: PropTypes.number,
};

export default DateField;
