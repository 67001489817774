import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Header, Label, Segment } from 'semantic-ui-react';
import ActivitiesList from '../activity/ActivitiesList';
import ActionButtons from '../common/ActionButtons';
import EditableHeader from '../common/EditableHeader';
import ActivitySetListItem from './ActivitySetListItem';
import ActivitySetScoping from './ActivitySetScoping';
import ActivitySetFields from './ActivitySetFields';

const ActivitySet = ({
  value,
  updateActivitySet,
  activitySetIndex,
  isActive,
  onClick,
  removeActivityFromSet,
  removeActivitySet,
  moveActivitySet,
  moveActivityInSet,
  createNewActivity,
  cloneActivity,
  editable,
}) => {
  return (
    <React.Fragment>
      <Segment
        secondary={isActive}
        onClick={onClick}
        padded
        className="u-show-on-hover"
      >
        {editable ? (
          <React.Fragment>
            <EditableHeader
              value={value.label}
              placeholder="Add Question set name"
              name="label"
              onSubmit={(event, data) => {
                updateActivitySet(activitySetIndex, data);
              }}
            />
            <EditableHeader
              value={value.ref}
              placeholder="Add Question set reference"
              name="ref"
              onSubmit={(event, data) => {
                updateActivitySet(activitySetIndex, data);
              }}
              size="tiny"
            />
          </React.Fragment>
        ) : (
          <Header>
            {value.label} {value.ref ? `(${value.ref})` : ''}
          </Header>
        )}

        <Divider />

        {editable && (
          <Label
            size="mini"
            attached="top right"
            className="u-show-on-hover__target"
          >
            <ActionButtons
              removeClicked={() => {
                removeActivitySet(activitySetIndex);
              }}
              upClicked={() => moveActivitySet(value, -1)}
              downClicked={() => moveActivitySet(value, 1)}
              addClicked={() => createNewActivity(value)}
            />
          </Label>
        )}

        {value.activities.length === 0 ? (
          <Header>No questions added</Header>
        ) : (
          <ActivitiesList
            activities={value.activities}
            layout="CARD"
            removeActivity={(activity) => {
              removeActivityFromSet(activitySetIndex, activity);
            }}
            moveActivity={(activity, increment) => {
              moveActivityInSet(activitySetIndex, activity, increment);
            }}
            cloneActivity={(activity) => {
              cloneActivity(activity, value);
            }}
            editable={editable}
          />
        )}
      </Segment>
    </React.Fragment>
  );
};

ActivitySet.propTypes = {
  value: PropTypes.object.isRequired,
  updateActivitySet: PropTypes.func,
  activitySetIndex: PropTypes.number,
  isActive: PropTypes.bool,
  editable: PropTypes.bool,
  onClick: PropTypes.func,
  removeActivityFromSet: PropTypes.func,
  removeActivitySet: PropTypes.func,
  moveActivitySet: PropTypes.func,
  moveActivity: PropTypes.func,
  moveActivityInSet: PropTypes.func,
  createNewActivity: PropTypes.func,
  cloneActivity: PropTypes.func,
};

ActivitySet.defaultProps = {
  editable: true,
};

ActivitySet.ListItem = ActivitySetListItem;
ActivitySet.Scoping = ActivitySetScoping;
ActivitySet.Fields = ActivitySetFields;

export default ActivitySet;
