import React from 'react';
import * as PropTypes from 'prop-types';
import { Label, Segment, Table } from 'semantic-ui-react';
import ActivityResolutionListRow from './ActivityResolutionListRow';

const ActivityResolutionList = ({
  activityResolutionTypes,
  resolutions,
  toggleResolution,
  onChange,
  editable,
  allowNotes = true,
  showUseDefault = false,
}) => {
  return (
    <React.Fragment>
      {editable ? (
        <Table compact celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              {editable && <Table.HeaderCell>Available</Table.HeaderCell>}
              <Table.HeaderCell>Label Override</Table.HeaderCell>
              {editable && allowNotes && (
                <Table.HeaderCell>Add Note</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {activityResolutionTypes.map((type) => (
              <ActivityResolutionListRow
                key={type.id}
                type={type}
                resolutions={resolutions}
                toggleResolution={toggleResolution}
                onChange={onChange}
                editable={editable}
                allowNotes={allowNotes}
                showUseDefault={showUseDefault}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <div>
          {resolutions.map((resolution, index) => (
            <Label key={index} color={resolution.resolutionType.colourId}>
              {resolution.labelOverride
                ? resolution.labelOverride.toUpperCase()
                : resolution.resolutionType.label}
            </Label>
          ))}
          {resolutions.map(
            (resolution, index) =>
              resolution.note && (
                <Segment key={index} size="tiny" padded>
                  <Label
                    as="span"
                    color={resolution.resolutionType.colourId}
                    attached="top right"
                    size="tiny"
                  >
                    {resolution.labelOverride
                      ? resolution.labelOverride.toUpperCase()
                      : resolution.resolutionType.label}
                  </Label>
                  {resolution.note}
                </Segment>
              )
          )}
        </div>
      )}
    </React.Fragment>
  );
};

ActivityResolutionList.propTypes = {
  activityResolutionTypes: PropTypes.array.isRequired,
  resolutions: PropTypes.array,
  toggleResolution: PropTypes.func,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  allowNotes: PropTypes.bool,
  showUseDefault: PropTypes.bool,
};

export default ActivityResolutionList;
