import React, { useEffect, useState } from 'react';
import userApi from '../../api/userApi';
import { Container } from 'semantic-ui-react';
import UsersListPaginated from './UsersListPaginated';

const UsersPage = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    userApi
      .getUsers(null, null, {
        include: ['party', 'loginDetails'],
      })
      .then((res) => {
        setUsers(res);
      });
  }, []);

  return (
    <Container>
      <UsersListPaginated
        users={users}
        userIsAdmin={true}
        includeParty={true}
      />
    </Container>
  );
};

export default UsersPage;
