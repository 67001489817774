import * as _ from 'lodash';

export function getActiveItems(items) {
  return _.filter(items, {
    deleted: false,
  });
}

export function getUndefaultUserGroups(userGroups) {
  return _.filter(userGroups, { isDefaultGroup: false });
}
