import { createContext, Component } from 'react';

const PermissionContext = createContext();

export const PermissionContextConsumer = (ComponentToWrap) =>
  class WrapContext extends Component {
    render() {
      return (
        <PermissionContext.Consumer>
          {(context) => {
            return <ComponentToWrap {...context} {...this.props} />;
          }}
        </PermissionContext.Consumer>
      );
    }
  };

export default PermissionContext;
