import React from 'react';
import * as PropTypes from 'prop-types';

import AddAttachmentModalRecent from './AddAttachmentModalRecent';
import AddAttachmentModalSearch from './AddAttachmentModalSearch';
import AddAttachmentModalUpload from './AddAttachmentModalUpload';
import AddAttachmentModalLink from './AddAttachmentModalLink';
import { menuTabs } from '../../../constants/addAttachments';
import { WrapAttachmentsContext } from '../../../context/AttachmentContext';

const AddAttachmentModalBody = ({ activeTab }) => {
  switch (activeTab) {
    case menuTabs.RECENT: {
      return <AddAttachmentModalRecent />;
    }
    case menuTabs.SEARCH: {
      return <AddAttachmentModalSearch />;
    }
    case menuTabs.UPLOAD: {
      return <AddAttachmentModalUpload />;
    }
    case menuTabs.LINK: {
      return <AddAttachmentModalLink />;
    }
  }
};

AddAttachmentModalBody.propTypes = {
  activeTab: PropTypes.string,
};

export default WrapAttachmentsContext(AddAttachmentModalBody);
