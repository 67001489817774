import React from 'react';
import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';
import ProgrammeReportsTableRow from './ProgrammeReportsTableRow';

const ProgrammeReportsTable = ({
  reports,
  handleDeleteReportClicked,
  handleReportLabelChanged,
}) => {
  return (
    <React.Fragment>
      <Header as="h4">Choosen Reports</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Dashboard</Table.HeaderCell>
            <Table.HeaderCell>Report Label</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(reports, (report) => (
            <ProgrammeReportsTableRow
              report={report}
              key={report.id}
              handleDeleteClicked={handleDeleteReportClicked}
              handleReportLabelChanged={handleReportLabelChanged}
            />
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

ProgrammeReportsTable.propTypes = {
  reports: PropTypes.array,
  handleDeleteReportClicked: PropTypes.func,
  handleReportLabelChanged: PropTypes.func,
};

export default ProgrammeReportsTable;
