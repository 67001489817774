import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';

import './PrincipalLabel.scss';

const PrincipalLabel = (props) => {
  const { principal } = props;
  if (!principal)
    return (
      <Label as="a" color="grey" image {...props}>
        Unassigned
      </Label>
    );

  const generatePrincipalText = (principal) => {
    const { userGroupLabel, label, firstName, lastName, userGroup, user } =
      principal;
    if (label) return label;
    if (userGroupLabel) return userGroupLabel;
    if (firstName) return `${firstName} ${lastName}`;
    if (userGroup) return userGroup.label;
    if (user) return user.label;
  };

  const generateIconType = (principal) => {
    const { type, userGroupLabel, userGroup } = principal;
    if (type && type === 'usergroup') return 'users';
    if (userGroupLabel || userGroup) return 'users';
    return 'user';
  };

  const iconName = generateIconType(principal);
  const principalText = generatePrincipalText(principal);

  return (
    <Label className="principal-label" as="a" color="grey" image {...props}>
      <Icon name={iconName} />
      &nbsp;
      {principalText}
    </Label>
  );
};

PrincipalLabel.propTypes = {
  principal: PropTypes.object,
};

export default PrincipalLabel;
