import moment from 'moment';
import _ from 'lodash';
import update from 'immutability-helper';

import initialState from './initialState';
import * as types from '../constants/actionTypes';

export default function eventsReducer(state = initialState.events, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_SUCCESS: {
      const latestDate = action.events[0]
        ? action.events[0].createdAt
        : moment.utc();
      // add some logic to check events are not already in list
      // action.event.filter to get rid of events already in eventsList
      // also may want to sort filtered events by date
      // then map to add newEvent to true (only is gotInitialList is true)
      const res = _.reverse(
        _.sortBy(
          _.uniqWith([...action.events, ...state.eventsList], _.isEqual),
          'id'
        )
      );
      if (state.gotInitialList) {
        let newState = update(state, {
          newEvents: {
            $set: state.newEvents + action.events.length,
          },
          eventsList: {
            $set: res,
          },
          latestDate: {
            $set: latestDate,
          },
          gotInitialList: {
            $set: true,
          },
          isFailed: { $set: false },
        });

        return newState;
      }

      let newState = update(state, {
        newEvents: {
          $set: action.events.length,
        },
        eventsList: {
          $set: action.events,
        },
        latestDate: {
          $set: latestDate,
        },
        gotInitialList: {
          $set: true,
        },
        isFailed: { $set: false },
      });

      return newState;
    }
    case types.RESET_NEW_EVENTS: {
      //map over eventslist and set newEvent to false
      return {
        ...state,
        newEvents: 0,
      };
    }

    case types.CLEAN_NEW_EVENTS: {
      return initialState.events;
    }

    case types.LOAD_EVENTS_FAILED: {
      const { errorMessage } = action;
      return {
        ...state,
        isFailed: true,
        errorMessage,
      };
    }

    default:
      return state;
  }
}
