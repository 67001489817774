import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import UIDGenerator from '../../../utils/UIDGenerator';
import FieldToolTip from './FieldToolTip';

const YesNo = (props) => {
  const {
    label,
    value,
    onChange,
    disabled = false,
    required,
    editable = true,
    error,
  } = props;
  const u = UIDGenerator.get();
  const valueBool = value ? JSON.parse(value) : null;

  if (!editable) {
    return <React.Fragment>{valueBool ? 'Yes' : 'No'}</React.Fragment>;
  }

  return (
    <Form.Field inline required={required} error={error}>
      <label>
        {label}
        <FieldToolTip {...props} />
      </label>
      <Form.Group inline>
        <Form.Radio
          label="Yes"
          name={`radioGroup${u}`}
          checked={valueBool === true}
          onChange={() => {
            onChange({}, { value: 'true' });
          }}
          disabled={disabled}
        />
        <Form.Radio
          label="No"
          name={`radioGroup${u}`}
          checked={valueBool === false}
          onChange={() => {
            onChange({}, { value: 'false' });
          }}
          disabled={disabled}
        />
      </Form.Group>
    </Form.Field>
  );
};

YesNo.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

YesNo.defaultProps = {
  value: '',
};

export default YesNo;
