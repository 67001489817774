import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Form, Icon, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import dashboardApi from '../../api/dashboardApi';
import Select from '../common/fields/Select';
import { PartyWrapContext } from './../../context/PartyContext';

const PartyDashboards = (props) => {
  const { party } = props;
  const { dashboards = [] } = party;

  const [availableDashboards, setAvailableDashboards] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  useEffect(() => {
    dashboardApi.getDashboards().then((dashboards) => {
      setAvailableDashboards(dashboards);
    });
  }, []);

  const addDashboard = () => {
    const { partyStateActions } = props;
    partyStateActions.addDashboard(party.id, selectedDashboard);
  };
  const removeDashboard = (dashboardId) => {
    const { partyStateActions } = props;
    partyStateActions.removeDashboard(party.id, dashboardId);
  };

  return (
    <div>
      <List>
        {dashboards.map((dashboard) => (
          <List.Item key={dashboard.id}>
            <List.Content floated="right">
              <Button
                icon
                onClick={() => {
                  removeDashboard(dashboard.id);
                }}
              >
                <Icon name="delete" />
              </Button>
            </List.Content>
            <Link to={`/dashboards/${dashboard.id}/edit`}>
              {dashboard.label}
            </Link>
          </List.Item>
        ))}
      </List>

      <Form>
        <Form.Group>
          <Select
            placeholder="Select Dashboard"
            fluid
            selection
            onChange={(event, { value }) => {
              setSelectedDashboard(value);
            }}
            name="partyId"
            options={availableDashboards}
            value={selectedDashboard}
            sortItemsAlphabetically={true}
          />
          <Button icon disabled={!selectedDashboard} onClick={addDashboard}>
            <Icon name="add" />
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

PartyDashboards.propTypes = {
  party: PropTypes.object,
};

export default PartyWrapContext(PartyDashboards);
