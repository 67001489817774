import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
  Header,
  Dimmer,
  Loader,
  Table,
  Segment,
  Icon,
  Label,
} from 'semantic-ui-react';
import toastr from 'toastr';
import * as _ from 'lodash';

import Permission from '../permissions/Permission';
import ExecutionWrapper from './Execution';
import { PermissionContextConsumer } from '../../context/PermissionContext';
import { permissionFilter } from '../../constants/apiFilters';
import AddUserPermission from '../permissions/AddUserPermission';
import ExecutionViewMatrixSettingsCell from './ExecutionViewMatrixSettingsCell';

import './ExecutionViewMatrixSettings.scss';
import RelationshipLabel from '../common/labels/RelationshipLabel';

class ExecutionViewMatrixSettings extends Component {
  state = {
    loading: false,
  };
  componentDidMount() {
    this.loadPermissions();
  }
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.history, this.props.history))
      this.loadPermissions();
  }
  removeUser = (structuralNodeId, principal) => {
    this.setState({ loading: true });
    const { permissionStateActions } = this.props;
    permissionStateActions
      .deleteRole(structuralNodeId, principal.id)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(({ message }) => {
        toastr.error(message);
        this.setState({ loading: false });
      });
  };
  loadPermissions = () => {
    const { executionStateActions, permissionStateActions } = this.props;
    const { id, structuralNodeId } = this.props.currentExecution;
    this.setState({ loading: true });
    permissionStateActions
      .loadPermission(structuralNodeId, permissionFilter)
      .then(() => {
        executionStateActions.fetchMatrixRole(id).then(() => {
          this.setState({ loading: false });
        });
      })
      .catch((e) => toastr.error(e.message));
  };
  definedPrincipal = (principalRoles) => {
    return _.map(principalRoles, (principalRole) => principalRole.principalId);
  };
  checkIfUserHasViewPermission = (permissionsMatrix, principalRole) => {
    return !_.isEmpty(
      _.filter(
        permissionsMatrix,
        (permission) =>
          !_.isEmpty(
            _.filter(permission.permissionsNode.principalRoles, {
              principalId: principalRole.principalId,
            })
          )
      )
    );
  };
  render() {
    const { currentExecution, structuralNode, permissionStateActions } =
      this.props;
    const filter = currentExecution.filter;
    const partyId = filter ? filter.assignedPartyRelationship.partyId : null;
    const principalRoles = structuralNode ? structuralNode.principalRoles : [];
    const { loading } = this.state;

    const permissionsMatrix = partyId
      ? _.filter(currentExecution.permissionsMatrix, {
          assignedPartyRelationshipId: partyId,
        })
      : currentExecution.permissionsMatrix;
    let groupedTicketSets = permissionsMatrix ? permissionsMatrix : [];
    if (
      permissionsMatrix &&
      principalRoles &&
      _.head(permissionsMatrix).activitySet.category &&
      currentExecution.granularPermissionTypeId === 'TICKETSET'
    ) {
      groupedTicketSets = _.groupBy(
        permissionsMatrix,
        (ticketSet) => ticketSet.activitySet.category.label
      );
    } else {
      groupedTicketSets = {
        Relationships: _.uniqBy(groupedTicketSets, 'structuralNodeId'),
      };
    }
    return (
      <React.Fragment>
        <Dimmer active={loading} inverted>
          <Loader disabled={!loading} />
        </Dimmer>
        <Header as="h3">Permissions Matrix</Header>
        <AddUserPermission
          structuralNodeId={currentExecution.structuralNodeId}
          showRoleSelect={false}
          roleDefinitionId="View"
          exceptPrincipal={this.definedPrincipal(principalRoles)}
        />
        {groupedTicketSets && (
          <React.Fragment>
            {principalRoles.length ? (
              _.map(groupedTicketSets, (ticketSet, key) => {
                const isCategorized = !_.isArray(groupedTicketSets);
                return (
                  <React.Fragment key={key}>
                    {/* <Header as="h4">
                      {key}
                      {isCategorized ? key : 'Uncategorised'}
                    </Header> */}
                    <Table
                      celled
                      className="view-matrix-table"
                      style={{
                        maxWidth: principalRoles.length === 1 ? '600px' : null,
                      }}
                    >
                      <Table.Row>
                        <Table.Cell
                          width={principalRoles.length === 1 ? 6 : 3}
                        />

                        {_.map(principalRoles, (principalRole) => {
                          const hasViewPermission =
                            this.checkIfUserHasViewPermission(
                              permissionsMatrix,
                              principalRole
                            );
                          const { principal } = principalRole;
                          const { label } = principal.user
                            ? principal.user
                            : principal.userGroup;
                          return (
                            <Table.Cell
                              textAlign="center"
                              className="view-matrix-table-cell"
                            >
                              <Label>
                                {label}
                                {!hasViewPermission && (
                                  <Icon
                                    name="delete"
                                    onClick={() =>
                                      this.removeUser(
                                        currentExecution.structuralNodeId,
                                        principalRole
                                      )
                                    }
                                  />
                                )}
                              </Label>
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                      {_.map(
                        isCategorized
                          ? groupedTicketSets[key]
                          : groupedTicketSets,
                        (ticketSet) => (
                          <Table.Row>
                            <Table.Cell>
                              {currentExecution.granularPermissionTypeId ===
                                'TICKETSET' && ticketSet.activitySet.label}
                              {ticketSet.assignedPartyRelationship && (
                                <RelationshipLabel
                                  relationship={
                                    ticketSet.assignedPartyRelationship
                                  }
                                />
                              )}
                            </Table.Cell>
                            {_.map(principalRoles, (principalRole) => (
                              <ExecutionViewMatrixSettingsCell
                                ticketSet={ticketSet}
                                principalRole={principalRole}
                                permissionStateActions={permissionStateActions}
                              />
                            ))}
                          </Table.Row>
                        )
                      )}
                    </Table>
                  </React.Fragment>
                );
              })
            ) : (
              <Segment className="placeholder">
                <Header as="h4" textAlign="center">
                  No users or usergroups have been added
                </Header>
              </Segment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ExecutionViewMatrixSettings.propTypes = {
  currentExecution: PropTypes.object,
  executionStateActions: PropTypes.object,
  permissionStateActions: PropTypes.object,
  modelStateActions: PropTypes.object,
  structuralNode: PropTypes.object,
  history: PropTypes.object,
};

export default Permission(
  PermissionContextConsumer(ExecutionWrapper(ExecutionViewMatrixSettings))
);
