import React from 'react';
import * as PropTypes from 'prop-types';
import { Popup, Table } from 'semantic-ui-react';
import { ExecutionContextConsumer } from '../../context/ExecutionContext';
import PartyLabel from '../common/labels/PartyLabel';
import ExecutionStatusOverview from '@vidende/components/ExecutionStatusOverview';
import { StackedBarProgress } from '@vidende/components/StackedBarProgress';
import StatusLabel from '../common/labels/StatusLabel';
import EntityLabel from '../common/labels/EntityLabel';
import ExecutionListDueDate from './ExecutionListDueDate';
import ApplicableDateLabel from '../common/ApplicableDateLabel';

const ExecutionListItem = ({
  execution,
  executionActions,
  workflowStatusTypes,
  compact = false,
}) => {
  const {
    label,
    ownerPartyId,
    ownerPartyLabel,
    partyId,
    partyLabel,
    dueDate,
    applicableDate,
    applicableDateTypeId,
    status,
    assignedEntities,
  } = execution;

  return (
    <Table.Row>
      <Table.Cell width={2}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            executionActions.gotoExecution(execution);
          }}
        >
          <strong>{label}</strong>
        </a>
        {assignedEntities &&
          assignedEntities.length > 0 &&
          assignedEntities.map((entity) => {
            return (
              <div>
                <EntityLabel entity={entity} size="tiny" />
              </div>
            );
          })}
      </Table.Cell>
      <Table.Cell width={1}>
        {applicableDate && (
          <ApplicableDateLabel
            applicableDateTypeId={applicableDateTypeId}
            applicableDate={applicableDate}
          />
        )}
      </Table.Cell>
      <Table.Cell width={1}>
        <StatusLabel status={status} />
      </Table.Cell>
      <Table.Cell width={2}>
        {ownerPartyId && (
          <PartyLabel
            party={{
              id: ownerPartyId,
              label: ownerPartyLabel,
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell width={2}>
        {partyId && <PartyLabel party={{ id: partyId, label: partyLabel }} />}
      </Table.Cell>

      {!compact && (
        <Table.Cell width={2}>
          {execution.dueDate && (
            <ExecutionListDueDate status={status} dueDate={dueDate} />
          )}
        </Table.Cell>
      )}

      <Table.Cell singleLine width={2}>
        <Popup
          wide="very"
          content={
            <ExecutionStatusOverview
              workflowStatusTypes={workflowStatusTypes}
              execution={execution}
            />
          }
          position="left center"
          on={['hover', 'click']}
          trigger={
            <div>
              <StackedBarProgress
                workflowStatusTypes={workflowStatusTypes}
                execution={execution}
              />
            </div>
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

ExecutionListItem.propTypes = {
  execution: PropTypes.object.isRequired,
  executionActions: PropTypes.object.isRequired,
  workflowStatusTypes: PropTypes.array.isRequired,
  compact: PropTypes.bool,
};

export default ExecutionContextConsumer(ExecutionListItem);
