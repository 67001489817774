import apiTools from './apiTools';

class TagApi {
  static getTags() {
    const filter = {
      order: 'label ASC',
    };
    return apiTools.get('Tags', { filter }).then((response) => {
      return response.data;
    });
  }

  static saveTag(tagName) {
    return apiTools.post(`Tags`, {
      label: tagName,
    });
  }
}

export default TagApi;
