// @ts-nocheck
export const pie = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: '80%',
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    marginRight: 0,
    events: {
      load: function () {
        if (this.options.legend.mergedPieItems) {
          // var series = this.series;
          // this.legend.allItems.forEach(function(item) {
          //   item.setVisible(false)
          //   item.legendItem.hide(false)
          //   console.log(item)
          //   // if (item.series) {
          //   //   item.legendItem.element.onclick = function(e) {
          //   //     e.stopPropagation();
          //   //     series.forEach(function(series) {
          //   //       series.data.forEach(function(point) {
          //   //         // if (point.name === item.name) {
          //   //           point.setVisible(false);
          //   //         // }
          //   //       });
          //   //     });
          //   //   };
          //   // }
          // });
        }
      },
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '<b>{point.percentage:.1f}% ({point.y})</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      animation: false,
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>',
        distance: 10,
        color: 'black',
        style: {
          textOutline: 'none',
          textOverflow: 'wrap',
          width: '100px',
        },
        filter: {
          property: 'percentage',
          operator: '>',
          value: 4,
        },

        allowOverlap: false,
        // crop: false
      },
    },
  },
  legend: {
    enabled: false,
  },
};
export const stackedColumn = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  tooltip: {
    formatter: function () {
      const stack = this.point.stack;
      return `<b>${this.x}${
        stack ? ` (${stack})` : ''
      }</b><br/><span style="color:${this.point.color}">${
        this.point.series.name
      }</span>: <b>${this.point.y}</b>`;
    },
  },
  yAxis: {
    min: 0,
    stackLabels: {
      enabled: false,
      style: {
        fontWeight: 'bold',
        color: 'gray',
      },
    },
    title: {
      text: null,
    },
  },
  plotOptions: {
    column: {
      // stacking: 'normal',
      pointPadding: 0.05,
      groupPadding: 0.05,
      borderWidth: 0,
      dataLabels: {
        formatter: function () {
          if (this.y != 0) {
            return this.y;
          }
        },
      },
      animation: false,
    },
    series: {
      // stacking: 'normal'
    },
  },
};

export const gauge = {
  chart: {
    type: 'solidgauge',
    height: '100%',
  },
  title: {
    text: '',
  },
  tooltip: {
    enabled: false,
  },
  pane: {
    center: ['50%', '50%'],
    startAngle: 0,
    endAngle: 360,
    background: {
      outerRadius: '84%',
      innerRadius: '72%',
      borderWidth: 0,
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: [],
  },
  plotOptions: {
    solidgauge: {
      innerRadius: '85%',
      linecap: 'round',
      stickyTracking: false,
      rounded: true,
      dataLabels: {
        y: -30,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
};

export const spider = {
  chart: {
    polar: true,
    type: 'line',
  },
  title: {
    text: '',
  },
  pane: {
    size: '80%',
  },
  tooltip: {
    shared: true,
    pointFormat:
      '<span style="color:{point.color}">{series.name}</span>: <b>{point.y}</b><br/>',
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    lineWidth: 0,
    min: 0,
  },
};

export const sankey = {
  title: {
    text: '',
  },
};

export const europeMap = {
  chart: {
    map: 'custom/europe',
    spacingBottom: 20,
  },

  title: false,

  legend: {
    enabled: true,
  },

  plotOptions: {
    map: {
      allAreas: false,
      joinBy: ['iso-a2', 'code'],
      dataLabels: {
        enabled: true,
        color: '#FFFFFF',
        style: {
          fontWeight: 'bold',
        },
        // Only show dataLabels for areas with high label rank
        format: null,
        formatter: function () {
          if (
            this.point.properties &&
            this.point.properties.labelrank.toString() < 5
          ) {
            return this.point.properties['iso-a2'];
          }
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.name}: <b>{series.name}</b>',
      },
    },
  },
};
