import React from 'react';
import * as PropTypes from 'prop-types';
import { WrapWizardContext } from '../../context/WizardContext';
import { Grid, Header, List, Segment } from 'semantic-ui-react';
import WorkflowList from '../workflow/WorkflowList';
import ExecutionPlanParties from '../executionPlan/ExecutionPlanParties';
import Moment from 'react-moment';
import ApplicableDateLabel from '../common/ApplicableDateLabel';

const CreateFollowUpProgrammeConfirmation = (props) => {
  const { wizardChoices } = props;
  return (
    <React.Fragment>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header>Settings</Header>

            <List>
              <List.Item>
                <List.Header>Label</List.Header>
                {wizardChoices.label}
              </List.Item>
              {wizardChoices.dueDate && (
                <List.Item>
                  <List.Header>Due Date</List.Header>
                  <Moment format="DD/MM/YYYY">{wizardChoices.dueDate}</Moment>
                </List.Item>
              )}
              {wizardChoices.applicableDate && (
                <List.Item>
                  <List.Header>Applicable Date</List.Header>
                  <ApplicableDateLabel
                    applicableDate={wizardChoices.applicableDate}
                    applicableDateTypeId={wizardChoices.applicableDateTypeId}
                  />
                </List.Item>
              )}
            </List>
          </Grid.Column>
          <Grid.Column>
            <Header>Selected Workflow</Header>
            <WorkflowList
              workflows={[wizardChoices.workflow]}
              itemsPerRow={1}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header>Assigned Parties & Users</Header>
      <Segment>
        <ExecutionPlanParties
          options={wizardChoices}
          needsReview={wizardChoices.needsReview}
          editable={false}
        />
      </Segment>
    </React.Fragment>
  );
};

CreateFollowUpProgrammeConfirmation.propTypes = {
  wizardChoices: PropTypes.object,
};

export default WrapWizardContext(CreateFollowUpProgrammeConfirmation);
