import React from 'react';
import * as PropTypes from 'prop-types';
import EditableHeader from '../common/EditableHeader';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import * as storeTypes from '../../constants/storeTypes';
import { mapToOptions } from '../../utils/React';
import RichTextArea from '../common/fields/RichTextArea';

const WorkflowDetailsForm = ({
  workflow,
  onChange,
  onSave,
  saving,
  workflowType,
  currentUser,
}) => {
  return (
    <Form>
      <EditableHeader
        placeholder="Add workflow name"
        value={workflow.label}
        name="label"
        onSubmit={onChange}
      />

      <Form.Field>
        <Dropdown
          label="Structural Node"
          fluid
          selection
          search
          name="structuralNodeId"
          onChange={onChange}
          options={mapToOptions(currentUser.nodes || [], null, true)}
          value={workflow.structuralNodeId}
        />
      </Form.Field>
      <Form.Field>
        <RichTextArea
          label="Description"
          name="description"
          value={workflow.description}
          onChange={onChange}
        />
      </Form.Field>

      <Form.Field>
        {workflowType === storeTypes.CURRENT ? (
          <Button
            type="submit"
            onClick={onSave}
            loading={saving}
            disabled={saving || !workflow.edited}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            type="submit"
            onClick={onSave}
            loading={saving}
            disabled={saving}
          >
            Submit
          </Button>
        )}
      </Form.Field>
    </Form>
  );
};

WorkflowDetailsForm.propTypes = {
  workflow: PropTypes.object,
  currentUser: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  parties: PropTypes.array,
  workflowStatuses: PropTypes.array,
  saving: PropTypes.bool,
  workflowType: PropTypes.string,
};

export default WorkflowDetailsForm;
