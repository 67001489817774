import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import TicketRow from './TicketRow/TicketRow';

type Ticket = {
  id: string;
};

type TicketListProps = {
  tickets: Ticket[];
  ticketComponent: React.FC<{
    item: Ticket;
    isSelected: boolean;
    onClick: (ticket: Ticket) => void;
  }>;
  compactView: boolean;
  onClick: (ticket: Ticket) => void;
  loading: boolean;
  selectedTicketId: string;
  disableInput: boolean;
  actions: {
    [key: string]: (ticket: Ticket) => void;
  };
  extraRowProps: {
    [key: string]: any;
  };
};

const TicketList = (props: TicketListProps) => {
  const {
    tickets,
    ticketComponent,
    compactView,
    onClick,
    loading,
    selectedTicketId,
    disableInput,
    actions,
    extraRowProps,
  } = props;

  let count = 0;
  return (
    <React.Fragment>
      {loading && (
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
      )}
      {tickets.map((item) => {
        count++;
        return (
          <TicketRow
            key={item.id}
            item={item}
            stripe={count % 2 === 0}
            onClick={onClick}
            compactView={compactView}
            isSelected={item.id === selectedTicketId}
            disableInput={disableInput}
            ticketComponent={ticketComponent}
            actions={actions}
            extraRowProps={extraRowProps}
          />
        );
      })}
    </React.Fragment>
  );
};

export default TicketList;
