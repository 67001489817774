import _ from 'lodash';
import pie from './mappers/Pie';
import kpi from './mappers/KPI';
import bar from './mappers/Bar';
import dataTable from './mappers/DataTable';
import dropDown from './mappers/DropDown';
import analyseAndImplementGantt from './mappers/AnalyseAndImplementGantt';
import filter from './mappers/filter';
import flatten from './mappers/flatten';
import sort from './mappers/sort';
import report from './mappers/report';
import group from './mappers/group';
import groupByDate from './mappers/groupByDate';
import mapValues from './mappers/mapValues';
import logic from './mappers/logic';
import mapArrayToObject from './mappers/mapArrayToObject';
import convertToJSON from './mappers/convertToJSON';
import toObject from './mappers/toObject';
import toArray from './mappers/toArray';
import split from './mappers/split';
import camelKeys from './mappers/camelKeys';

export const availableMaps = {
  pie,
  kpi,
  bar,
  dataTable,
  dropDown,
  analyseAndImplementGantt,
  filter,
  flatten,
  sort,
  report,
  group,
  groupByDate,
  mapValues,
  mapArrayToObject,
  convertToJSON,
  logic,
  toObject,
  toArray,
  split,
  camelKeys,
};

export function applyDataMapping(
  inputData,
  dataMapping,
  callback,
  extraArgs = {},
  extraMapping
) {
  if (!dataMapping) return;
  const data = _.zipObject(
    inputData.map((d) => d.key),
    inputData.map((d) => d.data)
  );

  try {
    let dataMappingClone = _.cloneDeep(dataMapping);
    if (dataMappingClone.setDashboardData)
      delete dataMappingClone.setDashboardData;
    const mappedData = _.map(dataMappingClone, ({ mappers, args }, key) => {
      let extraMappers = [];
      if (extraMapping && extraMapping[key]) {
        extraMappers = extraMapping[key].mappers;
      }

      const d = {
        key,
        data: applyMapping({
          mappers: [...mappers, ...extraMappers],
          args: { ...args, ...extraArgs },
          data,
          callback,
        }),
      };
      return d;
    });
    const keyedMappedData = _.zipObject(
      mappedData.map((r) => r.key),
      mappedData.map((r) => r.data)
    );
    // console.log(keyedMappedData);

    if (dataMapping.setDashboardData && callback) {
      _.forEach(dataMapping.setDashboardData, (d) => {
        const { dataSet, toSet } = d;
        const data = _.get(keyedMappedData.data.data, dataSet);
        callback({
          clickType: 'setData',
          toSet,
          data,
        });
      });
    }
    return keyedMappedData;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}

function applyMapping({ mappers, args, data, callback }) {
  const returnData = _.reduce(
    mappers,
    (newArgs, mapper) => {
      let mapperName = mapper;
      let mapperArgs = {};
      if (_.isPlainObject(mapper)) {
        mapperName = mapper.name;
        mapperArgs = mapper.args;
      }
      if (newArgs.outputKey) delete newArgs.outputKey;
      const mapperToUse = availableMaps[mapperName];
      if (!mapperToUse) return {};
      return mapperToUse({ ...newArgs, ...mapperArgs });
    },
    { ...args, data, callback }
  );
  return returnData;
}
