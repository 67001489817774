import { Field } from '../types';

export const copyFieldsToClipboard = (fields: Field[]) => {
  // copy fields to clipboard with tabs between columns to be pasted into a spreadsheet

  let text = 'Field Name\t';
  fields.forEach((field: Field) => {
    text += `${field.label}\t`;
  });
  text += '\nField Reference\t';
  fields.forEach((field: Field) => {
    text += `${field.fieldKey}\t`;
  });

  navigator.clipboard.writeText(text);
};
