import { useMemo } from 'react';
import { RAGStatusResult, Result } from '../../../../../api/statusApi';
import { StatusPopupWrapper } from '../StatusPopupWrapper';
import FormField from '../../../FormField';

interface MetricEntriesComponentProps {
  ragMetric: string;
  ragField?: string;
  data?: PropsData;
  header?: string;
}
export interface PropsData {
  selection: null;
  data: DataData;
}

export interface DataData {
  main: RAGStatusResult;
}

export interface Main {
  results: Result[];
}

const MetricEntriesComponent = (props: MetricEntriesComponentProps) => {
  const { data, ragMetric, ragField } = props;

  const { ragResult, args } = useMemo(() => {
    const args = data?.data?.main?.arguments;
    if (data?.data?.main?.results && (ragMetric || ragField)) {
      const result = data?.data?.main?.results.find(
        (result) => result.ref === ragMetric || result.ref === ragField
      );

      return { ragResult: result, args };
    }
    return { args };
  }, [data, ragMetric, ragField]);

  return (
    <>
      <StatusPopupWrapper result={ragResult} args={args}>
        <div>
          {ragResult &&
            ragResult.statusResults?.map((result) => {
              if (Array.isArray(result.logicResult)) {
                return result.logicResult.map((logicResult) =>
                  logicResult.relevantEntries?.map((entry) => {
                    return (
                      <FormField
                        field={{
                          type: entry.activityFieldTypeId,
                          args: {
                            outputArray: true,
                          },
                        }}
                        value={entry.value}
                        editable={false}
                      />
                    );
                  })
                );
              } else {
                return result.logicResult?.relevantEntries?.map((entry) => {
                  return (
                    <FormField
                      field={{
                        type: entry.activityFieldTypeId,
                        args: {
                          outputArray: true,
                        },
                      }}
                      value={entry.value}
                      editable={false}
                    />
                  );
                });
              }
            })}
        </div>
      </StatusPopupWrapper>
    </>
  );
};

export default MetricEntriesComponent;

MetricEntriesComponent.fields = [
  {
    id: 'ragMetric',
    required: false,
    label: 'RAG Metric',
    activityFieldType: 'ModelSearchField',
    options: {
      modelName: 'Status',
      valueProperty: 'ref',
    },
  },
  {
    id: 'ragField',
    required: false,
    label: 'RAG Field',
    activityFieldType: 'ModelSearchField',
    options: {
      modelName: 'ActivityField',
      valueProperty: 'fieldKey',
      refProperty: 'fieldKey',
    },
  },
];
