import * as _ from 'lodash';
import moment from 'moment';

import { filterTypes, progressColumnName } from '../constants/filterConstants';

interface PaginationOptions {
  recordsPerPage: number;
  activePage: number;
  sortedBy: string;
  direction: 'ascending' | 'descending';
}

interface Filter {
  type: string;
  property: string;
  values: any;
}

export interface Item {
  id: string | number;
  [key: string]: any;
}

export function applyPagination(
  items: Item[],
  paginationOptions: PaginationOptions
): Item[] {
  if (!items) return [];
  return items.slice(
    paginationOptions.recordsPerPage * (paginationOptions.activePage - 1),
    paginationOptions.recordsPerPage * paginationOptions.activePage
  );
}

export function sortItems(
  items: Item[],
  sortOptions: { sortedBy: string; direction: 'ascending' | 'descending' }
): Item[] {
  const { direction, sortedBy } = sortOptions;

  // Return the original items array if the sortedBy column is not progressColumnName
  // and all items have neither a string nor an object value for the property
  if (
    sortOptions.sortedBy !== progressColumnName &&
    items &&
    (items.length === 0 ||
      _.every(items, (item) => {
        const value = recursiveGet(item, sortOptions.sortedBy);
        return (
          !_.isString(value) &&
          !_.isObject(value) &&
          !_.isNumber(value) &&
          !_.isBoolean(value)
        );
      }))
  )
    return items;

  let sortedItems = items || [];
  // Collect items with no value for the sortedBy property
  const unsortableItems = _.filter(items, (item) => {
    const value = recursiveGet(item, sortOptions.sortedBy);
    if (typeof value === 'boolean') return false;
    return typeof value === 'number' ? !value.toString() : !value;
  });

  if (typeof items[0][sortedBy] === 'boolean') {
    // If sorting by a boolean property, sort items based on the boolean value
    sortedItems = _.sortBy(items, (item) => {
      const sortObject = recursiveGet(item, sortedBy);
      return sortObject ? 1 : 0;
    });
    if (direction === 'descending') {
      _.reverse(sortedItems);
    }
    return [...sortedItems, ...unsortableItems];
  }

  if (sortedBy === progressColumnName) {
    // If sorting by progressColumnName, calculate done percentages and sort items based on that
    const donePercentageArray = generateDonePercentageArray(items);
    const sortedArrayOfId = _.map(
      _.sortBy(donePercentageArray, (item) => item.donePercentage),
      (item) => item.id
    );
    sortedItems = _.map(sortedArrayOfId, (sortedId) =>
      _.find(items, (item) => item.id == sortedId)
    );
  } else {
    // If not sorting by progressColumnName, filter the items that have a value for the sortedBy property
    const sortableItems = _.filter(
      items,
      (item) => !!recursiveGet(item, sortedBy)
    );

    const isNestedProperty = sortedBy.includes('.');

    sortedItems = _.sortBy(sortableItems, (item) => {
      const sortObject = recursiveGet(item, sortedBy);

      if (_.isObject(sortObject) && isNestedProperty) {
        // If the sortObject is an object and the property is nested, recursively extract the nested property
        return recursiveGet(sortObject, sortedBy.split('.').pop());
      } else if (_.isObject(sortObject)) {
        // If the sortObject is an object, check for a 'label' property and sort by it if present
        return recursiveGet(sortObject, 'label');
      } else {
        // If the sortObject is not an object, sort items directly by the sortedBy property
        return sortObject;
      }
    });
  }

  // Reverse the sorted items if the direction is 'descending'
  if (direction === 'descending') {
    _.reverse(sortedItems);
  }

  // Combine the sorted and unsortable items
  return [...sortedItems, ...unsortableItems];
}

export function generateNewPaginationOptions(
  paginationOptions: PaginationOptions,
  clickedColumn: string
): PaginationOptions {
  return {
    ...paginationOptions,
    sortedBy: clickedColumn,
    direction:
      paginationOptions.sortedBy === clickedColumn
        ? paginationOptions.direction === 'ascending'
          ? 'descending'
          : 'ascending'
        : paginationOptions.direction,
  };
}

export function addNewFilter(
  filters: Filter[],
  newFilters: Filter[],
  property: string
): Filter[] {
  const updatedFilters = _.filter(
    filters,
    (filter) => filter.property != property
  );
  _.map(newFilters, (newFilter) => {
    updatedFilters.push(newFilter);
  });
  return updatedFilters;
}

export function applyFilter(filters: Filter[], items: Item[]): Item[] {
  if (!filters.length) return items;
  let filteredIds = _.map(filters, (filter) => {
    const filterFunction = filterFunctions[filter.type];
    const result = filterFunction(items, filter);
    return _.map(result, (item) => item.id);
  });
  const intersection = _.intersection(...filteredIds);
  return _.filter(items, (item) => _.indexOf(intersection, item.id) != -1);
}

const filterFunctions: Record<
  string,
  (items: Item[], filter: Filter) => Item[]
> = {
  [filterTypes.SEARCH]: (items, filter) => searchFilter(items, filter),
  [filterTypes.FILTER]: (items, filter) => filterFilter(items, filter),
  [filterTypes.DATE]: (items, filter) => dateFilter(items, filter),
};

export function recursiveGet(obj, property) {
  const value = _.get(obj, property);
  if (typeof value === 'boolean') return value;
  if (value !== undefined) {
    return typeof value === 'number' ? value.toString() : value;
  }

  // Check if the object is not null or undefined and is an object
  if (_.isObject(obj) && !_.isNull(obj)) {
    // Recursively search through nested objects
    for (const key in obj) {
      if (_.isObject(obj[key])) {
        const nestedValue = recursiveGet(obj[key], property);
        if (nestedValue !== undefined) {
          return nestedValue;
        }
      }
    }
  }

  return undefined;
}

function searchFilter(items: Item[], filter: Filter): Item[] {
  const { property, values } = filter;
  const lowerCaseValue = values ? values.toLowerCase() : '';
  return _.filter(items, (item) => {
    if (recursiveGet(item, property)) {
      return _.includes(
        recursiveGet(item, property).toLowerCase(),
        lowerCaseValue
      );
    }
    return false;
  });
}

function filterFilter(items: Item[], filter: Filter): Item[] {
  const res = _.filter(items, (item) => {
    const currentItem = recursiveGet(item, filter.property);
    return currentItem
      ? _.includes(filter.values, currentItem.id || currentItem)
      : false;
  });
  return res;
}

function dateFilter(items: Item[], filter: Filter): Item[] {
  const {
    values: { from, to },
  } = filter;
  return _.filter(items, (item) => {
    if (!recursiveGet(item, filter.property)) return false;
    return moment(
      recursiveGet(item, filter.property),
      moment.ISO_8601
    ).isBetween(moment(from), moment(to), 'day', '[]');
  });
}

export function getActiveFilters(
  currentFilters: Filter[] | undefined,
  name: string
): number {
  if (!currentFilters) return 0;
  const searchValuesCount = _.filter(currentFilters, (currentFilters) => {
    return (
      currentFilters.property === name &&
      (currentFilters.type === filterTypes.DATE ||
        currentFilters.type === filterTypes.SEARCH)
    );
  }).length;
  const filterValues = _.find(currentFilters, {
    property: name,
    type: filterTypes.FILTER,
  });
  const filterValuesCount = filterValues
    ? _.get(filterValues, 'values.length')
    : 0;
  return searchValuesCount + filterValuesCount;
}

interface DonePercentage {
  id: string | number;
  donePercentage: number;
}

function generateDonePercentageArray(executions: Item[]): DonePercentage[] {
  return _.map(executions, (execution) => {
    const { id, ticketCount } = execution;
    const doneTicketCount = _.find(execution.statuses, {
      id: 'DONE',
    }).ticketStatusCount;
    const donePercentage = ticketCount != 0 ? doneTicketCount / ticketCount : 0;
    return {
      id,
      donePercentage,
    };
  });
}
