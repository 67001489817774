import { Fragment } from 'react';
import PieChartComponent from './PieChartComponent';
import BarChartComponent from './BarChartComponent';
import SpiderChartComponent from './SpiderChartComponent';
import SankeyChartComponent from './SankeyChartComponent';
import GenericHighChartComponent from './GenericHighChartComponent';
import MapComponent from './MapComponent';
import KpiComponent from './components/KpiComponent/KpiComponent';
import MetricKpiComponent from './components/KpiComponent/MetricKpiComponent';
import KpiPieComponent from './KpiPieComponent';
import DataTableComponent from './components/DataTableComponent/DataTableComponent';
import DataTableRow from './components/DataTableComponent/DataTableRow';
import DropDownComponent from './DropDownComponent';
import GanttComponent from './components/GanttComponent/GanttComponent';
import ReportComponent from './components/ReportComponent/ReportComponent';
import DebugComponent from './components/DebugComponent';
import ReportItem from './components/report/ReportItem';
import GroupComponent from './components/GroupComponent';
import { Header, Item, List } from 'semantic-ui-react';
import DataTableWrapper from './components/DataTableComponent/DataTableWrapper';
import DateSelectRangeComponent from './DateSelectRangeComponent';
import DashboardTabsComponent from './components/DashboardTabsComponent';
import Field from './components/Field';
import ParagraphComponent from './components/ParagraphComponent';
import FragmentComponent from './components/FragmentComponent';
import Markdown from './components/Markdown';
import CustomComponent from './CustomComponent';
// import TicketTreeComponent from './TicketTreeComponent';
// import FairValueDistributorView from './../../fairValue/FairValueDistributorView';
// import FairValueRAG from './custom/fairValue/FairValueRAG';
// import FairValueProductList from './custom/fairValue/FairValueProductList';
// import FairValueRAGMatrix from './custom/fairValue/FairValueRAGMatrix';
// import FairValueExceptionList from './custom/fairValue/FairValueExceptionList';
import { Icon, Button } from 'semantic-ui-react';
import Moment from 'react-moment';
import RAGHeader from './components/RAGHeader/RAGHeader';
import RAGRow from './components/RAGRow/RAGRow';
import MetricTable from './components/MetricTable/MetricTable';
import MetricMatrix from './components/MetricMatrix/MetricMatrix';
import MetricEntries from './components/MetricEntries/MetricEntries';
import MetricChart from './components/MetricChart/MetricChart';
import RAGDimension from './components/RAGDimension/RAGDimension';
import SectionHeader from './components/SectionHeader/SectionHeader';
import RAGStatusIcon from './status/RAGStatusIcon';
import { StatusPopupWrapper } from './components/StatusPopupWrapper';

let TicketTreeComponent;
let FairValueDistributorView;
let FairValueProductList;
let FairValueRAGMatrix;
let FairValueExceptionList;

if (typeof process !== 'undefined' && process.env.SERVER_REND) {
  TicketTreeComponent = Fragment;
  FairValueDistributorView = Fragment;
  FairValueProductList = Fragment;
  FairValueRAGMatrix = Fragment;
  FairValueExceptionList = Fragment;
} else {
  TicketTreeComponent = require('./TicketTreeComponent').default;
  FairValueDistributorView = require('./../../fairValue/FairValueDistributorView')
    .default;
  FairValueProductList = require('./custom/fairValue/FairValueProductList')
    .default;
  FairValueRAGMatrix = require('./custom/fairValue/FairValueRAGMatrix').default;
  FairValueExceptionList = require('./custom/fairValue/FairValueExceptionList')
    .default;
}

export default {
  debug: DebugComponent,
  pie: PieChartComponent,
  kpi: KpiComponent,
  MetricKpi: MetricKpiComponent,
  kpiPie: KpiPieComponent,
  bar: BarChartComponent,
  spider: SpiderChartComponent,
  sankey: SankeyChartComponent,
  genericHighChart: GenericHighChartComponent,
  custom: CustomComponent,
  map: MapComponent,
  dataTable: DataTableComponent,
  dropDown: DropDownComponent,
  gantt: GanttComponent,
  report: ReportComponent,
  ReportItem: ReportItem,
  Header: Header,
  ItemGroup: Item.Group,
  group: GroupComponent,
  DataTableRow,
  DataTableWrapper,
  Markdown: Markdown,
  dateRange: DateSelectRangeComponent,
  tabs: DashboardTabsComponent,
  Field,
  ListItem: List.Item,
  List: List,
  ListList: List.List,
  p: ParagraphComponent,
  fragment: FragmentComponent,
  ticketTree: TicketTreeComponent,
  fairValueTree: FairValueDistributorView,
  FairValueProductList,
  FairValueRAGMatrix,
  FairValueExceptionList,
  Icon: Icon,
  Button: Button,
  Moment: Moment,
  RAGHeader,
  RAGRow,
  RAGDimension,
  SectionHeader,
  MetricTable,
  MetricMatrix,
  MetricEntries,
  MetricChart,
  RAGStatusIcon,
  StatusPopupWrapper
};
