import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Icon, Table } from 'semantic-ui-react';
import * as _ from 'lodash';

import AttachmentsListRow from './AttachmentsListRow';

class AttachmentsList extends Component {
  state = {
    editingAttachmentId: -1,
  };
  switchEditing = (id) => {
    const currentEditingAttachmentId = this.state.editingAttachmentId;
    const { switchEditingMode } = this.props;
    const isCurrentEditingAttachmentId = currentEditingAttachmentId == id;
    if (isCurrentEditingAttachmentId) {
      this.setState({ editingAttachmentId: -1 });
    } else {
      this.setState({ editingAttachmentId: id });
    }
    switchEditingMode(!isCurrentEditingAttachmentId);
  };
  render() {
    const {
      attachments,
      openAddAttachmentDialog,
      onDelete,
      onDownload,
      disabled = false,
      showDetails,
      allowSelection = false,
      activeIndexes,
      onAddValue,
      onRemoveValue,
      attachmentsConstants,
      allowEditDetails,
      attachmentStateActions,
      attachmentActions,
      editAsModal = false,
      openEditModal,
    } = this.props;
    const { editingAttachmentId } = this.state;
    return (
      <React.Fragment>
        {openAddAttachmentDialog && (
          <Button
            onClick={() => openAddAttachmentDialog()}
            size="tiny"
            disabled={disabled}
          >
            <Icon name="upload" /> Add attachment
          </Button>
        )}
        {attachments && attachments.length > 0 && (
          <Table celled compact className="attachments-list">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>File</Table.HeaderCell>
                {allowSelection && <Table.HeaderCell>Mark</Table.HeaderCell>}
                {showDetails !== false && (
                  <Table.HeaderCell>Details</Table.HeaderCell>
                )}
                {(onDelete || onDownload || allowEditDetails) && (
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {attachments.map((attachment) => (
                <AttachmentsListRow
                  attachment={attachment}
                  key={attachment.id}
                  onDelete={onDelete}
                  onDownload={onDownload}
                  isActive={
                    allowSelection && _.includes(activeIndexes, attachment.id)
                  }
                  onAdd={allowSelection ? onAddValue : null}
                  onRemove={allowSelection ? onRemoveValue : null}
                  allowSelection={allowSelection}
                  showDetails={showDetails}
                  attachmentsConstants={attachmentsConstants}
                  allowEditDetails={allowEditDetails}
                  switchEditingMode={this.switchEditing}
                  attachmentStateActions={attachmentStateActions}
                  attachmentActions={attachmentActions}
                  editAsModal={editAsModal}
                  openEditModal={openEditModal}
                  isVisibleDetailsForm={editingAttachmentId === attachment.id}
                />
              ))}
            </Table.Body>
          </Table>
        )}
      </React.Fragment>
    );
  }
}

AttachmentsList.propTypes = {
  attachments: PropTypes.array,
  openFileDialog: PropTypes.func,
  openAddAttachmentDialog: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  disabled: PropTypes.bool,
  showDetails: PropTypes.bool,
  activeIndexes: PropTypes.array,
  allowSelection: PropTypes.bool,
  onAddValue: PropTypes.func,
  onRemoveValue: PropTypes.func,
  attachmentsConstants: PropTypes.object,
  allowEditDetails: PropTypes.bool,
  switchEditingMode: PropTypes.func,
  attachmentStateActions: PropTypes.object,
  attachmentActions: PropTypes.object,
  editAsModal: PropTypes.bool,
  openEditModal: PropTypes.func,
};

export default AttachmentsList;
